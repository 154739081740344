import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_ALL_STREAMS, GET_VIDEO_TIMELINE } from 'api_configs/queries';
import VideoPlayer from 'components/VideoPlayer';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import { IconType, StatusCode } from '../constants';
import { IAlertMessageType, IStream, IVideoTimeline } from 'interface';
import AlertBar from 'components/AlertBar';
import { useHeader } from 'contexts/HeaderContext';
import AlertList from 'components/AlertList';
import SnapshotsViewer from 'components/SnapshotsViewer';
import { AlertListProvider } from 'contexts/AlertListSnapshotContext';
import Calendar from 'components/Calender';
import { convertToIST } from 'utils';
import Config from '../configs/config.json';
import SelectField from 'components/SelectField';

const Container = styled.div`
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  @media only screen and (min-width: 1920px){
    width: 1500px;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  gap: 30px;
  >:nth-child(1){
    >div>select {
      height: 54px;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      padding: 8px 30px 8px 12px;
    }
  }
`;

const CalenderWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

const CompulsoryIndicator = styled.span`
  color: red;
`;

const Label = styled.div``;

const VideoAlertContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  >div{
    width: 700px;
  }
`;

const NoDataFound = styled.div`
  border: 1px solid ${({theme}) => theme.divider};
  border-radius: 10px;
  height: 300px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VideoContainer = styled.div`
  position: relative;
`;

const VideoSnapshotToggle = styled.div`
  position: absolute;
  top: -32px;
  right: 6px;
  border: 1px solid ${({theme}) => theme.divider};
  border-bottom: none;
  border-radius: 6px 6px 0 0;
  display: flex;
  >:nth-child(1){
    border-right: 1px solid ${({theme}) => theme.divider};
  }
`;

const Span = styled.span<{isSelected?: boolean}>`
  padding: 6px 10px;
  width: 100px;
  text-align: center;
  cursor: pointer;
  background-color: ${({isSelected}) => isSelected ? '#D0D7F2' : ''};
`;

const VideoArchives = () => {
  const {t} = useTranslation(['common']);
  const [getVideoTimeline] = useLazyQuery(GET_VIDEO_TIMELINE);
  const {data: getAllStreamsResponse} = useQuery(GET_ALL_STREAMS);
  const alertListRef = useRef<{ applyCallback: () => void }>();
  const params = useLocation().search;
  const [historyParams] = useState(params);
  const [streamId, setStreamId] = useState<string>('');
  const [videoDetails, setVideoDetails] = useState<IVideoTimeline[]>([]);
  const [totalDuration, setTotalDuration] = useState<number>(0);
  const [durationArray, setDurationArray] = useState<number[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<boolean>(false);
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'});  
  const { updateHeaderTitle } = useHeader();
  const [graphUrl, setGraphUrl] = useState<string>('');
  const [selectedToggle, setSelectedToggle] = useState<string>('video');
  const {replace} = useHistory();
  const [finalStartDate, setFinalStartDate] = useState<string | null>(null);
  const [finalEndDate, setFinalEndDate] = useState<string | null>(null);

  useEffect(()=>{
    updateHeaderTitle(t('Archives'), 'Video');
  },[updateHeaderTitle, t]);

  const CAMERAS_OPTIONS = useMemo(()=>{
    let allStreams: IStream[] = [];
    if(getAllStreamsResponse && getAllStreamsResponse?.getAllStreams?.statusCode === StatusCode.SUCCESS && getAllStreamsResponse?.getAllStreams?.result !== null){
      allStreams = getAllStreamsResponse?.getAllStreams?.result;
    } 
    return allStreams?.map((item: IStream) => ({
      name: item.name,
      value: item.instanceId
    }));
  },[getAllStreamsResponse]);

  const setParams = useCallback(() => {
    const params = '?streamId='+streamId+'&startDate='+finalStartDate?.toString()+'&endDate='+finalEndDate?.toString();
    return params;
  },[streamId, finalStartDate, finalEndDate]);

  useEffect(() => {
    replace(window.location.pathname + setParams());
  }, [replace, setParams]);

  const handleGetVideos = useCallback(async() => {
    if(finalStartDate && finalEndDate){
      const start_date = finalStartDate;
      const end_date = finalEndDate;
      try{
        const res = await getVideoTimeline({variables: {payload: {streamId: streamId, startDate: start_date, endDate: end_date}}});
        if(res && res.data && res.data.getVideoTimeline && res.data.getVideoTimeline.result!==null){
          const videoDetails = res.data.getVideoTimeline.result.videoTimelines;
          const graphUrl = res.data.getVideoTimeline.result.graphUrl;
          if(graphUrl){
            setGraphUrl(graphUrl);
          }
          setVideoDetails(videoDetails);
          const durationArray: number[] = [];
          const totalDuration = [...videoDetails].reduce((total, currentValue) => {
            const sum = total + currentValue.videoDuration;
            durationArray.push(sum);
            return sum;
          },0);
          setTotalDuration(totalDuration);
          setDurationArray(durationArray);
        }else if(res && res.data && res.data.getVideoTimeline && res.data.getVideoTimeline.statusCode === StatusCode.NO_RESULT) {
          setMessage({text: t('Archive videos not found for selected time range'), id:'',type:'danger'});
        }
      }catch(e){
        console.log(e);
        setMessage({text: t('some error occured'), id:'',type:'danger'});
      }
      if(alertListRef.current){
        alertListRef.current.applyCallback();
      }
    }
  },[finalStartDate, finalEndDate, getVideoTimeline, streamId, t]);

  useEffect(() => {
    handleGetVideos();
  },[handleGetVideos, finalStartDate, finalEndDate, streamId]);

  const fetchHistoryParams = useCallback(() => {
    const params = new URLSearchParams(historyParams);
    const streamId = params.get('streamId');
    setStreamId(streamId === 'null' ? '' : streamId as string);
  }, [historyParams]);

  useEffect(() => {
    fetchHistoryParams();
  }, [fetchHistoryParams]);

  const handleDateRangeCallback = useCallback((startDate: string | null, endDate: string | null) => {
    if(startDate && endDate) {
      setFinalStartDate(convertToIST(startDate));
      setFinalEndDate(convertToIST(endDate));
      setSelectedFilters(true);
    }else{
      setFinalStartDate(null);
      setFinalEndDate(null);
      setSelectedFilters(false);
    }
  }, []);

  return (
    <Container>
      { message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
      <SearchContainer>
        <SelectField width='420px' isRequired label={t('Select Camera')} defaultValue={streamId} onChange={(value) => setStreamId(value)} placeholder={t('select filter')}  options={CAMERAS_OPTIONS} />
        <CalenderWrapper>
          <Label>{t('Date Range')} <CompulsoryIndicator> * </CompulsoryIndicator></Label>
          <Calendar onDateRangeCallback={handleDateRangeCallback} />
        </CalenderWrapper>
      </SearchContainer>
      {
        selectedFilters ? 
          <>
            <VideoAlertContainer>
              <AlertListProvider startDate={finalStartDate} endDate={finalEndDate} streamId={streamId}>
                {Config.getAlertsAPIEnable && <AlertList ref={alertListRef} />}
                <VideoContainer>
                  {!(Config.cameraDetailsPageConfigs.layout === 'traffic_pulse') && <VideoSnapshotToggle>
                    <Span isSelected={selectedToggle === 'snapshots'} onClick={() => setSelectedToggle('snapshots')}>{t('Snapshots')}</Span>
                    <Span isSelected={selectedToggle === 'video'} onClick={() => setSelectedToggle('video')}>{t('Video')}</Span>
                  </VideoSnapshotToggle>}
                  {selectedToggle === 'video' ? 
                    <>
                      {
                        videoDetails.length > 0 ? 
                          <VideoPlayer durationArray={durationArray} totalDuration={totalDuration} urls={videoDetails.map(video => video.videoPath)} width='100%' />
                          :
                          <NoDataFound style={{height: '410px'}}>{t('Video files not found for selected date range')}</NoDataFound>
                      }
                    </>
                    :
                    <SnapshotsViewer />
                  }
                </VideoContainer>
              </AlertListProvider>
            </VideoAlertContainer>
            {Config.Visualization_Tool !== 'Superset' && <iframe style={{backgroundColor: 'transparent'}} src={graphUrl} title='edb' width='100%' height='200' />}
          </> : 
          <NoDataFound>{t('Please select date range to view video and graphical analysis.')}</NoDataFound>
      }
    </Container>
  );
};

export default VideoArchives;