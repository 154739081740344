import React, {ChangeEvent} from 'react';
import styled from 'styled-components';
import SelectField from '../../components/SelectField';
import Input from '../../components/Input';
import { AWS_REGIONS } from '../../constants';
import { useTranslation } from 'react-i18next';
import { IEmailDetails } from 'interface';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ConfigurationLabel = styled.div`
  min-width: 186px;
  font-size: 20px;
`;

const AWSConfigurationLabel = styled.div`
  min-width: 225px;
  font-size: 20px;
`;

const ConfigurationLine = styled.div`
  height: 1px;
  background-color: #d3d3d3;
  width: inherit;
`;

const AWSConfigurationFields = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

const ConfigurationWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 450px;
  margin-top: 5px;
  margin-bottom: 14px;
`;

const BasicFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

const SelectFieldWrapper = styled.div`
  margin-bottom: 15px;
`;

const FieldsContainer = styled.div`
  display: flex;
  column-gap: 100px;
`;

interface IProps {
  emailDetails: IEmailDetails;
  onChange: (e?: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onHandleChangeRegion: (e?: string) => void;
  handlesSaveDetails: () => void;
  editMode?: boolean;
  handleUpdateDetails: () => void;
}

const GeneralEmailSetting: React.FC<IProps> = ({emailDetails, onChange, onHandleChangeRegion}) =>{
  const {t} = useTranslation(['common']);

  return(
    <Container>
      <FieldsContainer>
        <BasicFieldsWrapper>
          <ConfigurationWrapper>
            <ConfigurationLabel>{t('Basic Configuration')}</ConfigurationLabel>
            <ConfigurationLine />
          </ConfigurationWrapper>
          <Input id='instanceId' disabled name='instanceId' value={emailDetails.instanceId} onChange={onChange} isRequired type='text' label={t('Instance ID')} maxLength={64} />
          <Input id='nickname' name='nickname' onChange={onChange} value={emailDetails.nickname} type='text' label={t('Nickname')} maxLength={64} />
          <Input id='defaultFrom' name='defaultFrom' onChange={onChange} type='text' value={emailDetails.defaultFrom} label={t('Default From')} maxLength={64} />
          <Input id='defaultTo' name='defaultTo' onChange={onChange} type='text' value={emailDetails.defaultTo} label={t('Default To')} maxLength={64} />
        </BasicFieldsWrapper>
        <AWSConfigurationFields>
          <ConfigurationWrapper>
            <AWSConfigurationLabel>{t('AWS SES Configuration')}</AWSConfigurationLabel>
            <ConfigurationLine />
          </ConfigurationWrapper>
          <SelectFieldWrapper>
            <SelectField width='450px' isRequired onChange={onHandleChangeRegion} defaultValue={emailDetails.region} label={t('Region')} options={AWS_REGIONS}/>
          </SelectFieldWrapper>
          <Input id='accessKey' name='accessKey' onChange={onChange} isRequired value={emailDetails.accessKey} type='text' label={t('Access Key')} maxLength={64} />
          <Input id='secretKey' name='secretKey' onChange={onChange} isRequired value={emailDetails.secretKey} type='password' label={t('Secret Key')} maxLength={64} />
        </AWSConfigurationFields>
      </FieldsContainer>
    </Container>
  );
};

export default GeneralEmailSetting;