import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgArrow = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="m8 2 8.486 8.485a1.5 1.5 0 0 1 0 2.121L8 21.092"
        stroke={props.color}
        strokeWidth={props.weight}
        strokeLinecap="round"
        vectorEffect={"non-scaling-stroke"}
      />
    </svg>
  );
};
export default SvgArrow;
