import React, { useState, useRef, useEffect, useCallback, ChangeEvent } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import Modal from './Modal';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { BiRotateRight } from 'react-icons/bi';
import { GoZoomIn, GoZoomOut } from 'react-icons/go';
import Icon from './Icon';
import { useMutation } from '@apollo/client';
import { UPDATE_USER_DETAILS } from 'api_configs/mutations';
import { StatusCode, IconType } from '../constants';
import { IAlertMessageType } from 'interface';
import AlertBar from './AlertBar';
import NoUserImage from '../components/no-profile.jpg';
import ConfirmationModal from './modals/ConfirmationModal';
import { TbUpload } from 'react-icons/tb';

const ModalContainer = styled.div`
  width: 40vw;
`;

const ModalTitle = styled.div`
  font-size: 20px;
`;

const ModalHeader = styled.div`
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
`;

const SuccessIconContainer = styled.div`
  text-align: center;
  pointer-events: auto;
  cursor: pointer;
  > svg {
    margin-top: 2px;
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  column-gap: 20px;
  position: relative;
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 15px;
  height: 200px;
  width: 210px;
`;

const ProfileImage = styled.img<{disable: boolean}>`
  height: 218px;
  border-radius: 10px;
  margin-top: 35px;
  width: inherit;
  border: 1px solid lightgrey;
  pointer-events: ${({disable})=> disable ? 'none' : 'auto'};
  cursor: ${({disable})=> disable ? 'auto' : 'pointer'};
`;

const ActionIcon = styled.div<{disable: boolean}>`
  pointer-events: ${({disable})=> disable ? 'none' : 'auto'};
  cursor: ${({disable})=> disable ? 'auto' : 'pointer'};
  > div > svg > path {
    stroke: ${({disable})=> disable ? 'gray' : ''};
  }
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff82;
`;

const RotationIconContainer = styled.div`
  text-align: center;
  cursor: pointer;
  > svg {
    margin-top: 0;
  }
`;

const IconTitle = styled.div`
  font-size: 10px;
`;

const ZoomInContainer = styled.div<{disable: boolean}>`
  text-align: center;
  pointer-events: ${({disable})=> disable ? 'none' : 'auto'};
  cursor: ${({disable})=> disable ? 'auto' : 'pointer'};
  color: ${({disable})=> disable ? 'gray' : ''};
  > svg {
    margin-top: 2px;
  }
`;

const ThumbnailImg = styled.img`
  width: 210px;
  height: 218px;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
`;

const ZoomOutContainer = styled.div<{disable: boolean}>`
  text-align: center;
  pointer-events: ${({disable})=> disable ? 'none' : 'auto'};
  cursor: ${({disable})=> disable ? 'auto' : 'pointer'};
  color: ${({disable})=> disable ? 'gray' : ''};
  > svg {
    margin-top: 2px;
  }
`;

const ZoomContainer = styled.div`
  display: flex;
`;

const ActionIconsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 4px;
  top: 13px;
  row-gap: 4px;
`;

const ImageAndButtonsContainer = styled.div`
  width: 210px;
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
`;

const NoUserImageWrapper = styled.div`
  width: 450px;
  display: flex;
  justify-content: center;
`;

const ImageInputFieldWrapper = styled.label`
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  row-gap: 10px;
  width: 210px;
  position: relative;
  margin-top: 32px;
  > div {
    position: absolute;
    right: 6px;
    top: 7px;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: #ffffff82;
    justify-content: center;
    align-items: center;
  }
`;

const ImageInputField = styled.input`
  display: none;
`;

const PercentageWrapper = styled.span`
  margin-top: auto;
  font-size: 10px;
`;

interface ImageCropperProps {
  open: boolean;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  image: string;
  cropperRef: React.MutableRefObject<ReactCropperElement | null>;
  getCropData: () => void;
  handleFileChange: (e: ChangeEvent<HTMLInputElement>) => void;
  titleText?: string;
}

const EditImageContainer = styled.label`
  text-align: center;
  cursor: pointer;
`;

interface IUserDetails {
  name?: string
  username?: string
  email?: string
  phoneNumber?: string 
  role?: string
  image?: string
}

interface CropImageFieldProps {
  onFileChange: (file: string) => void;
  userDetails: IUserDetails;
  refetch: () => void;
  readOnlyMode: boolean;
  disabled: boolean;
  setFileName: (name: string) => void;
}

interface IAngleConfig {
  left: number;
  right: number;
}

export const ImageCropper : React.FC<ImageCropperProps> = ({open, handleFileChange, setDialogOpen, image, cropperRef, getCropData, titleText= 'Edit Image'}) =>{
  const [zoom, setZoom] = useState<number>(1);
  const { t } = useTranslation(['common']);

  const onRotate = (direction: 'left' | 'right') => () => {
    let angle = 0;
    const angleConfig: IAngleConfig = {
      left: -90,
      right: 90,
    };
    angle = angleConfig[direction] ?? 0;
    cropperRef.current?.cropper.rotate(angle);
  };

  useEffect(() => {
    const scaleValue = zoom / 10;
    cropperRef.current?.cropper.scale(scaleValue);
  }, [zoom, cropperRef]);

  return (
    <>
      {open && 
      <Modal isModalOpen={open} closeModal={setDialogOpen} width='42.3vw'>
        <ModalContainer>
          <ModalHeader>
            <ModalTitle>{t(titleText)}</ModalTitle>
            <IconsWrapper>
              <EditImageContainer >
                <IconTitle>{t('Browse')}</IconTitle>
                <TbUpload size={24}/>
                <ImageInputField type='file' accept='image/*' onChange={handleFileChange} />
              </EditImageContainer>
              <RotationIconContainer onClick={onRotate('right')}>
                <IconTitle>{t('Rotate')}</IconTitle>
                <BiRotateRight size={24} />
              </RotationIconContainer>
              <ZoomContainer>
                <ZoomOutContainer disable={parseFloat(zoom.toFixed(1)) === 1} onClick={() => setZoom(prev => Math.max(0.1, prev - 0.1))}>
                  <IconTitle>{t('Zoom Out')}</IconTitle>
                  <GoZoomOut size={22} />
                </ZoomOutContainer>
                <PercentageWrapper>{Math.round(parseFloat(zoom.toFixed(1)) / 0.01)}%</PercentageWrapper>
                <ZoomInContainer disable={parseFloat(zoom.toFixed(1)) === 3} onClick={() => setZoom(prev => Math.max(0.1, prev + 0.1))}>
                  <IconTitle>{t('Zoom In')}</IconTitle>
                  <GoZoomIn size={22} />
                </ZoomInContainer>
              </ZoomContainer>
              <SuccessIconContainer onClick={() => {setDialogOpen(false); getCropData();}}>
                <IconTitle>{t('Done')}</IconTitle>
                <Icon icon='Success' size={28} />
              </SuccessIconContainer>
            </IconsWrapper>
          </ModalHeader>
          <Cropper
            style={{ height: 440, width: '100%' }}
            initialAspectRatio={1}
            src={image}
            ref={cropperRef}
            viewMode={1}
            guides={false}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
          />
        </ModalContainer>
      </Modal>}
    </>
  );
};

const NewCropImageInputField : React.FC<CropImageFieldProps> = ({userDetails, setFileName, disabled, readOnlyMode, onFileChange, refetch}) =>{
  const [image, setImage] = useState<string>('');
  const [cropData, setCropData] = useState<string>('');
  const cropperRef = useRef<ReactCropperElement>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { t } = useTranslation(['common']);
  const [updateUserMutation] = useMutation(UPDATE_USER_DETAILS);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'});

  useEffect(() =>{
    if(userDetails.image){
      setCropData(userDetails.image);
      setImage(userDetails.image);
    } else {
      setCropData('');
      setImage('');
    }
  },[userDetails]);

  const onChange = (file: File) => {
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as string);
      setFileName(file.name);
      setModalOpen(true);
    };
    reader.readAsDataURL(file);
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const files = e.target.files;
    if (files && files.length > 0) {
      onChange(files[0]);
    }
  };

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== 'undefined') {
      setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
      onFileChange(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
    }
  };

  const onRemoveImage = useCallback(async () =>{
    try {
      const payload = {
        name: userDetails?.name,
        username: userDetails?.username,
        email: userDetails?.email,
        phoneNumber: userDetails?.phoneNumber,
        image: '',
        filename: ''
      };
      const data = await updateUserMutation({variables: { payload: payload }});
      if (data?.data?.updateUser?.statusCode === StatusCode?.SUCCESS) {
        setMessage({text: t('Profile photo deleted successfully'), id:'', type:'success'});
        refetch();
      } else {
        setMessage({text: t('Failed to communicate with the system'), id:'', type:'danger'});
      }
    } catch(error) {
      console.log(error);
      setMessage({text: t('Failed to communicate with the system'), id:'', type:'danger'});
    }
  },[updateUserMutation, t, userDetails, refetch]);

  const onCancelYes = useCallback(()=>{
    onRemoveImage();
  },[onRemoveImage]);

  return(
    <>
      {deleteModalOpen && 
        <ConfirmationModal modalOpen={deleteModalOpen} setModalOpen={setDeleteModalOpen} onSubmit={onCancelYes} hasConfirmationField={false}
          titleText={t('Delete Profile Photo?')} confirmDescription={t('Are you sure you want to delete profile photo?')} noteText={t('Once deleted this action cannot be undone.')} />}
      {modalOpen &&
        <ImageCropper
          getCropData={getCropData}
          image={image}
          handleFileChange={handleFileChange}
          cropperRef={cropperRef}
          open={modalOpen} 
          setDialogOpen={setModalOpen}
        />}
      {message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
      {cropData ?
        <ImageAndButtonsContainer>
          <ActionIconsWrapper>
            <ActionIcon disable={readOnlyMode || disabled} onClick={() => setModalOpen(true)} >
              <Icon icon='Edit' size={20} color='primary' />
            </ActionIcon>
            <ActionIcon disable={readOnlyMode || disabled} onClick={() => setDeleteModalOpen(true)}>
              <Icon icon='Delete' size={20} color='danger' />
            </ActionIcon>
          </ActionIconsWrapper>
          <ImageWrapper>
            <ProfileImage disable={disabled} onClick={() => setModalOpen(true)} src={cropData.substring(cropData.indexOf('data:image'))} alt={t('Profile Preview')} />
          </ImageWrapper>
        </ImageAndButtonsContainer>
        : 
        <NoUserImageWrapper>
          <ImageInputFieldWrapper>
            <Icon icon='Edit' size={20} color='primary' />
            <ThumbnailImg src={NoUserImage} />
            <ImageInputField type='file' accept='image/*' onChange={handleFileChange} />
          </ImageInputFieldWrapper>
        </NoUserImageWrapper>
      }
    </>
  );
};

export default NewCropImageInputField;
