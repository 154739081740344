import SelectField from 'components/SelectField';
import React, { useState } from 'react';
import { RiChatDeleteLine } from 'react-icons/ri';
import styled from 'styled-components';
import Config from '../../configs/config.json';
import { useTranslation } from 'react-i18next';
import Modal from 'components/Modal';
import Button from 'components/Button';
import ButtonWithLoading from 'components/ButtonWithLoading';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  gap: 20px;
  >:nth-child(3), :nth-child(4){
    input{
      margin-bottom: 0;
    }
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  >div{
    width: 400px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const IconDiv = styled.div`
  width: 40px !important;
  height: 40px;
  border-radius: 50%;
  background-color: #c4c4c4;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ConfirmDesc = styled.span`
  text-align: center;
  color: ${({theme})=>theme.text.secondaryText};
`;

const Label = styled.div``;

const CustomTextArea = styled.textarea`
  width: 100%;
  min-height: 100px;
  border-radius: 6px;
  max-width: 400px;
  padding: 6px;
  border: 1px solid lightgrey;
  font-family: Arial, Helvetica, sans-serif;
  &:focus{
    outline: none;
    border-color: ${({theme}) => theme.input.focus};
  }
`;

const HeaderText = styled.span`
  max-width: 350px;
  word-wrap: break-word;
  font-size: 16px;
  font-weight: 600;
  color: #595959;
  text-align: center;
`;

interface IIgnoreAlertModalProps {
  confirmDescription: string,
  modalOpen: boolean,
  setModalOpen: (modalOpen: boolean) => void,
  onSubmit: (reason: string, comment: string)=>void;
  titleText: string;
  updateAlertStatusLoading: boolean;
}

const IgnoreAlertModal: React.FC<IIgnoreAlertModalProps> = ({confirmDescription, modalOpen, setModalOpen, onSubmit, titleText, updateAlertStatusLoading}) => {
  const {t} = useTranslation(['common']);
  const [reason, setReason] = useState<string>('');
  const [comment, setComment] = useState<string>('');

  const handleSubmit = () => {
    onSubmit(reason, comment);
    setTimeout(() => {
      setModalOpen(false);
    }, 1000);
  };

  return (
    <Modal isModalOpen={modalOpen} closeModal={setModalOpen} width='450px'>
      <Container>
        <Header>
          <IconDiv>
            <RiChatDeleteLine color='#595959' size={20}/>
          </IconDiv>
          <HeaderText>{titleText}</HeaderText>
        </Header>
        <ConfirmDesc>{confirmDescription}</ConfirmDesc>
        <Header>
          <SelectField label={t('Ignore reason')} placeholder={t('Please select reason')} options={Config.ignoreAlertOptions} defaultValue={reason} onChange={(value) => setReason(value)} />
          <TextAreaWrapper>
            <Label>{t('Comment')}</Label>
            <CustomTextArea value={comment} maxLength={255} onChange={(e) => setComment(e.target.value)} />
          </TextAreaWrapper>
        </Header>
        <ButtonsWrapper>
          <ButtonWithLoading disabled={updateAlertStatusLoading} loading={updateAlertStatusLoading} variant={'primary'} type='submit' onClick={handleSubmit}>{t('Yes')}</ButtonWithLoading>
          <Button variant='secondary' onClick={()=>setModalOpen(false)}>{t('No') }</Button>
        </ButtonsWrapper>
      </Container>
    </Modal>
  );
};

export default IgnoreAlertModal;