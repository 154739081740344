import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgZoomOut = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="m21 21-5.143-5.143M7.5 10.714h6.429m-3.215 7.715A7.716 7.716 0 0 1 5.26 5.259a7.714 7.714 0 1 1 5.455 13.17Z"
        stroke={props.color}
        vectorEffect={"non-scaling-stroke"}
      />
    </svg>
  );
};
export default SvgZoomOut;
