import React, { useEffect, useState, ChangeEvent, useCallback } from 'react';
import { useHeader } from 'contexts/HeaderContext';
import { useTranslation } from 'react-i18next';
import { Container } from 'CommonStyles';
import Input from 'components/Input';
import styled from 'styled-components';
import Button from 'components/Button';
import Label from 'components/Label';
import { GET_ROLES_LIST, GET_USER_DETAILS } from 'api_configs/queries';
import { useQuery } from '@apollo/client';
import { COUNTRY_CODES, IconType, PERMISSION_CODE, StatusCode, USER_IMG_BASE_URL } from '../constants';
import TokenService from 'api_configs/tokenService';
import { CircularProgress } from '@mui/material';
import { UPDATE_USER_DETAILS } from 'api_configs/mutations';
import { useMutation } from '@apollo/client';
import { IAlertMessageType } from 'interface';
import AlertBar from 'components/AlertBar';
import ButtonWithLoading from 'components/ButtonWithLoading';
import { isEqual } from 'lodash';
import UpdatePassword from 'components/UpdatePassword';
import Modal from 'components/Modal';
import { user_mgmt_client } from 'index';
import { descriptionRegex, phoneNumberRegex, validInputRegex, validateEmail } from 'utils/utils';
import SelectField from 'components/SelectField';
import NewCropImageInputField from '../components/NewCropImageInputField';

const ButtonsContainer = styled.div`
  display: flex;
  column-gap: 30px;
  margin-top: 20px;
  width: 950px;
  justify-content: center;
  height: 70px;
  > button {
    width: max-content;
    line-height: 1.2;
  }
  > button:nth-child(2) {
     width: auto;
  }
`;

const SpinnerBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 600px;
  gap: 10px;
`;

const MainContainer = styled.div`
  display: flex;
  column-gap: 50px;
`;

const SubContainer = styled.div``;

const PasswordWrapper = styled.div`
  display: flex;
  position: relative;
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  row-gap: 2px;
  width: 450px;
  position: relative;
`;

const PhoneNumberWrapper = styled.div`
  width: 450px;
  display: flex;
  justify-content: space-between;
  > div:first-child{
    width: 120px;
  }
  > div:last-child{
    width: 310px !important;
  }
`;

const UpdatePasswordLabel = styled.div`
  color: rgb(11, 37, 136);
  cursor: pointer;
  text-decoration: underline;
  position: absolute;
  right: 0;
`;

interface IUserDetails {
  name?: string
  username?: string
  email?: string
  countryCode?: string
  phoneNumber?: string 
  roleId?: number
  image?: string
}

interface IRoles {
  id: number;
  name: string;
  description: string;
}

const USER_DETAILS: IUserDetails = {name: '', username: '', email: '', countryCode: '91', phoneNumber: '', roleId: 0, image: '' };

const MyProfile = () =>{
  const {updateHeaderTitle} = useHeader();
  const {t} = useTranslation(['common']);
  const [userDetails, setUserDetails] = useState<IUserDetails>(USER_DETAILS);
  const [prevUserDetails, setPrevUserDetails] = useState<IUserDetails>(USER_DETAILS);
  const [updateUserMutation] = useMutation(UPDATE_USER_DETAILS);
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'});
  const { data, loading: userLoading, refetch } = useQuery(GET_USER_DETAILS, { variables: {username: TokenService.getUser()?.username}});
  const {refetch: rolesListRefetch} = useQuery(GET_ROLES_LIST, {client: user_mgmt_client});
  const [loader, setLoader] = useState(false);
  const [isPasswordModal, setPasswordMoadl] = useState(false);
  const [readOnlyMode, setReadOnlyMode] = useState(true);
  const [loading, setLoading] = useState(false);
  const [rolesList, setRolesList] = useState<IRoles[]>([]);
  const [fileName, setFileName] = useState('');
  const updateProfilePhotoAccess = TokenService.hasAccess(PERMISSION_CODE.update_profile_photo);
  const updateNameAccess = TokenService.hasAccess(PERMISSION_CODE.update_name);
  const updateEmailAccess = TokenService.hasAccess(PERMISSION_CODE.update_email);
  const updateContactNoAccess = TokenService.hasAccess(PERMISSION_CODE.update_contact_number);
  const updatePasswordAccess = TokenService.hasAccess(PERMISSION_CODE.update_password);

  useEffect(()=>{
    updateHeaderTitle(t('My Profile'), 'User');
  },[updateHeaderTitle, t]);

  const getImageAsBase64 = useCallback(async(imageUrl: string) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise<string>((resolve) => {
        reader.onloadend = () => {
          const base64data = reader.result as string;
          resolve(base64data);
        };
      });
    } catch (error) {
      console.error('Error converting image to base64:', error);
      throw error;
    }
  }, []);

  const fetchBase64String = useCallback(async (str: string) => {
    try {
      const base64Data = await getImageAsBase64(str);
      return base64Data ? base64Data.split(',')[1]: '';
    } catch (error) {
      console.error('Error:', error);
    }
  },[getImageAsBase64]);

  const fetchUser = useCallback(async () => {
    if (data?.getUser?.statusCode === StatusCode.SUCCESS) {
      const userData = data?.getUser?.result;
      const response = {
        name: userData?.name, 
        username: userData?.username,
        countryCode: userData.countryCode?.toString(),
        phoneNumber: userData?.phoneNumber,
        email: userData?.email,
        roleId: userData.roleId,
        image: userData.imagePath ? USER_IMG_BASE_URL + userData.imagePath : ''
      };
      setFileName(userData.imagePath?.split('/').pop());
      setUserDetails(response);
      setPrevUserDetails(response);
    }
  }, [data]);

  useEffect(() =>{
    fetchUser();
  },[fetchUser]);

  const getRoleName = useCallback((roleId?: number) =>{
    const user = rolesList.filter((item) => item.id === roleId);
    return user ? user[0]?.name : '';
  },[rolesList]);

  const getRolesList = useCallback(async () =>{
    setLoading(true);
    try{
      const response = await rolesListRefetch();
      if(response.data.listRoles.statusCode === StatusCode.SUCCESS){
        setRolesList(response.data.listRoles.result);
      }
    } catch(error){
      console.log(error);
    }
    setLoading(false);
  },[rolesListRefetch]);

  useEffect(() =>{
    getRolesList();
  },[getRolesList]);

  const onChangeHandler = (event?: ChangeEvent<HTMLInputElement | HTMLSelectElement>)  => {
    const { name, value } = event?.target || {};
    if (name === 'name') {
      if(validInputRegex.test(value!)) {
        setUserDetails(prev => ({...prev, name: value}));
      }
    }
    if(name === 'username'){
      setUserDetails(prev => ({...prev, username: value}));
    }
    if(name === 'email'){
      setUserDetails(prev => ({...prev, email: value!.toLowerCase().replace(descriptionRegex, '')}));
    }
    if(name === 'phoneNumber'){
      setUserDetails(prev => ({...prev, phoneNumber: value?.replace(phoneNumberRegex, '')}));
    }
  };

  const handleSubmit = useCallback(async() => {
    const { name, email, phoneNumber } = userDetails;
    if (name?.trim() === '') {
      setMessage({text: t('Please enter the name'), id:'', type:'danger'});
      return;
    }
    if(phoneNumber){
      if (phoneNumber!.length < 10 || phoneNumber!.length > 16){
        setMessage({text: t('Contact number should be minimum 10 or maximum 16 digit'), id:'',type:'danger'});
        return;
      }
    }
    if (!validateEmail(email!)) {
      setMessage({text: t('Please enter the valid email'), id:'',type:'danger'});
      return;
    }
    setLoader(true);
    try {
      const payload = {
        name: userDetails?.name,
        username: userDetails?.username,
        email: userDetails?.email,
        countryCode: parseInt(userDetails.countryCode!),
        phoneNumber: userDetails?.phoneNumber,
        image: userDetails.image ? userDetails.image.startsWith('http') ? await fetchBase64String(userDetails.image as string) : userDetails.image.split(',')[1] : '',
        filename: fileName
      };
      const data = await updateUserMutation({variables: { payload: payload }});
      if (data?.data?.updateUser?.statusCode === StatusCode?.SUCCESS) {
        setMessage({text: t('User updated successfully'), id:'', type:'success'});
        setReadOnlyMode(true);
        refetch();
      } else {
        setMessage({text: t('Failed to communicate with the system'), id:'', type:'danger'});
      }
    } catch (error) {
      setMessage({text: t('Failed to communicate with the system'), id:'', type:'danger'});
      console.error((error as Error).message);
    }
    setLoader(false);
  }, [userDetails, t, updateUserMutation, refetch, fileName, fetchBase64String]);

  async function blobUrlToBase64(blobUrl: string): Promise<string> {
    try {
      const response = await fetch(blobUrl);
      const blob = await response.blob();
      return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result as string);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error('Error converting Blob to base64:', error);
      throw error;
    }
  }

  const getImage = useCallback(async (img: string | File | null) => {
    if (img) {
      try {
        const base64String = await blobUrlToBase64(img as string);
        setUserDetails((prev: IUserDetails) => ({ ...prev, image: base64String }));
      } catch (error) {
        console.error('Error getting image:', error);
      }
    }
  }, []);

  const onHandleCountryCode = (value: string) => {
    setUserDetails(prev => ({...prev, countryCode: value}));
  };

  if (loading || userLoading) {
    return (
      <SpinnerBox>
        <CircularProgress />
        <Label labelText={t('Loading...')}></Label>
      </SpinnerBox>
    );
  }

  return(
    <Container>
      {message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
      { isPasswordModal && <Modal isModalOpen={isPasswordModal} closeModal={() => setPasswordMoadl(false)}>
        <UpdatePassword setModalOpen={setPasswordMoadl}/>
      </Modal>}
      <MainContainer>
        <SubContainer>
          <PasswordWrapper>
            <Input id='username' name='username' value={userDetails.username} disabled onChange={onChangeHandler} isRequired type='text' label={t('Username')} maxLength={255} />
            { updatePasswordAccess && <UpdatePasswordLabel onClick={() => setPasswordMoadl(true)}>{t('Update Password')}</UpdatePasswordLabel>}
          </PasswordWrapper>
          <Input id='name' name='name' disabled={readOnlyMode || !updateNameAccess} value={userDetails.name} onChange={onChangeHandler} isRequired type='text' label={t('Name')} maxLength={255} />
          <Input id='email' name='email' isRequired value={userDetails.email} disabled={readOnlyMode || !updateEmailAccess} onChange={onChangeHandler} type='email' label={t('Email')} maxLength={255} />
        </SubContainer>
        <SubContainer>
          <ProfileContainer>
            <NewCropImageInputField setFileName={(name) => setFileName(name)} disabled={readOnlyMode} userDetails={userDetails} refetch={() => refetch()} onFileChange={getImage} readOnlyMode={!updateProfilePhotoAccess} />
          </ProfileContainer>
        </SubContainer>
      </MainContainer>
      <MainContainer>
        <PhoneNumberWrapper>
          <SelectField
            label={t('Country Code')}
            onChange={onHandleCountryCode} 
            defaultValue={userDetails.countryCode}
            options={COUNTRY_CODES}
            disabled={readOnlyMode || !updateContactNoAccess}
            width='100px'
          />
          <Input id='phoneNumber' name='phoneNumber' disabled={readOnlyMode || !updateContactNoAccess} value={userDetails?.phoneNumber} onChange={onChangeHandler} type='text' label={t('Contact Number')} maxLength={16} />
        </PhoneNumberWrapper>
        <SubContainer>
          <Input id='role' name='role' disabled value={getRoleName(userDetails.roleId)} type='text' label={t('Role')} />
        </SubContainer>
      </MainContainer>
      <ButtonsContainer>
        {readOnlyMode ? <Button variant='primary' onClick={() => setReadOnlyMode(false)}>{t('Edit')}</Button> :
          <ButtonWithLoading loading={loader} variant='primary' disabled={isEqual(prevUserDetails, userDetails)} onClick={() => handleSubmit()} type='submit'>{t('Save')}</ButtonWithLoading>}
        {readOnlyMode || <Button variant='secondary' disabled={isEqual(prevUserDetails, userDetails)} onClick={() => setUserDetails(prevUserDetails)} >{t('Reset')}</Button>}
      </ButtonsContainer>
    </Container>
  );
};

export default MyProfile;
