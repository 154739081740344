import { useState, useCallback, useEffect } from 'react';
import { Auth } from 'aws-amplify';

export const useIdentityID = () => {
  const [userId, setUserId] = useState('');

  const fetchIdentityID = useCallback(async () => {

    try {
      const userAuth = await Auth.currentAuthenticatedUser();
      const userId = 'ap-south-1:' + userAuth.attributes.sub; // Cognito User ID
      setUserId(userId);
    } catch (error) {
      console.log('Error fetching user: ', error);
    }
    
  }, []);

  useEffect(() => {
    fetchIdentityID();
  }, [fetchIdentityID]);

  return { userId, fetchIdentityID};
};