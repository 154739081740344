import React, { useCallback, useRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { MdOutlineManageSearch } from 'react-icons/md';
import { BsFilter } from 'react-icons/bs';
import { ScrollBarCss } from 'CommonStyles';
import { useClickOutside } from 'hooks/useClickOutside';
import { useTranslation } from 'react-i18next';
import { IGetAllAlgorithmRes } from 'interface';
import { DateTimePicker, LocalizationProvider, renderTimeViewClock } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Button from './Button';
import { format } from 'date-fns';
import { ADVANCE_FILTER_KEYNAMES, ALERT_STATUS, DATE_PICKER_DATE_TIME_FORMAT, ALGORITHM_CODES, DATE_FORMAT_WITH_SEC, DATE_TIME_FORMAT } from '../constants';
import Icon from './Icon';
import { getAlgoIcon, getAlgorithmName } from 'utils/utils';
import RadioButton from './RadioButton';
import Checkbox from './Checkbox';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
`;

const Wrapper = styled.div<{width: string}>`
  width: ${({width}) => width};
  height: 40px;
  border: 2px solid ${({theme})=>theme.divider};
  border-radius: 8px;
  display: flex;
  align-items: center;
  position: relative;
`;

const SearchContainer = styled.div`
  padding: 0 10px;
  display: flex;
  align-items: center;
  flex: 1;
`;

const FilterContainer = styled.div<{hasBorder: boolean}>`
  padding: 0 10px;
  min-width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: ${({hasBorder, theme}) => hasBorder ? `2px solid ${theme.divider}` : ''};
  cursor: pointer;
  gap: 10px;
  position: relative;
`;

const FilterIndicator = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  font-size: 12px;
  right: -6px;
  top: -6px;
  background-color: ${({theme}) => theme.divider};
`;

const Input = styled.input`
  flex: 1;
  border: none;
  outline: none;
  padding: 0 10px;
  background: transparent;
  ::placeholder{
    font-style: italic;
    color: ${({theme}) => theme.text.placeholder};
  }
`;

const SearchDrawer = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  width: 100%;
  max-height: 160px;
  overflow-y: auto;
  background-color: ${({theme}) => theme.backgroundColor};
  z-index: 1001 !important;
  border: 1px solid ${({theme}) => theme.divider};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  ${ScrollBarCss}
`;

const SearchFields = styled.div`
  width: 100%;
  padding: 10px;
  cursor: pointer;
  display: flex;
  gap: 10px;
`;

const AdvancedFilterContainer = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  width: fit-content;
  background-color: ${({theme}) => theme.backgroundColor};
  z-index: 1001 !important;
  border: 1px solid ${({theme}) => theme.divider};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 5px rgba(65, 65, 65, 0.5);
  min-height: 160px;
  padding: 10px;
  gap: 20px;
`;

const AdvanceFilterDrawer = styled.div`
  width: fit-content;
  display: flex;
  gap: 30px;
  @media only screen and (min-width: 1366px){
    gap: 10px;
  }
`;

const FilterContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex: 1;
`;

const DateRangeFilterContainer = styled(FilterContainerWrapper)`
  gap: 8px;
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 10px;
`;

const CheckboxInput = styled.input`
  margin-right: 5px;
  width: 16px;
  height: 16px;
  border-radius: 5px;
  cursor: pointer;
`;

const Label = styled.span<{isBold?: boolean}>`
  font-weight: ${({isBold}) => isBold ? 600 : ''};
  font-size: ${({isBold}) => isBold ? '18px' : '16px'};
`;

const CheckboxContainer = styled.div<{isDateRange?: boolean}>`
  display: flex;
  width: ${({isDateRange}) => isDateRange ? '220px' : '430px'};
  flex-wrap: wrap;
  gap: 10px;
  column-gap: 20px;
  @media only screen and (min-width: 1366px){
    column-gap: 10px;
  }
`;

const AlgoCheckBoxContainer = styled.div<{isChecked?: boolean}>`
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 6px 10px;
  border: 1px solid ${({theme}) => theme.divider};
  background-color: ${({isChecked}) => isChecked ? '#D0D7F2' : ''};
  border-radius: 4px;
  cursor: pointer;
  width: 100px;
  height: 60px;
  justify-content: center;
  flex-direction: column;
  :hover{
    border: 1px solid ${({theme}) => theme.text.primaryText};
  }
`;

const AlgoName = styled.div<{hasBackground?: boolean}>`
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
  ${({hasBackground}) => hasBackground && css`
  max-width: 170px;
  background-color: ${({theme}) => theme.divider};
  padding: 6px;
  border-radius: 5px;
  `}
`;

const AddSpace = styled.span`
  margin-left: 5px;
`;

const FilterAlgoNameWrapper = styled(AlgoName)`
  max-width: 90px;
`;

const DateRangeContainer = styled.div`
  div .MuiStack-root{
    flex-direction: column;
    gap: 10px;
  }
`;

const ErrorMessage = styled.div`
  font-size: 13px;
  color: red;
`;

const NotFoundDiv = styled.div`
  width: 150px;
  height: 55px;
  text-align: center;
  border: 1px solid #aaa;
  display: flex;
  align-items: center;
  border-radius: 4px;
`;

const DatePickersWrappers = styled.div`
  > div {
    display: flex;
    flex-direction: row;
    column-gap: 30px;
    top: 0;
    > div:nth-child(2){
       margin-top: 0;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;

const AppliedFiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
`;

const AppliedFiltersDiv = styled.div`
  padding: 5px 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: ${({theme}) => theme.sidemenu.selectedItembackground};
  border-radius: 10px;
`;

const AlgorithmNames = styled.div`
  display: flex;
  gap: 10px;
`;

const IconDiv = styled.div`
  position: absolute;
  cursor: pointer;
  right: 10px;
  display: flex;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ebe8e8;
`;

const AlertFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 20px;
  margin-right: 35px;
  >label{
    margin-bottom: 0;
  }
`;

const SearchQueryWrapper = styled.div`
  display: flex;
  padding-left: 10px;
  &:hover{
    background-color: ${({theme}) => theme.divider};
  }
`;

export interface ISearchQueryKeys {
  keyName: string;
  displayName: string; 
}

export interface IAdvanceFilterConfigs {
  algorithmList: string[];
  dateRange: string[];
}

export interface IAppliedFilter{
  displayName: string | string[];
  query: string | string[] | boolean;
  keyName: string | string[];
}

export interface ICameraSuggestionList {
  name: string;
  instanceId: string;
}

interface ISearchInputFilterProps{
  searchQueryKeys?: ISearchQueryKeys[];
  placeholder?: string;
  searchCallback?: (keyName: string, displayName: string, query: string) => void;
  advanceFilterCallback?: (advcanceFilterConfigs: IAdvanceFilterConfigs) => void;
  searchQuery?: string;
  setSearchQuery?: (query: string)=>void;
  showAlgorithm?: boolean;
  algorithmList?: IGetAllAlgorithmRes[];
  showDateRange?: boolean;
  searchFilterCallback?: (a: IAppliedFilter[]) => void;
  enableAdvancedFilter?: boolean
  enableInputFilter?: boolean
  showAlertActivityFilter?: boolean,
  enableMultiSelect?: boolean
  showCameraSearchSuggestions?: boolean
  cameraSuggestionList?: ICameraSuggestionList[];
  defaultAppliedFilters?: IAppliedFilter[];
  numberPlateFilter?: boolean;
}

const SearchInputFilter: React.FC<ISearchInputFilterProps> = ({showAlertActivityFilter=false, defaultAppliedFilters=[], placeholder='Search...', searchQueryKeys=[], cameraSuggestionList=[], algorithmList=[], searchFilterCallback=()=>{}, showAlgorithm=false, showDateRange=false, enableAdvancedFilter=true, enableInputFilter=true, enableMultiSelect=false, showCameraSearchSuggestions= false, numberPlateFilter= false}) => {
  const [searchDrawerOpen, setSearchDrawerOpen] = useState<boolean>(false);
  const [advanceFilterOpen, setAdvanceFilterOpen] = useState<boolean>(false);
  const drawerRef = useRef<HTMLDivElement>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [checkedSearchQueriesDisplayName, setCheckedSearchQueriesDisplayName] = useState<string[]>([]);
  const [checkedSearchQueries, setCheckedSearchQueries] = useState<string[]>([]);
  useClickOutside(drawerRef, () => {setSearchDrawerOpen(false);});
  const [checkAlgorithmList, setCheckedAlgorithmList] = useState<string[]>([]); 
  const {t} = useTranslation(['common']);
  const [startDate, setStartDate] = useState<Dayjs| null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [appliedFilters, setAplliedFilters] = useState<IAppliedFilter[]>(() => [...defaultAppliedFilters]);
  const [alertActivityFilter, setAlertActivityFilter] = useState<string>('');
  const [dateError, setDateError] = useState<boolean>(false);
  const [suggestionDrawerOpen, setSuggestionDrawerOpen] = useState<boolean>(false);
  const advanceFilterRef = useRef<HTMLDivElement>(null);
  const [filterSuggestionList, setFilterSuggestionList] = useState<ICameraSuggestionList[]>(cameraSuggestionList);
  const [showNumberPlate, setShowNumberPlate] = useState<boolean>(false);
  
  useEffect(() => {
    searchFilterCallback(appliedFilters);
  }, [searchFilterCallback, appliedFilters]);

  const handleSelectedQuery = useCallback((queryKey: ISearchQueryKeys)=>{
    if(searchQuery){
      if(checkedSearchQueries?.length > 0) {
        setAplliedFilters(prev => [...prev, {displayName: checkedSearchQueriesDisplayName, keyName: checkedSearchQueries, query: searchQuery}]);
      }else
        setAplliedFilters(prev => [...prev, {displayName: queryKey.displayName, keyName: queryKey.keyName, query: searchQuery}]);
    }
    setSearchQuery('');
    setSearchDrawerOpen(false);
  },[searchQuery, setSearchQuery, checkedSearchQueries, checkedSearchQueriesDisplayName]);

  const handleSelectedCameraQuery = useCallback((queryKey: string)=>{
    if(searchQuery){
      setAplliedFilters(prev => [...prev, {displayName: 'Name', keyName: 'name', query: queryKey}]);
    }
    setSearchQuery('');
    setSuggestionDrawerOpen(false);
  },[searchQuery, setSearchQuery]);

  const handleCheckboxChange = useCallback( async (algoName: string, isChecked:boolean) => {
    let newArray = checkAlgorithmList;
    if(!isChecked){
      newArray = [...checkAlgorithmList,algoName];
      setCheckedAlgorithmList(newArray);
    }else{
      newArray = checkAlgorithmList.filter((id)=>id !== algoName);
      setCheckedAlgorithmList(newArray);
    }
  },[checkAlgorithmList]);

  const handleMultiSelectFilter = (keyName: string, displayName: string) => {
    if(checkedSearchQueries.includes(keyName)) {
      setCheckedSearchQueries(checkedSearchQueries.filter((searchQuery) => searchQuery !== keyName));
      setCheckedSearchQueriesDisplayName(checkedSearchQueries.filter((searchQuery) => searchQuery !== displayName));
    }else {
      setCheckedSearchQueries(prev => ([...prev, keyName]));
      setCheckedSearchQueriesDisplayName(prev => ([...prev, displayName]));
    }
  };

  const handleApplyFilter = useCallback(() => {
    if(checkAlgorithmList.length > 0){
      setAplliedFilters(prev => {
        const filters = [...prev];
        const analysisFilterIndex = filters.findIndex(item => item.keyName === ADVANCE_FILTER_KEYNAMES.ANALYSIS);
        if(analysisFilterIndex !== -1){
          filters[analysisFilterIndex].query = checkAlgorithmList;
        }else{
          filters.push({ displayName: 'Analysis', keyName: ADVANCE_FILTER_KEYNAMES.ANALYSIS, query: checkAlgorithmList });
        }
        return filters;
      });
    }else{
      setAplliedFilters(prev => {
        const filters = [...prev];
        const analysisFilterIndex = filters.findIndex(item => item.keyName === ADVANCE_FILTER_KEYNAMES.ANALYSIS);
        if(analysisFilterIndex !== -1){
          filters.splice(analysisFilterIndex, 1);
        }
        return filters;
      });
    }
    if(startDate !== null && endDate !== null && !dateError){
      const updatedDateRangeQuery = [startDate !== null ? format(startDate.toDate(), DATE_FORMAT_WITH_SEC) : '', endDate !== null ? format(endDate.toDate(), DATE_FORMAT_WITH_SEC) : ''];
      setAplliedFilters(prev => {
        const filters = [...prev];
        const dateFilterIndex = filters.findIndex(item => item.keyName === ADVANCE_FILTER_KEYNAMES.DATE_RANGE);
        if(dateFilterIndex !== -1){
          filters[dateFilterIndex].query = updatedDateRangeQuery;
        }else{
          filters.push({query: updatedDateRangeQuery, displayName: t('Date Range'), keyName: ADVANCE_FILTER_KEYNAMES.DATE_RANGE});
        }
        return filters;
      });
    }
    if(alertActivityFilter){
      setAplliedFilters(prev => {
        const temp = [...prev];
        const alertActivityFilterIndex = temp.findIndex(item  => item.keyName === ADVANCE_FILTER_KEYNAMES.ALERT_STATUS);
        if(alertActivityFilterIndex !== -1){
          temp[alertActivityFilterIndex].query = alertActivityFilter;
        }else{
          temp.push({displayName: t('Alert Status'), keyName: ADVANCE_FILTER_KEYNAMES.ALERT_STATUS, query: alertActivityFilter});
        }
        return temp;
      });
    }
    if(numberPlateFilter) {
      setAplliedFilters(prev => {
        const filters = [...prev];
        const numberPlateIndex = filters.findIndex(item  => item.keyName === ADVANCE_FILTER_KEYNAMES.NUMBER_PLATE);
        if(numberPlateIndex !== -1 && !showNumberPlate){  
          filters.splice(numberPlateIndex, 1);
        }else if(numberPlateIndex !== -1){
          filters[numberPlateIndex].query = showNumberPlate;
        }else{
          if(showNumberPlate) {
            filters.push({displayName: t('Has Vehicle Number'), keyName: ADVANCE_FILTER_KEYNAMES.NUMBER_PLATE, query: showNumberPlate});
          }
        }
        return filters;
      });
    }
    setAdvanceFilterOpen(false);
  },[checkAlgorithmList, startDate, endDate, t, alertActivityFilter, dateError, showNumberPlate, numberPlateFilter]);

  const handleRemoveFilter = useCallback((index: number, keyName: string)=>{
    setAplliedFilters(prev => {
      let filterArray = [...prev];
      filterArray = [...filterArray.slice(0, index), ...filterArray.slice(index + 1)];
      return filterArray;
    });
    if(keyName === ADVANCE_FILTER_KEYNAMES.ALERT_STATUS){
      setAlertActivityFilter('');
    }
    if(keyName === ADVANCE_FILTER_KEYNAMES.ANALYSIS){
      setCheckedAlgorithmList([]);
    }
    if(keyName === ADVANCE_FILTER_KEYNAMES.DATE_RANGE){
      setStartDate(null);
      setEndDate(null);
    }
    if(keyName === ADVANCE_FILTER_KEYNAMES.NUMBER_PLATE){
      setShowNumberPlate(false);
    }
    if(enableMultiSelect) {
      setCheckedSearchQueries([]);
      setCheckedSearchQueriesDisplayName([]);
    }
  },[enableMultiSelect]);

  const handleClearFilter = useCallback(()=>{
    setAplliedFilters([]);
    setCheckedAlgorithmList([]);
    setStartDate(null);
    setEndDate(null);
    setAlertActivityFilter('');
    setShowNumberPlate(false);
  },[]);

  const handleEnterKeyPress = useCallback((event: { key: string; }) => {
    if (event.key === 'Enter') {
      handleSelectedQuery(searchQueryKeys[0]);
    }
  },[handleSelectedQuery, searchQueryKeys]);

  useEffect(() => {
    document.addEventListener('keydown', handleEnterKeyPress);
    return () => {
      document.removeEventListener('keydown', handleEnterKeyPress);
    };
  }, [searchQueryKeys, handleEnterKeyPress]);

  useEffect(() => {
    if(advanceFilterRef.current){
      const rect = advanceFilterRef.current.getBoundingClientRect();
      //Render Filter dropdown on top if the enough space is not available in below.
      if(window.innerHeight - rect.top < advanceFilterRef.current.clientHeight){
        advanceFilterRef.current.style.top = 'auto';
        advanceFilterRef.current.style.bottom = 'calc(100% + 5px)';
      }
      //Render Filter dropdown on left side if the enough space is not available on the right side
      if(window.innerWidth - rect.left < advanceFilterRef.current.clientWidth){
        advanceFilterRef.current.style.left = 'auto';
        advanceFilterRef.current.style.right = '0px';
      }
    }
  },[advanceFilterOpen]);

  useEffect(() =>{
    if(showCameraSearchSuggestions && cameraSuggestionList?.length !==0){
      const filteredList = cameraSuggestionList?.filter(item => item.name.toLowerCase().includes(searchQuery.toLowerCase()));
      setFilterSuggestionList(filteredList); 
    }
  },[cameraSuggestionList, searchQuery, showCameraSearchSuggestions]);

  return (
    <Container ref={drawerRef}>
      <Wrapper width={enableInputFilter ? '100%' : 'fit-content'}>
        {enableAdvancedFilter && 
        <FilterContainer hasBorder={enableInputFilter} onClick={() => {setAdvanceFilterOpen(!advanceFilterOpen); setSearchDrawerOpen(false);}}>
          <BsFilter title={('Advanced Filter')} />
          {!enableInputFilter && <span>{t('Filter')}</span>}
          {appliedFilters.length > 0 && !enableInputFilter && <FilterIndicator>{appliedFilters.length}</FilterIndicator> }
        </FilterContainer>}
        {enableInputFilter &&<SearchContainer>
          <MdOutlineManageSearch size={20} color='#bfbfbf' />
          <Input type="text" value={searchQuery} placeholder={placeholder} onFocus={()=> {setSearchDrawerOpen(true); setAdvanceFilterOpen(false); setSuggestionDrawerOpen(true);}} onChange={(e) => {setSearchQuery(e.target.value);}} />
        </SearchContainer>}
      </Wrapper>
      {appliedFilters.length > 0 && enableInputFilter &&
      <AppliedFiltersContainer>
        {appliedFilters?.map((filter, index) => (
          <AppliedFiltersDiv>
            {!enableMultiSelect ? 
              <div>{t(filter.displayName)}</div> 
              : <div>{checkedSearchQueriesDisplayName?.length !== 0 
                ? checkedSearchQueriesDisplayName.map((item) => <span>{t(item)}, </span>): 
                <div>{t(filter.displayName)}</div>}</div>}{typeof filter.query !== 'boolean' && ':'} <AlgorithmNames style={{fontWeight: '600'}}>{
              typeof filter.query === 'string' ? 
                <>{filter.query?.length > 0 ? 
                  <AlgoName title={filter.query} hasBackground>{t(filter.query)}</AlgoName> 
                  : null}</> : filter.keyName === ADVANCE_FILTER_KEYNAMES.DATE_RANGE ? (filter.query as string[]).map((item, index) => (
                  <span key={index}>
                    {format(new Date(item), DATE_TIME_FORMAT)}
                    {index === 0 ? <AddSpace> -</AddSpace>: ''}
                  </span>   
                )) : typeof filter.query === 'boolean' ? <> </> : 
                  filter.query.map((item) => (<AlgoName title={t(getAlgorithmName(item.toString()) as string)} hasBackground>{ filter.keyName === ADVANCE_FILTER_KEYNAMES.ANALYSIS ? t(getAlgorithmName(item.toString()) as string) 
                    : <>{item}</>}</AlgoName>))}</AlgorithmNames>
            <div onClick={()=>handleRemoveFilter(index, (filter?.keyName) as string)} style={{cursor: 'pointer'}}><Icon icon='Cross' size={20}/></div>
          </AppliedFiltersDiv>
        ))}
      </AppliedFiltersContainer>}
      {searchDrawerOpen && searchQueryKeys.length > 1 &&
      <SearchDrawer>
        {
          searchQueryKeys.map((queryKey, index: number)=>(
            <SearchQueryWrapper>
              {enableMultiSelect && <Checkbox checked={checkedSearchQueries.includes(queryKey.keyName as string)} onChangeCallback={() => handleMultiSelectFilter(queryKey.keyName, queryKey.displayName)}/>}
              <SearchFields key={index} onClick={()=>handleSelectedQuery(queryKey)}>{queryKey.displayName}: <div style={{fontWeight: '600'}}>{searchQuery}</div></SearchFields>
            </SearchQueryWrapper>
          ))
        }
      </SearchDrawer>}
      {showCameraSearchSuggestions && suggestionDrawerOpen && searchQuery && filterSuggestionList.length > 0 &&
      <SearchDrawer>
        {
          filterSuggestionList.map((suggestionList, index: number)=>(
            <SearchQueryWrapper>
              <SearchFields key={index} onClick={()=>handleSelectedCameraQuery(suggestionList.name)}> <div style={{fontWeight: '600'}}>{suggestionList.name}</div></SearchFields>
            </SearchQueryWrapper>
          ))
        }
      </SearchDrawer>}
      {advanceFilterOpen && 
        <AdvancedFilterContainer ref={advanceFilterRef}>
          <IconDiv onClick={() => setAdvanceFilterOpen(false)}>
            <Icon icon='Close' size={16}/>
          </IconDiv>
          <AdvanceFilterDrawer>
            {showAlgorithm && 
              <FilterContainerWrapper>
                <Label isBold>{t('Analysis')}</Label>
                <CheckboxContainer isDateRange={showDateRange}>
                  {
                    algorithmList?.length > 0 ? algorithmList.filter(algorithm => algorithm.code !== ALGORITHM_CODES.traffic_pulse_detection).map((algorithm)=>{
                      const isChecked = checkAlgorithmList.includes(algorithm.code);
                      return (
                        <AlgoCheckBoxContainer title={algorithm.name} isChecked={isChecked} onClick={()=>handleCheckboxChange(algorithm.code, isChecked)}>
                          {getAlgoIcon(algorithm.code, false, 26, true)}
                          <FilterAlgoNameWrapper>{t(algorithm.name)}</FilterAlgoNameWrapper>
                        </AlgoCheckBoxContainer>
                      );
                    }) : <NotFoundDiv>{t('Algorithms not found')}</NotFoundDiv>
                  }
                </CheckboxContainer>
              </FilterContainerWrapper>
            }
            {showAlertActivityFilter &&
              <AlertFilterContainer>
                <Label isBold>{t('Alert Status')}</Label>
                <>
                  <RadioButton 
                    id='activeAlerts'
                    value='activeAlerts'
                    label={t('Active')} 
                    name='activeAlerts'
                    checked={alertActivityFilter === ALERT_STATUS.ACTIVE}
                    onChangeCallback={() => {setAlertActivityFilter(ALERT_STATUS.ACTIVE);}} 
                    currentChecked={alertActivityFilter}
                  />
                  <RadioButton 
                    id='inActive'
                    value='inActive'
                    label={t('Inactive')} 
                    name='inActive'
                    checked={alertActivityFilter === ALERT_STATUS.INACTIVE}
                    onChangeCallback={() => {setAlertActivityFilter(ALERT_STATUS.INACTIVE);}}
                    currentChecked={alertActivityFilter}
                  />
                </>
              </AlertFilterContainer>

            }
            {showDateRange && 
              <DateRangeFilterContainer>
                <Label isBold>{t('Date Range')}</Label>
                <DateRangeContainer>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePickersWrappers>
                      <DemoContainer components={['DateTimePicker']}>
                        <DateTimePicker
                          label={t('From Date')}
                          value={startDate}
                          defaultValue={null}
                          disableFuture
                          onError={(err) => setDateError(!!err)}
                          format={DATE_PICKER_DATE_TIME_FORMAT}
                          onChange={(newValue) => {setStartDate(newValue);}}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                        />
                        <DateTimePicker
                          label={t('To Date')}
                          value={endDate}
                          disableFuture
                          onError={(err) => setDateError(!!err)}
                          minDateTime={startDate}
                          format={DATE_PICKER_DATE_TIME_FORMAT}
                          onChange={(newValue) => setEndDate(newValue)}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                        />
                      </DemoContainer>
                    </DatePickersWrappers>
                  </LocalizationProvider>
                </DateRangeContainer>
                {startDate !== null && endDate!== null  && (startDate > endDate) && <ErrorMessage>{t('To Date must be greater than From Date')}</ErrorMessage>} 
                {
                  numberPlateFilter && 
                  <CheckBoxWrapper>
                    <CheckboxInput
                      type='checkbox'
                      checked={showNumberPlate}
                      onChange={(e) => setShowNumberPlate(e.target.checked)}
                    />
                    <Label>{t('Has Number Plate')}</Label>
                  </CheckBoxWrapper>
                }
              </DateRangeFilterContainer>
            } 
            
          </AdvanceFilterDrawer>
          <ButtonContainer>
            <Button variant='primary' onClick={handleApplyFilter} disabled={(startDate !== null && endDate!== null  && (startDate > endDate))}>{t('Apply')}</Button>
            <Button variant='secondary' disabled={appliedFilters.length === 0} onClick={handleClearFilter}>{t('Clear')}</Button>
          </ButtonContainer>
        </AdvancedFilterContainer>
      }
    </Container>
  );
};

export default SearchInputFilter;