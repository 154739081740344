import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgAlert = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M12 4a6.504 6.504 0 0 1 4.44 1.727 6.043 6.043 0 0 1 1.94 4.219l.003.205v3.36l1.175 2.588a.99.99 0 0 1-.085.972 1.05 1.05 0 0 1-.384.34c-.155.08-.33.123-.506.123l-4.03.003c.001.64-.257 1.256-.72 1.715a2.599 2.599 0 0 1-1.758.747 2.61 2.61 0 0 1-1.8-.645 2.425 2.425 0 0 1-.824-1.672l-.004-.148H5.425c-.176 0-.35-.043-.505-.123a1.052 1.052 0 0 1-.383-.34 1.002 1.002 0 0 1-.088-.97l1.168-2.59v-3.36C5.617 6.743 8.47 4 12 4Zm1.277 13.535-2.554.002c0 .316.127.62.353.848.226.229.535.365.862.38.328.016.649-.091.897-.298.248-.206.404-.497.436-.812l.006-.12ZM12 5.23c-2.826 0-5.106 2.193-5.106 4.92v3.617L5.75 16.305h12.508l-1.151-2.537v-3.607l-.004-.184a4.83 4.83 0 0 0-1.554-3.37A5.2 5.2 0 0 0 12 5.23Zm7.66 3.899h1.702c.162 0 .317.06.436.166a.6.6 0 0 1 .06.838.645.645 0 0 1-.41.22l-.086.006H19.66a.651.651 0 0 1-.436-.166.6.6 0 0 1-.06-.838c.103-.12.249-.2.409-.22l.087-.006Zm-17.022 0H4.34c.162 0 .318.06.436.166a.6.6 0 0 1 .06.838.645.645 0 0 1-.409.22l-.087.006H2.638a.651.651 0 0 1-.435-.166.6.6 0 0 1-.06-.838c.102-.12.248-.2.408-.22l.087-.006Zm18.383-4.675a.6.6 0 0 1-.056.802l-.071.06-1.702 1.23a.656.656 0 0 1-.865-.087.601.601 0 0 1 .027-.838l.072-.06 1.702-1.23a.657.657 0 0 1 .893.123ZM3.872 4.331l1.702 1.23a.623.623 0 0 1 .25.406.593.593 0 0 1-.122.456.63.63 0 0 1-.42.24.662.662 0 0 1-.474-.117l-1.702-1.23a.623.623 0 0 1-.249-.406.593.593 0 0 1 .122-.456.63.63 0 0 1 .42-.24.662.662 0 0 1 .473.117Z"
        fill={props.color}
      />
    </svg>
  );
};
export default SvgAlert;
