import { IBreadcrum, ICameraInfo } from 'interface';
import React, { createContext, useContext, ReactNode, useState, useCallback } from 'react';

type HeaderContextType = {
  headerTitle: string;
  headerIcon: string | ReactNode;
  updateHeaderTitle: (title?: string, headerIcon?: string | ReactNode, breadCrumbs?: IBreadcrum[], statusColor?: string, cameraInfo?: ICameraInfo) => void;
  breadCrumbs: IBreadcrum[]; 
  statusColor: string;
  cameraInfo: ICameraInfo
};

const HeaderContext = createContext<HeaderContextType | undefined>(undefined);

type HeaderProviderProps = {
  children: ReactNode;
};

export const HeaderProvider = ({ children }: HeaderProviderProps) => {
  const [headerTitle, setHeaderTitle] = useState<string>('');
  const [headerIcon, setHeaderIcon] = useState<string | ReactNode>('');
  const [breadCrumbs, setBreadCrumbs] = useState<IBreadcrum[]>([]);
  const [statusColor, setStausColor] = useState<string>('');
  const [cameraInfo, setCameraInfo] = useState<ICameraInfo>({instanceId:'', streamType: ''});
 
  const updateHeaderTitle = useCallback((title?: string, headerIcon?: string | ReactNode, breadCrumb?: IBreadcrum[], statusColor?: string, cameraInfo?: ICameraInfo ) => {
    setHeaderTitle(title as string);
    setHeaderIcon(headerIcon as string);
    setBreadCrumbs(breadCrumb as IBreadcrum[]);
    setStausColor(statusColor as string);
    setCameraInfo(cameraInfo as ICameraInfo);
  },[]);

  return (
    <HeaderContext.Provider value={{ headerTitle, updateHeaderTitle, headerIcon, breadCrumbs, statusColor, cameraInfo }}>
      {children}
    </HeaderContext.Provider>
  );
};

export const useHeader = () => {
  const context = useContext(HeaderContext);
  if (context === undefined) {
    throw new Error('useHeader must be used within a HeaderProvider');
  }
  return context;
};
