import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgCross = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M6 18 19 5M6 5l13 13"
        stroke={props.color}
        strokeWidth={props.weight}
        strokeLinecap="round"
        vectorEffect={"non-scaling-stroke"}
      />
    </svg>
  );
};
export default SvgCross;
