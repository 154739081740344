import React from 'react';
import styled from 'styled-components';

const LabelWrapper = styled.label`
  text-align: left;
  margin-bottom: 8px;
  height: 25px;
  width: fit-content;
`;

const CompulsoryIndicator = styled.span`
  color: red;
`;

interface ILabel {
    labelText: string,
    isMandatory?: boolean
}

const Label = ({isMandatory, labelText}: ILabel) => {
  return (
    <LabelWrapper>
      {labelText}
      {isMandatory && <CompulsoryIndicator> * </CompulsoryIndicator>}
    </LabelWrapper>
  );
};

export default Label;