import Input from 'components/Input';
import RadioButton from 'components/RadioButton';
import { RTMP_SERVER_TYPES, RTMP_SERVER_URL_TYPES, RTMP_INTERNAL_SERVER_URL } from '../../constants';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {GET_DEVICE_INFO} from '../../api_configs/queries';
import { useQuery } from '@apollo/client';
import Label from 'components/Label';
import { CircularProgress } from '@mui/material';
import MdnsIpInput from 'components/MdnsIpInput';

const VideoConfigurationsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  position: relative;
`;

const VerticalLine = styled.div`
  width: 980px;
  height: 10px;
  border-bottom: 1px solid lightgrey;
  margin-top: 2px;
  margin-bottom: 15px;
  margin-left: 18px;
  @media (max-width: 768px) {
    width: 450px;
  }
`;

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Heading = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-left: 18px;
`;

const SelectedCameraVideoConfigurations = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`;

const LeftSideWrapper = styled.div`
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const LabelWrapper = styled.div`
  display: block;
`;

const ToggleWrapper = styled.div`
  position: absolute;
  left: 16px;
  top: 20px;
  cursor: pointer;
  z-index: 8;
`;

const RadioWrapper = styled.div`
  margin-bottom: 25px;
  display: flex;
  gap: 30px;
`;

const SpinnerBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
  position: absolute;
  left: 25px;
  top: 10px;
  margin-top: 30px;
  > span {
    margin-top: 10px;
  }
`;

const RTMPStreamKeyRadioWrapper = styled.div`
  margin-top: -11px;
  margin-bottom: -14px;
  display: flex;
  gap: 30px;
`;

interface IRTMPData {
  rtmpServerType: string,
  rtmpSourceUrl: string,
  rtmpStreamKeyType: string,
  rtmpStreamKey: string,
  rtmpUrlType: string
}

interface IRTMPCamera {
  rtmpData: IRTMPData
  setRtmpData: React.Dispatch<React.SetStateAction<IRTMPData>>
  cameraName: string,
  isEditCamera: boolean,
  isEditAccess?: boolean,
  serverId?: number | null
}

const RTMPCamera = React.memo(({rtmpData, serverId, setRtmpData, cameraName, isEditCamera, isEditAccess=false}: IRTMPCamera) => {
  const {t} = useTranslation(['common']);
  const {data, loading} = useQuery(GET_DEVICE_INFO, {variables: {serverId: serverId}});
  const [recallApi, setRecallApi] = useState(false);
  const {rtmpUrlType, rtmpServerType} = rtmpData;

  useEffect(() => {
    if(data?.getDeviceInfo?.result) {
      if(rtmpServerType === 'external' && !isEditCamera) {
        setRtmpData((prevRtmpData) => ({
          ...prevRtmpData,
          rtmpSourceUrl: 'rtmp://',
        }));
        return;
      }
      if((rtmpUrlType === RTMP_SERVER_URL_TYPES.mDNS && data?.getDeviceInfo?.result?.rtmpServerType === 'internal') || (!isEditCamera && rtmpUrlType === 'mDNS')){
        setRtmpData((prevRtmpData) => ({
          ...prevRtmpData,
          rtmpSourceUrl: `${RTMP_INTERNAL_SERVER_URL.replace('{X}', data.getDeviceInfo.result.deviceId)}`,
        }));
      }else if((rtmpUrlType === RTMP_SERVER_URL_TYPES.IP && data?.getDeviceInfo?.result?.rtmpServerType === 'internal') || !isEditCamera){
        setRtmpData((prevRtmpData) => ({
          ...prevRtmpData,
          rtmpSourceUrl: `${RTMP_INTERNAL_SERVER_URL.replace('{X}', data.getDeviceInfo.result.networks[0].ipAddress.split('/')[0])}`,
        }));
      }
    }
  }, [data, setRtmpData, recallApi, rtmpUrlType, rtmpServerType, isEditCamera]);

  const handleStreamNameChange = (value: string) => {
    setRtmpData({...rtmpData, rtmpStreamKeyType: value});
    if(value === 'default_rtmp_stream_key'){
      setRtmpData({...rtmpData, rtmpStreamKeyType: value, rtmpStreamKey: cameraName});
    }else{
      setRtmpData({...rtmpData,rtmpStreamKeyType: value, rtmpStreamKey: ''});
    }
  };

  return ( 
    <VideoConfigurationsWrapper>
      <HeadingWrapper>
        <Heading>{t('Video Configurations')}</Heading>
      </HeadingWrapper>
      <VerticalLine /> 
      {
        loading ? 
          <SpinnerBox>
            <CircularProgress size={20} />
            <Label labelText={t('Loading...')}/>
          </SpinnerBox> :
          <SelectedCameraVideoConfigurations>
            <LeftSideWrapper>
              <LabelWrapper>
                <Label labelText={t('Server Type')} isMandatory />
              </LabelWrapper>
              <RadioWrapper>      
                <RadioButton
                  id='internal'
                  value={RTMP_SERVER_TYPES.internal}
                  label={t('Internal Server')}
                  name='servertype'
                  checked={rtmpData.rtmpServerType === RTMP_SERVER_TYPES.internal}
                  currentChecked={rtmpData.rtmpServerType}
                  onChangeCallback={(value) => { setRtmpData({...rtmpData, rtmpSourceUrl: `rtmp://${data.getDeviceInfo.result.deviceId}.local:1935/live`, rtmpServerType: value, rtmpUrlType: RTMP_SERVER_URL_TYPES.mDNS});setRecallApi(!recallApi);}}
                  isRequired
                  disabled={isEditAccess}
                />
                <RadioButton
                  id='external'
                  value={RTMP_SERVER_TYPES.external}
                  label={t('External Server')}
                  name="servertype"
                  checked={rtmpData.rtmpServerType === RTMP_SERVER_TYPES.external}
                  currentChecked={rtmpData.rtmpServerType}
                  onChangeCallback={(value) => setRtmpData({...rtmpData, rtmpSourceUrl: 'rtmp://', rtmpServerType: value})}
                  isRequired
                  disabled={isEditAccess}
                />
              </RadioWrapper>
              <Label labelText= {t('Stream Name/Key')}/>
              <RTMPStreamKeyRadioWrapper>      
                <RadioButton
                  id='default'
                  value='default_rtmp_stream_key'
                  label={t('Same as Camera Name')}
                  name='streamkey'
                  checked={rtmpData.rtmpStreamKeyType === 'default_rtmp_stream_key'}
                  currentChecked={rtmpData.rtmpServerType}
                  onChangeCallback={(value) => handleStreamNameChange(value)}
                  isRequired
                  disabled={isEditAccess}
                />
                <RadioButton
                  id='custom'
                  value='custom_rtmp_stream_key'
                  label={t('Custom')}
                  name='streamkey'
                  checked={rtmpData.rtmpStreamKeyType === 'custom_rtmp_stream_key'}
                  currentChecked={rtmpData.rtmpServerType}
                  onChangeCallback={(value) => handleStreamNameChange(value)}
                  isRequired
                  disabled={isEditAccess}
                />
              </RTMPStreamKeyRadioWrapper>
              <Input label='' id='customRtmpUrl' type='text' disabled={rtmpData.rtmpStreamKeyType === 'default_rtmp_stream_key' || isEditAccess} value={  rtmpData.rtmpStreamKey } onChange={(e) => setRtmpData({...rtmpData, rtmpStreamKey: e.target.value})} />
            </LeftSideWrapper>
            <LeftSideWrapper>
              <ToggleWrapper>
                <MdnsIpInput disabled={isEditAccess} isToggleDisabled={true} customInputValue={rtmpData} onInputChange={setRtmpData} isMdnsIpToggleDisabled={rtmpData.rtmpServerType === 'external'} name={t('Server URL')} defaultValue={true} mdnsValue={rtmpData.rtmpServerType === 'internal' ? `${RTMP_INTERNAL_SERVER_URL.replace('{X}', data?.getDeviceInfo?.result?.deviceId)}`: rtmpData.rtmpSourceUrl} ipValue={`${RTMP_INTERNAL_SERVER_URL.replace('{X}', data?.getDeviceInfo?.result?.networks[0].ipAddress.split('/')[0])}`} />
              </ToggleWrapper>
            </LeftSideWrapper>
          </SelectedCameraVideoConfigurations>
      }
    </VideoConfigurationsWrapper>
  );
});

export default RTMPCamera;