import React, {useState, useRef, useMemo, useEffect} from 'react';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import Leaflet from 'leaflet';
import { IconType, LEAFLET_ONLINE_URL, LEAFLET_TILE_URL, MAP_CENTER, StatusCode } from '../../constants';
import styled from 'styled-components';
import MapMarker from '../../svgs/camera.svg';
import { Icon, LatLngExpression } from 'leaflet';
import Switch from 'components/Switch';
import Label from 'components/Label';
import Checkbox from 'components/Checkbox';
import Input from 'components/Input';
import Button from 'components/Button';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/client';
import { UPDATE_GEO_LOCATION } from 'api_configs/mutations';
import { IAlertMessageType } from 'interface';
import AlertBar from 'components/AlertBar';
import { GET_ALL_STREAMS, GET_STREAM } from 'api_configs/queries';
import { useAnnotation } from 'contexts/AnnotaionContext';
import TextOnlyConfirmationModal from 'components/modals/TextOnlyConfirmationModal';
import Config from '../../configs/config.json';
import ZoneMap from 'components/mapview/ZoneMap';
import Modal from 'components/Modal';
import Icons from 'components/Icon';
import { CircularProgress } from '@mui/material';
import ButtonWithLoading from 'components/ButtonWithLoading';

const Container = styled.div`
  display: flex;
  @media only screen and (min-width: 1270px){
    gap: 40px;
  }
  @media only screen and (min-width: 1400px){
    gap: 5px;
  }
  width: 100%;
`;

const GeoLocationOptionsWrapper = styled.div`
  margin-top: 8px;
  width: 25%;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const SpinnerBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 89vh;
  align-items: center;
  justify-content: center;
`;

const EnableGeoLocationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 910px !important;
  gap: 15px;
`;

const MoveMapToCenter = styled.div`
  display: flex;
  margin-bottom: 8px;
  gap: 8px;
  > :nth-child(2) {
    margin-top: 11px;
  }
`;

const CoordinatesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const ZoneMapWrapper = styled.div`
  position: relative;
  height: 420px;
  width: 900px;
  >div{
    z-index: 1;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 15px;
  margin-top: 10px;
`;

const LocationNameWrapper = styled.div`
  display: flex;
  margin-top: 8px;
  > input {
    @media only screen and (min-width: 1270px){
      width: 350px;
    }
  }
`;

const ActionIcon = styled.div<{isZoneMap: boolean}>`
  position: absolute;
  cursor: pointer;
  width: 36px;
  height: 36px;
  padding: 6px;
  background-color: ${({theme}) => theme.backgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 2px solid ${({theme}) => theme.divider};
  top: 10px;
  right: ${({isZoneMap}) => isZoneMap ? '10px' : '48px' } ;
  z-index: 1000;
  :hover{
    background-color: #F5F6FA;
  }
`;

const ModalMapViewContainer = styled.div`
  border-radius: 6px;
  display: flex ;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  margin-top: 10px;
  height: calc(100vh - 130px);
  width: 1180px;
  gap: 10px;
  >div > div :nth-child(4) > div {
    background-color: transparent;
    border: none;
  } 
  @media only screen and (min-width: 1366px){
    width: 1182px;
  }
  @media only screen and (min-width: 1440px){
    width: 1250px;
  }
  @media only screen and (min-width: 1600px){
    width: 1390px;
  }
  @media only screen and (min-width: 1680px){
    width: 1470px;
  }
  @media only screen and (min-width: 1920px){
    width: 1685px;
  }
  @media only screen and (min-width: 2560px){
    width: 2260px;
  }
  @media only screen and (min-width: 3840px){
    width: 3416px;
  }
`;

const MapWrapper = styled.div`
  height: 65vh;
  width: 100%;
  >div{
    z-index: 1;
  }
`;

interface IRecenterAutomatically {
  lat: number, 
  lng: number
}
  
const RecenterAutomatically = ({lat, lng}: IRecenterAutomatically) => {
  const map = useMap();
  useEffect(() => {
    map.setView([lat, lng]);
  }, [lat, lng, map]);
  return null;
};

interface IGeoLocation {
  instanceId: string
}

interface ILocationData {
  enabledLocation: boolean,
  latitude: string,
  longitude: string,
  locationName: string
}

const GeoLocation = ({instanceId}: IGeoLocation) => {
  const [locationData, setLocationData] = useState<ILocationData>({enabledLocation: false, latitude: '18.55233', longitude: '73.89739', locationName: ''});
  const [isMoveMapToCenter, setIsMoveMapToCenter] = useState(true);
  const [streamType, setStreamType] = useState<string>('');
  const [prevData, setPrevData] = useState();
  const {data, loading: getStreamLoading} = useQuery(GET_STREAM, { variables: {streamId: instanceId} });
  const history = useHistory();
  const {t} = useTranslation(['common']);
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: ''});  
  const [updateGeoLocation, {loading}] = useMutation(UPDATE_GEO_LOCATION, {
    refetchQueries: [{ query: GET_ALL_STREAMS }],
  });
  const markerRef = useRef<Leaflet.Marker | null>(null);
  const [validateEmptyValuesModal, setValidateEmptyValuesModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [locationDataChanged, setLocationDataChanged] = useState(false);
  const {isOfflineMap} = useAnnotation();
  const [isMapModalOpen,setIsMapModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if(!getStreamLoading) {
      if(data?.getStream.result.location){
        setLocationData(data?.getStream.result.location);
        setPrevData(data?.getStream.result.location);
        setStreamType(data?.getStream.result.streamType);
      }
    }
  }, [getStreamLoading, data]);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {         
          const lat = marker.getLatLng().lat.toFixed(8);
          const lng = marker.getLatLng().lng.toFixed(8);
          setLocationData(prev => ({...prev, latitude: lat, longitude: lng}));
          setLocationDataChanged(true);
        }
      },
    }),
    [],
  );

  const customIcon = new Icon({
    iconUrl: MapMarker,
    iconSize: [48, 48]
  });

  const handleGeoLocationEnabledChange = () => {
    setLocationData({...locationData, enabledLocation: !locationData.enabledLocation});
    if(!locationData.enabledLocation) {
      setIsMoveMapToCenter(false);
    }
  };

  const handleGeoLocationUpdate = async () => {
    const updateGeoLocationPayload = {streamId: instanceId, location: {enabledLocation: locationData.enabledLocation, locationName: locationData.locationName, latitude: (locationData.latitude).toString(), longitude: (locationData.longitude).toString()}};
    try{
      const data = await updateGeoLocation({variables: {payload: updateGeoLocationPayload}});
      if(data && data?.data?.updateGeolocation?.statusCode === StatusCode.SUCCESS){
        setMessage({text: t('Geolocation saved successfully'), id:'',type:'success'});
      }else{
        setMessage({text: t('apiError'), id:'',type:'danger'});
      }
    }catch(e){
      setMessage({text: t('apiError'), id:'',type:'danger'});
      console.error(e);
    }
  };

  const checkIsEmpty = () => {
    if(locationData.latitude === '' || locationData.longitude === '') {
      setValidateEmptyValuesModal(true);
    }else {
      handleGeoLocationUpdate();
    }
  };

  const handleGeoLocationLatitudeChange = (e: React. ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value) || value === '') {
      setLocationData({...locationData, latitude: value});
      setLocationDataChanged(true);
    }  
  };

  const handleGeoLocationLongitudeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value) || value === '') {
      setLocationData({...locationData, longitude: value});
      setLocationDataChanged(true);
    }
  };

  const handleCancel = () => {
    history.push(`/cameras/${instanceId}/configurations?selectedTab=analysis&camera-name=${instanceId}&camera-type=${streamType}`);
  };

  return (
    <>
      { isMapModalOpen &&
      <Modal isModalOpen={isMapModalOpen} closeModal={() => setIsMapModalOpen(false)} width='100vw'>
        <ModalMapViewContainer>
          <ZoneMap defaultZoom={0.9} height= '500px' width='1170px' instanceId={instanceId} isZoneMapTab={true} />
        </ModalMapViewContainer>
      </Modal>
      }
      {Config.isZoneMap ? 
        <ZoneMapWrapper>
          <ActionIcon onClick={()=>setIsMapModalOpen(true)} isZoneMap={Config.isZoneMap}>
            <Icons icon='Expand' color='mono' size={24} />
          </ActionIcon><ZoneMap defaultZoom={0.9} height= '410px' width='890px' instanceId={instanceId} isZoneMapTab={true} /> 
        </ZoneMapWrapper>:
        !getStreamLoading && !Config.isZoneMap ? <Container>
          {
            message && <AlertBar message={message.text} type={message.type as IconType} setMessage={setMessage} />
          }
          <TextOnlyConfirmationModal titleText={t('You made some changes, these changes will be discarded. Are you sure?')} confirmDescription='' modalOpen={modalOpen} setModalOpen={setModalOpen} onYes={handleCancel} />
          <TextOnlyConfirmationModal titleText={t('Latitude and Longitude are empty. Do you really want to make these changes?')} confirmDescription='' modalOpen={validateEmptyValuesModal} setModalOpen={setValidateEmptyValuesModal} onYes={handleGeoLocationUpdate} />
          <GeoLocationOptionsWrapper>
            <EnableGeoLocationWrapper>
              <Wrapper>
                <Label labelText={locationData.enabledLocation ? t('Enabled'): t('Disabled')} />
                <Switch checked={locationData.enabledLocation} onChange={() => handleGeoLocationEnabledChange()} />
              </Wrapper>  
            </EnableGeoLocationWrapper>
            <MoveMapToCenter>
              <Checkbox checked={isMoveMapToCenter} disabled={!locationData.enabledLocation} onChangeCallback={() => {setIsMoveMapToCenter(!isMoveMapToCenter); }}/>
              <Label labelText={t('Move the map to center the stream location')} />
            </MoveMapToCenter>
            <LocationNameWrapper>
              <Input label={t('Location Name')} disabled={!locationData.enabledLocation} id='locationName' type='text' width='300px' maxLength={255} value={locationData.enabledLocation ? locationData.locationName: ''} onChange={(e) => setLocationData({...locationData, locationName: e.target.value})} />
            </LocationNameWrapper>
            <CoordinatesWrapper>
              <Input label={t('Latitude')} id='latitude' type='text' disabled={!locationData.enabledLocation} maxLength={12} value={locationData.enabledLocation ? locationData.latitude: ''} width='300px' onChange={handleGeoLocationLatitudeChange} />
              <Input label={t('Longitude')} id='longitude' type='text' disabled={!locationData.enabledLocation} maxLength={12} value={locationData.enabledLocation ? locationData.longitude: ''} width='300px' onChange={handleGeoLocationLongitudeChange} />
            </CoordinatesWrapper>
            <ButtonsWrapper>
              <ButtonWithLoading disabled={JSON.stringify(locationData) === JSON.stringify(prevData)} loading={loading} variant='primary' onClick={checkIsEmpty}>{t('saveText')}</ButtonWithLoading>
              <Button variant='secondary' onClick={() => {if(locationDataChanged) setModalOpen(true); else history.push(`/cameras/${instanceId}/configurations?selectedTab=analysis&camera-name=${instanceId}&camera-type=${streamType}`);}}>{t('Cancel')}</Button>
            </ButtonsWrapper>
          </GeoLocationOptionsWrapper>
          <MapWrapper>
            <MapContainer center={isMoveMapToCenter ? [Number(locationData.latitude), Number(locationData.longitude)] as LatLngExpression: MAP_CENTER as LatLngExpression}  zoom={13} style={{height: '65vh', width: '90%'}}>
              <TileLayer url={isOfflineMap ? LEAFLET_TILE_URL : LEAFLET_ONLINE_URL} maxZoom={isOfflineMap ? 15 : 20} minZoom={isOfflineMap ? 12 : 3} />
              { locationData.enabledLocation && <Marker draggable eventHandlers={eventHandlers} ref={markerRef} position={[Number(locationData.latitude), Number(locationData.longitude)]} icon={customIcon}></Marker> }
              { isMoveMapToCenter && <RecenterAutomatically lat={Number(locationData?.latitude)} lng={Number(locationData.longitude)} /> }
            </MapContainer>
          </MapWrapper>      
        </Container>: <SpinnerBox>
          <CircularProgress />
          <Label labelText={t('Loading...')}/>
        </SpinnerBox>}
    </>
  );
};

export default GeoLocation;