import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgAddCircle = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M6.103 12a.884.884 0 0 1 .883-.883H11.1V7.002a.884.884 0 0 1 1.767 0v4.115h4.114a.884.884 0 0 1 0 1.766h-4.114v4.114a.883.883 0 1 1-1.767 0v-4.114H6.986A.883.883 0 0 1 6.103 12Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.467 2.305a50.06 50.06 0 0 1 11.032 0c2.152.24 3.889 1.935 4.141 4.099a48.075 48.075 0 0 1 0 11.192c-.253 2.164-1.99 3.858-4.141 4.1a50.06 50.06 0 0 1-11.032 0c-2.152-.242-3.889-1.936-4.14-4.1a48.176 48.176 0 0 1 0-11.192c.251-2.164 1.99-3.859 4.14-4.1ZM17.303 4.06a48.293 48.293 0 0 0-10.64 0A2.928 2.928 0 0 0 4.08 6.61a46.408 46.408 0 0 0 0 10.781 2.928 2.928 0 0 0 2.583 2.55c3.506.39 7.134.39 10.64 0a2.928 2.928 0 0 0 2.582-2.55c.419-3.581.419-7.2 0-10.781a2.928 2.928 0 0 0-2.582-2.548V4.06Z"
        fill={props.color}
      />
    </svg>
  );
};
export default SvgAddCircle;
