import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { CREATE_NEW_GROUP, EDIT_GROUP } from '../../api_configs/mutations';
import { GET_ALL_GROUPS } from '../../api_configs/queries';
import Modal from '../Modal';
import { IconType, PERMISSION_CODE, StatusCode } from '../../constants';
import Input from 'components/Input';
import TextArea from 'components/TextArea';
import Button from 'components/Button';
import ButtonWithLoading from 'components/ButtonWithLoading';
import TokenService from 'api_configs/tokenService';

const PopUpHeading = styled.label`
  font-size: 20px;
  font-weight: 500;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  gap: 10px;
`;

interface AddGroup {
  name:string;
  description:string;
}

interface EditGroup {
  id: number | null;
  name:string;
  description:string;
}

interface CreateEditModalProps{
  modalOpen: boolean,
  setModalOpen: (modalOpen: boolean) => void,
  editGroupDetails : EditGroup,
  isGroupEdit?: boolean,
  setMessage: React.Dispatch<React.SetStateAction<{ text: string; id: string; type: string |IconType }>>
}

const CreateEditGroupModal: React.FC<CreateEditModalProps> = ({isGroupEdit, modalOpen, setModalOpen, editGroupDetails, setMessage}) => {
  const {t} = useTranslation(['common']);
  const [addGroupDetails,setAddGroupDetails] = useState<AddGroup>({name:'',description:''});
  const [editDetails,setEditDetails] = useState<EditGroup>({id: editGroupDetails?.id, name: editGroupDetails?.name, description: editGroupDetails?.description});
  const [initialGroupDetails] = useState<EditGroup>({id: editGroupDetails?.id, name: editGroupDetails?.name, description: editGroupDetails?.description});
  const [editClicked, setEditClicked] = useState<boolean>(false);
  const [createGroupMutation, {loading: addGrpLoading}] = useMutation(CREATE_NEW_GROUP,{
    refetchQueries: [{ query: GET_ALL_GROUPS }],
  });
  const [editGroupMutation, {loading}] = useMutation(EDIT_GROUP,{
    refetchQueries: [{ query: GET_ALL_GROUPS }],
  });
  const username = TokenService?.getUser()?.username;
  const editGroupAccess = TokenService.hasAccess(PERMISSION_CODE.edit_group);

  const handleSubmit = async () => {
    if(isGroupEdit){
      const data = await editGroupMutation({variables:{payload:{id: editDetails.id, groupName: editDetails.name, description: editDetails.description}}});
      if(data?.data?.editGroup?.statusCode === StatusCode.SUCCESS){
        setMessage({text: t('Group updated successfully'), id:'',type:'success'});
      }else if(data?.data?.createGroup?.statusCode === StatusCode.DUPLICATE_ENTRY){
        setMessage({text: t('Group name already exists.'), id:'',type:'danger'});
      }else{
        setMessage({text: t('Some error occurred!'), id:'',type:'danger'});
      }
      setModalOpen(false);
    }else{
      const data = await createGroupMutation({variables:{input: {groupName: addGroupDetails.name, description: addGroupDetails.description, username: username}}});
      if(data?.data?.createGroup?.statusCode === StatusCode.SUCCESS){
        setMessage({text: t('Group created successfully'), id:'',type:'success'});
      }else if(data?.data?.createGroup?.statusCode === StatusCode.DUPLICATE_ENTRY){
        setMessage({text: t('Group name already exists.'), id:'',type:'danger'});
      }else{
        setMessage({text: t('Some error occurred!'), id:'',type:'danger'});
      }
      setModalOpen(false);
    }
  };
  
  const handleChange = useCallback((e:React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    if(isGroupEdit){
      setEditDetails({...editDetails, id: editGroupDetails.id, [e.target.name]: e.target.value} );
    }
    else{
      setAddGroupDetails({...addGroupDetails, [e.target.name]:e.target.value});
    }
  },[editDetails,addGroupDetails,isGroupEdit,editGroupDetails]);
 
  return (
    <>
      <Modal isModalOpen={modalOpen} closeModal={setModalOpen} width='500px'>
        <ModalContainer>
          <PopUpHeading>{isGroupEdit ? t('editGroupText') : t('addNewGroup')}</PopUpHeading>
          <Input autoFocus disabled={!editClicked && isGroupEdit} maxLength={32} id='name' type='text' name='name' label={t('Name')} isRequired={true} value={isGroupEdit ? editDetails?.name : addGroupDetails.name} onChange={handleChange} width='100%' />
          <TextArea disabled={!editClicked && isGroupEdit} maxLength={255} name='description' label={t('description')} rows={10} cols={10} height='140px' width='100%' value={isGroupEdit ? editDetails?.description : addGroupDetails.description} onChange={handleChange} />
          <ButtonsWrapper>
            {(editClicked || !isGroupEdit) && <ButtonWithLoading loading={loading || addGrpLoading} disabled={addGroupDetails.name.length === 0  && (!editGroupAccess || editGroupDetails.name.length === 0 || JSON.stringify(initialGroupDetails)===JSON.stringify(editDetails))} variant='primary' type='submit' onClick={handleSubmit}>{t('Save') }</ButtonWithLoading>}
            {!editClicked && isGroupEdit && <Button disabled={!editGroupAccess} variant='primary' onClick={()=>setEditClicked(true)}>{t('Edit') }</Button>}
            <Button variant='secondary' onClick={()=>setModalOpen(false)}>{t('Cancel') }</Button>
          </ButtonsWrapper>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default CreateEditGroupModal;