import { useMutation, useQuery } from '@apollo/client';
import { DataGrid } from '@mui/x-data-grid';
import { IMPORT_OBJECTS } from 'api_configs/mutations';
import Button from 'components/Button';
import ButtonWithLoading from 'components/ButtonWithLoading';
import Icon from 'components/Icon';
import Input from 'components/Input';
import Config from '../configs/config.json';
import { IconType, PROPERTY_TYPE, SERVER_URL, StatusCode } from '../constants';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Tabs from 'components/Tabs';
import { useHistory, useLocation, useParams } from 'react-router';
import { CircularProgress } from '@mui/material';
import { IAlertMessageType, IBreadcrum } from 'interface';
import AlertBar from 'components/AlertBar';
import Label from 'components/Label';
import { useHeader } from 'contexts/HeaderContext';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import Papa from 'papaparse';
import { GET_OBJECT_TYPE } from 'api_configs/queries';
import sample_image from '../svgs/Sample_Image.jpg';
import PersonImg from '../svgs/Person.jpg';
import NumberPlateImg from '../svgs/Number_Plate.jpeg';
import Modal from 'components/Modal';

const WizardContainer = styled.div`
  margin-top: 20px;
  background-color: white;
  padding: 0.2em 0em 0.2em 0.2em;
`;

const TablePaginationWrapper = styled.div<{rowHeight: number}>`
  margin-top: 22px;
  max-width: 1690px;
  min-width: 1200px;


  .MuiDataGrid-columnHeaderTitle {
    font-weight: bold;
    font-size: 15px;
  }

  .MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }
  > div:nth-child(1) > div:nth-child(2){
    display: none !important;
  }
  > div:first-child {
    > div:nth-child(1) {
      > div:nth-child(2){ 
        min-height: ${({rowHeight}) => `${rowHeight}px`};
        border-bottom: none !important;
      }
    }
  }
  > div > div:first-child{
    > div:first-child{
      background-color: #F5F8FF;
      font-size: 14px;
      color: #5a6269;
    }
  }
  > div > div:first-child{
  > div:first-child{
    font-size: 14px;
    color:#7b8288;
    font-weight: 800;
  }
  > div:nth-child(2) {
    text-align: left;
    font-size: 14px;
    text-decoration: none;
  }
}
`;

const MainContainerWrapper = styled.div`
  margin-top: 20px;
  margin-left: 30px;
  position: relative;
`;

const ImportInput = styled.div`
  display: flex;
  align-items: center;
  gap: 0 15px;
  > div > input {
    border: 1px solid lightgrey;
    cursor: pointer;
  }
  > button {
    margin-top: 17px;
  }
`;

const ImportButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 500px;
  gap: 0 15px;
  margin-top: 15px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  > button {
    height: 30px;
    font-size: 16px;
  }
`;

const WizardLink = styled.a<{ isCurrent: boolean, isPastStep: boolean, isFirstTab: boolean, isLastTab: boolean }>`
  padding: 13px 12px 13px;
  position: relative;
  display: inline-block;
  text-decoration: none;
  width: 360px;
  height: 60px;
  margin-left: 3px;
  text-decoration: none;
  font-size: 16px;
  text-align: center !important;
  color: ${({ isCurrent, isPastStep }) => (isCurrent || isPastStep ? '#ffffff' : 'rgb(44 23 23)')};;
  font-weight: 600;
  background: ${({ isCurrent, isPastStep }) => (isCurrent ? '#435FCA' : isPastStep ? '#435FCA' : 'rgb(206, 208, 214)')};
  cursor: pointer;
  pointer-events: ${({isCurrent}) =>  isCurrent ? '': 'none'};

  &:hover {
    text-decoration: none;
  }

  &:first-child {
    margin-left: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &:before {
    width: 0;
    height: 0;
    border-top: ${({isFirstTab}) => isFirstTab ? '' : '30px inset transparent'};
    border-bottom: ${({isFirstTab}) => isFirstTab ? '' : '30px inset transparent'};
    border-left: 30px solid #FFFFFF;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
  }

  &:after {
    width: 0;
    height: 0;
    border-top: ${({isLastTab}) => isLastTab ? '' : '30px inset transparent'};
    border-bottom: ${({isLastTab}) => isLastTab ? '' : '30px inset transparent'};
    border-left: 30px solid ${({ isCurrent, isPastStep }) => (isCurrent ? '#435FCA' : isPastStep ? '#435FCA' : 'rgb(206, 208, 214)')};
    position: absolute;
    content: '';
    top: 0;
    right: -30px;
    z-index: 2;
    }
    @media only screen and (max-width: 1366px){
      width: 280px;
    }
`;

const CurrentLink = styled(WizardLink)`
  background: #435FCA;
  color: #fff;
  &:after {
      border-left-color: #435FCA !important;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 60px;
  top: 18px;
`;

const PreviewObjectsWrapper = styled.div`
  margin-left: 26px;
  margin-top: 26px;
  max-width: 1450px;
`;

const TopHeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  max-width: 1450;
`;

const SpinnerBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 70px;
  margin-left: 50px;
  margin-top: 30px;
  height: 100px;
  width: 100%;
  max-width: 1450px;
  align-items: center;
  justify-content: center;
`;

const DownloadingText = styled.div`
  color: #8b9196;
  font-size: 14px;
  line-height: 1.79;
  text-align: center;
  position: absolute;
  margin-top: 115px;
  max-width: 415px;
`;

const TextCellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 200px;
  word-wrap: break-word;
  justify-content: space-between;
  gap: 14px;
  padding: 10px;
`;

const SectionHeading = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const SectionContainer = styled.div`
  margin-top: 30px;
  min-width: 1210px;
  max-width: 1820px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const IndicatorLabel = styled(Label)`
  font-size: 20px;
`;

const UploadingErrorText = styled(Label)`
  font-size: 14px;
  text-align: center;
`;

const UploadingSuccessText = styled.div`
  font-size: 14px;
  line-height: 1.79;
  text-align: center;
`;

const ImageProperty = styled.img`
  width: 50px;
  height: 50px;
  cursor: pointer;
`;

const SampleObjectsFileWrapper = styled.div`
  display: flex;
  justify-content: end;
  position: absolute;
  min-width: 980px;
  width: 81%;
  @media only screen and (max-width: 1366px){
    width: 92%;
  }
  
`;

const LinkWrapper = styled.div`
  margin-bottom: 10px;
  text-decoration: underline;
  color: #002ea2;
  margin-top: 5px;
  cursor: pointer;
`;

const SuccessButtonWrapper = styled.div`
  display: flex;
  gap: 0 10px;
  width: max-content;
  margin-top: 16px;  
`;

const HeadingTitle = styled.div`
  font-weight: 800;
  font-size: 24px;
`;

const SectionDescription = styled.div``;

const TextCell = styled.div`
  display: flex;
  gap:5px;
  font-size: 16px;
`;

const Badge = styled.div`
  margin: 7px 5px 0 10px;
  position: relative;
  top: -1px;
  font-size: 18px;
`;

const SmallWizard = styled(WizardContainer)`
  margin-left: 25px;
  margin-bottom: 15px;
  min-width: 1210px;
  max-width: 1820px;
`;

const ModalImage = styled.img`
  width: 650px;
  height: 400px;
`;

const ObjectImagesCountWrapper = styled.div`
  position: relative;
  display: flex;
  z-index: 3;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: black;
`;

const ImagesCount = styled.span`
  position: absolute;
  top: 34px;
  right: -10px;
  padding: 0 6px;
  background-color: #ffffff;
  border: 1px solid #000000;
  border-radius: 10px;
  font-size: 12px;
`;

interface IProperty {
  displayName: string,
  featureExtraction?: string,
  isRequired: boolean,
  key: string,
  maxImages?: number,
  propertyType: string,
  sequence: string,
  sequenceId: number,
  isUnique?: boolean,
  isTextOnly?: boolean,
  maxTextLength?: number,
  selectOptions?: string[],
  rows?: number,
  cols?: number
}

interface IParams {
  id: string
}

interface IRow {
  unique_attr: string
}

interface IPropertyValue {
  images: string[],
  defaultImage: string
}

interface IObjectType {
  id: number, 
  name: string,
  description: string,
  is_default?: boolean,
  properties?: IProperty[]
}

const ImportObjects: React.FC = () => {
  const [stateCount] = useState<number>(4);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const id: IParams = useParams();
  const importId = id.id;
  const [selectedFile, setSelectedFile] = useState<FileList | undefined>();
  const [loader, setLoader] = useState(false);
  const [importFileMutation] = useMutation(IMPORT_OBJECTS);
  const [imageBase64String, setImageBase64String] = useState('');
  const location = useLocation();
  const [modalImagePath, setModalImagePath] = useState('');
  const [currentObjectType, setCurrentObjectType] = useState<IObjectType>();
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'});
  const { data, loading: getSingleObjectLoading, refetch: refetchSingleObject } = useQuery(GET_OBJECT_TYPE, {variables: {id: Number(importId)}});
  const {t} = useTranslation(['common']);
  const [tableColumns, setTableColumns] = useState([]);
  const [isImageAttribute, setIsImageAttribute] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState(false);
  const {updateHeaderTitle} = useHeader();
  const history = useHistory();
  const fileInput = useRef<HTMLInputElement | null>(null);
  const [imageBase64, setImageBase64] = useState<string | ArrayBuffer | null>('');
  const [rowData, setRowData] = useState();
  const [currentTab, setCurrentTab] = useState<string>('ADD');
  const [importStatus, setImportStatus] = useState<string>('');
  const [objectBase64, setObjectBase64] = useState<string | ArrayBuffer | null>('');
  const [objectName, setObjectName] = useState('');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const selectedTab = searchParams.get('selectedTab');
    if(selectedTab) {
      setCurrentTab(selectedTab);
    }
  }, [location.search]);

  useEffect(()=>{
    const cameraPageBreadCrumb: IBreadcrum[] = [
      {label: t('Objects'), to: '/objects'}
    ];
    updateHeaderTitle(t('Import Objects'), 'Object', cameraPageBreadCrumb);
  },[updateHeaderTitle, t]);

  const imageToBase64 = (image: string) => {
    return new Promise((resolve, reject) => {
      fetch(image)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64String = reader.result;
            resolve((base64String as string).split(',')[1]);
          };
          reader.readAsDataURL(blob);
        })
        .catch((error) => {
          console.error('Error fetching the image:', error);
          reject(error);
        });
    });
  };

  const objectBase64ImageValue = useCallback(async (objectType: string) => {
    try {
      let base64String;
      if (objectType === Config.Object_Sample_file.DEFAULT_OBJECT_NAMES.FACE_OBJECT) {
        base64String = await imageToBase64(PersonImg);
      }
      if (objectType === Config.Object_Sample_file.DEFAULT_OBJECT_NAMES.NUMBER_PLATE) {
        base64String = await imageToBase64(NumberPlateImg);
      }
      setObjectBase64(String(base64String));
    } catch (error) {
      console.log('Error:', error);
    }
  }, [setObjectBase64]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const base64String = await imageToBase64(sample_image);
        setImageBase64(String(base64String));
      } catch (error) {
        console.log('Error:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if(!getSingleObjectLoading) {
      if(data) {
        setCurrentObjectType(data?.getObjectType?.result);
        if(data?.getObjectType?.result?.name === Config.Object_Sample_file.DEFAULT_OBJECT_NAMES.FACE_OBJECT) {
          setObjectName(Config.Object_Sample_file.DEFAULT_OBJECT_NAMES.FACE_OBJECT);
          objectBase64ImageValue(Config.Object_Sample_file.DEFAULT_OBJECT_NAMES.FACE_OBJECT);
        }
        if(data?.getObjectType?.result?.name === Config.Object_Sample_file.DEFAULT_OBJECT_NAMES.NUMBER_PLATE) {
          setObjectName(Config.Object_Sample_file.DEFAULT_OBJECT_NAMES.NUMBER_PLATE);
          objectBase64ImageValue(Config.Object_Sample_file.DEFAULT_OBJECT_NAMES.NUMBER_PLATE);
        }
        data?.getObjectType?.result?.properties?.forEach((property: IProperty) => {
          if(property?.propertyType === PROPERTY_TYPE.Image || property?.propertyType === PROPERTY_TYPE.MultiImage) {
            setIsImageAttribute(true);
          }
        });
      }
    }
  }, [getSingleObjectLoading, data, objectBase64ImageValue, t, refetchSingleObject]);

  const handleStepChange = (step: number) => {
    setCurrentStep(step);
  };

  const onBrowse = useCallback(() => {
    if (fileInput.current !== null)
      fileInput.current.click();
  }, []);

  const onChangeFile = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null && event.target.files.length !== 0) {
      setSelectedFile(event.target.files);
    } else {
      setSelectedFile(undefined);
    }
  },[]);

  const generateRowData = useCallback(() => {
    if(rowData) {
      return (rowData[currentTab as string] as []).map((newEntry) => newEntry);
    }else {
      return [];
    }
  }, [rowData, currentTab]);

  useEffect(() =>{
    const file = selectedFile && selectedFile[0];
    if(file){
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = (reader.result as string).split(',')[1];
        setImageBase64String(base64String);
      };
      reader.readAsDataURL(file);
    }
  },[selectedFile]);

  const onImportFile = useCallback(async() => {
    setLoader(true);
    try {
      const payload = { zipContent: imageBase64String, importAction: 'SCAN', objectTypeId: Number(importId)};
      const data = await importFileMutation({variables: {payload: payload}});
      if(data?.data?.importObjects?.statusCode === StatusCode.TYPE_MISMATCH) {
        setMessage({text: t('Object type does not match. Please specify the correct type.'), id:'', type:'danger'});
        setLoader(false);
      }else if(data?.data?.importObjects?.statusCode === StatusCode.ERROR) {
        setMessage({text: t('Failed to import objects. Please check file again.'), id:'', type:'danger'});
        setLoader(false);
      }else {
        const columns = data?.data?.importObjects?.result?.objectTypeProperties.map((property: IProperty) => {
          if(property.propertyType === PROPERTY_TYPE.Text) {
            return {
              field: property.key,
              headerName: property.displayName,
              flex: 1.3,
              sortable: false,
              headerAlign: 'center',
              align: 'center',
              renderCell: (params: any) => {
                return (
                  <TextCellWrapper>
                    <TextCell>{params.row[property.key]}</TextCell>
                  </TextCellWrapper>
                );}
            };
          } else if(property.propertyType === PROPERTY_TYPE.Image) {
            return {
              field: property.key,
              headerName: property.displayName,
              flex: 1.3,
              sortable: false,
              headerAlign: 'center',
              align: 'center',
              renderCell: (params: any) => (
                <TextCellWrapper>
                  <TextCell onClick={() => {setModalImagePath(SERVER_URL + params.row.image_paths[0]); setModalOpen(true);}}><ImageProperty src={SERVER_URL + params.row.image_paths[0]} alt={params.row['unique_attr' as string]} /></TextCell>
                </TextCellWrapper>
              )
            };
          } else if(property.propertyType === PROPERTY_TYPE.MultiImage) {
            return {
              field: property.key,
              headerName: property.displayName,
              flex: 1.3,
              sortable: false,
              headerAlign: 'center',
              align: 'center',
              renderCell: (params: any) => {
                return (<TextCellWrapper>
                  <TextCell>
                    <ImageProperty src={SERVER_URL + `${params.row.image_paths[0]}`} alt={params.row['unique_attr' as string]} />
                    <ObjectImagesCountWrapper><ImagesCount>+ {1}</ImagesCount></ObjectImagesCountWrapper>
                  </TextCell>
                </TextCellWrapper>);}
            };
          } else {
            return {
              field: property.key,
              headerName: property.displayName,
              flex: 1.3,
              sortable: false,
              headerAlign: 'center',
              align: 'center',
              renderCell: (params: any) => (
                <div>{params.row[property.key]}</div>
              )
            };
          }
        });
        setTableColumns(columns);
        setRowData(data?.data?.importObjects?.result?.objects);
        if(data?.data?.importObjects?.result?.objects['ADD' as string]?.length === 0) {
          if(data?.data?.importObjects?.result?.objects['UPDATE' as string]?.length === 0) {
            if(data?.data?.importObjects?.result?.objects['DELETE' as string]?.length === 0) {
              history.push('?selectedTab=ERROR');
            }else {
              history.push('?selectedTab=DELETE');
            }
          }else {
            history.push('?selectedTab=UPDATE');
          }
        }else {
          history.push('?selectedTab=ADD');
        }
        setLoader(false);
        setCurrentStep(2);
      }
    } catch {
      setLoader(false);
      console.log('errors');
    } finally {
      setLoader(false);
    }
  },[imageBase64String, t, history, importFileMutation, importId]);

  const handleConfirmImport = async () => {
    setCurrentStep(3);
    try {
      const payload = { zipContent: imageBase64String, importAction: 'IMPORT', objectTypeId: Number(importId) };
      const data = await importFileMutation({variables: {payload: payload}});
      if (data?.data?.importObjects?.statusCode === StatusCode?.SUCCESS) {
        setImportStatus('Success');
        setTimeout(() => {
          setMessage({text: t('Objects imported successfully'), id:'', type:'success'});
          setCurrentStep(4);
        }, 1000);
        setSelectedFile(undefined);
      } else {
        setImportStatus('Error');
        setCurrentStep(4);
        setMessage({text: t('Failed to import objects'), id:'', type:'danger'});
      }
    } catch {
      setMessage({text: t('Failed to communicate with the system'), id:'', type:'danger'});
    }
  };

  const preprocessDataForCSV = (data: IObjectType, sequence: string) => {
    const flatData: { [key: string]: string | number | boolean | [] } = {};
    const actualDemoData: { [key: string]: string | number | boolean | [] | IPropertyValue } = {};
    if(objectName === Config.Object_Sample_file.DEFAULT_OBJECT_NAMES.FACE_OBJECT) {
      actualDemoData['unique_attr' as string] = Config.Object_Sample_file.OBJECT_SAMPLE_NAMES.FACE_OBJECT;
    }else if(objectName === Config.Object_Sample_file.DEFAULT_OBJECT_NAMES.NUMBER_PLATE) {
      actualDemoData['unique_attr' as string] = Config.Object_Sample_file.OBJECT_SAMPLE_NAMES.NUMBER_PLATE;
    }else {
      actualDemoData['unique_attr' as string] = 'Unique';
    }
    const flattenObject = (obj: any, prefix = '') => {
      for (const key in obj) {
        if (Array.isArray(obj[key])) {
          if (key === 'properties') {
            if(sequence === 'first') {
              flatData[prefix + key] = JSON.stringify(obj[key]);
            }else {
              obj[key].map((property: IProperty) => {
                if(property.propertyType === PROPERTY_TYPE.Image || property.propertyType === PROPERTY_TYPE.MultiImage) {
                  actualDemoData['feature_data' as string] = 'None';
                  return null;
                }
                return null;
              });
              obj[key].map((property: IProperty) => {
                if(property.propertyType === PROPERTY_TYPE.Image) {
                  if(objectName === Config.Object_Sample_file.DEFAULT_OBJECT_NAMES.FACE_OBJECT) {
                    actualDemoData[property.key as string] = 'person.jpg';
                  }else if(objectName === Config.Object_Sample_file.DEFAULT_OBJECT_NAMES.NUMBER_PLATE) {
                    actualDemoData[property.key as string] = 'number_plate.jpg';
                  }else {
                    actualDemoData[property.key as string] = Config.Object_Sample_file.DEFAULT_IMAGE_NAME;
                  }
                  return null;
                }else if(property.propertyType === PROPERTY_TYPE.MultiImage) {
                  actualDemoData[property.key as string] = `{"images": ['images/${Config.Object_Sample_file.DEFAULT_IMAGE_NAME}', 'images/${Config.Object_Sample_file.DEFAULT_IMAGE_NAME}'], "defaultImage": "images/${Config.Object_Sample_file.DEFAULT_IMAGE_NAME}"}`;
                  return null;
                }else if(property.isUnique) {
                  if(objectName === Config.Object_Sample_file.DEFAULT_OBJECT_NAMES.FACE_OBJECT) {
                    actualDemoData['unique_attr' as string] = Config.Object_Sample_file.OBJECT_SAMPLE_NAMES.FACE_OBJECT;
                    actualDemoData[property.key as string] = Config.Object_Sample_file.OBJECT_SAMPLE_NAMES.FACE_OBJECT;
                  }else if(objectName === Config.Object_Sample_file.DEFAULT_OBJECT_NAMES.NUMBER_PLATE) {
                    actualDemoData['unique_attr' as string] = Config.Object_Sample_file.OBJECT_SAMPLE_NAMES.NUMBER_PLATE;
                    actualDemoData[property.key as string] = Config.Object_Sample_file.OBJECT_SAMPLE_NAMES.NUMBER_PLATE;
                  }else {
                    actualDemoData['unique_attr' as string] = 'Unique';
                    actualDemoData[property.key as string] = 'Unique';
                  }
                  return null;
                }else if(property.propertyType === PROPERTY_TYPE.SelectField) {
                  actualDemoData[property.key as string] = (property.selectOptions as string[])[0];
                }else {
                  if(objectName === Config.Object_Sample_file.DEFAULT_OBJECT_NAMES.FACE_OBJECT) {
                    actualDemoData[property.key as string] = Config.Object_Sample_file.OBJECT_SAMPLE_NAMES.FACE_OBJECT;
                  }else if(objectName === Config.Object_Sample_file.DEFAULT_OBJECT_NAMES.NUMBER_PLATE) {
                    actualDemoData[property.key as string] = Config.Object_Sample_file.OBJECT_SAMPLE_NAMES.NUMBER_PLATE;
                  }else {
                    actualDemoData[property.key as string] = `Test_${property.propertyType}`;
                  }
                }
                return null;
              });
            }
          }
        }
        if(sequence === 'first') {
          if (typeof obj[key] === 'object' && obj[key] !== null) {
            flattenObject(obj[key], prefix + key + '.');
          } else {
            flatData[prefix + key] = obj[key];
          }
        }
      }
    };
    flattenObject(data);
    let dataArray;
    if(sequence === 'first') {
      dataArray = [flatData];
    }else {
      dataArray = [actualDemoData];
    }
    return dataArray;
  };
  
  const generateZip = (data: IObjectType) => {
    const zip = new JSZip();
    const csvData1 = preprocessDataForCSV(data, 'first');
    const csvData2 = preprocessDataForCSV(data, 'second');
    zip.file('object_type.csv', Papa.unparse(csvData1));
    zip.file('objects.csv', Papa.unparse(csvData2));
    if(isImageAttribute) {
      const objectsFolder = zip.folder('objects');
      let objectTypeFolder;
      if(objectName === Config.Object_Sample_file.DEFAULT_OBJECT_NAMES.FACE_OBJECT) {
        objectTypeFolder = objectsFolder?.folder(Config.Object_Sample_file.OBJECT_SAMPLE_NAMES.FACE_OBJECT);
      }else if(objectName === Config.Object_Sample_file.DEFAULT_OBJECT_NAMES.NUMBER_PLATE) {
        objectTypeFolder = objectsFolder?.folder(Config.Object_Sample_file.OBJECT_SAMPLE_NAMES.NUMBER_PLATE);
      }else {
        objectTypeFolder = objectsFolder?.folder('Unique');
      }
      const images = objectTypeFolder?.folder('images');
      images?.file(Config.Object_Sample_file.DEFAULT_IMAGE_NAME, imageBase64 as string, { base64: true });
      if(objectName === Config.Object_Sample_file.DEFAULT_OBJECT_NAMES.FACE_OBJECT) {
        images?.file('person.jpg', objectBase64 as string, {base64: true});
      }
      if(objectName === Config.Object_Sample_file.DEFAULT_OBJECT_NAMES.NUMBER_PLATE) {
        images?.file('number_plate.jpg', objectBase64 as string, {base64: true});
      }
    }
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, `Object-Sample-File-${currentObjectType?.name}.zip`);
    });
  };

  const handleDownload = () => {
    generateZip(currentObjectType as IObjectType);
  };

  return (
    <div>
      {message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
      <SmallWizard>
        {Array.from({ length: stateCount }, (_, i) => {
          let stepName;
          const step = i + 1;
          const isCurrent = step === currentStep;
          const StepLink = isCurrent ? CurrentLink : WizardLink;
          if(step === 1) {
            stepName = t('Select File');
          }else if(step === 2) {
            stepName = t('Check and Confirm');
          }else if(step === 3) {
            stepName = t('Submit');
          }else if(step === 4) {
            stepName = t('Finished');
          }
          return (
            <StepLink key={step} onClick={() => handleStepChange(step)} isCurrent={isCurrent} isPastStep={Number(step) < Number(currentStep)} isFirstTab={step === 1} isLastTab={step === 4}>
              {(Number(step) < Number(currentStep) || currentStep === 4) && <IconWrapper>
                {(currentStep === 4) ? (importStatus === 'Success' ? <Icon icon='Success' color='inverse' /> : <Icon icon='Warning' color='inverse' />): <Icon icon='Success' color='inverse' /> }
              </IconWrapper>}
              <Badge>{`${stepName}`}</Badge>
            </StepLink>
          );
        })}
      </SmallWizard>
      {modalOpen && <Modal isModalOpen={modalOpen} closeModal={setModalOpen} width='700px'>
        <ModalImage src={modalImagePath} alt='Property'/>
      </Modal>}
      {
        currentStep === 1 && 
        <MainContainerWrapper>
          <SampleObjectsFileWrapper>
            {currentObjectType && <LinkWrapper onClick={handleDownload}>{t('Download Sample ZIP File')}</LinkWrapper>}
          </SampleObjectsFileWrapper>
          <ImportInput>
            <input type='file' ref={fileInput} onChange={onChangeFile} accept='.zip' style={{ display: 'none' }} />
            <Input type='text' label={t('Please select a zip file')} placeholder={t('No file selected')} value={selectedFile ? selectedFile[0].name : ''} onClick={onBrowse}/>
            <Button variant='primary' onClick={onBrowse} >{t('Browse')}</Button>
          </ImportInput>
          <ImportButton>
            <ButtonWithLoading variant='primary' loading={loader} onClick={onImportFile} disabled={selectedFile ? false : true} >{t('Next')}</ButtonWithLoading>
            <Button variant='secondary' disabled={selectedFile ? false : true} onClick={() => setSelectedFile(undefined)}>{t('Clear')}</Button>
          </ImportButton>
        </MainContainerWrapper>
      }
      {
        currentStep === 2 &&
        <PreviewObjectsWrapper>
          <TopHeadingWrapper>
            <SectionHeading>
              <HeadingTitle>{t('Check and Confirm')}</HeadingTitle>
              <SectionDescription>{t('Check all the tabs below. if you notice any issues, go back and change file or if changes are correct, click confirm import')}</SectionDescription>
            </SectionHeading>
            <ButtonsWrapper>
              <Button variant='primary' disabled={(rowData && (rowData['ADD' as string] as [])?.length === 0 && (rowData['UPDATE' as string] as [])?.length === 0 && (rowData['DELETE' as string] as [])?.length === 0)} onClick={() => handleConfirmImport()}>{t('Confirm Import')}</Button>
              <Button variant='primary' onClick={() => {setCurrentStep(1); history.push(importId);}}>{t('Back')}</Button>
            </ButtonsWrapper>
          </TopHeadingWrapper>
          <Tabs tabs={[
            {
              id: 'ADD',
              name: t('Add'), 
              subTitle: {text: rowData ? `${(rowData['ADD' as string] as [])?.length} Object${(rowData['ADD' as string] as [])?.length > 1 ? 's': ''}` : t('No Objects'), color: currentTab === 'ADD' ? 'inverse': 'mono'},
              content: <TablePaginationWrapper rowHeight={60}>
                <DataGrid
                  rows={generateRowData() ? generateRowData() : []}
                  columns={tableColumns}
                  disableRowSelectionOnClick
                  disableColumnMenu
                  getRowHeight={() => 60}
                  getRowId={(row: IRow) => row.unique_attr}
                /> 
              </TablePaginationWrapper>
            },
            {
              id: 'UPDATE',
              name: t('update'),
              subTitle: {text: rowData ? `${(rowData['UPDATE' as string] as [])?.length} Object${(rowData['UPDATE' as string] as [])?.length > 1 ? 's': ''}` : t('No Objects'), color: currentTab === 'UPDATE' ? 'inverse': 'mono'},
              content: <TablePaginationWrapper rowHeight={60}>
                <DataGrid
                  rows={generateRowData() ? generateRowData() : []}
                  columns={tableColumns}
                  disableRowSelectionOnClick
                  disableColumnMenu
                  getRowHeight={() => 60}
                  getRowId={(row: IRow) => row.unique_attr}
                /> 
              </TablePaginationWrapper>
            },
            {
              id: 'DELETE',
              name: t('Delete'),
              subTitle: {text: rowData ? `${(rowData['DELETE' as string] as [])?.length} Object${(rowData['DELETE' as string] as [])?.length > 1 ? 's': ''}` : t('No Objects'), color: currentTab === 'DELETE' ? 'inverse': 'mono'},
              content: <TablePaginationWrapper rowHeight={60}>
                <DataGrid
                  rows={generateRowData() ? generateRowData() : []}
                  columns={tableColumns}
                  disableRowSelectionOnClick
                  disableColumnMenu
                  getRowHeight={() => 60}
                  getRowId={(row: IRow) => row.unique_attr}
                /> 
              </TablePaginationWrapper>
            },
            {
              id: 'ERROR',
              name: t('Error'),
              subTitle: {text: rowData ? `${(rowData['ERROR' as string] as [])?.length} Object${(rowData['ERROR' as string] as [])?.length > 1 ? 's': ''}` : t('No Objects'), color: currentTab === 'ERROR' ? 'inverse': 'mono'},
              content: <TablePaginationWrapper rowHeight={60}>
                <DataGrid
                  rows={generateRowData() ? generateRowData() : []}
                  columns={tableColumns}
                  disableRowSelectionOnClick
                  disableColumnMenu
                  getRowHeight={() => 60}
                  getRowId={(row: IRow) => row.unique_attr}
                /> 
              </TablePaginationWrapper>
            }
          ]} activeMode='box' padding='0 16px' />
        </PreviewObjectsWrapper>
      }
      {
        currentStep === 3 && 
        <SpinnerBox>
          <CircularProgress />
          <DownloadingText>{t('Please do not navigate away from this page while objects are being imported. We will let you know once this has been completed.')}</DownloadingText>
        </SpinnerBox>
      }
      {
        currentStep === 4 && importStatus === 'Success' &&
        <SectionContainer>
          <Icon color='success' icon='Success' size={40} weight='regular' />
          <IndicatorLabel labelText={t('Import Completed')} />
          <UploadingSuccessText>{t('A file has been imported successfully. You may close this page now.')} </UploadingSuccessText>
          <SuccessButtonWrapper>
            <Button variant='primary' onClick={() => setCurrentStep(1)}>{t('Import Again')}</Button>
            <Button variant='primary' onClick={() => history.push(`/objects?selectedTab=${importId}`)}>{t('View Objects')}</Button>
          </SuccessButtonWrapper>
        </SectionContainer>
      }
      {
        currentStep === 4 && importStatus === 'Error' &&
        <SectionContainer>
          <Icon icon='Warning' color='danger' size={40} weight='regular' />
          <IndicatorLabel labelText={t('Error Occured')} />
          <UploadingErrorText labelText={t('An error occurred during import')} />
          <SuccessButtonWrapper>
            <Button variant='primary' onClick={() => {setCurrentStep(1);}}>{t('Try Again')}</Button>
            <Button variant='secondary' onClick={() => {setCurrentStep(1);}}>{t('Cancel')}</Button>
          </SuccessButtonWrapper>
        </SectionContainer>
      }
    </div>
  );
};

export default ImportObjects;