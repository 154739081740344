import Input from 'components/Input';
import React, {useEffect, useState, useCallback} from 'react';
import styled from 'styled-components';
import { IAlertMessageType, IBreadcrum, IPipelineInfo } from 'interface';
import { useMutation } from '@apollo/client';
import { EP_ACTION_TEMPLATES, IconType, StatusCode } from '../../constants';
import Button from 'components/Button';
import { ADD_EVENT_PIPELINE } from 'api_configs/mutations';
import AlertBar from 'components/AlertBar';
import { useHeader } from 'contexts/HeaderContext';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import ButtonWithLoading from 'components/ButtonWithLoading';
import PipelineArea from './EventPipelineTemplates/PipelineArea';
import usePipelineInfo from 'hooks/usePipelineInfo';
import { CircularProgress } from '@mui/material';
import lodash from 'lodash';
import CancelConfirmationModal from 'pages/configurations/CancelConfirmationModal';
import Switch from 'components/Switch';

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderLeftContainer = styled.div`
  display: flex;
  gap: 30px;
  position: relative;
`;

const SwitchWrapper = styled.div`
  display: flex;
  gap: 10px;
  position: absolute;
  right: 0;
`;

const Span = styled.span``;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 300px;
  gap: 10px;
`;

const Loading = styled.span``;

const AddNewEventpipeline = () => {
  const [selectedCloneId, setSelectedCloneId] = useState(-1);
  const {pipelineInfo, setPipelineInfo, actionNodes, scheduleNodes, filterNodes, epLoading, loading, pipelineInfoBeforeEdit} = usePipelineInfo(selectedCloneId);
  const {updateHeaderTitle} = useHeader();
  const {t} = useTranslation(['common']);
  const {push} = useHistory();
  const [addEventPipelineMutation, {loading: addEPLoading}] = useMutation(ADD_EVENT_PIPELINE);
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'});
  const [currentFilters, setCurrentFilters] = useState<number>(0);
  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false);

  useEffect(()=>{
    const cameraPageBreadCrumb: IBreadcrum[] = [
      {label: t('Event Pipelines'), to: '/settings/event-pipeline'}
    ];
    updateHeaderTitle(t('New Event Pipeline'), 'EventPipeline', cameraPageBreadCrumb);
  },[updateHeaderTitle, t]);

  const fetchHistoryParams = () => {
    const params = new URLSearchParams(window.location.search);
    const cloneId = params.get('cloneId') as string;
    if(cloneId){
      setSelectedCloneId(parseInt(cloneId));
    }
  };
  
  useEffect(() => {
    fetchHistoryParams();
  },[]); 

  const handleCancelclick = useCallback(()=>{
    if(lodash.isEqual(pipelineInfoBeforeEdit, pipelineInfo)){
      push('/settings/event-pipeline');
    }else{
      setCancelModalOpen(prev => !prev);
    }
  },[push, pipelineInfoBeforeEdit, pipelineInfo]);

  const onCancelYes = useCallback(() => {
    push('/settings/event-pipeline');
  },[push]);

  const onCancelNo = useCallback(() => {
    setCancelModalOpen(prev => !prev);
  },[]);

  const handleSaveEventPipeline = useCallback(async()=>{
    try{
      // Remove Action Ids
      const updatedActions = pipelineInfo.actions.map((action)=>{
        const {id, ...other} = action;
        return other;
      });
      const updatedPayload: IPipelineInfo = currentFilters === 0 ? {...pipelineInfo, actions: [...updatedActions], filters: {cameras: ['ALL']}} : {...pipelineInfo, actions: [...updatedActions]};
      const pipelineName = updatedPayload.name.trim();
      if(pipelineName.length === 0){
        setMessage({text: t('Please enter event pipeline name.'), id:'',type:'danger'});
        return;
      }
      if(updatedPayload.event.length === 0){
        setMessage({text: t('Please select algorithm'), id:'',type:'danger'});
        return;
      }
      if(updatedPayload.actions.length === 0){
        setMessage({text: t('No action configured.'), id:'',type:'danger'});
        return;
      }
      if(updatedPayload.actions.length > 0){
        let isErrorInActionsData = false;
        updatedPayload.actions.forEach(action => {
          if(action.type === EP_ACTION_TEMPLATES.WHATSAPP || action.type === EP_ACTION_TEMPLATES.SMS || action.type === EP_ACTION_TEMPLATES.EMAIL){
            if(action.templateId === null ){
              setMessage({text: t(`Please select ${action.type} template`), id:'',type:'danger'});
              isErrorInActionsData = true;
              return;
            }
            if(!('data' in action)){
              setMessage({text: t('Please enter configuration details for each action.'), id:'',type:'danger'});
              isErrorInActionsData = true;
              return;
            }
            if(!('mobile_no' in action.data) && !(action.type === EP_ACTION_TEMPLATES.EMAIL)){
              setMessage({text: t('Please enter mobile numbers.'), id:'',type:'danger'});
              isErrorInActionsData = true;
              return;
            }
            if(action.type === EP_ACTION_TEMPLATES.EMAIL){
              if(!('to' in action.data)){
                setMessage({text: t('Please enter valid email recipients'), id:'',type:'danger'});
                isErrorInActionsData = true;
                return;
              }
            }
          }
        });
        if(isErrorInActionsData){
          return;
        }
      } 
      const {id, ...payloadWithoutId} = updatedPayload; // Remove pipelineId in case of clone.
      const res = await addEventPipelineMutation({variables: {payload: {...payloadWithoutId, name: pipelineName}}});
      if(res && res?.data?.createEventPipeline?.statusCode === StatusCode.SUCCESS){
        setMessage({text: t('Event pipeline created successfully!'), id:'',type:'success'});
        setTimeout(()=>{
          push('/settings/event-pipeline');
        },1000);
      }else if(res && res?.data?.createEventPipeline?.statusCode === StatusCode.DUPLICATE_ENTRY){
        setMessage({text: t('Event pipeline with this name already exist. Please try different name'), id:'',type:'danger'});
      }else{
        setMessage({text: t('apiError'), id:'',type:'danger'});
      }
    }catch(e){
      console.log(e);
    }
  },[addEventPipelineMutation, pipelineInfo, t, push, currentFilters]);

  const getLoading = useCallback(()=>{
    return (
      <LoadingContainer>
        <CircularProgress />
        <Loading>{t('Loading')}</Loading>
      </LoadingContainer>
    );
  },[t]);

  return (
    <Container>
      {message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
      {(selectedCloneId !== -1 && (epLoading || loading)) ? getLoading() : 
        <>
          <Header>
            <HeaderLeftContainer>
              <Input type='text' id='name' maxLength={100} placeholder={t('Pipeline name')} value={pipelineInfo?.name} onChange={(e) => setPipelineInfo(prev => ({...prev, name: e.target.value}))} label={t('Pipeline name')} isRequired={true} />
              <SwitchWrapper>
                <Span>{pipelineInfo?.enabled ? t('Enabled'): t('Disabled')}</Span>
                <Switch checked={pipelineInfo.enabled}  onChange={() => setPipelineInfo(prev => ({...prev, enabled: !prev.enabled}))} />
              </SwitchWrapper>
            </HeaderLeftContainer>
            <ButtonsContainer>
              <ButtonWithLoading loading={addEPLoading} variant='primary' onClick={handleSaveEventPipeline}>{t('Save')}</ButtonWithLoading>
              <Button variant='secondary' onClick={handleCancelclick}>{t('Cancel')}</Button>
            </ButtonsContainer>
          </Header>
          <PipelineArea 
            pipelineInfo={pipelineInfo.filters?.cameras?.includes('ALL') ? {...pipelineInfo, filters: {}}:{...pipelineInfo}}
            setPipelineInfo={setPipelineInfo}
            getFilterLength={(length) => setCurrentFilters(length)}
            filter_nodes={filterNodes}
            schedule_nodes={scheduleNodes}
            action_nodes={actionNodes}
          />
        </>}
      {cancelModalOpen && <CancelConfirmationModal modalOpen={cancelModalOpen} setModalOpen={setCancelModalOpen} onCancelNo={onCancelNo} onCancelYes={onCancelYes} />}
    </Container>
  );
};

export default AddNewEventpipeline;