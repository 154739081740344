import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgSuccess = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M12 3c-4.95 0-9 4.05-9 9s4.05 9 9 9 9-4.05 9-9-4.05-9-9-9Zm0 16.2c-3.969 0-7.2-3.231-7.2-7.2 0-3.969 3.231-7.2 7.2-7.2 3.969 0 7.2 3.231 7.2 7.2 0 3.969-3.231 7.2-7.2 7.2Zm4.131-11.178L10.2 13.953l-2.331-2.322L6.6 12.9l3.6 3.6 7.2-7.2-1.269-1.278Z"
        fill={props.color}
      />
    </svg>
  );
};
export default SvgSuccess;
