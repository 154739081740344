import Button from 'components/Button';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import AlertBar from 'components/AlertBar';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ALL_GRAFANA_CHARTS, GET_DASHBOARD_CHARTS, GET_DASHBOARD_INFO, GET_STORED_GRAFANA_CHARTS } from 'api_configs/queries';
import { IconType, SUPERSET_URL, StatusCode } from '../../constants';
import { IAlertMessageType, IBreadcrum, IOption } from 'interface';
import { useTranslation } from 'react-i18next';
import { GRAFANA_DASHBOARD_PAGE_MUTATION, SUPERSET_DASHBOARD_PAGE_MUTATION } from 'api_configs/mutations';
import { CircularProgress } from '@mui/material';
import Label from 'components/Label';
import { useHistory, useLocation } from 'react-router';
import SelectField from 'components/SelectField';
import { useHeader } from 'contexts/HeaderContext';
import Modal from 'components/Modal';
import RadioButton from 'components/RadioButton';
import { AiOutlineBarChart } from 'react-icons/ai';
import config from '../../configs/config.json';
import ButtonWithLoading from 'components/ButtonWithLoading';

const SupersetChartsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  justify-content: center;
  padding: 10px;
  gap: 15px;
  >:nth-child(3), :nth-child(4) {
    input {
      margin-bottom: 0;
    }
  }
`;

const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;

  > button {
    width: 80px;
    height: 28px;
    min-width: 60px;
    font-size: 14px;
    margin-left: 15px;
    margin-bottom: 15px;
  }
`;

const AllChartsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  position: relative;
  
  > button {
    width: 80px;
    height: 28px;
    min-width: 60px;
    font-size: 14px;
    margin-bottom: 15px;
  }
`;

const ChartsWrapper = styled.div`
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  column-gap: 54px;
  row-gap: 40px;
`;

const SingleChart = styled.input.attrs({ type: 'checkbox' })`
  width: 18px;
  display: flex;
`;

const ChartsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ConfirmationModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  gap: 15px;
  >:nth-child(3), :nth-child(4){
    input{
      margin-bottom: 0;
    }
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ConfirmationModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const HeaderText = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({theme})=>theme.icons.success};
  text-align: center;
`;

const IconDiv = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({theme})=>theme.statusBackground.success};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RadioButtonContainer = styled.div``;

const ConfirmDesc = styled.span`
  text-align: center;
  color: ${({theme})=>theme.text.secondaryText};
`;

const SingleChartThumbnail = styled.div<{checked: string}>`
  > iframe {
    border-radius: 10px;
    border: ${({checked}) => checked === 'prevCharts' ? '2px solid #54D06F' : checked === 'selectedCharts' ? '2px solid #1538BD' : '0.8px solid lightgrey' }
  }
`;

const InfoWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  gap: 5px;
  width: 450px;
`;

const ChartName = styled.span`
  width: 450px;
  white-space: nowrap;
  text-overflow: ellipsis !important;
  overflow: hidden;
  font-size: 16px;
`;

const ImportButton = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 18px;
  margin-bottom: 30px;
`;

const SpinnerBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-top: 10px;
  height: 10vh;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const EmbeddedIframe = styled.iframe``;

const GrafanaChartsWrapper = styled.div`
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  margin-left: -42px;
  margin-bottom: 18px;
`;

const GrafanaSingleChartWrapper = styled.div`
  position: relative;
`;

const IFrameGrafana = styled.iframe`
  border: 0.8px solid lightgrey;
  border-radius: 10px;
`;

const NoDashboardsMessageWrapper = styled.div`
  display: flex;
  height: 600px;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  > button {
    width: 190px;
    height: 40px;
    margin-left: -4px;
  }
`;

const MessageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

interface IChartsInfo {
  datasource: string,
  description: string | null,
  permalink: string,
  sliceId: number,
  sliceName: string,
  sequenceId: number,
  width: number,
  height: number,
  dashboardId: number,
  linkStatus: string,
  id?: number,
  title?: string,
  url: string,
  vizType: string
}

interface IDashboard {
  chartsInfo: IChartsInfo[],
  chartInfo?: IChartsInfo[],
  id: number,
  permalink: string,
  status: string,
  thumbnailUrl: string,
  title: string,
  url: string,
  dashboardId?:number,
  uid?: string,
}

 interface IDashboardInfo {
   id: number, 
   title: string, 
   url: string,
   uid?: string,
   chartInfo?: IDashboard[],
   chartsInfo?: IDashboard[]
 }

 interface IUserSettings {
   dashboardInfo: IDashboardInfo,
   chartsInfo: IChartsInfo[],
   chartInfo?: IChartsInfo[]
 }

 interface IDashboardInfoWithCharts {
   id: number, 
   title: string, 
   url: string,
   chartsInfo: IChartsInfo[],
   chartInfo?: IChartsInfo[]
 }

 interface IDatabaseData {
  pageName: string,
  data: IDashboardInfoWithCharts[],
 }

const ImportChartPage: React.FC = () => {
  const [selectedDashboard, setSelectedDashboard] = useState('');
  const {pathname} = useLocation();
  const [pathUrl] = useState(pathname.split('/')[1]);
  const [dashboards, setDashboards] = useState<IDashboard[]>([]);
  const {data: pageChartsData, loading: pageChartsDataLoading} = useQuery(GET_DASHBOARD_CHARTS, { variables: {pageName: pathUrl, username: JSON.parse(localStorage.getItem('user_login_info') as string).username}, skip: config.Visualization_Tool !== 'Superset'});
  const {data: pageStoredGrafanaChartsData, loading: pageStoredGrafanaChartsDataLoading} = useQuery(GET_STORED_GRAFANA_CHARTS, { variables: {pageName: pathUrl, username: JSON.parse(localStorage.getItem('user_login_info') as string).username}, skip: config.Visualization_Tool !== 'Grafana'});
  const {data, loading, refetch} = useQuery(GET_DASHBOARD_INFO, {skip: config.Visualization_Tool !== 'Superset'});
  const [storeChartsInfo] = useMutation(SUPERSET_DASHBOARD_PAGE_MUTATION);
  const [storeGrafanaChartsInfo] = useMutation(GRAFANA_DASHBOARD_PAGE_MUTATION);
  const history = useHistory();
  const {t} = useTranslation(['common']);
  const [hostIp, setHostIp] = useState('');
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: ''});  
  const [databaseData, setDatabaseData] = useState<IDatabaseData>({pageName: '', data: [{ id: 2, title: '', url: '', chartsInfo: []}]});
  const {updateHeaderTitle} = useHeader();
  const [importAction, setImportAction] = useState<string>('append');
  const [importChartsloading, setImportChartsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const {data: grafanaCharts, loading: grafanaChartsLoading} = useQuery(GET_ALL_GRAFANA_CHARTS, {skip: config.Visualization_Tool !== 'Grafana'});
  const [selectedDashboardCharts, setSelectedDashboardCharts] = useState<IUserSettings[]>([{dashboardInfo: {id: 0, title: '', url: ''}, chartsInfo: []}]);
  const [allGrafanaCharts, setAllGrafanaCharts] = useState<any>([]); // will update this in next PR.

  useEffect(()=>{
    const setPage = `${pathUrl.substring(0, pathUrl.indexOf('page'))[0]?.toUpperCase()}${pathUrl.substring(0, pathUrl.indexOf('page'))?.slice(1)}`;
    const cameraConfigurationsBreadCrumb: IBreadcrum[] = [
      {label: t(`${setPage}`), to: `/${pathUrl.substring(0, pathUrl.indexOf('page'))}`},
      {label: t('Select Chart'), to: `/${pathUrl}/selectcharts`}
    ];
    updateHeaderTitle(t('Select Dashboard'), 'Home', cameraConfigurationsBreadCrumb);
  },[updateHeaderTitle, t, pathUrl]);

  useEffect(() => {
    if(config.Visualization_Tool === 'Superset') {
      if(data) {
        if(!loading) {
          setDashboards(data?.getDashboardsInfo?.result);
          setSelectedDashboard(data?.getDashboardsInfo?.result[0].id.toString());
          const formattedData = data.getDashboardsInfo.result.map((dashboard: IDashboard) => {
            return {
              dashboardInfo: dashboard,
              chartsInfo: []
            };
          });
          setSelectedDashboardCharts(formattedData);
        }
      }
    }
  }, [data, loading]);

  useEffect(() => {
    if(config.Visualization_Tool === 'Grafana') {
      if(grafanaCharts) {
        if(!grafanaChartsLoading) {
          setAllGrafanaCharts(grafanaCharts?.getGrafanaChartsInfo?.result?.dashboards);
          setHostIp(grafanaCharts?.getGrafanaChartsInfo?.result?.hostIp);
        }
      }
    }
  }, [grafanaChartsLoading, grafanaCharts]);

  useEffect(() => {
    if(config.Visualization_Tool === 'Superset') {
      if(pageChartsData?.getStoredChartsInfo?.result?.data?.length > 0) {
        if(!pageChartsDataLoading) {
          setDatabaseData(pageChartsData?.getStoredChartsInfo?.result);
          setSelectedDashboard(pageChartsData?.getStoredChartsInfo?.result?.data[0]?.id.toString());
          const prevCharts = pageChartsData?.getStoredChartsInfo?.result?.data?.map((dashboard: IDashboard) => {
            return {
              dashboardInfo: dashboard,
              chartsInfo: dashboard.chartsInfo.map((charts) => charts),
            };
          });

          const allDashboard = data?.getDashboardsInfo?.result.map((dashboard: IDashboard) => {
            return {
              dashboardInfo: dashboard,
              chartsInfo: []
            };
          });

          const mergedData = allDashboard?.map((dashboardData: IUserSettings) => {
            const matchingDashboard = prevCharts?.find((dashboard: IUserSettings) => dashboard.dashboardInfo.id === dashboardData.dashboardInfo.id);
            if (matchingDashboard) {
              return {
                dashboardInfo: matchingDashboard.dashboardInfo,
                chartsInfo: matchingDashboard.chartsInfo
              };
            } else {
              return dashboardData;
            }
          });
          setSelectedDashboardCharts(mergedData);
        }
      }else{
        setSelectedDashboard(data?.getDashboardsInfo?.result[0].id.toString());
        const allDashboard = data?.getDashboardsInfo?.result.map((dashboard: IDashboard) => {
          return {
            dashboardInfo: dashboard,
            chartsInfo: []
          };
        });
        setSelectedDashboardCharts(allDashboard);
      }
    }else if(config.Visualization_Tool === 'Grafana') {
      if(pageStoredGrafanaChartsData?.getStoredGrafanaCharts?.result?.dashboards?.length > 0) {
        if(!pageStoredGrafanaChartsDataLoading) {
          setDatabaseData({pageName: 'dashboardpage', data: pageStoredGrafanaChartsData?.getStoredGrafanaCharts?.result?.dashboards});
          setSelectedDashboard(pageStoredGrafanaChartsData?.getStoredGrafanaCharts?.result?.dashboards[0]?.id?.toString());
          const prevCharts = pageStoredGrafanaChartsData?.getStoredGrafanaCharts?.result?.dashboards?.map((dashboard: IDashboard) => {
            return {
              dashboardInfo: dashboard,
              chartsInfo: dashboard?.chartInfo?.map((charts) => charts),
            };
          });
          const allDashboard = allGrafanaCharts.map((dashboard: IDashboard) => {
            return {
              dashboardInfo: dashboard,
              chartsInfo: []
            };
          });
          const mergedData = allDashboard?.map((dashboardData: IUserSettings) => {
            const matchingDashboard = prevCharts?.find((dashboard: IUserSettings) => dashboard.dashboardInfo.id === dashboardData.dashboardInfo.id);
            if (matchingDashboard) {
              return {
                dashboardInfo: matchingDashboard.dashboardInfo,
                chartsInfo: matchingDashboard.chartInfo
              };
            } else {
              return dashboardData;
            }
          });
          if(mergedData.length > 0) {
            setSelectedDashboardCharts(mergedData);
          }else {
            setSelectedDashboardCharts([]);
          }
        }
      } else if(!grafanaCharts?.getGrafanaChartsInfo?.result) {
        console.log('Null Returned by backend'); // debugging purpose..
      }else {
        setSelectedDashboard(grafanaCharts?.getGrafanaChartsInfo?.result?.dashboards[0]?.id?.toString());
        const allDashboard = grafanaCharts?.getGrafanaChartsInfo?.result?.dashboards?.map((dashboard: IDashboard) => {
          return {
            dashboardInfo: {
              ...dashboard,
              chartInfo: []
            },
            chartsInfo: []
          };
        });
        setSelectedDashboardCharts(allDashboard);
      }
    }
  }, [data?.getDashboardsInfo?.result, allGrafanaCharts, grafanaCharts, pageChartsData, pageStoredGrafanaChartsData, pageStoredGrafanaChartsDataLoading, pageChartsDataLoading]);

  useEffect(() => {
    if(config.Visualization_Tool === 'Superset') {
      refetch();
    }
  }, [refetch]);

  const handleDashboardChange = (value: string) => {
    setSelectedDashboard(value);
  };  
  
  const handleChartsChange = (chartId: number) => {
    if(config.Visualization_Tool === 'Superset') {
      const selectedChart = selectedDashboardCharts.find((dashboard) => dashboard.chartsInfo.find((charts)=> charts.sliceId === chartId));
      if (selectedChart) {
        const updatedSelectedDashboardCharts: IUserSettings[] = selectedDashboardCharts.map((dashboardChart: IUserSettings) => {
          if (dashboardChart.dashboardInfo?.id?.toString() === selectedDashboard) {
            return {
              ...dashboardChart,
              chartsInfo: dashboardChart.chartsInfo.filter((chart) => chart.sliceId !== chartId)
            };
          }
          return dashboardChart;
        });
        setSelectedDashboardCharts(updatedSelectedDashboardCharts);
      } else {
        const selectedDashboardChart = dashboards?.find((dashboard) => dashboard.id.toString() === selectedDashboard)?.chartsInfo.find((chart) => chart.sliceId === chartId);
        if (selectedDashboardChart) {
          const updatedSelectedDashboardCharts = selectedDashboardCharts.map((dashboardChart) => {
            if (dashboardChart.dashboardInfo?.id.toString() === selectedDashboard) {
              return {
                ...dashboardChart,
                chartsInfo: [...dashboardChart.chartsInfo, selectedDashboardChart]
              };
            }
            return dashboardChart;
          });
          setSelectedDashboardCharts(updatedSelectedDashboardCharts);
        }
      }
    } else {
      const selectedChart = selectedDashboardCharts?.find((dashboard) => dashboard.dashboardInfo?.chartInfo?.filter((chart) => (chart.id === chartId && Number(selectedDashboard) === chart.dashboardId)));
      if (selectedChart) {
        const updatedSelectedDashboardCharts: IUserSettings[] = selectedDashboardCharts.map((dashboardChart: IUserSettings) => {
          if (dashboardChart.dashboardInfo?.id?.toString() === selectedDashboard) {
            return {
              ...dashboardChart,
              dashboardInfo: {...dashboardChart.dashboardInfo,
                chartInfo: dashboardChart?.dashboardInfo?.chartInfo?.filter((chart) => chart.id !== chartId)}
            };
          }
          return dashboardChart;
        });
        setSelectedDashboardCharts(updatedSelectedDashboardCharts);
      } else {
        const selectedDashboardChart = allGrafanaCharts?.find((dashboard: any) => dashboard.id.toString() === selectedDashboard)?.chartInfo.find((chart: any) => chart.id === chartId);
        if (selectedDashboardChart) {
          const updatedSelectedDashboardCharts = selectedDashboardCharts.map((dashboardChart) => {
            if (dashboardChart.dashboardInfo?.id.toString() === selectedDashboard) {
              return {
                ...dashboardChart,
                dashboardInfo: {...dashboardChart.dashboardInfo,
                  chartInfo: [...dashboardChart.dashboardInfo.chartInfo as [], selectedDashboardChart]
                },
              };
            }
            return dashboardChart;
          });
          setSelectedDashboardCharts(updatedSelectedDashboardCharts);
        }
      }
    }
  };

  const handleImportNewCharts = async () => {
    setImportChartsLoading(true);
    if(config.Visualization_Tool === 'Superset') {
      let sequenceCount =  1;
      const payloadData = selectedDashboardCharts
        .filter((dashboard: IUserSettings) => {
          if(importAction === 'append') {
            return dashboard.chartsInfo.length > 0;
          }else {
            return dashboard.chartsInfo.length > 0 && dashboard.dashboardInfo.id === Number(selectedDashboard);
          }
        })
        .map((dashboard) => ({
          id: dashboard.dashboardInfo.id.toString(),
          title: dashboard.dashboardInfo.title,
          url: dashboard.dashboardInfo.url,
          chartsInfo: dashboard.chartsInfo.map((chart) => ({
            datasource: chart.datasource,
            description: chart.description,
            dashboardId: chart.dashboardId,
            width: chart.width || 400,
            height: chart.height || 400,
            permalink: chart.permalink,
            sliceId: chart.sliceId,
            sliceName: chart.sliceName,
            sequenceId: sequenceCount++,
            url: chart.url,
            vizType: chart.vizType,
          })),
        }));
        
      const payload = {
        pageName: pathUrl,
        username: JSON.parse(localStorage.getItem('user_login_info') as string).username,
        data: payloadData,
      };
      try{
        const data = await storeChartsInfo({variables: {payload: payload}});
        if(data && data?.data?.storeChartsInfo?.statusCode === StatusCode.SUCCESS){
          setMessage({text: t('Charts Imported Successfully'), id:'',type:'success'});
          if(pathUrl === 'cameradetailspage') {
            history.push(`/cameras/${pathname.split('/')[2]}`);
            localStorage.setItem('cameradetails', 'true');
          }else {
            localStorage.setItem(`${pathUrl}`, 'true');
            history.push(`/${pathUrl.substring(0, pathUrl.indexOf('page'))}`);
          }
          setImportChartsLoading(false);
        }else{
          setImportChartsLoading(false);
          setMessage({text: t('apiError'), id:'',type:'danger'});
        }
      }catch(e){
        setImportChartsLoading(false);
        setMessage({text: t('apiError'), id:'',type:'danger'});
      }
    } else {
      let sequenceCount = 1;
      const payloadData = selectedDashboardCharts.filter((dashboard: IUserSettings) => dashboard?.dashboardInfo?.chartInfo?.length && dashboard?.dashboardInfo?.chartInfo?.length > 0)
        .map((dashboard) => ({
          id: dashboard.dashboardInfo.id,
          title: dashboard.dashboardInfo.title,
          url: dashboard.dashboardInfo.url,
          uid: dashboard.dashboardInfo.uid,
          chartInfo: dashboard?.dashboardInfo?.chartInfo?.map((chart) => ({
            id: Number(chart.id),
            url: chart.url,
            title: chart.title,
            sequenceId: sequenceCount++
          }))
        }));
        
      const payload = {
        pageName: pathUrl,
        username: JSON.parse(localStorage.getItem('user_login_info') as string).username,
        data: payloadData,
      };
      try{
        const data = await storeGrafanaChartsInfo({variables: {payload: payload}});
        if(data && data?.data?.storeGrafanaChartsInfo?.statusCode === StatusCode.SUCCESS){
          setMessage({text: t('Charts Imported Successfully'), id:'',type:'success'});
          localStorage.setItem(`${pathUrl}`, 'true');
          setImportChartsLoading(false);
          history.push(`/${pathUrl.substring(0, pathUrl.indexOf('page'))}`);
        }else{
          setImportChartsLoading(false);
          setMessage({text: t('apiError'), id:'',type:'danger'});
        }
      }catch(e){
        setImportChartsLoading(false);
        setMessage({text: t('apiError'), id:'',type:'danger'});
      }
    }
  };

  return (
    <SupersetChartsWrapper>
      { message && <AlertBar message={message.text} type={message.type as IconType} setMessage={setMessage} />}
      { modalOpen &&  
      <Modal isModalOpen={modalOpen} closeModal={setModalOpen} width='500px'>
        <ConfirmationModalContainer>
          <ConfirmationModalHeader>
            <IconDiv>
              <AiOutlineBarChart size={24} color='success'/>
            </IconDiv>
            <HeaderText>{t('Import Charts')}</HeaderText>
          </ConfirmationModalHeader>
          <ConfirmDesc>{t('Would you like to replace the existing charts or append the new dashboard charts?')}</ConfirmDesc>
          <RadioButtonContainer>
            <RadioButton 
              id='append'
              value='append'
              label={t('Append')} 
              name='appendCharts'
              checked={importAction === 'append'}
              onChangeCallback={() => {setImportAction('append');}}
              currentChecked={importAction}
            />
            <RadioButton 
              id='override'
              value='override'
              label={t('Override')} 
              name='overrideCharts'
              checked={importAction === 'override'}
              onChangeCallback={() => {setImportAction('override');}}
              currentChecked={importAction}
            />
          </RadioButtonContainer>
          <ButtonsWrapper>
            <ButtonWithLoading loading={importChartsloading} disabled={importChartsloading} variant='primary' onClick={handleImportNewCharts}>{t('Import')}</ButtonWithLoading>
            <Button variant='secondary' onClick={() => setModalOpen(false)}>{t('Cancel')}</Button>
          </ButtonsWrapper>
        </ConfirmationModalContainer>
      </Modal>}
      {config.Visualization_Tool === 'Superset' ? (loading && dashboards !== null) ?           
        <SpinnerBox>
          <CircularProgress />
          <Label labelText={t('Fetching dashboards')}/>
        </SpinnerBox> : 
        (dashboards!== null && dashboards.length !== 0) ?
          <DashboardWrapper>
            <Wrapper>
              <SelectField label='' placeholder={t('Please Select Dashboard')} options={dashboards.length > 0 ? dashboards.map((dashboard) => {
                return {name: dashboard.title, value: dashboard.id} as IOption;
              }) : []} defaultValue={ (dashboards.length > 0 && selectedDashboard !== '') ? dashboards.filter((dashboard) => dashboard?.id.toString() === selectedDashboard)[0]?.id : ''} onChange={handleDashboardChange}/>
            </Wrapper>
            <AllChartsWrapper>
              <ChartsWrapper>
                { 
                  dashboards.filter((dashboard) => dashboard.id === Number(selectedDashboard))[0]?.chartsInfo
                    .map((chart) => {
                      const checkCondition = databaseData.data.some((dashboardChart) =>
                        dashboardChart.chartsInfo.some((charts) => charts.sliceId === chart.sliceId)
                      )
                        ? 'prevCharts'
                        : selectedDashboardCharts.some((dashboard) =>
                          dashboard.chartsInfo.some((userChart) => userChart.sliceId === chart.sliceId)
                        )
                          ? 'selectedCharts'
                          : '';
                      return <ChartsContainer>
                        <InfoWrapper>
                          <SingleChart checked={selectedDashboardCharts.some((dashboard) => dashboard.chartsInfo.some((userChart) => userChart.sliceId === chart.sliceId))} onChange={() => handleChartsChange(chart.sliceId)} />
                          <ChartName title={chart.sliceName}>{chart.sliceName}</ChartName>
                        </InfoWrapper>
                        <SingleChartThumbnail checked={checkCondition}><EmbeddedIframe title={chart.sliceName} src={SUPERSET_URL + chart?.permalink?.split(':9097')[1]} width={450} height={300}/></SingleChartThumbnail>
                      </ChartsContainer>;}) 
                } 
              </ChartsWrapper> 
            </AllChartsWrapper>    
            <ImportButton>
              <Button variant='primary' onClick={() => {setModalOpen(true);}}>{t('Import')}</Button>
              <Button variant='secondary' onClick={() => history.push(`/${pathUrl.substring(0, pathUrl.indexOf('page'))}`)}>{t('Back')}</Button>
            </ImportButton>
          </DashboardWrapper> : 
          <NoDashboardsMessageWrapper>
            <MessageWrapper>{t('No Dashboards are configured')}</MessageWrapper>
            <Button variant='primary' onClick={() => window.open(SUPERSET_URL, '_blank')}>{t('Manage Dashboards')}</Button>
          </NoDashboardsMessageWrapper> : <></>
      }
      {
        config.Visualization_Tool === 'Grafana' ? 
          (grafanaChartsLoading && allGrafanaCharts !== null) ? 
            <SpinnerBox>
              <CircularProgress />
              <Label labelText={t('Fetching dashboards')}/>
            </SpinnerBox> : 
            (allGrafanaCharts!== null && allGrafanaCharts?.length !== 0 && selectedDashboardCharts && selectedDashboardCharts?.length > 0) ? 
              <div>
                {
                  <Wrapper>
                    <SelectField label='' placeholder={t('Please Select Dashboard')} options={allGrafanaCharts?.length > 0 ? allGrafanaCharts?.map((grafanaChart: any) => ({name: grafanaChart.title, value: grafanaChart.id} as IOption)) : []} defaultValue={ (allGrafanaCharts?.length > 0 && selectedDashboard !== '') ? allGrafanaCharts?.filter((dashboard: any) => dashboard?.id.toString() === selectedDashboard)[0]?.id : ''} onChange={handleDashboardChange}/>
                  </Wrapper>
                }
                <GrafanaChartsWrapper>
                  { selectedDashboardCharts.length > 0 &&
                    allGrafanaCharts?.filter((dashboard:IDashboard) => dashboard.id === Number(selectedDashboard))[0]?.chartInfo
                      .map((chart: IChartsInfo) => {  
                        const checkCondition = databaseData?.data?.some((dashboardChart) => {
                          dashboardChart.chartInfo?.some((previousCharts: any) => {
                            return (previousCharts.id === chart.id && previousCharts.dashboardId === chart.dashboardId);
                          });
                          return false;
                        })
                          ? 'prevCharts'
                          : 
                          selectedDashboardCharts?.some((dashboardChart) => {
                            if (dashboardChart?.dashboardInfo?.id === Number(selectedDashboard)) {
                              return dashboardChart?.dashboardInfo?.chartInfo?.some((previousCharts: any) => {
                                return previousCharts.id === chart.id;
                              });
                            }
                            return false;
                          })
                            ? 'selectedCharts'
                            : '';
                        const updatedChartUrl = chart.url.replace('/grafana/grafana', '/grafana');
                        const isChecked = selectedDashboardCharts?.some((dashboardChart) => {
                          if (dashboardChart?.dashboardInfo?.id === Number(selectedDashboard)) {
                            return dashboardChart?.dashboardInfo?.chartInfo?.some((previousCharts: any) => {
                              return previousCharts.id === chart.id;
                            });
                          }
                          return false;
                        });
                        return <GrafanaSingleChartWrapper>
                          <InfoWrapper>
                            <SingleChart checked={isChecked} onChange={() => handleChartsChange(chart.id as number)} />
                            <ChartName title={chart.title}>{chart.title}</ChartName>
                          </InfoWrapper>
                          <SingleChartThumbnail checked={checkCondition}> <IFrameGrafana src={hostIp + updatedChartUrl + '&theme=light&orgId=2'} title='Grafana Chart' width={824} height={400}></IFrameGrafana></SingleChartThumbnail>
                        </GrafanaSingleChartWrapper>;}) 
                  } 
                </GrafanaChartsWrapper>
                <ImportButton>
                  <Button variant='primary' onClick={() => {setModalOpen(true);}}>{t('Import')}</Button>
                  <Button variant='secondary' onClick={() => history.push(`/${pathUrl.substring(0, pathUrl.indexOf('page'))}`)}>{t('Back')}</Button>
                </ImportButton>
              </div>: 
              <NoDashboardsMessageWrapper>
                <MessageWrapper>{t('No Dashboards are configured')}</MessageWrapper>
                <Button variant='primary' onClick={() => window.open(SUPERSET_URL, '_blank')}>{t('Manage Dashboards')}</Button>
              </NoDashboardsMessageWrapper> : <></>
      }
    </SupersetChartsWrapper>
  );
};

export default ImportChartPage;