import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgParkingViolation = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.252 23.08v-1.52h10.52v1.93c0 .23 0 0 .06.18h-10.63c0-.29.06-.29.06-.58l-.01-.01Zm11.57.23v-1.75h2.69c0 .58.06 1.58-.06 2.04-.53.12-1.75.06-2.34.06-.18 0-.29-.18-.29-.35Zm-15.36 0v-1.75h2.63c0 .58.06 1.58 0 2.04-.53.12-1.81.06-2.4.06-.12 0-.23-.18-.23-.35Zm0-5.61c0-1.05.29-1.93 1.29-2.28.58-.29 2.63-.18 3.45-.18 0 .29.18.76.23 1.11.12.41.18.64.35.99.29.53.99 1.05 1.75 1.05h3.74c.88 0 1.4-.47 1.75-.99.23-.35.58-1.69.64-2.16h2.92c.99 0 1.93.99 1.93 2.04v3.21h-18.06v-2.8l.01.01Zm10.75-.35h-3.45c-.58 0-.93-.29-1.11-.7-.12-.23-.35-1.11-.35-1.4h6.25c-.12.35-.18.93-.35 1.34-.12.47-.53.76-.99.76Zm-4.85-7.24h6.19c1.34 0 2.34.88 2.86 1.99l.88 2.04h-13.67c.35-.64.93-2.22 1.23-2.63.3-.41.58-.7.99-.93.35-.23.99-.47 1.52-.47ZM7.611 9.06v14.61c-1.17 0-.76 1.05-.35 1.05h24.36c.41 0 .64-.93-.06-.99v-5.2c0-1.87.06-2.69-1.29-3.74l-.12-.12c1.58 0 1.64.18 1.64-1.4 0-.93-1.11-.88-1.11-.06v.41c-1.81 0-1.29.18-1.75-.82l-.76-1.64c-.53-.88-1.11-1.34-1.99-1.75-.53-.29-1.29-.41-2.1-.41h-5.32c-1.75 0-2.8.7-3.68 1.69-.29.35-1.29 2.57-1.34 2.92h-1.52c0-.64.12-.99-.64-.99-.58 0-.41 1.23-.41 1.52 0 .7 1.05.53 1.58.53-.12.23-.76.47-1.11 1.46-.29.7-.23 1.4-.23 2.4 0 .7-.12 4.85.06 5.14h-2.8V9.06h-1.06Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.87 18.339c-.64 0-.64-.99 0-.99.12 0 .29.06.35.12.18.18.23.47 0 .7-.06.12-.18.18-.35.18v-.01Zm-.18 1.05h.29c.82 0 1.46-.7 1.46-1.58 0-1.93-2.92-2.22-3.15-.06-.06.41.18.88.41 1.11.18.23.58.53.99.53ZM15.082 18.34c-.7 0-.7-.99 0-.99.64 0 .7.99 0 .99Zm-1.58-.7v.35c0 .7.76 1.4 1.4 1.4h.35c.7 0 1.4-.76 1.4-1.46v-.23c0-1.05-1.46-2.04-2.69-1.05-.18.18-.47.64-.47.99h.01ZM5.497 1.287h5.61v2.51c0 1.46.06 2.69.06 4.15h-5.67v-6.66Zm-1.05-.53v7.77c.06.53.7.53 1.05.53h5.49c.64 0 1.17-.41 1.17-.99v-7.02c0-.35-.47-.82-.82-.82h-6.43c-.29 0-.47.23-.47.53h.01Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.236 4.318h-.47v-.99c.41 0 1.11-.12 1.11.47 0 .35-.29.53-.64.53v-.01Zm-1.58-1.29c0 .64-.12 3.27.06 3.62.23.41 1.05.41 1.05-.41v-.88h.53c1.58 0 2.1-1.69 1.23-2.63-.58-.58-1.46-.47-2.34-.47-.41 0-.53.35-.53.76v.01ZM15.538 3.798c0 1.11-.12 1.75-.7 2.63-.23.41-.41.58-.58.82-.35.47.12.82.29.82.41 0 .47 0 .99-.7.64-.88 1.17-2.28 1.11-3.33-.06-1.4-.41-2.34-1.17-3.33-.47-.64-.58-.7-.99-.7-.18 0-.64.41-.29.88.12.12.23.18.35.35.53.7.99 1.52.99 2.57v-.01ZM11.281 2.798c0 .64.47.41.47 1.29 0 .58-.47.76-.47 1.11 0 .35.23.53.58.53.53 0 .99-.99.99-1.52 0-.7-.12-1.11-.58-1.64-.35-.47-.99-.23-.99.23ZM1.113 3.8c0 1.11.12 1.75.7 2.63.23.41.41.58.58.82.35.47-.12.82-.29.82-.41 0-.47 0-.99-.7-.64-.89-1.16-2.29-1.11-3.34.06-1.4.41-2.34 1.17-3.33.47-.64.58-.7.99-.7.18 0 .64.41.29.88-.12.12-.23.18-.35.35-.53.7-.99 1.52-.99 2.57ZM5.383 2.798c0 .64-.47.41-.47 1.29 0 .58.47.76.47 1.11 0 .35-.23.53-.58.53-.53 0-.99-.99-.99-1.52 0-.7.12-1.11.58-1.64.35-.47.99-.23.99.23Z"
        fill={props.color}
      />
    </svg>
  );
};
export default SvgParkingViolation;
