import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import Icon from './Icon';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ALL_ALGORITHMS, GET_LATEST_ALERTS  } from 'api_configs/queries';
import { useTranslation } from 'react-i18next';
import { getTimeSince } from 'utils';
import AwaitingStreamEng from '../svgs/Awaiting_Stream-ENG.svg';
import Thumbnail from './Thumbnail';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ALERT_IMG_BASE_URL, ALERT_STATUS_VALUES, IconType, PERMISSION_CODE, StatusCode } from '../constants';
import CircularProgress from '@mui/material/CircularProgress';
import { IAlertMessageType } from 'interface';
import AlertBar from './AlertBar';
import { useClickOutside } from 'hooks/useClickOutside';
import TokenService from 'api_configs/tokenService';
import ButtonWithLoading from './ButtonWithLoading';
import Button from './Button';
import ConfirmationModal from './modals/ConfirmationModal';
import { DISMISS_ALL_ALERTS } from 'api_configs/mutations';

const AlertIconWrapper = styled.div<{hasAlert: boolean}>`
  position: relative;
  display: inline-block;
  > div > svg {
    cursor: ${({ hasAlert }) => hasAlert ? 'pointer' : 'not-allowed'};
    opacity: ${({ hasAlert }) => hasAlert ? '1' : '0.5'};
  }
`;

const AlertCount = styled.div<{hasAlert: boolean}>`
  position: absolute;
  right: -19px;
  top: -9px;
  border-radius: 50%;
  height: 21px;
  width: 30px;
  min-width: 14px;
  padding: 4px;
  font-size: 10px;
  color: white;
  display: ${({ hasAlert }) => hasAlert ? 'flex' : 'none'};
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  transition: background-color 0.7s ease 0s;
  background-color: #435FCA;
  cursor: pointer;
`;

const ActiveAlertsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 15px 10px;
  border-radius: 4px;
  align-items: center;
  background-color: #D0D7F2;
  width: 248px;
  > button {
    min-width: unset;
  }
`;

const AlertDetails = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  cursor: pointer;
`;

const ViewAllAlertsWrapper = styled.div`
  position: absolute;
  bottom: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 16px;
  border-radius: 4px;
  background-color: #D0D7F2;
  z-index: 999;
`;

const CameraName = styled.div`
  font-size: 16px;
  color: #575757;
  width: 140px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 10px;
`;

const CameraSubTitle = styled.div`
  font-size: 14px;
  color: #575757;
  width: 130px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 10px;
`;

const ActiveAlertsLabel = styled.div`
  font-size: 18px;
`;

const AlertDrawerContainer = styled.div<{isOpen: boolean}>`
  display: ${({isOpen}) => isOpen ? 'block' : 'none'};
  margin-top: 12px;
  position: fixed; 
  height: 100%;
  right: 0;
  background-color: #D0D7F2;
  z-index: 999;
`;

const AlertsContainer = styled.div`
  position: fixed;
  right: 0;
  width: 248px;
  height: calc(100% - 180px);
  padding-bottom: 4%;
  overflow: auto;
  z-index: 9;
  > div {
    > div {
      overflow: hidden !important;
    }
  }
`;

const AlertDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 16px 10px;
  border-radius: 10px;
  background-color: #F5F8FF;
  margin: 8px 4px;
`;

const StreamWrapper = styled.div`
  border-radius: 5px;
  overflow: hidden;
  height: 40px;
  width: 40px;
  flex-shrink: 0;
  margin-top: 5px;
  cursor: pointer;
  > img {
    border-radius: 5px;
    object-fit: cover;
  }
  > div > img {
    margin-left: -13px;
  }
`;

const AlertWrapper = styled.div``;

const NotificationLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  border-top: 1px solid rgb(238, 238, 238);
  border-bottom: 1px solid rgb(238, 238, 238);
  color: rgb(87, 87, 87);
  padding: 10px 0px 10px 20px;
`;

const LatestTime = styled.div`
  font-size: 12px;
  color: rgb(171, 171, 171);
`;

const LoadingWrapper = styled.div`
  text-align: center;
`;

interface IAlertData {
  id?: number;
  displayName?: string;
  message?: string;
  lastUpdate?: string;
  imagePath?: string;
  instanceId?: string;
  status?: number;
  streamId?: string;
  eventType?: string;
}

interface IEvents {
  code: string;
  displayName?: string;
  actions: IActions[]
}

interface IActions {
  code: string;
  displayName?: string;
 }

interface IAlgorithmConfigDetails {
  id?: number,
  name?: string,
  code?: string,
  description?: string,
  events: IEvents[],
  actions: IActions[]
}

interface IEventInfo {
  code?: string,
  displayName?: string,
  description?: string
}

const AlertDrawer = () =>{
  const [latestLowestId, setLatestLowestId] = useState(0);
  const {data, refetch} = useQuery(GET_LATEST_ALERTS, {variables: { payload: { alertId: undefined } }});
  const [dismissAllAlerts]= useMutation(DISMISS_ALL_ALERTS);
  const [alertDrawer, setAlertDrawer] = useState(false);
  const history = useHistory();
  const [alertData, setAlertData] = useState<IAlertData[]>([]);
  const {t} = useTranslation(['common']);
  const [hasMore, setHasMore] = useState(false);
  const [alertCount, setAlertCount] = useState(0);
  const [usePollAPI, setUsePollAPI] = useState(true);
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'});
  const [loading, setLoading] = useState<boolean>(false);
  const drawerRef = useRef<HTMLDivElement>(null);
  useClickOutside(drawerRef, () => setAlertDrawer(false));
  const [algorithmConfigDetails, setAlgorithmConfigDetails] = useState<IAlgorithmConfigDetails[]>([]);
  const { data: allAlgorithms } = useQuery(GET_ALL_ALGORITHMS);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      await refetch({ payload: { alertId: undefined } });
      setAlertData(data?.getLatestAlert?.result?.filter((item: IAlertData) => item.status === ALERT_STATUS_VALUES.Active));
      setAlertCount(data?.getLatestAlert?.count);
    };

    const interval = setInterval(() => {
      if(usePollAPI){
        fetchData();
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [refetch, data?.getLatestAlert, usePollAPI, allAlgorithms]);

  useEffect(() => {
    if (data?.getLatestAlert?.statusCode === StatusCode.SUCCESS) {
      const activeAlerts = data?.getLatestAlert?.result?.filter((item: IAlertData) => item.status === ALERT_STATUS_VALUES.Active);
      if (activeAlerts?.length > 0) {
        const latestAlertId = Math.min(...activeAlerts.map((alert: IAlertData) => alert.id));
        setLatestLowestId(latestAlertId);
        const newAlerts = activeAlerts.filter((alert: IAlertData) => !alertData?.some((existingAlert) => existingAlert.id === alert.id));
        if (newAlerts.length > 0) {
          setAlertData((prevAlerts) => [...(prevAlerts || []), ...newAlerts]);
        }
      }
      setHasMore(true);
      setAlertCount(data?.getLatestAlert?.count);
    }
    if(data?.getLatestAlert?.statusCode === StatusCode.FAILED){
      setHasMore(false);
    }
  }, [data, alertData]);

  const handleAlertIconClick = () =>{
    if(alertData?.length){
      setAlertDrawer((prev) => !prev);
    } else {
      return;
    }
  };

  useEffect(()=>{
    if(allAlgorithms?.getAllAlgorithm?.statusCode === StatusCode.SUCCESS && allAlgorithms?.getAllAlgorithm?.result.length > 0){
      const result = allAlgorithms?.getAllAlgorithm?.result;
      setAlgorithmConfigDetails(result);
    }
  },[allAlgorithms]);

  const onDismissClick = useCallback(async () => {
    setLoading(true);
    try {
      const res = await dismissAllAlerts({ variables: {dismissAll: true}});
      if (res.data.dismissAllAlert.statusCode === StatusCode.SUCCESS) {
        await refetch({ payload: { alertId: undefined } });
        setAlertData(data?.getLatestAlert?.result?.filter((item: IAlertData) => item.status === ALERT_STATUS_VALUES.Active));
        setAlertDrawer(false);
        setMessage({ text: t('Alerts resolved successfully'), id: '', type: 'success' });
        setLoading(false);
      } else {
        setMessage({ text: t('apiError'), id: '', type: 'danger' });
      }
    } catch (err) {
      console.error(err);
      setMessage({ text: t('apiError'), id: '', type: 'danger' });
    } finally {
      setLoading(false);
    }  
  }, [dismissAllAlerts, data, refetch, t]);
  

  const fetchMoreData = useCallback(() => {
    setUsePollAPI(false);
    if (latestLowestId !== null) {
      refetch({ payload: { alertId: latestLowestId }});
    }
    const resumePollingTimeout = setTimeout(() => {
      setUsePollAPI(true);
      refetch({ payload: { alertId: undefined } });
    }, 30000);
  
    return () => clearTimeout(resumePollingTimeout);
  }, [refetch, latestLowestId]);

  const getEventName = useCallback((code: string) => {
    const event = algorithmConfigDetails.map((item: IAlgorithmConfigDetails) => item?.events.find((event: IEvents) => event.code === code)).find((event?: IEventInfo) => event !== undefined);
    return event ? event.displayName : null;
  },[algorithmConfigDetails]);

  const onCancelYes = useCallback(()=>{
    onDismissClick();
  },[onDismissClick]);

  const onCancelNo = () => {
    setAlertDrawer(true);
  };

  return(
    <AlertWrapper ref={drawerRef}>
      {message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
      {
        modalOpen && 
        <ConfirmationModal modalOpen={modalOpen} setModalOpen={setModalOpen} onSubmit={onCancelYes} onCancelNo={onCancelNo} hasConfirmationField={false}
          titleText={t('Resolve Alerts?')} actionName='Resolve' loading={loading} confirmDescription={t('Are you sure you want to resolve all alerts?')} />}
      <AlertIconWrapper onClick={handleAlertIconClick} hasAlert={alertCount > 0 ? true : false} >
        <AlertCount hasAlert={alertCount > 0 ? true : false} >{alertCount > 999 ? '999+' : alertCount === 0 ? '' : alertCount}</AlertCount>
        <Icon icon='Bell' size={24} />
      </AlertIconWrapper>
      <AlertDrawerContainer isOpen={alertDrawer}>
        <ActiveAlertsWrapper>
          <ActiveAlertsLabel>{t('Active Alerts')}</ActiveAlertsLabel>
          { TokenService.hasAccess(PERMISSION_CODE.alerts_dismiss_all) && <ButtonWithLoading loading={loading} variant='primary' onClick={() => setModalOpen(!modalOpen)} type='submit'>{t('Resolve All')}</ButtonWithLoading>}
        </ActiveAlertsWrapper>
        <AlertsContainer id='parentDiv'>
          <InfiniteScroll 
            scrollableTarget='parentDiv'
            dataLength={alertData?.length || 0}
            next={fetchMoreData}
            hasMore={hasMore} 
            loader={<LoadingWrapper><CircularProgress size={30} /></LoadingWrapper>}
          >
            {alertData?.map((item, index) => (
              <AlertDetailsContainer key={index}>
                <StreamWrapper><Thumbnail src={item?.imagePath ? ALERT_IMG_BASE_URL + item?.imagePath : AwaitingStreamEng} size={40} /></StreamWrapper>
                <AlertDetails onClick={() => history.push(`/cameras/${item.streamId}`)}>
                  <CameraName>{t(getEventName(item.eventType!) as string)}</CameraName>
                  <CameraSubTitle>{item?.displayName}</CameraSubTitle>
                  <LatestTime>{item?.lastUpdate ? getTimeSince(item?.lastUpdate) : ''}</LatestTime>
                </AlertDetails>
              </AlertDetailsContainer>
            ))}
          </InfiniteScroll>
        </AlertsContainer>
        {alertData?.length > 0 && 
        <ViewAllAlertsWrapper>
          <Button variant='primary' onClick={() => {setAlertDrawer(false); history.push('/alerts');}}>{t('View All Alerts')}</Button>
        </ViewAllAlertsWrapper> }
        {(alertData?.length === 0 || alertData === undefined || alertData === null) && <NotificationLabel>{t('NO NEW NOTIFICATIONS')}</NotificationLabel> }
      </AlertDrawerContainer>
    </AlertWrapper>
  );
};

export default AlertDrawer;
