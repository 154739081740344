import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import { DOWNLOAD_ALERTS, GET_ALL_ALERTS, GET_ALL_ALGORITHMS } from 'api_configs/queries';
import Icon from 'components/Icon';
import { ALERT_STATUS_VALUES, SERVER_URL, StatusCode, IconType, AlertMessageType, ALERT_IMG_BASE_URL, PERMISSION_CODE, ADVANCE_FILTER_KEYNAMES, DATE_TIME_FORMAT } from '../constants';
import { useHeader } from 'contexts/HeaderContext';
import { IAlertMessageType, IAlertTableData, IGetAllAlgorithmRes } from 'interface';
import React, { useEffect, useState, useMemo, useCallback, useRef, useContext} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import styled, { keyframes } from 'styled-components';
import SearchInputFilter, { IAppliedFilter, ISearchQueryKeys } from 'components/SearchInputFilter';
import { DataGrid, GridColDef, GridRowSelectionModel, GridSortModel } from '@mui/x-data-grid';
import Thumbnail from 'components/Thumbnail';
import { DELETE_ALERTS, UPDATE_ALERT_PINNED_STATUS, UPDATE_ALERT_STATUS } from 'api_configs/mutations';
import AlertBar from 'components/AlertBar';
import Modal from 'components/Modal';
import Switch from 'components/Switch';
import { getOnlyDate, getTimeSince } from 'utils';
import AwaitingStreamEng from '../svgs/noImage.svg';
import Config from '../configs/config.json';
import Pagination from 'components/Pagination';
import Button from 'components/Button';
import ButtonWithLoading from 'components/ButtonWithLoading';
import TokenService from 'api_configs/tokenService';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import ResolveReopenModal from './modals/ResolveReopenModal';
import { PiPushPinSimpleLight } from 'react-icons/pi';
import { PiPushPinSimpleSlashFill } from 'react-icons/pi';
import { LuPanelLeftOpen, LuPanelRightOpen } from 'react-icons/lu';
import { HiOutlineBellAlert } from 'react-icons/hi2';
import { CiCircleChevLeft, CiCircleChevRight } from 'react-icons/ci';
import { ScrollBarCss } from 'CommonStyles';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import SelectField from './SelectField';
import { IoMdRefresh } from 'react-icons/io';
import { css } from 'styled-components';
import { RiChatDeleteLine } from 'react-icons/ri';
import IgnoreAlertModal from './modals/IgnoreAlertModal';
import { Link } from 'react-router-dom';
import { AlertsContext } from 'contexts/AlertsContext';
import { getAlgorithmName } from 'utils/utils';
import { format } from 'date-fns';

const MainContainer = styled.div<{isCameraPageAlerts:boolean, isAlertsTab: boolean}>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: ${(prop) => !prop.isAlertsTab ? '100%' : prop.isCameraPageAlerts  ? '750px' : '100%'};
  padding: ${(prop) => !prop.isAlertsTab ? '0px' : prop.isCameraPageAlerts ? '5px 0px 20px 0px' : '5px 0 5px 0'};
  @media only screen and (min-width: 1366px){
    width: ${(prop) => !prop.isAlertsTab ? '100%' : prop.isCameraPageAlerts  ? '560px' : '100%'};
  }
  @media only screen and (min-width: 1920px){
    width: ${(prop) => !prop.isAlertsTab ? '100%' : prop.isCameraPageAlerts  ? '750px' : '100%'};
  }
`;

const AlertsPageContainer = styled.div<{isCameraPageAlerts: boolean, isAlertsTab: boolean}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  @media only screen and (min-width: 1920px){
    width: ${(prop) => !prop.isAlertsTab ? '100%' : prop.isCameraPageAlerts  ? '500px' : '100%'};
  }
  @media only screen and (min-width: 1920px){
    width: ${(prop) => !prop.isAlertsTab ? '100%' : prop.isCameraPageAlerts  ? '750px' : '100%'};
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  min-height: 70vh;
  gap: 10px;
`;

const SpinnerBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 89vh;
  align-items: center;
  justify-content: center;
`;

const Label = styled.span``;

const AlertsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const RefreshIntervalSection = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const SelectFieldWrapper = styled.div`
  >div {
    margin-bottom: 0;
    width: 72px;
    >div>select {
      height: 34px;
      padding: 8px 24px 8px 8px;
      cursor: pointer;
    }
    >div > div {
      right: 8px;
    }
  }
`;

const AlertsStatusTabsWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: fit-content;
`;

const AlertsStatusTabs = styled.div`
  border: 2px solid ${({theme}) => theme.divider};
  display: flex;
  border-radius: 6px;
  height: 40px;
`;

const AlertsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
`;

const StatusTab = styled.div<{isSelected: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 10px;
  cursor: pointer;
  background-color: ${({isSelected}) => isSelected ? '#FFFFFF' : '#F5F8FF'};
  color: ${({isSelected}) => isSelected ? '#1E40C0' : ''};
  min-width: 170px;
`;

const AlertStatusIndicator = styled.div<{color?: string}>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({color}) => color};
`;

const VerticalDivider = styled.div`
  width: 2px;
  background-color: ${({theme}) => theme.divider};
`;

const AlertStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const AlertsTableWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const SingleAlertContainer = styled.div`
  flex: 2;
  width: 100%;
  border: 1px solid ${({theme}) => theme.divider};
  border-radius: 6px;
  background-color:#F9F9F9 ;
`;

const AlertDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 10px;
  `;

const ImageContainer = styled.div`
  overflow: hidden;
  position: relative;
`;

const ImagesWrapper = styled.div<{translateValue: number}>`
  display: flex;
  transition: transform 0.5s ease-in-out;
  transform: translateX(${props => props.translateValue}px);
`;

const Image = styled.img<{containerWidth?: number, isCroppedImage?: boolean}>`
  width: ${({containerWidth}) => containerWidth ? `${containerWidth}px` : '100%'};
  object-fit: ${({isCroppedImage}) => isCroppedImage ? 'scale-down': 'contain'};
  border-radius: 6px;
  background-color: #E8E8E8;
  min-width: 100%;
  height: 400px;
  @media only screen and (min-width: 1366px){
    height: 214px;
  }
  @media only screen and (min-width: 1920px){
    height: 400px;
  }
`;

const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }
`;

const ArrowButtonModal = styled(ArrowButton)`
  color: #3a3a3a;
  background-color: #FFF;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
  &.left {
    left: -30px;
  }

  &.right {
    right: -30px;
  }
`;

const NoAlertDiv = styled.div`
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 60px;
  gap: 10px;
`;

const P = styled.p`
  text-align: center;
  max-width: 50%;
  color: ${({theme}) => theme.text.secondaryText};
`;

const AlertsTableContainer = styled.div<{isCameraPageAlerts: boolean}>`
  flex:  ${({isCameraPageAlerts}) => isCameraPageAlerts ? 2 : 3};
  width: ${({isCameraPageAlerts}) => isCameraPageAlerts ? '50%' : '60%'};
  .selected-row {
      background-color: #f0f0f0;
    }
  .custom-cell {
    ${({isCameraPageAlerts}) => isCameraPageAlerts ? css`
      min-height: 140px !important;
      max-height: 140px;
    `: css`
      min-height: 80px !important;
      max-height: 80px;
    `}
  }
  .MuiDataGrid-row{
    ${({isCameraPageAlerts}) => isCameraPageAlerts ? css`
      min-height: 140px !important;
      max-height: 140px;
    `: 
    css`
      min-height: 85px !important;
      max-height: 100px;
    `}
    > div:nth-child(2){
    display: flex;
    justify-content: right;
  }
  }
  .MuiDataGrid-root {
    height: 100vh;
  }
  .MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeaders{
    background-color: #F5F8FF;
  }
  .MuiDataGrid-virtualScrollerContent {
    min-height: unset !important;
    height: auto !important;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle{
    font-weight: 600;
  }
  > div:nth-child(1) > div:nth-child(2){
    display: none;
  }
  > div:first-child {
    > div:nth-child(1) {
      > div:nth-child(2){
        min-height: 100px;
        max-height: 98vh;
        overflow-y: auto;
        ${ScrollBarCss};
      }
    }
  }
  > div > div:first-child{
    > div:first-child{
      font-size: 14px;
      color: #5a6269;
      > div > div > div > div:nth-child(2) > svg {
        display: none;
      }
    }
    > div:nth-child(2) {
      text-align: center;
      font-size: 14px;
      text-decoration: none;
      color:#7b8288;
    }
  }
`;

const NoAlertsWrapper = styled.div`
  width: 100%;
  border: 1px solid ${({theme}) => theme.divider};
  border-radius: 10px;
  height: 300px ;
  display: flex ;
  justify-content: center ;
  align-items: center ;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 10px 40px;
  align-items: center;
`;

const SwitchLabel = styled.div`
  min-width: 100px;
  margin-bottom: auto;
  font-size: 20px;
`;

const EnableCameraWrapper = styled.div`
  display: flex;
  column-gap: 20px;
`;

const DownloadAlertSettingLabel = styled.div`
  font-size: 22px;
`;

const PaginationBox = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  padding-bottom: 10px ;
`;

const AlertInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  max-width: 100%;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const AlertInfoCommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  margin-bottom: 10px;
  max-width: 100%;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const AlertInfoLabel = styled.div<{isCameraAlert:boolean}>`
  font-weight: 400;
  font-size: ${({isCameraAlert}) => isCameraAlert ? '12px' : '14px'};
  color: ${({theme}) => theme.text.secondaryText};
`;

const AlertInfoText = styled.div<{isCameraAlert:boolean, noWrap?: boolean, isBold?: boolean}>`
  font-weight: ${({isBold}) => isBold ? '600' : '500'};
  font-size: ${({isCameraAlert}) => isCameraAlert ? '14px' : '16px'};
  color: ${({theme}) => theme.text.primaryText};
  white-space: ${({noWrap}) => !noWrap ? 'nowrap' : 'normal'};
  text-overflow: ${({noWrap}) => !noWrap ? 'ellipsis' : 'clip'};
  overflow: hidden;
  max-width: ${({isCameraAlert, noWrap}) => !noWrap ? '100%' : isCameraAlert ? '142px' : '232px'};
  word-wrap: break-word;
`;

const MetaWrapper = styled.div<{isCameraAlerts: boolean, wrap?: boolean}>`
  display: flex;
  gap: ${({isCameraAlerts}) => isCameraAlerts ? '10px' : '40px'};
  flex-wrap: ${({wrap}) => wrap ? 'wrap' : ''};
  max-width: 100%;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ActionIcons = styled.div<{isCameraPageAlerts: boolean}>`
  display: flex;
  column-gap: 5px;
  > button {
    height: ${(props) => (props.isCameraPageAlerts ? '24px' : '')};
    font-size: ${(props) => (props.isCameraPageAlerts ? '12px' : '')};
    padding: ${(props) => (props.isCameraPageAlerts ? '4px' : '')};
  }
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const DownloadIconWrapper = styled.div<{isCameraPageAlerts?: boolean, loading?: boolean }>`
  cursor: pointer;
  width: ${(props) => (props.isCameraPageAlerts ? '24px' : '34px')};
  height: ${(props) => (props.isCameraPageAlerts ? '24px' : '34px')};
  border-radius: 5px;
  border: 1px solid ${({theme}) => theme.divider};
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    ${(props) =>
    props.loading &&
      css`
        animation: ${rotate} 2s linear infinite;
      `}
  }
`;

const ThumbnailWrapper = styled.div``;

const AlertDetailsWrapper = styled.div`
  max-height: 85vh;
  overflow-y: auto;
  ${ScrollBarCss};
  scrollbar-gutter: stable;
  box-sizing: border-box;
`;

const AlertThumbnailContainer = styled.div`
  display: grid;
  grid-template-columns: 75% 24%;
  gap: 8px;
`;

const ThumbnailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SmallThumbnailContainer = styled(ThumbnailContainer)`
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-top: 6px;
`;

const ThumbnailList = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

const ThumbnailsContainer = styled.div<{noPadding?: boolean}>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: ${({noPadding}) => noPadding ? '0' : '0px 10px'};
`;

const SmallThumbnailWrapper = styled.div<{isSelected: boolean, width?: string}>`
  width: ${({width}) => width ? width : '150px'};
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  padding: ${({isSelected}) => isSelected ? '4px' : '0'};
  border: ${({isSelected}) => isSelected ? '2px solid #8894c7' : ''};
`;

const NumberPlateWrapper = styled.div`
  width: fit-content;
  margin-top: 10px;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
`;

const TextualInformation = styled.div<{hasLeftBorder?: boolean}>`
  height: 100%;
  border-left: ${({hasLeftBorder}) => hasLeftBorder ? '1px solid #b9b9b9' : '' };
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  gap: 16px;
`;

const TextInformationContainer = styled.div<{isCameraPageAlert?: boolean}>`
  display: grid;
  grid-template-columns: ${({isCameraPageAlert}) => isCameraPageAlert ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)'} ;
  gap: 16px;
`;

const ModalThumbnail = styled.img`
  width: 100%;
  object-fit: scale-down;
  aspect-ratio: 16/9;
  margin-bottom: -4px;
  border-radius: 6px;
  background-color: #E8E8E8;
`;

const NumberPlateThumbnail = styled(ModalThumbnail)`
  height: 150px;
`;

const CurrentModalImage = styled.img`
  width: 100%;
  object-fit: scale-down;
  height: 600px;
  margin-bottom: -4px;
  border-radius: 6px;
  background-color: #E8E8E8;
`;

const CircularProgressWrapper = styled.div`
  width: 34px;
  height: 34px;
  > span{
    width: 24px !important;
    height: 24px !important;
    margin-top: 6px !important;
  }
`;

const AlertsInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start ;
  justify-content: flex-start ;
`;

const CameraName = styled.div`
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 165px;
`;

const AlertIdWrapper = styled(CameraName)<{isCameraPage?: boolean}>`
  margin-top: ${({isCameraPage}) => isCameraPage ? '': '-5px'};
`;

const CameraNameLink= styled(Link)<{isCameraAlert: boolean}>`
  margin-bottom: ${({isCameraAlert}) => isCameraAlert ? '': '18px'};
  color: #6384ca;
  text-decoration: underline;
  &:hover{
    color: #456fca;
  }
`;

const ButtonsContainer = styled.div<{isCameraPageAlerts: boolean}>`
  display: flex;
  gap: 10px;
  > button {
     height: ${(props) => (props.isCameraPageAlerts ? '30px' : '40px')};
     font-size: ${(props) => (props.isCameraPageAlerts ? '14px' : '')};;
  }
`;

const NoPermissionMessage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoAlertsTitle = styled.div`
  font-size: 22px;
`;

interface IAlertData {
  id: number;
  status: number;
  displayName: string;
  ptzErrorStatus: string;
  timestamp: string;
  imagePath: string;
  croppedImagePath: string[];
  eventType: string;
  alertData: Record<string, string | number>;
  algoCode: string;
  streamId: string;
  pinned: boolean;
  numberPlate: string;
  alertThumbnails: string[]
  statusReason: string,
  statusComments: string
}

interface IAlertStatusCount {
  [key: string]: number;
}


interface IProps {
  isCameraPageAlerts: boolean;
  isSearchQuery: boolean;
  showSingleAlertDetailsPanel?: boolean;
  streamId?: string | undefined;
  streamName?: string | undefined
}

interface IFetchAlertsArgs {
  skipLoadingState: boolean, 
  pageSize: number, 
  currentPage: number, 
  sortOrder: string | undefined | null, 
  streamId: string| undefined, 
  status: number, 
  algoCodeList: string[], 
  startDate: string, 
  endDate: string, 
  searchField: string,
  hasNumberPlate: boolean,
  alertId: number | undefined
}

const getIntialAlertRefreshInterval = (): string => {
  const storedValue = localStorage.getItem('alertRefreshInterval');
  return storedValue !== null ? JSON.parse(storedValue) : 'none';
};

const Alerts : React.FC<IProps> = ({isCameraPageAlerts, isSearchQuery, showSingleAlertDetailsPanel = false, streamId=undefined, streamName=undefined}) => {
  const [loader, setLoader] = useState(false);
  const {t} = useTranslation(['common']);
  const {updateHeaderTitle} = useHeader();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(Number(localStorage.getItem('alertsPageSize')) || 10);
  const [pageCount, setPageCount ] = useState(1);
  const {replace} = useHistory();
  const [isNewCharts, setIsNewCharts] = useState(false);
  const params = useLocation().search;
  const [historyParams] = useState(params);
  const [selectedId, setSelectedId] = useState<GridRowSelectionModel>([]);
  const [isModalOpen,setIsModalOpen] = useState(false);
  const [downloadAlertsReqData, setDownloadAlertsReqData] = useState({image: true, video: false});
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'}); 
  const [downloadAlertsRefetch] = useLazyQuery(DOWNLOAD_ALERTS);
  const [sorting, setSorting] = useState<GridSortModel>([]);
  const [updateAlertStatusMutation] = useMutation(UPDATE_ALERT_STATUS);
  const [deleteAlertsMutation] = useMutation(DELETE_ALERTS);
  const [selectedTab, setSelectedTab] = useState(ALERT_STATUS_VALUES.Active);
  const {data, loading, refetch } = useQuery(GET_ALL_ALERTS, { fetchPolicy: 'no-cache', variables: {payload: {}}});
  const [alertStatusCount, setAlertStatusCount] = useState<IAlertStatusCount>({});
  const [alertData, setAlertData] = useState<IAlertData[]>([]);
  const [alertsLoading, setAlertsLoading] = useState<boolean>(false);
  const [filteredAlgos, setFilteredAlgos] = useState<string[]>([]);
  const [filterName, setFilterName] = useState<string>('');
  const [alertId, setAlertId] = useState<string>('');
  const [dateRange, setDateRange] = useState<string[]>(['','']);
  const [algorithmList, setAlgorithmList] = useState<IGetAllAlgorithmRes[]>([]);
  const { data: allAlgorithms } = useQuery(GET_ALL_ALGORITHMS);
  const [downloadAlertsLoader, setDownloadAlertsLoader] = useState(false);
  const [deleteAlertsLoader, setDeleteAlertsLoader] = useState(false);
  const [selectedDownloadId, setSelectedDownloadId] = useState(0);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [allResolveAlertModal, setAllResolveAlertModal] = useState(false);
  const [allReOpenAlertModal, setAllReOpenAlertModal] = useState(false);
  const [singleResolveAlertModal, setSingleResolveAlertModal] = useState(false);
  const [singleReOpenAlertModal, setSingleReOpenAlertModal] = useState(false);
  const [selectedAlertId, setSelectedAlertId] = useState<number>(0);
  const [updateAlertStatusLoading, setUpdateAlertStatusLoading] = useState(false);
  const [selectedIdAndStatus, setSelectedIdAndStatus] = useState({id: 0, status: 0});
  const updateStatusAccess = TokenService.hasAccess(PERMISSION_CODE.update_alert_status);
  const downloadAlertsAccess = TokenService.hasAccess(PERMISSION_CODE.download_alert);
  const deleteAlertsAccess = TokenService.hasAccess(PERMISSION_CODE.delete_alert);
  const viewAlertsListAccess = TokenService.hasAccess(PERMISSION_CODE.alert_list);
  const [alertThumbnailModal, setAlertThumbnailModal] = useState<boolean>(false);
  const [updateAlertPinnedStatusMutation] = useMutation(UPDATE_ALERT_PINNED_STATUS);
  const [selectedPinnedId, setSelectedPinnedId] = useState(0);
  const [pinLoader, setPinLoader] = useState(false);
  const [currentAlertData, setCurrentAlertData] = useState<IAlertTableData | null>(null);
  const [currentEnlargedImage, setCurrentEnlargedImage] = useState<string>('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const [hasNumberPlate, setHasNumberPlate] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [appliedFilters, setAppliedFilters] = useState<IAppliedFilter[]>([]);
  const translateValue = -currentIndex * containerWidth;
  const [alertRefreshInterval, setAlertRefreshInterval] = useState<string>(getIntialAlertRefreshInterval);
  const [ignoreAlertModal, setIgnoreAlertModal] = useState<boolean>(false);
  const [ignoreAllAlertModal, setIgnoreAllAlertModal] = useState<boolean>(false);
  const { setAlertContextRefreshInterval } = useContext(AlertsContext);
  const [isCurrentAlertDataSelected, setIsCurrentAlertDataSelected] = useState<boolean>(false);

  useEffect(() => {
    localStorage.setItem('alertRefreshInterval', JSON.stringify(alertRefreshInterval));
    setAlertContextRefreshInterval(alertRefreshInterval);
  }, [alertRefreshInterval, setAlertContextRefreshInterval]);

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
  }, [currentAlertData]);

  useEffect(() => {
    localStorage.setItem('alertsPageSize', String(pageSize));
  }, [pageSize]);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getNewAlertData = useCallback((alert: IAlertData) => {
    const modifiedData = {
      id: alert.id, 
      message: t(AlertMessageType[alert.eventType as string]?.message), 
      unit: t(AlertMessageType[alert.eventType as string]?.unit),
      analysis: t(AlertMessageType[alert.eventType as string]?.analysis),
      meta: alert.alertData,
      timestamp: alert?.timestamp ?? '',
      status: alert?.status,
      image: alert?.imagePath,
      croppedImagePath: alert?.croppedImagePath ? alert?.croppedImagePath : null,
      streamId: alert?.streamId,
      displayName: alert?.displayName,
      pinned: alert?.pinned,
      numberPlateImage: alert?.numberPlate ? alert?.numberPlate : null,
      alertThumbnails: [alert?.imagePath, ...(alert?.croppedImagePath || [])],
      reason: alert?.statusReason ? alert?.statusReason : '-',
      comment: alert?.statusComments ? alert?.statusComments : '-',
    };
    return modifiedData;
  },[t]);

  const handleUpArrowClick = useCallback(() => {
    if (!currentAlertData) return;
    setIsCurrentAlertDataSelected(true);
    const currentIndex = alertData.findIndex(
      (alert) => alert.id === currentAlertData.id
    );
    if (currentIndex > 0 && currentIndex !== -1) {
      const newAlertData = alertData[currentIndex - 1];
      const modifiedData =  getNewAlertData(newAlertData);
      setCurrentAlertData({...modifiedData});
      setCurrentEnlargedImage(modifiedData.alertThumbnails[0]);
    }
  },[alertData, currentAlertData, getNewAlertData]);

  const handleDownArrowClick = useCallback(() => {
    if (!currentAlertData) return;
    setIsCurrentAlertDataSelected(true);
    const currentIndex = alertData.findIndex(
      (alert) => alert?.id === currentAlertData.id
    );
    if (currentIndex < alertData.length - 1 && currentIndex !== -1) {
      const newAlertData = alertData[currentIndex + 1];
      const modifiedData =  getNewAlertData(newAlertData);
      setCurrentAlertData({...modifiedData});
      setCurrentEnlargedImage(modifiedData.alertThumbnails[0]);
    } 
  },[alertData, currentAlertData, getNewAlertData]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
      case 'ArrowUp':
      case 'ArrowLeft':
        handleUpArrowClick();
        break;
      case 'ArrowDown':
      case 'ArrowRight':
        handleDownArrowClick();
        break;
      default:
        break;
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
  
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [alertData, currentAlertData, currentPage, handleUpArrowClick, handleDownArrowClick]);

  const handlePrevClick = useCallback((length: number) => {
    setCurrentIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : length - 1));
  },[]);

  const handleNextClick = useCallback((length: number) => {
    setCurrentIndex(prevIndex => (prevIndex < length - 1 ? prevIndex + 1 : 0));
  },[]);

  useEffect(()=>{
    setLoader(loading);
  },[loading]);

  useEffect(() => {
    if(isNewCharts) {
      setTimeout(() => {
        setIsNewCharts(false);
      }, 2000);
    }
  }, [isNewCharts]);

  useEffect(()=>{
    // Setting Algorithm Data
    const allAlgorithmsData: IGetAllAlgorithmRes[] = allAlgorithms?.getAllAlgorithm?.result !== null ? allAlgorithms?.getAllAlgorithm?.result: [];
    if(allAlgorithmsData?.length > 0){
      const configuredAlgos = allAlgorithmsData?.filter((algorithm)=>{
        return Config.algorithmConfigurations.configuredAlgos.indexOf(algorithm.code) !== -1;
      });
      setAlgorithmList(configuredAlgos);
    }
  },[allAlgorithms]);

  const fetchAlerts = useCallback(async ({skipLoadingState,sortOrder, currentPage, streamId, alertId, status, algoCodeList, startDate, endDate, searchField, hasNumberPlate} : IFetchAlertsArgs) => {
    if (!skipLoadingState) {
      setAlertsLoading(true);
    }
    if (refetch) {
      await refetch({
        payload: {
          pageSize,
          pageNumber: currentPage,
          sortOrder: sortOrder,
          streamId: streamId,
          status: status,
          algoCodeList: algoCodeList,
          startDate: startDate,
          endDate: endDate,
          searchField: searchField,
          hasNumberPlate: hasNumberPlate,
          alertId: Number(alertId) ? Number(alertId) : undefined
        },
      });
      if (!skipLoadingState) {
        setAlertsLoading(false);
      }
    }
  },[refetch, pageSize]);

  const onDeleteAlert = useCallback(async () => {
    try {
      setDeleteAlertsLoader(true);
      const result = await deleteAlertsMutation({variables: { alertIdList: selectedAlertId ? [selectedAlertId] : selectedId }});
      if(result.data.deleteAlerts.statusCode === StatusCode.SUCCESS){
        setMessage({text: t('Alerts deleted successfully'), id:'',type:'success'});
        setSelectedAlertId(0);
        setSelectedId([]);
        setCurrentAlertData(null);
        setCurrentPage(prev => {
          if(prev === 1) return prev;
          return prev - 1;
        });
        await fetchAlerts({
          skipLoadingState: true,
          pageSize,
          currentPage: currentPage,
          sortOrder: sorting[0]?.sort,
          streamId: streamId,
          status: selectedTab,
          algoCodeList: filteredAlgos,
          startDate: dateRange[0],
          endDate: dateRange[1],
          searchField: filterName,
          hasNumberPlate,
          alertId: Number(alertId) ? Number(alertId) : undefined
        }); 
        setDeleteAlertsLoader(false);
        setModalOpen(false);
      }else {
        setMessage({text: t('apiError'), id:'',type:'danger'});
        setDeleteAlertsLoader(false);
      }
    } catch(e){
      setMessage({text: t('apiError'), id:'',type:'danger'});
      setDeleteAlertsLoader(false);
    }
  },[deleteAlertsMutation, fetchAlerts, dateRange,pageSize, alertId, selectedTab,currentPage,sorting,filterName, hasNumberPlate, streamId, filteredAlgos, t, selectedId, selectedAlertId]);

  const handleChange = (value: number) => {
    setCurrentPage(value);
  };

  useEffect(()=>{
    if(!isCameraPageAlerts && isSearchQuery){
      updateHeaderTitle(t('Alerts'), 'Alert');
    }
  },[updateHeaderTitle, t, isCameraPageAlerts, isSearchQuery]);
  
  useEffect(()=>{
    if(!loading) {
      if(data?.getAlerts?.result) {
        setAlertData(data?.getAlerts?.result?.data ?? []);
        setAlertStatusCount(data?.getAlerts?.result?.statusCount);
        setPageCount(data?.getAlerts?.count ?? 0);
        if(!isCurrentAlertDataSelected && data?.getAlerts?.result?.data?.length > 0){
          setCurrentAlertData(getNewAlertData(data?.getAlerts?.result?.data[0]));
        }
      }
    }
  },[data?.getAlerts, loading, isCurrentAlertDataSelected, getNewAlertData]);

  useEffect(() => {
    fetchAlerts({
      skipLoadingState: false,
      pageSize,
      currentPage: currentPage,
      sortOrder: sorting[0]?.sort,
      streamId: streamId,
      status: selectedTab,
      algoCodeList: filteredAlgos,
      startDate: dateRange[0],
      endDate: dateRange[1],
      searchField: filterName,
      hasNumberPlate,
      alertId: Number(alertId) ? Number(alertId) : undefined
    });
    let fetchInterval: any;
    if(alertRefreshInterval !== 'none' && currentPage === 1){
      fetchInterval = setInterval(() => {
        fetchAlerts({
          skipLoadingState: true,
          pageSize,
          currentPage: currentPage,
          sortOrder: sorting[0]?.sort,
          streamId: streamId,
          status: selectedTab,
          algoCodeList: filteredAlgos,
          startDate: dateRange[0],
          endDate: dateRange[1],
          searchField: filterName,
          hasNumberPlate,
          alertId: Number(alertId) ? Number(alertId) : undefined
        });
      }, parseInt(alertRefreshInterval)*1000);
    }
  
    return () => {
      clearInterval(fetchInterval);
    };
  }, [currentPage, pageSize,fetchAlerts, sorting, alertRefreshInterval, alertId, streamId, selectedTab, filteredAlgos, dateRange, filterName, hasNumberPlate]);

  const onUpdateAlertStatus = useCallback( async(alertIdList: number[], status: number, isSingleAlert: boolean= false, reason: string='', comment: string='') =>{
    const payload = {
      alertIdList: alertIdList,
      status : status,
      reason,
      comment
    };
    try {
      setUpdateAlertStatusLoading(true);
      const result = await updateAlertStatusMutation({variables: {payload}});
      if(result.data.updateAlertsStatus.statusCode === StatusCode.SUCCESS){
        setMessage({text: t('Status updated successfully'), id:'',type:'success'});
        refetch();
        setSelectedId([]);
        if(isSingleAlert){
          setCurrentAlertData(prev => ({...prev, status: status} as IAlertTableData));
        }
      }else {
        setMessage({text: t('apiError'), id:'',type:'danger'});
      }
    } catch(e){
      setMessage({text: t('apiError'), id:'',type:'danger'});
    } finally {
      setUpdateAlertStatusLoading(false);
    }
  }, [t, updateAlertStatusMutation, refetch]);

  const handleSaveAlerts = useCallback(async (id?: number) => {
    setDownloadAlertsLoader(true);
    setDownloadLoading(true);

    const getFormattedDateTime = () => {
      const now = new Date();
      const date = now.toISOString().slice(0, 10);
      const hours = now.getHours();
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const seconds = now.getSeconds().toString().padStart(2, '0');
      const ampm = hours >= 12 ? 'pm' : 'am';
      const formattedHours = hours % 12 || 12;
      
      return `${date}-${formattedHours}-${minutes}-${seconds}-${ampm}`;
    };

    const filtersData: any = {};
    let filename = '';
    let dateRangeFilterApplied = false;
    if(streamName){
      filename+=streamName.split(' ').join('') + '-';
      filtersData['Camera Name'] = streamName;
    }
    if(appliedFilters.length > 0 ){

      // Check for Camera Name Filter on All Alert page.
      const cameraNameFilterIndex = appliedFilters.findIndex(filter => filter.keyName === 'name');
      if(cameraNameFilterIndex !== -1) {
        const cameraname = appliedFilters[cameraNameFilterIndex].query as string;
        filename+=cameraname.split(' ').join('') + '-';
        filtersData['Camera Name'] = cameraname;
      }

      // Check for Analysis Filter
      const analysisFilterIndex = appliedFilters.findIndex(filter => filter.keyName === ADVANCE_FILTER_KEYNAMES.ANALYSIS);
      if(analysisFilterIndex !== -1){
        const algos = (appliedFilters[analysisFilterIndex].query as string[]).map(algoCode => getAlgorithmName(algoCode)?.split(' ').join('')).join('_');
        filename+= algos;
        filtersData.Violation= (appliedFilters[analysisFilterIndex].query as string[]).map(algoCode => getAlgorithmName(algoCode)).join(',');
      }

      // Check for Date Range filter
      const dateRangeFilterIndex = appliedFilters.findIndex(filter => filter.keyName === ADVANCE_FILTER_KEYNAMES.DATE_RANGE);
      if(dateRangeFilterIndex !== -1){
        dateRangeFilterApplied = true;
        const dates = appliedFilters[dateRangeFilterIndex].query as string[];
        const modifiedDates = dates.map(date => format(new Date(date), DATE_TIME_FORMAT));
        filename+= modifiedDates.map(date => date.replaceAll(' ', '_').replaceAll(':', '_')).join('to');
        filtersData['Date Range'] = modifiedDates.join(' to ');
      }
    }
    try {
      const res = await downloadAlertsRefetch({variables: {payload: {alertIdList: id ? [id] : selectedId, filters: JSON.stringify(filtersData), includeImages: downloadAlertsReqData.image , includeVideos: downloadAlertsReqData.video, }}});
      if (res.data?.downloadAlerts.statusCode === StatusCode.SUCCESS) {
        const filenameWithDate = filename ? `${filename}-${dateRangeFilterApplied ? '' : getFormattedDateTime()}.zip` : `Violations-${dateRangeFilterApplied ? '' : getFormattedDateTime()}.zip`; 
        setTimeout(() =>{
          setSelectedId([]);
          setIsModalOpen(false);
          downloadLink(SERVER_URL + res.data?.downloadAlerts.result.file, filenameWithDate);
          setMessage({ text: t('Alert data downloaded successfully'), id: '', type: 'success' });
          setDownloadAlertsLoader(false);
          setDownloadLoading(false);
        },1000);
      } else {
        setMessage({ text: t('apiError'), id: '', type: 'danger' });
        setTimeout(() =>{
          setDownloadAlertsLoader(false);
          setDownloadLoading(false);
        },1000);
      }
    } catch (err) {
      console.error(err);
      setMessage({ text: t('apiError'), id: '', type: 'danger' });
      setTimeout(() =>{
        setDownloadAlertsLoader(false);
        setDownloadLoading(false);
      },1000);
    }
  },[downloadAlertsRefetch, downloadAlertsReqData, selectedId, t, streamName, appliedFilters]);

  const handleImageSwitchChange = () => {
    const updateData = {...downloadAlertsReqData, image: !downloadAlertsReqData?.image};
    setDownloadAlertsReqData(updateData);
    localStorage.setItem('Download_Alert_Setting', JSON.stringify(updateData));
  };

  const handleVideoSwitchChange = () => {
    const updateData = {...downloadAlertsReqData, video: !downloadAlertsReqData?.video};
    setDownloadAlertsReqData(updateData);
    localStorage.setItem('Download_Alert_Setting', JSON.stringify(updateData));
  };
 
  const handlePinnedAlert = useCallback(async (id: number, status: boolean | null) =>{
    setPinLoader(true);
    try {
      const data = await updateAlertPinnedStatusMutation({variables: {payload: {pinned: !status, alertIdList: [id]}}});
      if (data?.data?.updateAlertsPinnedStatus?.statusCode === StatusCode?.SUCCESS) {
        setCurrentAlertData(prev => ({...prev, pinned: !status} as IAlertTableData));
        setTimeout(() =>{
          setMessage({text: status === true ? t('Alert unpinned successfully.') : t('Alert pinned successfully.'), id:'', type:'success'});
          setPinLoader(false);
          refetch();
        },1000);
      } else {
        setTimeout(() =>{
          setMessage({text: t('apiError'), id:'',type:'danger'});
          setPinLoader(false);
        },1000);
      }
    } catch (error) {
      setTimeout(() =>{
        setMessage({text: t('apiError'), id:'',type:'danger'});
        console.error((error as Error).message);
        setPinLoader(false);
      },1000);
    }
  },[t, updateAlertPinnedStatusMutation, refetch]);


  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedId([]);
  };

  const searchQueryKeys: ISearchQueryKeys[] = [
    {keyName: 'name', displayName: t('CameraName')},
    {keyName: 'id', displayName: t('Alert Id')}
  ];


  const getAlertMetaData = useCallback((meta: Record<string, string | number>, unit: string, isBold: boolean)=> {
    const meassage: Record<string, string | number> = meta;
    const formattedItems = Object.entries(meassage ?? {}).map(([key, value]) => {
      const formattedKey = key.split('_').map((word, index) => (index === 0 ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : word.toLowerCase())).join(' ');
      return { key: formattedKey, value };
    });

    return (
      <MetaWrapper isCameraAlerts={isCameraPageAlerts} wrap={isBold}>
        {formattedItems?.map((item) => (
          <AlertInfoWrapper>
            <AlertInfoLabel isCameraAlert={isCameraPageAlerts}>{t(item.key)}</AlertInfoLabel>
            <AlertInfoText noWrap={isBold} isBold={isBold} isCameraAlert={isCameraPageAlerts}>{item.value} {item.key !== 'Label name' && unit}</AlertInfoText>
          </AlertInfoWrapper>
        ))}
      </MetaWrapper>
    );

  },[isCameraPageAlerts, t]);

  const columns: GridColDef[] = useMemo(()=>
    [
      {
        field: 'thumbnail',
        headerName: t('Image'),
        width: 70,
        sortable: false,
        renderCell: (params) => (
          <SmallThumbnailContainer>
            <ThumbnailWrapper  onClick={() => {setAlertThumbnailModal(true); setCurrentAlertData(params?.row); setCurrentEnlargedImage(params?.row?.image);}}>
              <Thumbnail disableModal src={ params?.row?.image ? ALERT_IMG_BASE_URL + params?.row?.image : AwaitingStreamEng} size={40} />
            </ThumbnailWrapper>
            <AlertIdWrapper isCameraPage={isCameraPageAlerts}>{params?.row?.id}</AlertIdWrapper>
          </SmallThumbnailContainer>
        )
      },
      { 
        field: 'alertsInfo',
        headerName: t('Alert Details'),
        flex: 2,
        headerAlign: 'left',
        align: 'left',
        sortable: false,
        renderCell: (params) => {
          const onlyDate = getOnlyDate(params?.row?.timestamp);
          const timeSince = getTimeSince(params?.row?.timestamp);
          return (
            <AlertsInfo>
              <CameraNameLink isCameraAlert={isCameraPageAlerts} to={`/cameras/${params?.row?.streamId}`}>{params?.row?.displayName}</CameraNameLink>
              <CameraName title={params?.row?.message}>{params?.row?.message}</CameraName>
              <AlertInfoLabel isCameraAlert={isCameraPageAlerts} title={onlyDate}>{onlyDate}</AlertInfoLabel>
              <AlertInfoText isCameraAlert={isCameraPageAlerts} title={timeSince}>{timeSince}</AlertInfoText>
            </AlertsInfo>
          );
        }
      },
      { 
        field: 'cameraName',
        headerName: t('Camera Name '),
        flex: 1,
        headerAlign: 'left',
        align: 'left',
        sortable: false,
        renderCell: (params) => {
          return (
            <CameraNameLink isCameraAlert={isCameraPageAlerts} to={`/cameras/${params?.row?.streamId}`}>{params?.row?.displayName}</CameraNameLink>
          );
        }
      },
      {
        field: 'numberPlate',
        headerName: t('Vehicle Number'),
        flex: 1,
        headerAlign: 'left',
        align: 'left',
        sortable: false,
        renderCell: (params) => { 
          return (
            <>
              {params?.row?.numberPlateImage !== undefined && params?.row?.numberPlateImage !== null ? 
                <ThumbnailWrapper
                  onClick={() => {
                    setAlertThumbnailModal(true);
                    setCurrentAlertData(params?.row);
                    setCurrentEnlargedImage(params?.row?.numberPlateImage);
                  }}
                >
                  <Thumbnail
                    width='70px'
                    disableModal
                    src={ALERT_IMG_BASE_URL + params?.row?.numberPlateImage}
                    size={40}
                    objectFit='scale-down'
                  />
                </ThumbnailWrapper> : null}
            </>
          );
        }
      },
      { field: 'message', headerName: t('Message'), flex: 1, headerAlign: 'left', align: 'left', sortable: false },
      {
        field: 'meta',
        headerName: t('Meta'),
        flex: 1.5, 
        headerAlign: 'left',
        align: 'left', 
        sortable: false,
        renderCell: (params) => {
          return (
            <>
              {params?.row?.meta ? getAlertMetaData(params?.row?.meta, params?.row.unit, false) : null}
            </>
          );
        }
      },
      { field: 'timestamp', 
        headerName: t('Reported Time'), 
        flex: 1.2, 
        headerAlign: 'left', 
        align: 'left',
        renderCell: (params) => {
          return (
            <AlertInfoWrapper>
              <AlertInfoLabel isCameraAlert={isCameraPageAlerts} title={getOnlyDate(params?.row?.timestamp)}>{getOnlyDate(params?.row?.timestamp)}</AlertInfoLabel>
              <AlertInfoText isCameraAlert={isCameraPageAlerts} title={getTimeSince(params?.row?.timestamp)}>{getTimeSince(params?.row?.timestamp)}</AlertInfoText>
            </AlertInfoWrapper>
          );
        }
      },
      {
        field: 'actions',
        headerName: '',
        flex: isCameraPageAlerts ? 1 : 1.3,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        renderCell: (params) => {
          return (
            <ActionIcons isCameraPageAlerts={isCameraPageAlerts}>
              {updateStatusAccess && selectedTab === ALERT_STATUS_VALUES.Active &&
                <DownloadIconWrapper title={t('Resolve')} isCameraPageAlerts={isCameraPageAlerts} onClick={() => { setSingleResolveAlertModal(!singleResolveAlertModal); setSelectedIdAndStatus({id: params.row.id, status: params?.row?.status});}} >
                  <LuPanelLeftOpen color='#18C97F' size={isCameraPageAlerts ? 16 : 24}/>
                </DownloadIconWrapper>}
              {updateStatusAccess && selectedTab === ALERT_STATUS_VALUES.Dismissed &&
                <DownloadIconWrapper title={t('Reopen')} isCameraPageAlerts={isCameraPageAlerts} onClick={() => { setSingleReOpenAlertModal(!singleResolveAlertModal); setSelectedIdAndStatus({id: params.row.id, status: params?.row?.status});}} >
                  <LuPanelRightOpen  color='#435FCA' size={isCameraPageAlerts ? 16 : 24}/>
                </DownloadIconWrapper>
              }
              {selectedPinnedId === params?.row?.id && pinLoader ? 
                <CircularProgressWrapper><CircularProgress /> </CircularProgressWrapper> :
                <DownloadIconWrapper
                  title={params?.row?.pinned ? t('Unpin') : t('Pin')}
                  isCameraPageAlerts={isCameraPageAlerts}
                  onClick={() => {handlePinnedAlert(params?.row?.id, params?.row?.pinned); setSelectedPinnedId(params?.row?.id);}}>
                  { params?.row?.pinned ? <PiPushPinSimpleSlashFill strokeWidth={8} color={'#8dd1e7'} size={isCameraPageAlerts ? 16 : 24} />: <PiPushPinSimpleLight strokeWidth={8} color={'#8dd1e7'} size={isCameraPageAlerts ? 16 : 24} />}
                </DownloadIconWrapper>  
              }
   
              { downloadAlertsLoader && selectedDownloadId === params?.row?.id ?
                <CircularProgressWrapper><CircularProgress /> </CircularProgressWrapper>
                : 
                downloadAlertsAccess && <DownloadIconWrapper title={t('Download')} isCameraPageAlerts={isCameraPageAlerts} onClick={() => {handleSaveAlerts(params?.row?.id); setSelectedDownloadId(params?.row?.id);}}>
                  <Icon icon='Download' color='primary' size={isCameraPageAlerts ? 16 : 24} />
                </DownloadIconWrapper>
              }
              { deleteAlertsAccess && (selectedTab !== ALERT_STATUS_VALUES.Pinned) &&  <DownloadIconWrapper title={t('Delete')} isCameraPageAlerts={isCameraPageAlerts} onClick={() => {setSelectedAlertId(params?.row?.id); setModalOpen(!modalOpen);}}>
                <Icon icon='Delete' color='danger' size={isCameraPageAlerts ? 16 : 24} />
              </DownloadIconWrapper>}
            </ActionIcons>
          );
        }
      }
    ]
  ,[t, getAlertMetaData, handleSaveAlerts, handlePinnedAlert, pinLoader, selectedPinnedId ,updateStatusAccess, isCameraPageAlerts, singleResolveAlertModal, downloadAlertsAccess, deleteAlertsAccess, selectedTab, modalOpen, downloadAlertsLoader, selectedDownloadId]); 

  const getConfiguredColumns = useCallback(()=>{
    const configuredColumns = columns.filter((item)=>{
      if(isCameraPageAlerts){
        return Config.CameraPageAlertColumns.indexOf(item.field) !== -1;
      }
      else if(isSearchQuery){
        return Config.AlertsColumns.indexOf(item.field) !== -1;
      }else{
        return Config.AlertTabColumns.indexOf(item.field) !== -1;
      }
    });
    return configuredColumns;
  },[columns, isCameraPageAlerts, isSearchQuery]);

  const generateRowData = useCallback(() =>{
    return alertData?.length > 0 ? alertData?.map((item: IAlertData)=>(
      { 
        id: item.id, 
        message: t(AlertMessageType[item.eventType as string]?.message), 
        unit: t(AlertMessageType[item.eventType as string]?.unit),
        analysis: t(AlertMessageType[item.eventType as string]?.analysis),
        meta: item.alertData,
        timestamp: item?.timestamp ?? '',
        status: item?.status,
        image: item?.imagePath,
        croppedImagePath: item?.croppedImagePath ? item?.croppedImagePath : null,
        streamId: item?.streamId,
        displayName: item?.displayName,
        pinned: item?.pinned,
        numberPlateImage: item?.numberPlate ? item?.numberPlate : null,
        alertThumbnails: [item?.imagePath, ...(item?.croppedImagePath || [])],
        reason: item?.statusReason ? item?.statusReason : '-',
        comment: item?.statusComments ? item?.statusComments : '-',
      }
    )) : [];
  },[alertData, t]);

  const handleRowSelectionModelChange = useCallback((params: GridRowSelectionModel) => {
    setSelectedId(params ? params : []);
  }, []);

  const setParams = useCallback(() => {
    const newDateParam = (dateRange[0] && dateRange[1]) ? dateRange : '';
    const params = '?analysis=' + filteredAlgos + '&dateRange=' + newDateParam + '&hasNumberPlate=' + hasNumberPlate + '&cameraName=' + filterName + '&alertId=' + alertId;
    return params;
  }, [filteredAlgos, dateRange, hasNumberPlate, filterName, alertId]);

  useEffect(() => {
    replace(window.location.pathname + setParams());
  }, [replace, setParams]);

  const fetchHistoryParams = useCallback(() => {
    const newFilters = [];
    const params = new URLSearchParams(historyParams);

    const filteredAlgos = params.get('analysis');
    const filteredDate = params.get('dateRange');
    const hasNumberPlateFilter = params.get('hasNumberPlate');
    const cameraName = params.get('cameraName');
    const alertIdFilter = params.get('alertId');

    if(filteredAlgos){
      setFilteredAlgos(filteredAlgos?.length ? filteredAlgos.split(',') : []);
      newFilters.push({keyName: 'analysis', displayName: 'Analysis', query: filteredAlgos?.length ? filteredAlgos.split(',') : []});
    }
    if(filteredDate) {
      setDateRange(filteredDate.length ? filteredDate.split(','): []);
      newFilters.push({keyName: 'dateRange', displayName: 'Date Range', query: filteredDate?.length ? filteredDate.split(','): []});
    }
    if(hasNumberPlateFilter) {
      setHasNumberPlate(hasNumberPlateFilter === 'false' ? false: true);
      if(hasNumberPlateFilter === 'true') {
        newFilters.push({displayName: t('Has Vehicle Number'), keyName: ADVANCE_FILTER_KEYNAMES.NUMBER_PLATE, query: true});
      }
    }
    if(cameraName) {
      setFilterName(cameraName);
      newFilters.push({keyName: 'name', displayName: 'Camera Name', query: cameraName});
    }
    if(alertIdFilter) {
      setAlertId(alertIdFilter);
      newFilters.push({keyName: 'id', displayName: 'Alert Id', query: alertIdFilter});
    }
    setAppliedFilters(newFilters);
  }, [historyParams, t]);

  useEffect(() => {
    fetchHistoryParams();
  }, [fetchHistoryParams]);

  const searchFilterCallback = useCallback((appliedFilters: IAppliedFilter[])=>{
    if(appliedFilters.length > 0){
      const analysisConfigsIndex = appliedFilters.findIndex((item) =>item.keyName === 'analysis');
      if(analysisConfigsIndex !== -1){
        setFilteredAlgos(appliedFilters[analysisConfigsIndex].query as string[]);
      }else{
        setFilteredAlgos([]);
      }
      const dateRangeFilter = appliedFilters.findIndex((item) =>item.keyName === 'dateRange');
      if(dateRangeFilter !== -1){
        const filteredDateRange = appliedFilters[dateRangeFilter].query ?? [];
        setDateRange(filteredDateRange as string[]);
      }else{
        setDateRange(['','']);
      }
      const cameraFilter = appliedFilters.findIndex((item) =>item.keyName === 'name');
      if(cameraFilter !== -1){
        const filterName = appliedFilters[cameraFilter].query ?? '';
        setFilterName(filterName as string);
      }else{
        setFilterName('');
      }
      const alertIdFilter = appliedFilters.findIndex((item) =>item.keyName === 'id');
      if(alertIdFilter !== -1) {
        const alertFilterId = appliedFilters[alertIdFilter].query ?? '';
        setAlertId(alertFilterId as string);
      }else {
        setAlertId('');
      }
      const isNumberPlateIndex = appliedFilters.findIndex(item =>item.keyName === ADVANCE_FILTER_KEYNAMES.NUMBER_PLATE);
      if(isNumberPlateIndex !== -1){
        const hasNumberPlate = appliedFilters[isNumberPlateIndex].query ?? false;
        setHasNumberPlate(hasNumberPlate as boolean);
      }else{
        setHasNumberPlate(false);
      }
      setAppliedFilters(appliedFilters);
      setCurrentPage(1);
    }else{
      setAppliedFilters([]);
      setFilteredAlgos([]);
      setDateRange(['','']);
      setFilterName('');
      setHasNumberPlate(false);
      setCurrentPage(1);
      setAlertId('');
    }
  },[]);

  const handleThumbnailError = useCallback((e:React.SyntheticEvent<HTMLElement>)=>{
    (e.target as HTMLSourceElement).src = AwaitingStreamEng;
  },[]);

  const getLoading = () => {
    return (
      <LoadingContainer>
        <CircularProgress />
        <Label>{t('Loading')}</Label>
      </LoadingContainer>
    );
  };

  const handleSortModelChange = (val: GridSortModel) =>{
    setSorting(val);
  };

  const getSelectedTabName = useCallback(() => {
    if(selectedTab === 1) {
      return 'Active Alerts';
    }else if(selectedTab === 2) {
      return 'Challans';
    }else if(selectedTab === 3) {
      return 'Ignored Alerts';
    }else if(selectedTab === 4) {
      return 'Expired Alerts';
    }else if(selectedTab === 5) {
      return 'Pinned Alerts';
    }else return '';
  }, [selectedTab]);

  const onCancelYes = useCallback(()=>{
    onDeleteAlert();
  },[onDeleteAlert]);

  const onCancelNo = () => {
    setSelectedAlertId(0);
  };

  const onAllAlertsStatusChange = useCallback((newStatus: number, reason: string='', comment: string='')=>{
    onUpdateAlertStatus(selectedId as number[], newStatus, false, reason, comment);
  },[onUpdateAlertStatus, selectedId]);

  const onSingleAlertStatusChange = useCallback((newStatus: number, reason: string='', comment: string='')=>{
    onUpdateAlertStatus([selectedIdAndStatus.id], newStatus, true, reason, comment);
  },[selectedIdAndStatus.id, onUpdateAlertStatus]);

  if ((loader) && !isCameraPageAlerts && isSearchQuery) {
    return (
      <SpinnerBox>
        <CircularProgress />
        <Label>{t('Loading...')}</Label>
      </SpinnerBox>
    );
  }

  return (
    <MainContainer isCameraPageAlerts={isCameraPageAlerts} isAlertsTab={isSearchQuery} > 
      {
        modalOpen && 
        <ConfirmationModal modalOpen={modalOpen} loading={deleteAlertsLoader} setModalOpen={setModalOpen} autoCloseAfterAction={false} onSubmit={onCancelYes} onCancelNo={onCancelNo} hasConfirmationField={false}
          titleText={t('Delete Alert?')} confirmDescription={t('Are you sure you want to delete this alert?')} noteText={t('Once deleted this action cannot be undone.')} />}
      { allResolveAlertModal && 
        <ResolveReopenModal isResolveModal={true} modalOpen={allResolveAlertModal} setModalOpen={setAllResolveAlertModal} onSubmit={() => onAllAlertsStatusChange(ALERT_STATUS_VALUES.Dismissed)} hasConfirmationField={false}
          titleText={t('Move to Challans?')} actionName='Yes' updateAlertStatusLoading={updateAlertStatusLoading} variant='success' confirmDescription={t('resolveAlertModalMessage').replace('{X}', `${selectedId.length}`)} />}
      { singleResolveAlertModal && 
        <ResolveReopenModal isResolveModal={true} modalOpen={singleResolveAlertModal} setModalOpen={setSingleResolveAlertModal} onSubmit={() => onSingleAlertStatusChange(ALERT_STATUS_VALUES.Dismissed)} hasConfirmationField={false}
          titleText={t('Move to Challans?')} actionName='Yes' variant='success' confirmDescription={t('singleResolveAlertModalMessage')} />}
      { singleReOpenAlertModal && 
        <ResolveReopenModal isResolveModal={false} modalOpen={singleReOpenAlertModal} setModalOpen={setSingleReOpenAlertModal} onSubmit={()=>onSingleAlertStatusChange(ALERT_STATUS_VALUES.Active)} hasConfirmationField={false}
          titleText={t('Reopen Alert?')} actionName='Yes' variant='primary' confirmDescription={t('Are you sure you want to reopen this alert?')} />}
      { allReOpenAlertModal && 
        <ResolveReopenModal isResolveModal={false} modalOpen={allReOpenAlertModal} setModalOpen={setAllReOpenAlertModal} onSubmit={() => onAllAlertsStatusChange(ALERT_STATUS_VALUES.Active)} hasConfirmationField={false}
          titleText={t('Reopen Alerts?')} actionName='Yes' variant='primary' confirmDescription={t('Are you sure you want to reopen all alerts?')} />}
      { ignoreAlertModal && 
        <IgnoreAlertModal titleText={t('Ignore Alert?')} updateAlertStatusLoading={updateAlertStatusLoading} modalOpen={ignoreAlertModal} setModalOpen={setIgnoreAlertModal} confirmDescription={t('singleIgnoreAlertModalMessage')} onSubmit={(reason, comment) => onSingleAlertStatusChange(ALERT_STATUS_VALUES.Ignored, reason, comment)} /> }
      { ignoreAllAlertModal && 
        <IgnoreAlertModal titleText={t('Ignore Alerts?')} updateAlertStatusLoading={updateAlertStatusLoading} modalOpen={ignoreAllAlertModal} setModalOpen={setIgnoreAllAlertModal} confirmDescription={t('ignoreAlertModalMessage').replace('{X}', `${selectedId.length}`)} onSubmit={(reason, comment) => onAllAlertsStatusChange(ALERT_STATUS_VALUES.Ignored, reason, comment)} />
      }
      {message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
      { isModalOpen &&
       <Modal isModalOpen={isModalOpen} width='32vw' closeModal={handleCloseModal}>
         <ModalContent>
           <DownloadAlertSettingLabel>{t('Download Alert Setting')}:</DownloadAlertSettingLabel>
           <EnableCameraWrapper>
             <SwitchLabel>{t('Include Images')}:</SwitchLabel>
             <Switch checked={downloadAlertsReqData.image} onChange={handleImageSwitchChange}/>
           </EnableCameraWrapper>
           <EnableCameraWrapper>
             <SwitchLabel>{t('Include Videos')}:</SwitchLabel>
             <Switch checked={downloadAlertsReqData.video} onChange={handleVideoSwitchChange}/>
           </EnableCameraWrapper>
         </ModalContent>
       </Modal>
      }
      {alertThumbnailModal && currentAlertData &&
        <Modal isModalOpen={alertThumbnailModal} closeModal={setAlertThumbnailModal} width='76vw'>
          <AlertDetailsWrapper>
            <ArrowButtonModal className="left" onClick={handleUpArrowClick}>
              <FaChevronLeft size={32} color='#3958bd'/>
            </ArrowButtonModal>
            <ArrowButtonModal className="right" onClick={handleDownArrowClick}>
              <FaChevronRight size={32} color='#3958bd'/>
            </ArrowButtonModal>
            <AlertThumbnailContainer>
              <ThumbnailContainer>
                <CurrentModalImage src={ALERT_IMG_BASE_URL + currentEnlargedImage} onError={handleThumbnailError} />
                <ThumbnailsContainer noPadding>
                  <ThumbnailList>
                    {currentAlertData?.alertThumbnails.map((url => (
                      <div>
                        <SmallThumbnailWrapper isSelected={currentEnlargedImage === url} onClick={() => setCurrentEnlargedImage(url)}>
                          <ModalThumbnail src={ALERT_IMG_BASE_URL + url} onError={handleThumbnailError} />
                        </SmallThumbnailWrapper>
                      </div>
                    )))}
                  </ThumbnailList>
                  <ActionIcons isCameraPageAlerts={isCameraPageAlerts}>
                    {updateStatusAccess && (currentAlertData?.status) !== ALERT_STATUS_VALUES.Ignored &&
                      <DownloadIconWrapper title={t('Ignore')} isCameraPageAlerts={isCameraPageAlerts} onClick={() => { setIgnoreAlertModal(!ignoreAlertModal); setSelectedIdAndStatus({id: currentAlertData?.id, status: (currentAlertData.status)});}} >
                        <RiChatDeleteLine color='#595959' size={isCameraPageAlerts ? 16 : 24}/>
                      </DownloadIconWrapper>}
                    {updateStatusAccess && (currentAlertData?.status) !== ALERT_STATUS_VALUES.Dismissed &&
                      <DownloadIconWrapper title={t('Move to Challans')} isCameraPageAlerts={isCameraPageAlerts} onClick={() => { setSingleResolveAlertModal(!singleResolveAlertModal); setSelectedIdAndStatus({id: currentAlertData?.id, status: (currentAlertData.status)});}} >
                        <LuPanelLeftOpen color='#18C97F' size={isCameraPageAlerts ? 16 : 24}/>
                      </DownloadIconWrapper>}
                    {updateStatusAccess && (currentAlertData?.status) !== ALERT_STATUS_VALUES.Active &&
                      <DownloadIconWrapper title={t('Reopen')} isCameraPageAlerts={isCameraPageAlerts} onClick={() => { setSingleReOpenAlertModal(!singleResolveAlertModal); setSelectedIdAndStatus({id: currentAlertData.id, status: (currentAlertData.status)});}} >
                        <LuPanelRightOpen  color='#435FCA' size={isCameraPageAlerts ? 16 : 24}/>
                      </DownloadIconWrapper>
                    }
                    {selectedPinnedId === currentAlertData?.id && pinLoader ? 
                      <CircularProgressWrapper><CircularProgress /> </CircularProgressWrapper> :
                      <DownloadIconWrapper
                        title={currentAlertData?.pinned ? t('Unpin') : t('Pin')}
                        isCameraPageAlerts={isCameraPageAlerts}
                        onClick={() => {handlePinnedAlert(currentAlertData?.id, currentAlertData?.pinned); setSelectedPinnedId(currentAlertData?.id);}}>
                        { currentAlertData?.pinned ? <PiPushPinSimpleSlashFill strokeWidth={8} color={'#8dd1e7'} size={isCameraPageAlerts ? 16 : 24} />: <PiPushPinSimpleLight strokeWidth={8} color={'#8dd1e7'} size={isCameraPageAlerts ? 16 : 24} />}
                      </DownloadIconWrapper>  
                    }
        
                    { downloadAlertsLoader && selectedDownloadId === currentAlertData?.id ?
                      <CircularProgressWrapper><CircularProgress /> </CircularProgressWrapper>
                      : 
                      downloadAlertsAccess && <DownloadIconWrapper title={t('Download')} isCameraPageAlerts={isCameraPageAlerts} onClick={() => {handleSaveAlerts(currentAlertData?.id); setSelectedDownloadId(currentAlertData?.id);}}>
                        <Icon icon='Download' color='primary' size={isCameraPageAlerts ? 16 : 24} />
                      </DownloadIconWrapper>
                    }
                    { deleteAlertsAccess && (selectedTab !== ALERT_STATUS_VALUES.Pinned) &&  <DownloadIconWrapper title={t('Delete')} isCameraPageAlerts={isCameraPageAlerts} onClick={() => {setSelectedAlertId(currentAlertData?.id); setModalOpen(!modalOpen);}}>
                      <Icon icon='Delete' color='danger' size={isCameraPageAlerts ? 16 : 24} />
                    </DownloadIconWrapper>}
                  </ActionIcons>
                </ThumbnailsContainer>
              </ThumbnailContainer>
              <TextualInformation hasLeftBorder>
                <TextInformationContainer isCameraPageAlert={alertThumbnailModal}>
                  <AlertInfoWrapper>
                    <AlertInfoLabel isCameraAlert={isCameraPageAlerts}>{t('Id')}</AlertInfoLabel>
                    <AlertInfoText noWrap isBold isCameraAlert={isCameraPageAlerts}>{currentAlertData?.id}</AlertInfoText>
                  </AlertInfoWrapper>
                  <AlertInfoWrapper>
                    <AlertInfoLabel isCameraAlert={isCameraPageAlerts}>{t('Camera Name')}</AlertInfoLabel>
                    <AlertInfoText noWrap isBold isCameraAlert={isCameraPageAlerts}>{currentAlertData?.displayName}</AlertInfoText>
                  </AlertInfoWrapper>
                  <AlertInfoWrapper>
                    <AlertInfoLabel isCameraAlert={isCameraPageAlerts}>{t('Reported at')}</AlertInfoLabel>
                    <AlertInfoText noWrap isCameraAlert={isCameraPageAlerts}>{currentAlertData?.timestamp ? getOnlyDate(currentAlertData?.timestamp) : '-'}</AlertInfoText>
                    <AlertInfoText noWrap isBold isCameraAlert={isCameraPageAlerts}>{currentAlertData?.timestamp ? getTimeSince(currentAlertData?.timestamp) : '-'}</AlertInfoText>
                  </AlertInfoWrapper>
                  <AlertInfoWrapper>
                    <AlertInfoLabel isCameraAlert={isCameraPageAlerts}>{t('Message')}</AlertInfoLabel>
                    <AlertInfoText noWrap isBold isCameraAlert={isCameraPageAlerts}>{currentAlertData?.message}</AlertInfoText>
                  </AlertInfoWrapper>
                  <AlertInfoWrapper>
                    <AlertInfoLabel isCameraAlert={isCameraPageAlerts}>{t('Analysis')}</AlertInfoLabel>
                    <AlertInfoText noWrap isBold isCameraAlert={isCameraPageAlerts}>{currentAlertData?.analysis}</AlertInfoText>
                  </AlertInfoWrapper>
                  <AlertInfoWrapper>
                    {currentAlertData?.meta ? getAlertMetaData(currentAlertData?.meta, currentAlertData?.unit, true) : null}
                  </AlertInfoWrapper>
                  {currentAlertData?.numberPlateImage !== undefined &&
                  <AlertInfoWrapper>
                    <AlertInfoLabel isCameraAlert={isCameraPageAlerts}>{t('Vehicle Number')}</AlertInfoLabel> 
                    <NumberPlateWrapper onClick={() => setCurrentEnlargedImage(currentAlertData?.numberPlateImage ? currentAlertData?.numberPlateImage : '')}>
                      <NumberPlateThumbnail src={ALERT_IMG_BASE_URL + currentAlertData?.numberPlateImage} onError={handleThumbnailError} />
                    </NumberPlateWrapper>
                  </AlertInfoWrapper>}
                  {currentAlertData?.status === ALERT_STATUS_VALUES.Ignored && <>
                    <AlertInfoWrapper>
                      <AlertInfoLabel isCameraAlert={isCameraPageAlerts}>{t('Ignore reason')}</AlertInfoLabel>
                      <AlertInfoText noWrap isBold isCameraAlert={isCameraPageAlerts}>{t(currentAlertData?.reason)}</AlertInfoText>
                    </AlertInfoWrapper>
                    <AlertInfoCommentWrapper>
                      <AlertInfoLabel isCameraAlert={isCameraPageAlerts}>{t('Comment')}</AlertInfoLabel>
                      <AlertInfoText noWrap isBold isCameraAlert={isCameraPageAlerts}>{currentAlertData?.comment}</AlertInfoText>
                    </AlertInfoCommentWrapper>
                  </>}
                </TextInformationContainer>
              </TextualInformation>
            </AlertThumbnailContainer>
          </AlertDetailsWrapper>
        </Modal>}
      { (loading) ? getLoading() :
        <AlertsPageContainer isCameraPageAlerts={isCameraPageAlerts} isAlertsTab={isSearchQuery}>
          <AlertsContainer>
            <AlertsHeader>
              <AlertsStatusTabsWrapper>
                <AlertsStatusTabs>
                  <StatusTab onClick={()=>{setSelectedTab(ALERT_STATUS_VALUES.Active); setSelectedId([]); setCurrentPage(1);}} isSelected={selectedTab === ALERT_STATUS_VALUES.Active}>
                    <AlertStatusWrapper>
                      <AlertStatusIndicator color='#FF0000' />
                      <Label>{t('Active')}</Label>
                    </AlertStatusWrapper>
                    <Label>{(alertStatusCount && typeof alertStatusCount === 'object' && alertStatusCount[1] !== undefined) ? alertStatusCount[1] : 0}</Label>
                  </StatusTab>
                  <VerticalDivider />
                  <StatusTab onClick={()=>{setSelectedTab(ALERT_STATUS_VALUES.Dismissed); setSelectedId([]); setCurrentPage(1);}} isSelected={selectedTab === ALERT_STATUS_VALUES.Dismissed}>
                    <AlertStatusWrapper>
                      <AlertStatusIndicator color='#00FF94' />
                      <Label>{t('Challans')}</Label>
                    </AlertStatusWrapper>
                    <Label>{(alertStatusCount && typeof alertStatusCount === 'object' && alertStatusCount[2] !== undefined) ? alertStatusCount[2] : 0}</Label>
                  </StatusTab>
                  <VerticalDivider />
                  { <> <StatusTab onClick={()=>{setSelectedTab(ALERT_STATUS_VALUES.Ignored); setSelectedId([]); setCurrentPage(1);}} isSelected={selectedTab === ALERT_STATUS_VALUES.Ignored}>
                    <AlertStatusWrapper>
                      <AlertStatusIndicator color='#595959' />
                      <Label>{t('Ignored')}</Label>
                    </AlertStatusWrapper>
                    <Label>{(alertStatusCount && typeof alertStatusCount === 'object' && alertStatusCount[3] !== undefined) ? alertStatusCount[3] : 0}</Label>
                  </StatusTab>
                  <VerticalDivider /></>}
                  { !isCameraPageAlerts && <><StatusTab onClick={()=>{setSelectedTab(ALERT_STATUS_VALUES.Expired); setSelectedId([]); setCurrentPage(1);}} isSelected={selectedTab === ALERT_STATUS_VALUES.Expired}>
                    <AlertStatusWrapper>
                      <AlertStatusIndicator color='#B2B1B1' />
                      <Label>{t('Expired')}</Label>
                    </AlertStatusWrapper>
                    <Label>{(alertStatusCount && typeof alertStatusCount === 'object' && alertStatusCount[4] !== undefined) ? alertStatusCount[4] : 0}</Label>
                  </StatusTab>
                  <VerticalDivider /></>}
                  <StatusTab onClick={()=>{setSelectedTab(ALERT_STATUS_VALUES.Pinned); setCurrentPage(1);}} isSelected={selectedTab === ALERT_STATUS_VALUES.Pinned}>
                    <AlertStatusWrapper>
                      <AlertStatusIndicator color='#ADD8E6' />
                      <Label>{t('Pinned')}</Label>
                    </AlertStatusWrapper>
                    <Label>{(alertStatusCount && typeof alertStatusCount === 'object' && alertStatusCount[5] !== undefined) ? alertStatusCount[5] : 0}</Label>
                  </StatusTab>
                </AlertsStatusTabs>
                {!isSearchQuery && <SearchInputFilter defaultAppliedFilters={appliedFilters} numberPlateFilter enableInputFilter={false} showAlgorithm showDateRange searchFilterCallback={searchFilterCallback} algorithmList={algorithmList} placeholder={t('search by Camera Name...')} />}
              </AlertsStatusTabsWrapper>
              {!isCameraPageAlerts && <RefreshIntervalSection>
                <Label>{t('Refresh Interval')}</Label>
                <SelectFieldWrapper>
                  <SelectField options={Config.refreshAlertsOptions} defaultValue={alertRefreshInterval} onChange={(value) => setAlertRefreshInterval(value)}/>
                </SelectFieldWrapper>
                {alertRefreshInterval === 'none' && 
                <DownloadIconWrapper loading={loading || alertsLoading} onClick={() => fetchAlerts({
                  skipLoadingState: false,
                  pageSize: pageSize,
                  currentPage: currentPage,
                  sortOrder: sorting[0]?.sort,
                  streamId: streamId,
                  status: selectedTab,
                  algoCodeList: filteredAlgos,
                  startDate: dateRange[0],
                  endDate: dateRange[1],
                  searchField: filterName,
                  hasNumberPlate,
                  alertId: Number(alertId) ? Number(alertId) : undefined
                })}>
                  <IoMdRefresh size={20} />
                </DownloadIconWrapper>
                }
              </RefreshIntervalSection>}
            </AlertsHeader>
            {selectedId.length > 0 && <>
              <ButtonsContainer isCameraPageAlerts={isCameraPageAlerts}>
                {updateStatusAccess && selectedTab !== ALERT_STATUS_VALUES.Dismissed && <Button variant='success' onClick={()=> setAllResolveAlertModal(!allResolveAlertModal)}>{t('Challan')}</Button>}
                {updateStatusAccess && selectedTab !== ALERT_STATUS_VALUES.Ignored && <Button variant='secondary' onClick={()=> setIgnoreAllAlertModal(!ignoreAllAlertModal)}>{t('Ignore')}</Button>}
                {downloadAlertsAccess && <ButtonWithLoading loading={downloadLoading} variant='primary' onClick={() => handleSaveAlerts()}>{t('Download')}</ButtonWithLoading>}
                {deleteAlertsAccess && <Button variant='danger' onClick={() => setModalOpen(!modalOpen)}>{t('Delete')}</Button>}
                {updateStatusAccess && selectedTab !== ALERT_STATUS_VALUES.Active && <Button variant='secondary' onClick={()=> setAllReOpenAlertModal(!allReOpenAlertModal)}>{t('Reopen')}</Button>}
              </ButtonsContainer></>}
            {
              !isCameraPageAlerts && isSearchQuery ? <><SearchInputFilter defaultAppliedFilters={appliedFilters} numberPlateFilter showCameraSearchSuggestions={true} showAlgorithm showDateRange searchFilterCallback={searchFilterCallback} algorithmList={algorithmList} searchQueryKeys={searchQueryKeys} placeholder={t('search by Camera Name...')} /><></></>
                :<></>
            }
            {(alertData?.length !== 0) && viewAlertsListAccess && 
            <AlertsTableWrapper>
              {(alertsLoading && viewAlertsListAccess) ? <LoadingContainer>
                <CircularProgress />
              </LoadingContainer>: 
                <AlertsTableContainer isCameraPageAlerts={isCameraPageAlerts}>
                  <DataGrid
                    disableVirtualization
                    rows={generateRowData() ? generateRowData() : []}
                    columns={getConfiguredColumns()}
                    checkboxSelection={true}
                    disableRowSelectionOnClick
                    onRowClick={(params) => {setIsCurrentAlertDataSelected(false);setCurrentAlertData(params?.row);}}
                    disableColumnMenu
                    onRowSelectionModelChange={handleRowSelectionModelChange}
                    rowSelectionModel={selectedId}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                    getRowClassName={(params) =>
                      params?.row?.id === currentAlertData?.id ? 'selected-row' : ''
                    }
                    getRowHeight={(params) => {
                      if (params?.model?.meta) {
                        const messageKeys = Object.keys(params.model.meta);
                        if (messageKeys.length === 2) return 100;
                        if (messageKeys.length === 3) return 100;
                        if (messageKeys.length === 4) return 120;
                        if (messageKeys.length > 4) return 140;
                        return isCameraPageAlerts ? 130 : 100;
                      }
                      return 150;
                    }}
                    getCellClassName={() => 'custom-cell'}
                  />
                  <PaginationBox>
                    <Pagination
                      onChange={handleChange}
                      pageSize={pageSize} 
                      count={Math.ceil(pageCount/pageSize)} 
                      setPageSize={(e) => setPageSize(e)}
                      currentPage={currentPage}
                      showPageList={!isCameraPageAlerts}
                    />
                  </PaginationBox>
                </AlertsTableContainer> 
              }
              {showSingleAlertDetailsPanel &&
              <SingleAlertContainer>
                {currentAlertData ? 
                  <AlertDataContainer>
                    <ImageContainer ref={containerRef}>
                      <ArrowButton className="left" onClick={()=>handlePrevClick(currentAlertData?.alertThumbnails.length)}>
                        <CiCircleChevLeft size={24} strokeWidth={1} />
                      </ArrowButton>
                      <ImagesWrapper translateValue={translateValue}>
                        {currentAlertData?.alertThumbnails.map((image, index) => (
                          <>
                            <Image
                              key={index}
                              isCroppedImage={index > 0 }
                              src={ALERT_IMG_BASE_URL + image}
                              alt={`Image ${index + 1}`}
                              onClick={() => {
                                setAlertThumbnailModal(true);
                                setCurrentEnlargedImage(image);
                              }}
                              onError={handleThumbnailError}
                            />
                          </>
                        ))}
                      </ImagesWrapper>
                      <ArrowButton className='right' onClick={()=>handleNextClick(currentAlertData?.alertThumbnails.length)}>
                        <CiCircleChevRight size={24} strokeWidth={1} />
                      </ArrowButton>
                    </ImageContainer>
                    <ThumbnailsContainer>
                      <ThumbnailList> 
                        {currentAlertData?.alertThumbnails.map(((url, index) => (
                          <SmallThumbnailWrapper width='60px' isSelected={currentIndex === index} onClick={() => setCurrentIndex(index)}>
                            <ModalThumbnail src={ALERT_IMG_BASE_URL + url} onError={handleThumbnailError} />
                          </SmallThumbnailWrapper>
                        )))}
                      </ThumbnailList>
                      <ActionIcons isCameraPageAlerts={isCameraPageAlerts}>
                        {updateStatusAccess && (currentAlertData?.status) !== ALERT_STATUS_VALUES.Ignored &&
                          <DownloadIconWrapper title={t('Ignore')} isCameraPageAlerts={isCameraPageAlerts} onClick={() => { setIgnoreAlertModal(!ignoreAlertModal); setSelectedIdAndStatus({id: currentAlertData?.id, status: (currentAlertData.status)});}} >
                            <RiChatDeleteLine color='#595959' size={isCameraPageAlerts ? 16 : 24}/>
                          </DownloadIconWrapper>}
                        {updateStatusAccess && (currentAlertData?.status) !== ALERT_STATUS_VALUES.Dismissed &&
                          <DownloadIconWrapper title={t('Move to Challans')} isCameraPageAlerts={isCameraPageAlerts} onClick={() => { setSingleResolveAlertModal(!singleResolveAlertModal); setSelectedIdAndStatus({id: currentAlertData?.id, status: (currentAlertData.status)});}} >
                            <LuPanelLeftOpen color='#18C97F' size={isCameraPageAlerts ? 16 : 24}/>
                          </DownloadIconWrapper>}
                        {updateStatusAccess && (currentAlertData?.status) !== ALERT_STATUS_VALUES.Active &&
                          <DownloadIconWrapper title={t('Reopen')} isCameraPageAlerts={isCameraPageAlerts} onClick={() => { setSingleReOpenAlertModal(!singleResolveAlertModal); setSelectedIdAndStatus({id: currentAlertData.id, status: (currentAlertData.status)});}} >
                            <LuPanelRightOpen  color='#435FCA' size={isCameraPageAlerts ? 16 : 24}/>
                          </DownloadIconWrapper>
                        }
                        {selectedPinnedId === currentAlertData?.id && pinLoader ? 
                          <CircularProgressWrapper><CircularProgress /> </CircularProgressWrapper> :
                          <DownloadIconWrapper
                            title={currentAlertData?.pinned ? t('Unpin') : t('Pin')}
                            isCameraPageAlerts={isCameraPageAlerts}
                            onClick={() => {handlePinnedAlert(currentAlertData?.id, currentAlertData?.pinned); setSelectedPinnedId(currentAlertData?.id);}}>
                            { currentAlertData?.pinned ? <PiPushPinSimpleSlashFill strokeWidth={8} color={'#8dd1e7'} size={isCameraPageAlerts ? 16 : 24} />: <PiPushPinSimpleLight strokeWidth={8} color={'#8dd1e7'} size={isCameraPageAlerts ? 16 : 24} />}
                          </DownloadIconWrapper>  
                        }
            
                        { downloadAlertsLoader && selectedDownloadId === currentAlertData?.id ?
                          <CircularProgressWrapper><CircularProgress /> </CircularProgressWrapper>
                          : 
                          downloadAlertsAccess && <DownloadIconWrapper title={t('Download')} isCameraPageAlerts={isCameraPageAlerts} onClick={() => {handleSaveAlerts(currentAlertData?.id); setSelectedDownloadId(currentAlertData?.id);}}>
                            <Icon icon='Download' color='primary' size={isCameraPageAlerts ? 16 : 24} />
                          </DownloadIconWrapper>
                        }
                        { deleteAlertsAccess && (selectedTab !== ALERT_STATUS_VALUES.Pinned) &&  <DownloadIconWrapper title={t('Delete')} isCameraPageAlerts={isCameraPageAlerts} onClick={() => {setSelectedAlertId(currentAlertData?.id); setModalOpen(!modalOpen);}}>
                          <Icon icon='Delete' color='danger' size={isCameraPageAlerts ? 16 : 24} />
                        </DownloadIconWrapper>}
                      </ActionIcons>
                    </ThumbnailsContainer>
                    <TextualInformation>
                      <TextInformationContainer>
                        <AlertInfoWrapper>
                          <AlertInfoLabel isCameraAlert={isCameraPageAlerts}>{t('Id')}</AlertInfoLabel>
                          <AlertInfoText noWrap isBold isCameraAlert={isCameraPageAlerts}>{currentAlertData?.id}</AlertInfoText>
                        </AlertInfoWrapper>
                        <AlertInfoWrapper>
                          <AlertInfoLabel isCameraAlert={isCameraPageAlerts}>{t('Camera Name')}</AlertInfoLabel>
                          <AlertInfoText noWrap isBold isCameraAlert={isCameraPageAlerts}>{currentAlertData?.displayName}</AlertInfoText>
                        </AlertInfoWrapper>
                      </TextInformationContainer>
                      <TextInformationContainer isCameraPageAlert={isCameraPageAlerts}>
                        <AlertInfoWrapper>
                          <AlertInfoLabel isCameraAlert={isCameraPageAlerts}>{t('Reported at')}</AlertInfoLabel>
                          <AlertInfoText noWrap isCameraAlert={isCameraPageAlerts}>{currentAlertData?.timestamp ? getOnlyDate(currentAlertData?.timestamp) : '-'}</AlertInfoText>
                          <AlertInfoText noWrap isBold isCameraAlert={isCameraPageAlerts}>{currentAlertData?.timestamp ? getTimeSince(currentAlertData?.timestamp) : '-'}</AlertInfoText>
                        </AlertInfoWrapper>
                        {currentAlertData?.numberPlateImage !== undefined &&
                        <AlertInfoWrapper style={{ gridRow: 'span 4' }}>
                          <AlertInfoLabel isCameraAlert={isCameraPageAlerts}>{t('Vehicle Number')}</AlertInfoLabel> 
                          <NumberPlateWrapper onClick={() => setCurrentEnlargedImage(currentAlertData?.numberPlateImage ? currentAlertData?.numberPlateImage : '')}>
                            <NumberPlateThumbnail src={ALERT_IMG_BASE_URL + currentAlertData?.numberPlateImage} onError={handleThumbnailError} />
                          </NumberPlateWrapper>
                        </AlertInfoWrapper>}
                        <AlertInfoWrapper>
                          <AlertInfoLabel isCameraAlert={isCameraPageAlerts}>{t('Message')}</AlertInfoLabel>
                          <AlertInfoText noWrap isBold isCameraAlert={isCameraPageAlerts}>{t(currentAlertData?.message)}</AlertInfoText>
                        </AlertInfoWrapper>
                        <AlertInfoWrapper>
                          <AlertInfoLabel isCameraAlert={isCameraPageAlerts}>{t('Analysis')}</AlertInfoLabel>
                          <AlertInfoText noWrap isBold isCameraAlert={isCameraPageAlerts}>{t(currentAlertData?.analysis)}</AlertInfoText>
                        </AlertInfoWrapper>
                        <AlertInfoWrapper>
                          {currentAlertData?.meta ? getAlertMetaData(currentAlertData?.meta, currentAlertData?.unit, true) : null}
                        </AlertInfoWrapper>
                      </TextInformationContainer>
                      {currentAlertData?.status === ALERT_STATUS_VALUES.Ignored && <>
                        <AlertInfoWrapper>
                          <AlertInfoLabel isCameraAlert={isCameraPageAlerts}>{t('Ignore reason')}</AlertInfoLabel>
                          <AlertInfoText noWrap isBold isCameraAlert={isCameraPageAlerts}>{t(currentAlertData?.reason)}</AlertInfoText>
                        </AlertInfoWrapper>
                        <AlertInfoCommentWrapper>
                          <AlertInfoLabel isCameraAlert={isCameraPageAlerts}>{t('Comment')}</AlertInfoLabel>
                          <AlertInfoText noWrap isBold isCameraAlert={isCameraPageAlerts}>{currentAlertData?.comment}</AlertInfoText>
                        </AlertInfoCommentWrapper>
                      </>}
                    </TextualInformation>
                  </AlertDataContainer> :
                  <NoAlertDiv>
                    <HiOutlineBellAlert size={50} color='#e2e0e0' />
                    <P>{t('Please select alert to view its details.')}</P>
                  </NoAlertDiv>
                }
              </SingleAlertContainer>}
            </AlertsTableWrapper>
            }
            
            {(alertData?.length === 0 && !alertsLoading) && viewAlertsListAccess &&
            <NoAlertsWrapper>
              <NoAlertsTitle>{t(`No ${getSelectedTabName()}`)}</NoAlertsTitle>
            </NoAlertsWrapper>}
            { !viewAlertsListAccess &&
            <NoAlertsWrapper>
              <NoPermissionMessage>{t('Unfortunately, you do not have permission to view Alerts list at this time. Please contact your administrator for assistance.')}</NoPermissionMessage>
            </NoAlertsWrapper>
            }
          </AlertsContainer>
        </AlertsPageContainer>
      }
    </MainContainer>
  );
};

export default Alerts;

const downloadLink = async (link: string, fileName: string) => {
  if(!link){return;}
  try {
    const response = await fetch(link);
    if (!response.ok) {
      throw new Error('Failed to fetch file.');
    }
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    anchor.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};
