import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgSetting = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="M13.679 4.188c.131 0 .26.04.366.113a.596.596 0 0 1 .226.299l.684 2.029c.288.134.563.285.825.455l2.185-.45a.65.65 0 0 1 .384.04c.12.05.22.137.286.246l1.724 2.849a.572.572 0 0 1-.078.696l-1.5 1.579c.022.303.022.607 0 .91l1.5 1.581a.572.572 0 0 1 .078.696l-1.724 2.85a.613.613 0 0 1-.287.246.65.65 0 0 1-.383.038l-2.185-.449a7.01 7.01 0 0 1-.823.455l-.686 2.03a.596.596 0 0 1-.226.297.642.642 0 0 1-.366.114h-3.45a.642.642 0 0 1-.366-.114.595.595 0 0 1-.225-.298l-.684-2.028a6.932 6.932 0 0 1-.827-.457l-2.184.45a.65.65 0 0 1-.383-.038.613.613 0 0 1-.286-.247L3.55 15.231a.571.571 0 0 1 .078-.696l1.5-1.581a6.323 6.323 0 0 1 0-.908l-1.5-1.581a.572.572 0 0 1-.078-.696l1.724-2.85a.613.613 0 0 1 .286-.246.65.65 0 0 1 .383-.038l2.184.45a7 7 0 0 1 .827-.457L9.64 4.6a.596.596 0 0 1 .225-.298.642.642 0 0 1 .365-.114h3.449Zm-.456 1.187h-2.538l-.707 2.098-.476.222c-.234.11-.46.234-.677.373l-.44.285-2.26-.465-1.269 2.1 1.549 1.636-.037.503a5.191 5.191 0 0 0 0 .746l.037.503-1.552 1.637 1.27 2.1 2.26-.465.44.284c.217.139.443.263.678.373l.476.222.708 2.098h2.54l.71-2.1.475-.22c.234-.11.46-.234.676-.373l.44-.284 2.26.464 1.27-2.1-1.551-1.636.037-.503a5.202 5.202 0 0 0 0-.747l-.037-.504 1.551-1.635-1.27-2.1-2.26.464-.44-.283a5.656 5.656 0 0 0-.676-.373l-.475-.22-.71-2.1h-.002Zm-1.269 3.563c.99 0 1.94.375 2.64 1.043a3.482 3.482 0 0 1 1.093 2.519c0 .945-.393 1.851-1.093 2.52a3.825 3.825 0 0 1-2.64 1.043c-.99 0-1.94-.376-2.64-1.044A3.482 3.482 0 0 1 8.223 12.5c0-.945.393-1.851 1.093-2.52a3.825 3.825 0 0 1 2.64-1.043Zm0 1.187c-.66 0-1.293.25-1.76.696a2.321 2.321 0 0 0-.728 1.679c0 .63.262 1.234.729 1.68a2.55 2.55 0 0 0 1.76.695c.66 0 1.292-.25 1.759-.696.467-.445.729-1.05.729-1.679 0-.63-.262-1.234-.73-1.68a2.55 2.55 0 0 0-1.759-.695Z"
          fill="#34495E"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="translate(2 3)" d="M0 0h19.908v19H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgSetting;
