import Modal from 'components/Modal';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import { LuPanelLeftOpen, LuPanelRightOpen } from 'react-icons/lu';
import ButtonWithLoading from 'components/ButtonWithLoading';

const ResolveReopenModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  gap: 20px;
  >:nth-child(3), :nth-child(4){
    input{
      margin-bottom: 0;
    }
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ResolveReopenModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ConfirmDesc = styled.span`
  text-align: center;
  color: ${({theme})=>theme.text.secondaryText};
  width: 80%;
`;

const HeaderText = styled.span<{headerTextColor: boolean}>`
  max-width: 350px;
  word-wrap: break-word;
  font-size: 16px;
  font-weight: 600;
  color: ${({headerTextColor}) => headerTextColor ? '#18C97F' : '#435FCA'};
  text-align: center;
`;

const IconDiv = styled.div<{iconColor: boolean}>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ iconColor }) => iconColor ? '#1ED6674D' : '#b8b8ed'};
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface IResolveReopenModalProps{
  modalOpen: boolean,
  setModalOpen: (modalOpen: boolean) => void,
  onSubmit: ()=>void;
  hasConfirmationField?: boolean;
  updateAlertStatusLoading?: boolean
  textToCheck?: string;
  titleText?: string;
  confirmDescription?: string;
  onCancelNo?: () => void;
  actionName?: string;
  variant?: any;
  isResolveModal:boolean;
}

const ResolveReopenModal: React.FC<IResolveReopenModalProps> = ({modalOpen, updateAlertStatusLoading=false, setModalOpen, onSubmit, hasConfirmationField=true, textToCheck='', titleText='', confirmDescription='', onCancelNo, actionName='Delete', variant='danger', isResolveModal= true}) => {
  const {t} = useTranslation(['common']);
  const [confirmText,setConfirmText] = useState<string>('');

  const handleSubmit = useCallback(()=>{
    if(hasConfirmationField){
      if(textToCheck === confirmText){
        onSubmit();
        setConfirmText('');
        setTimeout(() => {
          setModalOpen(false);
        }, 1000);
      }
    }else{
      onSubmit();
      setConfirmText('');
      setTimeout(() => {
        setModalOpen(false);
      }, 1000);
    }
  }, [onSubmit, setModalOpen, confirmText, hasConfirmationField, textToCheck]);

  return (
    <Modal isModalOpen={modalOpen} closeModal={setModalOpen}>
      <ResolveReopenModalContainer>
        <ResolveReopenModalHeader>
          <IconDiv iconColor={isResolveModal}>
            { isResolveModal ? <LuPanelLeftOpen color='#18C97F' size={20}/> : <LuPanelRightOpen  color='#435FCA' size={20}/>}
          </IconDiv>
          <HeaderText headerTextColor={isResolveModal}>{titleText}</HeaderText>
        </ResolveReopenModalHeader>
        <ConfirmDesc>{confirmDescription}</ConfirmDesc>
        <ButtonsWrapper>
          <ButtonWithLoading loading={updateAlertStatusLoading} variant={variant} type='submit' disabled={textToCheck !== confirmText} onClick={handleSubmit}>{t(`${actionName}`)}</ButtonWithLoading>
          <Button
            variant='secondary' 
            onClick={()=>{ setModalOpen(false);
              if(onCancelNo){
                onCancelNo();
              }
            }}
          >
            {t('No') }
          </Button>
        </ButtonsWrapper>
      </ResolveReopenModalContainer>
    </Modal>
  );
};

export default ResolveReopenModal;