import React, { useCallback, useMemo, useState } from 'react';
import { IStream } from 'interface';
import styled, { css } from 'styled-components';
import Icon from '../../components/Icon';
import { IconType, PERMISSION_CODE, SNAPSHOT_URL, STATUS_URL, StatusCode } from '../../constants';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef, GridRenderCellParams, GridRowSelectionModel } from '@mui/x-data-grid';
import { getAlgoIcon, getStatusColor, getStatusText } from 'utils/utils';
import Config from '../../configs/config.json';
import axios from 'axios';
import { useHistory } from 'react-router';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import { DELETE_STREAM } from 'api_configs/mutations';
import { ApolloQueryResult, OperationVariables, useMutation } from '@apollo/client';
import { GET_ALL_STREAMS } from 'api_configs/queries';
import Modal from 'components/Modal';
import FullScreenView from 'components/FullScreenView';
import AwaitingStreamEng from '../../svgs/Awaiting_Stream-ENG.svg';
import TokenService from 'api_configs/tokenService';

interface ListViewContainerProps {
  isMapViewEnabled: boolean;
  selectedId: number | null;
}

const ListViewContainer = styled.div<ListViewContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-height: ${({ isMapViewEnabled }) => isMapViewEnabled ? '350px' : 'none'};
  overflow-y: auto;

  @media only screen and (min-width: 1366px){
    width: ${({isMapViewEnabled}) => isMapViewEnabled ? '450px' : '975px'};
  }

  @media only screen and (min-width: 1920px){
    width: ${({isMapViewEnabled}) => isMapViewEnabled ? '450px' : '1522px'};
  }

  > div:nth-child(1) > div:nth-child(2){
    display: none;
  }

  >div{
    width: 100%;
  }

  .MuiDataGrid-row {
    min-height: 60px !important;
    > div {
      padding: 10px 0;
    }

    &.Mui-selected {
      background-color: white !important;
    }

    ${props => props.selectedId && css`
      &[data-id="${props.selectedId}"] {
        background-color: rgba(25, 118, 210, 0.08) !important;
      }
    `}
  }

  .MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }

  .MuiDataGrid-root .MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }

  .MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
    span{
      display: none;
    }
  }
`;

const StatusContainer = styled.div<{color:string}>`
  display: flex;
  align-items: center;
  border-radius: 20px;
  gap: 10px;
  background-color: #E6FFEB;
  max-width: 100px;
  width: fit-content;
  padding: 6px;
  color: ${({color})=> color};
`;

const Indicator = styled.div<{bgColor:string}>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({bgColor})=> bgColor};
`;

const StatusText = styled.label`
  font-size: 12px;
`;

const NoDataContainer = styled.div`
  width: 100%;
  height: 100px;
  margin-top: 20px;
  border: 1px solid #F5F6F6;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  gap: 20px;
`;

const NoDataTitle = styled.label`
  font-size: 20px;
  font-weight: 600;
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
`;

const AlgosContainer = styled(ActionsContainer)`
  gap: 10px;
`;

const IconDiv = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 5px;
  border: 1px solid ${({theme}) => theme.divider};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ActionsDiv = styled(IconDiv)`
  cursor: pointer;
  width: auto;
  height: auto;
  border: none;
`;

const CameraInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
`;

const CameraName = styled.div`
  cursor: pointer;
  padding-left: 10px;
  font-weight: 600;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  max-height: 3 * 1.2em;
  white-space: pre-wrap;
  padding-left: 10px;
  word-wrap: break-word;
`;

const NoPermissionMessage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ThumbnailContainer = styled.div`
  width: 56px;
  height: 42px;
  object-fit: cover;
  border-radius: 5px;
  margin-left: 10px;
  cursor: pointer;
  background-image: url(${AwaitingStreamEng});
  background-repeat: no-repeat;
  :hover{
    transform: scale(1.2);
    transition: all 0.3s ease-out;
  }
`;

const ThumbnailImg = styled.img<{size?: number}>`
  width: ${({ size }) => size ? `${size}px` : '100%'};
  height: ${({ size }) => size ? `${size}px` : '100%'};
  object-fit: cover;
  border-radius: 5px;
`;

const CameraDesc = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  max-height: 3 * 1.2em;
  white-space: pre-wrap;
  padding-left: 10px;
  word-wrap: break-word;
  font-size: 12px;
  color: ${({theme}) => theme.text.secondaryText};
`;

interface IListviewProps {
  streams: IStream[];
  isMapViewEnabled: boolean;
  isFloorPlan?: boolean;
  setMessage: React.Dispatch<React.SetStateAction<IAlertMessageType>>;
  getAllStreamsRefetch: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<any>>
  setSelectedId: React.Dispatch<React.SetStateAction<GridRowSelectionModel>>;
  selectedId: GridRowSelectionModel;
  selectedCameraId: number;
}

interface IAlertMessageType {
  text: string,
  id: string,
  type: string | IconType,
}

interface IDeleteStreamInfo{
  instanceId: string,
  textToCheck: string,
}

const ZoneCameraList: React.FC<IListviewProps> = ({streams, isMapViewEnabled, selectedId, setMessage, getAllStreamsRefetch, isFloorPlan = false, setSelectedId, selectedCameraId}) => {
  const {t} = useTranslation(['common']);
  const {push} = useHistory();
  const [deleteStreamModal,setDeleteStreamModal] = useState<boolean>(false);
  const [deleteStreamInfo, setDeleteStreamInfo] = useState<IDeleteStreamInfo>({instanceId: '', textToCheck: ''});
  const [deleteStreamMutation] = useMutation(DELETE_STREAM,{
    refetchQueries: [{ query: GET_ALL_STREAMS }], // to refetch GET_ALL_STREAMS API to prevent refreshing the page to see the result.
  });
  const [fullScreenModal, setFullScreenModal] = useState<boolean>(false);
  const [selectedStream, setSelectedStream] = useState<IStream>();
  const viewCameraListAccess = TokenService.hasAccess(PERMISSION_CODE.camera_list);

  const localizationTexts = {
    noRowsLabel: t('No Data'),
  };

  const handleThumbnailError = useCallback((e:React.SyntheticEvent<HTMLElement>)=>{
    (e.target as HTMLSourceElement).src = AwaitingStreamEng;
  },[]);

  const getStatusInfo = async (instanceId: string) =>{
    try{
      const statusRes = await axios.get(`${STATUS_URL.replace('{X}',instanceId)}`);
      if(statusRes && statusRes?.data?.data?.running_status){
        return statusRes?.data?.data?.running_status?.status;
      }
    }catch(e){
      console.log(e);
    }
    return null;
  };
      
  const getStatusColumn = useCallback((status: string) => {
    return (
      <StatusContainer color={getStatusColor(status) as string}>
        <Indicator bgColor={getStatusColor(status) as string} /> 
        <StatusText>{t(`${getStatusText(status)}`)}</StatusText>
      </StatusContainer>
    );
  },[t]);

  const getActionsColumn = useCallback((instanceId: string, streamType: string, textToCheck: string)=>{
    return (
      <ActionsContainer style={{paddingLeft: '10px'}}>
        <ActionsDiv onClick={()=>push(`/cameras/${instanceId}/configurations?selectedTab=basicConfig&camera-name=${instanceId}&camera-type=${streamType}`)} >
          <Icon icon='Edit' color='primary' size={28}/>
        </ActionsDiv>
        <ActionsDiv onClick={()=>{setDeleteStreamModal(!deleteStreamModal); setDeleteStreamInfo({instanceId: instanceId, textToCheck: textToCheck});}}>
          <Icon icon='Delete' color='danger' size={28}/>
        </ActionsDiv>
      </ActionsContainer>
    );
  },[push,deleteStreamModal]);

  const getAlgosColumn = useCallback((algorithms: string[])=>{
    return (
      <>
        {algorithms?.length > 0 ?
          <AlgosContainer>
            {
              algorithms?.map((algorithm)=>(
                <IconDiv>{getAlgoIcon(algorithm, false, 24, true)}</IconDiv>
              ))
            }
          </AlgosContainer>:
          <div>-</div>
        }
      </>
    );
  },[]);

  const getThumbNail = useCallback((streamDetails: IStream)=>{
    return (
      <ThumbnailContainer onClick={()=>{setSelectedStream(streamDetails);setFullScreenModal(prev => !prev);}}>
        <ThumbnailImg src={SNAPSHOT_URL.replace('{X}',streamDetails.instanceId).replace('{Y}',new Date().getTime().toString())} onError={handleThumbnailError} />
      </ThumbnailContainer>
    );
  },[handleThumbnailError]);

  const columns: GridColDef[] = useMemo(()=>
    [
      { field: 'thumbnail', headerName: '', flex: 0.5, sortable: false, headerAlign: 'center', align: 'center',
        renderCell: (params:GridRenderCellParams)=>getThumbNail(params.row.streamDetails)
      },
      { field: 'name', headerName: t('Camera Name'), flex: 1, sortable: true, headerAlign: 'left',  align: 'left',

        renderCell: (params:GridRenderCellParams) => {
          return (
            <CameraInfoWrapper>
              <CameraName>{params?.row?.name}</CameraName>
              <CameraDesc title={params?.row?.notes}>{params?.row?.notes}</CameraDesc>
            </CameraInfoWrapper>
          );
        }
      },
      { field: 'status', headerName: t('Status'), sortable: false, flex: 1, headerAlign: 'center',  align: 'center',
        renderCell: (params:GridRenderCellParams) => getStatusColumn(params?.row?.status),
      },
      { field: 'location', headerName: t('Location'), sortable: false, flex: 1, headerAlign: 'center',  align: 'center' },
      { field: 'streamType', headerName: t('Type'), sortable: false, flex: 1, headerAlign: 'center',  align: 'center' },
      { field: 'schedules', headerName: t('Schedules'), sortable: false, flex: 1, headerAlign: 'center',  align: 'center' },
      { field: 'alerts', headerName: t('Alerts'), sortable: false, flex: 1, headerAlign: 'center',  align: 'center' },
      { field: 'algorithms', headerName: t('Algorithms'), sortable: false, flex: 1, headerAlign: 'center',  align: 'center' ,
        renderCell: (params:GridRenderCellParams) => getAlgosColumn(params.row.algorithms)
      },
      { field: 'actions', headerName: t('Actions'), sortable: false, flex: 0.5, headerAlign: 'center', align: 'center',
        renderCell: (params:GridRenderCellParams) => getActionsColumn(params.row.instanceId, params.row.streamType, params.row.name)
      },
    ]
  ,[getStatusColumn, t, getActionsColumn, getThumbNail, getAlgosColumn]); 

  const getConfiguredColumns = useCallback(()=>{
    let configuredColumns;
    if(isFloorPlan){
      configuredColumns = columns.filter((item)=>{
        return Config.cameraManagement.floorPlanlistViewColumns.indexOf(item.field) !== -1;
      });
    }else{
      configuredColumns = columns.filter((item)=>{
        return Config.cameraManagement.listViewColumns.indexOf(item.field) !== -1;
      });
    }
    return configuredColumns;
  },[columns, isFloorPlan]);

  const generateRowData = useCallback(() =>{
    if(streams === undefined || streams === null) return [];
    return streams?.map((item: IStream)=>(
      { 
        id: item.id, 
        name: item?.name, 
        streamType: item?.streamType,
        location: item?.location ? item?.location : '-',
        status: getStatusInfo(item?.instanceId),
        schedules: 'No', 
        alerts: 'Active',
        algorithms: item?.algorithmList ? item?.algorithmList.map((algorithm)=>algorithm.code): [],
        streamDetails: item,
        notes: item.notes ? item.notes : '-'
      }
    ));
  },[streams]);

  const handleDeleteStream = useCallback(async (instanceId: string) => {
    try{
      const data = await deleteStreamMutation({variables:{streamId: instanceId}});
      if(data && data?.data?.deleteStream?.statusCode === StatusCode.SUCCESS){
        setMessage({text: t('Camera deleted successfully'), id:'',type:'success'});
        await getAllStreamsRefetch();
      }else{
        setMessage({text: t('Failed to delete stream. Please try again!'), id:'',type:'danger'});
      }
    }catch(e){
      console.error(e);
    }
  },[deleteStreamMutation, t, setMessage, getAllStreamsRefetch]);

  const handleRowSelectionModelChange = (params: GridRowSelectionModel) => {
    setSelectedId(params ? params : []);
  };

  return (
    <>
      {
        streams?.length !== 0 && viewCameraListAccess && 
          <ListViewContainer isMapViewEnabled={isMapViewEnabled} selectedId={selectedCameraId}>
            <DataGrid
              sx={{ '& .MuiDataGrid-columnSeparator': { display: 'none' } }}
              rows={generateRowData()}
              columns={getConfiguredColumns()}
              checkboxSelection={true}
              disableColumnMenu
              keepNonExistentRowsSelected
              onRowSelectionModelChange={handleRowSelectionModelChange}
              rowSelectionModel={selectedId}
              getRowId={(row) => row?.id}
              getRowHeight={()=>'auto'}
              localeText={localizationTexts}
            />
          </ListViewContainer>}
      { streams?.length === 0 && viewCameraListAccess && <NoDataContainer>
        <NoDataTitle>{t('cameraNotFound')}</NoDataTitle>
      </NoDataContainer>
      }
      { !viewCameraListAccess && <NoDataContainer>
        <NoPermissionMessage>{t('Unfortunately, you do not have permission to view Camera list at this time. Please contact your administrator for assistance.')}</NoPermissionMessage>
      </NoDataContainer>
      }
      {fullScreenModal && 
      <Modal isModalOpen={fullScreenModal} closeModal={setFullScreenModal} width='60vw'>
        <FullScreenView streamDetails={selectedStream as IStream} />
      </Modal>}
      {deleteStreamModal && 
        <ConfirmationModal modalOpen={deleteStreamModal} setModalOpen={setDeleteStreamModal} onSubmit={()=>handleDeleteStream(deleteStreamInfo.instanceId)} hasConfirmationField={true} placeholder={t('Enter Camera Name')} textToCheck={deleteStreamInfo.textToCheck}
          titleText={t('Delete Camera')  + `: ${deleteStreamInfo.textToCheck}?`} confirmDescription={t('Are you sure you want to delete this Camera? Please enter the camera name to confirm.')} noteText={t('Once deleted this action cannot be undone.')} />}
    </>
  );
};

export default ZoneCameraList;