import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgUpload = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 5.828V17h-2V5.828L7.757 9.071 6.343 7.657 12 2l5.657 5.657-1.414 1.414L13 5.828ZM5 19h14a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"
        fill={props.color}
      />
    </svg>
  );
};
export default SvgUpload;
