import React, { ButtonHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import Button from './Button';
import { CircularProgress } from '@mui/material';

const LoadingButton = styled(Button)<{$loading: boolean}>`
  ${({$loading}) => $loading && css`
    cursor: wait;
    &:disabled {
      opacity: 1;
    }
  `};
  > div > div {
    margin-left: ${({$loading}) => $loading ? '8px': '0'};
  }
`;

const TextContainer = styled.div`
  height: inherit;
  flex: 1;
  order: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  ${({theme}) => theme && css`
    transition: padding 0.7s ease-in-out;
  `}
`;

const LoadingContainer = styled.div<{show?: boolean, position?: string}>`
  height: inherit;
  flex: 0 0 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  opacity: 0;

  ${({position}) => css`
    transition:
      flex 0.7s ease-in-out 0.7s,
      opacity 0.7s ease-in-out;
    order: ${position && position === 'left' ? 0 : 2};
  `}
  svg {
    display:block;
  }
`;

const InnerContainer = styled.div<{position?: string, $loading: boolean}>`
  display: flex;
  height: inherit;
  ${({position, $loading}) => position && position === 'left' ? css`
    margin-right: ${$loading ? '-20px' : '0'};
    justify-content: space-between;
  ` : css`
    margin-left: ${$loading ? '-20px' : '0'};
  `}

  ${({$loading}) => $loading ? css`
    transition: margin 0.7s ease-in-out;
    ${TextContainer}{
      padding: 0 4px;
      transition: padding 0.7s ease-in-out;
    }
    ${LoadingContainer}{
      opacity: 1;
      flex: 0 0 auto; /* Change to auto to enable smooth transition */
      transition: flex 0.7s ease-in-out 0.7s, opacity 0.7s ease-in-out;
      background: none;
    }
  ` : css`
    ${TextContainer}{
      transition: padding 0.7s ease-in-out;
    }
    ${LoadingContainer}{
      flex: 0 0 0px;
    }
  `}
`;

interface ButtonProps {
  variant: 'primary' | 'secondary' | 'danger' | 'success';
  size ?: 'small' | 'normal';
  loading?: boolean
}

interface IProps extends ButtonProps {
  position?: 'left' | 'right'
  loading: boolean
}

export type ButtonWithLoadingProps = IProps & ButtonHTMLAttributes<HTMLButtonElement>

const ButtonWithLoading : React.FC<ButtonWithLoadingProps> = ({variant='primary', size='normal', onClick, disabled, position, loading, children, ...rest}) => {
  return (
    <LoadingButton disabled={disabled || loading} $loading={loading} {...{ variant, size, onClick}} {...rest}>
      <InnerContainer $loading={loading} {...{ variant, size}}>
        <TextContainer>{children}</TextContainer>
        {loading && <LoadingContainer {...{ variant, position }}>
          <CircularProgress size={14} style={{color: '#ffffff'}}/>
        </LoadingContainer>}
      </InnerContainer>
    </LoadingButton>
  );
};

export default ButtonWithLoading;