import React from 'react';
import styled from 'styled-components';

interface CheckboxProps {
  checked: boolean;
  id?: string,
  disabled?: boolean;
  onChangeCallback?: (checked: boolean) => void;
  size?: number
}

const CheckboxWrapper = styled.label<{ disabled?: boolean }>`
  display: inline-flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  user-select: none;
`;

const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border: 2px solid #ccc;
  border-radius: 3px;
  margin-right: 8px;
  outline: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  position: relative;

  &:checked {
    background-color: #1976D2;
    border-color: #1976D2;
  }

  &:hover:not(:disabled) {
    border-color: #999;
  }

  &:checked::before {
    content: '\u2714';
    position: absolute;
    top: -2px;
    left: 1px;
    color: white;
    font-size: ${({ size }) => `${Number(size) - 4}px`}; 
    pointer-events: none;
  }
`;

const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  disabled,
  onChangeCallback=()=>{},
  id,
  size = 18,
}) => {
  const handleChange = () => {
    if (!disabled) {
      onChangeCallback(!checked);
    }
  };

  return (
    <CheckboxWrapper disabled={disabled}>
      <StyledCheckbox
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
        id={id}
        size={size}
      />
    </CheckboxWrapper>
  );
};

export default Checkbox;