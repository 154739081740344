import React from 'react';
import styled, { keyframes } from 'styled-components';

const jump = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0);
  }
`;

const LoaderWrapper = styled.div`
  display: block;
  padding: 20px;
`;

const DotsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const Dot = styled.div<{delay: string}>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  animation: ${jump} 0.6s ease-in-out infinite;
  animation-delay: ${({ delay }) => delay};
`;

const Threedots = () => {
  return (
    <LoaderWrapper>
      <DotsContainer>
        <Dot color="red" delay="0s" />
        <Dot color="green" delay="0.2s" />
        <Dot color="yellow" delay="0.4s" />
      </DotsContainer>
    </LoaderWrapper>
  );
};

export default Threedots;
