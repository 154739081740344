import { IPointSet, IVector2 } from './';
import update from 'immutability-helper';

export type IReducerActions =
  | UpdateAction
  | LoadAction
  | AddSetAction
  | RemoveAction
  | AddPointAction
  | RemovePointAction
  | UpdateSetOptions
  | RenameSetAction
  | ChangeFillColorAction
  | ChangeTranparencyLevelAction
  | RemoveSetArray
  | AddSetArray
  ;

interface AddSetAction{
  type: 'ADD_SET';
  data: IPointSet;
}

interface AddSetArray{
  type: 'ADD_SET_ARRAY';
  index: number
  data: IPointSet;
}

interface UpdateAction {
  type: 'UPDATE'|'UPDATE_SET_POINTS';
  index: number;
  data: IPointSet;
}

interface RenameSetAction {
  type: 'RENAME_SET';
  index: number;
  data: {
    name?: string;
    areaName?: string;
  };
}

interface UpdateSetOptions {
  type: 'UPDATE_SET_OPTIONS';
  index: number;
  data: Partial<IPointSet>;
}

interface RemoveAction {
  type: 'REMOVE_SET';
  index: number;
}

interface RemoveSetArray {
  type: 'REMOVE_SET_ARRAY';
  index: number;
  lineIndex: number;
}

interface LoadAction {
  type: 'LOAD';
  state: IPointSet[];
}

interface AddPointAction {
  type: 'ADD_POINT';
  index: number;
}

interface RemovePointAction {
  type: 'REMOVE_POINT';
  index: number;
}

interface ChangeFillColorAction {
  type: 'UPDATE_FILL_COLOR';
  index: number;
  data: {
    areaFillColor: string;
  };
}

interface ChangeTranparencyLevelAction {
  type: 'UPDATE_TRANSPARENCY_LEVEL';
  index: number;
  data: {
    areaTransparencyLevel: number;
  };
}

const getMidpoint = (pointA : IVector2, pointB : IVector2) => {
  return({
    x: pointA.x + (pointB.x - pointA.x) * 0.5,
    y: pointA.y + (pointB.y - pointA.y) * 0.5
  });
};

// TO_DO
// eslint-disable-next-line import/no-anonymous-default-export 
export default (state : IPointSet[], action: IReducerActions) => {

  switch(action.type){
  case 'UPDATE': {
    const points = action.data.points.map((point) => ({...point}));
    return update(state, {[action.index]: { points: {$set: points}}});
  }

  case 'RENAME_SET': {
    const set = { ...state[action.index], name: action.data.name, areaName: action.data.areaName};
    return update(state, {[action.index]: {$set: set}});
  }

  case 'UPDATE_SET_OPTIONS': {
    const set = { ...state[action.index], ...action.data};
    return update(state, {[action.index]: {$set: set}});
  }

  case 'ADD_SET':
    return [...state, action.data];

  case 'ADD_SET_ARRAY':
    return [
      ...state.slice(0, action.index), // Elements before the index
      action.data, // Inserted data
      ...state.slice(action.index) // Elements after the index
    ];

  case 'REMOVE_SET':
    return update(state, { $splice: [[action.index, 1]]});

  case 'REMOVE_SET_ARRAY':
    return update(state, { $splice: [[action.index, action.lineIndex]]});

  case 'ADD_POINT': {
    const totalPoints = state[action.index].points.length;
    const newPoint: IVector2 = getMidpoint(state[action.index].points[0], state[action.index].points[totalPoints-1]);
    return update(state, {[action.index]: {points: {$splice: [[totalPoints, 0, newPoint]]}}});
  }

  case 'REMOVE_POINT':
    if(state[action.index].points.length <= 2){ return state; }
    return update(state, {[action.index]: {points: {$splice: [[state[action.index].points.length - 1, 1]]}}});

  case 'LOAD': {
    const newState = action.state?.map(
      ({name, points, ...rest}) => ({
        name,
        points: points?.map( ({x,y}) => ({x,y}) ),
        ...rest
      })
    );
    return newState;
  }

  case 'UPDATE_FILL_COLOR': {
    const set = { ...state[action.index], areaFillColor: action.data.areaFillColor};
    return update(state, {[action.index]: {$set: set}});
  }

  case 'UPDATE_TRANSPARENCY_LEVEL': {
    const set = { ...state[action.index], areaTransparencyLevel: action.data.areaTransparencyLevel};
    return update(state, {[action.index]: {$set: set}});
  }

  default:
    console.error('Action Type not registered.');
    return state;
  }
};
