import React from 'react';
import styled, { keyframes } from 'styled-components';
import LoaderImage from '../svgs/loader_logo.png';

const spin = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Loader = styled.div`
  width: 200px;
  height: 200px;
  background: url(${LoaderImage}) no-repeat center center;
  background-size: contain;
  animation: ${spin} 2s linear infinite;
`;

const CustomLoader = () => {
  return (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  );
};

export default CustomLoader;
