import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgSearch = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M20.03 20.79c.46.46 1.17-.25.71-.7l-3.75-3.76a7.904 7.904 0 0 0 2.04-5.31c0-4.39-3.57-7.96-7.96-7.96-4.39 0-7.96 3.57-7.96 7.96 0 4.39 3.57 7.96 7.96 7.96 1.98 0 3.81-.73 5.21-1.94l3.75 3.75ZM4.107 11.02c0-3.84 3.13-6.96 6.96-6.96 3.84 0 6.96 3.12 6.96 6.96s-3.12 6.96-6.96 6.96c-3.83 0-6.96-3.12-6.96-6.96Z"
        fill={props.color}
      />
    </svg>
  );
};
export default SvgSearch;
