import Input from 'components/Input';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const VideoConfigurationsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const VerticalLine = styled.div`
  width: 980px;
  height: 10px;
  border-bottom: 1px solid lightgrey;
  margin-top: 2px;
  margin-bottom: 15px;
  margin-left: 18px;
  @media (max-width: 768px) {
    width: 450px;
  }
`;

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Heading = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-left: 18px;
`;

const SelectedCameraVideoConfigurations = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`;

const LeftSideWrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

interface IHLSData {
  httpSourceUrl: string
}

interface IMessage {
  text: string,
  id: string
}

interface IHLSCamera {
  hlsData: IHLSData,
  setHlsData: React.Dispatch<React.SetStateAction<IHLSData>>,
  message: IMessage,
  isEditAccess?: boolean
}

const HLSCamera = ({hlsData, setHlsData, message, isEditAccess=false}: IHLSCamera) => {
  const {t} = useTranslation(['common']);
  
  return (
    <VideoConfigurationsWrapper>
      <HeadingWrapper>
        <Heading>{t('Video Configurations')}</Heading>
      </HeadingWrapper>
      <VerticalLine />   
      <SelectedCameraVideoConfigurations>
        <LeftSideWrapper>
          <Input type='text' id='httpStreamUrl' value={hlsData.httpSourceUrl} message={message} disabled={isEditAccess} label={t('HTTP Stream URL')} isRequired onChange={(e) => setHlsData({...hlsData, httpSourceUrl: e.target.value})} />
        </LeftSideWrapper>
      </SelectedCameraVideoConfigurations>
    </VideoConfigurationsWrapper>  
  );
};

export default HLSCamera;