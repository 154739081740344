import React, {useCallback, useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Button from 'components/Button';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import Input from 'components/Input';
import ScheduleDays from 'components/ScheduleDays';
import { EDIT_SCHEDULE } from 'api_configs/mutations';
import { useMutation, useQuery } from '@apollo/client';
import AlertBar from 'components/AlertBar';
import { IconType, StatusCode, getDayTextValue, getDayNumberValue } from '../../constants';
import { IAlertMessageType, IBreadcrum } from 'interface';
import { GET_SCHEDULE } from 'api_configs/queries';
import CircularProgress from '@mui/material/CircularProgress';
import { Container } from 'CommonStyles';
import lodash from 'lodash';
import CancelConfirmationModal from 'pages/configurations/CancelConfirmationModal';
import { useHeader } from 'contexts/HeaderContext';

const ButtonsContainer = styled.div`
  display: flex;
  column-gap: 30px;
  justify-content: center;
  > button {
    width: 120px;
  }
  width: 560px;
`;

const FieldsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

interface IRules {
  startTime?: string;
  endTime?: string;
  days?: number[];
}

interface IRuleList {
  startTime?: string;
  endTime?: string;
  days?: string[];
}

const ScheduleDaysContainer = styled.div`
  margin: 10px 0px 20px 0;
`;

const SetScheduleWrapper = styled.div`
  div::after{
    font-weight: bold;
    content: " *";
    color: rgb(238, 75, 43);
  }
`;

const SetScheduleLabel = styled.div``;

const SpinnerBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 89vh;
  align-items: center;
  justify-content: center;
`;

const Label = styled.div``;

interface ISchedule {
  rules?: IRules[] | [];
  id?: number;
}

interface IParams {
  id: string;
}

const Editschedule = () =>{
  const {t} = useTranslation(['common']);
  const history = useHistory();
  const params : IParams = useParams();
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'});
  const [updatedSchedule, setUpdatedSchedule] = useState<ISchedule>({rules: []});
  const [editScheduleMutation] = useMutation(EDIT_SCHEDULE);
  const [scheduleName, setScheduleName] = useState<string>('');
  const [loader, setLoader] = useState(false);
  const { data, refetch, loading } = useQuery(GET_SCHEDULE, { variables: {scheduleId: parseInt(params.id)} });
  const [initialScheduleName, setInitialScheduleName] = useState<string>('');
  const [initialRules,setInitialRules] = useState<ISchedule>({rules: []});
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const {updateHeaderTitle} = useHeader();

  useEffect(()=>{
    const eventPipelineBreadCrumb: IBreadcrum[] = [
      {label: t('schedules'), to: '/settings/schedules'}
    ];
    updateHeaderTitle(t('Edit Schedule'), 'Schedule', eventPipelineBreadCrumb);
  },[t, updateHeaderTitle]);

  useEffect(()=>{
    setLoader(loading);
  },[loading]);

  useEffect(() =>{
    refetch();
  },[refetch]);

  const getUpdatedScheduleList= useCallback((list: object) => {
    setUpdatedSchedule({rules: list as []});
  },[]);

  useEffect(()=>{
    if(data?.getSchedule?.result){
      const result = data?.getSchedule?.result;
      if(result){
        const ruleList = result && result?.rules?.map((item: IRuleList) => {  
          const daysList: number[] = [];
          item?.days?.map((item)=> daysList.push(getDayNumberValue[item]));
          return {
            startTime: item?.startTime,
            endTime: item?.endTime,
            days: daysList
          };
        });
        setInitialScheduleName(result.name);
        setScheduleName(result.name);
        setInitialRules({rules: ruleList});
        setUpdatedSchedule({rules: ruleList});
      }
    }
  },[data?.getSchedule, params]);

  const handleSaveSchedule = async () =>{
    if(scheduleName?.replace(/\s/g, '').length === 0){
      setMessage({text: t('Please enter the schedule name'), id:'',type:'danger'});
      return;
    } else if(updatedSchedule.rules?.length === 0){
      setMessage({text: t('Please add a rule'), id:'',type:'danger'});
      return;
    }

    const ruleList = updatedSchedule?.rules && updatedSchedule?.rules.map((item: IRules) => {  
      const daysList: string[] = [];
      item?.days?.map((item: number)=> daysList.push(getDayTextValue[item]));
      return {
        startTime: item?.startTime,
        endTime: item?.endTime,
        days: daysList
      };
    });

    try {
      const result = await editScheduleMutation({variables: {scheduleId: parseInt(params.id), payload: { name: scheduleName, rules: ruleList}}});
      if(result.data.updateSchedule.statusCode === StatusCode.SUCCESS){
        setMessage({text: t('Schedule updated successfully'), id:'',type:'success'});
        setTimeout(()=>{
          history.push('/settings/schedules');
        },1000);
      }else {
        setMessage({text: t('apiError'), id:'',type:'danger'});
      }
    } catch(e){
      setMessage({text: t('apiError'), id:'',type:'danger'});
    }
  };

  if (loader) {
    return (
      <SpinnerBox>
        <CircularProgress />
        <Label>{t('Loading...')}</Label>
      </SpinnerBox>
    );
  }

  const onCancelYes = () =>{
    setModalOpen(false);
    history.push('/settings/schedules');
  };

  const onCancelNo = ()=>{
    setModalOpen(false);
  };

  return(
    <Container>
      {message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
      {modalOpen && <CancelConfirmationModal modalOpen={modalOpen} setModalOpen={setModalOpen} onCancelYes={onCancelYes} onCancelNo={onCancelNo} />}
      <FieldsWrapper>
        <Input id='instanceId' name='instanceId' value={scheduleName} onChange={(e) => setScheduleName(e.target.value)} isRequired type='text' label={t('Schedule Name')} maxLength={64} />
      </FieldsWrapper>
      <SetScheduleWrapper>
        <SetScheduleLabel>{t('Rules')}</SetScheduleLabel>
      </SetScheduleWrapper>
      <ScheduleDaysContainer>
        <ScheduleDays actionIcons hideActionButtons scheduleDetails={updatedSchedule.rules} isUpdateList getScheduleList={getUpdatedScheduleList} />
      </ScheduleDaysContainer>
      <ButtonsContainer>
        <Button variant='primary' onClick={() => handleSaveSchedule()}>{t('Save')}</Button>
        <Button variant='secondary' onClick={() =>{ scheduleName === initialScheduleName && lodash.isEqual(updatedSchedule.rules, initialRules.rules) ? history.push('/settings/schedules') : setModalOpen(!modalOpen);}}>{t('Cancel')}</Button>
      </ButtonsContainer>
    </Container>
  );
};

export default Editschedule;