import React from 'react';
import styled from 'styled-components';
import Button, { ButtonProps } from './Button';
import Icon, { OwnProps as IconProps } from './Icon';

interface ButtonWithIconProps extends ButtonProps {
  icon: string;
  position?: string;
  iconProps?: IconProps;
}

const ButtonWrapper = styled(Button)<{position?: string}>`
  display: flex;
  flex-direction: ${({position}) => position==='left' ? 'row': 'row-reverse'};
  gap: 5px;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  > div > svg > path {
    fill: #FFFF;
  }
`;

const ButtonLabel = styled.span``;

const ButtonWithIcon: React.FC<ButtonWithIconProps> = ({
  icon,
  iconProps,
  position='left',
  children,
  ...buttonProps
}) => {
  return (
    <>
      <ButtonWrapper {...buttonProps} position={position}>
        <Icon icon={icon} {...iconProps} color='inverse' />
        <ButtonLabel>{children}</ButtonLabel>
      </ButtonWrapper>
    </>
  );
};

export default ButtonWithIcon;
