import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from 'styled-components';
import { DarkTheme, LightTheme } from './utils/theme';
import { GlobalStyle } from './globalStyles';
import './i18n';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, ApolloLink, Observable, split } from '@apollo/client';
import { API_BASE_URL, StatusCode, SUBSCRIPTION_BASE_URL, USER_MGMT_BASE_URL } from './constants';
import { setContext } from '@apollo/client/link/context';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import TokenService from 'api_configs/tokenService';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import Config from './configs/config.json';
import AppCloud from 'AppCloud';
import Amplify, { Auth } from 'aws-amplify';
import awsConfig, { API_key } from 'lib/amplify';
import { onError } from '@apollo/client/link/error';

Amplify.configure(awsConfig);

const graphqlEndpoint = awsConfig.aws_appsync_graphqlEndpoint;

const accessToken = TokenService.getLocalAccessToken();

const httpLink = () => createHttpLink({
  uri: Config.envType === 'edge' ? API_BASE_URL : graphqlEndpoint,
});

const wsClient = () => createClient({
  url: SUBSCRIPTION_BASE_URL,
  connectionParams: {
    headers: {
      Authorization: accessToken ? `Bearer ${accessToken}` : '',
    },
  },
  webSocketImpl: typeof WebSocket !== 'undefined' ? WebSocket : require('ws'),
  on: {
    connected: () => console.log('WebSocket connected'),
    connecting: () => console.log('WebSocket connecting'),
    closed: (event) => console.log('WebSocket closed', event),
    error: (error) => console.error('WebSocket error', error),
  },

});

const wsLink = () => new GraphQLWsLink(wsClient());

const splitLink = () => split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink(),
  httpLink(),
);

const authLink = () => setContext((_, { headers }) => {
  const accessToken = TokenService.getLocalAccessToken();

  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : '',
    },
  };
});

const getAuthToken = async () => {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken(); // or session.getAccessToken().getJwtToken()
  } catch (error) {
    console.error('Error fetching authentication token:', error);
    return null;
  }
};

const authLinkForCloud = setContext(async (_, { headers }) => {
  const token = await getAuthToken();

  let currentSession;
  try {
    currentSession = await Auth.currentSession();
  } catch (error) {
    currentSession = null;  // User is not logged in
  }

  const isUserLoggedIn = currentSession !== null;
  const cognitoUserId = isUserLoggedIn ? token : null;

  return {
    headers: {
      ...headers,
      ...(isUserLoggedIn ? { authorization: `Bearer ${cognitoUserId}` } : {}),
      ...(!isUserLoggedIn ? { 'X-Api-Key': API_key } : {}),
    },
  };
});

const responseLink = () => new ApolloLink((operation, forward) => {

  return new Observable((observer) => {
    forward(operation).subscribe({
      next: ({ data }) => {
        if(data?.getLatestAlert){
          if (data?.getLatestAlert?.statusCode === StatusCode.ACCESS_TOKEN_EXPIRED) {
            localStorage.setItem('token_expired', JSON.stringify(true));
          } else if (data?.getLatestAlert?.statusCode === StatusCode.USER_NOT_FOUND || data?.getLatestAlert?.statusCode === StatusCode.INACTIVE ) {
            TokenService.removeUser();
            window.location.href = '/login';
            localStorage.setItem('token_expired', JSON.stringify(false));
          } else{
            localStorage.setItem('token_expired', JSON.stringify(false));
          }
        }
        observer.next({ data });
      },
      error: (error) => {
        console.error('Received error:', error);
        observer.error(error); 
      },
      complete: observer.complete.bind(observer),
    });
  });
});

const httpLink_user_mgmt = () => createHttpLink({
  uri: USER_MGMT_BASE_URL,
});

const errorLink = () => onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

export const user_mgmt_client = new ApolloClient({
  link: ApolloLink.from([errorLink(), Config.envType === 'cloud' ? authLinkForCloud : authLink(), responseLink(), httpLink_user_mgmt()]),
  cache: new InMemoryCache({ addTypename: false }),
});

const client = new ApolloClient({
  link: ApolloLink.from([errorLink(), Config.envType === 'cloud' ? authLinkForCloud : authLink(), responseLink(), splitLink()]),
  cache: new InMemoryCache({ addTypename: false }),
});

const Root: React.FC = () => {
  const [darkMode,setDarkMode] = useState<boolean>(false);
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={darkMode ? DarkTheme : LightTheme}>
        <GlobalStyle />
        <Router>
          <Switch>
            {Config.envType === 'edge' ?
              <App {...{darkMode,setDarkMode}} /> : <AppCloud {...{darkMode,setDarkMode}} />}
          </Switch>
        </Router>
      </ThemeProvider>
    </ApolloProvider>
  );
}; 

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);

reportWebVitals();