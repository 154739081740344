import { ColumnConfig } from 'interface';
import React from 'react';
import styled from 'styled-components';

interface TableProps {
  columns: ColumnConfig[];
  data: any[];
  alternateRowColor?: boolean;
  infoRow?: () => React.ReactNode;
  noDataText?: string;
}

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TableRow = styled.div<{ alternateColor?: boolean, index?: number; isLastRow?: boolean }>`
  display: flex;
  padding: 10px 0;
  width: 100%;
  background-color: ${({ alternateColor, index }) =>
    alternateColor && index !== undefined && index % 2 === 0 ? '#FFF' : '#F4F6F8'};
  box-shadow: ${({ isLastRow }) => (isLastRow ? '0 4px 2px -2px #dbdbdb' : 'none')};
`;

const ColumnRow = styled(TableRow)`
  padding: 8px 0;
  background-color: #A4B0C7;
`;

const TableHeaderCell = styled.div<{ align?: string, flex?: number }>`
  flex: ${({ flex }) => flex || 1};
  text-align: ${({ align }) => align || 'left'};
  font-weight: bold;
  padding: 8px;
  color: #FFF;
  font-size: 18px;
`;

const TableCell = styled.div<{ align?: string, flex?: number }>`
  flex: ${({ flex }) => flex || 1};
  text-align: ${({ align }) => align || 'left'};
  padding: 8px;
`;

const NoDataFound = styled.div`
  margin: 60px auto;
  text-align: center;
  width: 100%;
  font-size: 20px;
`;

const Table: React.FC<TableProps> = ({ columns, data, alternateRowColor = true, infoRow, noDataText='No data found'}) => {
  return (
    <TableContainer>
      <ColumnRow>
        {columns.map((col) => (
          <TableHeaderCell
            key={col.columnId}
            align={col.alignHeader}
            flex={col.flex}
          >
            {col.header}
          </TableHeaderCell>
        ))}
      </ColumnRow>
      {data.map((row, rowIndex) => (
        <TableRow key={rowIndex} index={rowIndex} alternateColor={alternateRowColor} isLastRow={rowIndex === data.length - 1}>
          {columns.map((col) => (
            <TableCell
              key={col.columnId}
              align={col.alignText}
              flex={col.flex}
            >
              {col.render ? col.render(row[col.columnId], row) : row[col.columnId]}
            </TableCell>
          ))}
        </TableRow>
      ))}
      { data.length === 0 && 
        <NoDataFound>
          {noDataText}
        </NoDataFound>
      }
      { data.length !== 0 && infoRow && (
        <>
          {infoRow()}
        </>
      )}
    </TableContainer>
  );
};

export default Table;
