import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgAdd = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M13.842 4.342a1.342 1.342 0 0 0-2.684 0v5.816H5.342a1.342 1.342 0 0 0 0 2.684h5.816v5.816a1.342 1.342 0 0 0 2.684 0v-5.816h5.816a1.342 1.342 0 0 0 0-2.684h-5.816V4.342Z"
        fill={props.color}
      />
    </svg>
  );
};
export default SvgAdd;
