import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Skeleton from './skeletons/Skeleton';
import { FaAngleDoubleDown, FaAngleDoubleUp, FaChartPie } from 'react-icons/fa';
import Odometer from './Odometer';
import { useLazyQuery } from '@apollo/client';
import { GET_DETECTIONS_COUNT } from 'api_configs/queries';
import { StatusCode, ALGORITHM_CODES } from '../constants';
import Config from '../configs/config.json';
import { useDetection } from 'contexts/DetectionContext';
import { useTranslation } from 'react-i18next';

const StatisticsWrapper = styled.div`
  display: flex;
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  gap: 60px;
  width: 80%;
  padding: 20px;
`;

const StyledDiv = styled.div`
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ChartContainer = styled.div`
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Box = styled.div<{ borderColor: string; width: string }>`
  background: white;
  padding: 10px 70px;
  border-left: 10px solid ${(props) => props.borderColor};
  border-radius: 20px;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: ${({ width }) => width};
  min-width: 316px;
  height: 100px;
  margin: 0 10px;
`;


const Icon = styled.div`
  font-size: 2em;
  margin-right: 15px;
  color: ${(props) => props.color};
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Count = styled.div`
  font-size: 36px;
  font-weight: bold;
  line-height: normal !important;
  &,
  & * {
    line-height: normal !important;
  }
`;

const LabelText = styled.div`
  font-size: 18px;
  margin-top: 5px;
  color: #737D81;
`;

interface IStatisticInfoPanelProps {
  startDate: string,
  endDate: string,
  isFilterApplied: boolean,
  selectedCamera: string[],
  selectedGroup: number[],
}

const StatisticInfoPanel: React.FC<IStatisticInfoPanelProps> = ({startDate, endDate, isFilterApplied, selectedCamera, selectedGroup}) => {
  const [statisticsLoading, setStatisticsLoading] = useState(false);
  const [getDetections, { loading: detectionLoading }] = useLazyQuery(GET_DETECTIONS_COUNT);
  const [detectionData, setDetectionData] = useState({downCount: 0, totalCount: 0, upCount: 0});
  const { data } = useDetection();
  const {t} = useTranslation(['common']);

  const getDetectionCount = useCallback(async() => {
    let start_date = startDate;
    let end_date = endDate;
    setStatisticsLoading(true);
    if(!(startDate && endDate) && !isFilterApplied){
      const currentDate = new Date();
      const istOffset = 9 * 60 * 60 * 1000;
      const istDate = new Date(currentDate.getTime() + istOffset);
      end_date = istDate.toISOString().slice(0, 19).replace('T', ' ');
      const startOfDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
      const istStartOfDay = new Date(startOfDay.getTime() + istOffset);
      start_date = istStartOfDay.toISOString().slice(0, 19).replace('T', ' ').slice(0, 11) + '00:00:00';
    }
    const response = await getDetections({variables: {payload: {startDate: start_date, endDate: end_date, streamId: selectedCamera, groupIdList: selectedGroup, algoCode: ALGORITHM_CODES.traffic_pulse_detection }}});
    if(response?.data?.getDetectionCount?.result){
      const result = response?.data?.getDetectionCount?.result;
      const detectionData = {downCount: result?.data?.downCount, totalCount: result?.totalCount, upCount: result?.data?.upCount};
      setDetectionData({...detectionData});
    }else if(!response?.data?.getDetectionCount?.result && response?.data?.getDetectionCount?.statusCode === StatusCode.FAILED){
      setDetectionData({totalCount: 0, upCount: 0, downCount: 0});
    }
    setStatisticsLoading(false);
  },[getDetections, startDate, endDate, isFilterApplied, selectedCamera, selectedGroup]);

  useEffect(() => {
    if(Config.showDetectionCountOnDashboard) {
      getDetectionCount();
    }
  },[getDetectionCount]);

  useEffect(() => {
    if(Config.showDetectionCountOnDashboard) {
      if (!detectionLoading && data?.getDetectionsSub?.result && !isFilterApplied && data.getDetectionsSub.result.algoCode === ALGORITHM_CODES.traffic_pulse_detection && Config.isSubscriptionEnabled) {
        const detectionData = JSON.parse(data.getDetectionsSub.result.detectionData);
        const isUp = detectionData.direction_kind === 'forward';
        if(isUp) {
          setDetectionData(prev => ({downCount: prev?.downCount, totalCount: prev?.totalCount + 1, upCount: prev?.upCount + 1}));
        }else{
          setDetectionData(prev => ({downCount: prev?.downCount + 1, totalCount: prev?.totalCount + 1, upCount: prev?.upCount}));
        }
      }
    }
  }, [data, detectionLoading, isFilterApplied]);

  return (
    <StatisticsWrapper>
      {
        (statisticsLoading) ? <LoadingWrapper>
          <Skeleton type='rectBox'></Skeleton>
          <Skeleton type='rectBox'></Skeleton>
          <Skeleton type='rectBox'></Skeleton>
        </LoadingWrapper> : <>
          <StyledDiv>
            <ChartContainer className="draggable">
              <div className='chart-inner'>
                <Box borderColor="#6E8EFB" key="chart5" width={'340px'}>
                  <Icon color="#6E8EFB"><FaChartPie size={40} /></Icon>
                  <Info>
                    {detectionData !== undefined && detectionData?.totalCount !== 0 ? <Count><Odometer value={detectionData?.totalCount} fontSize={36} /></Count> : <Count>{'-'}</Count>}
                    <LabelText>{t('Total Vehicles')}</LabelText>
                  </Info>
                </Box>
              </div>
            </ChartContainer>
          </StyledDiv>
          <StyledDiv>
            <ChartContainer className="draggable">
              <div className='chart-inner'>
                <Box borderColor="#fffb00" key="chart5" width={'340px'}>
                  <Icon color="#fffb00"><FaAngleDoubleUp size={40} /></Icon>
                  <Info>
                    {detectionData !== undefined && detectionData?.upCount !== 0 ? <Count><Odometer value={detectionData?.upCount} fontSize={36} /></Count> : <Count>{'-'}</Count>}
                    <LabelText>{t('Up Count')}</LabelText>
                  </Info>
                </Box>
              </div>
            </ChartContainer>
          </StyledDiv>
          <StyledDiv>
            <ChartContainer className="draggable">
              <div className='chart-inner'>
                <Box borderColor="#12d88c" key="chart5" width={'340px'}>
                  <Icon color="#12d88c"><FaAngleDoubleDown size={40} /></Icon>
                  <Info>
                    {detectionData !== undefined && detectionData?.downCount !== 0 ? <Count><Odometer value={detectionData?.downCount} fontSize={36} /></Count> : <Count>{'-'}</Count>}
                    <LabelText>{t('Down Count')}</LabelText>
                  </Info>
                </Box>
              </div>
            </ChartContainer>
          </StyledDiv>
        </>}
    </StatisticsWrapper>
  );
};

export default StatisticInfoPanel;