import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgSchedule = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M22.145 9.656h-6a.188.188 0 0 0-.187.188v1.125c0 .103.084.187.187.187h6a.188.188 0 0 0 .188-.187V9.844a.188.188 0 0 0-.188-.188Zm-3.14 3.188h-2.86a.188.188 0 0 0-.187.187v1.125c0 .103.084.188.187.188h2.86a.188.188 0 0 0 .187-.188v-1.125a.188.188 0 0 0-.187-.187Zm-7.821-5.285h-1.015a.262.262 0 0 0-.263.262v5.813c0 .084.04.161.108.21l3.49 2.546a.262.262 0 0 0 .366-.056l.602-.823v-.002a.262.262 0 0 0-.059-.366l-2.97-2.147V7.821a.26.26 0 0 0-.26-.262Z"
        fill={props.color}
      />
      <path
        d="M18.863 15.795h-1.355a.386.386 0 0 0-.326.18 7.52 7.52 0 0 1-3.417 2.907 7.428 7.428 0 0 1-2.906.585 7.434 7.434 0 0 1-5.28-2.187A7.433 7.433 0 0 1 3.39 12a7.434 7.434 0 0 1 2.187-5.28 7.434 7.434 0 0 1 5.28-2.187 7.434 7.434 0 0 1 5.28 2.187c.4.398.746.834 1.044 1.305.07.113.195.18.326.18h1.355a.214.214 0 0 0 .192-.31 9.166 9.166 0 0 0-8.086-5.056c-5.065-.064-9.272 4.082-9.281 9.143-.01 5.07 4.099 9.182 9.168 9.182a9.168 9.168 0 0 0 8.199-5.057.215.215 0 0 0-.192-.312Z"
        fill={props.color}
      />
    </svg>
  );
};
export default SvgSchedule;
