import React from 'react';
import styled from 'styled-components';
import Label from './Label';

interface Ownprops {
  type: string;
  label?: string;
  value?: string | number;
  width?: string;
  unit?: string;
}

type InputProps = Ownprops & React.InputHTMLAttributes<HTMLInputElement>

const InputContainer = styled.div<{width?: string}>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${({width}) => width ? width : '100%'};
`;

const InputFieldWrapper = styled.div<{width:string}>`
  position: relative;
  display: flex;
`;

const Unit = styled.div`
  position: absolute;
  top: 45%;
  right: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform: translateY(-50%);
  padding: 5px 10px;
  font-size: 14px;
`;

const LabelWrapper = styled.div`
  display: flex;
  gap: 2px;
`;

const InputField = styled.input`
  padding-right: 40px;
  padding-left: 10px;
  width: 100%;
  border-radius: 6px;
  height: 40px;
  border: 1px solid lightgrey;
  font-size: 16px;
  padding-right: 60px !important;
  &:focus{
    outline: none;
    border-color: ${({theme}) => theme.input.focus};
  }
  :disabled{
    cursor: no-drop;
  }
  ::placeholder{
    font-style: italic;
    font-size: 12px;
  }
`;

const InputWithUnit: React.FC<InputProps> = ({width='100%', label, unit, ...props}) => {

  return (
    <InputContainer width={width}>
      {label && <LabelWrapper><Label labelText={label} />{props.required && <span style={{color: '#FF0000'}}> * </span>}</LabelWrapper> }
      <InputFieldWrapper width={width}>
        <InputField {...props} />
        <Unit>
          {unit}
        </Unit>
      </InputFieldWrapper>
    </InputContainer>
  );
};

export default InputWithUnit;