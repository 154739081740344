import Button from 'components/Button';
import ButtonWithLoading from 'components/ButtonWithLoading';
import Modal from 'components/Modal';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const ConfirmationText = styled.div`
  text-align: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const ConfirmationTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

interface ITextOnlyConfirmationModalProps{
  modalOpen: boolean,
  setModalOpen: (modalOpen: boolean) => void,
  onYes: ()=>void;
  onNo?: ()=>void;
  titleText: string;
  confirmDescription: string;
  isLoading?: boolean;
}

const TextOnlyConfirmationModal: React.FC<ITextOnlyConfirmationModalProps> = ({modalOpen, isLoading=false, setModalOpen, confirmDescription, titleText, onYes, onNo}) => {
  const {t} = useTranslation(['common']);
  const handleNo = () => {
    setModalOpen(false);
    if(onNo !== undefined) {
      onNo();
    }
  };

  return (
    <Modal closeModal={setModalOpen} isModalOpen={modalOpen}>
      <Wrapper>
        <ConfirmationTitle>{titleText}</ConfirmationTitle>
        <ConfirmationText>{confirmDescription}</ConfirmationText>
        <ButtonsWrapper>
          <ButtonWithLoading loading={isLoading} variant='primary' onClick={()=>onYes()}>{t('Yes')}</ButtonWithLoading>
          <Button variant='secondary' type='submit' onClick={()=>handleNo()}>{t('No')}</Button>
        </ButtonsWrapper>
      </Wrapper>
    </Modal>
  );
};

export default TextOnlyConfirmationModal;