import React, {  useCallback, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { IPointSet, IRestrictionPointsData, LineSetContext } from './LineUI';
import { LineUIType } from '../constants';
import { getAlgoIcon, getFillAreaColor } from '../utils/utils';
import { IReducerActions } from './LineUI/LineReducer';
import { ILowSpeedDetectionConfigs, IAnomalyDetectionConfigs, ITrafficPulseDetectionConfigs, INewAnalysisConfigsRes, IPoints, IRouteDeviationDetectionConfigs } from 'interface';
import { GET_ANALYSIS_CONFIGS } from 'api_configs/queries';
import { useQuery } from '@apollo/client';
import Modal from './Modal';
import ZoneLineUI from './LineUI/ZoneLineUI';
import Icon from './Icon';
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
} from 'react-zoom-pan-pinch';
import { IoMdAdd } from 'react-icons/io';
import { FiMinus } from 'react-icons/fi';
import { MdMyLocation } from 'react-icons/md';

const SpinnerContainer = styled.div<{width?: string, height?: string, minHeight?: string, backgroundColor?: string }>`
  width: ${({width}) => width ? width : '100%'};
  height: ${({height}) => height ? height : '100%'};
  min-height: ${({minHeight}) => minHeight};
  border-radius: 3px;
  background-color: ${({backgroundColor})=>backgroundColor? backgroundColor: '#E8E8E8'};
  display: flex; 
  justify-content: center;
  align-items: center;
  z-index: 3;
`;

const LineViwerContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const ResetButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  border: 2px solid ${({theme}) => theme.divider};
  right: 20px;
  top: 10px;
  position: absolute;
  z-index: 1000 ;
  width: 36px;
  height: 36px;
  background-color: white;
  cursor: pointer;
`;

const LineUIWrapper = styled.div<{width?: string, height?: string}>`
  width: ${({width}) => width};
  height: ${({height}) => height};
  border-radius: 5px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;

const LineToolToggles = styled.div`
  z-index: 9;
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  gap: 10px;
`;

const AlogorithmIcon = styled.div<{isSelected: boolean}>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({isSelected})=>isSelected ? '#FFFFFF': '#ffffff82'};
  cursor: pointer;
`;

const ActionWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconWrapper = styled.div<{disabled: boolean}>`
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: ${({disabled}) => disabled ? 'no-drop !important' : 'pointer'};
`;

const ActionIcon = styled.div<{disabled: boolean}>`
  width: 24px;
  height: 24px;
  z-index: 9;
  border-radius: 5px;
  background-color: #DFDFDF;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: ${({disabled}) => disabled ? 'none' : 'auto'};
  opacity: ${({disabled})=>disabled ? '0.6': ''};
`;

const ZoomInContainer = styled.div<{disable: boolean}>`
  border-bottom: 2px solid ${({theme}) => theme.divider};
  width: 35px;
  height: 35px;
  text-align: center;
  pointer-events: ${({disable})=> disable ? 'none' : 'auto'};
  cursor: ${({disable})=> disable ? 'auto' : 'pointer'};
  color: ${({disable})=> disable ? 'gray' : ''};
  > svg {
    margin-top: 4px;
  }
`;

const ZoomOutContainer = styled.div<{disable: boolean}>`
  width: 35px;
  height: 35px;
  text-align: center;
  pointer-events: ${({disable})=> disable ? 'none' : 'auto'};
  cursor: ${({disable})=> disable ? 'auto' : 'pointer'};
  color: ${({disable})=> disable ? 'gray' : ''};
  > svg {
    margin-top: 4px;
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 2px solid ${({theme}) => theme.divider};
  left: 10px;
  top: 10px;
  position: absolute;
  z-index: 1000 ;
  background-color: white;
`;

interface IZoneMapLineViewer {
  setSelectedLineIndex?: (id: number)=>void;
  streamID?: string;
  width?: string;
  height?: string;
  lineData?: IPointSet[];
  state: IPointSet[];
  dispatch?: React.Dispatch<IReducerActions>;
  showAlgorithmIcons?: boolean;
  onLineClickCallback?: (id: number)=>void;
  showDirectionMark?: boolean;
  minHeight?: string;
  isCamera?: boolean;
  hasFullScreenToggle?: boolean;
  floorMapImg?: string;
  restrictionPoints?:IRestrictionPointsData; 
}

const ZoneMapLineViewer: React.FC<IZoneMapLineViewer> = ({showAlgorithmIcons=false, isCamera=false, streamID, showDirectionMark=false,hasFullScreenToggle= true, width = '540px', onLineClickCallback=()=>{}, height = '310px', state, dispatch = ()=>{}, floorMapImg='', restrictionPoints= {data: [], selectedIndex: -1} }) => {
  const [linesVisisble, setLinesVisible] = useState<boolean>(false);
  const [lineData, setLineData] = useState<IPointSet[][]>([]);
  const [configuredAlgos, setConfiguredAlgos] = useState<INewAnalysisConfigsRes[]>([]);
  const { data, refetch } = useQuery(GET_ANALYSIS_CONFIGS,{ variables: {streamId: streamID} });
  const [selectedIndices, setSelectedIndices] = useState<number[]>([]);
  const [fullScreenView, setFullScreenView] = useState<boolean>(false);
  const [imageDetails, setImageDetails] = useState({x: 1920, y: 1080});
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);

  useEffect(()=>{
    localStorage.setItem('state', JSON.stringify(state));
  },[state]);

  useEffect(() => {
    if (floorMapImg) {
      const img = new Image();
      img.src = floorMapImg;
      img.onload = () => {
        setImageDetails({
          x: img.naturalWidth,
          y: img.naturalHeight
        });
      };
    }
  }, [floorMapImg]);

  const generateConfigPointSet = (config: INewAnalysisConfigsRes)=>{
    const configs = config?.configs as (ILowSpeedDetectionConfigs | ITrafficPulseDetectionConfigs | IAnomalyDetectionConfigs | IRouteDeviationDetectionConfigs)[];
    const lineDetails:IPointSet[] = [];
    configs?.map((config) => {
      if(config?.lineType === LineUIType.LINE && 'points' in config){
        const data= {
          name: config.name,
          points: config?.points as IPoints[],
          styling: config.styling,
          showMoveHandle: false,
          showPointHandle: false,
          showSmallDirectionMark: true
        };
        lineDetails.push(data);
      }else if(config?.lineType === LineUIType.AREA && 'points' in config){
        const data = {
          areaName: config.name,
          areaFillColor: getFillAreaColor(config.styling),
          areaTransparencyLevel: 40,
          points: config?.points as IPoints[],
          styling: config.styling,
          showMoveHandle: false,
          showPointHandle: false,
          showSmallDirectionMark: true
        };
        lineDetails.push(data);
      }else if(config?.lineType === LineUIType.LINE_SET && 'line_set' in config){
        const line1 = {
          name: config.name,
          areaTransparencyLevel: 40,
          points: config.line_set?.line1 as IPoints[],
          styling: config.styling,
          showMoveHandle: false,
          showPointHandle: false,
          showSmallDirectionMark: true
        };
        const line2 = {
          name: config.name,
          areaTransparencyLevel: 40,
          points: config.line_set?.line2 as IPoints[],
          styling: config.styling,
          showMoveHandle: false,
          showPointHandle: false,
          showSmallDirectionMark: true
        };
        lineDetails.push(line1);
        lineDetails.push(line2);
      }
      else if (config?.lineType === LineUIType.LINE_SET_ARRAY && 'line_set_array' in config) {
        if (config?.line_set_array) {
          for (let i = 0; i < config?.line_set_array.length; i++) {
            const line = {
              name: config.name,
              areaTransparencyLevel: 40,
              points: config?.line_set_array[i]?.points as IPoints[] || [],
              styling: config.styling,
              showMoveHandle: false,
              showPointHandle: false,
              showSmallDirectionMark: false,
            };
            lineDetails.push(line);
          }
        }
      }
      return null;
    });
    return lineDetails;
  };

  useEffect(()=>{
    refetch({streamId: streamID});
    const configDetails: INewAnalysisConfigsRes[]  = data?.getAnalysisConfig?.result !== null ? data?.getAnalysisConfig?.result : [];
    let pointData: IPointSet[][] = [];
    let configuredAlgorithms: INewAnalysisConfigsRes[] = [];
    if(configDetails){
      configuredAlgorithms = configDetails?.filter(config => config?.configs?.length > 0 && config?.enabled );
      pointData = configuredAlgorithms?.map(config => generateConfigPointSet(config)) as IPointSet[][];
    }
    setConfiguredAlgos(configuredAlgorithms);
    setLineData(pointData);
  },[data, streamID, refetch]);
 
  const getSelectedIndicesLineData = (indicesArray: number[]) => {
    const lineSetData: IPointSet[]= [];
    indicesArray?.forEach(index => {
      lineData[index]?.forEach(item => lineSetData.push(item));
    });
    return lineSetData;
  };

  const handleAlgorithmClick = useCallback((event: React.MouseEvent,index: number)=>{
    event.stopPropagation();
    setSelectedIndices(prev => {
      let prevIndicesArray = [...prev];
      if(prevIndicesArray.includes(index)){
        prevIndicesArray = prevIndicesArray.filter((item) => item !== index);
      }else{
        prevIndicesArray.push(index);
      }
      setLinesVisible( prevIndicesArray.length > 0);
      return prevIndicesArray;
    });
  },[]);

  const modifyPointPosition = useCallback((point: {x: number, y: number}) => {
    const newPoint = {...point};
    //check point's horizontal position.
    if (point.x < 0){
      newPoint.x = 0;
    } else if (point.x > imageDetails.x) {
      newPoint.x = imageDetails.x;
    }
    //check point's vertical position.
    if (point.y < 0){
      newPoint.y = 0;
    } else if (point.y > imageDetails.y) {
      newPoint.y = imageDetails.y;
    }
    return newPoint;
  },[imageDetails]);

  const getLinePoints = useCallback((state: IPointSet[]) => {
    const newState: IPointSet[] = state.map(pointSet => {
      const modifiedPoints = pointSet.points.map(point => modifyPointPosition(point));
      return {...pointSet, points: modifiedPoints};
    });
    return newState;
  },[modifyPointPosition]);

  const zoomToImage = () => {
    if (transformComponentRef.current) {
      const { zoomToElement } = transformComponentRef.current;
      zoomToElement('imgExample');
    }
  };

  return (
    <LineViwerContainer>
      <LineUIWrapper {...{width,height}}>
        <LineSetContext.Provider value={{ state: showAlgorithmIcons ? (linesVisisble && selectedIndices.length > 0) ? getLinePoints(getSelectedIndicesLineData(selectedIndices)) : [] : getLinePoints(state) , dispatch }}>
          {floorMapImg === '' ? 
            <SpinnerContainer {...{width,height, backgroundColor: '#ddd' }}>
            </SpinnerContainer>:
            <>
              {showAlgorithmIcons && lineData.length > 0 && 
                <LineToolToggles>
                  {configuredAlgos.map((algo,index)=>(
                    <AlogorithmIcon isSelected={selectedIndices.includes(index)} key={index} onClick={(e)=>handleAlgorithmClick(e,index)}>
                      {getAlgoIcon(algo.algorithmCode, false, 20, true)}
                    </AlogorithmIcon>
                  ))}
                </LineToolToggles>}
              { !fullScreenView ? 
                <ZoneLineUI
                  options={{
                    showSetIndex: false,
                    showPoint: true,
                    showDirectionMark: showDirectionMark,
                    showMoveHandle: false,
                    showPointHandle: true,
                    setIndexOffset: 0,
                  }}
                  src={floorMapImg}
                  onLineClick={onLineClickCallback}
                  hasClickSensingBorder={false}
                  isCamera={isCamera}
                  restrictionPoints={restrictionPoints}
                />:
                <Modal isModalOpen={fullScreenView} closeModal={() => setFullScreenView(false)} width='50vw'>
                  <>
                    <TransformWrapper
                      initialScale={1}
                      initialPositionX={0}
                      initialPositionY={0}
                      ref={transformComponentRef}
                      panning={{ disabled: true }}
                    >
                      {(utils) => (
                        <React.Fragment>
                          <IconsWrapper>
                            <ZoomInContainer disable={false} onClick={() => utils.zoomIn()}>
                              <IoMdAdd size={22} />
                            </ZoomInContainer>
                            <ZoomOutContainer disable={false} onClick={() => utils.zoomOut()}>
                              <FiMinus size={22} />
                            </ZoomOutContainer>
                          </IconsWrapper>
                          <ResetButton onClick={() => utils.resetTransform()}>
                            <MdMyLocation size={24} />
                          </ResetButton>
                          <TransformComponent>
                            <ZoneLineUI
                              options={{
                                showSetIndex: false,
                                showPoint: true,
                                showDirectionMark: showDirectionMark,
                                showMoveHandle: false,
                                showPointHandle: true,
                                setIndexOffset: 0,
                              }}
                              src={floorMapImg}
                              onLineClick={onLineClickCallback}
                              hasClickSensingBorder={false}
                              isCamera={isCamera}
                              restrictionPoints={restrictionPoints}
                            />
                            <div onClick={zoomToImage}></div>
                          </TransformComponent>
                        </React.Fragment>
                      )} 
                    </TransformWrapper>
                  </>
                </Modal>
              }
            </>
          }
        </LineSetContext.Provider>
        {hasFullScreenToggle &&
          <ActionWrapper>
            <IconWrapper disabled={!(floorMapImg)}>
              <ActionIcon disabled={!(floorMapImg)} onClick={()=>setFullScreenView(true)}>
                <Icon icon='Expand' color='primary' size={16} />
              </ActionIcon>
            </IconWrapper>
          </ActionWrapper> }
      </LineUIWrapper>
    </LineViwerContainer>
  );
};

export default ZoneMapLineViewer;