import React, { useState, useCallback, ChangeEvent, useEffect } from 'react';
import styled, {css} from 'styled-components';
import { useTranslation } from 'react-i18next';
import Input from 'components/Input';
import LoginBackground from '../svgs/login_background.jpg';
import LoginLogo from '../svgs/logo_login.png';
import { IAlertMessageType } from 'interface';
import AlertBar from 'components/AlertBar';
import { GUEST_USERNAME, IconType, PERMISSION_CODE_CLOUD, StatusCode } from '../constants';
import { useHistory } from 'react-router';
import AuthService from 'api_configs/authService';
import ButtonWithLoading from 'components/ButtonWithLoading';
import { GET_GUEST_USER_INFO } from 'api_configs/queries';
import { useQuery } from '@apollo/client';
import { Auth } from 'aws-amplify';
import Config from '../configs/config.json';
import awsConfig from '../lib/amplify';
import Amplify from 'aws-amplify';

const Container = styled.div`
  position: relative;
  padding: 130px;
  width: 100%;
  min-height: 100vh;
  @media (max-width: 1366px) {
    padding: 36px;
  }
`;

const Box = styled.div<{ margin?: string; flex?: string; }>`
  margin-top: 10px;
  button{
    border-radius: 10px;
    width: 100%;
    height: 40px;
    background-color: #0139C4;
    @media (max-width: 1366px) {
      height: 35px;
  }
  }
  
  ${({ margin }) => margin && css`
    margin: ${margin};
  `}

  ${({ flex }) => flex && css`
    flex: ${flex};
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
  `}
`;

const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
`;

const LinkWrapper = styled.div`
  text-decoration: underline;
  color: #0139C4;
  margin-top: 5px;
  cursor: pointer;
`;

const InputBox = styled.div`
  label > div > div:nth-child(2) > div > div {
    height: 20px;
    @media (max-width: 1366px) {
      height: 10px;
  }
  }
  >div> input{
    width: 400px;
    @media (max-width: 1366px) {
    width: 318px;
  }
  }
`;

const PasswordContainer = styled.div`
  button > div {
    margin-top: 5px;
  }
`;

const LoginWrapper = styled.div<{height: string}>`
  width: 440px;
  padding: 20px;
  background-color: #FFFFFF;
  border-radius: 10px;
  height: ${({height}) => height};
  @media (max-width: 1366px) {
    height: 450px;
    width: 360px;
  }
`;

const FormDiv = styled.div`
  max-height: 280px;
  max-width: 400px;
  margin-top: 12px;
`;

const GuestInfoWrapper = styled.div`
  > button {
    border-radius: 10px;
    width: 400px;
    height: 38px;
    background-color: #0139C4;
    @media (max-width: 1366px) {
    width: 318px;
    height: 35px;
  }
  }
`;

const Separator = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 6px;
  font-size: 14px;
`;

const LoginForm = styled.form`
  margin-left: auto;
  display: flex;
  margin-top: 120px;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  align-items: flex-end;
  @media (max-width: 1366px) {
    margin-top: 40px;
  }
`;

const ForgotPasswordWrapper = styled.div<{isGuestUser: boolean}>`
  margin-top: 3px;
  padding: 5px 0;
  width: 400px;
  display: flex;
`;

const AvtarImg = styled.img<{isGuestEnabled: boolean}>`
  margin-left: 90px;
  width: 200px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: ${({isGuestEnabled}) => isGuestEnabled ? '30px': '50px'};
  @media (max-width: 1366px) {
    width: 156px;
    margin-left: 80px;
  }
`;

const ForgotPasswordSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

Amplify.configure(awsConfig);

interface ILogin {
  setIsAuthenticated?: (value: boolean) => void
}

const Login: React.FC<ILogin> = ({setIsAuthenticated}) => {
  const [form, setForm] = useState({username:'', password:''});
  const { t } = useTranslation(['common']);
  const [loading, setLoading] = useState<boolean>(false);
  const [guestLoading, setGuestLoading] = useState(false);
  const history = useHistory();
  const authService = AuthService();
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'});
  const [guestInfo, setGuestInfo] = useState({enabled: false, username: GUEST_USERNAME, permissions: []});
  const {data: getUserManagementInfo, loading: getUserManagementInfoLoading} = useQuery(GET_GUEST_USER_INFO, { variables: {recordType: 'guest_info'} });

  useEffect(() => {
    if(Config.envType === 'edge') {
      if(!getUserManagementInfoLoading) {
        if(getUserManagementInfo) {
          setGuestInfo(getUserManagementInfo?.getUserMgmtInfo?.result);
        }
      }
    }
  }, [getUserManagementInfoLoading, getUserManagementInfo]);

  const onFieldChange = useCallback((key: 'username' | 'password') => ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setForm(prevForm => ({ ...prevForm, [key]: value.trim() }));
  }, []);

  const handleGuestLogin = useCallback(() => {
    setGuestLoading(true);
    localStorage.setItem('user_login_info', `{"accessToken": "${guestInfo?.username}","tokenType":"bearer","username": "${guestInfo?.username}","permissions": "[${guestInfo?.permissions}]"}`);
    setTimeout(() => {
      setGuestLoading(false);
      window.location.reload();
    }, 1000);
  }, [guestInfo]);

  const handleEdgeLogin = useCallback(async (e: React.FormEvent<HTMLFormElement | HTMLButtonElement>) => {
    e.preventDefault();
    try {
      setTimeout(async () => {
        const loginResponse = await authService.loginUser(form);
        if (loginResponse.login.statusCode === StatusCode.SUCCESS) {
          window.location.href = '/';
        }else{
          setMessage({ text: t('Invalid Username or Password'), id: '', type: 'danger' });
          setLoading(false);
        }
        setLoading(false);
      }, 2000);
    } catch (e) {
      setMessage({ text: t('Failed to communicate with the system'), id: '', type: 'danger' });
      setLoading(false);
    }
  }, [form, t, authService]);

  const handleCloudLogin = useCallback(async () => {
    try {
      const user = await Auth.signIn(form.username, form.password);
      localStorage.setItem('user_login_info', `{"accessToken": "${user.signInUserSession.accessToken.jwtToken}","refreshToken":"${user.signInUserSession.refreshToken.token}", "tokenType":"bearer","username": "${user.username}","permissions": "[${PERMISSION_CODE_CLOUD}]"}`);
      window.location.href = '/';
      if(setIsAuthenticated) {
        setIsAuthenticated(true);
      }
    } catch (error) {
      setMessage({ text: t('Invalid Username or Password'), id: '', type: 'danger' });
      setLoading(false);
    }
  }, [form.username, form.password, setIsAuthenticated, t]);

  const handleSubmit = useCallback((e: React.FormEvent<HTMLFormElement | HTMLButtonElement>) => {
    e.preventDefault();
    if (Config.envType === 'cloud') {
      handleCloudLogin();
    } else {
      handleEdgeLogin(e);
    }
  }, [handleCloudLogin, handleEdgeLogin]);


  return (
    <Container>
      {message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
      <BackgroundImage src={LoginBackground} />
      <LoginForm onSubmit={(e) => handleSubmit(e)}>
        <LoginWrapper height={guestInfo.enabled ? '486px': '440px'}>
          <FormDiv>
            <AvtarImg src={LoginLogo} isGuestEnabled={guestInfo.enabled} />
            <InputBox>
              <Input
                id='username'
                type='text'
                label={t('Username')}
                onChange={onFieldChange('username')}
                value={form.username}
                name='username'
                maxLength={128}
                width={'100%'}
              />
            </InputBox>
            <PasswordContainer>
              <InputBox>
                <Input
                  id='password'
                  type='password'
                  label={t('Password')}
                  onChange={onFieldChange('password')}
                  value={form.password}
                  name='password'
                  maxLength={32}
                  width={'100%'}
                />
              </InputBox>
            </PasswordContainer>
            <Box flex='1'>
              <ButtonWithLoading loading={loading} disabled={!((form.username.length > 0) && (form.password.length > 0))} variant='primary' type='submit' onClick={(e) => handleSubmit(e)}>{t('Login')}</ButtonWithLoading>
            </Box>
            {guestInfo.enabled && <Separator>{t('OR')}</Separator>}
            <ForgotPasswordSection>
              <ForgotPasswordWrapper isGuestUser={guestInfo.enabled}>
                {guestInfo.enabled && <GuestInfoWrapper><ButtonWithLoading loading={guestLoading} variant='primary' onClick={handleGuestLogin}>{t('Access as Guest')}</ButtonWithLoading></GuestInfoWrapper>}
              </ForgotPasswordWrapper>
            </ForgotPasswordSection>
            <ForgotPasswordSection>
              <LinkWrapper onClick={() => {history.push('/register'); window.location.reload();}}>{t('Register Now')}</LinkWrapper>
              <LinkWrapper onClick={() => {history.push('/forgot-password'); window.location.reload();}}>{t('Forgot Password?')}</LinkWrapper>
            </ForgotPasswordSection>
          </FormDiv>
        </LoginWrapper>
      </LoginForm>
    </Container>
  );
};

export default Login;