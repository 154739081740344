import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Icon from './Icon';
import React, { useState, useRef } from 'react';
import { ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import ConfirmationModal from './modals/ConfirmationModal';
import { ImageCropper } from './NewCropImageInputField';

const SingleImageAttributeWrapper = styled.div<{isBorder: boolean}>`
  width: 600px;
  height: 360px;
  border: ${({isBorder}) => isBorder ? '1px solid #ccc': 'none'};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  flex-direction: column;
  position: relative;
  border-radius: 5px;
`;

const ImagePreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;  
  justify-content: end;
  width: 100%;
`;

const ImagePreview = styled.img`
  width: 400px;
  height: 280px;
  margin-bottom: 5px;
  margin: auto;
`;

const ImagePreviewIconsWrapper = styled.div`
  cursor: pointer;
`;

const ImagePreviewIcons = styled.div`
  display: flex;
  margin-bottom: 20px;
  gap: 8px;
  justify-content: end;
`;

const NoImageText = styled.div`
  max-width: 60%;
  font-size: 14px;
  margin-bottom: 8px;
  color: ${({theme})=>theme.text.secondaryText};
  text-align: center;
`;

const AddNewAreaButton = styled.div`
  background: linear-gradient(to top, #1538BD, #7287D7);
  color: #FFFFFF;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  gap: 5px;
  cursor: pointer;
`;

interface IFile{
  filename: string;
  base64: string;
}

interface IUploadImageProps{
  setFile:React.Dispatch<React.SetStateAction<IFile>>;
  file: IFile;
}

const UploadImage: React.FC<IUploadImageProps> = ( {setFile, file}) => {
  const {t} = useTranslation(['common']);
  const [ modalOpen, setModalOpen] = useState<boolean>(false);
  const cropperRef = useRef<ReactCropperElement>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const triggerFileInput = (key: string) => {
    const input = document.getElementById(key) as HTMLInputElement | null;
    if (input) {
      input.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filename = event.target.files?.item(0)?.name;
    const reader = new FileReader();
    reader.onload = function (event) {
      const base64Image = event?.target?.result;
      setFile({filename: filename ? filename : '', base64: base64Image ? base64Image as string : ''});
      setModalOpen(true);
    };
    reader.readAsDataURL(event.target.files?.item(0)!);
  };

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== 'undefined') {
      setFile({filename:'', base64: cropperRef.current?.cropper.getCroppedCanvas().toDataURL()});
    }
  };

  return (
    <SingleImageAttributeWrapper isBorder={true}>
      {deleteModalOpen && 
        <ConfirmationModal modalOpen={deleteModalOpen} setModalOpen={setDeleteModalOpen} onSubmit={()=>{ setFile({filename:'', base64: ''}); setDeleteModalOpen(false); }} hasConfirmationField={false}
          titleText={t('Delete floor plan image?')} confirmDescription={t('Are you sure you want to delete floor plan image?')} noteText={t('Once deleted this action cannot be undone.')} />}
      {modalOpen &&
        <ImageCropper
          getCropData={getCropData}
          image={file.base64}
          handleFileChange={handleFileChange}
          cropperRef={cropperRef}
          open={modalOpen} 
          setDialogOpen={setModalOpen}
          titleText= 'Floor Plan Image'
        />}
      {file.base64 === '' ? <NoImageText>{t('No floor plan have been uploaded yet, Please upload new floor plan')}</NoImageText> : <ImagePreviewWrapper><ImagePreviewIcons><ImagePreviewIconsWrapper onClick={() => setModalOpen(true)}><Icon icon='Edit' size={30} /></ImagePreviewIconsWrapper>
        <ImagePreviewIconsWrapper onClick={() => setDeleteModalOpen(true) }><Icon icon='Delete' color='danger' size={30} /></ImagePreviewIconsWrapper>
      </ImagePreviewIcons><ImagePreview src={file.base64} alt='Hello' /> </ImagePreviewWrapper>}
      {file.base64 === '' && <AddNewAreaButton onClick={() => triggerFileInput('image')}>{t('Upload')} <Icon icon='Add' size={16} color='inverse'></Icon></AddNewAreaButton>}
      <input type='file' id={'image'} onChange={(e) => handleFileChange(e)} style={{ display: 'none' }} />
    </SingleImageAttributeWrapper>
  );
};

export default UploadImage;