import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgAlertStatus = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M11.96 3.893c-5.25 0-6 4.5-6 8.25s-2.25 6-2.25 6h16.5s-2.25-2.25-2.25-6-.75-8.25-6-8.25Zm0 0v-2.25m3 16.5s0 3-3 3-3-3-3-3"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={"non-scaling-stroke"}
      />
    </svg>
  );
};
export default SvgAlertStatus;
