import { useQuery } from '@apollo/client';
import { GET_STREAM_STATUS } from 'api_configs/queries';
import { useCallback, useEffect, useState } from 'react';

export const useStreamStatus = (streamId: string) => {
  const [streamStatus, setStreamStatus] = useState<string>('');
  const { data, refetch } = useQuery(GET_STREAM_STATUS, {variables: {streamId: streamId}});

  const updateStatusInfo = useCallback(() => {
    refetch();
    const status = data?.getStreamStatus?.result?.status ? data?.getStreamStatus?.result?.status : '';
    setStreamStatus(status);
  },[refetch, data]);
  
  useEffect(()=>{
    updateStatusInfo();
    const intervalId = setInterval(async () => {
      await updateStatusInfo();
    }, 5000);
    return () => clearInterval(intervalId);
  }, [updateStatusInfo]);

  return {
    streamStatus
  };
};