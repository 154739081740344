import { ScrollBarCss } from 'CommonStyles';
import { useClickOutside } from 'hooks/useClickOutside';
import React, { useState, ChangeEvent, useRef, KeyboardEvent } from 'react';
import styled from 'styled-components';

const SelectFieldContainer = styled.div`
  position: relative;
  width: 300px;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 4px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
`;

const OptionsList = styled.div`
  position: absolute;
  width: 100%;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 150px;
  overflow-y: auto;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 4px;
  padding: 0;
  bottom: 100%;
  ${ScrollBarCss};
`;

const OptionItem = styled.div`
  padding: 8px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

interface SelectFieldWithInputProps {
  options: {name: string, value: string}[];
  placeholder?: string;
  onSelect: (value: string) => void;
  defaultValue: string;
}

const SelectFieldWithInput: React.FC<SelectFieldWithInputProps> = ({ options=[], placeholder, onSelect, defaultValue='' }) => {
  const [searchTerm, setSearchTerm] = useState(() => options.find(item => item.value === defaultValue)?.name || '');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [showOptions, setShowOptions] = useState(false);
  const listRef = useRef<HTMLDivElement>(null);
  useClickOutside(listRef, () => setShowOptions(false));

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);

    const filtered = options.filter(option =>
      option.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredOptions(filtered);
    setShowOptions(true);
  };

  const handleOptionClick = (option: {name: string, value: string}) => {
    setSearchTerm(option.name);
    setShowOptions(false);
    onSelect(option.value);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && filteredOptions.length > 0) {
      const firstOption = filteredOptions[0];
      setSearchTerm(firstOption.name);
      setShowOptions(false);
      onSelect(firstOption.value);
    }
  };

  return (
    <SelectFieldContainer ref={listRef}>
      <SearchInput
        type="text"
        value={searchTerm}
        onChange={handleSearchChange}
        placeholder={placeholder}
        onFocus={() => setShowOptions(true)}
        onKeyDown={handleKeyDown}
      />
      {showOptions && (
        <OptionsList>
          {filteredOptions.map((option, index) => (
            <OptionItem key={index} onClick={() => {handleOptionClick(option);}}>
              {option.name}
            </OptionItem>
          ))}
        </OptionsList>
      )}
    </SelectFieldContainer>
  );
};

export default SelectFieldWithInput;
