import React, {useEffect, ChangeEvent, useState} from 'react';
import { useHeader } from 'contexts/HeaderContext';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Switch from 'components/Switch';
import Input from 'components/Input';
import { EXPORT_OPTIONS, IconType, PERMISSION_CODE, StatusCode } from '../../constants';
import { useMutation, useQuery } from '@apollo/client';
import { SAVE_INTEGRATION_CONFIG } from 'api_configs/mutations';
import { IAlertMessageType } from 'interface';
import AlertBar from 'components/AlertBar';
import { Container } from 'CommonStyles';
import { GET_INTEGRATION_SETTING } from 'api_configs/queries';
import CircularProgress from '@mui/material/CircularProgress';
import TokenService from 'api_configs/tokenService';
import ButtonWithLoading from 'components/ButtonWithLoading';
import { isEqual } from 'lodash';

const EndpointsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  padding: 15px 30px 30px 22px;
  height: 211px;
  width: 496px;
  border-radius: 4px;
  border: 0.2px solid lightgrey;
`;

const ConfigurationWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 450px;
  margin-top: 5px;
  margin-bottom: 14px;
  height: 30px;
`;

const EndpointsLabel = styled.div`
  min-width: 120px;
  font-size: 20px;
  margin-top: -4px;
`;

const SwitchLabel = styled.span`
  margin-top: -2px;
`;

const EnableCameraWrapper = styled.div`
  display: flex;
  width: 450px;
  justify-content: end;
  column-gap: 20px;
`;

const ButtonWrapper = styled.div`
  width: 450px;
  text-align: center;
  > button {
    height: 36px;
  }
`;

const SpinnerBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 89vh;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.backgroundColor};
`;

const Label = styled.div`
  font-size: 16px;
`;

interface IEndpointDetails {
  isEnabled: boolean;
  url?: string;
  }

const Integration = () =>{
  const {updateHeaderTitle} = useHeader();
  const {t} = useTranslation(['common']);
  const [endPointDetails, setEndPointDetails] = useState<IEndpointDetails>({isEnabled: false, url: ''});
  const [saveExportConfigMutation] = useMutation(SAVE_INTEGRATION_CONFIG);
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'});
  const {data: endpointsDetails, refetch, loading: endpointsLoading } = useQuery(GET_INTEGRATION_SETTING, { variables: {recordType: EXPORT_OPTIONS.EXPORT_TO_EXTERNAL_SERVER} });
  const [endpointsLoader, setEndpointsLoader] = useState(false);
  const editIntegrationAccess = TokenService.hasAccess(PERMISSION_CODE.edit_integration);
  const [prevEndPointDetails, setPrevEndPointDetails] = useState<IEndpointDetails>({isEnabled: false, url: ''});
  const [loader, setLoader] = useState(false);
  const [areEndPointsEqual, setAreEndPointsEqual] = useState(false);

  useEffect(()=>{
    updateHeaderTitle(t('Integration'), 'Export');
  },[updateHeaderTitle, t]);

  useEffect(()=>{
    setEndpointsLoader(endpointsLoading);
  },[endpointsLoading]);

  useEffect(()=>{
    if(endpointsDetails?.getIntegrationSetting?.statusCode === StatusCode.SUCCESS){
      if(endpointsDetails.getIntegrationSetting.result.data){
        const result = JSON.parse(endpointsDetails.getIntegrationSetting.result.data);
        const response = {
          isEnabled: result.enable,
          url: result.url
        };
        setEndPointDetails(response);
        setPrevEndPointDetails(response);
      }
    }
  },[endpointsDetails]);

  const onHandleChangeEndpoints = (event?: ChangeEvent<HTMLInputElement | HTMLSelectElement>)  =>{
    const { name, value } = event?.target || {};
    if(name === 'url'){
      setEndPointDetails((prev: IEndpointDetails) => ({...prev, url: value}));
    }
  };

  const onSaveEndpoints = async () =>{
    const payload = {
      recordType: EXPORT_OPTIONS.EXPORT_TO_EXTERNAL_SERVER,
      data: JSON.stringify({ url: endPointDetails.url, enable: endPointDetails.isEnabled })
    };
    setLoader(true);
    try {
      const result = await saveExportConfigMutation({variables: {payload}});
      if(result.data.putIntegrationSetting.statusCode === StatusCode.SUCCESS){
        setMessage({text: t('Endpoints configuration saved successfully'), id:'',type:'success'});
        refetch();
      }else {
        setMessage({text: t('apiError'), id:'',type:'danger'});
      }
    } catch(e){
      setMessage({text: t('apiError'), id:'',type:'danger'});
    }
    setLoader(false);
  };

  useEffect(() =>{
    refetch();
  },[refetch]);

  useEffect(() =>{
    setAreEndPointsEqual(isEqual(endPointDetails, prevEndPointDetails));
  },[endPointDetails, prevEndPointDetails]);

  if(endpointsLoader) {
    return (
      <SpinnerBox>
        <CircularProgress />
        <Label>{t('Loading...')}</Label>
      </SpinnerBox>
    );
  }

  return(
    <Container>
      {message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
      <EndpointsWrapper>
        <ConfigurationWrapper>
          <EndpointsLabel>{t('Endpoints')}</EndpointsLabel>
          <EnableCameraWrapper>
            <SwitchLabel>{endPointDetails.isEnabled ? t('Enabled'): t('Disabled')}</SwitchLabel>
            <Switch checked={endPointDetails.isEnabled} onChange={() =>setEndPointDetails(prev => ({...prev, isEnabled: !endPointDetails.isEnabled }))}/>
          </EnableCameraWrapper>
        </ConfigurationWrapper>
        <Input id='url' name='url' onChange={onHandleChangeEndpoints} value={endPointDetails.url} isRequired type='text' label={t('URL')} />
        <ButtonWrapper>
          <ButtonWithLoading loading={loader} variant='primary' onClick={onSaveEndpoints} disabled={!editIntegrationAccess || areEndPointsEqual}>{t('Save')}</ButtonWithLoading>
        </ButtonWrapper>
      </EndpointsWrapper>
    </Container>
  );
};

export default Integration;