import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgVehicleIdentity = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.777 10.61h-11.56c-.47 0-.94.33-1.07.73-.2.47-.07 1 .33 1.27l1.74 1.47c.2.2.47.27.73.27h8.08c.27 0 .53-.07.73-.27l1.74-1.47c.33-.27.53-.8.33-1.27-.13-.4-.6-.73-1.07-.73h.02Zm.07 1.2-1.74 1.47h-8.22l-1.74-1.47s-.07-.07 0-.07c0-.047.024-.07.07-.07h11.56s.07 0 .07.07v.07ZM25.593 10.88l-1.74 1.47c-.33.27-.47.8-.33 1.27.2.47.6.73 1.07.73h4.21c.6 0 1.07-.47 1.07-1.07v-1.6c0-.6-.47-1.07-1.07-1.07h-2.47c-.27 0-.53.13-.73.27h-.01Zm3.21.8v1.6h-4.21s-.07 0-.07-.07v-.07l1.74-1.47h2.54v.01ZM6.41 10.88c-.2-.13-.47-.27-.73-.27H3.21c-.6 0-1.07.47-1.07 1.07v1.6c0 .6.47 1.07 1.07 1.07h4.21c.47 0 .87-.27 1.07-.73.13-.47 0-1-.33-1.27l-1.74-1.47h-.01Zm1.07 2.34c0 .07-.07.07-.07.07h-4.2v-1.6h2.47l1.74 1.47c.07 0 .07.07.07.07l-.01-.01ZM6.95 15.96H3.21c-.27 0-.53.27-.53.53s.27.53.53.53h3.74c.27 0 .53-.27.53-.53s-.27-.53-.53-.53ZM28.793 15.96h-3.74c-.27 0-.53.27-.53.53s.27.53.53.53h3.74c.27 0 .53-.27.53-.53s-.27-.53-.53-.53Z"
        fill={props.color}
      />
      <path
        d="M17.297 22.173h4.02c.13-.12.25-.26.35-.4h-4.73c.11.14.22.28.35.4h.01ZM10.219 21.77c-.33 0-.6-.27-.6-.6v-4.21c0-.33.27-.6.6-.6h1.9c.07-.14.14-.27.22-.4h-2.12c-.53 0-1 .47-1 1v4.21c0 .6.47 1 1 1h1.3c-.04-.13-.07-.27-.1-.4h-1.2Z"
        fill={props.color}
      />
      <path
        d="M29.53 7.41h.87c.87 0 1.6-.67 1.6-1.6 0-.87-.73-1.6-1.6-1.6h-1.07c-.47 0-.94.27-1.2.6l-1-3.01C26.73.73 25.73 0 24.59 0H7.42c-1.14 0-2.2.73-2.54 1.8l-1 3.01c-.27-.33-.73-.6-1.2-.6H1.61c-.87 0-1.6.73-1.6 1.6 0 .94.73 1.6 1.6 1.6h.87l-2.01 2c-.27.27-.47.67-.47 1.14v7.01c0 .67.47 1.27 1.07 1.47v1.74c0 .87.73 1.6 1.6 1.6h3.74c.87 0 1.6-.73 1.6-1.6v-1.6h.8V18.1H1.6c-.27 0-.53-.27-.53-.53v-7.01c0-.13.07-.27.13-.4l2.74-2.74h24.12l2.67 2.74c.13.13.2.27.2.4v7.01c0 .27-.27.53-.53.53h-3.26c.09.35.16.7.2 1.07h2.52v1.6c0 .27-.27.53-.53.53h-2.05c-.06.36-.14.72-.25 1.07h2.3c.87 0 1.6-.73 1.6-1.6v-1.74c.6-.2 1.07-.8 1.07-1.47v-7.01c0-.47-.2-.87-.47-1.14l-2-2ZM2.14 19.16h4.81v1.6c0 .27-.27.53-.53.53H2.68c-.27 0-.53-.27-.53-.53v-1.6h-.01ZM3.21 6.33h-1.6a.52.52 0 0 1-.53-.53c0-.27.27-.53.53-.53h1.07c.27 0 .53.27.53.53v.53Zm1.27 0 1.4-4.21c.2-.67.87-1.07 1.54-1.07h17.17c.67 0 1.27.4 1.54 1.07l1.4 4.21H4.48ZM28.8 5.8c0-.27.2-.53.53-.53h1.07c.27 0 .53.27.53.53 0 .33-.27.53-.53.53h-1.6V5.8Z"
        fill={props.color}
      />
      <path
        d="m27.522 27.45-3.54-3.54c.41-.5.73-1.07.97-1.67.14-.34.24-.7.32-1.06.07-.37.11-.75.11-1.14 0-.3-.03-.58-.07-.87-.05-.37-.14-.72-.25-1.07-.82-2.4-3.09-4.14-5.75-4.14a6.064 6.064 0 0 0-4.82 2.4 6.045 6.045 0 0 0-1.25 3.67c0 .6.09 1.19.26 1.74.04.14.09.27.14.4.87 2.29 3.09 3.94 5.68 3.94 1.47 0 2.81-.53 3.87-1.4l3.54 3.54c.13.07.27.13.4.13s.27-.07.4-.13c.2-.27.2-.6 0-.8h-.01Zm-8.22-2.47a4.906 4.906 0 0 1-4.61-3.21c-.2-.54-.33-1.12-.33-1.74 0-1.49.66-2.8 1.69-3.7.02-.02.05-.04.07-.06.86-.73 1.96-1.18 3.18-1.18a4.933 4.933 0 0 1 4.94 4.95c0 .43-.07.84-.17 1.24a4.916 4.916 0 0 1-4.77 3.7Z"
        fill={props.color}
      />
    </svg>
  );
};
export default SvgVehicleIdentity;
