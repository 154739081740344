import { useMutation, useQuery } from '@apollo/client';
import { DataGrid, GridColDef, GridRowId, GridRowSelectionModel } from '@mui/x-data-grid';
import { EXPORT_OBJECTS, GET_ALL_OBJECTS, GET_ALL_OBJECT_TYPES, GET_OBJECT_TYPE } from 'api_configs/queries';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import Tabs, { ITabs } from 'components/Tabs';
import { SERVER_URL, PRODUCT_IMAGES_URL, IMAGE_URL, StatusCode, IconType, PERMISSION_CODE, PROPERTY_TYPE } from '../constants';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHeader } from 'contexts/HeaderContext';
import NoObjectsPage from './NoObjectsPage';
import { getQueryParams } from 'utils/utils';
import ProductsFullScreenView from 'components/ProductsFullScreenView';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import { DELETE_OBJECT } from 'api_configs/mutations';
import { IAlertMessageType } from 'interface';
import { AiOutlineExport } from 'react-icons/ai';
import { AiOutlineImport } from 'react-icons/ai';
import AlertBar from 'components/AlertBar';
import Button from 'components/Button';
import TokenService from 'api_configs/tokenService';
import { CircularProgress, Stack } from '@mui/material';
import Pagination from 'components/Pagination';
import SearchInputFilter, { IAppliedFilter, ISearchQueryKeys } from 'components/SearchInputFilter';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TablePaginationWrapper = styled.div<{rowHeight: number}>`
  margin-top: 22px;
  max-width: 1690px;
  min-width: 1200px;

  .MuiDataGrid-columnHeaderTitle {
    font-weight: bold;
    font-size: 15px;
  }

  .MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }
  > div:nth-child(1) > div:nth-child(2){
    display: none !important;
  }
  > div:first-child {
    > div:nth-child(1) {
      > div:nth-child(2){ 
        min-height: ${({rowHeight}) => `${rowHeight}px`};
        border-bottom: none !important;
        overflow: hidden;
      }
    }
  }
  > div > div:first-child{
    > div:first-child{
      background-color: #F5F8FF;
      font-size: 14px;
      color: #5a6269;
    }
  }
  > div > div:first-child{
  > div:first-child{
    font-size: 14px;
    color:#7b8288;
    font-weight: 800;
  }
  > div:nth-child(2) {
    text-align: left;
    font-size: 14px;
    text-decoration: none;
  }
}
`;

const TextCellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 200px;
  word-wrap: break-word;
  justify-content: space-between;
  gap: 14px;
  padding: 10px;
`;

const TextCell = styled.div`
  display: flex;
  gap:5px;
  font-size: 16px;
`;

const ModalImage = styled.img`
  width: 650px;
  height: 400px;
`;

const ActionIconsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const DownloadIconWrapper = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 5px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;

const TabsContainer = styled.div`
  margin-top: 20px;
  position: relative;
`; 

const ImageCell = styled.div`
  display: flex;
  gap:5px;
  font-size: 16px;
  cursor: pointer;
`;

const HeaderButtonWrapper = styled.div<{ isDisabled?: boolean; isDefault?: boolean }>`
  width: 110px;
  height: 40px;
  background: linear-gradient(to top, #1538bd, #7287d7);
  border-radius: 5px;
  opacity: ${({ isDisabled, isDefault }) => (isDisabled || isDefault ? '0.6' : '1')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  cursor: ${({ isDisabled, isDefault }) => (isDisabled || isDefault ? 'not-allowed' : 'pointer')};
  position: relative;

  &:hover {
    .tooltip {
      display: ${({ isDefault }) => (isDefault ? 'block' : 'none')};
    }
  }
`;

const NewObjectText = styled.div`
  font-size: 14px;
  color: ${({theme}) => theme.text.lightText};
`;

const Loading = styled.span``;

const ImageProperty = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 8px;
`;

const TabContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AllObjectTypesContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  min-height: calc(100vh - 120px);
  padding: 10px 20px;
  background-color: ${({ theme }) => theme.backgroundColor};  
`;

const HeaderWrapper = styled.div`
  display: flex;
  column-gap: 20px;
  justify-content: flex-start !important;
  width: 100%;
`;

const ThumbnailWrapper = styled.div`
  padding: 12px 14px;
  margin-top: 2px;
  margin-bottom: 2px;
  position: relative;
  border-radius: 6px;
  width: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProductImageThumbnail = styled.img<{ isEnabled: boolean }>`
  width: 80px;
  height: 80px;
  aspect-ratio: 3/2;
  border-radius: 8px;
  opacity: ${({ isEnabled }) => isEnabled ? '' : '0.5'};
  transition: all 0.3s ease-out;
  padding: 2px;
  z-index: 2;
  cursor: pointer;
  :hover {
    transform: scale(1.2);
    transition: all 0.3s ease-out;
  }
`;

const ProductImagesCountWrapper = styled.div`
  position: absolute;
  top: 65px;
  right: 31px;
  display: flex;
  z-index: 3;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: black;
`;

const ImagesCount = styled.span`
  position: absolute;
  top: 10px;
  right: 5px;
  padding: 0 6px;
  background-color: #ffffff;
  border: 1px solid #000000;
  border-radius: 10px;
  font-size: 12px;
`;

const SearchWrapper = styled.div`
  width: 70%;
  min-width: 580px;
`;

const FirstWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1690px !important;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 300px;
  gap: 10px;
`;

const Tooltip = styled.div`
  width: 137px;
  height: 70px;
  background-color: black;
  color: white;
  position: absolute;
  top: -75px;
  left: -12px;
  display: none;
  border-radius: 5px;
  padding: 4px;
`;

const DeleteButtonWrapper = styled.div<{isRowsSelected: boolean}>`
  display: ${({isRowsSelected}) => isRowsSelected ? 'none' : 'flex'};
  margin-right: 20px;
  > button {
    width: 110px;
    height: 40px;
  }
`;

const PaginationBox = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
`;

interface ICurrentObjectType {
  id: number,
  name?: string,
  isDefault: boolean
  properties: any
}

interface IObjectType {
  description: string,
  id: number,
  name: string,
  properties: any[]
}

interface IProperty {
  displayName: string,
  featureExtraction?: string,
  isRequired: boolean,
  key: string,
  maxImages?: number,
  propertyType: string,
  sequence: string,
  sequenceId?: number,
  isTextOnly?: boolean,
  maxTextLength?: number,
  selectOptions?: string[],
  rows?: number,
  columns?: number
}

const ObjectList = () => {
  const [currentObjectTypeIndex, setCurrentObjectTypeIndex] = useState<number>();
  const [allObjectTypes, setAllObjectTypes] = useState([]);
  const [tableColumns, setTableColumns] = useState<GridColDef[]>([]);
  const location = useLocation();
  const {t} = useTranslation(['common']);
  const [deleteObject] = useMutation(DELETE_OBJECT);
  const {updateHeaderTitle} = useHeader();
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: ''}); 
  const [isImageModalOpen, setImageModalOpen] = useState<number | null>(null);
  const [multiDeleteConfirmModal, setMultiDeleteConfirmModal] = useState(false);
  const {push} = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [singleObjectDeleteId, setSingleObjectDeleteId] = useState(); 
  const { data, loading: getSingleObjectLoading, refetch: refetchSingleObject } = useQuery(GET_OBJECT_TYPE, {variables: {id: currentObjectTypeIndex ? Number(currentObjectTypeIndex) : 0}});
  const [tabsConfig, setTabsConfig] = useState<ITabs[]>([]);
  const [modalImagePath, setModalImagePath] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [isObjectTabSelected, setIsObjectsTabSelected] = useState(false);
  const [exportDisabled, setExportDisabled] = useState(false);
  const [deleteStreamPopUp, setDeleteStreamPopUp] = useState(false);
  const [searchQueries, setSearchQueries] = useState<ISearchQueryKeys[]>();
  const [deleteObjectIds, setDeleteObjectIds] = useState<GridRowId[]>([]);
  const [rowHeight, setRowHeight] = useState<number>();
  const [pageCount, setPageCount ] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentObjectType, setCurrentObjectType] = useState<ICurrentObjectType>();
  const {data: getAllObjectTypes, loading, refetch: refetchAllObjectTypes} = useQuery(GET_ALL_OBJECT_TYPES);
  const {refetch: refetchExportObjects} = useQuery(EXPORT_OBJECTS);
  const [searchResultsLoader, setSearchResultsLoader] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const { data:allObjects, loading: allObjectsLoading, refetch: allObjectsDataRefetch } = useQuery(GET_ALL_OBJECTS, {variables: {payload: {objectTypeId: currentObjectTypeIndex ? Number(currentObjectTypeIndex): 0, pageNumber: currentPage, pageSize: pageSize}}});
  const addObjectAccess = TokenService.hasAccess(PERMISSION_CODE.add_object);
  const editObjectAccess = TokenService.hasAccess(PERMISSION_CODE.edit_object);
  const deleteObjectAccess = TokenService.hasAccess(PERMISSION_CODE.delete_object);
  const editObjectTypeAccess = TokenService.hasAccess(PERMISSION_CODE.edit_object_type);
  const addObjectTypeAccess = TokenService.hasAccess(PERMISSION_CODE.add_object_type);
  const importObjectsAccess = TokenService.hasAccess(PERMISSION_CODE.import_objects);
  const exportObjectsAccess = TokenService.hasAccess(PERMISSION_CODE.export_objects);

  useEffect(() => {
    allObjectsDataRefetch({variables: {payload: {objectTypeId: currentObjectTypeIndex ? Number(currentObjectTypeIndex): 0, pageNumber: currentPage, pageSize: pageSize}}});
  }, [allObjectsDataRefetch, currentObjectTypeIndex, pageSize, currentPage]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const selectedTab = searchParams.get('selectedTab');
    const queryParams = getQueryParams();
    if(Object.keys(queryParams).length === 0) {
      setIsObjectsTabSelected(true);
    }
    if(selectedTab === '-1') {
      push('/objects/addobjecttype');
    }
    if(selectedTab)
      setCurrentObjectTypeIndex(Number(selectedTab));
  }, [location.search,push]);

  useEffect(()=>{
    updateHeaderTitle(t('All Objects'), 'Object');
  },[updateHeaderTitle,currentObjectType, t]);

  useEffect(() => {
    refetchSingleObject();
    if(!getSingleObjectLoading) {
      if(data) {
        setCurrentObjectType(data?.getObjectType?.result);
        setSearchQueries(
          data?.getObjectType?.result?.properties
            ?.map((property: IProperty) => {
              if (property.propertyType === PROPERTY_TYPE.Text || property.propertyType === PROPERTY_TYPE.SelectField) {
                return { keyName: property.key, displayName: property.displayName };
              } else {
                return null;
              }
            })
            .filter(Boolean)
        );
      }
    }
  }, [getSingleObjectLoading, data, t, refetchSingleObject]);

  useEffect(() => {
    allObjectsDataRefetch({variables: {payload: {objectTypeId: currentObjectTypeIndex ? Number(currentObjectTypeIndex): 0, pageNumber: currentPage, pageSize: pageSize}}});
    if(!loading) {
      if(getAllObjectTypes?.getAllObjectTypes?.result) {
        setAllObjectTypes(getAllObjectTypes?.getAllObjectTypes?.result);
        if(isObjectTabSelected) {
          setCurrentObjectTypeIndex(getAllObjectTypes?.getAllObjectTypes?.result[0]?.id);
          allObjectsDataRefetch({variables: {payload: {objectTypeId: currentObjectTypeIndex ? Number(currentObjectTypeIndex): 0, pageNumber: currentPage, pageSize: pageSize}}});
          setIsObjectsTabSelected(false);
        }
      }
    }
  }, [loading, getAllObjectTypes, currentObjectTypeIndex, currentPage, pageSize, isObjectTabSelected, allObjectsDataRefetch]);

  useEffect(() => {
    refetchAllObjectTypes();
  }, [refetchAllObjectTypes]);

  useEffect(() => {
    if(!allObjectsLoading) {
      if(allObjects) {
        setSearchResults(allObjects?.getAllObjects?.result);
        setPageCount(allObjects?.getAllObjects?.count);
      }
    }
  }, [allObjectsLoading, allObjects]);

  useEffect(() => {
    allObjectsDataRefetch({ objectTypeId: currentObjectTypeIndex, pageSize, pageNumber: currentPage });
  }, [currentPage, pageSize, currentObjectTypeIndex, allObjectsDataRefetch]);

  useEffect(() => {
    const thumbnailCount = currentObjectType?.properties?.filter((property: any) => property.propertyType === PROPERTY_TYPE.Image || property.propertyType === PROPERTY_TYPE.MultiImage);
    if(thumbnailCount?.length > 0) {
      setRowHeight(100);
    }else {
      setRowHeight(60);
    }
  }, [currentObjectType]);

  useEffect(() => {
    setTimeout(() => {
      if(exportDisabled) {
        setExportDisabled(false);
      }
    }, 2000);
  }, [exportDisabled]);

  const handleDeleteObject = useCallback(async () => {
    try {
      const result = await deleteObject({
        variables: {
          idList: deleteObjectIds.length > 0 ? deleteObjectIds : singleObjectDeleteId
        }
      });
      if (result?.data?.deleteObjects?.statusCode === StatusCode.SUCCESS) {
        setMessage({ text: t('Objects deleted Successfully'), id: '', type: 'success' });
        allObjectsDataRefetch({ objectTypeId: currentObjectTypeIndex, pageSize, pageNumber: currentPage });
      } else {
        setMessage({ text: t('apiError'), id: '', type: 'danger' });
      }
    } catch (e) {
      setMessage({ text: t('apiError'), id: '', type: 'danger' });
      console.error(e);
    }
  }, [deleteObjectIds, singleObjectDeleteId, t, currentPage, pageSize, currentObjectTypeIndex, deleteObject, allObjectsDataRefetch]);

  const handleChange = (value: number) => {
    setCurrentPage(value);
  };

  const handleMultiDeleteObject = () => {
    setMultiDeleteConfirmModal(false);
    handleDeleteObject();
    setDeleteObjectIds([]);
  };

  const handleExport = useCallback(async () => {
    setExportDisabled(true);
    const payload = {objectTypeId: Number(currentObjectTypeIndex), objectIdList: deleteObjectIds};
    try {
      const result = await refetchExportObjects({ payload: payload });
      if(result.data.exportObjects.statusCode === StatusCode.SUCCESS){
        setMessage({text: t('Objects exported successfully'), id:'',type:'success'});
        setDeleteObjectIds([]);
        downloadLink(SERVER_URL + result.data.exportObjects.result.file);
      } else{
        setMessage({text: t('apiError'), id:'',type:'danger'});
        setDeleteObjectIds([]);
      }
    }catch(e){
      setMessage({text: t('apiError'), id:'',type:'danger'});
      setDeleteObjectIds([]);
    }
  }, [currentObjectTypeIndex, refetchExportObjects, t, deleteObjectIds]);
  
  const generateRowData = useCallback(() => {
    return searchResults?.map((newEntry) => newEntry);
  }, [searchResults]);

  const handleRowSelectionModelChange = (params: GridRowSelectionModel) => {
    setDeleteObjectIds(params ? params : []);
  };

  const searchFilterCallback = useCallback(async (appliedFilters: IAppliedFilter[]) => {
    if (appliedFilters.length > 0) {
      setSearchResultsLoader(true);
      setSearchResults([]);
      try {
        const filteredObjects = await Promise.all(
          appliedFilters.map(async (filter) => {
            const { keyName, query } = filter;
            const result = await allObjectsDataRefetch({ payload: { objectTypeId: currentObjectTypeIndex, searchField: keyName, searchText: (query as string).toLowerCase() } });
            return result;
          })
        );
        setSearchResultsLoader(false);
        setSearchResults(filteredObjects[0]?.data?.getAllObjects?.result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else {
      setSearchResults([]);
      setSearchResultsLoader(true);
      const result = await allObjectsDataRefetch({payload: { objectTypeId: currentObjectTypeIndex, pageSize, pageNumber: currentPage }});
      setSearchResults(result?.data?.getAllObjects?.result);
      setSearchResultsLoader(false);
    }
  }, [currentPage, pageSize, currentObjectTypeIndex, allObjectsDataRefetch]);

  const getLoading = useCallback(()=>{
    return (
      <LoadingContainer>
        <CircularProgress />
        <Loading>{t('Loading')}</Loading>
      </LoadingContainer>
    );
  },[t]);

  useEffect(() => {
    const tabsData = allObjectTypes.map((objectType: IObjectType) => {
      return {
        id: objectType.id.toString(),
        name: objectType.name,
        content:
        <TabContentWrapper>
          <FirstWrapper>
            <DeleteButtonWrapper isRowsSelected={deleteObjectIds.length === 0}>
              <Button variant='danger' onClick={() => {setMultiDeleteConfirmModal(true);}}>{t('delete')}</Button>
            </DeleteButtonWrapper>
            <HeaderWrapper>
              <SearchWrapper>
                <SearchInputFilter enableAdvancedFilter={false} searchFilterCallback={searchFilterCallback} searchQueryKeys={searchQueries} placeholder={t('Search across your objects...')} enableMultiSelect/>
              </SearchWrapper>
              { importObjectsAccess && <HeaderButtonWrapper onClick={()=>push(`/objects/importobjects/${currentObjectTypeIndex}`)}>
                <NewObjectText>{t('Import')}</NewObjectText>
                <AiOutlineImport size={18} color='#ffffff'/>
              </HeaderButtonWrapper>}
              { exportObjectsAccess &&<HeaderButtonWrapper isDisabled={exportDisabled} title={`Export all ${currentObjectType?.name}`} onClick={() => handleExport()}>
                <NewObjectText>{t('Export')}</NewObjectText>
                <AiOutlineExport size={18} color='#ffffff'/>
              </HeaderButtonWrapper>}
              { editObjectTypeAccess && <HeaderButtonWrapper onClick={()=>push(`/objects/editobjecttype/${currentObjectType?.id}`)}>
                <NewObjectText>{t('Edit Type')}</NewObjectText>
                <Icon icon='Edit' size={20} color='inverse' />
                <Tooltip className='tooltip'>{t('This is the default object type and cannot be edited.')}</Tooltip>
              </HeaderButtonWrapper>}
              { addObjectAccess && <HeaderButtonWrapper onClick={()=>push(`/objects/${currentObjectType?.id}/add`)}>
                <NewObjectText>{t('Object')}</NewObjectText>
                <Icon icon='Plus' size={20} color='inverse' />
              </HeaderButtonWrapper>}
            </HeaderWrapper>
          </FirstWrapper>
          {loading ? getLoading() : <TablePaginationWrapper rowHeight={rowHeight !== undefined ? rowHeight : 60}>
            <DataGrid
              rows={generateRowData() ? generateRowData() : []}
              columns={tableColumns}
              checkboxSelection={deleteObjectAccess}
              disableRowSelectionOnClick
              onRowSelectionModelChange={handleRowSelectionModelChange}
              disableColumnMenu
              getRowId={(row) => row?.id}
              getRowHeight={() => rowHeight}
              components={{
                NoRowsOverlay: () => (
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    {allObjectsLoading || searchResultsLoader ? t('Loading...') : t('No Data')}
                  </Stack>
                )
              }}
            /> 
            {searchResults?.length > 0 && <PaginationBox> 
              <Pagination
                onChange={handleChange}
                pageSize={pageSize} 
                count={Math.ceil(pageCount/pageSize)} 
                setPageSize={(e) => setPageSize(e)}
                currentPage={currentPage}
              />
            </PaginationBox>}
          </TablePaginationWrapper>
          }
        </TabContentWrapper>,
      };
    });
    const addButton = {
      id: '-1',
      name: t(''),
      icon: 'Add',
      isIconOnly: true,
      content: <NoObjectsPage />,
    };
    if (addObjectTypeAccess) {
      setTabsConfig([...tabsData, addButton as ITabs]);
    }else{
      setTabsConfig([...tabsData]);
    }
  }, [allObjectTypes, addObjectTypeAccess, deleteObjectAccess, exportDisabled, searchFilterCallback, searchResults, generateRowData, searchQueries, rowHeight, getLoading, tableColumns, editObjectTypeAccess, currentObjectTypeIndex, t, handleDeleteObject, deleteObjectIds, loading, push, currentObjectType, handleExport, addObjectAccess, editObjectAccess, importObjectsAccess, exportObjectsAccess, pageSize, pageCount, allObjectsLoading, searchResultsLoader, currentPage]);

  useEffect(() => {
    if(currentObjectType) {
      const columns = currentObjectType?.properties?.map((property: any) => {
        if(property.propertyType === PROPERTY_TYPE.Text) {
          return {
            field: property.key,
            headerName: property.displayName,
            flex: 1.3,
            sortable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params: any) => (
              <TextCellWrapper>
                <TextCell>{params.row.objectProperties[property.key]}</TextCell>
              </TextCellWrapper>
            )
          };
        } else if(property.propertyType === PROPERTY_TYPE.Image) {
          return {
            field: property.key,
            headerName: property.displayName,
            flex: 1.3,
            sortable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params: any) => (
              <TextCellWrapper>
                {modalOpen && <Modal isModalOpen={modalOpen} closeModal={setModalOpen} width='700px'>
                  <ModalImage src={modalImagePath} alt='Property'/>
                </Modal>}
                {params.row.objectProperties[property.key] !== '' ? <ImageCell onClick={() => {setModalImagePath(PRODUCT_IMAGES_URL.replace('{SERVER_URL}', SERVER_URL).replace('{ID}', params.row.id).replace('{imageUrl}', `images/${params.row.objectProperties[property.key]}`)); setModalOpen(true);}}><ImageProperty src={PRODUCT_IMAGES_URL.replace('{SERVER_URL}', SERVER_URL).replace('{ID}', params.row.id).replace('{imageUrl}', `images/${params.row.objectProperties[property.key]}`) as string} alt='Hello'/></ImageCell>: '-'}
              </TextCellWrapper>
            )
          };
        } else if(property.propertyType === PROPERTY_TYPE.MultiImage) {
          return {
            field: property.key,
            headerName: property.displayName,
            flex: 1.3,
            sortable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params: any) => {
              const rowData = {
                image: {
                  defaultImage: params?.row?.objectProperties[property.key]?.defaultImage,
                  id: params?.row?.id,
                  images: params?.row?.objectProperties[property.key]?.images
                }
              };
              return (params?.row?.objectProperties[property.key]?.images?.length === undefined || params?.row?.objectProperties[property.key]?.images?.length === 0) ? (
                '-'
              ) : (
                <ThumbnailWrapper>
                  <ProductImageThumbnail
                    isEnabled
                    src={IMAGE_URL.replace('{X}', SERVER_URL).replace('{Y}', params?.row?.id).replace('{Z}', params.row.objectProperties[property.key]?.defaultImage)}
                    onClick={() => setImageModalOpen(params?.row?.id)}
                  />
                  {params.row.objectProperties[property.key]?.images?.length > 1 && <ProductImagesCountWrapper><ImagesCount>+ {params.row.objectProperties[property.key]?.images?.length - 1}</ImagesCount></ProductImagesCountWrapper>}
                  <Modal isModalOpen={isImageModalOpen === params?.row?.id} closeModal={() => setImageModalOpen(null)} width='60vw'>
                    <ProductsFullScreenView rowData={rowData} />
                  </Modal>
                </ThumbnailWrapper>
              );
            }
          };
        } else {
          return {
            field: property.key,
            headerName: property.displayName,
            flex: 1.3,
            sortable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params: any) => (
              <div>{params.row.objectProperties[property.key]}</div>
            )
          };
        }
      });
      const actionsColumn = {
        field: 'actions',
        headerName: t('Actions'),
        flex: 1,
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params: any) => {
          return (
            <ActionIconsWrapper>
              { editObjectAccess && <DownloadIconWrapper onClick={() => push(`/objects/${currentObjectType?.id}/edit/${params?.row?.id}`)}>
                <Icon icon='Edit' color='primary' size={28}/>
              </DownloadIconWrapper>}
              { deleteObjectAccess && <DownloadIconWrapper onClick={() => {setSingleObjectDeleteId(params?.row?.id); setDeleteStreamPopUp(true);}}>
                <Icon icon='Delete' color='danger' size={28}/>
              </DownloadIconWrapper>} 
            </ActionIconsWrapper>
          );
        }
      };
      (editObjectAccess || deleteObjectAccess) && columns.push(actionsColumn);
      setTableColumns(columns);
    }
  }, [currentObjectType, push, modalOpen, modalImagePath, isImageModalOpen, t, currentObjectTypeIndex, editObjectAccess, deleteObjectAccess]);

 
  return (
    <AllObjectTypesContainer>
      {
        message && <AlertBar message={message.text} type={message.type as IconType} setMessage={setMessage} />
      }
      <Wrapper>
        { loading ? <LoadingContainer>
          <CircularProgress />
          <Loading>{t('Loading')}</Loading>
        </LoadingContainer> : (allObjectTypes?.length >= 1) ? <TabsContainer>
          <Tabs tabs={tabsConfig} activeMode='box' />
        </TabsContainer> : <NoObjectsPage />
        }
        { deleteStreamPopUp && 
            <ConfirmationModal modalOpen={deleteStreamPopUp} setModalOpen={setDeleteStreamPopUp} onSubmit={()=>handleDeleteObject()} hasConfirmationField={false} 
              titleText={t('Delete Object?')} confirmDescription={t('Are you sure you want to delete this Object?')} noteText={t('Once deleted this action cannot be undone.')} />
        }
        <ConfirmationModal modalOpen={multiDeleteConfirmModal} setModalOpen={setMultiDeleteConfirmModal} onSubmit={handleMultiDeleteObject} titleText={t(`Delete ${deleteObjectIds?.length} Object(s)?`)} hasConfirmationField={true} 
          confirmDescription={t('Are you sure?')} placeholder={t('Enter Object(s) Count')} textToCheck={deleteObjectIds?.length.toString()} noteText={t('Once deleted this action cannot be undone.')} />
      </Wrapper>
    </AllObjectTypesContainer>
  );
};

export default ObjectList;

const downloadLink = (link: string) => {
  if(!link){return;}
  const anchor = document.createElement('a');
  anchor.href = link;
  document.body.appendChild(anchor);
  anchor.click();
  anchor.remove(); 
};