
import { WEBSOCKET_URL } from '../constants';
import { useCallback, useEffect, useState } from 'react';
import { io } from 'socket.io-client';

async function readImage(imageData: string): Promise<string> {
  if (!imageData) {
    return '';
  }
  else {
    return new Promise((resolve,_reject)=>{
      const reader = new FileReader();
      reader.addEventListener('load',()=>{
        if (reader.result === null) resolve('');
        resolve(reader.result as string);
      });
      reader.addEventListener('error',()=>{
        // reject(reader.error);
        //alternatively we could just ignore and NOOP
        console.error('Error reading Image', reader.error);
        resolve('');
      });
      reader.readAsDataURL(new Blob([imageData], { type: 'image/jpeg' }));
    });
  }
}

export const useWebsocketFrame = (path: string, streamName: string) => {

  const [frame, setFrame] = useState('');
  const [loadingFrame, setLoadingFrame] = useState(false);
  const [stopped, setStopped] = useState(true);
  const [keepWait, setKeepWait] = useState(true);

  const startFrame = useCallback((lastFrame: string) => {
    setStopped(false);
    setFrame(lastFrame);
    setKeepWait(true);
  }, []);

  const stopFrame = useCallback(() => {
    setStopped(true);
  }, []);

  useEffect(() => {
    if (keepWait) {
      setTimeout(() => {
        setKeepWait(false);
      }, 1000);
    }
  }, [keepWait]);

  useEffect(()=>{
    if(!path || stopped === true) {
      setLoadingFrame(false);
      return;
    }
    setLoadingFrame(true);
    if (keepWait) {
      return;
    }
    
    const vidSocket = io(WEBSOCKET_URL, {path});

    vidSocket.on('message', async(payload: {image: {data?: string}}) => {
      const {image: {data=''}={}} = payload;
      const imgString = await readImage(data);
      setFrame(imgString);
      data && setLoadingFrame(false);
    });
    
    //error handle
    vidSocket.on('connect_error', (err:Error) => {
      console.log(err); // prints the message associated with the error
    });

    vidSocket.on('update-stream-ids', async(streams: string[]) => {
      const streamID = streams.find(streamID => streamID.split('_')[0] === streamName);
      streamID !== undefined && vidSocket.emit('subscribe-stream', [streamID]);
    });

    vidSocket.emit('update-stream-ids');

    console.debug('start stream', path);
    return ()=>{
      vidSocket.close();
      console.debug('closed stream', path);
    };
  },[path, stopped, streamName, keepWait]);

  return {
    loadingFrame,
    frame,
    action: {
      stopFrame,
      startFrame
    }
  };
};