import Button from 'components/Button';
import Modal from 'components/Modal';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const ConfirmationText = styled.div`
  text-align: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const ConfirmationTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

interface ICancelModalProps{
  modalOpen: boolean,
  setModalOpen: (modalOpen: boolean) => void,
  onCancelYes: ()=>void;
  onCancelNo: ()=>void;
}

const CancelConfirmationModal: React.FC<ICancelModalProps> = ({modalOpen, setModalOpen, onCancelNo, onCancelYes}) => {
  const {t} = useTranslation(['common']);

  return (
    <Modal isModalOpen={modalOpen} closeModal={setModalOpen}>
      <Wrapper>
        <ConfirmationTitle>{t('Unsaved Changes...!')}</ConfirmationTitle>
        <ConfirmationText>{t('Are you sure you want to cancel the changes?')}</ConfirmationText>
        <ButtonsWrapper>
          <Button variant='primary' onClick={onCancelYes}>{t('Yes')}</Button>
          <Button variant='secondary' type='submit' onClick={onCancelNo}>{t('No')}</Button>
        </ButtonsWrapper>
      </Wrapper>
    </Modal>
  );
};

export default CancelConfirmationModal;