import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import jp from './locales/jp.json';
import hi from './locales/hi.json';
import te from './locales/te.json';

i18n.use(LanguageDetector).use(initReactI18next).init({
  resources: {
    en: {
      common: en
    },
    jp: {
      common: jp
    },
    hi: {
      common: hi
    },
    te: {
      common: te
    }
  },
  fallbackLng: 'en',
  debug: false,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ','
  },
  react: {
    wait: true,
    useSuspense: false
  }
});

export default i18n;

