import Input from 'components/Input';
import Label from 'components/Label';
import Switch from 'components/Switch';
import Title from 'components/Title';
import React, { useState } from 'react';
import styled from 'styled-components';
import {H264PROFILE, StreamType, VIDEO_MAX_FILE_SIZE, VIDEO_QUALITY_OPTIONS} from '../../../constants';
import SelectField from 'components/SelectField';
import { IRecordingFields } from 'interface';
import { useTranslation } from 'react-i18next';
import { allowOnlyNumbers } from 'utils/utils';
import Toggle from 'components/Toggle';

interface IMP4Recording {
  streamType: string | undefined,
  recordingFields: IRecordingFields,
  setRecordingFields: React.Dispatch<React.SetStateAction<IRecordingFields>>
}

const MP4RecordingWrapper = styled.div`
  border: 1px solid #d3d3d352;
  border-radius: 5px;
  width: 472px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 8px 0px 8px;
`;

const SaveOptions = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 15px;
`;

const VerticalLine = styled.div`
  width: 472px;
  height: 10px;
  border-bottom: 1px solid #d3d3d352;
  margin-bottom: 16px;
  margin-top: -6px;
`;

const ToggleWrapper = styled.div`
  display: flex;
  gap: 10px;
  height: 28px;
`;

const ContentWrapper = styled.div`
  padding: 0 10px;
`;

const SaveRecordingOption = styled.div`
  display: flex;
  gap: 20px;
`;

const SaveTimeOverlayOption = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 6px;
  margin-bottom: 6px;
`;

const ValueWrapper = styled.div`
  display: flex;
  gap: 20px;
  > label {
    margin-top: 5px;
  }
  > div {
    width: 134px;
  }
  > div > input {
    height: 34px;
  }
`;

const SplitByWrapper = styled.div`
  display: flex;
  gap: 18px;
  margin-top: 5px;
  margin-bottom: 5px;
  > label {
    margin-top: 4px;
  }
`;

const MP4Recording = ({streamType, recordingFields, setRecordingFields}: IMP4Recording) => {
  const {t} = useTranslation(['common']);
  const [selectedTab, setSelectedTab] = useState(0);

  const tabButtons = [
    { label: 'Time', selected: selectedTab === 0 },
    { label: 'Size', selected: selectedTab === 1 },
  ];

  const handleInputFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = event.target.id;
    const value: string = event.target.value;
    setRecordingFields(prevState => ({
      ...prevState,
      services: {
        ...prevState?.services,
        recorder: {
          ...prevState?.services?.recorder,
          [id]: value
        }
      }
    }));   
  };

  const handleSelectFieldChange = (value: string, id: string) => {
    if(id === 'videoSplitMode')
      setRecordingFields(prevState => ({
        ...prevState,
        services: {
          ...prevState?.services,
          recorder: {
            ...prevState?.services?.recorder,
            videoSplitMode: value,
            videoFileMaxSize: value === 'time' ? VIDEO_MAX_FILE_SIZE: '50'
          }
        }
      }));
    else if(id === 'videoDuration') {
      if(recordingFields.services.recorder.videoSplitMode === 'time') 
        setRecordingFields(prevState => ({
          ...prevState,
          services: {
            ...prevState?.services,
            recorder: {
              ...prevState?.services?.recorder,
              videoDuration: value
            }
          }
        }));
      else 
        setRecordingFields(prevState => ({
          ...prevState,
          services: {
            ...prevState?.services,
            recorder: {
              ...prevState?.services?.recorder,
              videoFileMaxSize: value
            }
          }
        }));
    }
    else 
      setRecordingFields(prevState => ({
        ...prevState,
        services: {
          ...prevState?.services,
          recorder: {
            ...prevState?.services?.recorder,
            [id]: value
          }
        }
      })); 
  };

  const handleSwitchChange = (state: boolean, id: string) => {
    setRecordingFields(prevState => ({
      ...prevState,
      services: {
        ...prevState?.services,
        recorder: {
          ...prevState?.services?.recorder,
          [id]: !state
        }
      }
    }));
  };
  
  return (
    <MP4RecordingWrapper>
      <TitleWrapper>
        <Title size='18px' text={t('MP4 Recording')} />
        <SaveRecordingOption>
          <Switch checked={recordingFields?.services?.recorder?.enableSaveMp4} onChange={() => handleSwitchChange(recordingFields?.services?.recorder?.enableSaveMp4, 'enableSaveMp4')} />
        </SaveRecordingOption>
      </TitleWrapper>
      <VerticalLine />
      <ContentWrapper>
        <SaveOptions>  
          {
            streamType === StreamType.USB && <SaveTimeOverlayOption>
              <Switch checked={recordingFields?.services?.recorder?.enableMp4TimeOverlay !== undefined ? recordingFields?.services?.recorder?.enableMp4TimeOverlay: false} disabled={recordingFields?.services?.recorder?.enableSaveMp4} onChange={() => handleSwitchChange(recordingFields?.services?.recorder?.enableMp4TimeOverlay, 'enableMp4TimeOverlay')} />
              <Label labelText={t('Time Overlay')} />
            </SaveTimeOverlayOption>
          }
        </SaveOptions>
        <SplitByWrapper>
          <Label labelText={t('Split By')} />
          <ToggleWrapper>
            <Toggle enabled={recordingFields?.services?.recorder?.enableSaveMp4} selected={selectedTab} tabButtons={tabButtons} onTabClick={(value) => {if(value === 0) {setSelectedTab(0); handleSelectFieldChange('time', 'videoSplitMode');} else {setSelectedTab(1); handleSelectFieldChange('size', 'videoSplitMode');}}} />
          </ToggleWrapper>
          <ValueWrapper>
            <Label labelText={recordingFields?.services?.recorder?.videoSplitMode === 'time' ? t('Interval (Sec)') : t('Interval (MiB)')} />
            <Input type='text' disabled={!recordingFields?.services?.recorder?.enableSaveMp4} label='' id='videoDuration' maxLength={4} onKeyPress={allowOnlyNumbers} width='120px' value={recordingFields?.services?.recorder?.videoSplitMode === 'time' ? recordingFields?.services?.recorder?.videoDuration : recordingFields?.services?.recorder?.videoFileMaxSize} onChange={handleInputFieldChange} />
          </ValueWrapper>
        </SplitByWrapper>
        {
          streamType === StreamType.USB && <SelectField label={t('Video Quality')} defaultValue={recordingFields.services.recorder.recordingQuality} disabled={recordingFields?.services?.recorder?.enableSaveMp4} options={VIDEO_QUALITY_OPTIONS} onChange={(value) => handleSelectFieldChange(value, 'recordingQuality')} />
        }
        {
          (streamType === StreamType.USB || streamType === StreamType.RTMP) && <SelectField label={t('H264 Profile')} options={H264PROFILE} disabled={recordingFields?.services?.recorder?.enableSaveMp4} defaultValue={recordingFields.services.recorder.h264Profile} onChange={(value) => handleSelectFieldChange(value, 'h264Profile')} />
        }
      </ContentWrapper>
    </MP4RecordingWrapper>
  );
};

export default MP4Recording;