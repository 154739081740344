import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgNotificationAlert = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M11.053 5.002c-5.125 0-5.857 4.434-5.857 8.13C5.196 16.825 3 19.043 3 19.043h16.105s-2.196-2.218-2.196-5.913m-2.928 5.913S13.98 22 11.053 22c-2.929 0-2.929-2.956-2.929-2.956"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={"non-scaling-stroke"}
      />
      <circle
        cx={17.5}
        cy={6.5}
        r={5}
        stroke={props.color}
        vectorEffect={"non-scaling-stroke"}
      />
      <path
        d="M17 4.475a.475.475 0 0 1 .95 0v3.588a.475.475 0 0 1-.95 0V4.474Z"
        fill={props.color}
      />
      <rect x={17} y={9} width={1} height={1} rx={0.5} fill={props.color} />
    </svg>
  );
};
export default SvgNotificationAlert;
