import { GET_EVENT_PIPELINE } from 'api_configs/queries';
import { NODE_ID } from '../constants';
import { IPipelineActions, IPipelineInfo } from 'interface';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

interface INewNodeProps {
  value?: string;
  startId: string;
  endId: string;
  topId?: string;
  bottomId?: string;
}

const initialPipelineInfo: IPipelineInfo = {
  name: '',
  algoCode: '',
  event: [],
  filters: {},
  actions: [],
  scheduleId: null,
  enabled: true
};

const usePipelineInfo = (id: number) => {
  const [pipelineInfo, setPipelineInfo] = useState<IPipelineInfo>(initialPipelineInfo);
  const [pipelineInfoBeforeEdit, setPipelineInfoBeforeEdit] = useState<IPipelineInfo>(initialPipelineInfo);
  const {data, loading: epLoading, refetch: refetchPipelineInfo } = useQuery(GET_EVENT_PIPELINE, { variables: {epId: id} });
  const [scheduleNodes, setScheduleNodes] = useState<INewNodeProps[]>([]);
  const [actionNodes, setActionNodes] = useState<INewNodeProps[]>([]);
  const [filterNodes, setFilterNodes] = useState<INewNodeProps[]>([]);
  const [actions, setActions] = useState<IPipelineActions[]>([]);
  const [loading, setIsLoading] = useState<boolean>(false);

  useEffect(()=>{
    setIsLoading(true);
    if(data && data?.getEventPipeline?.result){
      const pipelineInfo:IPipelineInfo = {...data?.getEventPipeline?.result};
      if(pipelineInfo){
        const filterNodesData: INewNodeProps[] = pipelineInfo?.filters?.cameras?.includes('ALL') ? [] : Object.entries(pipelineInfo?.filters ?? {}).map(([key, _value]) => {
          return {value: key, startId: NODE_ID.FILTER_START, endId: NODE_ID.FILTER_END, topId: NODE_ID.FILTER_TOP, bottomId: NODE_ID.FILTER_BOTTOM};
        });
        const scheduleNodesData : INewNodeProps[] = pipelineInfo?.scheduleId !== null ? [{startId: NODE_ID.SCHEDULE_START, endId: NODE_ID.SCHEDULE_END}] : [];
        const actionNodesData:INewNodeProps[] = pipelineInfo?.actions?.map((action) => {
          return {startId: NODE_ID.ACTION_START, endId: NODE_ID.ACTION_END, value: action.type};
        });
        setScheduleNodes(scheduleNodesData);
        setFilterNodes(filterNodesData);
        setActionNodes(actionNodesData);
        setActions([...pipelineInfo.actions]);
        setTimeout(()=>{
          setPipelineInfo(prev => {
            const temp = {...prev,...pipelineInfo};
            if('cameras' in pipelineInfo.filters && pipelineInfo.filters.cameras.includes('ALL')){
              temp.filters = {};
            }
            return {...temp};
          });
          setPipelineInfoBeforeEdit(prev => {
            const temp = {...prev,...pipelineInfo};
            if('cameras' in pipelineInfo.filters && pipelineInfo.filters.cameras.includes('ALL')){
              temp.filters = {};
            }
            return {...temp};
          });
        },10);
      }
    }
    setIsLoading(false);
  },[data]);

  useEffect(()=>{
    setPipelineInfo((prev)=>{
      const temp = {...prev};
      temp.actions = [...actions];
      return temp;
    });
    setPipelineInfoBeforeEdit((prev)=>{
      const temp = {...prev};
      temp.actions = [...actions];
      return temp;
    });
  },[actions]);

  return {
    pipelineInfo,
    scheduleNodes,
    actionNodes,
    filterNodes,
    loading,
    epLoading,
    setPipelineInfo,
    pipelineInfoBeforeEdit,
    refetchPipelineInfo
  };
};

export default usePipelineInfo;
