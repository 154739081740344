import { useAlertList } from 'contexts/AlertListSnapshotContext';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import AwaitingStreamEng from '../svgs/Awaiting_Stream-ENG.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CircularProgress } from '@mui/material';
import { ScrollBarCss } from 'CommonStyles';
import { ALERT_IMG_BASE_URL } from '../constants';
import { IAlertData } from 'interface';
import AlertThumbnailModal from './modals/AlertThumbnailModal';

const SnapshotContainer = styled.div`
  border: 1px solid ${({theme}) => theme.divider};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  >div>div{
    display: grid;
    padding: 10px;
    grid-template-columns: repeat(4, 1fr); 
    row-gap: 10px;
    column-gap: 10px;
    ${ScrollBarCss}
  }
`;

const NotFoundDiv = styled.div`
  height: 100%;
  aspect-ratio: 16 / 7;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
`;

const LoadingWrapper = styled.div``;

const AlertImageContainer = styled.div`
  :hover{
    transform: scale(1.1);
  }
  transition: all 0.3s ease;
`;

const AlertImage = styled.img`
  width: 100%;
  aspect-ratio: 16 / 9;
`;

const SnapshotsViewer = () => {
  const {alertData, fetchMoreData, hasMore} = useAlertList();
  const [modalAlertData, setModalAlertData] = useState<IAlertData>();
  const [modalOpen, setModalOpen] = useState(false);
  const {t} = useTranslation(['common']);

  const handleThumbnailError = useCallback((e:React.SyntheticEvent<HTMLElement>)=>{
    (e.target as HTMLSourceElement).src = AwaitingStreamEng;
  },[]);

  return (
    <SnapshotContainer>
      {
        alertData.length > 0 ?
          <InfiniteScroll
            dataLength={alertData?.length || 0}
            next={fetchMoreData}
            hasMore={hasMore}
            height={410} 
            loader={<LoadingWrapper><CircularProgress size={30} /></LoadingWrapper>}
          >
            {
              alertData.map(alert => (
                <AlertImageContainer>
                  <AlertImage src={ALERT_IMG_BASE_URL + alert.imagePath} onClick={() => {setModalAlertData({...alert}); setModalOpen(true);}} onError={handleThumbnailError} />
                </AlertImageContainer>
              ))
            }
          </InfiniteScroll>
          :
          <NotFoundDiv>{t('Alerts not found')}</NotFoundDiv>
      }
      {modalOpen && <AlertThumbnailModal setModalOpen={setModalOpen} modalOpen={modalOpen} alertData={modalAlertData ?? {}} /> }
    </SnapshotContainer>
  );
};

export default SnapshotsViewer;