import React, {  useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import { IPointSet, LineSetContext, LineUI } from './LineUI';
import { LineUIType, SNAPSHOT_URL } from '../constants';
import axios from 'axios';
import { cacheImage, getAlgoIcon, getCachedImg, getFillAreaColor, isValidImageURL } from '../utils/utils';
import AwaitingStreamEng from '../svgs/noImage.svg';
import Icon from './Icon';
import { useTranslation } from 'react-i18next';
import { useWebsocketFrame } from '../hooks/useWebsocketFrame';
import { CircularProgress } from '@mui/material';
import { IReducerActions } from './LineUI/LineReducer';
import { ILowSpeedDetectionConfigs, IAnomalyDetectionConfigs, ITrafficPulseDetectionConfigs, INewAnalysisConfigsRes, IPoints, IRouteDeviationDetectionConfigs, IRedLightViolationConfigs } from 'interface';
import { GET_ANALYSIS_CONFIGS } from 'api_configs/queries';
import { useQuery } from '@apollo/client';
import Modal from './Modal';
import { useAnnotation } from 'contexts/AnnotaionContext';

const SpinnerContainer = styled.div<{width?: string, height?: string, minHeight?: string, backgroundColor?: string }>`
  width: ${({width}) => width ? width : '100%'}; 
  border-radius: 3px;
  background-color: ${({backgroundColor})=>backgroundColor? backgroundColor: '#E8E8E8'};
  display: flex; 
  justify-content: center;
  align-items: center;
  aspect-ratio: 16/9;
`;

const LineViwerContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const StreamToggleWrapper = styled.div<{disabled: boolean}>`
  display: flex;
  align-items: center;
  border-radius: 20px;
  cursor: ${({disabled}) => disabled ? 'no-drop !important' : 'pointer'};
  background-color: #D9D9D9;
`; 

const StreamToggle = styled.div<{isSelected:boolean, disabled: boolean}>`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 2px 10px;
  pointer-events: ${({disabled}) => disabled ? 'none' : 'auto'};
  opacity: ${({disabled})=>disabled ? '0.6': ''};
  background-color: ${({isSelected})=>isSelected ? '#7287D7' : '#D9D9D9'};
  border-radius: 20px;
  border: ${({isSelected})=>isSelected ? '1px solid #6073BF' : ''};
  >:nth-child(2){
    color: ${({theme,isSelected})=>isSelected ? '#FFFFFF' : theme.text.secondaryText};
  }
  >div>svg>path{
    fill: ${({theme,isSelected})=>isSelected ? '#FFFFFF' : theme.text.secondaryText};;
  }
  transition: all 0.3s ease;
`;

const ToggleText = styled.span`
  color: white;
  font-size: 12px;
`;

const LineUIWrapper = styled.div<{width?: string, height?: string}>`
  width: ${({width}) => width};
  height: ${({height}) => height};
  border-radius: 5px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;

const StreamHeader = styled.div`
  position: absolute;
  top: 0;
  height: 50px;
  background: #c5c7e68e;
  width: 100%;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  opacity: 1;
  z-index: 9;
`;

const Title = styled.div`
  font-size: 18px;
  padding: 5px 10px;
  border-radius: 10px;
  width: max-content;
  height: max-content;
  background-color: #FFF;
`;

const ImageNone = styled.img`
  height: 100%;
  min-width: 100%;
  object-fit: cover;
  border-radius: 5px;
  aspect-ratio: 16/9;
`;

const LineToolToggles = styled.div`
  z-index: 10;
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  gap: 10px;
`;

const AlogorithmIcon = styled.div<{isSelected: boolean}>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({isSelected})=>isSelected ? '#FFFFFF': '#ffffff82'};
  cursor: pointer;
`;

const ActionWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FullScreenToggle = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6px;
`;

const IconWrapper = styled.div<{disabled: boolean}>`
  position: absolute;
  bottom: 0px;
  right: 0;
  cursor: ${({disabled}) => disabled ? 'no-drop !important' : 'pointer'};
`;

const ActionIcon = styled.div<{disabled: boolean}>`
  width: 24px;
  height: 24px;
  z-index: 9;
  border-radius: 5px;
  background-color: #DFDFDF;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: ${({disabled}) => disabled ? 'none' : 'auto'};
  opacity: ${({disabled})=>disabled ? '0.6': ''};
`;

interface ILineViewer {
  isReadOnly?: boolean;
  setSelectedLineIndex?: (id: number)=>void;
  streamID: string;
  width?: string;
  height?: string;
  lineData?: IPointSet[];
  state: IPointSet[];
  dispatch?: React.Dispatch<IReducerActions>;
  hasStreamToggleOptions?: boolean;
  liveVideo?: boolean;
  showAlgorithmIcons?: boolean;
  onLineClickCallback?: (id: number)=>void;
  showDirectionMark?: boolean;
  minHeight?: string;
  isCard?: boolean;
  isTracking?: boolean;
  hasFullScreenToggle?: boolean;
  streamName?: string;
}

const LineViewer: React.FC<ILineViewer> = ({showAlgorithmIcons=false, streamName='', isCard=false, streamID, minHeight='100%',  showDirectionMark=false, hasStreamToggleOptions=true, width = '540px', onLineClickCallback=()=>{}, height = '310px', liveVideo=false, state, dispatch = ()=>{}, hasFullScreenToggle=false}) => {
  const [imgSrc,setImgSrc] = useState<string>('');
  const [imageDetails, setImageDetails] = useState({x: 1920, y: 1080});
  const [isLiveFeed, setIsLiveFeed] = useState<boolean>(liveVideo);
  const [hasLiveVideoStarted, setHasLiveVideoStarted] = useState<boolean>(false);
  const {t} = useTranslation(['common']);
  const {annotaionEnabled} = useAnnotation();
  const pathUrl = annotaionEnabled ? '/websocket-sink-tracking/socket.io' : '/websocket-sink/socket.io';
  const [isImageLoading,setIsImageLoading] = useState<boolean>(true && (getCachedImg(streamID) === undefined));
  const { loadingFrame, frame, action: { stopFrame, startFrame } } = useWebsocketFrame(pathUrl, streamID);
  const [linesVisisble, setLinesVisible] = useState<boolean>(false);
  const [lineData, setLineData] = useState<IPointSet[][]>([]);
  const [configuredAlgos, setConfiguredAlgos] = useState<INewAnalysisConfigsRes[]>([]);
  const { data, refetch } = useQuery(GET_ANALYSIS_CONFIGS,{ variables: {streamId: streamID} });
  const [selectedIndices, setSelectedIndices] = useState<number[]>([]);
  const [fullScreenView, setFullScreenView] = useState<boolean>(false);
      
  const updateSnapshot = useCallback(async ()=>{
    const snapshotImg: HTMLElement | null = document.getElementById(streamID + 'snapshot');
    try{
      const res = await axios.get(SNAPSHOT_URL.replace('{X}',streamID).replace('{Y}',new Date().getTime().toString()), { responseType: 'arraybuffer' });
      if (res && res.status === 200 && res.data) {
        if('is_latest' in res.headers && res.headers.is_latest === 'false' && snapshotImg !== null){
          snapshotImg.classList.add('no-img');
        }
        if('is_latest' in res.headers && res.headers.is_latest === 'true' && snapshotImg !== null){
          snapshotImg.classList.remove('no-img');
        }
        const binaryData = new Uint8Array(res.data);
        const byteArray = Array.from(binaryData);
        const base64String = btoa(byteArray.map(byte => String.fromCharCode(byte)).join(''));
        const imgBase64 = 'data:image/jpg;base64,' + base64String;
        const isImageValid = await isValidImageURL(imgBase64);
        if (isImageValid === true) {
          const img = new Image();
          img.src = imgBase64;
          setImgSrc(imgBase64);
          setImageDetails({
            x: img.naturalWidth,
            y: img.naturalHeight
          });
          cacheImage(streamID, imgBase64);
          setIsImageLoading(false);
        }else{
          const cachedImage = getCachedImg(streamID);
          if (cachedImage) {
            setImgSrc(cachedImage);
          } else {
            setImgSrc('');
          }
        }
      }else{
        const cachedImage = getCachedImg(streamID);
        if (cachedImage) {
          setImgSrc(cachedImage);
        } else {
          setImgSrc('');
        }
      }
    }catch{
      const cachedImage = getCachedImg(streamID);
      if (cachedImage) {
        setImgSrc(cachedImage);
      } else {
        setImgSrc('');
      }
    }finally {
      setIsImageLoading(false);
    }
  },[streamID]);

  useEffect(() =>{
    const cachedImg = getCachedImg(streamID);
    if(cachedImg){
      setImgSrc(cachedImg);
    }
  },[streamID]);

  useEffect(()=>{
    localStorage.setItem('state', JSON.stringify(state));
  },[state]);

  useEffect(()=>{
    updateSnapshot();
    const intervalId = setInterval(() => {
      updateSnapshot();
    }, 5000);
    return () => clearInterval(intervalId);
  }, [updateSnapshot, streamID]);

  const generateConfigPointSet = (config: INewAnalysisConfigsRes)=>{
    const configs = config?.configs as (ILowSpeedDetectionConfigs | ITrafficPulseDetectionConfigs | IAnomalyDetectionConfigs | IRouteDeviationDetectionConfigs | IRedLightViolationConfigs)[];
    const lineDetails:IPointSet[] = [];
    configs?.map((config) => {
      if(config?.lineType === LineUIType.LINE && 'points' in config){
        const data= {
          name: config.name,
          points: config?.points as IPoints[],
          styling: config.styling,
          showMoveHandle: false,
          showPointHandle: false,
          showSmallDirectionMark: true
        };
        lineDetails.push(data);
      }else if(config?.lineType === LineUIType.AREA && 'points' in config){
        const data = {
          areaName: config.name,
          areaFillColor: getFillAreaColor(config.styling),
          areaTransparencyLevel: 40,
          points: config?.points as IPoints[],
          styling: config.styling,
          showMoveHandle: false,
          showPointHandle: false,
          showSmallDirectionMark: true
        };
        lineDetails.push(data);
      }else if(config?.lineType === LineUIType.LINE_SET && 'line_set' in config){
        const line1 = {
          name: config.name,
          areaTransparencyLevel: 40,
          points: config.line_set?.line1 as IPoints[],
          styling: config.styling,
          showMoveHandle: false,
          showPointHandle: false,
          showSmallDirectionMark: true
        };
        const line2 = {
          name: config.name,
          areaTransparencyLevel: 40,
          points: config.line_set?.line2 as IPoints[],
          styling: config.styling,
          showMoveHandle: false,
          showPointHandle: false,
          showSmallDirectionMark: true
        };
        lineDetails.push(line1);
        lineDetails.push(line2);
      }
      else if (config?.lineType === LineUIType.LINE_SET_ARRAY && 'line_set_array' in config) {
        if (config?.line_set_array) {
          for (let i = 0; i < config?.line_set_array.length; i++) {
            const line = {
              name: config.name,
              areaTransparencyLevel: 40,
              points: config?.line_set_array[i]?.points as IPoints[] || [],
              styling: config.styling,
              showMoveHandle: false,
              showPointHandle: false,
              showSmallDirectionMark: false,
            };
            lineDetails.push(line);
          }
        }
      }else if(config?.lineType === LineUIType.LINE_AREA_SET ){
        const line = {
          name: config.name,
          points: (config as IRedLightViolationConfigs).cross_line as IPoints[],
          styling: config.styling,
          showMoveHandle: false,
          showPointHandle: false,
          showSmallDirectionMark: true,
          areaTransparencyLevel: 40,
        };
        const area = {
          areaName: config.name,
          points: (config as IRedLightViolationConfigs).area as IPoints[],
          styling: config.styling,
          showMoveHandle: false,
          showPointHandle: false,
          showSmallDirectionMark: true,
          areaTransparencyLevel: 40,

        };
        lineDetails.push(line);
        lineDetails.push(area);
      }
      return null;
    });
    return lineDetails;
  };

  useEffect(()=>{
    refetch({streamId: streamID});
    const configDetails: INewAnalysisConfigsRes[]  = data?.getAnalysisConfig?.result !== null ? data?.getAnalysisConfig?.result : [];
    let pointData: IPointSet[][] = [];
    let configuredAlgorithms: INewAnalysisConfigsRes[] = [];
    if(configDetails){
      configuredAlgorithms = configDetails?.filter(config => config?.configs?.length > 0 && config?.enabled );
      pointData = configuredAlgorithms?.map(config => generateConfigPointSet(config)) as IPointSet[][];
    }
    setConfiguredAlgos(configuredAlgorithms);
    setLineData(pointData);
  },[data, streamID, refetch]);
 

  const onLatestSnapsot = useCallback(() => {
    setIsLiveFeed(false);
    stopFrame();
    updateSnapshot();
  },  [updateSnapshot,stopFrame]);

  const onLiveFeed = useCallback(() => {
    startFrame(imgSrc);
  }, [imgSrc, startFrame]);

  useEffect(()=>{
    if(liveVideo && !hasLiveVideoStarted){
      setIsLiveFeed(true);
      onLiveFeed();
      setHasLiveVideoStarted(true);
    }else if(!liveVideo && hasLiveVideoStarted){
      setHasLiveVideoStarted(false);
      setIsLiveFeed(false);
    }
  },[liveVideo, onLiveFeed, hasLiveVideoStarted]);

  const getSelectedIndicesLineData = (indicesArray: number[]) => {
    const lineSetData: IPointSet[]= [];
    indicesArray?.forEach(index => {
      lineData[index]?.forEach(item => lineSetData.push(item));
    });
    return lineSetData;
  };

  const handleAlgorithmClick = useCallback((event: React.MouseEvent,index: number)=>{
    event.stopPropagation();
    setSelectedIndices(prev => {
      let prevIndicesArray = [...prev];
      if(prevIndicesArray.includes(index)){
        prevIndicesArray = prevIndicesArray.filter((item) => item !== index);
      }else{
        prevIndicesArray.push(index);
      }
      setLinesVisible( prevIndicesArray.length > 0);
      return prevIndicesArray;
    });
  },[]);

  const modifyPointPosition = useCallback((point: {x: number, y: number}) => {
    const newPoint = {...point};
    //check point's horizontal position.
    if (point.x < 0){
      newPoint.x = 0;
    } else if (point.x > imageDetails.x) {
      newPoint.x = imageDetails.x;
    }
    //check point's vertical position.
    if (point.y < 0){
      newPoint.y = 0;
    } else if (point.y > imageDetails.y) {
      newPoint.y = imageDetails.y;
    }
    return newPoint;
  },[imageDetails]);

  const getLinePoints = useCallback((state: IPointSet[]) => {
    const newState: IPointSet[] = state.map(pointSet => {
      const modifiedPoints = pointSet.points.map(point => modifyPointPosition(point));
      return {...pointSet, points: modifiedPoints};
    });
    return newState;
  },[modifyPointPosition]);

  return (
    <LineViwerContainer>
      <LineUIWrapper {...{width,height}}>
        <LineSetContext.Provider value={{ state: showAlgorithmIcons ? (linesVisisble && selectedIndices.length > 0) ? getLinePoints(getSelectedIndicesLineData(selectedIndices)) : [] : getLinePoints(state) , dispatch }}>
          { isImageLoading ? 
            <SpinnerContainer {...{width,height, minHeight}}>
              <CircularProgress />
            </SpinnerContainer> :
            imgSrc === '' ? 
              <SpinnerContainer {...{width,height, backgroundColor: '#ddd' }}>
                <ImageNone src={AwaitingStreamEng} />
              </SpinnerContainer>:
              <>
                {showAlgorithmIcons && lineData.length > 0 && 
                <LineToolToggles>
                  {configuredAlgos.map((algo,index)=>(
                    <AlogorithmIcon title={t(algo.algorithmName ?? '')} isSelected={selectedIndices.includes(index)} key={index} onClick={(e)=>handleAlgorithmClick(e,index)}>
                      {getAlgoIcon(algo.algorithmCode, false, 20, true)}
                    </AlogorithmIcon>
                  ))}
                </LineToolToggles>}
                { !fullScreenView ? 
                  <LineUI
                    id={streamID + 'snapshot'}
                    options={{
                      showSetIndex: false,
                      showPoint: true,
                      showDirectionMark: showDirectionMark,
                      showMoveHandle: false,
                      showPointHandle: true,
                      setIndexOffset: 0,
                      boundaryOffset: Math.round(imageDetails.x * 0.5 / 100),
                      fixedImgDimensions: imageDetails}}
                    src={isLiveFeed && frame !== '' ? frame : imgSrc}
                    onLineClick={onLineClickCallback}
                    hasClickSensingBorder={false}
                    isCard={isCard}
                  />:
                  <Modal isModalOpen={fullScreenView} closeModal={() => setFullScreenView(false)} width='60vw'>
                    <LineUIWrapper>
                      {streamName && 
                      <StreamHeader>
                        <Title>{streamName}</Title>
                      </StreamHeader>}
                      {showAlgorithmIcons && lineData.length > 0 && 
                        <LineToolToggles>
                          {configuredAlgos.map((algo,index)=>(
                            <AlogorithmIcon title={algo.algorithmName} isSelected={selectedIndices.includes(index)} key={index} onClick={(e)=>handleAlgorithmClick(e,index)}>
                              {getAlgoIcon(algo.algorithmCode, false, 20, true)}
                            </AlogorithmIcon>
                          ))}
                        </LineToolToggles>}
                      <LineUI
                        options={{
                          showSetIndex: false,
                          showPoint: true,
                          showDirectionMark: showDirectionMark,
                          showMoveHandle: false,
                          showPointHandle: true,
                          setIndexOffset: 0,
                          boundaryOffset: Math.round(imageDetails.x * 0.5 / 100),
                          fixedImgDimensions: imageDetails}}
                        src={isLiveFeed && frame !== '' ? frame : imgSrc}
                        onLineClick={onLineClickCallback}
                        hasClickSensingBorder={false}
                        isCard={isCard}
                      />
                      <FullScreenToggle>
                        <StreamToggleWrapper disabled={!(imgSrc && !isImageLoading)}>
                          <StreamToggle disabled={!(imgSrc && !isImageLoading)} isSelected={!isLiveFeed} onClick={onLatestSnapsot}>
                            <Icon icon='Image' size={18} />
                            <ToggleText>{t('Recent Snapshot')}</ToggleText>
                          </StreamToggle>
                          <StreamToggle disabled={!(imgSrc && !isImageLoading)} isSelected={isLiveFeed} onClick={()=>{setIsLiveFeed(true);setHasLiveVideoStarted(false);onLiveFeed();localStorage.setItem('isLive', 'true');}}>
                            {
                              loadingFrame ? <CircularProgress size={10} />:
                                <Icon icon='Video' size={18} />
                            }
                            <ToggleText>{t('Live Stream')}</ToggleText>
                          </StreamToggle>
                        </StreamToggleWrapper>
                      </FullScreenToggle>
                    </LineUIWrapper>
                  </Modal>
                }
              </>
          }
        </LineSetContext.Provider>
        {
          hasStreamToggleOptions &&
          <ActionWrapper>
            <StreamToggleWrapper disabled={!(imgSrc && !isImageLoading)}>
              <StreamToggle disabled={!(imgSrc && !isImageLoading)} isSelected={!isLiveFeed} onClick={onLatestSnapsot}>
                <Icon icon='Image' size={18} />
                <ToggleText>{t('Recent Snapshot')}</ToggleText>
              </StreamToggle>
              <StreamToggle disabled={!(imgSrc && !isImageLoading)} isSelected={isLiveFeed} onClick={()=>{setIsLiveFeed(true);setHasLiveVideoStarted(false);onLiveFeed();localStorage.setItem('isLive', 'true');}}>
                {
                  loadingFrame ? <CircularProgress size={10} />:
                    <Icon icon='Video' size={18} />
                }
                <ToggleText>{t('Live Stream')}</ToggleText>
              </StreamToggle>
            </StreamToggleWrapper>
            { hasFullScreenToggle &&
            <IconWrapper disabled={!(imgSrc && !isImageLoading)}>
              <ActionIcon disabled={!(imgSrc && !isImageLoading)} onClick={()=>setFullScreenView(true)}>
                <Icon icon='Expand' color='primary' size={16} />
              </ActionIcon>
            </IconWrapper>
            }
          </ActionWrapper> 
        }
      </LineUIWrapper>
    </LineViwerContainer>
  );
};

export default LineViewer;