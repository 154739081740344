import React from 'react';
import styled from 'styled-components';

interface ProgressBarProps {
  progress: number;
}

const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: #e0e0df;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 10px;
`;

const Filler = styled.div<{ progress: number }>`
  height: 10px;
  width: ${({ progress }) => progress}%;
  background-color: #4a00e0;
  transition: width 0.2s ease-in;
`;

const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
  return (
    <ProgressBarContainer>
      <Filler progress={progress} />
    </ProgressBarContainer>
  );
};

export default ProgressBar;