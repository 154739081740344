import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getAudioAlertName } from 'utils';
import { useHeader } from 'contexts/HeaderContext';
import { IAlertMessageType } from 'interface';
import AlertBar from 'components/AlertBar';
import { useMutation, useQuery } from '@apollo/client';
import { ALERT_SETTING_IN_APP_SOUND, IconType, PERMISSION_CODE, StatusCode } from '../../constants';
import lodash from 'lodash';
import { GET_ALERT_AUDIO } from 'api_configs/queries';
import Switch from 'components/Switch';
import Label from 'components/Label';
import SelectField from 'components/SelectField';
import { FaPlay } from 'react-icons/fa';
import { CircularProgress, Slider } from '@mui/material';
import { SAVE_ALERT_AUDIO_SETTING } from 'api_configs/mutations';
import { HiMiniSpeakerWave, HiPause } from 'react-icons/hi2';
import TokenService from 'api_configs/tokenService';
import ButtonWithLoading from 'components/ButtonWithLoading';


const Container = styled.div`
  margin: 20px;
  padding: 10px;
  width: 550px;
  border: 1px solid ${({theme}) => theme.divider};
  border-radius: 6px;
  min-height: 330px;
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding: 10px;
`;

const StyledSlider = styled(Slider)<{max: number}>`
  & .MuiSlider-markLabel[data-index="0"] {
    transform: none;
  }
  & .MuiSlider-markLabel[data-index="9"] {
    transform: translateX(-100%);
  }
`;

const SelectSoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  >label{
    margin-bottom: 0;
  }
`;

const SelectFieldWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

const PlayButton = styled.div<{disabled?: boolean}>`
  padding: 0 10px;
  height: 40px;
  border: 1px solid ${({theme}) => theme.divider};
  border-radius: 6px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  cursor: ${({disabled}) => disabled ? 'no-drop': 'pointer'};
  background-color: ${({disabled,theme}) => disabled ? theme.divider: '#FFF'};
`;

const Span = styled.span<{isBold?: boolean}>`
  margin-bottom: 2px;
  font-weight: ${({isBold}) => isBold ? 600 : 400};
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

const AlertVolumeHeader = styled.div`
  display: flex;
  gap: 5px;
  >label{
    margin-bottom: 0;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 300px;
  gap: 10px;
`;

export const ALERT_AUDIO_OPTIONS = [
  { value: 'Fairy-Sparkle', name: 'Fairy-Sparkle' },
  { value: 'Wrong-Answer', name: 'Wrong-Answer' },
  { value: 'Surprise-Sound', name: 'Surprise-Sound' },
  { value: 'Interface', name: 'Interface' },
  { value: 'Game-Bonus', name: 'Game-Bonus' },
  { value: 'Ding-Idea', name: 'Ding-Idea' },
  { value: 'Click-Button', name: 'Click-Button' },
  { value: 'Cash-Register', name: 'Cash-Register' },
  { value: 'Appears', name: 'Appears' }
];

interface IGlobalAlertSoundSetting {
  volume: number,
  audioName: string,
  enabled: boolean,
}

const AlertConfiguration = () => {
  const {t} = useTranslation(['common']);
  const [globalAlertSoundSettings, setGlobalAlertSoundSettings] = useState<IGlobalAlertSoundSetting>({volume: 50, audioName: 'Fairy-Sparkle', enabled: true});
  const [initialAlertSoundSetting, setInitialAlertSoundSetting] = useState<IGlobalAlertSoundSetting>(globalAlertSoundSettings);
  const {updateHeaderTitle} = useHeader();
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'}); 
  const { data, loading, refetch } = useQuery(GET_ALERT_AUDIO, { 
    variables: {recordType: ALERT_SETTING_IN_APP_SOUND}});
  const [soundPlaying, setSoundPlaying] = useState(false);
  const [saveAlertConfigMuattion, {loading: saveAlertAudioLoading}] = useMutation(SAVE_ALERT_AUDIO_SETTING);
  const editAlertAudioAccess = TokenService.hasAccess(PERMISSION_CODE.edit_alert_audio);

  useEffect(()=>{
    updateHeaderTitle(t('Alert Audio'), <HiMiniSpeakerWave color='#FFF' size={28} />);
    if(data && data?.getAlertAudio?.statusCode === StatusCode.SUCCESS){
      const convertData = JSON.parse(data?.getAlertAudio?.result?.data);
      setGlobalAlertSoundSettings(convertData);
      setInitialAlertSoundSetting(convertData);
    }
  },[updateHeaderTitle, t, data]);

  const handleToggleChange = useCallback(() =>{
    setGlobalAlertSoundSettings(prev => ({...prev, enabled: !prev.enabled}));
  }, []);

  const playAlertSound = useCallback((audioName: string) => {
    setSoundPlaying(true);
    const selectedAudio = getAudioAlertName(audioName);
    const audio = new Audio(selectedAudio);
    audio.volume = globalAlertSoundSettings.volume / 100;
    audio.play();
    audio.addEventListener('ended', () => {
      setSoundPlaying(false);
    });
  },[globalAlertSoundSettings]);

  const handleAlertAudioChange = useCallback((value: string) => {
    setGlobalAlertSoundSettings(prev => ({...prev, audioName: value}));
    playAlertSound(value);
  },[playAlertSound]);

  const handleSaveChanges = useCallback(async () => {
    const payload = {
      recordType: ALERT_SETTING_IN_APP_SOUND,
      data : JSON.stringify({...globalAlertSoundSettings})
    };
    try {
      const res = await saveAlertConfigMuattion({variables: { payload }});
      if(res) {
        setMessage({ text: t('Alert configuration saved successfully'), id: '', type: 'success' });
        refetch();
      }
    } catch(err) {
      console.error(err);
      setMessage({ text: t('Failed to communicate with the system'), id: '', type: 'danger' });
    }
  },[t, saveAlertConfigMuattion, globalAlertSoundSettings, refetch]);

  const handleSliderChange = useCallback((event: Event, newValue: number | number[]) => {
    setGlobalAlertSoundSettings(prev => ({...prev, volume: newValue as number}));
  },[]);

  const volumeMarks = [
    { value: 10, label: '10'},
    { value: 20, label: '20'},
    { value: 30, label: '30'},
    { value: 40, label: '40'},
    { value: 50, label: '50'},
    { value: 60, label: '60'},
    { value: 70, label: '70'},
    { value: 80, label: '80'},
    { value: 90, label: '90'},
    { value: 100, label: '100'},
  ];

  return (
    <Container>
      {message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
      {loading ? 
        <LoadingContainer>
          <CircularProgress />
          <Span>{t('Loading...')}</Span>
        </LoadingContainer>:
        <Wrapper>
          <LabelWrapper>
            <Switch checked={globalAlertSoundSettings.enabled} onChange={handleToggleChange} />
            <Label labelText={t(`${globalAlertSoundSettings.enabled ? 'Enabled':'Disabled'}`)} />
          </LabelWrapper>
          <SelectSoundWrapper>
            <Label labelText={t('Select Audio')} />
            <SelectFieldWrapper>
              <SelectField onChange={handleAlertAudioChange} defaultValue={globalAlertSoundSettings.audioName}
                options={ALERT_AUDIO_OPTIONS} label=''/>
              <PlayButton disabled={soundPlaying} onClick={() => playAlertSound(globalAlertSoundSettings.audioName)}>
                {soundPlaying ? <HiPause size={20} /> : <FaPlay />}
              </PlayButton>
            </SelectFieldWrapper>
          </SelectSoundWrapper>
          <SelectSoundWrapper>
            <AlertVolumeHeader>
              <Label labelText={t('Volume')+' :' }/>
              <Span isBold={true}>{globalAlertSoundSettings.volume} %</Span>
            </AlertVolumeHeader>
            <SelectFieldWrapper>
              <StyledSlider
                marks={volumeMarks}
                min={10}
                max={100}
                value={globalAlertSoundSettings.volume}
                onChange={handleSliderChange}
                aria-labelledby="input-slider"
              />
            </SelectFieldWrapper>
          </SelectSoundWrapper>
          <ButtonsWrapper>
            <ButtonWithLoading loading={saveAlertAudioLoading} disabled={lodash.isEqual(initialAlertSoundSetting, globalAlertSoundSettings) || !editAlertAudioAccess} type='submit' onClick={handleSaveChanges} variant={'primary'}>{t('Save')}</ButtonWithLoading>
          </ButtonsWrapper>
        </Wrapper>
      }
    </Container>
  );
};

export default AlertConfiguration;