import { useLazyQuery } from '@apollo/client';
import { GET_ALL_ALERTS } from 'api_configs/queries';
import { StatusCode } from '../constants';
import React, { createContext, useContext, ReactNode, useState, useCallback, useEffect } from 'react';

interface IAlertData {
  id?: number;
  displayName?: string;
  alertData?: any;
  message?: string;
  lastUpdate?: string;
  imagePath?: string;
  instanceId?: string;
  status?: number;
  streamId?: string;
  eventType?: string;
}

type AlertListContextType = {
  alertData: IAlertData[];
  applyCallback: () => void;
  fetchMoreData: () => void;
  hasMore: boolean,
};

const AlertListContext = createContext<AlertListContextType | undefined>(undefined);

type AlertListProviderProps = {
  children: ReactNode;
  startDate: string | null,
  endDate: string | null,
  streamId: string,
};

export const AlertListProvider = ({ children, startDate, endDate, streamId }: AlertListProviderProps) => {
  const [alertData, setAlertData] = useState<IAlertData[]>([]);
  const [getAlerts, {data}] = useLazyQuery(GET_ALL_ALERTS);
  const [hasMore, setHasMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const applyCallback = useCallback(() => {
    if(startDate && endDate){
      const start_date = startDate;
      const end_date = endDate;
      getAlerts({variables:{payload: {pageNumber: 1, streamId: streamId, startDate: start_date, endDate: end_date}}});
    }
  },[startDate, endDate, getAlerts, streamId]);

  useEffect(() => {
    if(data?.getAlerts?.statusCode === StatusCode.SUCCESS){
      let newAlerts = data.getAlerts?.result?.data;
      newAlerts = newAlerts.filter((alert: IAlertData) => !alertData?.some((existingAlert) => existingAlert.id === alert.id));
      if (newAlerts.length > 0) {
        if(currentPage === 1){
          setAlertData([...newAlerts]);
        }else{
          setAlertData((prevAlerts) => [...(prevAlerts || []), ...newAlerts]);
        }
      }
      setHasMore(true);
    }
    if(data?.getAlerts?.statusCode === StatusCode.NOT_FOUND){
      setHasMore(false);
    }
  },[data?.getAlerts, alertData, currentPage]);

  const fetchMoreData = useCallback(() => {
    if(startDate && endDate && streamId){
      const start_date = startDate;
      const end_date = endDate;
      let currentPage = 1;
      setCurrentPage(prev => {
        currentPage = prev + 1;
        return currentPage;
      });
      getAlerts({variables:{payload:{pageNumber: currentPage, streamId: streamId, startDate: start_date, endDate: end_date}}});
    }
  },[getAlerts, streamId, startDate, endDate]);

  return (
    <AlertListContext.Provider value={{ alertData, applyCallback, fetchMoreData, hasMore }}>
      {children}
    </AlertListContext.Provider>
  );
};

export const useAlertList = () => {
  const context = useContext(AlertListContext);
  if (context === undefined) {
    throw new Error('useHeader must be used within a HeaderProvider');
  }
  return context;
};
