import React, {useCallback, useState, useEffect, useRef, useMemo} from 'react';
import PipelineNode from './PipelineNode';
import { ACTION_OPTIONS, ALGORITHM_CODES, EP_ACTION_TEMPLATES, EP_FILTERS, FILTER_OPTIONS, NODE_ID, NODE_TYPE, StatusCode } from '../../../constants';
import Xarrow, { Xwrapper, anchorType } from 'react-xarrows';
import styled, {css} from 'styled-components';
import SelectField from 'components/SelectField';
import Checkbox from 'components/Checkbox';
import MultipleSelect from 'components/MultiSelectField';
import Icon from 'components/Icon';
import { useQuery } from '@apollo/client';
import { GET_ALL_ALGORITHMS, GET_ALL_GROUPS, GET_ALL_STREAMS, GET_SCHEDULES_LIST, GET_TEMPLATES } from 'api_configs/queries';
import { IEmailActionData, IPipelineActions, IPipelineInfo, ISchedule, IStream } from 'interface';
import { useTranslation } from 'react-i18next';
import { RiFilter2Line } from 'react-icons/ri';
import { TfiTimer } from 'react-icons/tfi';
import WhatsappConfiguration from './WhatsappConfiguration';
import EmailConfigurationModal from './EmailConfigurationModal';

const PipelineWrapper = styled.div`
  border-radius: 6px;
  min-height: 200px;
  display: flex;
  position: relative;
  gap: 120px;
  @media only screen and (min-width: 1920px){
    gap: 150px;
  }
`; 

const SelectAlgorithmWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

const SelectFieldWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  >:nth-child(2){
    width: 190px;
    @media only screen and (min-width: 1920px){
      width: 230px;
    }
    margin-bottom: 0;
    >label{
      margin-bottom: 4px;
    }
  }
`;

const TemplateWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: row;
  >:nth-child(1){
    width: 140px;
    @media only screen and (min-width: 1920px){
      width: 180px;
    }
    margin-bottom: 0;
    >label{
      margin-bottom: 4px;
    }
  }
`;

const IconDiv = styled.div<{disabled?: boolean}>`
  border: 1px solid ${({theme}) => theme.divider};
  width: 40px;
  height: 40px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({disabled}) => disabled ? '' : '#D0D7F2'};
  cursor: ${({disabled}) => disabled ? 'no-drop' : 'pointer'};
  pointer-events: ${({disabled}) => disabled ? 'none' : ''};
`;

const SelectActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SelectScheduleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  z-index: 2;
`;

const StyledMenuWrapper = styled.button<{active: boolean}>`
  position: absolute;
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  outline: none;
  border: none;
  color: var(--color);
  box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
  cursor: pointer;
  transition: transform 0.5s ease;
  :disabled{
    cursor: no-drop;
    background-color: #e4e4e4;
  }
  ${({ active }) =>
    active &&
      `
      transform: rotate(45deg);
  `}
`;

const StyledMenuItem = styled.li<{active: boolean, length: number}>`
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: ${({theme}) => theme.divider};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10;
  transition: all 0.3s ease-in-out;
  :hover{
  opacity: 0.7;
  box-shadow: 0 5px 10px black;
  }

  ${({ active, length }) =>
    active && css`
    :nth-child(1){
      transform: ${length === 1 ? 'translate(0px, 50px)' : 'translate(-30px, 40px)'} 
    }
    :nth-child(2){
      transform: translate(30px,40px)
    }
  `}
`;

const StyledMenuItems = styled.ul`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: -40px;
`;

const StyledMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 50px;
`;

const EventContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const EventCheckList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 190px;
  @media only screen and (min-width: 1920px){
    width: 230px;
  }
`;

const Span = styled.span<{isSmall?: boolean, hasBorder?: boolean}>`
  font-size: ${({isSmall}) => isSmall ? '14px' : ''};
  font-weight: 400;
  color: ${({isSmall}) => isSmall ? '#ADADAD' : ''};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({hasBorder}) => hasBorder && css`
    border: 1px solid ${({theme}) => theme.divider};
    padding: 2px 4px;
    border-radius: 4px;
  `}
`; 

const SelectedFiltersList = styled.div<{flexDirection?: string}>`
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: ${({flexDirection}) => flexDirection ? flexDirection : 'row'};
  gap: 10px;
`;

const SingleEvent = styled.div<{disabled?: boolean}>`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: ${({disabled}) => disabled ? 'no-drop' : 'pointer'};
  pointer-events: ${({disabled}) => disabled ? 'none' : ''};
`;

const TemplateInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const RulesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 190px;
  @media only screen and (min-width: 1920px){
    width: 230px;
  }
`;

const RuleContainer = styled.div`
  border: 1px solid #dddddd;
  border-radius: 5px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  max-width: inherit;
`;

const DaysContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 5px;
  flex-wrap: wrap;
`;

const Day = styled.div`
  display: flex;
  font-size: 14px;
`;

const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Time = styled.div`
`;

const CompulsoryIndicator = styled.span`
  color: red;
`;

interface IActions {
  code: string;
  displayName: string;
}

interface IEvents {
  code: string;
  displayName: string;
}

interface IAlgorithmListSchema {
  id: number;
  name: string;
  code: string;
  description: string;
  events: IEvents[];
  actions: IActions[];
}

interface IGroups {
  id: number,
  name: string,
  description?: string,
  streams: IStream[]
}

interface IArrows{
  start: string,
  end: string,
  hasLabel?: boolean,
  labelOptions?: string[];
}

interface INewNodeProps {
  startId: string, 
  endId?: string, 
  topId?: string,
  bottomId?: string,
  value?: string;
}

interface IAlertTemplate {
  id: number,
  name: string,
  body: string,
  subject: string,
  templateType: string
}

const emailInitialData: IEmailActionData = {
  cc: [],
  bcc: [],
  to: [],
  snapshot: false,
  movie_clip: false
};

interface IPipelineAreaProps {
  action_nodes?: INewNodeProps[];
  filter_nodes?: INewNodeProps[];
  schedule_nodes?: INewNodeProps[];
  receivedArrows?: IArrows[];
  setPipelineInfo?: React.Dispatch<React.SetStateAction<IPipelineInfo>>;
  pipelineInfo: IPipelineInfo;
  isEditMode?: boolean;
  isReadOnly?: boolean;
  actions?: IPipelineActions[];
  getFilterLength?: (length: number) => void;
  generateDelay?: number;
}

const PipelineArea: React.FC<IPipelineAreaProps> = ({action_nodes=[],filter_nodes=[],getFilterLength=()=>{}, isReadOnly=false, schedule_nodes=[],pipelineInfo,setPipelineInfo=()=>{}}, generateDelay= 100) => {
  const { data: allAlgorithms } = useQuery(GET_ALL_ALGORITHMS);
  const [algorithmListSchema, setAlgorithmListSchema] = useState<IAlgorithmListSchema[]>([]);
  const [allStreams, setAllStreams] = useState<IStream[]>([]);
  const [allGroups, setAllGroups] = useState<IGroups[]>([]);
  const {data: getAllStreamsResponse} = useQuery(GET_ALL_STREAMS);
  const {data: getAllGroupsResponse} = useQuery(GET_ALL_GROUPS);
  const { data: scheduleList, refetch: refetchSchedules } = useQuery(GET_SCHEDULES_LIST);
  const [scheduleListData, setScheduleListData] = useState<ISchedule[]>([]);
  const [scheduleNodes, setScheduleNodes] = useState<INewNodeProps[]>([]);
  const [actionNodes, setActionNodes] = useState<INewNodeProps[]>([]);
  const [filterNodes, setFilterNodes] = useState<INewNodeProps[]>([]);
  const [isActive, setIsActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const {data: allTemplates} = useQuery(GET_TEMPLATES);
  const [allTemplatesList, setAllTemplatesList] = useState<IAlertTemplate[]>([]);
  const templateSettingsRef = useRef<HTMLDivElement | null>(null);
  const [flag, setFlag] = useState<boolean>(false);
  const [configurationModalOpen, setConfigurationModalOpen] = useState<boolean>(false);
  const [selectedConfigModal, setSelectedConfigModal] = useState<string>('');
  const {t} = useTranslation(['common']);

  useEffect(()=>{
    if(action_nodes.length > 0){
      setActionNodes(action_nodes);
    }
    if(filter_nodes.length > 0){
      setFilterNodes(filter_nodes);
    }
    if(schedule_nodes.length > 0){
      setScheduleNodes(schedule_nodes);
    }
  },[action_nodes, schedule_nodes, filter_nodes]);

  useEffect(()=>{
    getFilterLength(Object.keys(pipelineInfo.filters).length);
  },[pipelineInfo.filters, getFilterLength]);

  useEffect(()=>{
    setAllTemplatesList((allTemplates?.getTemplates?.statusCode === StatusCode.SUCCESS && allTemplates?.getTemplates?.result !== null) ? allTemplates?.getTemplates?.result?.templates : []);
  },[allTemplates]);

  useEffect(()=>{
    if(allAlgorithms?.getAllAlgorithm?.statusCode === StatusCode.SUCCESS && allAlgorithms?.getAllAlgorithm?.result.length > 0){
      const result = allAlgorithms?.getAllAlgorithm?.result;
      setAlgorithmListSchema(result);
    }
    if(getAllStreamsResponse && getAllStreamsResponse?.getAllStreams?.statusCode === StatusCode.SUCCESS && getAllStreamsResponse?.getAllStreams?.result !== null){
      const allStreams: IStream[] = getAllStreamsResponse?.getAllStreams?.result;
      setAllStreams(allStreams);
    }
    setAllGroups(getAllGroupsResponse?.getAllGroup?.result === null ? [] : getAllGroupsResponse?.getAllGroup?.result);
  },[allAlgorithms, getAllStreamsResponse, getAllGroupsResponse]);

  useEffect(()=>{
    refetchSchedules();
    const schedules: ISchedule[] = scheduleList?.getSchedules?.result !== null ? scheduleList?.getSchedules?.result : [];
    if(schedules?.length > 0){
      setScheduleListData(schedules);
    }
  },[scheduleList, refetchSchedules]);

  const handleRemoveActionCallback = useCallback((index: number)=>{
    setFlag(true);
    setActionNodes(prev => {
      const temp = [...prev];
      temp.splice(index, 1);
      return temp;
    });
    setPipelineInfo(prev => {
      const pipelineInfo = {...prev};
      const tempActions = [...pipelineInfo.actions];
      tempActions.splice(index, 1);
      pipelineInfo.actions = [...tempActions];
      return pipelineInfo;
    });
    setTimeout(()=>{
      setFlag(false);
    },10);
  },[setPipelineInfo]);

  const handleRemoveScheduleCallback = useCallback((index: number)=>{
    setFlag(true);
    setScheduleNodes(prev => {
      const schedule_nodes = [...prev];
      schedule_nodes.splice(index, 1);
      return schedule_nodes;
    });
    setPipelineInfo(prev => {
      const pipelineInfo = {...prev};
      pipelineInfo.scheduleId = null;
      return pipelineInfo;
    });
    setTimeout(()=>{
      setFlag(false);
    },10);
  },[setPipelineInfo]);

  const handleRemoveFilterCallback = useCallback((index: number, filterType: string)=>{
    setFlag(true);
    setFilterNodes(prev => {
      const filter_nodes = [...prev];
      filter_nodes.splice(index, 1);
      return filter_nodes;
    });
    setPipelineInfo(prev => {
      const updatedPipelineInfo = { ...prev };
      if (filterType in updatedPipelineInfo.filters && updatedPipelineInfo.filters && typeof updatedPipelineInfo.filters === 'object') {
        const { [filterType]: deletedFilter, ...remainingFilters } = updatedPipelineInfo.filters;
        updatedPipelineInfo.filters = {...remainingFilters};
      }
      return updatedPipelineInfo;
    });
    setTimeout(()=>{
      setFlag(false);
    },10);
  },[setPipelineInfo]);

  const generateLineData = useCallback(()=>{
    const {ACTION_START, ADD_NEW_NODE, FILTER_BOTTOM, FILTER_END, FILTER_START, FILTER_TOP, SCHEDULE_END, SCHEDULE_START, ALGORITHM} = NODE_ID;
    const { FILTER, SCHEDULE } = NODE_TYPE;
    const arrows: IArrows[] = [];
    if(actionNodes.length === 0 && filterNodes.length === 0 && scheduleNodes.length === 0){
      arrows.push({start: ALGORITHM, end: ADD_NEW_NODE});
    }else if(actionNodes.length > 0 && filterNodes.length === 0 && scheduleNodes.length === 0){
      arrows.push({start: ALGORITHM, end: ACTION_START+'0', hasLabel: true, labelOptions:[FILTER, SCHEDULE]});
      for(let i=1;i<actionNodes.length;i++){
        arrows.push({start: ALGORITHM , end: ACTION_START+i});
      }
    }else if(actionNodes.length === 0 && filterNodes.length === 0 && scheduleNodes.length > 0){
      arrows.push({start: ALGORITHM, end: SCHEDULE_START+'0', hasLabel: true, labelOptions: [FILTER]});
      arrows.push({start: SCHEDULE_END+'0', end: ADD_NEW_NODE});
    }else if(actionNodes.length === 0 && filterNodes.length > 0 && scheduleNodes.length === 0){
      arrows.push({start: ALGORITHM, end: FILTER_START+'0'});
      for(let i=1;i<filterNodes.length;i++){
        arrows.push({start:FILTER_BOTTOM+(i-1) , end: FILTER_TOP+i});
      }
      arrows.push({start: FILTER_END+'0', end: ADD_NEW_NODE});
    }else if(actionNodes.length === 0 && filterNodes.length > 0 && scheduleNodes.length > 0){
      arrows.push({start: ALGORITHM, end: FILTER_START+'0'});
      for(let i=1;i<filterNodes.length;i++){
        arrows.push({start:FILTER_BOTTOM+(i-1) , end: FILTER_TOP+i});
      }
      arrows.push({start: FILTER_END+'0', end: SCHEDULE_START+'0'});
      arrows.push({start: SCHEDULE_END+'0', end: ADD_NEW_NODE});
    }else if(actionNodes.length > 0 && filterNodes.length === 0 && scheduleNodes.length > 0){
      arrows.push({start: ALGORITHM, end: SCHEDULE_START+'0', hasLabel: true, labelOptions: [FILTER]});
      arrows.push({start: SCHEDULE_END+'0', end: ACTION_START+'0'});
      for(let i=1; i<actionNodes.length; i++){
        arrows.push({start: SCHEDULE_END+'0', end: ACTION_START+i});
      }
    }else if(actionNodes.length > 0 && filterNodes.length > 0 && scheduleNodes.length === 0){
      arrows.push({start: ALGORITHM, end: FILTER_START+'0'});
      for(let i=1;i<filterNodes.length;i++){
        arrows.push({start:FILTER_BOTTOM+(i-1) , end: FILTER_TOP+i});
      }
      arrows.push({start: FILTER_END+'0', end: ACTION_START+'0', hasLabel: true, labelOptions: [SCHEDULE]});
      for(let i=1;i<actionNodes.length;i++){
        arrows.push({start:FILTER_END+'0' , end: ACTION_START+i});
      }
    }else if(actionNodes.length > 0 && filterNodes.length > 0 && scheduleNodes.length > 0){
      arrows.push({start: ALGORITHM, end: FILTER_START+'0'});
      for(let i=1;i<filterNodes.length;i++){
        arrows.push({start:FILTER_BOTTOM+(i-1) , end: FILTER_TOP+i});
      }
      arrows.push({start: FILTER_END+'0', end: SCHEDULE_START+'0'});
      arrows.push({start: SCHEDULE_END+'0', end: ACTION_START+'0'});
      for(let i=1; i<actionNodes.length; i++){
        arrows.push({start: SCHEDULE_END+'0', end: ACTION_START+i});
      }
    }
    return arrows;
  },[scheduleNodes, actionNodes, filterNodes]);

  useEffect(()=>{
    setFlag(true);
    setTimeout(()=>{
      setFlag(false);
      generateLineData();
    },generateDelay);
  },[generateLineData, generateDelay]);

  const ALGORITHM_OPTIONS = useMemo(() => {
    return algorithmListSchema?.filter(algo => algo.code !== ALGORITHM_CODES.traffic_pulse_detection).map((item: IAlgorithmListSchema) => ({
      name: item.name,
      value: item.code,
    }));
  }, [algorithmListSchema]);

  const SCHEDULE_OPTIONS = useMemo(()=>{
    return scheduleListData?.map((item: ISchedule) => ({
      name: item.name as string,
      value: item.id as number
    }));
  },[scheduleListData]);

  const CAMERAS_OTIONS = useMemo(()=>{
    return allStreams?.map((item: IStream) => ({
      name: item.name,
      value: item.instanceId
    }));
  },[allStreams]);

  const GROUP_OPTIONS = useMemo(()=>{
    return allGroups?.map((item: IGroups) => ({
      name: item.name,
      value: item.id
    }));
  },[allGroups]);

  const handleAlgorithmChange = useCallback((value: string)=>{
    setPipelineInfo(prev => ({...prev, algoCode: value, event: []}));
  },[setPipelineInfo]);

  const handleEventsChange = useCallback((eventCode: string, isChecked: boolean)=>{
    let eventsArray = pipelineInfo.event;
    if(!isChecked){
      eventsArray = [...eventsArray, eventCode];    
    }else{
      eventsArray = eventsArray.filter(code => code !== eventCode);
    }
    setPipelineInfo(prev => ({...prev, event: eventsArray}));
  },[pipelineInfo, setPipelineInfo]);

  const handleFilterConditionChange = useCallback((index: number, value: string)=>{
    setFlag(true);
    setFilterNodes(prev => {
      const temp = [...prev];
      temp[index] = {...temp[index], value};
      return temp;
    });
    setTimeout(()=>{
      setFlag(false);
    },10);
  },[]); 

  const handleFilterListChange = useCallback((selectedIds: string[], selectedFilter: string)=>{
    let ids: (number | string)[] = selectedIds;
    if(selectedFilter === 'groups') {
      ids = selectedIds.map(id => parseInt(id));
    }
    setPipelineInfo(prev => {
      const newFilters = { ...prev.filters };
      if (selectedIds.length === 0) {
        delete newFilters[selectedFilter];
      } else {
        newFilters[selectedFilter] = ids;
      }
      return { ...prev, filters: newFilters };
    });
  },[setPipelineInfo]);

  const getAvailableActionOption = (id: number) => {
    const selectedOptions = actionNodes.filter((_item, index) => index !== id).map(item => item.value);
    return ACTION_OPTIONS.filter((option) => !selectedOptions.includes(option.value));
  };

  const getAvailableFilterOption = (id: number) => {
    const selectedOptions = filterNodes.filter((_item, index) => index !== id).map(item => item.value);
    return FILTER_OPTIONS.filter((option) => !selectedOptions.includes(option.value));
  };

  const handleActionsChange = useCallback((value: string, index: number)=>{
    setActionNodes(prev => {
      const temp = [...prev];
      temp[index] = {...temp[index], value};
      return temp;
    });
    setPipelineInfo(prev => {
      const pipelineInfo = {...prev};
      const tempActions = [...pipelineInfo.actions];
      tempActions[index] = {...tempActions[index], templateId: null, type: value, data: {}};
      pipelineInfo.actions = [...tempActions];
      return pipelineInfo;
    });
  },[setPipelineInfo]);

  const handleTemplateChange = useCallback((value: string, templateType: string)=>{
    setPipelineInfo(prev => {
      const temp = {...prev};
      const templateIndex = temp.actions.findIndex(item => item.type === templateType);
      if(templateIndex !== -1){
        temp.actions[templateIndex] = {...temp.actions[templateIndex], templateId: parseInt(value)};
      }
      return temp;
    });
  },[setPipelineInfo]);

  const addNewNodeCallback = useCallback((id: string)=>{
    setFlag(true);
    if(id === NODE_TYPE.SCHEDULE){ // ADD SCHEDULE NODE
      const newScheduleNode: INewNodeProps = { 
        startId: NODE_ID.SCHEDULE_START, 
        endId: NODE_ID.SCHEDULE_END,
      };
      setScheduleNodes(prev => [...prev, newScheduleNode]);
    }
    else if(id === NODE_TYPE.FILTER){ // ADD FILTER NODE
      const filterNode: INewNodeProps = {
        startId: NODE_ID.FILTER_START,
        endId: NODE_ID.FILTER_END,
        topId: NODE_ID.FILTER_TOP,
        bottomId: NODE_ID.FILTER_BOTTOM
      };
      setFilterNodes(prev => [...prev, filterNode]);
    }else if(id === NODE_TYPE.ACTION){ // ADD ACTION NODE
      const actionNode: INewNodeProps = {
        startId: NODE_ID.ACTION_START,
        endId: NODE_ID.ACTION_END,
      };
      setActionNodes(prev => [...prev, actionNode]);
    }
    setTimeout(()=>{
      setFlag(false);
    },10);
  },[]);

  const handleAddNumbersListCallback = useCallback((value: string, data: string[], countryCode: string)=>{
    const mobileNosWithCountryCode = data.map(mobileNumber => countryCode + mobileNumber);
    setPipelineInfo(prev => {
      const temp = {...prev};
      const findIndex = temp.actions.findIndex(item => item.type === value);
      if(findIndex !== -1){
        const updatedActions = [...temp.actions]; // Create a new array to avoid mutating the original array
        updatedActions[findIndex] = {
          ...updatedActions[findIndex],
          data: { mobile_no: [...mobileNosWithCountryCode], countryCode: countryCode} 
        };
        temp.actions = updatedActions;
      }
      return temp;
    });
  },[setPipelineInfo]);

  const handleEmailConfigurationsCallback = useCallback((value: string, emailData: IEmailActionData)=>{
    setPipelineInfo(prev => {
      const temp = {...prev};
      const findIndex = temp.actions.findIndex(item => item.type === value);
      if(findIndex !== -1){
        const updatedActions = [...temp.actions]; 
        updatedActions[findIndex] = {
          ...updatedActions[findIndex],
          data: {...emailData}
        };
        temp.actions = updatedActions;
      }
      return temp;
    });
  },[setPipelineInfo]);

  const getIcon = useCallback((icon: string)=>{
    switch(icon){
    case NODE_TYPE.FILTER:{
      return <RiFilter2Line size={20}/>;
    }
    case NODE_TYPE.SCHEDULE:{
      return <TfiTimer size={20} />;
    }
    }
  },[]);

  const getTemplateOptions = useCallback((value: string)=>{
    const arr: IAlertTemplate[] = allTemplatesList.filter(template => template.templateType === value);
    return arr.map(item => ({value: item.id, name: item.name}));
  },[allTemplatesList]);

  const getTemplateDetails = (templateId: number) => {
    return allTemplatesList.find(item => item.id === templateId);
  };

  const getAlgoDetails = useCallback((algoCode: string) => {
    return algorithmListSchema.find(item => item.code === algoCode);
  },[algorithmListSchema]);

  const getLabel = useCallback((labelOptions: string[], index: number) => {
    return (
      <StyledMenu>
        <StyledMenuWrapper className={isActive && index === activeIndex ? 'active' : ''} onClick={() => {setIsActive(!isActive); setActiveIndex(index);}} active={isActive && index === activeIndex}>
          <Icon icon='Plus' />
        </StyledMenuWrapper>
        <StyledMenuItems>
          {labelOptions.map(label => (
            <StyledMenuItem length={labelOptions.length} title={`Add ${label}`} onClick={()=>addNewNodeCallback(label)} active={isActive && index === activeIndex}>{getIcon(label)}</StyledMenuItem>
          ))}
        </StyledMenuItems>
      </StyledMenu>
    );
  },[getIcon, isActive, activeIndex, addNewNodeCallback]);

  const getNumbersList = useCallback((numbers: string[], countryCode: string) => {
    return numbers.map(number => {
      if (number.startsWith(countryCode)) {
        return number.slice(countryCode.length);
      }
      return number;
    });
  },[]);

  return (
    <Xwrapper>
      <PipelineWrapper >
        <PipelineNode 
          hasAddNewNodeOption={actionNodes.length === 0 && scheduleNodes.length === 0 && filterNodes.length === 0}
          onNewNodeCallBack={addNewNodeCallback}
          addNewNodeOptions={[NODE_TYPE.FILTER, NODE_TYPE.SCHEDULE, NODE_TYPE.ACTION]}
          hasRemoveNodeOption={false}
          connectionOptions={
            { 
              rightPoint:{show: true, id:NODE_ID.ALGORITHM},
            }
          }>
          <SelectAlgorithmWrapper>
            <SelectFieldWrapper>
              <Span isSmall>{t('Analysis')} <CompulsoryIndicator>*</CompulsoryIndicator> </Span>
              <SelectField disabled={isReadOnly} options={ALGORITHM_OPTIONS} defaultValue={pipelineInfo?.algoCode ?? ''} onChange={handleAlgorithmChange} placeholder={t('select algorithm')} />
            </SelectFieldWrapper>
            {pipelineInfo?.algoCode && 
                      <EventContainer>
                        <Span isSmall>{t('Events')} <CompulsoryIndicator>*</CompulsoryIndicator> </Span>
                        <EventCheckList>
                          {getAlgoDetails(pipelineInfo?.algoCode)?.events.map((item, index) => {
                            const isChecked = pipelineInfo.event.includes(item.code);
                            return (
                              <SingleEvent key={item.code} disabled={isReadOnly} onClick={()=>handleEventsChange(item.code, isChecked)}>
                                <Checkbox disabled={isReadOnly} checked={isChecked} key={index} />
                                <Span title={item.displayName}>{t(item.displayName)}</Span>
                              </SingleEvent>
                            );
                          }
                          )}
                        </EventCheckList>
                      </EventContainer>}
          </SelectAlgorithmWrapper>
        </PipelineNode>
        {filterNodes.length > 0 && 
              <SelectScheduleWrapper>
                {filterNodes.map(({ startId, bottomId='', topId='', endId='', value=''}, index) => (
                  <PipelineNode 
                    key={startId+index+value}
                    isReadOnly={isReadOnly}
                    hasAddNewNodeOption={actionNodes?.length === 0 && scheduleNodes?.length === 0 && index === 0}
                    removeNodeCallback={()=>handleRemoveFilterCallback(index, value)}
                    addNewNodeOptions={[NODE_TYPE.SCHEDULE,NODE_TYPE.ACTION]}
                    copyNodeCallback={() => addNewNodeCallback(NODE_TYPE.FILTER)}
                    onNewNodeCallBack={addNewNodeCallback}
                    hasCopyNodeOption={{show: (index === filterNodes.length - 1 && filterNodes.length < FILTER_OPTIONS.length), id: endId}}
                    connectionOptions={
                      {
                        leftPoint:{show: true, id: startId+index},
                        rightPoint:{show: endId.length > 0 ?? false, id: endId+index},
                        bottomPoint: {show: bottomId.length > 0 ?? false, id: bottomId+index},
                        topPoint:{show: topId.length > 0 ?? false, id: topId+index},
                      }
                    }
                  >
                    <SelectAlgorithmWrapper>
                      <SelectFieldWrapper>
                        <Span isSmall>{t('Filter')}</Span>
                        <SelectField 
                          disabled={isReadOnly} 
                          options={getAvailableFilterOption(index)} 
                          defaultValue={value ?? ''}
                          placeholder={t('select filter')} 
                          onChange={(value) => handleFilterConditionChange(index, value)}  />
                      </SelectFieldWrapper>
                      {value && 
                        <EventCheckList>
                          <Span isSmall>{value === EP_FILTERS.CAMERAS ? t('Cameras') : t('Groups')}</Span>
                          {!isReadOnly && <MultipleSelect
                            disabled={isReadOnly}
                            width='100%'
                            showSelectedOptions={false}
                            selectedPlaceholder={t('selected')}
                            placeholder={value === EP_FILTERS.CAMERAS ? t('select cameras') : t('select groups')}
                            options={value === EP_FILTERS.CAMERAS ? CAMERAS_OTIONS??[] : GROUP_OPTIONS??[]} 
                            selectedValues={pipelineInfo.filters[value]?.map(item => item.toString()) ?? []} 
                            onChange={(selectedFilterIds) => handleFilterListChange(selectedFilterIds, value)}
                            emptyListPlaceholder={value === EP_FILTERS.CAMERAS ? t('No cameras created') : t('No group created')}
                          />}
                          <SelectedFiltersList>{pipelineInfo.filters[value]?.length > 0 && pipelineInfo.filters[value]?.map(id => {
                            const name = value === EP_FILTERS.CAMERAS ? CAMERAS_OTIONS?.find(item => item.value === id)?.name : GROUP_OPTIONS?.find(item => item.value === id)?.name;
                            return (
                              <>
                                {name ? <Span title={name} hasBorder>{name}</Span> : null}
                              </>
                            );
                          })}</SelectedFiltersList>
                        </EventCheckList>
                      }
                    </SelectAlgorithmWrapper>
                  </PipelineNode>
                ))}
              </SelectScheduleWrapper>
        }
        {scheduleNodes.length > 0 && 
            <SelectScheduleWrapper>
              {scheduleNodes.map(({startId, endId=''}, index) => (
                <PipelineNode
                  isReadOnly={isReadOnly}
                  hasAddNewNodeOption={actionNodes.length === 0}
                  removeNodeCallback={()=>handleRemoveScheduleCallback(index)}
                  addNewNodeOptions={[NODE_TYPE.ACTION]}
                  onNewNodeCallBack={addNewNodeCallback}
                  connectionOptions={
                    {
                      leftPoint:{show: true, id: startId+index},
                      rightPoint:{show: endId.length > 0 ?? false, id: endId+index},
                    }
                  }
                >
                  <SelectFieldWrapper>
                    <Span isSmall>{t('Alert Schedule')}</Span>
                    <SelectField disabled={isReadOnly} options={SCHEDULE_OPTIONS} defaultValue={pipelineInfo?.scheduleId ?? ''} placeholder={t('select schedule')} onChange={(value) => setPipelineInfo(prev => ({...prev, scheduleId: parseInt(value)}))} />
                    {pipelineInfo?.scheduleId && 
                    <TemplateInfo>
                      <Span isSmall>{t('Rules')}</Span>
                      <RulesWrapper>
                        {scheduleListData.find(item => item.id === pipelineInfo.scheduleId)?.rules?.map((rule)=>(
                          <RuleContainer>
                            <DaysContainer>
                              {
                                rule?.days?.map((day,index)=>(
                                  <Day>{day}{index !== rule?.days?.length-1 && <div>,</div>}</Day>
                                ))
                              }
                            </DaysContainer>
                            <TimeContainer>
                              <Time>{rule?.startTime}</Time>
                              <Icon icon='Right' size={18} />
                              <Time>{rule?.endTime}</Time>
                            </TimeContainer>
                          </RuleContainer>
                        ))}
                      </RulesWrapper>
                    </TemplateInfo>}
                  </SelectFieldWrapper>
                </PipelineNode>
              ))}
            </SelectScheduleWrapper>
        }
        {actionNodes.length > 0 && 
            <SelectScheduleWrapper>
              {actionNodes.map(({startId, endId='', value}, index) => (
                <PipelineNode
                  key={startId + index}
                  isReadOnly={isReadOnly} 
                  removeNodeCallback={()=>handleRemoveActionCallback(index)}
                  hasCopyNodeOption={{show: (index === actionNodes.length - 1 && actionNodes.length < ACTION_OPTIONS.length), id: endId}}
                  copyNodeCallback={() => addNewNodeCallback(NODE_TYPE.ACTION)}
                  hasAddNewNodeOption={false}
                  addNewNodeOptions={[]}
                  onNewNodeCallBack={addNewNodeCallback}
                  connectionOptions={
                    {
                      leftPoint:{show: true, id: startId+index},
                      rightPoint:{show: endId.length > 0 ?? false, id: endId+index},
                    }
                  }
                >
                  <SelectAlgorithmWrapper ref={templateSettingsRef}>
                    <SelectFieldWrapper>
                      <Span isSmall>{t('Action')} <CompulsoryIndicator>*</CompulsoryIndicator> </Span>
                      <SelectField 
                        disabled={isReadOnly}
                        options={getAvailableActionOption(index)} 
                        defaultValue={ value ?? ''}
                        placeholder={t('Select actions...')}
                        onChange={(value) =>handleActionsChange(value, index)} />
                    </SelectFieldWrapper>
                    {(value === EP_ACTION_TEMPLATES.WHATSAPP || value === EP_ACTION_TEMPLATES.SMS || value === EP_ACTION_TEMPLATES.EMAIL) && 
                    <SelectActionWrapper>
                      <Span isSmall>{t('Template')} <CompulsoryIndicator>*</CompulsoryIndicator> </Span>
                      <TemplateWrapper>
                        <SelectField 
                          disabled={isReadOnly}
                          options={getTemplateOptions(value)} 
                          defaultValue={pipelineInfo.actions.find(item => item.type === value)?.templateId as number ?? ''}
                          placeholder={t('Select template...')}
                          onChange={(e) =>handleTemplateChange(e, value)} />
                        <IconDiv disabled={pipelineInfo.actions.find(item => item.type === value)?.templateId ? false : true} onClick={()=>{setConfigurationModalOpen(true);setSelectedConfigModal(value);}}>
                          <Icon icon='Setting' size={26} />
                        </IconDiv>
                      </TemplateWrapper>
                    </SelectActionWrapper>}
                    {configurationModalOpen && (selectedConfigModal === EP_ACTION_TEMPLATES.WHATSAPP || selectedConfigModal === EP_ACTION_TEMPLATES.SMS) && 
                    <WhatsappConfiguration 
                      isReadOnly={isReadOnly}
                      selectedAction={selectedConfigModal}
                      numberList={getNumbersList(pipelineInfo.actions.find(item => item.type === selectedConfigModal)?.data?.mobile_no ?? [], 
                        pipelineInfo.actions.find(item => item.type === selectedConfigModal)?.data?.countryCode ?? '91')}
                      subject={getTemplateDetails(pipelineInfo.actions.find(item => item.type === selectedConfigModal)?.templateId as number ?? -1)?.subject ?? ''}
                      body={getTemplateDetails(pipelineInfo.actions.find(item => item.type === selectedConfigModal)?.templateId as number ?? -1)?.body ?? ''} 
                      modalOpen={configurationModalOpen}
                      setModalOpen={setConfigurationModalOpen}
                      handleAddNumbersListCallback={handleAddNumbersListCallback}
                      countryCode={pipelineInfo.actions.find(item => item.type === selectedConfigModal)?.data?.countryCode ?? '91'}
                    />}
                    {configurationModalOpen && selectedConfigModal === EP_ACTION_TEMPLATES.EMAIL && 
                    <EmailConfigurationModal 
                      isReadOnly={isReadOnly}
                      selectedAction={selectedConfigModal}
                      subject={getTemplateDetails(pipelineInfo.actions.find(item => item.type === selectedConfigModal)?.templateId as number ?? -1)?.subject ?? ''}
                      body={getTemplateDetails(pipelineInfo.actions.find(item => item.type === selectedConfigModal)?.templateId as number ?? -1)?.body ?? ''} 
                      modalOpen={configurationModalOpen}
                      setModalOpen={setConfigurationModalOpen}
                      handleEmailConfigurationsCallback={handleEmailConfigurationsCallback}
                      emailActionData={pipelineInfo.actions.find(item => item.type === EP_ACTION_TEMPLATES.EMAIL)?.data ?? emailInitialData}
                    />}
                  </SelectAlgorithmWrapper>
                </PipelineNode>
              ))}
            </SelectScheduleWrapper>
        }
        { 
          !flag && generateLineData().map((ar: IArrows, index) => (
            <Xarrow
              path='grid'
              zIndex={ar.hasLabel ? 99 : 0}
              start={ar?.start}
              labels={ar.hasLabel && !isReadOnly ? getLabel(ar.labelOptions ?? [], index): undefined}
              end={ar?.end}
              strokeWidth={2}
              showHead={!ar.start.includes('Bottom')}
              gridBreak='100%-50'
              color='rgb(128, 128, 128)'
              key={ar?.start + '-.' + ar?.start}
              startAnchor={{position: ar.start.includes('Bottom') ? 'auto' : 'right'} as anchorType}
              endAnchor={{position: ar.start.includes('Bottom') ? 'auto' : 'left'} as anchorType}
            />
          ))}
      </PipelineWrapper>
    </Xwrapper>
  );
};

export default PipelineArea;