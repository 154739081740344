import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgGrid = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M7 4.5V7H4.5V4.5H7Zm1.25 15V17A1.25 1.25 0 0 0 7 15.75H4.5A1.25 1.25 0 0 0 3.25 17v2.5a1.25 1.25 0 0 0 1.25 1.25H7a1.25 1.25 0 0 0 1.25-1.25Zm0-6.25v-2.5A1.25 1.25 0 0 0 7 9.5H4.5a1.25 1.25 0 0 0-1.25 1.25v2.5A1.25 1.25 0 0 0 4.5 14.5H7a1.25 1.25 0 0 0 1.25-1.25Zm0-6.25V4.5A1.25 1.25 0 0 0 7 3.25H4.5A1.25 1.25 0 0 0 3.25 4.5V7A1.25 1.25 0 0 0 4.5 8.25H7A1.25 1.25 0 0 0 8.25 7Zm6.25 12.5V17a1.25 1.25 0 0 0-1.25-1.25h-2.5A1.25 1.25 0 0 0 9.5 17v2.5a1.25 1.25 0 0 0 1.25 1.25h2.5a1.25 1.25 0 0 0 1.25-1.25Zm0-6.25v-2.5a1.25 1.25 0 0 0-1.25-1.25h-2.5a1.25 1.25 0 0 0-1.25 1.25v2.5a1.25 1.25 0 0 0 1.25 1.25h2.5a1.25 1.25 0 0 0 1.25-1.25Zm0-6.25V4.5a1.25 1.25 0 0 0-1.25-1.25h-2.5A1.25 1.25 0 0 0 9.5 4.5V7a1.25 1.25 0 0 0 1.25 1.25h2.5A1.25 1.25 0 0 0 14.5 7Zm-1.25-2.5V7h-2.5V4.5h2.5Zm6.25 0V7H17V4.5h2.5ZM7 10.75v2.5H4.5v-2.5H7Zm6.25 0v2.5h-2.5v-2.5h2.5Zm6.25 0H17v2.5h2.5v-2.5ZM7 17v2.5H4.5V17H7Zm6.25 0v2.5h-2.5V17h2.5Zm6.25 0v2.5H17V17h2.5ZM17 3.25a1.25 1.25 0 0 0-1.25 1.25V7A1.25 1.25 0 0 0 17 8.25h2.5A1.25 1.25 0 0 0 20.75 7V4.5a1.25 1.25 0 0 0-1.25-1.25H17Zm-1.25 7.5A1.25 1.25 0 0 1 17 9.5h2.5a1.25 1.25 0 0 1 1.25 1.25v2.5a1.25 1.25 0 0 1-1.25 1.25H17a1.25 1.25 0 0 1-1.25-1.25v-2.5Zm1.25 5A1.25 1.25 0 0 0 15.75 17v2.5A1.25 1.25 0 0 0 17 20.75h2.5a1.25 1.25 0 0 0 1.25-1.25V17a1.25 1.25 0 0 0-1.25-1.25H17Z"
        fill={props.color}
      />
    </svg>
  );
};
export default SvgGrid;
