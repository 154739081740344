import React,{HTMLAttributes} from 'react';
import styled, { DefaultTheme, css, useTheme } from 'styled-components';
import { IconSVGs } from '@futops/icons';

const wrapperCss = css`
  svg {
    overflow: visible;
    vector-effect: non-scaling-stroke;
    line, path, circle, ellipse, foreignObject, polygon, polyline, rect, text, textPath, tspan {
      vector-effect: non-scaling-stroke;
    }
  }
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  ${wrapperCss};
`;

const IconWrapperForSVG = styled.g`
  ${wrapperCss};
`;

interface ISvgIcons extends React.SVGProps<SVGSVGElement> {
  size: number
  color: 'mono' | 'dimmed' | 'subtle' | 'inverse' | 'primary' | 'danger' | 'success' | 'warning' | 'neutral';
  weight: number
}

export { IconWrapper, IconWrapperForSVG, IconSVGs };

export interface OwnProps {
  icon: string;
  size?: number;
  weight?: 'light' | 'regular' | 'heavy' | 'strong';
  color?: ISvgIcons['color'];
  forSvgUsage?: boolean;
}

type IconProps = OwnProps & HTMLAttributes<HTMLDivElement>

const dimensions = {
  icons: {
    weights: {
      light: 1,
      regular: 1.5,
      heavy: 3,
      strong: 5
    }
  }
}; 

const Icon: React.FC<IconProps> = ({ icon, size = 24, weight = 'regular', color = 'mono', forSvgUsage = false}) => {
  const theme: DefaultTheme = useTheme();
  const iconWeight: number = dimensions.icons.weights[weight];
  const IconSVG = IconSVGs[icon as keyof typeof IconSVGs];
  return (
    IconSVG != null ?
      forSvgUsage ?
        <IconWrapperForSVG>
          {IconSVG({ size: size, weight: iconWeight,color: theme.icons[color] })}
        </IconWrapperForSVG>
        :
        <IconWrapper>
          {IconSVG({ size: size, weight: iconWeight,color: theme.icons[color] })}
        </IconWrapper>
      :
      null
  );

};

export default Icon;