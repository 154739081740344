import React, { ButtonHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

interface OwnProps {
  variant: 'primary' | 'secondary' | 'danger' | 'success';
  size ?: 'small' | 'normal'
}

export type ButtonProps = OwnProps & ButtonHTMLAttributes<HTMLButtonElement>

const primary = css`
  background: #435FCA;
  color: #FFFFFF;
`;

const secondary = css`
  background: linear-gradient(to top, #D0D7F2, #F5F8FF);
  color: '#34495E';
  border: 1px solid #818E9A;
`;

const danger = css`
  background: linear-gradient(to top, #FF0000, #FF6357);
  color: #ffffff;
  border: 1px solid #D63031;
`;

const success = css`
  color: #ffffff;
  background-color: #18C97F;
`;

const StyledButton = styled.button<OwnProps>`
  padding: ${({size}) => size === 'small' ? '3px 6px' : '0 18px' };
  border: none;
  border-radius: 5px;
  text-align: center !important;
  min-width: ${({size}) => size === 'small' ? 'fit-content' : '90px' };
  height: ${({size}) => size === 'small' ? '20px' : '34px' };
  font-size: ${({size}) => size === 'small' ? '12px' : '16px' };
  cursor: pointer;
  z-index: 8;
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  ${({ variant }) => {
    if (variant === 'primary') {
      return primary;
    }
    if (variant === 'secondary') {
      return secondary;
    }
    if (variant === 'danger') {
      return danger;
    }
    if(variant === 'success'){
      return success;
    }
    return '';
  }}
`;

const Button: React.FC<ButtonProps> = ({ variant, size='normal', children, ...props }) => {
  return (
    <StyledButton type='button' size={size} variant={variant} {...props}>
      {children}
    </StyledButton>
  );
};

export default Button;