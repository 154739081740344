import React, { useEffect, useRef, useState, useCallback } from 'react';
import Config from '../configs/config.json';
import styled from 'styled-components';
import Icon from './Icon';
import { GrLanguage } from 'react-icons/gr';
import i18n from 'i18next';
import AlertDrawer from './AlertDrawer';
import AuthService from 'api_configs/authService';
import TokenService from 'api_configs/tokenService';
import { useTranslation } from 'react-i18next';
import { useHeader } from 'contexts/HeaderContext';
import BreadCrumbs from './BreadCrumbs';
import { useHistory } from 'react-router';
import { useAnnotation } from 'contexts/AnnotaionContext';
import Switch from './Switch';
import { GUEST_USERNAME, IconType, PERMISSION_CODE, RELEASE_VERSION, StatusCode, USER_IMG_BASE_URL } from '../constants';
import { GET_USERMANAGEMENT_INFO, GET_USER_DETAILS } from 'api_configs/queries';
import { useQuery } from '@apollo/client';
import { MdNewReleases } from 'react-icons/md';
import { Auth } from 'aws-amplify';
import { IAlertMessageType } from 'interface';
import AlertBar from './AlertBar';

const ContainerWrapper = styled.div`
  background-color: ${({theme})=>theme.topBar.backGround};
  padding: 0px 10px;
  position: sticky;
  top: 0;
  right: 0px;
  z-index: 9;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
`;

const LogoutDiv = styled.div`
  font-weight: 600;
  cursor: pointer;
  margin: 20px;
`;

const UserTitle = styled.div`
  font-weight: 600;
  margin-bottom: 8px;
`;

const MyProfileDiv = styled.div`
  font-weight: 600;
  margin-bottom: 8px;
  cursor: pointer;
  margin: 20px;
  height: 40px;
  border-bottom: 1px solid #d4d4d4;
`;

const UserName = styled.div``;

const UserDrawerWrapper = styled.div`
  position: relative;
`;

const UserIcon = styled.div`
  cursor: pointer;
  color: ${({theme})=>theme.text.primaryText};
`;

const Title = styled.span`
  font-size: 20px;
  font-weight: 600;
  max-width: 1100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  position: absolute;
  right: 10px;
`;

const AllLanguagesWrapper = styled.div`
  position: relative;
`;

const LanguageFilterWrapper = styled.div`
  display: flex;
  width: fit-content;
  border: none;
`;

const LanguageIcon = styled.div`
  cursor: pointer;
  color: ${({theme})=>theme.text.primaryText};
`;

const ActiveLanguageIndicator = styled.span<{isActive:boolean}>`
  display: ${({isActive}) => isActive ? 'block' : 'none'};
  border-radius: 50%;
  position: absolute;
  left: 10px;
  top: 22px;
  width: 5px;
  height: 5px;
  background-color: #000000;
`;

const AllLanguages = styled.div<{languageIconToggle: boolean}>`
  display: ${({languageIconToggle}) => languageIconToggle ? 'flex' : 'none'};
  flex-direction: column;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  position: absolute;
  background-color: ${({theme}) => theme.backgroundColor};
  border-radius: 5px;
  height: fit-content;
  width: 120px;
  right: -14px;
  margin-top: 11px;
  z-index: 2;
`;

const LanguageOption = styled.option`
  padding: 7px;
  margin: 5px 20px;
`;

const LanguageWrapper = styled.div<{isActive: boolean}>`
  display: flex;
  position: relative;
  width: 120px;
  cursor: pointer;
  background-color: ${({isActive}) => isActive ? '#D0D7F2': '#FFFFFF'};
  border: 1px solid #DCE2E4;
  :hover{
    background-color: #F5F8FF;
  }
  padding: 2px;
  height: fit-content;
  z-index: 9;
`;

const Arrow = styled.div`
  width: 15px;
  height: 15px;
  background-color: ${({theme}) => theme.backgroundColor};
  position: absolute;
  right: 17px;
  top: -8px;
  transform: rotate(45deg);
  border-top: 1px solid #DCE2E4;
  border-left: 1px solid #DCE2E4;
`;

const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const DrawerWrapper = styled.div`
  margin-top: 5px;
  position: fixed;
  right: 2px;
  width: 200px;
  height: 100%;
  overflow-y: auto;
  z-index: 9;
  background-color: ${({theme}) => theme.backgroundColor};
  border: 1px solid ${({theme}) => theme.divider};
`;

const UserDrawerContainer = styled.div<{isOpen: boolean}>`
  display: ${({isOpen}) => isOpen ? 'block' : 'none'};
  margin-top: 12px;
  position: fixed; 
  height: 100%;
  right: 0;
`;

const CurrentUser = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
  height: 70px;
  border-bottom: 1px solid #d4d4d4;
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  width: fit-content;
  height: fit-content;
  border-radius: 30px;
  margin-left: 10px;
`;

const IconDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: linear-gradient(to top, #1538BD, #7287D7);
  border-radius: 50%;
  >div>svg>path{
    fill: ${({theme}) => theme.text.lightText};
  }
  >div{
    >svg{
      >path{
        stroke: ${({theme}) => theme.text.lightText};
      }
    }
    >svg>g>path{
      stroke: ${({theme}) => theme.text.lightText};
      fill: ${({theme}) => theme.text.lightText};
    }
  }
`;

const EditIcon = styled.div`
  cursor: pointer;
`;

const TitleContainer = styled.div`
  height: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
`;

const TitleWrapper = styled.div`
  display: flex;
  gap: 10px;
  overflow: hidden;
`;

const CameraStatusIndicator = styled.div<{status: string}>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: auto;
  background-color: ${({status}) => status};
`;

const AnnotationsContainer = styled.div`
  z-index: 9;
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 20px;
  bottom: 15px;
  right: 50px;
  width: 130px;
`;

const AnnotationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const VersionDetails = styled.div`
  color: ${({theme}) => theme.text.secondaryText};
  font-weight: 600;
  font-size: 12px;
  margin-left: -10px;
`;

const AnnotationToogle = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
`;

const UserPicture = styled.img`
  height: 24px;
  width: 24px;
  object-fit: cover;
  border-radius: 50%;
`;

interface RefObject<T> {
  // immutable
  readonly current: T | null
}

interface ITopBarProps{
  setQuery: (str:string)=>void;
  query:string;
}

const TopBar: React.FC<ITopBarProps> = () => {
  const [selectedOption, setSelectedOption] = useState(i18n.language);
  const langWrapperRef = useRef<HTMLInputElement>(null);
  const userRef = useRef<HTMLInputElement>(null);
  const [languageIconToggle, setLanguageIconToggle] = useState(false);
  const authService = AuthService();
  const [userDrawer, setUserDrawer] = useState(false);
  const {t} = useTranslation(['common']);
  const {headerTitle, headerIcon, breadCrumbs, statusColor, cameraInfo} = useHeader();
  const {push} = useHistory();
  const {annotaionEnabled, updateAnnotationEnabled, updateMapTile, isOfflineMap} = useAnnotation();
  const { data } = useQuery(GET_USER_DETAILS, { variables: {username: TokenService.getUser()?.username}});
  const {data: userManagementInfo, loading} = useQuery(GET_USERMANAGEMENT_INFO);
  const [isUserManagementEnabled, setIsUserManagementEnabled] = useState(true);
  const editCameraAccess = TokenService.hasAccess(PERMISSION_CODE.edit_camera);
  const [userImage, setUserImage] = useState('');
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'});

  const fetchUser = useCallback(async () => {
    if (data?.getUser?.statusCode === StatusCode.SUCCESS) {
      const userData = data?.getUser?.result;
      setUserImage(userData?.imagePath ? USER_IMG_BASE_URL + userData.imagePath : '');
    } else if(data?.getUser?.statusCode === StatusCode.ACCESS_TOKEN_EXPIRED){
      authService.logoutUser();
    }
  }, [data, authService]);

  useEffect(() => {
    if(!loading && Config.envType === 'edge') {
      if(userManagementInfo?.getUserMgmtInfo?.result) {
        setIsUserManagementEnabled(userManagementInfo?.getUserMgmtInfo?.result?.enabled);
      }
    }
  }, [loading, userManagementInfo]);

  useEffect(() =>{
    if(Config.envType === 'edge') {
      fetchUser();
    }
  },[fetchUser]);

  const handleSignout = useCallback(async() => {
    try {
      await Auth.signOut();
      push('/login');
      localStorage.removeItem('username');
      TokenService.removeUser();
      window.location.reload();
    } catch {
      setMessage({ text: t('Logout failed. Please try again.'), id: 'register', type: 'danger' });
    }
  },[push, t]);

  const useOutsideAlerter = (ref: RefObject<HTMLDivElement>) => {
    useEffect(() => {
      function handleClickOutside(event:MouseEvent) {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          setLanguageIconToggle(false);
        }
      }
      // Bind the event listener
      document.addEventListener('click', handleClickOutside);
      return () => {
      // Unbind the event listener on clean up
        document.removeEventListener('click', handleClickOutside);
      };
    }, [ref]);
  };

  useOutsideAlerter(langWrapperRef);
  const onLanguageChange = (newSelect: string) => {
    setSelectedOption(newSelect);
    i18n.changeLanguage(newSelect);
  };

  const useOutSideClick = (ref: RefObject<HTMLDivElement>) => {
    useEffect(() => {
      function handleOutsideClick(event:MouseEvent) {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          setUserDrawer(false);
        }
      }
      document.addEventListener('click', handleOutsideClick);
      return () => {
        document.removeEventListener('click', handleOutsideClick);
      };
    }, [ref]);
  };

  useOutSideClick(userRef);
  const handleAlertIconClick = () =>{
    setUserDrawer((prev) => !prev);
  };

  return (
    <ContainerWrapper>
      {message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
      <Container>
        <HeaderTitle>
          <IconDiv>
            {typeof headerIcon === 'string' ? 
              <Icon icon={headerIcon} color='inverse' size={28} />:
              <>{headerIcon}</>
            }
          </IconDiv>
          <TitleContainer>
            {breadCrumbs && <BreadCrumbs breadCrumbs={breadCrumbs} />}
            <TitleWrapper>
              <Title title={headerTitle}>{headerTitle}</Title>
              { statusColor && <CameraStatusIndicator status={statusColor}></CameraStatusIndicator>}
              { editCameraAccess && cameraInfo?.instanceId && cameraInfo?.streamType && <EditIcon onClick={()=>push(`/cameras/${cameraInfo.instanceId}/configurations?selectedTab=basicConfigs&camera-name=${cameraInfo.instanceId}&camera-type=${cameraInfo.streamType}`)}>
                <Icon icon='Edit' />
              </EditIcon>}
            </TitleWrapper>
          </TitleContainer>
        </HeaderTitle>
        <Wrapper>
          {Config.fetchLatestAlertAPIEnable && <AlertDrawer />}
          <AllLanguagesWrapper ref={langWrapperRef}>
            <LanguageFilterWrapper>
              <LanguageIcon onClick={() => setLanguageIconToggle(!languageIconToggle)}>
                <GrLanguage size='21' />
              </LanguageIcon> 
            </LanguageFilterWrapper>
            <AllLanguages languageIconToggle={languageIconToggle}>
              <Arrow />
              {Config.supportedLanguages.map((option) => 
                <LanguageWrapper onClick={() => onLanguageChange(option.value)} isActive={selectedOption === option.value}>
                  <ActiveLanguageIndicator isActive={selectedOption === option.value} />
                  <LanguageOption>{option.label}</LanguageOption>
                </LanguageWrapper>)}
            </AllLanguages>
          </AllLanguagesWrapper>
          <UserDrawerWrapper ref={userRef}>
            <UserIcon onClick={handleAlertIconClick}>
              {userImage ? <UserPicture src={userImage} /> : <Icon icon={(TokenService.getUser()?.username === GUEST_USERNAME || !isUserManagementEnabled) ? 'Settings': 'User'} color='mono' size={24} />}
            </UserIcon>
            <UserDrawerContainer isOpen={userDrawer} >
              <UserContainer>
                <DrawerWrapper>
                  {(isUserManagementEnabled) && <CurrentUser>
                    <UserTitle>{t('Current User')}</UserTitle>
                    <UserName>{Config.envType === 'edge' ? (TokenService.getUser()?.username) ? (TokenService.getUser()?.username) : t('Default User') : localStorage.getItem('username')}</UserName>
                  </CurrentUser>}
                  {(isUserManagementEnabled && TokenService.getUser()?.username !== GUEST_USERNAME) && <MyProfileDiv onClick={() => push('/my-profile')}>{t('My Profile')}</MyProfileDiv>}
                  {isUserManagementEnabled && Config.envType === 'edge' && <LogoutDiv onClick={authService.logoutUser}>{t('Logout')}</LogoutDiv>}
                  {Config.envType === 'cloud' && <LogoutDiv onClick={handleSignout}>{t('Logout')}</LogoutDiv>}

                </DrawerWrapper>
                <AnnotationsContainer>
                  <AnnotationsWrapper>
                    <UserTitle>{t('Annotations')}</UserTitle>
                    <AnnotationToogle>
                      <UserName>{annotaionEnabled ? t('Enabled') : t('Disabled')}</UserName>
                      <Switch checked={annotaionEnabled} onChange={(event)=>updateAnnotationEnabled(event.target.checked)}/>
                    </AnnotationToogle>
                  </AnnotationsWrapper>
                  { !Config.isZoneMap &&<AnnotationsWrapper>
                    <UserTitle>{t('Offline Map')}</UserTitle>
                    <AnnotationToogle>
                      <UserName>{ isOfflineMap ? t('Enabled') : t('Disabled')}</UserName>
                      <Switch checked={isOfflineMap} onChange={(event)=>updateMapTile(event.target.checked)}/>
                    </AnnotationToogle>
                  </AnnotationsWrapper>}
                  <AnnotationToogle title={'Version - ' + RELEASE_VERSION}>
                    <MdNewReleases color='#737D81' />
                    <VersionDetails>{RELEASE_VERSION}</VersionDetails>
                  </AnnotationToogle>
                </AnnotationsContainer>
              </UserContainer>
            </UserDrawerContainer>
          </UserDrawerWrapper>
        </Wrapper>
      </Container>
    </ContainerWrapper>
  );
};

export default TopBar;