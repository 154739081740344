import Icon from 'components/Icon';
import { ALGORITHM_CODES, EdgeStatus, StatusColor, ZONE_LINEUI_CODES } from '../constants';
import React from 'react';
import SpeedDetectionSVG from 'svgs/SpeedDetectionSVG';
import TrafficJamSVG from 'svgs/TrafficJamSVG';
import TrafficPulseSVG from 'svgs/TrafficPulseSVG';
import RouteDeviationSVG from 'svgs/RouteDeviationSVG';
import StoppedVehicleSVG from 'svgs/StoppedVehicleSVG';
import OppositeDirectionSVG from 'svgs/OppositeDirectionSVG';
import NoEntryVehicleSVG from 'svgs/NoEntryVehicleSVG';
import Config from '../configs/config.json';
import ParkingViolationSVG from 'svgs/ParkingViolationSVG';
import VehicleIndentitySVG from 'svgs/VehicleIdentitySVG';
import { FiTwitter } from 'react-icons/fi';
import { BsWhatsapp } from 'react-icons/bs';
import { FaPeopleLine } from 'react-icons/fa6';
import { RiAlarmWarningLine } from 'react-icons/ri';
import { MdOutlineSms } from 'react-icons/md';
import { IVector2 } from 'components/LineUI';
import { GiTrafficLightsRed } from 'react-icons/gi';
import { TbChartAreaFilled, TbHelmetOff } from 'react-icons/tb';
import { IFloorPlanConfigs } from 'interface';
import { HiOutlineTicket } from 'react-icons/hi2';
import TripleSeatImg from 'svgs/tripleseat.png';
import styled from 'styled-components';

export const getStatusColor = (status: string) => {
  if(status === EdgeStatus.RUNNING) return StatusColor.RUNNING;
  if(status === EdgeStatus.RECOVERING) return StatusColor.RECOVERING;
  if(status === EdgeStatus.PAUSED) return StatusColor.PAUSED;
  if(status === EdgeStatus.DISABLED) return StatusColor.DISABLED;
  if(status === EdgeStatus.FAILED) return StatusColor.FAILED;
}; 

export const getStatusText = (status: string) => {
  if(status === EdgeStatus.RUNNING) return 'Running';
  if(status === EdgeStatus.RECOVERING) return 'Recovering';
  if(status === EdgeStatus.PAUSED) return 'Paused';
  if(status === EdgeStatus.DISABLED) return 'Disabled';
  if(status === EdgeStatus.FAILED) return 'Failed';
};

export const getAlgorithmName = (code: string) => {
  if(code === ALGORITHM_CODES.speed_detection) return 'Vehicle Speed Detection';
  if(code === ALGORITHM_CODES.stop_vehicle_detection) return 'Stopped Vehicle Detection';
  if(code === ALGORITHM_CODES.traffic_jam_detection) return 'Traffic Jam Detection';
  if(code === ALGORITHM_CODES.opposite_direction_detection) return 'Opposite Direction Detection';
  if(code === ALGORITHM_CODES.route_deviation_detection) return 'Route Deviation Detection';
  if(code === ALGORITHM_CODES.traffic_pulse_detection) return 'Traffic Pulse Detection';
  if(code === ALGORITHM_CODES.noentry_vehicle_detection) return 'No Entry Vehicle Detection';
  if(code === ALGORITHM_CODES.parking_violation_detection) return 'Parking Violation Detection';
  if(code === ALGORITHM_CODES.license_number_plate_detection) return 'License Number Plate Detection';
  if(code === ALGORITHM_CODES.face_analysis) return 'Face Analysis';
  if(code === ALGORITHM_CODES.face_recognition) return 'Face Recognition';
  if(code === ALGORITHM_CODES.red_light_violation) return 'Red Light Violation';
  if(code === ALGORITHM_CODES.no_helmet_detection) return 'No Helmet Detection';
  if(code === ALGORITHM_CODES.triple_seat_riding_detection) return 'Triple Seat Riding Detection';
  if(code === ALGORITHM_CODES.people_counting_and_classification) return 'People Counting';
  if(code === ALGORITHM_CODES.restricted_area_monitoring) return 'Restricted Area Monitoring';
};

export const getAlgorithmDescription = (code: string) => {
  if(code === ALGORITHM_CODES.speed_detection) return 'Speed detection is used to detect the speed of the vehicles in specified range and generate alert if the speed is crosses the specified threshold.';
  if(code === ALGORITHM_CODES.stop_vehicle_detection) return 'Identifies vehicles that are stopped on the road for a certain period of time.';
  if(code === ALGORITHM_CODES.traffic_jam_detection) return 'In this algorithm, you can define maximum number of vehicles to detect traffic jam.';
  if(code === ALGORITHM_CODES.opposite_direction_detection) return 'Identifies vehicles that are moving opposite direction within a lane.';
  if(code === ALGORITHM_CODES.route_deviation_detection) return 'Route deviation direction detection algorithm monitors vehicles for deviations from their intended routes.';
  if(code === ALGORITHM_CODES.traffic_pulse_detection) return 'Counting and classification of vehicles or pedestrians crossing a configured line in a specified direction.';
  if(code === ALGORITHM_CODES.noentry_vehicle_detection) return 'Algorithm detects and enforces a no-entry zone, preventing vehicle movement both from outside and within.';
  if(code === ALGORITHM_CODES.parking_violation_detection) return 'Identifies and detect vehicles those are parked at wrong places, enabling efficient enforcement of parking regulations.';
  if(code === ALGORITHM_CODES.license_number_plate_detection) return 'Identify and detect the vehicle\'s license number plate details';
  if(code === ALGORITHM_CODES.face_analysis) return 'Identifies and detects faces and face features.';
  if(code === ALGORITHM_CODES.face_recognition) return 'Detects and recognizes facial features accurately.';
  if(code === ALGORITHM_CODES.red_light_violation) return 'Identifies the vehicle that jump red light on signal.';
  if(code === ALGORITHM_CODES.no_helmet_detection) return 'Identifies the rider riding a bike without a helmet.';
  if(code === ALGORITHM_CODES.triple_seat_riding_detection) return 'Identifies the vehicle which has more than two riders.';
  if(code === ALGORITHM_CODES.people_counting_and_classification) return 'People count detects the number of people in a specified area and generates an alert if the count exceeds the specified threshold.';
  if(code === ALGORITHM_CODES.restricted_area_monitoring) return 'Restricted area monitoring detects individuals entering designated restricted areas and generates alerts.';
};

export const getLineUIColor = (code: string) => {
  if(code === ALGORITHM_CODES.speed_detection || code === ZONE_LINEUI_CODES[0]) return Config.lineUiColor.speed_detection;
  if(code === ALGORITHM_CODES.stop_vehicle_detection || code === ZONE_LINEUI_CODES[1]) return Config.lineUiColor.stop_vehicle_detection;
  if(code === ALGORITHM_CODES.traffic_jam_detection || code === ZONE_LINEUI_CODES[2]) return Config.lineUiColor.traffic_jam_detection;
  if(code === ALGORITHM_CODES.opposite_direction_detection || code === ZONE_LINEUI_CODES[3]) return Config.lineUiColor.opposite_direction_detection;
  if(code === ALGORITHM_CODES.route_deviation_detection || code === ZONE_LINEUI_CODES[4]) return Config.lineUiColor.route_deviation_detection;
  if(code === ALGORITHM_CODES.traffic_pulse_detection || code === ZONE_LINEUI_CODES[5]) return Config.lineUiColor.traffic_pulse_detection;
  if(code === ALGORITHM_CODES.noentry_vehicle_detection || code === ZONE_LINEUI_CODES[6]) return Config.lineUiColor.noentry_vehicle_detection;
  if(code === ALGORITHM_CODES.parking_violation_detection || code === ZONE_LINEUI_CODES[7]) return Config.lineUiColor.parking_violation_detection;
  if(code === ALGORITHM_CODES.license_number_plate_detection || code === ZONE_LINEUI_CODES[8]) return Config.lineUiColor.license_number_plate_detection;
  if(code === ALGORITHM_CODES.face_analysis || code === ZONE_LINEUI_CODES[9]) return Config.lineUiColor.face_analysis;
  if(code === ALGORITHM_CODES.red_light_violation ) return Config.lineUiColor.red_light_violation;
  if(code === ALGORITHM_CODES.no_helmet_detection ) return Config.lineUiColor.no_helmet_detection;
  if(code === ALGORITHM_CODES.triple_seat_riding_detection ) return Config.lineUiColor.triple_seat_riding_detection;
  if(code === ALGORITHM_CODES.restricted_area_monitoring ) return Config.lineUiColor.face_analysis;

};

export const getDirectionMarkColor = (color: string) => {
  if(color === 'primary') return 'hsla(203, 100%, 35%, 0.49);';
  if(color === 'secondary') return 'hsla(120, 98%, 46%, 0.49);';
  if(color === 'danger') return 'hsla(0, 89%, 51%, 0.49);';
  if(color === 'neutral') return 'hsla(33, 100%, 50%, 0.49);';
  if(color === 'info') return 'hsla(270, 100%, 50%, 0.49);';
  if(color === 'warning') return 'hsla(60, 100%, 50%, 0.49);';
  if(color === 'calm') return 'hsla(89.33, 58.33%, 64.90%, 0.49);';
  if(color === 'vibrant') return 'hsla(328, 100%, 47%, 0.49);';
  if(color === 'subtle') return 'hsla(28, 100%, 47%, 0.49);';
  if(color === 'dark') return 'hsla(227, 98%, 47%, 0.49);';
};

export const getFillAreaColor = (color: string) => {
  if(color === 'primary') return '#6BB5CD';
  if(color === 'secondary') return '#86d2a7';
  if(color === 'danger') return '#B79999';
  if(color === 'neutral') return '#CFBA94';
  if(color === 'info') return '#d1bcde';
  if(color === 'warning') return '#ece583';
  if(color === 'calm') return '#cbe995';
  if(color === 'vibrant') return '#cd617a'; 
  if(color === 'subtle') return '#c5907d'; 
  if(color === 'dark') return '#73619a'; 
};

export const pointInPolygon = (point: IVector2, restrictedPoints: {x:number,y: number}[]) => {
  let inside = false;
  for (let i = 0, j = restrictedPoints?.length - 1; i < restrictedPoints?.length; j = i++) {
    const xi = restrictedPoints[i]?.x;
    const yi = restrictedPoints[i]?.y;
    const xj = restrictedPoints[j]?.x;
    const yj = restrictedPoints[j]?.y;

    const intersect = ((yi > point.y) !== (yj > point.y)) &&
                      (point.x < (xj - xi) * (point.y - yi) / (yj - yi) + xi);
    if (intersect) {
      inside = !inside;
    }
  }
  return inside;
};

export const determineGroup = (algorithmCode: string, algoType: string): string => {
  const algoGroups = Config.AlgoGroup;

  const isAlgoGroupPresent = (groupCode: string): boolean => {
    return algoType?.split(/[_-]/)?.includes(groupCode);
  };

  const appendAlgoGroupCode = (group: string[], code: string, str: string, separator: string): string => {
    const containsSeparator = algoType?.includes('_') || algoType?.includes('-');
  
    if (group.includes(code)) {
      if (!isAlgoGroupPresent(str)) {
        if (containsSeparator) {
          algoType = `${algoType}${separator}${str}`;
        } else {
          algoType = algoType ? `${algoType}_${str}` : str;
        }
      }
    }
    return algoType;
  };

  algoType = appendAlgoGroupCode(algoGroups.TRAFFIC_ANOMALY_DETECTION.algo_list, algorithmCode, algoGroups.TRAFFIC_ANOMALY_DETECTION.code, '-');
  algoType = appendAlgoGroupCode(algoGroups.NUMBER_PLATE_DETECTION.algo_list, algorithmCode, algoGroups.NUMBER_PLATE_DETECTION.code, '-');
  algoType = appendAlgoGroupCode(algoGroups.NUMBER_PLATE_RECOGNITION.algo_list, algorithmCode, algoGroups.NUMBER_PLATE_RECOGNITION.code, '-');
  algoType = appendAlgoGroupCode(algoGroups.FACE_ENGINE_ANALYSIS.algo_list, algorithmCode, algoGroups.FACE_ENGINE_ANALYSIS.code, '-');
  algoType = appendAlgoGroupCode(algoGroups.FACE_ENGINE_DETECTION.algo_list, algorithmCode, algoGroups.FACE_ENGINE_DETECTION.code, '-');

  return algoType;
};

export const isValidImageURL = (base64URL: string) => {
  const img = new Image();
  return new Promise((resolve) => {
    img.onload = () => {
      resolve(true);
    };
    img.onerror = () => {
      resolve(false);
    };
    img.src = base64URL;
  });
};

// Function to compare two arrays of strings.
export const isEqualArrays = (arr1: string[], arr2: string[]) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true; 
};

export const allowOnlyNumbers = (event: { key: string; preventDefault: () => void; }) => {
  if (!/^[0-9]/.test(event.key)) {
    event.preventDefault();
  }
};

export const getQueryParams = () => {
  const queryParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(queryParams.entries());
};

export const toTitleCase = (str: string) => {
  return str
    .replace(/([A-Z])/g, ' $1') // Add space before uppercase letters
    .replace(/^./, (char) => char.toUpperCase()); // Capitalize the first letter
};

export const allowDegree = (event: React.KeyboardEvent<HTMLInputElement>) => {
  const inputValue = (event.target as HTMLInputElement).value + event.key;
  if (!/^\d+$/.test(inputValue) || parseInt(inputValue, 10) > 360) {
    event.preventDefault();
  }
};
export const allowOnlyNumbersWithDecimal = (event: { key: string; preventDefault: () => void; }) => {
  if (!/^[0-9.]$/.test(event.key)) {
    event.preventDefault();
  }
};

export const isNullOrEmpty = (value: string | null | undefined | number) => {
  return value === null || value === undefined || value.toString() === '' || Number.isNaN(value);
};

export const checkUniqueNames = (configurations: IFloorPlanConfigs[]) => {
  const encounteredNames = new Set<string>();
  const duplicates: string[] = [];

  for (const config of configurations) {
    if (!config.name || config.name.trim() === '') {
      return true;
    }

    if (encounteredNames.has(config.name)) {
      duplicates.push(config.name);
    } else {
      encounteredNames.add(config.name);
    }
  }

  return duplicates.length === 0;
};

export const getStatusName = (status: number) =>{
  switch(status){
  case 1: 
    return 'Active';
  case 2: 
    return 'Dismissed';
  case 3: 
    return 'Ignored';
  case 4: 
    return 'Expired';
  default:
    return '-';
  }
};

const TripleSeatIcon = styled.div<{size: number}>`
  width: ${({size}) => `${size}px`};
  height: ${({size}) => `${size}px`};
  border-radius: 50%;
  overflow: hidden;
  padding: 2px;
`;

const TripleSeatImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const getAlgoIcon = (algoCode: string, isSelected?: boolean, iconSize?: number, isConfigured?: boolean) => {
  const fill = isSelected ? '#FFFFFF' : isConfigured ? '#000000' : '#B2B1B1 ';
  const size = iconSize ? iconSize : 24;

  switch (algoCode) {
  case ALGORITHM_CODES.speed_detection:
    return <SpeedDetectionSVG color={fill} size={size} />;
  case ALGORITHM_CODES.traffic_jam_detection:
    return <TrafficJamSVG color={fill} size={size} />;
  case ALGORITHM_CODES.stop_vehicle_detection:
    return <StoppedVehicleSVG color={fill} size={size} />;
  case ALGORITHM_CODES.opposite_direction_detection:
    return <OppositeDirectionSVG color={fill} size={size} />;
  case ALGORITHM_CODES.route_deviation_detection:
    return <RouteDeviationSVG color={fill} size={size} />;
  case ALGORITHM_CODES.traffic_pulse_detection:
    return <TrafficPulseSVG color={fill} size={size} />;
  case ALGORITHM_CODES.noentry_vehicle_detection:
    return <NoEntryVehicleSVG color={fill} size={size} />;
  case ALGORITHM_CODES.parking_violation_detection:
    return <ParkingViolationSVG color={fill} size={size} />;
  case ALGORITHM_CODES.license_number_plate_detection:
    return <VehicleIndentitySVG color={fill} size={size} />;
  case ALGORITHM_CODES.face_analysis:
    return <Icon icon="Algorithm" size={size} />; //replace with Face Analysis icon
  case ALGORITHM_CODES.face_recognition:
    return <Icon icon="Algorithm" size={size} />; //replace with Face Recognition icon
  case ALGORITHM_CODES.red_light_violation:
    return <GiTrafficLightsRed size={size} color={fill}/>; //replace with Red Light Violation icon
  case ALGORITHM_CODES.no_helmet_detection:
    return <TbHelmetOff size={size} color={fill}/>; //replace with Red Light Violation icon: ;
  case ALGORITHM_CODES.triple_seat_riding_detection:
    return (
      <TripleSeatIcon size={size}>
        <TripleSeatImage src={TripleSeatImg} alt='' />; 
      </TripleSeatIcon>);
  case ALGORITHM_CODES.people_counting_and_classification:
    return <FaPeopleLine size={size} color={fill} />;
  case ALGORITHM_CODES.restricted_area_monitoring:
    return <TbChartAreaFilled size={size} color={fill} />;
  default:
    return <Icon icon="Algorithm" size={size} />;
  }
};

export const ImageCache: Record<string, string | undefined> = {};

export const cacheImage = (streamId: string, image: string) => {
  ImageCache[streamId] = image;
};

export const getCachedImg = (camId: string) => {
  return ImageCache[camId];
};

export const validInputRegex = /^[a-zA-Z0-9@_]*$/;
export const nameRegex = /^(?!^[0-9]*$)[a-zA-Z0-9\s@#_]+$/;
export const cameraNameRegex = '[^a-zA-Z0-9_.@\\- ]';
export const usernameRegex = /^[a-zA-Z0-9_.@-]+$/;
export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/;
export const phoneNumberRegex = /[^0-9]/g;
export const descriptionRegex = /[^\w\s.,!?@#$%^&*()_\-\\|"+/=<>]/g;
export const specialSymbol = /[!@#$%^&*(),.?":{}|<>]/;
export const IPv4_regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
export const MDNS_regex = /^(?!-)[A-Za-z0-9-]{1,63}(?<!-)\.local$/;

export function validateEmail(email: string) {
  /* eslint-disable */
  const emailRegex =/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(String(email).toLowerCase());
}

export const ActionIcons: Record<string, JSX.Element> = {
  'In-App Notification': <Icon icon='Bell' />,
  'Email': <Icon icon='EmailAlert' />,
  'In-App Sound': <Icon icon='InAppSound' />,
  'Twitter': <FiTwitter size={24} />,
  'WhatsApp': <BsWhatsapp size={24} />,
  'SMS': <MdOutlineSms size={24} />  ,
  'Relay Controller': <RiAlarmWarningLine size={24} />,
  'Challan' : <HiOutlineTicket size={24} />
};
