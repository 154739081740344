import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgDateTime = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M8.597 9.674v1.323M5.95 12.318v1.324M5.95 14.967v1.323M11.243 9.674v1.323M8.597 12.318v1.324M8.597 14.967v1.323M13.89 9.674v1.323M11.243 12.318v1.324M16.537 9.674v1.323M13.89 12.318v1.324M3 7.491h15.883M5.95 2v3.97M16.423 2v3.97M19.113 14.863V4.302H3v15.345h8.824"
        stroke={props.color}
        vectorEffect={"non-scaling-stroke"}
      />
      <path
        d="M20 17.73a4.34 4.34 0 1 1-8.68 0 4.34 4.34 0 0 1 8.68 0Z"
        stroke={props.color}
        vectorEffect={"non-scaling-stroke"}
      />
      <path
        d="M15.66 15.044v2.507a.431.431 0 0 1-.126.305l-1.792 1.792"
        stroke={props.color}
        strokeLinecap="round"
        vectorEffect={"non-scaling-stroke"}
      />
    </svg>
  );
};
export default SvgDateTime;
