import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { GET_ADV_CONFIG, GET_DEVICE_INFO, GET_USB_STREAM } from 'api_configs/queries';
import { useMutation, useQuery } from '@apollo/client';
import Button from 'components/Button';
import MP4Recording from './CameraComponents/MP4Recording';
import PeriodicSnapshot from './CameraComponents/PeriodicSnapshot';
import Label from 'components/Label';
import Switch from 'components/Switch';
import SelectField from 'components/SelectField';
import Title from 'components/Title';
import { useHistory } from 'react-router';
import { HLS_IP_URL, HLS_MDNS_URL, IconType, PERMISSION_CODE, RTSP_IP_URL, RTSP_MDNS_URL, StatusCode, StreamType, USB_VIDEO_SOURCE_FRAME_RATE, USB_VIDEO_SOURCE_RESOLUTION, VIDEO_FLIP_METHODS, WEB_RTC_IP_URL, WEB_RTC_MDNS_URL } from '../../constants';
import { PATCH_USB_CAMERA } from 'api_configs/mutations';
import { useTranslation } from 'react-i18next';
import { IAlertMessageType } from 'interface';
import AlertBar from 'components/AlertBar';
import MdnsIpInput from 'components/MdnsIpInput';
import { CircularProgress } from '@mui/material';
import ButtonWithLoading from 'components/ButtonWithLoading';
import TokenService from 'api_configs/tokenService';
const viewAdvancedConfigAccess = TokenService.hasAccess(PERMISSION_CODE.view_advanced_config);
const editAdvancedConfigAccess = TokenService.hasAccess(PERMISSION_CODE.edit_advanced_config);

const AdvancedConfigWrapper = styled.div<{isEditPermission: boolean}>`
  pointer-events: ${({isEditPermission}) => isEditPermission ? '': 'none'};
`;

const VideoConfigurationWrapper = styled.div`
  display: flex;
  width: 1060px;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 768px) {
    width: 530px;
  }
`;

const Heading = styled.div`
  font-size: 20px;
  font-weight: 700;
`;

const PeriodicSnapshotWrapping = styled.div``;

const HWAccelerationWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Configurations = styled.div`
  display: flex;
  gap: 150px;
`;

const SpinnerBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 89vh;
  align-items: center;
  justify-content: center;
`;

const VideoSourceProperties = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

const LeftSideWrapper = styled.div``;

const RightSideWrapper = styled.div``;

const SwitchWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 15px;
`;

const VerticalLine = styled.div`
  width: 1300px;
  height: 10px;
  border-bottom: 1px solid lightgrey;
  margin-top: 2px;
  margin-bottom: 32px;
  @media (max-width: 768px) {
    width: 450px;
  }
`;

const NoPermissionMessage = styled.div`
  width: 1040px;
  height: 800px;
  display: flex;
  padding-top: 160px;
  justify-content: center;
`;

const MessageWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const EndPointsWrapper = styled.div`
  width: 1060px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const MP4RecordingWrapping = styled.div``;

const ButtonsWrapper = styled.div`
  display: flex;
  width: 1060px;
  justify-content: center;
  gap: 20px;
  margin-top: 15px;
`;

interface IAdvancedConfig {
  streamType: string | undefined,
  instanceId: string | undefined
}

const AdvancedUSB = ({streamType, instanceId}: IAdvancedConfig) => {
  const [basicInfo, setBasicInfo] = useState({name: '', notes: '', usbDeviceName: '', instanceId: '', groupList: [], usbVideoSourceType: '', usbDeviceDescription: 'Description'});
  const [advancedUSB, setAdvancedUSB] = useState({rtspPort: '', hlsEnabled: false, rtspEnabled: false, services: {recorder: {enableHwAcceleration: false, videoFps: 'auto', rtspSourceBufferTime: '5', webrtcPort: '', maximumPacketDelay: 'auto', maximumPacketDelayType: 'default', recordingQuality: 'low', rtspSourceShortHeader: false, h264Profile: 'auto', enableMp4TimeOverlay: true, enableSaveMp4: true, videoWidth: '1280', videoHeight: '720', videoSplitMode: 'time', videoFileMaxSize: '52428100', videoFlipMethod: 'none', videoDuration: '', periodicSnapshot: true, enableSnapshotTimeOverlay: false, saveInterval: '600', specifySnapshotResolution: false, periodicSnapshotResolution: '1920x1080', saveOriginalSnapshot: false, videoPayloadType: 96, enableVideoMulticast: true, videoPortRtcp: '1063', pixelAspectRatio: 'auto', enableVideoRtcpMuxed: false, enableAudio: false, rtpAudioSourceType: 'acc', audioPayloadType: 103, enableAudioMulticast: false, audioMulticastAddress: '', audioPortRtp: '', enableAudioRtcpMuxed: false, audioPortRtcp: ''}}});
  const [currentResponse, setCurrentResponse] = useState({rtspPort: '', hlsEnabled: false, rtspEnabled: false, services: {recorder: {enableHwAcceleration: false, videoFps: 'auto', rtspSourceBufferTime: '5', webrtcPort: '', maximumPacketDelay: 'auto', maximumPacketDelayType: 'default', recordingQuality: 'low', rtspSourceShortHeader: false, h264Profile: 'auto', enableMp4TimeOverlay: true, enableSaveMp4: true, videoWidth: '1280', videoHeight: '720', videoSplitMode: 'time', videoFileMaxSize: '52428100', videoFlipMethod: 'none', videoDuration: '', periodicSnapshot: true, enableSnapshotTimeOverlay: false, saveInterval: '600', specifySnapshotResolution: false, periodicSnapshotResolution: '1920x1080', saveOriginalSnapshot: false, videoPayloadType: 96, enableVideoMulticast: true, videoPortRtcp: '1063', pixelAspectRatio: 'auto', enableVideoRtcpMuxed: false, enableAudio: false, rtpAudioSourceType: 'acc', audioPayloadType: 103, enableAudioMulticast: false, audioMulticastAddress: '', audioPortRtp: '', enableAudioRtcpMuxed: false, audioPortRtcp: ''}}});
  const {data: getRTSP, loading: getStreamLoading} = useQuery(GET_USB_STREAM, { variables: {streamId: instanceId} });
  const {t} = useTranslation(['common']);
  const [currentDeviceInfo, setCurrentDeviceInfo] = useState({deviceId: '', networkId: ''});
  const history = useHistory();
  const [pageLoading, setPageLoading] = useState(false);
  const {data: deviceInfo, loading: getDeviceInfoLoading} = useQuery(GET_DEVICE_INFO);
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: ''});  
  const { data: advancedConfigData, loading: getAdvancedConfigLoading, refetch } = useQuery(GET_ADV_CONFIG, { variables: {streamId: instanceId} });
  const [patchUSBStreamMutation] = useMutation(PATCH_USB_CAMERA);

  useEffect(() => {
    refetch();
    if(!getAdvancedConfigLoading && !getStreamLoading && !getDeviceInfoLoading) {
      if(advancedConfigData?.getAdvConfig?.result){
        setAdvancedUSB(advancedConfigData?.getAdvConfig?.result);
        setCurrentResponse(advancedConfigData?.getAdvConfig?.result);
        setBasicInfo(getRTSP?.getStream?.result);
        setCurrentDeviceInfo(prevState => ({
          ...prevState,
          deviceId: deviceInfo?.getDeviceInfo?.result.deviceId,
          networkId: deviceInfo?.getDeviceInfo?.result?.networks[0]?.ipAddress?.split('/')[0]
        }));
      }
    } 
  },[getAdvancedConfigLoading, advancedConfigData, deviceInfo, getStreamLoading, getRTSP, refetch, getDeviceInfoLoading]);

  const handleVideoSourceResolutionChange = (value:string) => {
    const videoRes = value.split('x');
    setAdvancedUSB(prevState => ({
      ...prevState,
      services: {
        ...prevState?.services,
        recorder: {
          ...prevState?.services?.recorder,
          videoWidth: videoRes[0],
          videoHeight: videoRes[1]
        }
      }
    }));
  };

  const handleSelectFieldChange = (value: string, id: string) => {
    setAdvancedUSB(prevState => ({
      ...prevState,
      services: {
        ...prevState?.services,
        recorder: {
          ...prevState?.services?.recorder,
          [id]: value
        }
      }
    }));  
  };

  const handleSwitchChange = (state: boolean, id: string) => {
    setAdvancedUSB(prevState => ({
      ...prevState,
      services: {
        ...prevState?.services,
        recorder: {
          ...prevState?.services?.recorder,
          [id]: !state
        }
      }
    }));
  };

  const handlePatchUSBStream = async () => {
    const advancedUSBPatchPayload = {name: basicInfo.name, notes: basicInfo.notes, streamType: StreamType.USB, groupList: basicInfo.groupList, streamId: basicInfo.instanceId, rtspEnabled: advancedUSB.rtspEnabled, hlsEnabled: advancedUSB.hlsEnabled, videoConfiguration: {enableHwAcceleration: advancedUSB.services.recorder.enableHwAcceleration, videoFps: advancedUSB.services.recorder.videoFps, videoFlipMethod: advancedUSB.services.recorder.videoFlipMethod, enableSaveMp4: advancedUSB.services.recorder.enableSaveMp4, enableMp4TimeOverlay: advancedUSB.services.recorder.enableMp4TimeOverlay, videoSplitMode: advancedUSB.services.recorder.videoSplitMode, videoDuration: advancedUSB.services.recorder.videoDuration, h264Profile: advancedUSB.services.recorder.h264Profile, recordingQuality: advancedUSB.services.recorder.recordingQuality, periodicSnapshot: advancedUSB.services.recorder.periodicSnapshot, enableSnapshotTimeOverlay: advancedUSB.services.recorder.enableSnapshotTimeOverlay, saveInterval: advancedUSB.services.recorder.saveInterval, specifySnapshotResolution: advancedUSB.services.recorder.specifySnapshotResolution, periodicSnapshotResolution: advancedUSB.services.recorder.periodicSnapshotResolution, videoWidth: advancedUSB.services.recorder.videoWidth, videoHeight: advancedUSB.services.recorder.videoHeight, saveOriginalSnapshot: advancedUSB.services.recorder.saveOriginalSnapshot, videoDevice: {usbDeviceName: basicInfo.usbDeviceName, usbVideoSourceType: basicInfo.usbVideoSourceType, usbDeviceDescription: basicInfo.usbDeviceDescription} }};
    try{
      setPageLoading(true);
      const data = await patchUSBStreamMutation({variables: {payload: advancedUSBPatchPayload}});
      if(data && data?.data?.patchStream?.statusCode === StatusCode.SUCCESS_200){
        setMessage({text: t('Data updated successfully'), id:'',type:'success'});
        refetch();
        setPageLoading(false);
      }else{
        setMessage({text: t('apiError'), id:'',type:'danger'});
        setPageLoading(false);
      }
    }catch(e){
      setMessage({text: t('apiError'), id:'',type:'danger'});
      console.error(e);
    }finally {
      setPageLoading(false);
    }
  };

  if(!viewAdvancedConfigAccess) {
    return <NoPermissionMessage>
      <MessageWrapper>{t('Unfortunately, you do not have permission to view advanced configurations at this time. Please contact your administrator for assistance.')}</MessageWrapper>
    </NoPermissionMessage>;
  }

  return (
    (getAdvancedConfigLoading) ? 
      <SpinnerBox>
        <CircularProgress />
        <Label labelText={t('Loading...')}/>
      </SpinnerBox> : 
      <AdvancedConfigWrapper isEditPermission={editAdvancedConfigAccess}>
        {
          message && <AlertBar message={message.text} type={message.type as IconType} setMessage={setMessage} />
        }
        <EndPointsWrapper>
          <Heading>{t('Endpoints')}</Heading>
          <VerticalLine />
          <MdnsIpInput defaultValue={advancedUSB?.hlsEnabled} name={t('HLS Enabled')} mdnsValue={advancedUSB?.hlsEnabled === false ? '' : HLS_MDNS_URL.replace('{deviceId}', currentDeviceInfo.deviceId).replace('{instanceId}', instanceId as string)} ipValue={advancedUSB?.hlsEnabled === false ? '' : HLS_IP_URL.replace('{networkId}', currentDeviceInfo.networkId).replace('{instanceId}', instanceId as string)} onEnabled={() => {setAdvancedUSB(prevState => ({...prevState, hlsEnabled: !advancedUSB?.hlsEnabled}));}} />
          <MdnsIpInput defaultValue={true} name={t('WebRTC Enabled')} mdnsValue={WEB_RTC_MDNS_URL.replace('{deviceId}', currentDeviceInfo.deviceId).replace('{webRTCPort}', advancedUSB?.services?.recorder?.webrtcPort)} ipValue={WEB_RTC_IP_URL.replace('{networkId}', currentDeviceInfo.networkId).replace('{webRTCPort}', advancedUSB?.services?.recorder?.webrtcPort)} />
          <MdnsIpInput defaultValue={advancedUSB?.rtspEnabled} name={t('RTSP Enabled')} mdnsValue={advancedConfigData?.getAdvConfig?.result?.rtspPort === '' ? t('Please save endpoints to generate URL') : advancedUSB?.rtspEnabled === false ? '' : RTSP_MDNS_URL.replace('{deviceId}', currentDeviceInfo.deviceId).replace('{rtspPort}', advancedUSB?.rtspPort).replace('{instanceId}', instanceId as string)} ipValue={advancedConfigData?.getAdvConfig?.result?.rtspPort === '' ? t('Please save endpoints to generate URL') : advancedUSB?.rtspEnabled === false ? '' : RTSP_IP_URL.replace('{networkId}', currentDeviceInfo.networkId).replace('{rtspPort}', advancedUSB.rtspPort).replace('{instanceId}', instanceId as string)} onEnabled={() => {setAdvancedUSB(prevState => ({...prevState, rtspEnabled: !advancedUSB?.rtspEnabled}));}} />
        </EndPointsWrapper>
        <VideoConfigurationWrapper>
          <Heading>{t('Video Configurations')}</Heading>
          <VerticalLine />
          <Configurations>
            <LeftSideWrapper>
              <HWAccelerationWrapper>
                <Title size='18px' text={t('HW Acceleration')} />
                <SwitchWrapper>
                  <Switch checked={advancedUSB?.services?.recorder?.enableHwAcceleration} onChange={() => handleSwitchChange(advancedUSB?.services?.recorder?.enableHwAcceleration, 'enableHwAcceleration')} />
                  <Label labelText={advancedUSB?.services?.recorder?.enableHwAcceleration ? t('Enabled'): t('Disabled')} />
                </SwitchWrapper>
              </HWAccelerationWrapper>
              <SectionWrapper>
                <Title size='18px' text={t('Video Source')} />
                <VideoSourceProperties>
                  <SelectField label={t('Resolution')} defaultValue={`${advancedUSB?.services?.recorder?.videoWidth}x${advancedUSB?.services?.recorder?.videoHeight}`} options={USB_VIDEO_SOURCE_RESOLUTION} onChange={handleVideoSourceResolutionChange} />
                  <SelectField label={t('Frame Rate')} defaultValue={advancedUSB?.services?.recorder?.videoFps} options={USB_VIDEO_SOURCE_FRAME_RATE} onChange={(value) => handleSelectFieldChange(value, 'videoFps')} />
                  <SelectField label={t('Video Flipping')} options={VIDEO_FLIP_METHODS} defaultValue={advancedUSB.services.recorder.videoFlipMethod} onChange={(value) => handleSelectFieldChange(value, 'videoFlipMethod')} />
                </VideoSourceProperties>
              </SectionWrapper>
            </LeftSideWrapper>
            <RightSideWrapper>
              <MP4RecordingWrapping>
                <MP4Recording streamType={streamType} recordingFields={advancedUSB} setRecordingFields={setAdvancedUSB} />
              </MP4RecordingWrapping>
              <PeriodicSnapshotWrapping>
                <PeriodicSnapshot streamType={streamType} snapshotFields={advancedUSB} setSnapshotFields={setAdvancedUSB} />
              </PeriodicSnapshotWrapping>
            </RightSideWrapper>
          </Configurations>
          <ButtonsWrapper>
            <ButtonWithLoading loading={pageLoading} variant='primary' disabled={(JSON.stringify(advancedUSB) === JSON.stringify(currentResponse)) || pageLoading || !editAdvancedConfigAccess} onClick={handlePatchUSBStream}>{t('Save')}</ButtonWithLoading>
            <Button variant='secondary' onClick={() => history.push('/')}>{t('Cancel')}</Button>
          </ButtonsWrapper>
        </VideoConfigurationWrapper>
      </AdvancedConfigWrapper>    
  );
};

export default AdvancedUSB;
