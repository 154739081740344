import { useMutation, useQuery } from '@apollo/client';
import { GET_ALL_ALGORITHMS, GET_ALL_OBJECTS, GET_ANALYSIS_CONFIGS, GET_EVENT_PIPELINES, GET_SCHEDULES_LIST } from 'api_configs/queries';
import { IAlertMessageType, IGetAllAlgorithmRes, INewAnalysisConfigsRes, IGetStream, IPoints, IAnomalyDetectionConfigs, ISpeedDetectionConfigs, IRouteDeviationLaneLines, ITrafficPulseDetectionConfigs, IRouteDeviationDetectionConfigs, IPipelineInfo, IPeopleCountingAndClassification, IRedLightViolationConfigs } from 'interface';
import React, { useState, useEffect, useReducer, useCallback } from 'react';
import styled from 'styled-components';
import Config from '../../configs/config.json';
import Icon from 'components/Icon';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Switch from 'components/Switch';
import LineViewer from 'components/LineViewer';
import { ALGORITHM_CODES, IconType, LineUIType, PERMISSION_CODE, StatusCode } from '../../constants';
import { IPointSet, LineReducer, LineSetContext } from 'components/LineUI';
import Button from 'components/Button';
import SelectField from 'components/SelectField';
import { ADD_ANALYSIS_CONFIGS, UPDATE_ANALYSIS_CONFIGS } from 'api_configs/mutations';
import AlertBar from 'components/AlertBar';
import lodash from 'lodash';
import CancelConfirmationModal from './CancelConfirmationModal';
import MultipleSelect from 'components/MultiSelectField';
import { ProductAnomalyRICHS, OppositeDirectionDetection, RouteDeviationDetection, TrafficPulseDetection, StopDetection, TrafficJamDetection, NoEntryVehicleDetection, SpeedDetection, AreaConfiguration, RedLightViolation, NoHelmetDetection } from './algorithm-config';
import { determineGroup, getAlgoIcon, getAlgorithmDescription, getAlgorithmName, getFillAreaColor, getLineUIColor } from 'utils/utils';
import ButtonWithLoading from 'components/ButtonWithLoading';
import TokenService from 'api_configs/tokenService';
import Modal from 'components/Modal';
import EventPipelineGraphicalView from 'pages/settings/EventPipelineGraphicalView';
import { useHistory } from 'react-router';
import PeopleCountingAndClassification from './algorithm-config/PeopleCountingAndClassification';
import RestrictedAreaMonitoring from './algorithm-config/RestrictedAreaMonitoring';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const AlgorithmsList = styled.div`
  display: flex;
  background-color: ${({theme})=>theme.backgroundColor};
  border-radius: 5px;
  border: 1px solid #F5F6F6;
  padding: 10px;
`;

const ConfigurationDetails = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #F5F5F5;
  border-radius: 5px;
  padding: 10px;
  gap: 20px;
  width: 1202px;
`;

const ConfigurationHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ConfigurationFieldsWrapper = styled.div`
  display: flex;
  gap: 30px;
`;

const Divider = styled.div`
  height: 1px;
  margin-top: 6px;
  background-color: ${({theme}) => theme.divider};
`;

const AdvancedConfigurationSection = styled.div`
  margin-left: 150px;
`;

const AlgorithmInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const AlgorithmName = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: #2E4EC4;
`;

const Algorithmdescription = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: ${({theme})=>theme.text.secondaryText};
`;

const AnalysisEnableSection = styled.div`
  display: flex;
  gap: 10px;
`;

const LineViewerWrapper = styled.div`
  width: 40vw;
  gap: 5px;
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 1366px) {
    width: 540px;
  }
  @media only screen and (min-width: 1920px){
    width: 550px;
  }
`;

const SelectedAlgorithmContainer = styled.div`
  border-radius: 5px;
`;

const AlgorithmContainer = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px 10px;
  background-color: ${({ isSelected }) => (isSelected ? '#7287D7' : '#F5F8FF')};
  position: relative;
  width: 100px;

  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60vh;
  gap: 10px;
`;

const Label = styled.div`
  font-size: 16px;
`;

const Loading = styled.span``;

const AdvancedHeading = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

const AdvanceConfigurations = styled.div`
  display: flex;
  gap: 30px;
  margin-top: 20px;
  >div{
    >label{
      margin-bottom: 0;
    }
    >select{
      width: 18vw;
      height: 35px;
      padding: 6px;
      margin-bottom: 6px;
      padding-right: 30px;
    }
    #arrow{
      top: 42px;
    }
  }
`;

const EventPipelineDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const Span = styled.span``;

const EventPipelineList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-left: -36px;
`;

const PipelineName = styled.li<{isSinglePipeline?: boolean}>`
  color: #2E4EC4;
  font-size: 14px;
  cursor: pointer;
  max-width: 540px;
  list-style-position: inside;
  margin-top: ${({isSinglePipeline}) => isSinglePipeline ? '8px' : ''};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;  
  :hover{
    text-decoration: underline;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
`;

const SuccessIcon = styled.div<{configured: boolean}>`
  position: absolute;
  top: 6px;
  right: 6px;
  width: 12px;
  height: 12px;
  background-color: ${({configured})=>configured ? '#03DA66': '#B2B1B1'};
  border-radius: 50%;
  display: flex;
  z-index: 9;
  align-items: center;
  justify-content: center;
  >div>svg>path{
    fill: white;
  }
`;

const AlgoIconDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border: 1px solid ${({theme}) => theme.divider};
  border-radius: 6px;
`;

const AlgoInfoContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

interface IStateValue {
  name: string,
  points: IPoints[],
  readOnly: boolean,
  styling: string,
  showSmallDirectionMark: boolean,
  showPointHandle: boolean
}

interface IAnalysisTabProps {
  streamDetails: IGetStream;
}

interface IOptions{
  name: string,
  value: string,
}

interface IDays{
  days: string[];
}

interface IRules{
  startTime: String;
  endTime: string;
  days: IDays[];
}

interface ISchedule {
  id?: number;
  name?: string;
  rules?: IRules[];
}

interface IAllObjects {
  id?: number;
  name?: string;
  description?: string;
  properties: string;
  images?: string[];
  defaultImage:string;
}

const AnalysisTab: React.FC<IAnalysisTabProps> = ({streamDetails}) => {
  const [algorithmList, setAlgorithmList] = useState<IGetAllAlgorithmRes[]>([]);
  const [selectedAlgorithm, setSelectedAlgorithm] = useState<IGetAllAlgorithmRes>();
  const { data: allAlgorithms, loading } = useQuery(GET_ALL_ALGORITHMS);
  const {t} = useTranslation(['common']);
  const [analysisConfigDetails, setAnalysisConfigDetails] = useState<INewAnalysisConfigsRes[]>([]);
  const [selectedAlgorithmConfigDetails, setSelectedAlgorithmConfigDetails] = useState<INewAnalysisConfigsRes>({algorithmCode: '', algoType: '', configs: [], enabled: true, scheduleId: -1, streamId: streamDetails?.instanceId});
  const [initialAlgorithmConfigDetails, setInitialAlgorithmConfigDetails] = useState<INewAnalysisConfigsRes>({algorithmCode: '', algoType: '', configs: [], enabled: true, scheduleId: -1, streamId: streamDetails?.instanceId});
  const { data: analysisConfigs, refetch } = useQuery(GET_ANALYSIS_CONFIGS,{ variables: {streamId: streamDetails?.instanceId} });
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [state, dispatch] = useReducer(LineReducer,[]);
  const [scheduleOptions, setScheduleOptions] = useState<IOptions[]>([]);
  const { data: scheduleList, refetch: refetchSchedules } = useQuery(GET_SCHEDULES_LIST);
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'});
  const [cancelModal, setCancelModal] = useState<boolean>(false);
  const { data } = useQuery(GET_ALL_OBJECTS);
  const [selectedObjects, setSelectedObjects] = useState<string[]>([]);
  const [selectedAlgoLineDetails, setSelectedAlgoLineDetails] = useState<IPointSet[]>([]);
  const [selectedAlgoConfigs, setSelectedAlgoConfigs] = useState<(IAnomalyDetectionConfigs | IRedLightViolationConfigs | ITrafficPulseDetectionConfigs | IPeopleCountingAndClassification | ISpeedDetectionConfigs | IRouteDeviationDetectionConfigs)[]>([]);
  const [objectData, setObjectData] = useState<{name: string, value: string}[]>([]);
  const [ updateAnalysisConfigMutation ] = useMutation(UPDATE_ANALYSIS_CONFIGS,{
    refetchQueries: [{ query: GET_ANALYSIS_CONFIGS }]
  });
  const [ addAnalysisConfigMutation ] = useMutation(ADD_ANALYSIS_CONFIGS,{
    refetchQueries: [{ query: GET_ANALYSIS_CONFIGS }]
  });
  const [algoConfigured, setAlgoConfigured] = useState<boolean>(true);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [algoType, setAlgoType] = useState('');
  const {data: getAllEventPipelines} = useQuery(GET_EVENT_PIPELINES,{
    fetchPolicy: 'network-only',
  });
  const [eventPipelineData, setEventPipelineData] = useState<IPipelineInfo[]>([]);
  const [applicableEventPipelines, setApplicableEventPipelines] = useState<IPipelineInfo[]>([]);
  const [isGraphicalView, setIsGraphicalView] = useState(false);
  const { replace } = useHistory();
  const [graphicalViewId, setGraphicalViewId] = useState<number>(0);
  const [validationMessage, setValidationMessage] = useState<string>('');
  const [isEditConfiguration, setIsEditConfiguration] = useState<boolean>(false);

  const editAnalysisAccess = TokenService.hasAccess(PERMISSION_CODE.edit_analysis_configuration);

  useEffect(()=>{
    if(getAllEventPipelines?.getEventPipelines?.result){
      setEventPipelineData(getAllEventPipelines?.getEventPipelines?.result);
    }
  },[getAllEventPipelines]);

  useEffect(()=>{
    const objectsList: IAllObjects[] = data?.getAllObjects?.result ? data?.getAllObjects?.result : [];
    if(objectsList?.length > 0){
      setObjectData(objectsList?.map((object)=>{
        return {name: object?.name as string, value: object?.id?.toString() as string};
      }));
    }
  },[data]);

  const handleSelectChange = useCallback((selectedValues: string[]) => {
    setSelectedObjects(selectedValues);
  },[]);

  useEffect(()=>{
    refetchSchedules();
    const schedules: ISchedule[] = scheduleList?.getSchedules?.result !== null ? scheduleList?.getSchedules?.result : [];
    if(schedules?.length > 0){
      const scheduleoptions: IOptions[] = schedules?.map((item)=> {
        return {name: item?.name as string, value: item?.id?.toString() as string};
      });
      setScheduleOptions(scheduleoptions);
    }
  },[scheduleList, refetchSchedules]);

  useEffect(()=>{
    // Setting Algorithm Data
    const allAlgorithmsData: IGetAllAlgorithmRes[] = allAlgorithms?.getAllAlgorithm?.result !== null ? allAlgorithms?.getAllAlgorithm?.result: [];
    if(allAlgorithmsData?.length > 0){
      const configuredAlgos = allAlgorithmsData?.filter((algorithm)=>{
        return Config.algorithmConfigurations.configuredAlgos.indexOf(algorithm.code) !== -1;
      });
      setAlgorithmList(configuredAlgos);
      if(configuredAlgos.length && algoConfigured) setSelectedAlgorithm(configuredAlgos[0]);
    }
    // Setting Analysis Configs Data
    const analysisConfigsData: INewAnalysisConfigsRes[] = analysisConfigs?.getAnalysisConfig?.result !== null ? analysisConfigs?.getAnalysisConfig?.result : [];
    setAnalysisConfigDetails(analysisConfigsData); 
  },[allAlgorithms, analysisConfigs, algoConfigured]);

  const getLineDataOfSelectedAlgorithm = useCallback((configs: (ITrafficPulseDetectionConfigs | IAnomalyDetectionConfigs | ISpeedDetectionConfigs | IRouteDeviationDetectionConfigs | IPeopleCountingAndClassification | IRedLightViolationConfigs )[]): IPointSet[]=>{
    const lineData: IPointSet[] = [];
    configs?.map((config)=>{
      if(config?.lineType === LineUIType.AREA){
        const areaDetails = {
          areaName: config.name,
          points: (config as IAnomalyDetectionConfigs)?.points as IPoints[],
          styling: config.styling,
          showMoveHandle: false,
          showPointHandle: false,
          showSmallDirectionMark: false,
          areaFillColor: getFillAreaColor(config.styling),
          areaTransparencyLevel: 40
        };
        lineData.push(areaDetails);
      }else if (config?.lineType === LineUIType.LINE){
        const lineDetails = {
          name: config.name,
          points: (config as ITrafficPulseDetectionConfigs)?.points as IPoints[],
          styling: config.styling,
          showMoveHandle: false,
          showPointHandle: false,
          showSmallDirectionMark: true,
          areaFillColor: getFillAreaColor(config.styling),
          areaTransparencyLevel: 40
        };
        lineData.push(lineDetails);
      } else if(config?.lineType === LineUIType.LINE_SET){
        const line1 = {
          name: config.name,
          points: (config as ISpeedDetectionConfigs).line_set?.line1 as IPoints[],
          styling: config.styling,
          showMoveHandle: false,
          showPointHandle: false,
          showSmallDirectionMark: true
        };
        const line2 = {
          name: config.name,
          points: (config as ISpeedDetectionConfigs).line_set?.line2 as IPoints[],
          styling: config.styling,
          showMoveHandle: false,
          showPointHandle: false,
          showSmallDirectionMark: true
        };
        lineData.push(line1);
        lineData.push(line2);
      } else if (config?.lineType === LineUIType.LINE_SET_ARRAY && 'line_set_array' in config) {
        if (config.line_set_array) {
          for (let i = 0; i < config.line_set_array.length; i++) {
            const line = {
              name: config.name,
              points: config.line_set_array[i]?.points as IPoints[] || [],
              styling: config.styling,
              showMoveHandle: false,
              showPointHandle: false,
              showSmallDirectionMark: false,
            };
            lineData.push(line);
          }
        }
      } else if (config?.lineType === LineUIType.LINE_AREA_SET && 'cross_line' in config) {
        const line = {
          name: config.name,
          points: (config as IRedLightViolationConfigs).cross_line as IPoints[],
          styling: config.styling,
          showMoveHandle: false,
          showPointHandle: false,
          showSmallDirectionMark: true
        };
        const area = {
          areaName: config.name,
          points: (config as IRedLightViolationConfigs).area as IPoints[],
          styling: config.styling,
          showMoveHandle: false,
          showPointHandle: false,
          showSmallDirectionMark: true
        };
        lineData.push(line);
        lineData.push(area);
      } 
      return null;
    });
    return lineData;
  },[]);

  useEffect(() => {
    if(analysisConfigs?.getAnalysisConfig?.result === null )
    {
      setAlgoType(streamDetails?.instanceId);
    }
    if (analysisConfigDetails?.length > 0) {
      let configs: INewAnalysisConfigsRes | null = null;
      const configList: INewAnalysisConfigsRes[] = [];

      for (let i = 0; i < analysisConfigDetails.length; i++) {
        const configItem = analysisConfigDetails[i];

        if (configItem?.configs?.length > 0) {
          configList.push({...configItem});
        }
      }

      const findFirstConfiguredAlgorithm = (algoList: IGetAllAlgorithmRes[], configList: INewAnalysisConfigsRes[]) => {
        for (const algorithmCode of algoList) {
          const foundAlgorithm = configList.find((algo: INewAnalysisConfigsRes) => algo.algorithmCode === algorithmCode.code as string);
          if (foundAlgorithm) {
            return foundAlgorithm;
          }
        }
        return null;
      };

      const firstConfiguredAlgorithm = findFirstConfiguredAlgorithm(algorithmList, configList);

      if (firstConfiguredAlgorithm) {
        configs = { ...firstConfiguredAlgorithm };
      } 

      if (configs !== null) {
        setAlgoType(configs.algoType);
        setSelectedAlgorithmConfigDetails((prev) => ({ ...prev, ...configs }));
        setInitialAlgorithmConfigDetails({ ...configs });
        if (algoConfigured) {
          setSelectedAlgorithm(
            algorithmList?.find((item) => item.code === configs?.algorithmCode)
          );
        }
        let defaultIndex = 0;
        if (configs.configs.length > 0) {
          defaultIndex = 1;
        }
        setSelectedIndex(defaultIndex);
        const lineData = getLineDataOfSelectedAlgorithm(configs.configs);
        setSelectedAlgoLineDetails(lineData);
        setSelectedAlgoConfigs(configs?.configs);
        dispatch({
          type: 'LOAD',
          state: lineData,
        });
      }
    }
  }, [analysisConfigDetails, getLineDataOfSelectedAlgorithm, algorithmList, algoConfigured, streamDetails?.instanceId, analysisConfigs]);

  useEffect(()=>{
    localStorage.setItem('line_data', JSON.stringify(state));
    setSelectedAlgoLineDetails(state);
    if(selectedIndex > 0) {
      setSelectedAlgoConfigs(prev => {
        const selectedAlgoConfigs = [...prev];
        const selectedLineConfig = {...selectedAlgoConfigs[selectedIndex -1]};
        if(selectedLineConfig && 'points' in selectedLineConfig){
          selectedLineConfig.points = state[selectedIndex - 1]?.points &&  [...state[selectedIndex - 1].points];
        }else if(selectedLineConfig && 'line_set' in selectedLineConfig){
          const line_set = selectedLineConfig.line_set;
          if(line_set){
            selectedLineConfig.line_set = {
              ...line_set,
              line1: state[(selectedIndex - 1)*2]?.points && [...state[(selectedIndex - 1)*2].points],
              line2: state[(selectedIndex - 1)*2 + 1]?.points && [...state[(selectedIndex - 1)*2 + 1].points]
            };
          }
        }
        else if (selectedLineConfig && 'line_set_array' in selectedLineConfig) {
          const line_set = selectedLineConfig.line_set_array;
          if (line_set) {
            const newLineSetArray: IRouteDeviationLaneLines[] = [];
            for (let i = 0; i < line_set.length; i++) {
              newLineSetArray.push({
                line_name: state[(selectedIndex - 1) * 2 + i]?.name ? state[(selectedIndex - 1) * 2 + i].name as string : '',
                points: state[(selectedIndex - 1) * 2 + i]?.points && [...state[(selectedIndex - 1) * 2 + i].points],
              });
            }
            selectedLineConfig.line_set_array = newLineSetArray;
          }
        }
        selectedAlgoConfigs[selectedIndex - 1] = selectedLineConfig;
        return selectedAlgoConfigs;
      });
    }
  },[state, selectedIndex]);
  
  useEffect(()=>{
    setSelectedAlgorithmConfigDetails(prev => ({...prev, configs: selectedAlgoConfigs}));
  },[selectedAlgoConfigs, getLineDataOfSelectedAlgorithm]);

  const addLine = useCallback((name: string, lineArray: IPoints[][], isLine: boolean, styling?:string, prevLength: number= -1, lineIndex?:number ) => {
    const state_value = localStorage.getItem('line_data');
    const stateValue = JSON.parse(state_value as string);
    const deselectLineIndex: number[] = stateValue?.map((item: IStateValue, index: number) => item.showPointHandle ? index : null).filter((index: number | null) => index !== null);
    const newLineData: IPointSet[] = [];
    deselectLineIndex.forEach((index)=>{
      dispatch({
        type: 'UPDATE_SET_OPTIONS',
        index: index,
        data: {
          showPointHandle: false,
          showMoveHandle: false
        }
      });
    });

    lineArray?.map((line)=>{
      const lineData = {
        name: isLine ? name : '',
        areaName: isLine ? '' : name,
        points: line,
        styling: styling ? styling : getLineUIColor(selectedAlgorithmConfigDetails.algorithmCode),
        showMoveHandle: true,
        showPointHandle: true,
        showSmallDirectionMark: true,
        areaFillColor: getFillAreaColor(styling ? styling : getLineUIColor( selectedAlgorithmConfigDetails.algorithmCode) as string),
        areaTransparencyLevel: 40
      };
      newLineData.push(lineData);
      return line;
    });

    setSelectedAlgoLineDetails(prev => [...prev, ...newLineData]);
    if(prevLength !== -1){
      newLineData.forEach((line)=>{
        dispatch({
          type: 'ADD_SET_ARRAY',
          index: prevLength + (lineIndex as number),
          data: line
        });
      });
    }else{
      newLineData.forEach((line)=>{
        dispatch({
          type: 'ADD_SET',
          data: line
        });
      });
    }
  },[selectedAlgorithmConfigDetails]);

  const onAddNewLine = useCallback((isLine: boolean, data: ITrafficPulseDetectionConfigs | IAnomalyDetectionConfigs | ISpeedDetectionConfigs | IRouteDeviationDetectionConfigs | IRedLightViolationConfigs | ITrafficPulseDetectionConfigs, currentLength: number) => {
    if ((data.lineType === LineUIType.AREA || data.lineType === LineUIType.LINE) && 'points' in data) {
      addLine(data.name, [data?.points] as IPoints[][], isLine, data.styling);
    } else if (data.lineType === LineUIType.LINE_SET && 'line_set' in data) {
      addLine(data.name, [data?.line_set?.line1, data?.line_set?.line2] as IPoints[][], isLine, data.styling);
    } else if (data.lineType === LineUIType.LINE_SET_ARRAY && 'line_set_array' in data) {
      data.line_set_array?.forEach((lineSet) => {
        if (lineSet?.points && lineSet.line_name) {
          addLine(lineSet.line_name, [lineSet?.points] as IPoints[][], isLine, data.styling);
        }
      });
    } else if(data.lineType === LineUIType.LINE_AREA_SET && 'cross_line' in data ) {
      addLine(data.name , [data?.cross_line] as IPoints[][], true, data.styling);
      addLine(data.name , [data?.area] as IPoints[][], false, data.styling);
    }
    setSelectedIndex(currentLength + 1);
    setSelectedAlgorithmConfigDetails(prev => {
      const algoConfigDetails = { ...prev };
      algoConfigDetails.configs = [...algoConfigDetails.configs, data];
      algoConfigDetails.algorithmCode = selectedAlgorithm?.code || '';
      return algoConfigDetails;
    });
  },[addLine, selectedAlgorithm]);

  const handleSaveConfigs = useCallback(async ()=>{
    setSaveLoading(true);
    const algo_Type = determineGroup(selectedAlgorithmConfigDetails.algorithmCode, algoType ? algoType : streamDetails?.instanceId);
    const objectIds = selectedObjects?.map(item => parseInt(item));
    let selectedAlgoConfigurations = selectedAlgoConfigs;
    if(selectedAlgorithm?.code === ALGORITHM_CODES.speed_detection){
      selectedAlgoConfigurations = selectedAlgoConfigs.map((item) => {
        const lineDetails = {...item};
        if('distance' in lineDetails){
          lineDetails.distance = parseFloat(lineDetails.distance as string);
        }
        return lineDetails;
      });
    }
    if(validationMessage !== ''){
      setMessage({text: t(validationMessage), id:'',type:'danger'});
    }else{
      if(isEditConfiguration) {
        const data = await updateAnalysisConfigMutation({variables:{payload: {streamId: streamDetails?.instanceId, algoType:algo_Type, algorithmCode: selectedAlgorithm?.code, configs: selectedAlgoConfigurations, objectIds: objectIds, scheduleId: selectedAlgorithmConfigDetails.scheduleId === -1 ? null : selectedAlgorithmConfigDetails.scheduleId, enabled: selectedAlgorithmConfigDetails.enabled, }}});
        if(data && data?.data?.updateAnalysisConfig?.statusCode === StatusCode.SUCCESS){
          setMessage({text: t('Configuration saved successfully'), id:'',type:'success'});
          refetch();
          setAlgoConfigured(false);
        }else{
          setMessage({text: t('Failed to save configuration. Please try again!'), id:'',type:'danger'});
        }
      }else{
        const data = await addAnalysisConfigMutation({variables:{payload: {streamId: streamDetails?.instanceId, algoType:algo_Type, algorithmCode: selectedAlgorithm?.code, configs: selectedAlgoConfigurations, objectIds: objectIds, scheduleId: selectedAlgorithmConfigDetails.scheduleId === -1 ? null : selectedAlgorithmConfigDetails.scheduleId, enabled: selectedAlgorithmConfigDetails.enabled, }}});
        if(data && data?.data?.updateAnalysisConfig?.statusCode === StatusCode.SUCCESS){
          setMessage({text: t('Configuration saved successfully'), id:'',type:'success'});
          refetch();
          setAlgoConfigured(false);
        }else{
          setMessage({text: t('Failed to save configuration. Please try again!'), id:'',type:'danger'});
        }
      }
    }
    setSaveLoading(false);
  },[selectedAlgorithmConfigDetails.algorithmCode, selectedAlgorithmConfigDetails.scheduleId, selectedAlgorithmConfigDetails.enabled, algoType, streamDetails?.instanceId, selectedObjects, selectedAlgoConfigs, selectedAlgorithm?.code, validationMessage, t, isEditConfiguration, updateAnalysisConfigMutation, refetch, addAnalysisConfigMutation]);

  const onLineClickCallback = useCallback((lineId: number, isSet?: boolean, prevLength: number= -1, length?:number) => {
    setSelectedIndex(lineId+1);
    if(editAnalysisAccess){
      const deselectLineIndex = state?.map((item: IPointSet, index: number) => item.showPointHandle ? index : null).filter((index: number | null) => index !== null);
      deselectLineIndex.length > 0 && deselectLineIndex.forEach((index)=>{
        dispatch({
          type: 'UPDATE_SET_OPTIONS',
          index: index as number,
          data: {
            showPointHandle: false,
            showMoveHandle: false
          }
        });
      });
      if(prevLength !== -1){
        for(let i=prevLength; i<prevLength + (length as number); i++){
          dispatch({
            type: 'UPDATE_SET_OPTIONS',
            index: i,
            data: {
              showPointHandle: true,
              showMoveHandle: true
            }
          });
        }
      }else{
        if(isSet){
          const indexArray = [lineId*2, lineId*2 + 1];
          indexArray.forEach((index)=>{
            dispatch({
              type: 'UPDATE_SET_OPTIONS',
              index: index,
              data: {
                showPointHandle: true,
                showMoveHandle: true
              }
            });
          });
        }else{
          dispatch({
            type: 'UPDATE_SET_OPTIONS',
            index: lineId,
            data: {
              showPointHandle: true,
              showMoveHandle: true
            }
          });
        }
      }
    }
  }, [state, editAnalysisAccess]);

  const handleRemoveLine = useCallback((index: number, length: number, isSet: boolean = false, prevLength: number = -1, lineIndex?:number)=>{
    let nextIndex = 0;
    if(length === 1 && index === 0){
      nextIndex = 0;
    }else if (length-1 === index){
      nextIndex = index;
    }else{
      nextIndex = index + 1;
    }
    setSelectedAlgoConfigs(prev => {
      return [...prev.filter((_,index2)=>index2 !== index)];
    });
    onLineClickCallback(nextIndex-1, isSet);
    setSelectedAlgorithmConfigDetails(prev => {
      const algoConfigDetails = {...prev};
      const algoConfigs = [...algoConfigDetails.configs.filter((_,index2)=>index2 !== index)];
      algoConfigDetails.configs = algoConfigs;
      return algoConfigDetails;
    });
    if(prevLength !== -1){
      dispatch({
        type: 'REMOVE_SET_ARRAY',
        index: prevLength,
        lineIndex: lineIndex as number,  
      }); 
    }else{
      if(isSet){ 
        const indexArray = [index*2, index*2 + 1];
        indexArray.forEach(()=>{
          dispatch({
            type: 'REMOVE_SET',
            index: index*2,
          });
        });
      }else{
        dispatch({
          type: 'REMOVE_SET',
          index: index,
        });
      }
    }
  },[onLineClickCallback]);

  const loadAnalysisConfigData = useCallback((algoCode: string) => {
    const algoData = analysisConfigDetails?.find(item => item.algorithmCode === algoCode);
    let index = 0;
    let lineData: IPointSet[] = [];
    if(algoData && algoData?.configs?.length > 0){
      index = 1;
      setSelectedAlgorithmConfigDetails(algoData as INewAnalysisConfigsRes);
      setSelectedAlgoConfigs(algoData?.configs);
      setInitialAlgorithmConfigDetails(algoData as INewAnalysisConfigsRes);
      lineData = getLineDataOfSelectedAlgorithm(algoData.configs);
      setSelectedAlgoLineDetails(lineData);
      setIsEditConfiguration(true);
    }else{
      setSelectedAlgoConfigs([]);
      setSelectedAlgoLineDetails([]);
      setSelectedAlgorithmConfigDetails(prev => ({...prev, configs: [], enabled: false, algorithmCode: algoCode, scheduleId: -1}));
      setInitialAlgorithmConfigDetails(prev => ({...prev, configs: [], enabled: false, algorithmCode: algoCode, scheduleId: -1}));
      setIsEditConfiguration(false);
    }
    setSelectedIndex(index);
    dispatch({
      type: 'LOAD',
      state: lineData
    });
  },[analysisConfigDetails, getLineDataOfSelectedAlgorithm]);

  const handleAlgorithmClick = useCallback((algorithm: IGetAllAlgorithmRes)=>{
    setSelectedAlgorithm(algorithm);
    const params = new URLSearchParams(window.location.search);
    params.set('selectedAlgorithm', algorithm.code);
    replace(`${window.location.pathname}?${params.toString()}`);
    loadAnalysisConfigData(algorithm.code);
  },[loadAnalysisConfigData, replace]);

  const fetchHistoryParams = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    const selectedAlgoCode = params.get('selectedAlgorithm') as string;
    const algorithmDetails = algorithmList.find(item => item.code === selectedAlgoCode);
    if(algorithmDetails){
      setSelectedAlgorithm(algorithmDetails);
      loadAnalysisConfigData(selectedAlgoCode);
    }
  },[algorithmList, loadAnalysisConfigData]);

  useEffect(() => {
    fetchHistoryParams();
  },[fetchHistoryParams, selectedAlgorithm]);

  useEffect(() =>{
    if(!algoConfigured){
      setSelectedAlgorithm(algorithmList?.find(item => item.code === selectedAlgorithm?.code));
      handleAlgorithmClick(selectedAlgorithm as IGetAllAlgorithmRes );
    }
  },[selectedAlgorithm, algoConfigured, algorithmList, handleAlgorithmClick]);

  const handleCancel = useCallback(()=>{
    if(initialAlgorithmConfigDetails?.configs.length !== 0){
      setCancelModal(prev => !prev);
    }else{
      setSelectedIndex(0);
      setSelectedAlgoConfigs([]);
    }
  },[initialAlgorithmConfigDetails]);

  const onCancelYes = useCallback((isEdited: boolean)=>{
    if(isEdited){
      setSelectedIndex(1);
      setCancelModal(prev => !prev);
      setSelectedAlgoConfigs(initialAlgorithmConfigDetails?.configs);

    }else{
      setCancelModal(prev => !prev);
    }
  },[initialAlgorithmConfigDetails]);

  const onCancelNo = useCallback(()=>{
    setCancelModal(prev => !prev);
  },[]);

  useEffect(() =>{
    const algoData = analysisConfigDetails?.find(item => item.algorithmCode === selectedAlgorithm?.code);
    if(selectedIndex === 0){
      setSelectedAlgorithmConfigDetails(prev => ({...prev, enabled: false, scheduleId: -1}));
    }else if(selectedIndex !== 0 && !(analysisConfigDetails?.findIndex((config) => config?.algorithmCode === selectedAlgorithm?.code && config.configs?.length > 0) !== -1)){
      setSelectedAlgorithmConfigDetails(prev => ({...prev, enabled: true}));
    }else if(selectedIndex !== 0 && algoData && algoData?.configs?.length > 0){
      setSelectedAlgorithmConfigDetails(algoData as INewAnalysisConfigsRes);
    }
  },[analysisConfigDetails, selectedIndex, selectedAlgorithm]); 

  const getApplicableEventPipelines = useCallback(() => {
    const applicableEventPipelines = eventPipelineData.filter(pipeline => {
      if(pipeline.algoCode === selectedAlgorithm?.code || pipeline.algoCode === ALGORITHM_CODES.all_algorithms){
        if('cameras' in pipeline.filters && pipeline.filters.cameras){
          return pipeline.filters.cameras.includes(streamDetails?.instanceId) || pipeline.filters.cameras.includes('ALL');
        }
        if('groups' in pipeline.filters && pipeline.filters.groups){
          return pipeline.filters.groups.some(pipeline => (streamDetails.groupList as number[])?.includes(pipeline as number));
        }
      }
      return false;
    });
    setApplicableEventPipelines(applicableEventPipelines);
  },[eventPipelineData, selectedAlgorithm, streamDetails]);

  useEffect(() => {
    getApplicableEventPipelines();
  },[getApplicableEventPipelines, selectedAlgorithm, streamDetails]);

  return (
    <>
      {
        loading ? 
          <LoadingContainer>
            <CircularProgress />
            <Loading>{t('Loading...')}</Loading>
          </LoadingContainer>:
          <>
            <Container>
              { message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
              {algorithmList?.length > 1 && <AlgorithmsList>
                {
                  algorithmList?.map((item)=>(
                    <AlgorithmContainer key={item?.code} title={t(item?.name)} onClick={()=>handleAlgorithmClick(item)} isSelected={selectedAlgorithm?.code === item?.code}>
                      <SuccessIcon configured={(selectedAlgorithmConfigDetails?.enabled && selectedAlgorithmConfigDetails?.algorithmCode === item.code) || (selectedAlgorithmConfigDetails?.algorithmCode !== item.code && analysisConfigDetails?.findIndex((config) => config?.algorithmCode === item.code && config.enabled=== true ) !== -1) ? true : false}>
                        <Icon icon="Success" size={12} />
                      </SuccessIcon>
                      {getAlgoIcon(item.code, selectedAlgorithm?.code === item?.code, 32, analysisConfigDetails?.findIndex(config => config.algorithmCode === item.code && config.configs?.length > 0) !== -1 ? true : false)}
                    </AlgorithmContainer>
                  ))
                }
              </AlgorithmsList>}
              <ConfigurationDetails>
                <ConfigurationHeaderContainer>
                  <AlgoInfoContainer>
                    <AlgoIconDiv>
                      {getAlgoIcon(selectedAlgorithm?.code as string, false, 30, true)}  
                    </AlgoIconDiv>
                    <AlgorithmInfo>
                      <AlgorithmName>{t(`${getAlgorithmName((selectedAlgorithm?.code) as string)}`)}</AlgorithmName>
                      <Algorithmdescription>{t(`${getAlgorithmDescription((selectedAlgorithm?.code) as string)}`)}</Algorithmdescription>
                    </AlgorithmInfo>
                  </AlgoInfoContainer>
                  <AnalysisEnableSection>
                    <Label>{selectedAlgorithmConfigDetails?.enabled ? t('Enabled') : t('Disabled')}</Label>
                    <Switch checked={selectedAlgorithmConfigDetails?.enabled} onChange={()=>setSelectedAlgorithmConfigDetails(prev => ({...prev, enabled: !prev.enabled}))} disabled={!editAnalysisAccess || selectedIndex === 0} />
                  </AnalysisEnableSection>
                </ConfigurationHeaderContainer>
                <ConfigurationFieldsWrapper>
                  <SelectedAlgorithmContainer>
                    {selectedAlgorithm?.code === ALGORITHM_CODES.rich_anomaly &&
                                                  <ProductAnomalyRICHS 
                                                    onAddNewLine={onAddNewLine}
                                                    setSelectedAlgoConfigs={setSelectedAlgoConfigs}
                                                    selectedAlgoConfigs={selectedAlgoConfigs}
                                                    handleRemoveLine={handleRemoveLine}
                                                    selectedIndex={selectedIndex}  
                                                    selectedAlgorithmConfigsLength={selectedAlgoConfigs.length}
                                                    dispatch={dispatch}
                                                    scheduleOptions={scheduleOptions}
                                                    selectedAlgorithmConfigDetails={selectedAlgorithmConfigDetails}
                                                    setSelectedAlgorithmConfigDetails={setSelectedAlgorithmConfigDetails}
                                                    onLineClickCallback={onLineClickCallback} />}
                    {selectedAlgorithm?.code === ALGORITHM_CODES.traffic_pulse_detection && 
                                                  <TrafficPulseDetection 
                                                    onAddNewLine={onAddNewLine}
                                                    setSelectedAlgoConfigs={setSelectedAlgoConfigs}
                                                    setValidationMessage={setValidationMessage}
                                                    selectedAlgoConfigs={selectedAlgoConfigs}
                                                    handleRemoveLine={handleRemoveLine}
                                                    selectedIndex={selectedIndex}  
                                                    selectedAlgorithmConfigsLength={selectedAlgoConfigs.length}
                                                    dispatch={dispatch}
                                                    scheduleOptions={scheduleOptions}
                                                    selectedAlgorithmConfigDetails={selectedAlgorithmConfigDetails}
                                                    setSelectedAlgorithmConfigDetails={setSelectedAlgorithmConfigDetails}
                                                    onLineClickCallback={onLineClickCallback} />}
                    {selectedAlgorithm?.code === ALGORITHM_CODES.people_counting_and_classification && 
                                                  <PeopleCountingAndClassification 
                                                    onAddNewLine={onAddNewLine}
                                                    setSelectedAlgoConfigs={setSelectedAlgoConfigs}
                                                    setValidationMessage={setValidationMessage}
                                                    selectedAlgoConfigs={selectedAlgoConfigs}
                                                    handleRemoveLine={handleRemoveLine}
                                                    selectedIndex={selectedIndex}  
                                                    selectedAlgorithmConfigsLength={selectedAlgoConfigs.length}
                                                    dispatch={dispatch}
                                                    scheduleOptions={scheduleOptions}
                                                    selectedAlgorithmConfigDetails={selectedAlgorithmConfigDetails}
                                                    setSelectedAlgorithmConfigDetails={setSelectedAlgorithmConfigDetails}
                                                    onLineClickCallback={onLineClickCallback} />}
                    {selectedAlgorithm?.code === ALGORITHM_CODES.restricted_area_monitoring &&
                                                  <RestrictedAreaMonitoring
                                                    onAddNewLine={onAddNewLine}
                                                    setSelectedAlgoConfigs={setSelectedAlgoConfigs}
                                                    setValidationMessage={setValidationMessage}
                                                    selectedAlgoConfigs={selectedAlgoConfigs}
                                                    handleRemoveLine={handleRemoveLine}
                                                    selectedIndex={selectedIndex}  
                                                    selectedAlgorithmConfigsLength={selectedAlgoConfigs.length}
                                                    dispatch={dispatch}
                                                    scheduleOptions={scheduleOptions}
                                                    selectedAlgorithmConfigDetails={selectedAlgorithmConfigDetails}
                                                    setSelectedAlgorithmConfigDetails={setSelectedAlgorithmConfigDetails}
                                                    onLineClickCallback={onLineClickCallback} />}
                    {selectedAlgorithm?.code === ALGORITHM_CODES.speed_detection &&
                                                  <SpeedDetection
                                                    onAddNewLine={onAddNewLine}
                                                    setSelectedAlgoConfigs={setSelectedAlgoConfigs}
                                                    setValidationMessage={setValidationMessage}
                                                    selectedAlgoConfigs={selectedAlgoConfigs}
                                                    handleRemoveLine={handleRemoveLine}
                                                    selectedIndex={selectedIndex}  
                                                    selectedAlgorithmConfigsLength={selectedAlgoConfigs.length}
                                                    dispatch={dispatch}
                                                    scheduleOptions={scheduleOptions}
                                                    selectedAlgorithmConfigDetails={selectedAlgorithmConfigDetails}
                                                    setSelectedAlgorithmConfigDetails={setSelectedAlgorithmConfigDetails}
                                                    onLineClickCallback={onLineClickCallback} />}
                    {selectedAlgorithm?.code === ALGORITHM_CODES.traffic_jam_detection &&
                                                  <TrafficJamDetection
                                                    onAddNewLine={onAddNewLine}
                                                    setSelectedAlgoConfigs={setSelectedAlgoConfigs}
                                                    setValidationMessage={setValidationMessage}
                                                    selectedAlgoConfigs={selectedAlgoConfigs}
                                                    handleRemoveLine={handleRemoveLine}
                                                    selectedIndex={selectedIndex}  
                                                    selectedAlgorithmConfigsLength={selectedAlgoConfigs.length}
                                                    dispatch={dispatch}
                                                    scheduleOptions={scheduleOptions}
                                                    selectedAlgorithmConfigDetails={selectedAlgorithmConfigDetails}
                                                    setSelectedAlgorithmConfigDetails={setSelectedAlgorithmConfigDetails}
                                                    onLineClickCallback={onLineClickCallback} />}
                    {selectedAlgorithm?.code === ALGORITHM_CODES.stop_vehicle_detection &&
                                                  <StopDetection
                                                    onAddNewLine={onAddNewLine}
                                                    setSelectedAlgoConfigs={setSelectedAlgoConfigs}
                                                    setValidationMessage={setValidationMessage}
                                                    selectedAlgoConfigs={selectedAlgoConfigs}
                                                    handleRemoveLine={handleRemoveLine}
                                                    selectedIndex={selectedIndex}  
                                                    selectedAlgorithmConfigsLength={selectedAlgoConfigs.length}
                                                    dispatch={dispatch}
                                                    scheduleOptions={scheduleOptions}
                                                    selectedAlgorithmConfigDetails={selectedAlgorithmConfigDetails}
                                                    setSelectedAlgorithmConfigDetails={setSelectedAlgorithmConfigDetails}
                                                    onLineClickCallback={onLineClickCallback} />}
                    {selectedAlgorithm?.code === ALGORITHM_CODES.opposite_direction_detection &&
                                                  <OppositeDirectionDetection
                                                    onAddNewLine={onAddNewLine}
                                                    setSelectedAlgoConfigs={setSelectedAlgoConfigs}
                                                    setValidationMessage={setValidationMessage}
                                                    selectedAlgoConfigs={selectedAlgoConfigs}
                                                    handleRemoveLine={handleRemoveLine}
                                                    selectedIndex={selectedIndex}  
                                                    selectedAlgorithmConfigsLength={selectedAlgoConfigs.length}
                                                    dispatch={dispatch}
                                                    scheduleOptions={scheduleOptions}
                                                    selectedAlgorithmConfigDetails={selectedAlgorithmConfigDetails}
                                                    setSelectedAlgorithmConfigDetails={setSelectedAlgorithmConfigDetails}
                                                    onLineClickCallback={onLineClickCallback} />}
                    {selectedAlgorithm?.code === ALGORITHM_CODES.route_deviation_detection &&
                                                  <RouteDeviationDetection
                                                    onAddNewLine={onAddNewLine}
                                                    setSelectedAlgoConfigs={setSelectedAlgoConfigs}
                                                    setValidationMessage={setValidationMessage}
                                                    selectedAlgoConfigs={selectedAlgoConfigs}
                                                    handleRemoveLine={handleRemoveLine}
                                                    selectedIndex={selectedIndex}  
                                                    selectedAlgorithmConfigsLength={selectedAlgoConfigs.length}
                                                    dispatch={dispatch}
                                                    addLine={addLine}
                                                    scheduleOptions={scheduleOptions}
                                                    selectedAlgorithmConfigDetails={selectedAlgorithmConfigDetails}
                                                    setSelectedAlgorithmConfigDetails={setSelectedAlgorithmConfigDetails}
                                                    onLineClickCallback={onLineClickCallback} />}
                    {selectedAlgorithm?.code === ALGORITHM_CODES.noentry_vehicle_detection &&
                                                  <NoEntryVehicleDetection
                                                    onAddNewLine={onAddNewLine}
                                                    setSelectedAlgoConfigs={setSelectedAlgoConfigs}
                                                    setValidationMessage={setValidationMessage}
                                                    selectedAlgoConfigs={selectedAlgoConfigs}
                                                    handleRemoveLine={handleRemoveLine}
                                                    selectedIndex={selectedIndex}  
                                                    selectedAlgorithmConfigsLength={selectedAlgoConfigs.length}
                                                    dispatch={dispatch}
                                                    scheduleOptions={scheduleOptions}
                                                    selectedAlgorithmConfigDetails={selectedAlgorithmConfigDetails}
                                                    setSelectedAlgorithmConfigDetails={setSelectedAlgorithmConfigDetails}
                                                    onLineClickCallback={onLineClickCallback} />}
                    {selectedAlgorithm?.code === ALGORITHM_CODES.parking_violation_detection &&
                                                  <AreaConfiguration
                                                    onAddNewLine={onAddNewLine}
                                                    setSelectedAlgoConfigs={setSelectedAlgoConfigs}
                                                    setValidationMessage={setValidationMessage}
                                                    selectedAlgoConfigs={selectedAlgoConfigs}
                                                    handleRemoveLine={handleRemoveLine}
                                                    selectedIndex={selectedIndex}  
                                                    selectedAlgorithmConfigsLength={selectedAlgoConfigs.length}
                                                    dispatch={dispatch}
                                                    scheduleOptions={scheduleOptions}
                                                    selectedAlgorithmConfigDetails={selectedAlgorithmConfigDetails}
                                                    setSelectedAlgorithmConfigDetails={setSelectedAlgorithmConfigDetails}
                                                    onLineClickCallback={onLineClickCallback} />}
                    {selectedAlgorithm?.code === ALGORITHM_CODES.license_number_plate_detection &&
                                                  <AreaConfiguration
                                                    onAddNewLine={onAddNewLine}
                                                    setSelectedAlgoConfigs={setSelectedAlgoConfigs}
                                                    setValidationMessage={setValidationMessage}
                                                    selectedAlgoConfigs={selectedAlgoConfigs}
                                                    handleRemoveLine={handleRemoveLine}
                                                    selectedIndex={selectedIndex}  
                                                    selectedAlgorithmConfigsLength={selectedAlgoConfigs.length}
                                                    dispatch={dispatch}
                                                    scheduleOptions={scheduleOptions}
                                                    selectedAlgorithmConfigDetails={selectedAlgorithmConfigDetails}
                                                    setSelectedAlgorithmConfigDetails={setSelectedAlgorithmConfigDetails}
                                                    onLineClickCallback={onLineClickCallback} />}
                    {selectedAlgorithm?.code === ALGORITHM_CODES.face_analysis &&
                                                  <AreaConfiguration
                                                    onAddNewLine={onAddNewLine}
                                                    setSelectedAlgoConfigs={setSelectedAlgoConfigs}
                                                    setValidationMessage={setValidationMessage}
                                                    selectedAlgoConfigs={selectedAlgoConfigs}
                                                    handleRemoveLine={handleRemoveLine}
                                                    selectedIndex={selectedIndex}  
                                                    selectedAlgorithmConfigsLength={selectedAlgoConfigs.length}
                                                    dispatch={dispatch}
                                                    scheduleOptions={scheduleOptions}
                                                    selectedAlgorithmConfigDetails={selectedAlgorithmConfigDetails}
                                                    setSelectedAlgorithmConfigDetails={setSelectedAlgorithmConfigDetails}
                                                    onLineClickCallback={onLineClickCallback} />}
                    {selectedAlgorithm?.code === ALGORITHM_CODES.face_recognition &&
                                                  <AreaConfiguration
                                                    onAddNewLine={onAddNewLine}
                                                    setSelectedAlgoConfigs={setSelectedAlgoConfigs}
                                                    setValidationMessage={setValidationMessage}
                                                    selectedAlgoConfigs={selectedAlgoConfigs}
                                                    handleRemoveLine={handleRemoveLine}
                                                    selectedIndex={selectedIndex}  
                                                    selectedAlgorithmConfigsLength={selectedAlgoConfigs.length}
                                                    dispatch={dispatch}
                                                    scheduleOptions={scheduleOptions}
                                                    selectedAlgorithmConfigDetails={selectedAlgorithmConfigDetails}
                                                    setSelectedAlgorithmConfigDetails={setSelectedAlgorithmConfigDetails}
                                                    onLineClickCallback={onLineClickCallback} />}
                    {selectedAlgorithm?.code === ALGORITHM_CODES.red_light_violation &&
                                                  <RedLightViolation
                                                    onAddNewLine={onAddNewLine}
                                                    setSelectedAlgoConfigs={setSelectedAlgoConfigs}
                                                    setValidationMessage={setValidationMessage}
                                                    selectedAlgoConfigs={selectedAlgoConfigs}
                                                    handleRemoveLine={handleRemoveLine}
                                                    selectedIndex={selectedIndex}  
                                                    selectedAlgorithmConfigsLength={selectedAlgoConfigs.length}
                                                    dispatch={dispatch}
                                                    scheduleOptions={scheduleOptions}
                                                    selectedAlgorithmConfigDetails={selectedAlgorithmConfigDetails}
                                                    setSelectedAlgorithmConfigDetails={setSelectedAlgorithmConfigDetails}
                                                    onLineClickCallback={onLineClickCallback} />}
                    {selectedAlgorithm?.code === ALGORITHM_CODES.no_helmet_detection &&
                                                  <NoHelmetDetection
                                                    onAddNewLine={onAddNewLine}
                                                    setSelectedAlgoConfigs={setSelectedAlgoConfigs}
                                                    selectedAlgoConfigs={selectedAlgoConfigs}
                                                    setValidationMessage={setValidationMessage}
                                                    handleRemoveLine={handleRemoveLine}
                                                    selectedIndex={selectedIndex}  
                                                    selectedAlgorithmConfigsLength={selectedAlgoConfigs.length}
                                                    dispatch={dispatch}
                                                    scheduleOptions={scheduleOptions}
                                                    selectedAlgorithmConfigDetails={selectedAlgorithmConfigDetails}
                                                    setSelectedAlgorithmConfigDetails={setSelectedAlgorithmConfigDetails}
                                                    onLineClickCallback={onLineClickCallback} />}
                    {selectedAlgorithm?.code === ALGORITHM_CODES.triple_seat_riding_detection &&
                                                  <AreaConfiguration
                                                    onAddNewLine={onAddNewLine}
                                                    setSelectedAlgoConfigs={setSelectedAlgoConfigs}
                                                    setValidationMessage={setValidationMessage}
                                                    selectedAlgoConfigs={selectedAlgoConfigs}
                                                    handleRemoveLine={handleRemoveLine}
                                                    selectedIndex={selectedIndex}  
                                                    selectedAlgorithmConfigsLength={selectedAlgoConfigs.length}
                                                    dispatch={dispatch}
                                                    scheduleOptions={scheduleOptions}
                                                    selectedAlgorithmConfigDetails={selectedAlgorithmConfigDetails}
                                                    setSelectedAlgorithmConfigDetails={setSelectedAlgorithmConfigDetails}
                                                    onLineClickCallback={onLineClickCallback} />}
                  </SelectedAlgorithmContainer>
                  <LineViewerWrapper>
                    <LineSetContext.Provider value={{ state, dispatch }}>
                      <LineViewer liveVideo streamName={streamDetails?.name} onLineClickCallback={selectedAlgoConfigs && (selectedAlgoConfigs[selectedIndex-1]?.lineType === LineUIType.LINE_SET || selectedAlgoConfigs[selectedIndex-1]?.lineType === LineUIType.LINE_SET_ARRAY || selectedAlgoConfigs[selectedIndex-1]?.lineType === LineUIType.LINE_AREA_SET) ? ()=>{} : onLineClickCallback} showDirectionMark={selectedAlgoConfigs && (selectedAlgoConfigs[selectedIndex-1]?.lineType === LineUIType.LINE_SET || selectedAlgoConfigs[selectedIndex-1]?.lineType === LineUIType.LINE)} streamID={streamDetails?.instanceId} width='100%' height='100%' state={selectedAlgoLineDetails} dispatch={dispatch} hasFullScreenToggle={true}/>
                    </LineSetContext.Provider>
                  
                  </LineViewerWrapper>
                  {isGraphicalView &&
                  <Modal isModalOpen={isGraphicalView} width='fit-content' closeModal={() => setIsGraphicalView(false)}>
                    <EventPipelineGraphicalView id={graphicalViewId} />
                  </Modal>
                  }
                </ConfigurationFieldsWrapper>
                {selectedIndex !== 0 ? selectedAlgorithm?.code !== ALGORITHM_CODES.traffic_pulse_detection &&
                  <>
                    <AdvancedConfigurationSection>
                      <AdvancedHeading>{t('Advanced')}</AdvancedHeading>
                      <Divider/>
                      <AdvanceConfigurations>
                        {Config.algorithmConfigurations.showSelectObjects && Config.menulist.findIndex(item => item.isVisible && item.label === 'Objects') !== -1 && <MultipleSelect
                          width='30vw'
                          placeholder={t('Objects')}
                          label={t('Objects')}
                          options={objectData}
                          selectedValues={selectedObjects}
                          onChange={handleSelectChange}
                        />}
                        {Config.algorithmConfigurations.showAnalysisSchedule && <SelectField
                          width='450px'
                          label={t('Schedule')}
                          options={scheduleOptions}
                          placeholder={t('Please select the schedule')}
                          defaultValue={(selectedAlgorithmConfigDetails?.scheduleId === -1 || selectedAlgorithmConfigDetails?.scheduleId === null) ? '' : selectedAlgorithmConfigDetails?.scheduleId}
                          onChange={(value)=>setSelectedAlgorithmConfigDetails(prev => ({...prev, scheduleId: parseInt(value)}))}
                          emptyListText={t('schedules not found')}
                        />}
                        <EventPipelineDetails>
                          <Span>{t('Event Pipelines')}</Span>
                          <EventPipelineList>
                            {applicableEventPipelines.map((pipeline) => (
                              <PipelineName isSinglePipeline={applicableEventPipelines.length === 1} onClick={() => {setGraphicalViewId(pipeline.id as number); setIsGraphicalView(true);}}>{pipeline.name}</PipelineName>
                            ))}
                          </EventPipelineList>
                        </EventPipelineDetails>
                      </AdvanceConfigurations>
                    </AdvancedConfigurationSection>
                  </>
                  :<></>
                }
                <ButtonsWrapper>
                  <ButtonWithLoading loading={saveLoading} disabled={lodash.isEqual(initialAlgorithmConfigDetails, selectedAlgorithmConfigDetails) || !editAnalysisAccess} variant='primary' type='submit' onClick={handleSaveConfigs}>{t('Save')}</ButtonWithLoading>
                  <Button disabled={lodash.isEqual(initialAlgorithmConfigDetails, selectedAlgorithmConfigDetails) || !editAnalysisAccess} variant='secondary' onClick={handleCancel}>{t('Cancel')}</Button>
                </ButtonsWrapper>
                {cancelModal && <CancelConfirmationModal modalOpen={cancelModal} setModalOpen={setCancelModal} onCancelYes={()=>onCancelYes(!lodash.isEqual(initialAlgorithmConfigDetails, selectedAlgorithmConfigDetails))} onCancelNo={onCancelNo} />}
              </ConfigurationDetails>
            </Container>
          </>
      }
    </>
  );
};

export default AnalysisTab;