import React from 'react';
import styled from 'styled-components';

interface RadioButtonProps {
  id: string;
  value: string | number;
  name: string;
  disabled?: boolean;
  currentChecked: string | number;
  onChangeCallback: (value: string) => void;
  isRequired?: boolean;
  checked?: boolean;
  label: string;
}

const RadioContainer = styled.label<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const RadioWrapper = styled.div``;

const RadioInput = styled.input.attrs({ type: 'radio' })`
  margin-right: 5px;
`;

const RadioLabel = styled.label`
  font-size: 16px;
  cursor: pointer;
`;

const RadioButton: React.FC<RadioButtonProps> = ({
  id,
  value,
  name,
  disabled = false,
  currentChecked,
  onChangeCallback,
  isRequired = false,
  checked = false,
  label
}) => {
  const handleChange = () => {
    if (value !== currentChecked) {
      onChangeCallback(value as string);
    }
  };

  return (
    <RadioContainer disabled={disabled}>
      <RadioWrapper onClick={handleChange}>
        <RadioInput
          id={id}
          value={value}
          name={name}
          checked={checked}
          disabled={disabled}
          required={isRequired}
        />
        <RadioLabel htmlFor={id}>{label}</RadioLabel>
      </RadioWrapper>
    </RadioContainer>
  );
};

export default RadioButton;
