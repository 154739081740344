import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgTrafficJam = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.308 21.418c.2.87 1.6 1.97 2.7 1.97h10.28c1.1 0 2.54-1.1 2.74-1.97.9 0 2.14-.2 2.27.4.13.77-1.2.57-1.94.8-.13.03-.27.1-.37.2-.77.77.63 1.43.63 2.37v2.57c0 .53-.53.97-1.23.97h-14.48c-.7 0-1.23-.43-1.23-.97v-2.57c0-.87 1.53-1.8.53-2.47-.2-.13-.4-.13-.67-.2-.3-.03-1.2-.1-1.37-.27-.17-.13-.37-.8.47-.83h1.67Zm3.1-2.5h9.48c.5 0 1.23 1.27 1.4 1.87.03.2.17.73.2.93-.23.1-.77.47-1.03.47h-10.58c-.3 0-.83-.37-1.03-.47.07-.8.4-1.7.93-2.34.13-.1.47-.47.63-.47v.01Zm-2.5 1.47c-.47-.23-1.63-.13-2.34-.13-.9 0-1.67.67-1.67 1.47 0 .7.03 1.07.67 1.53.53.37 1.13.23 1.47.4-.1.37-.53.57-.53 1.67s-.17 2.6.3 3.4c.13.27.2.17.23.5.1.77-.2 1.5.2 2.14.17.27.6.63 1 .63h3.04c.3 0 .67-.27.83-.43.6-.63.43-1.73-.3-1.6-.5.1-.43.43-.53.87-.53.13-2.5.13-3.04 0-.1-.37-.07-.6-.07-1.03.13.03.13.07.33.07h15.28c.2 0 .2-.03.37-.07 0 .43 0 .67-.07 1.03-.53.13-2.54.13-3.07 0-.1-.43-.03-.73-.47-.87-1.13-.3-.8 2.04.47 2.04h3.07c.37 0 .77-.33.93-.57.4-.53.2-1.33.23-2.14.03-.4.07-.23.23-.53.47-.77.33-2.34.33-3.44s-.47-1.3-.57-1.67c.2-.1.5-.1.77-.13 2.07-.37 1.77-3.27-.27-3.27-.73 0-1.87-.1-2.37.13-.27-.53-.43-1.03-.83-1.53-.2-.27-.97-1.03-1.4-1.03h-10.01c-.63 0-1.5 1.1-1.84 1.7-.17.3-.23.6-.4.87l.03-.01Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.44 14.352c-.53-.37-1.84-.2-2.84-.2-.73 0-1.6.7-1.6 1.67 0 .7.2 1.17.8 1.53.3.2 1.23.4 1.7.4-.3.47-.7.83-.7 1.6v3.14c0 .43.23.83.43 1.1.4.6-.2 2.04.4 2.8.17.23.6.6.97.6h3.27c1.43 0 1.8-2.14.83-2.14-.6 0-.57.5-.7.97-.53.13-2.84.13-3.4 0-.2-.43-.13-.73-.13-1.27.37 0 .37.07.77.07h3.67c.3 0 .63-.2.63-.53 0-.67-.6-.63-1.23-.63-.67 0-3.3.1-3.7-.13-.43-.23-.7-.63-.7-1.27v-2.37c0-1.13 1-1.43 1-2.2 0-.27-.2-.57-.4-.67-.63-.37-2.4-.03-2.4-.93 0-.13.1-.33.17-.4.23-.3 1.63-.17 2.27-.17.2 0 .23.13.33.3.57 1 1.77 1.87 2.87 1.87h5.64c.7 0 .93-1.07.07-1.2H6.89c-.5 0-.57-.03-.9-.2-.6-.3-.37-.33-.7-.43.07-.2.1-.2.17-.47.13-.67.17-.9.53-1.53s.83-1.1 1.6-1.1h9.44c.57 0 .83.07 1.13.33.53.5.93 1.23 1.1 1.97.1.43.07.9.63.9 1.53 0-.37-4.34-2.37-4.34H7.24c-1.43 0-2.54 1.84-2.8 2.94v-.01Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.192 8.278c.37-.27 1.27-.17 1.94-.17.5 0 1.1.5 1.1 1.17 0 .47-.13.8-.53 1.07-.23.13-.87.27-1.17.27.2.3.47.57.47 1.1v2.14c0 .3-.17.6-.3.77-.27.43.13 1.4-.27 1.94-.13.17-.43.4-.67.4h-2.27c-.97 0-1.23-1.47-.57-1.47.43 0 .4.37.47.67.4.1 1.97.1 2.37 0 .13-.27.1-.5.1-.87-.27 0-.27.07-.53.07h-2.54c-.2 0-.43-.13-.43-.4 0-.43.4-.43.87-.43.43 0 2.24.1 2.54-.07.3-.17.47-.43.47-.87v-1.63c0-.77-.67-1-.67-1.53 0-.17.13-.4.27-.47.43-.23 1.63 0 1.63-.63 0-.07-.07-.23-.1-.27-.17-.2-1.13-.1-1.57-.1-.13 0-.17.1-.2.2-.4.67-1.23 1.27-2 1.27h-3.87c-.47 0-.63-.73-.03-.8h3.8c.37 0 .4-.03.63-.17.4-.17.27-.2.47-.27-.03-.17-.03-.13-.1-.33-.1-.47-.13-.63-.37-1.07-.23-.4-.57-.77-1.1-.77h-6.51c-.37 0-.57.07-.77.27-.37.33-.63.83-.73 1.33-.1.3-.07.6-.47.6-1.07 0 .27-2.97 1.63-2.97h7.07c.97 0 1.73 1.27 1.94 2.04v-.02ZM23.892.003c.27-.03.53.17.53.47l.23 3.17c0 .27-.2.5-.47.53-.3 0-.53-.2-.53-.47l-.23-3.17c0-.3.2-.53.47-.53Zm6.74 4.37c.13.27 0 .57-.27.67l-2.94 1.17c-.27.1-.57-.03-.67-.27-.1-.27.03-.57.3-.67l2.94-1.17c.27-.1.53 0 .63.27h.01ZM7.009 5.909c-.3-.03-.53.2-.53.47l-.23 3.17c0 .27.2.5.47.53.27 0 .53-.2.53-.47l.23-3.17c0-.27-.2-.53-.47-.53Zm-6.78 4.37c-.1.27.03.57.3.67l2.94 1.17c.27.1.53 0 .63-.27.13-.27 0-.53-.27-.67l-2.94-1.17c-.27-.1-.57.03-.67.27h.01Z"
        fill={props.color}
      />
    </svg>
  );
};
export default SvgTrafficJam;
