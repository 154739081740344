import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import Icon from './Icon';
import { IMAGE_URL, SERVER_URL } from '../constants';
import AwaitingStreamEng from '../svgs/Awaiting_Stream-ENG.svg';
import { CiPlay1 } from 'react-icons/ci';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-height: 80vh;
  gap: 10px;
  overflow-y: auto;
`;

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 90vh;
  max-height: 70vh;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  @media (min-width: 1366px) and (max-width:1440px) {
    height: 61vh;
  }
`;

const Img = styled.img`
  aspect-ratio: 3/2;
  object-fit: contain;
  background-color: ${({theme})=>theme.backgroundColor};
  height: 100%;
  width: 100%;
  border-radius: 25px;
`;

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 15vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  background-color: ${({theme})=>theme.backgroundColor};
`;

const ImageSliderConatainer = styled.div`
  height: 8vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
`;

const StreamToggleButtonCSS = css`
  width: 42px;
  height: 84px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({theme})=>theme.backgroundColor};
  position: absolute;
  cursor: pointer;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
`;

const PreviousButton = styled.div`
  ${StreamToggleButtonCSS}
  height: 100%;
  left: 0;
`;

const NextButton = styled.div`
  ${StreamToggleButtonCSS}
  height: 100%;
  right: 0;
`;

const SwiperContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 90%;
`;

const CustomeSwiperSlide = styled(SwiperSlide)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImageThumbnail = styled.img<{isSelected:boolean, isDefaultImage: boolean}>`
  object-fit: contain;
  width: 135px;
  aspect-ratio: 3/2;
  overflow: hidden;
  border-radius: 5px;
  transform: scale(${({isSelected})=>isSelected ? '1': '0.8'});
  border: ${({isDefaultImage}) => isDefaultImage ? '2px solid #0d35d5': '2px solid lightgrey'};
  padding: 2px;
  cursor: pointer;
`;

interface RowData {
  image: {
    images: string[];
    id: string;
    defaultImage: string;
  };
}

interface IProps{
  rowData: RowData;
}

const ProductsFullScreenView: React.FC<IProps> = ({rowData}) => {
  const [streams, setStreams] = useState<String[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const swiperRef = useRef<SwiperRef>(null);
  const [imgSrc, setImgSrc] = useState('');
  const [isPlaying , setPlaying] = useState(false);
  const Images = rowData.image.images;
  const [thumbnailImage, setThumbnailImage] = useState(rowData.image.defaultImage);
  const imageId = rowData.image.id;
  const defaultImageId = rowData.image.images.indexOf(rowData.image.defaultImage);

  useEffect(()=>{
    setImgSrc(IMAGE_URL.replace('{X}', SERVER_URL).replace('{Y}', imageId).replace('{Z}', thumbnailImage));
  }, [thumbnailImage, imageId]);

  useEffect(()=>{
    setStreams(Images);
    setSelectedIndex(defaultImageId);
    setThumbnailImage(Images[defaultImageId]);
  },[Images, defaultImageId]);

  const handleSlidechange = () => {
    const currentIndex = swiperRef.current?.swiper.realIndex;  
    setSelectedIndex(currentIndex as number);
    setThumbnailImage(Images[Number(currentIndex)]);
  };

  useEffect(() => {
    swiperRef.current?.swiper.slideTo(selectedIndex);
  }, [selectedIndex]);

  useEffect(() => {
    let intervalId: NodeJS.Timer;
    const currentIndex = swiperRef.current?.swiper.realIndex;  
    if (isPlaying) {
      intervalId = setInterval(() => {
        setSelectedIndex((prevIndex) => (prevIndex + 1) % streams?.length);
        setThumbnailImage(Images[Number(currentIndex)]);
      },3000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isPlaying, streams, Images]);

  const onHandlePlayAndPauseStream = () =>{
    setPlaying(!isPlaying);
  };

  const handleThumbnailError = useCallback((e:React.SyntheticEvent<HTMLElement>)=>{
    (e.target as HTMLSourceElement).src = AwaitingStreamEng;
  },[]);

  return (
    <Container>
      <ImageContainer>
        <Img src={imgSrc} onError={handleThumbnailError} />
      </ImageContainer>
      {Images?.length > 1 && <IconWrapper onClick={onHandlePlayAndPauseStream}>
        {isPlaying ? <Icon icon='Pause' size={30} /> : <CiPlay1 size={30}/>}
      </IconWrapper>}
      <ImageWrapper>
        <ImageSliderConatainer>
          <PreviousButton onClick={()=>swiperRef.current?.swiper.slidePrev()}>
            <Icon icon='Left' size={30} weight='heavy' />
          </PreviousButton>
          <SwiperContainer>
            <Swiper
              ref={swiperRef}
              slidesPerView={streams.length > 5 ? 5 : streams.length}
              spaceBetween={0}
              centeredSlides={true}
              navigation={true}
              slideToClickedSlide={true} 
              onSlideChange={handleSlidechange}
            >
              {
                Images.map((stream,index)=>(
                  <CustomeSwiperSlide key={index} id='swiperSlide'>
                    <ImageThumbnail src={IMAGE_URL.replace('{X}', SERVER_URL).replace('{Y}', imageId).replace('{Z}', stream)} isSelected={index===selectedIndex} isDefaultImage={index === defaultImageId} onError={handleThumbnailError} />
                  </CustomeSwiperSlide>
                ))
              }
            </Swiper>
          </SwiperContainer>
          <NextButton onClick={()=>swiperRef.current?.swiper.slideNext()}>
            <Icon icon='Right' size={30} weight='heavy' />
          </NextButton>
        </ImageSliderConatainer>
      </ImageWrapper>
    </Container>
  );
};

export default ProductsFullScreenView;