import { useState, useCallback, useEffect } from 'react';
import { Auth } from 'aws-amplify';

export const useUsername = () => {
  const [username, setUsername] = useState<string>('');
  const [error, setError] = useState<string | null>(null);

  const fetchUsername = useCallback(async () => {
    const cachedUsername = localStorage.getItem('username');
    if (cachedUsername) {
      setUsername(cachedUsername);
      return;
    }

    const retries = 3;
    let delay = 1000;

    const retryFetch = async (attempt: number) => {
      try {
        const userInfo = await Auth.currentUserInfo();
        if (userInfo && userInfo.username) {
          setUsername(userInfo.username);
          localStorage.setItem('username', userInfo.username);
        }
      } catch (error: any) {
        if (error.name === 'TooManyRequestsException' && attempt < retries) {
          setTimeout(() => retryFetch(attempt + 1), delay);
          delay *= 2;
        } else {
          console.error('Failed to fetch username:', error);
          setError('Failed to fetch username. Please try again later.');
        }
      }
    };

    retryFetch(0);
  }, []);

  useEffect(() => {
    fetchUsername();
  }, [fetchUsername]);

  return { username, fetchUsername, error };
};
