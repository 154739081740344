import Icon from 'components/Icon';
import Input from 'components/Input';
import SelectField from 'components/SelectField';
import { ALGORITHM_CODES, DEFAULT_TRAFFIC_PULSE_LINE_POINTS, DIRECTION_OPTIONS, IconType, LineUIType, PERMISSION_CODE } from '../../../constants';
import { IAlertMessageType, INewAnalysisConfigsRes, IOption, IPeopleCountingAndClassification } from 'interface';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IReducerActions } from 'components/LineUI/LineReducer';
import Config from '../../../configs/config.json';
import AlertBar from 'components/AlertBar';
import { AddNewLineWrapper, DeleteIconDiv, HorizontalDivider, IconDiv, LabelWrapper, LineDetailContainer, LineNameWrapper, LinesContainer, LinesListContainer } from './algoConfigStyles';
import { checkUniqueNames, getLineUIColor, isNullOrEmpty } from 'utils/utils';
import TokenService from 'api_configs/tokenService';
import InputWithUnit from 'components/InputWithUnit';

const ConfigurationForm = styled.div`
  display: flex;
  gap: 20px;
`;

const NoLineData = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 100%;
  border-radius: 5px;
  border: 1px solid #D0D7F2;
  min-height: 310px;
  min-width: 600px;
`;

const AddNewLineButton = styled.div<{disabled:boolean}>`
  background: ${({disabled}) => disabled ? '#8E9FDF' : 'linear-gradient(to top, #1538BD, #7287D7)' } ;
  color: #FFFFFF;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: ${({disabled}) => disabled ? 'not-allowed' : 'pointer' } ;
`;

const NoLineText = styled.div`
  max-width: 60%;
  font-size: 14px;
  color: ${({theme})=>theme.text.secondaryText};
  text-align: center;
`;

const Label = styled.div`
  font-size: 16px;
`;

const LineName = styled.div`
  width: 70px;
  white-space: nowrap;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const InputsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
`;

interface ITrafficPulseDetectionAlgorithmProps{
  selectedAlgorithmConfigsLength: number;
  selectedIndex: number;
  onAddNewLine: (a: boolean, data: IPeopleCountingAndClassification, currentLength: number)=>void;
  handleRemoveLine?: (index: number, length: number)=>void;
  dispatch: React.Dispatch<IReducerActions>;
  onLineClickCallback?: (id: number) => void;
  setSelectedAlgoConfigs: React.Dispatch<React.SetStateAction<IPeopleCountingAndClassification[]>>;
  selectedAlgoConfigs: IPeopleCountingAndClassification[];
  scheduleOptions:IOption[];
  selectedAlgorithmConfigDetails: INewAnalysisConfigsRes;
  setSelectedAlgorithmConfigDetails: React.Dispatch<React.SetStateAction<INewAnalysisConfigsRes>>;
  setValidationMessage: React.Dispatch<React.SetStateAction<string>>;
}

const PeopleCountingAndClassification: React.FC<ITrafficPulseDetectionAlgorithmProps> = ({selectedAlgorithmConfigsLength,selectedAlgoConfigs, dispatch, setSelectedAlgoConfigs, setValidationMessage, selectedIndex, handleRemoveLine=()=>{}, onLineClickCallback=()=>{}, onAddNewLine}) => {
  const {t} = useTranslation(['common']);
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'});

  const editAnalysisAccess = TokenService.hasAccess(PERMISSION_CODE.edit_analysis_configuration);

  const AddNewLine = useCallback(() => {
    if(selectedAlgorithmConfigsLength + 1 > Config.algorithmConfigurations.linesLimit){
      setMessage({text: t('configurationLimitWarning').replace('{X}',`${Config.algorithmConfigurations.linesLimit}`), id:'',type:'danger'});
    }else{
      const newLineData: IPeopleCountingAndClassification = {
        name: 'New Line' + (selectedAlgorithmConfigsLength + 1),
        index: selectedAlgorithmConfigsLength + 1,
        lineType: LineUIType.LINE,
        styling: getLineUIColor(ALGORITHM_CODES.traffic_pulse_detection) as string,
        forward_label: '',
        backward_label: '',
        direction: 'automatic',
        points: DEFAULT_TRAFFIC_PULSE_LINE_POINTS.map(item => ({x: item.x , y: item.y + (selectedAlgorithmConfigsLength * 50)}))
      };
      onAddNewLine(true, {...newLineData}, selectedAlgorithmConfigsLength);
      setSelectedAlgoConfigs(prev => [...prev, newLineData]);
    }
  },[onAddNewLine, selectedAlgorithmConfigsLength, setSelectedAlgoConfigs, t]);

  const handleDirectionOptionChange = useCallback((value: string)=>{
    if(value === 'manual'){
      setSelectedAlgoConfigs((prev: IPeopleCountingAndClassification[]) => {
        const selectedAlgoConfigs = [...prev];
        const selectedLineConfig = JSON.parse(JSON.stringify(selectedAlgoConfigs[selectedIndex - 1])); 
        selectedLineConfig.direction = value;
        selectedAlgoConfigs[selectedIndex - 1] = selectedLineConfig;
        return selectedAlgoConfigs;
      });
    }else{
      setSelectedAlgoConfigs((prev: IPeopleCountingAndClassification[]) => {
        const selectedAlgoConfigs = [...prev];
        const selectedLineConfig = JSON.parse(JSON.stringify(selectedAlgoConfigs[selectedIndex - 1])); 
        selectedLineConfig.direction = 'automatic';
        selectedLineConfig.forward_label = '';
        selectedLineConfig.backward_label = '';
        selectedAlgoConfigs[selectedIndex - 1] = selectedLineConfig;
        return selectedAlgoConfigs;
      });
    }
  },[setSelectedAlgoConfigs, selectedIndex]);

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAlgoConfigs((prev: IPeopleCountingAndClassification[]) => {
      const selectedAlgoConfigs = [...prev];
      const selectedLineConfig = JSON.parse(JSON.stringify(selectedAlgoConfigs[selectedIndex - 1])); 
      selectedLineConfig.name = e.target.value;
      selectedAlgoConfigs[selectedIndex - 1] = selectedLineConfig;
      return selectedAlgoConfigs;
    });
    dispatch({
      type: 'RENAME_SET',
      index: selectedIndex - 1,
      data: {
        name: e.target.value
      }
    });
  },[setSelectedAlgoConfigs, selectedIndex, dispatch]);

  const handleDirectionChange = useCallback((e: React.ChangeEvent<HTMLInputElement>)=>{
    setSelectedAlgoConfigs((prev: IPeopleCountingAndClassification[]) => {
      const selectedAlgoConfigs = [...prev];
      const selectedLineConfig = JSON.parse(JSON.stringify(selectedAlgoConfigs[selectedIndex - 1])); 
      selectedLineConfig[e.target.name] = e.target.value;
      selectedAlgoConfigs[selectedIndex - 1] = selectedLineConfig;
      return selectedAlgoConfigs;
    });
  },[setSelectedAlgoConfigs, selectedIndex]);

  const handleThresholdChange = useCallback((e: React.ChangeEvent<HTMLInputElement>)=>{
    setSelectedAlgoConfigs((prev: IPeopleCountingAndClassification[]) => {
      const selectedAlgoConfigs = [...prev];
      const selectedLineConfig = JSON.parse(JSON.stringify(selectedAlgoConfigs[selectedIndex - 1])); 
      selectedLineConfig.confidence_threshold = e.target.value ? parseInt(e.target.value) : '';
      selectedAlgoConfigs[selectedIndex - 1] = selectedLineConfig;
      return selectedAlgoConfigs;
    });
  },[setSelectedAlgoConfigs, selectedIndex]);

  useEffect(() => {
    if (!checkUniqueNames(selectedAlgoConfigs)) {
      setValidationMessage('Duplicate Line Name values are not allowed');
    } else {
      let anyConditionTrue = false;

      for (const config of selectedAlgoConfigs) {
        if (isNullOrEmpty(config.name)) {
          setValidationMessage('Line Name should not be empty or null');
          anyConditionTrue = true;
          break;
        } else if (config.direction === 'manual') {
          if(isNullOrEmpty(config.forward_label)){
            setValidationMessage('Forward should not be empty or null');
            anyConditionTrue = true;
            break;
          }else if(isNullOrEmpty(config.backward_label)) {
            setValidationMessage('Backward should not be empty or null');
            anyConditionTrue = true;
            break;
          }
        }
      }

      if (!anyConditionTrue) {
        setValidationMessage('');
      }
    }
  }, [selectedAlgoConfigs, setValidationMessage]);

  return (
    <ConfigurationForm>
      { message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
      {selectedIndex === 0 ? 
        <NoLineData>
          <NoLineText>{t('No Lines have been configured yet, Please add New Line')}</NoLineText>
          <AddNewLineButton onClick={editAnalysisAccess ? AddNewLine : undefined} disabled={!editAnalysisAccess}>{t('Add Line')}</AddNewLineButton>
        </NoLineData>:
        <>
          <LinesContainer>
            <AddNewLineWrapper>
              <LabelWrapper>
                <Icon icon='Line' size={20} />
                <Label>{t('Lines')}</Label>
              </LabelWrapper>
              <IconDiv title={t('Add New Lines')} onClick={AddNewLine}>
                {editAnalysisAccess && <Icon icon='Plus' size={16} />}
              </IconDiv>
            </AddNewLineWrapper>
            {selectedIndex !== 0 &&
            <LinesListContainer>
              {(selectedAlgoConfigs as IPeopleCountingAndClassification[])?.map((line, index) => (
                <>
                  <LineNameWrapper isSelected={selectedIndex === index + 1} onClick={() => onLineClickCallback(index)}>
                    <div>{index + 1}.</div>
                    <LineName>{line?.name}</LineName>
                  </LineNameWrapper>
                  {index !== selectedAlgoConfigs.length - 1 && <HorizontalDivider />}
                </>
              ))}
            </LinesListContainer>}
          </LinesContainer>
          <LineDetailContainer>
            {editAnalysisAccess &&
            <DeleteIconDiv onClick={() => handleRemoveLine(selectedIndex - 1, selectedAlgorithmConfigsLength)}>
              <Icon icon='Delete' size={16} />
            </DeleteIconDiv>}
            <Input id='name' isRequired type='text' name='name' label={t('Line Name')} disabled={!editAnalysisAccess} value={selectedAlgoConfigs[selectedIndex - 1]?.name} onChange={handleInputChange} maxLength={16} />
            <SelectField
              label={t('Direction Detection')}
              options={DIRECTION_OPTIONS}
              defaultValue={selectedAlgoConfigs[selectedIndex - 1]?.direction}
              onChange={handleDirectionOptionChange} 
              disabled={!editAnalysisAccess}
              isRequired
            />
            <InputsContainer>
              <Input id='name' isRequired={selectedAlgoConfigs[selectedIndex - 1]?.direction === 'manual'} disabled={selectedAlgoConfigs[selectedIndex - 1]?.direction !== 'manual' || !editAnalysisAccess} type='text' name='forward_label' value={selectedAlgoConfigs[selectedIndex - 1]?.forward_label} label={t('Forward')} onChange={handleDirectionChange} maxLength={64} />
              <Input id='name' isRequired={selectedAlgoConfigs[selectedIndex - 1]?.direction === 'manual'} disabled={selectedAlgoConfigs[selectedIndex - 1]?.direction !== 'manual' || !editAnalysisAccess} type='text' name='backward_label' value={selectedAlgoConfigs[selectedIndex - 1]?.backward_label} label={t('Backward')} onChange={handleDirectionChange} maxLength={64} />
            </InputsContainer>
            <InputWithUnit
              label={('Person Threshold')}
              unit={t('persons')}
              id='threshold'
              type='text'
              name='threshold'
              value={selectedAlgoConfigs[selectedIndex - 1]?.confidence_threshold ? selectedAlgoConfigs[selectedIndex - 1]?.confidence_threshold : ''}
              onChange={handleThresholdChange}
              maxLength={4} />
          </LineDetailContainer>
        </>
      }
    </ConfigurationForm>
  );
};

export default PeopleCountingAndClassification;