import { gql } from '@apollo/client';

export const CREATE_NEW_GROUP = gql`
  mutation createGroupMutation($input:CreateGroupInput!) {
    createGroup(input: $input) {
      statusCode
      statusMessage
    }
  }
`;

export const DELETE_STREAM = gql`
  mutation deleteStreamMutation($streamId: String!) {
    deleteStream(streamId: $streamId) {
      statusCode
      statusMessage
    }
  }
`;

export const DELETE_GROUP = gql`
  mutation deleteGroupMutation($groupId: Int!) {
    deleteGroup(groupId: $groupId) {
      statusCode
      statusMessage
    }
  }
`;

export const EDIT_GROUP = gql`
  mutation editGroupMutation($payload: EditGroupInput!){
    editGroup(payload: $payload){
      statusCode
      statusMessage
    }
  }
`;

export const CREATE_NEW_CAMERA = gql`
  mutation addNewStreamMutation($input: StreamInput!) {
    putStream(payload: $input) {
      result
      statusCode
      statusMessage
    }
  }
`;

export const CREATE_NEW_OBJECT = gql`
  mutation createNewObjectMutation($payload: CreateObjectInput!) {
    createObject(payload: $payload) {
      result
      statusCode
      statusMessage
    }
  }
`;

export const EDIT_OBJECT = gql`
  mutation editObjectMutation($payload: EditObjectInput!) {
    editObject(payload: $payload) {
      result
      statusCode
      statusMessage
    }
  }
`;

export const DELETE_OBJECT = gql`
  mutation deleteObject($idList: [Int!]!) {
    deleteObjects(idList: $idList) {
      result
      statusCode
      statusMessage
    }
  }
`;

export const PATCH_RTSP_CAMERA = gql`
  mutation patchRTSPStreamMutation($payload: StreamInput!) {
    patchStream(payload: $payload) {
      statusCode
      statusMessage
    }
  }
`;

export const ADVANCED_PATCH_RTSP_CAMERA = gql`
  mutation patchAdvancedRTSPStreamMutation($payload: StreamInput!) {
    advPatchStream(payload: $payload) {
      statusCode
      statusMessage
    }
  }
`;

export const PATCH_USB_CAMERA = gql`
  mutation patchUSBStreamMutation($payload: StreamInput!) {
    patchStream(payload: $payload) {
      statusCode
      statusMessage
    }
  }
`;

export const PATCH_RTMP_CAMERA = gql`
  mutation patchRTMPStreamMutation($payload: StreamInput!) {
    patchStream(payload: $payload) {
      statusCode
      statusMessage
    }
  }
`;

export const PATCH_HLS_CAMERA = gql`
  mutation patchHLSStreamMutation($payload: StreamInput!) {
    patchStream(payload: $payload) {
      statusCode
      statusMessage
    }
  }
`;

export const PATCH_RTP_CAMERA = gql`
  mutation patchRTPStreamMutation($payload: StreamInput!) {
    patchStream(payload: $payload) {
      statusCode
      statusMessage
    }
  }  
`;

export const UPDATE_STREAM_TO_GROUP = gql`
  mutation streamToGroupsMutation($payload: UpdateStreamToGroupInput!) {
    updateStreamToGroup(payload: $payload) {
      statusCode
      statusMessage
    }
  }
`;

export const STREAM_TOGGLE = gql`
  mutation streamToggleMutation($payload: CameraActionInput!) {
    postStream(payload: $payload) {
      statusCode
      statusMessage
    }
  }
`;

export const SAVE_EMAIL_CLIENT = gql`
  mutation addEmailClient($input: AddEmailClientInput!){
    addEmailClient(input: $input){
      statusCode
      statusMessage
      result
    }
  }
`;

export const DELETE_EMAIL_CLIENT = gql`
 mutation deleteEmailClient($emailClientId: String!){
  deleteEmailClient(emailClientId: $emailClientId){
    result
    statusCode
    statusMessage
  }
 }
`;

export const UPDATE_EMAIL_CLIENT = gql`
  mutation editEmailClient($payload: EditEmailClientInput!){
    editEmailClient(payload: $payload){
      statusCode
      statusMessage
      result
    }
  }
`;

export const CREATE_SCHEDULE = gql`
  mutation createSchedule($payload: ScheduleInput!){
    createSchedule(payload: $payload){
      statusCode
      statusMessage
    }
  }
`;

export const EDIT_SCHEDULE = gql`
  mutation updateSchedule($scheduleId: Int!, $payload: ScheduleInput!){
    updateSchedule(scheduleId: $scheduleId, payload: $payload){
      statusCode
      statusMessage
    }
  }
`;

export const DELETE_SCHEDULE = gql`
  mutation deleteSchedules($scheduleIds: [Int!]!){
    deleteSchedules(scheduleIds: $scheduleIds){
      statusCode
      statusMessage
    }
  }
`;

export const UPDATE_ANALYSIS_CONFIGS = gql`
  mutation updateAnalysisConfigMutation($payload: UpdateAnalysisConfigInput!) {
    updateAnalysisConfig(payload: $payload){
      result
      statusCode
      statusMessage
    }
  }
`;

export const ADD_ANALYSIS_CONFIGS = gql`
  mutation addAnalysisConfig($payload: UpdateAnalysisConfigInput!) {
    updateAnalysisConfig(payload: $payload){
      result
      statusCode
      statusMessage
    }
  }
`;

export const DISMISS_ALL_ALERTS  = gql`
  mutation dismissAllAlert($dismissAll: Boolean!) {
    dismissAllAlert(dismissAll: $dismissAll) {
        count
        statusCode
        statusMessage
        result
      }
    }
`;

export const SAVE_EVENT_PIPELINE = gql`
  mutation createEventPipeline($payload: EventPipelineInput!) {
    createEventPipeline(payload: $payload){
      statusCode
      statusMessage
    }
  }
`;

export const DELETE_EVENT_PIPELINE = gql`
  mutation deleteEventPipeline($epIds: [Int!]!){
    deleteEventPipeline(epIds: $epIds){
      statusCode
      statusMessage
    }
  }
`;

export const UPDATE_EVENT_PIPELINE = gql`
  mutation updateEventPipeline($payload: UpdateEventPipelineInput!) {
    updateEventPipeline(payload: $payload){
      statusCode
      statusMessage
    }
  }
`;

export const IMAGE_UPLOAD = gql`
  mutation imageUpload($payload: ImageUploadInput!) {
    imageUpload(payload: $payload){
      result
      statusCode
      statusMessage
    }
  } 
`;

export const ASSIGN_ALERT_SUPPRESION_SCHEDULE = gql`
  mutation assignAlertSuppressionSchedule($streamId: String!, $suppressionSchedules: [SuppressionSchedules!]!){
    assignAlertSuppressionSchedule(streamId: $streamId, suppressionSchedules: $suppressionSchedules){
      result
      statusCode
      statusMessage
    }
  }
`;

export const VALIDATE_EMAIL_CLIENT = gql`
  mutation validateEmailClient($payload: ValidateEmailClientInput!){
    validateEmailClient(payload: $payload){
      statusCode
      statusMessage
    }
  }
`;

export const SAVE_TEST_EMAIL = gql`
  mutation sendTestEmail($payload: SendTestEmailInput!){
    sendTestEmail(payload: $payload){
      statusCode
      statusMessage
    }
  }
`;

export const SAVE_ALERT_AUDIO_SETTING = gql`
  mutation putAlertAudio($payload: SettingInput!){
    putAlertAudio(payload: $payload){
      statusCode
      statusMessage
    }
  }
`;

export const SAVE_EXPORT_SETTING = gql`
  mutation putExportSetting($payload: SettingInput!){
    putExportSetting(payload: $payload){
      statusCode
      statusMessage
    }
  }
`;

export const SAVE_INTEGRATION_CONFIG = gql`
  mutation putIntegrationSetting($payload: SettingInput!){
    putIntegrationSetting(payload: $payload){
      statusCode
      statusMessage
    }
  }
`;

export const UPDATE_GEO_LOCATION = gql`
  mutation updateGeolocation($payload: GeolocationInput!){
    updateGeolocation(payload: $payload){
      statusCode
      statusMessage
    }
  }
`;

export const LOGOUT = gql`
  mutation logout($accessToken: String!){
    logout(accessToken: $accessToken){
      statusCode
      statusMessage
      result 
    }
  }
`;

export const UPDATE_ALERT_STATUS = gql`
  mutation updateAlertStatus($payload: AlertStatusInput!){
    updateAlertsStatus(payload: $payload){
      result
      statusCode
      statusMessage
    }
  }
`;

export const LOGIN = gql`
  mutation login($payload: LoginInput!){
    login(payload: $payload){
      statusCode
      statusMessage
      result {
        id
        accessToken
        refreshToken
        roleId
        tokenType
        username
        permissions
      }
    }
  }
`;

export const DELETE_ALERTS = gql`
  mutation deleteAlerts($alertIdList: [Int!]!){
    deleteAlerts(alertIdList: $alertIdList){
      statusCode
      statusMessage
    }
  }
`;

export const SUPERSET_DASHBOARD_PAGE_MUTATION = gql`
  mutation storeChartsInfo($payload: DisplayChartsInput!){
    storeChartsInfo(payload: $payload) {
      statusCode
      statusMessage
    }
  }
`;

export const GRAFANA_DASHBOARD_PAGE_MUTATION = gql`
  mutation storeGrafanaChartsInfo($payload: DisplayGarafanaChartsInput!){
    storeGrafanaChartsInfo(payload: $payload) {
      statusCode
      statusMessage
    }
  }
`;

export const REFESHTOKEN = gql`
  mutation refreshToken($refreshToken: String!){
    refreshToken(refreshToken: $refreshToken){
      result
      statusCode
      statusMessage
    }
  }
`;

export const SAVE_WHATSAPP_NUMBERS = gql`
  mutation updateWhatsappDetails($payload: UpdateWhatsappDetailsInput!){
    updateWhatsappDetails(payload: $payload) {
      result
      statusCode
      statusMessage
    }
  }
`;

export const DELETE_WHATSAPP_NUMBERS = gql`
  mutation deleteWhatsappDetails($payload: DeleteWhatsAppNumbers!){
    deleteWhatsappDetails(payload: $payload) {
      result
      statusCode
      statusMessage
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation updateUserPassword($input: PasswdInput!){
    updateUserPassword(input: $input){
      result
      statusCode
      statusMessage
    }
  }
`;

export const IMPORT_SYSTEM_CONFIGURATION = gql`
  mutation importSystemConfiguration($payload: ImportSysConfigInput!){
    importSystemConfiguration(payload: $payload){
      result { response }
      statusCode
      statusMessage
    }
  }
`;

export const UPDATE_USER_DETAILS = gql`
  mutation updateUser($payload:UserInput!) {
    updateUser(payload: $payload) {
      statusMessage
      statusCode
    }
  }
`;

export const CREATE_NEW_OBJECT_TYPE = gql`
  mutation createObjectType($payload: ObjectTypeInput!) {
    createObjectType(payload: $payload) {
      result
      statusCode
      statusMessage
    }
  }
`;

export const EDIT_OBJECT_TYPE = gql`
  mutation editObjectType($payload: EditObjectTypeInput!) {
    editObjectType(payload: $payload) {
      result
      statusCode
      statusMessage
    }
  }
`;

export const ADD_EVENT_PIPELINE = gql`
  mutation addEventPipelineMutation($payload: EventPipelineInput!) {
    createEventPipeline(payload: $payload){
      result
      statusCode
      statusMessage
    }
  }
`;

export const GENERATE_OTP = gql`
  mutation generateOtp($username: String!){
    generateOtp(username: $username){
      result
      statusCode
      statusMessage
    }
  }
`;

export const VERIFY_OTP = gql`
  mutation verifyOtp($username: String!, $otp: Int!, $password: String!){
    verifyOtp(username: $username, otp: $otp, password: $password){
      result
      statusCode
      statusMessage
    }
  }
`;

export const IMPORT_OBJECTS = gql`
  mutation importObjects($payload: ImportObjectsInput!) {
    importObjects(payload: $payload) {
      result {
        objectTypeProperties
        objects
      }
      statusCode
      statusMessage
    }
  }
`;

export const UPDATE_SMTP_CLIENT = gql`
  mutation updateSmtpClient($payload: UpdateSMTPInput!) {
    updateSmtpClient(payload: $payload){
      result
      statusCode
      statusMessage
    }
  }
`;

export const DELETE_SMTP_CLIENT = gql`
  mutation deleteSmtpClient {
    deleteSmtpClient {
      result
      statusCode
      statusMessage
    }
  }
`;

export const DELETE_DETECTIONS = gql`
  mutation deleteDetections($detectionIdList: [Int!]!){
    deleteDetections(detectionIdList: $detectionIdList){
      statusCode
      statusMessage
    }
  }
`;

export const EDIT_FLOOR_PLAN_CONFIG = gql`
  mutation editFloorPlan($payload: EditFloorPlanInput!) {
    editFloorPlan(payload: $payload){
      result
      statusCode
      statusMessage
    }
  }
`;

export const CREATE_FLOOR_PLAN_CONFIG = gql`
  mutation createFloorPlan($payload: FloorPlanInput!) {
    createFloorPlan(payload: $payload){
      result
      statusCode
      statusMessage
    }
  }
`;

export const DELETE_OBJECT_TYPE = gql`
  mutation deleteObjectType($id: Int!){
    deleteObjectType(id: $id){
      statusCode
      statusMessage
    }
  }
`;

export const UPDATE_ALERT_PINNED_STATUS = gql`
  mutation updateAlertsPinnedStatus($payload: AlertPinnedInput!){
    updateAlertsPinnedStatus(payload: $payload){
      result
      statusCode
      statusMessage
    }
  }
`;

export const DELETE_FLOOR_PLAN = gql`
  mutation deleteFloorPlans($idList: [Int!]!){
    deleteFloorPlans(idList: $idList){
      statusCode
      statusMessage
    }
  }
`;

export const CREATE_SERVER = gql`
  mutation createServer($payload: ServerInput!) {
    createServer(payload: $payload) {
      statusCode
      statusMessage
    }
  }
`;

export const DELETE_SERVER = gql`
  mutation deleteServer($payload: Int!) {
    deleteServer(payload: $payload) {
        count
        statusCode
        statusMessage
      }
  }
`;

export const UPDATE_SERVER = gql`
  mutation updateServer($payload: UpdateServerInput!) {
    updateServer(payload: $payload) {
      statusCode
      statusMessage
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser($input: CreateUserInput!, $cognitoID: String!, $cognitoIdentityPoolID: String!) {
    createUser(input: $input) {
      id
      username
      email
      phone_number
      statusCode
      statusMessage
    }
  }
`;

export const CREATE_NEW_FOLDER = gql`
  mutation createFolder($payload: CreateFolderInput!, $cognitoID: String!, $cognitoIdentityPoolID: String!){
    createFolder(payload: $payload, cognitoID: $cognitoID, cognitoIdentityPoolID: $cognitoIdentityPoolID){
      statusCode
      statusMessage
    }
  }
`;

export const DELETE_FOLDER = gql`
  mutation deleteFolder($device_id_list: [String!]){
    deleteFolder(device_id_list: $device_id_list){
      statusCode
      statusMessage
    }
  }
`;

export const UPDATE_FOLDER = gql`
  mutation editFolder($payload: EditFolderInput!){
    editFold(payload: $payload){
      statusCode
      statusMessage
    }
  }
`;