import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgAlgorithm = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M14.057 9.258H9.943a.686.686 0 0 0-.686.685v4.114a.686.686 0 0 0 .686.686h4.114a.686.686 0 0 0 .686-.686V9.944a.686.686 0 0 0-.686-.685Zm-.686 4.114H10.63v-2.743h2.742v2.743Zm7.543 0h-1.371v-2.743h1.371a.686.686 0 0 0 0-1.371h-1.371V5.829a1.371 1.371 0 0 0-1.372-1.371h-3.428V3.086a.686.686 0 0 0-1.372 0v1.372H10.63V3.086a.686.686 0 0 0-1.372 0v1.372H5.828a1.371 1.371 0 0 0-1.371 1.371v3.429H3.086a.686.686 0 0 0 0 1.371h1.371v2.743H3.086a.686.686 0 0 0 0 1.371h1.371v3.429a1.372 1.372 0 0 0 1.371 1.371h3.429v1.372a.686.686 0 0 0 1.372 0v-1.372h2.742v1.372a.686.686 0 0 0 1.372 0v-1.372h3.428a1.371 1.371 0 0 0 1.372-1.371v-3.429h1.371a.686.686 0 1 0 0-1.371Zm-2.743 4.8H5.828V5.829h12.343v12.343Z"
        fill={props.color}
      />
    </svg>
  );
};
export default SvgAlgorithm;
