import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgTime = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
        stroke={props.color}
        strokeWidth={props.weight}
        vectorEffect={"non-scaling-stroke"}
      />
      <path
        d="M12 6v5.51a1.86 1.86 0 0 1-.597 1.37L8.5 15"
        stroke={props.color}
        strokeWidth={props.weight}
        strokeLinecap="round"
        vectorEffect={"non-scaling-stroke"}
      />
    </svg>
  );
};
export default SvgTime;
