import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgRouteDeviation = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m15.138 11.268.03-4.12 1.33-.06-2.4-3.15-2.37 3.26 1.42-.03.01 2.52-6.58 1.73.02 8.99 6.56 2.03-.07 5.31 2.07.02v-6.88l-6.58-2.06.06-5.81 6.5-1.75ZM.59 0c.33 0 .59.27.59.59v30.52a.591.591 0 0 1-1.18 0V.59C0 .26.27 0 .59 0ZM22.488 0c.33 0 .59.27.59.59v30.52a.591.591 0 0 1-1.18 0V.59c0-.33.27-.59.59-.59ZM11.543 0c.33 0 .59.03.59.08v3.93s-.27.08-.59.08c-.33 0-.59-.03-.59-.08V.08s.27-.08.59-.08Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.543 11.172c.33 0 .59.03.59.08v3.93s-.27.08-.59.08c-.33 0-.59-.03-.59-.08v-3.93s.27-.08.59-.08ZM11.543 16.75c.33 0 .59.03.59.08v3.93s-.27.08-.59.08c-.33 0-.59-.03-.59-.08v-3.93s.27-.08.59-.08ZM11.543 27.922c.33 0 .59.03.59.08v3.93s-.27.08-.59.08c-.33 0-.59-.03-.59-.08v-3.93s.27-.08.59-.08Z"
        fill={props.color}
      />
      <path
        d="m14.137 15.84.92-.92c.06-.06.14-.1.22-.09.07 0 .14.03.21.1.06.06.1.13.11.21 0 .08-.03.15-.09.22l-.92.92.93.93c.07.07.1.14.09.22 0 .08-.04.15-.1.21-.06.06-.13.09-.21.09-.08 0-.15-.02-.22-.09l-.92-.92-.93.93c-.06.06-.13.09-.21.09-.09 0-.16-.04-.22-.1a.346.346 0 0 1-.1-.21c0-.08.03-.16.1-.23l.92-.92-.94-.94a.246.246 0 0 1-.08-.21c0-.07.03-.15.09-.22.07-.06.14-.09.22-.09.08 0 .15.02.21.08l.94.94h-.02Z"
        fill={props.color}
      />
    </svg>
  );
};
export default SvgRouteDeviation;
