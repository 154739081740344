import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgPipelineView = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M22.98 17.29a5.61 5.61 0 0 0-5.021-3.583 5.61 5.61 0 0 0-5.022 3.583.336.336 0 0 0 0 .229 5.61 5.61 0 0 0 5.022 3.583 5.61 5.61 0 0 0 5.021-3.583.336.336 0 0 0 0-.229Zm-5.021 3.14c-1.782 0-3.664-1.322-4.346-3.026.682-1.704 2.564-3.025 4.346-3.025 1.781 0 3.663 1.321 4.346 3.025-.683 1.704-2.565 3.025-4.346 3.025Z"
        fill={props.color}
      />
      <path
        d="M17.96 15.389a2.016 2.016 0 1 0 0 4.033 2.016 2.016 0 0 0 0-4.033Zm0 3.36a1.345 1.345 0 1 1 0-2.688 1.345 1.345 0 0 1 0 2.689Z"
        fill={props.color}
      />
      <rect
        x={4.25}
        y={13.293}
        width={2.667}
        height={2.667}
        rx={0.5}
        stroke={props.color}
        vectorEffect={"non-scaling-stroke"}
      />
      <rect
        x={12.584}
        y={7.793}
        width={2.667}
        height={2.667}
        rx={0.5}
        stroke={props.color}
        vectorEffect={"non-scaling-stroke"}
      />
      <path
        d="M7 15h.222a2 2 0 0 0 2-2v-2a2 2 0 0 1 2-2H12.5"
        stroke={props.color}
        vectorEffect={"non-scaling-stroke"}
      />
      <path
        d="M18.875 12.792V7a2 2 0 0 0-2-2H3a2 2 0 0 0-2 2v9.75a2 2 0 0 0 2 2h9"
        stroke={props.color}
        strokeLinecap="round"
        vectorEffect={"non-scaling-stroke"}
      />
    </svg>
  );
};
export default SvgPipelineView;
