import Checkbox from 'components/Checkbox';
import Input from 'components/Input';
import Label from 'components/Label';
import SelectField from 'components/SelectField';
import Switch from 'components/Switch';
import Title from 'components/Title';
import { SNAPSHOT_RESOLUTIONS } from '../../../constants';
import React from 'react';
import styled from 'styled-components';
import { ISnapshotFields } from '../../../interface';
import { useTranslation } from 'react-i18next';
import { allowOnlyNumbers } from 'utils/utils';

interface IPeriodicSnapshot {
  streamType: string | undefined,
  snapshotFields: ISnapshotFields,
  setSnapshotFields: React.Dispatch<React.SetStateAction<ISnapshotFields>>
}

const PeriodicSnapshotWrapper = styled.div`
  border: 1px solid #d3d3d352;
  border-radius: 5px;
  margin-top: 20px;
  width: 472px;
`;

const SaveOptions = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 15px;
  margin-bottom: 10px;
`;

const VerticalLine = styled.div`
  width: 472px;
  height: 10px;
  border-bottom: 1px solid #d3d3d352;
  margin-bottom: 16px;
  margin-top: -6px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 8px 0px 8px;
`;

const SaveRecordingOption = styled.div`
  margin-top: 4px;
  display: flex;
  gap: 20px;
`;

const ContentWrapper = styled.div`
  padding: 0 10px;
`;

const ResolutionWrapper = styled.div`
  display: flex;
  > label {
    align-items: flex-start;
  }
  > label > input {
    margin-top: 3px;
  }
   > div > select {
    width: 426px;
  }
`;

const OriginalResolution = styled.div`
  display: flex;
  > label {
    align-items: flex-start;
  }
  > label > input {
    margin-top: 3px;
  }
`;

const ValueWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-left: 14px;
  > label {
    margin-top: 5px;
  }
  > div {
    width: 134px;
  }
  > div > input {
    height: 34px;
  }
`;

const PeriodicSnapshot = ({snapshotFields, setSnapshotFields}: IPeriodicSnapshot) => {
  const {t} = useTranslation(['common']);

  const handleInputFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = event.target.id;
    const value: string = event.target.value;
    setSnapshotFields(prevState => ({
      ...prevState,
      services: {
        ...prevState?.services,
        recorder: {
          ...prevState?.services?.recorder,
          [id]: value
        }
      }
    }));   
  };

  const handleSelectFieldChange = (value: string, id: string) => {
    if(id === 'videoSplitMode') 
    {
      setSnapshotFields(prevState => ({
        ...prevState,
        services: {
          ...prevState?.services,
          recorder: {
            ...prevState?.services?.recorder,
            saveInterval: value === 'time' ? '600' : '50',
            videoSplitMode: value,
          }
        }
      }));
    }
    else {
      setSnapshotFields(prevState => ({
        ...prevState,
        services: {
          ...prevState?.services,
          recorder: {
            ...prevState?.services?.recorder,
            [id]: value
          }
        }
      })); 
    }    
  };

  const handleSwitchChange = (state: boolean, id: string) => {
    setSnapshotFields(prevState => ({
      ...prevState,
      services: {
        ...prevState?.services,
        recorder: {
          ...prevState?.services?.recorder,
          [id]: !state
        }
      }
    }));
  };

  return (
    <PeriodicSnapshotWrapper>
      <TitleWrapper>
        <Title size='18px' text={t('Periodic Snapshot')} />
        <SaveRecordingOption>
          <Switch checked={snapshotFields?.services?.recorder?.periodicSnapshot} onChange={() => handleSwitchChange(snapshotFields?.services?.recorder?.periodicSnapshot, 'periodicSnapshot')} />
        </SaveRecordingOption>
      </TitleWrapper>
      <VerticalLine />
      <ContentWrapper>
        <SaveOptions>              
          <SaveRecordingOption>
            <Label labelText={t('Time Overlay')} />
            <Switch checked={snapshotFields?.services?.recorder?.enableSnapshotTimeOverlay} disabled={!snapshotFields?.services?.recorder?.periodicSnapshot} onChange={() => handleSwitchChange(snapshotFields?.services?.recorder?.enableSnapshotTimeOverlay, 'enableSnapshotTimeOverlay')} />
          </SaveRecordingOption>
          <ValueWrapper>
            <Label labelText={t('Interval (Sec)')} />
            <Input label='' id='saveInterval' disabled={!snapshotFields?.services?.recorder?.periodicSnapshot} width='180px' maxLength={4} onKeyPress={allowOnlyNumbers} value={snapshotFields?.services?.recorder?.saveInterval} type='text' onChange={handleInputFieldChange} />
          </ValueWrapper>
        </SaveOptions>
        <ResolutionWrapper>
          <Checkbox id='specifySnapshotResolution' disabled={!snapshotFields?.services?.recorder?.periodicSnapshot} checked={snapshotFields?.services?.recorder?.specifySnapshotResolution} onChangeCallback={() => handleSwitchChange(snapshotFields?.services?.recorder?.specifySnapshotResolution, 'specifySnapshotResolution')} />
          <SelectField id='periodicSnapshotResolution' label={t('Specify Resolution')} disabled={!snapshotFields?.services?.recorder?.specifySnapshotResolution || snapshotFields?.services?.recorder?.periodicSnapshot} defaultValue={snapshotFields?.services?.recorder?.periodicSnapshotResolution} options={SNAPSHOT_RESOLUTIONS} onChange={(value) => handleSelectFieldChange(value, 'periodicSnapshotResolution')}/>
        </ResolutionWrapper>
        <OriginalResolution>
          <Checkbox checked={snapshotFields?.services?.recorder?.saveOriginalSnapshot} disabled={!snapshotFields?.services?.recorder?.specifySnapshotResolution || snapshotFields?.services?.recorder?.periodicSnapshot} onChangeCallback={() => handleSwitchChange(snapshotFields?.services?.recorder?.saveOriginalSnapshot, 'saveOriginalSnapshot')} />
          <Label labelText={t('Save the snapshots of the original size')} />
        </OriginalResolution>
      </ContentWrapper>
    </PeriodicSnapshotWrapper>
  );
};

export default PeriodicSnapshot;