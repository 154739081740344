
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { getOnlyDate, getTimeSince } from 'utils';
import { BiAnalyse } from 'react-icons/bi';
import Icon from 'components/Icon';
import SearchInputFilter, { IAppliedFilter } from 'components/SearchInputFilter';
import { IGetAllAlgorithmRes } from 'interface';
import Config from '../../configs/config.json';
import { useQuery } from '@apollo/client';
import { GET_ALL_ALGORITHMS } from 'api_configs/queries';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width:100%;
  padding: 5px 0 5px 0;
`;

const TableContainer = styled.div`
  width: 100%;
  gap: 10px 0;
  .MuiDataGrid-row{
    > div:nth-child(2){
    display: flex;
    justify-content: right ;
  }
  }
  .MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeaders{
    background-color: #F5F8FF;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle{
    font-weight: 600;
  }
  > div:nth-child(1) > div:nth-child(2){
    display: none;
  }
  > div:first-child {
    > div:nth-child(1) {
      > div:nth-child(2){
        min-height: 100px;
      }
    }
  }
  > div > div:first-child{
    > div:first-child{
      font-size: 14px;
      color: #5a6269;
      > div > div > div > div:nth-child(2) > svg {
        display: none;
      }
    }
    > div:nth-child(2) {
      text-align: center;
      font-size: 14px;
      text-decoration: none;
      color:#7b8288;
    }
  }
`;

const TimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

const TimeStampLabel = styled.div`
  font-weight: 400;
  font-size: 14px;
`;

const TimeStampText = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: ${({theme}) => theme.text.primaryText};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width:192px;
`;

const ActionIcons = styled.div`
  display: flex;
  column-gap: 5px;
`;

const DownloadIconWrapper = styled.div`
  cursor: pointer;
  width: 34px;
  height: 34px;
  border-radius: 5px;
  border: 1px solid ${({theme}) => theme.divider};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const fileData = [
  {
    'id': 4011,
    'length': '00:59:12',
    'timestamp': '2024-07-31 10:00:00',
    'jobtype': 'Scheduled',
    'status': 'Running',
    'analysedRange': '2020/06/11 - 16:00  → 2020/06/11 - 21:30',
    'algoCode': 'SPEED_DETECTION'
  },
  {
    'id': 4012,
    'length': '00:59:12',
    'timestamp': '2024-07-31 11:00:00',
    'jobtype': 'On-Demand',
    'status': 'Complete',
    'analysedRange': '2020/06/11 - 16:00  → 2020/06/11 - 21:30',
    'algoCode': 'SPEED_DETECTION'
  },
  {
    'id':4013,
    'length': '00:59:12',
    'timestamp': '2024-07-31 12:00:00',
    'jobtype': 'Scheduled',
    'status': 'Failed',
    'analysedRange': '2020/06/11 - 16:00  → 2020/06/11 - 21:30',
    'algoCode': 'SPEED_DETECTION'
  },
  {
    'id': 4014,
    'length': '00:59:12',
    'timestamp': '2024-07-31 13:00:00',
    'jobtype': 'On-Demand',
    'status': 'Partial Failure',
    'analysedRange': '2020/06/11 - 16:00  → 2020/06/11 - 21:30',
    'algoCode': 'SPEED_DETECTION'
  }
];
  
const Jobs : React.FC = () => {
  const {t} = useTranslation(['common']);
  const [alertData] = useState(fileData);
  const [_dateRange, setDateRange] = useState<string[]>(['','']);
  const [algorithmList, setAlgorithmList] = useState<IGetAllAlgorithmRes[]>([]);
  const { data: allAlgorithms } = useQuery(GET_ALL_ALGORITHMS);

  useEffect(()=>{
    // Setting Algorithm Data
    const allAlgorithmsData: IGetAllAlgorithmRes[] = allAlgorithms?.getAllAlgorithm?.result !== null ? allAlgorithms?.getAllAlgorithm?.result: [];
    if(allAlgorithmsData?.length > 0){
      const configuredAlgos = allAlgorithmsData?.filter((algorithm)=>{
        return Config.algorithmConfigurations.configuredAlgos.indexOf(algorithm.code) !== -1;
      });
      setAlgorithmList(configuredAlgos);
    }
  },[allAlgorithms]);

  const generateRowData = useCallback(() =>{
    return alertData?.length > 0 ? alertData?.map((item: any)=>(
      { 
        id: item.id, 
        length: item.length,
        timestamp: item.timestamp,
        type: item.jobtype,
        size: item.size,
        status: item.status,
        analysedRange: item.analysedRange,
        algorithm: item.algoCode
      }
    )) : [];
  },[alertData]);

  const columns: GridColDef[] = useMemo(()=>
    [
      {
        field: 'id',
        headerName: t('ID'),
        width: 70,
        sortable: false,
        renderCell: (params) => (
          <div style={{'marginRight': '20px'}}>
            {params.row.id}
          </div>
        )
      },
      { field: 'timestamp', 
        headerName: t('Created At'), 
        flex: 1.2, 
        headerAlign: 'left', 
        align: 'left',
        renderCell: (params) => {
          return (
            <TimeWrapper>
              <TimeStampLabel title={getOnlyDate(params.row.timestamp)}>{getOnlyDate(params.row.timestamp)}</TimeStampLabel>
              <TimeStampText title={getTimeSince(params.row.timestamp)}>{getTimeSince(params.row.timestamp)}</TimeStampText>
            </TimeWrapper>
          );
        }
      },
      { field: 'type', headerName: t('Type'), flex: 1, headerAlign: 'left', align: 'left', sortable: false },
      { field: 'length', headerName: t('Length'), flex: 1, headerAlign: 'left', align: 'left', sortable: false },
      { field: 'analysedRange', headerName: t('Analysed Range'), flex: 1, headerAlign: 'left', align: 'left', sortable: false },
      { field: 'algorithm', headerName: t('Algorithm'), flex: 1, headerAlign: 'left', align: 'left', sortable: false },
      { field: 'status', headerName: t('Status'), flex: 1, headerAlign: 'left', align: 'left', sortable: false },
      {
        field: 'actions',
        headerName: '',
        flex: 1.3,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        renderCell: () => {
          return (
            <ActionIcons>
              <DownloadIconWrapper>
                <BiAnalyse size={24} color='#18C97F' />
              </DownloadIconWrapper>
              <DownloadIconWrapper>
                <Icon icon='Download' color='primary' size={24} />
              </DownloadIconWrapper>
              <DownloadIconWrapper>
                <Icon icon='Delete' color='danger' size={24} />
              </DownloadIconWrapper>
            </ActionIcons>
          );
        }
      }
    ]
  ,[t]); 

  const getConfiguredColumns = useCallback(()=>{
    return columns;
  },[columns]);

  const searchFilterCallback = useCallback((appliedFilters: IAppliedFilter[])=>{
    if(appliedFilters.length > 0){
      
      const dateRangeFilter = appliedFilters.findIndex(item =>item.keyName === 'dateRange');
      if(dateRangeFilter !== -1){
        const filteredDateRange = appliedFilters[dateRangeFilter].query ?? [];
        setDateRange(filteredDateRange as string[]);
      }else{
        setDateRange(['','']);
      }
    }else{
      setDateRange(['','']);
    }
  },[]);

  return (
    <MainContainer> 
      <SearchInputFilter enableInputFilter={false} showAlgorithm showDateRange searchFilterCallback={searchFilterCallback} algorithmList={algorithmList} placeholder={t('search by Camera Name...')} />
      <TableContainer>
        <DataGrid
          rows={generateRowData()}
          columns={getConfiguredColumns()}
          checkboxSelection={true}
          disableRowSelectionOnClick
          disableColumnMenu
          keepNonExistentRowsSelected
          getRowHeight={() => 100}
        /> 
      </TableContainer>
    </MainContainer>
  );
};

export default Jobs;