import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import axios from 'axios';
import { IStream } from '../interface';
import { useTranslation } from 'react-i18next';
import { ALGORITHM_CODES, EdgeStatus, IconType, PERMISSION_CODE, STATUS_URL, StatusCode } from '../constants';
import Icon from './Icon';
import Modal from './Modal';
import FullScreenView from './FullScreenView';
import { useLazyQuery, useMutation } from '@apollo/client';
import { DELETE_STREAM, STREAM_TOGGLE, UPDATE_STREAM_TO_GROUP } from 'api_configs/mutations';
import { GET_ALL_STREAMS, GET_DETECTIONS_COUNT } from 'api_configs/queries';
import { useHistory } from 'react-router';
import ConfirmationModal from './modals/ConfirmationModal';
import { useClickOutside } from 'hooks/useClickOutside';
import TextOnlyConfirmationModal from './modals/TextOnlyConfirmationModal';
import LineViewer from './LineViewer';
import TokenService from 'api_configs/tokenService';
import { useDetection } from 'contexts/DetectionContext';
import Config from '../configs/config.json';
import Odometer from './Odometer';

const pulse = keyframes`
  100%{
    transform: scale(1.7);
    opacity: 0;
  }
`;

const animate = keyframes`
  from{
    box-shadow: 0 0 6px 6px rgb(91, 171, 237);;
  }
  to{
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }
`;

const Container = styled.div<{gridViewOption:number, isGroupView: boolean, isHighLight: boolean}>`
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.3s ease;
  ${({isHighLight}) => isHighLight === true && css`
    animation: ${animate} 3s linear;
  `}
  :hover{
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  }
  transition: all 0.6s ease;
`;

const CameraStream = styled.div`
  position: relative;
  perspective: 1000px;
`;

const CameraStreamWrapper = styled.div<{flipped?:boolean, gridViewOption:number, disabled: boolean, isGroupView: boolean}>`
  position: relative;
  width: 100%;
  transform: ${({flipped})=>flipped ? 'rotateY(180deg)': 'rotateY(0)'};
  transform-style: preserve-3d;
  transition: transform 0.6s;
  overflow: hidden;

  transition: all 0.6s ease;
`;

const CameraCardFooter = styled.div`
  height: 40px;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const Icondiv = styled.div<{active?: boolean, isBlinking?: boolean, isEnabled?: boolean}>`
  position: relative;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  display: flex;
  z-index: 9;
  align-items: center;
  justify-content: center !important;
  cursor: pointer;
  background-color: ${({active, theme})=>active ? theme.sidemenu.itemIconBackground : 'transperant'};
  :hover{
    background-color: ${({theme})=>theme.sidemenu.itemIconBackground};
  }
  ${({ isBlinking, isEnabled }) =>
    isBlinking ? 
      isEnabled ?
        css`
        &:before,&:after{
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background-color: #fe1900;
          z-index: 6;
          opacity: 0.7;
        }
        &:before{
          animation: ${pulse} 2s ease-out infinite;
        }
        &:after{
          animation: ${pulse} 2s 1s ease-out infinite;
        }
        `: 
        css`
        &:before{
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          top: 6px;
          right: 6px;
          border-radius: 50%;
          background-color: #fe1900;
          z-index: 6;
        }
    ` : null}
  
`;

const AlertsContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const MoreOptions = styled.div`
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  position: absolute;
  background-color: white;
  border-radius: 5px;
  width: max-content;
  top: 0;
  right: 40px;
  z-index: 11;
  display: flex;
  flex-direction: column;
`;

const StreamBackDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background-color: ${({theme})=>theme.backgroundColor};
  transform: rotateY(180deg);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
`;

const IndividualOption = styled.div<{disabled?: boolean}>`
  display: flex;
  padding: 5px;
  gap: 8px;
  position: relative;
  width: 84px;
  cursor: ${({disabled})=>disabled ? 'no-drop': 'pointer'};
  border: 1px solid #DCE2E4;
  :hover{
    background-color: ${({theme})=>theme.sidemenu.selectedItembackground};
  }
  font-size: 12px;
  @media only screen and (min-width: 1920px){
    width: 100%;
    font-size: 14px;
    padding: 5px 10px;
  }
`;

const Submenu = styled.div<{isAddToGroupHover:boolean}>`
  display: ${({isAddToGroupHover}) => isAddToGroupHover ? 'block': 'none'};
  flex-direction: column;
  gap: 5px;
  width: fit-content;
  min-width: 100px;
  height: fit-content;
  border-radius: 5px;
  background-color: ${({theme})=>theme.backgroundColor};
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  position: absolute;
  right: 100%;
  top: 0;
  padding: 10px 0;
`;

const GroupsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 150px;
`;

const GroupLabel = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  :hover{
    background-color: ${({theme})=>theme.sidemenu.selectedItembackground};
  }
  width: 100px;
  padding: 4px 6px;
  @media only screen and (min-width: 1920px) {
    width: 150px;
    padding: 8px;
    font-size: 14px;
  }
`;

const StreamInfoContainer = styled.div`
  display: flex;
  height: 100%;
  cursor: pointer;
  align-items: center;
  width: fill-available;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const OptionText = styled.div`
  text-align: center;
  padding: 0;
  font-size: 12px;
  @media only screen and (min-width: 1920px){
    padding: 3px 2px;
    font-size: 14px;
  } 
`;

const Checkbox = styled.input`
  margin-right: 5px;
  width: 16px;
  height: 16px;
  border-radius: 5px;
  cursor: pointer;
`;

const GroupLabelText = styled.div`
  width: calc(150px - 20px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StreamName = styled.div`
  font-size: 16px;
  color: ${({theme})=>theme.text.primaryText};
  cursor: pointer;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StreamNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DetectionData = styled.div`
  display: flex;
  height: 100%;
  gap: 4px;
  cursor: pointer;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DetectionInfo = styled.div`
  font-size: 12px;
  color: #6b6c6f;
  display: flex;
  white-space: nowrap;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1em !important;
  >:nth-child(2){
    margin-bottom: 4px;
  }
`;

const StreamDescription = styled.div<{visibility: boolean}>`
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  max-width: 400px;
  height: max-content;
  top: 42px;
  left: 0;
  z-index: 15;
  display: ${({visibility}) => visibility ? 'flex': 'none'};
  flex-direction: column;
  overflow-y: auto;
  word-wrap: break-word;
  white-space: pre-wrap;
`;

const StreamFooterActions = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const StreamHeaders = styled.div`
  position: absolute;
  top: 0;
  height: 40px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 5;
`; 

const StreamStatus = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffffa0;
  cursor: pointer;
`;

const StreamStatusMsg = styled.div<{bgColor:string}>`
  font-size: 12px;
  background-color: ${({bgColor})=>bgColor};
  padding: 5px;
  border-radius: 5px;
  z-index: 5;
  color: ${({theme})=>theme.text.primaryText};
`;

const StreamStatusInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StatusMsgContainer = styled.div<{isVisible:boolean}>`
  position: relative;
  display: flex;
  align-items: center;
  display: ${({isVisible})=>isVisible ? '' : 'none'};
`;

const MoreOptionsContainer = styled.div`
  position: relative;
`;

const StatusArrow = styled.div<{bgColor:string}>`
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  position: absolute;
  left: -5px;
  background-color: ${({bgColor})=>bgColor};
`;

const NoGroupText = styled.div`
  padding: 0 5px;
`;

interface ITabs {
  id: number,
  name: string,
  streams: IStream[]
}

interface IProp {
  gridViewOption:number;
  stream: IStream;
  groups: ITabs[];
  isGroupView?: boolean;
  highLightStreamId: string;
  setMessage?: (a: IAlertMessageType) => void;
  message?: IAlertMessageType;
  showAlertIcon?: boolean
}

interface IAlertMessageType {
  text: string,
  id: string,
  type: string | IconType,
}

const NewCard: React.FC<IProp> = ({ stream, gridViewOption, groups=[], isGroupView=false, highLightStreamId, setMessage=()=>{}, showAlertIcon=true }) => {
  const [extraInfo, setExtraInfo] = useState<boolean>(true);
  const [moreOptions, setMoreOptions] = useState<boolean>(false);
  const [isAddToGroupHover, setIsAddToGroupHover] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteStreamModal, setDeleteStreamModal] = useState(false);
  const [enableStreamModal, setEnableStreamModal] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [checkGrpList, setCheckGrpList] = useState<number[]>(stream.groupList === null ? [] : stream.groupList);
  const {t} = useTranslation(['common']);
  const [streamStatus, setStreamStatus] = useState<string>('');
  const history = useHistory();
  const moreOptionsRef = useRef<HTMLDivElement>(null);
  const [deleteStreamMutation] = useMutation(DELETE_STREAM,{
    refetchQueries: [{ query: GET_ALL_STREAMS }], // to refetch GET_ALL_STREAMS API to prevent refreshing the page to see the result.
  });
  const [streamToGroupsMutation] = useMutation(UPDATE_STREAM_TO_GROUP,{
    refetchQueries: [{ query: GET_ALL_STREAMS }],
  });
  const [streamToggleMutation, {loading: streamToggleLoading}] = useMutation(STREAM_TOGGLE,{
    refetchQueries: [{ query: GET_ALL_STREAMS }],
  });
  const editGroupAccess = TokenService.hasAccess(PERMISSION_CODE.edit_group);
  const deleteCameraAccess = TokenService.hasAccess(PERMISSION_CODE.delete_camera);
  const editCameraAccess = TokenService.hasAccess(PERMISSION_CODE.edit_camera);
  const [getDetections, {loading: detectionLoading}] = useLazyQuery(GET_DETECTIONS_COUNT);
  const [detectionData, setDetectionData] = useState({totalCount: 0, upCount: 0, downCount: 0});
  const { data } = useDetection();

  useClickOutside(moreOptionsRef, () => setMoreOptions(false));

  const getDetectionCount = useCallback(async () => {
    const currentDate = new Date();
    const istOffset = 9 * 60 * 60 * 1000;
    const istDate = new Date(currentDate.getTime() + istOffset);
    const endDate = istDate.toISOString().slice(0, 19).replace('T', ' ');
    const startOfDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    const istStartOfDay = new Date(startOfDay.getTime() + istOffset);
    const startDate = istStartOfDay.toISOString().slice(0, 19).replace('T', ' ').slice(0, 11) + '00:00:00';

    const response = await getDetections({ variables: { payload: { startDate: startDate, endDate: endDate, streamId: stream.instanceId, algoCode: ALGORITHM_CODES.traffic_pulse_detection }}});

    if (response && response.data?.getDetectionCount && response.data?.getDetectionCount?.result !== null) {
      const totalCount = response.data?.getDetectionCount?.result?.totalCount;
      const upCount = response.data?.getDetectionCount?.result?.data?.upCount;
      const downCount = response.data?.getDetectionCount?.result?.data?.downCount;
      setDetectionData({totalCount, upCount, downCount});
    }

  }, [getDetections, stream.instanceId]);

  useEffect(() => {
    getDetectionCount();
  }, [getDetectionCount]);

  useEffect(() => {
    if (!detectionLoading && data?.getDetectionsSub?.result && data.getDetectionsSub.result.algoCode === ALGORITHM_CODES.traffic_pulse_detection && Config.isSubscriptionEnabled) {
      const streamId = data.getDetectionsSub.result.streamId;
      const detectionData = JSON.parse(data.getDetectionsSub.result.detectionData);
      const isUp = detectionData.direction_kind === 'forward';
      if(streamId === stream.instanceId){
        setDetectionData(prev => {
          const temp = {...prev};
          temp.totalCount = prev.totalCount + 1;
          temp.upCount = isUp ? temp.upCount + 1 : temp.upCount;
          temp.downCount = !isUp ? temp.downCount + 1 : temp.downCount;
          return {...temp};
        });
      }
    }
  }, [data, detectionLoading, stream.instanceId]);

  const updateStatusInfo = useCallback(async () =>{
    try{
      const statusRes = await axios.get(`${STATUS_URL.replace('{X}',stream?.instanceId)}`);
      if(statusRes && statusRes?.data?.data?.running_status){
        setStreamStatus(statusRes?.data?.data?.running_status?.status);
      }
    }catch(e){
      console.log(e);
    }
  },[stream]);

  useEffect(()=>{
    updateStatusInfo();
    const intervalId = setInterval(() => {
      updateStatusInfo();
    }, 5000);
    return () => clearInterval(intervalId);
  }, [stream, updateStatusInfo]);

  const getStatus = (status : string) => {
    if(status === EdgeStatus.RECOVERING){
      return {bgColor:'#ffa459', status: 'Reconciliation', icon:<Icon icon='AlertTriangle' size={20} color='warning' />};
    }
    else if(status === EdgeStatus.FAILED){
      return {bgColor:'#ED2B2A', status: 'Failed', icon:<Icon icon='AlertCircle' size={20} color='danger' />};
    }
    else if(status === EdgeStatus.DISABLED){
      return {bgColor:'#b1b1b1', status: 'Disabled', icon:<Icon icon='AlertCircle' size={20} color='subtle' />};
    }
    else if(status === EdgeStatus.PAUSED){
      return {bgColor:'#707070', status: 'Paused', icon:<Icon icon='Pause' size={20} color='dimmed' />};
    }
    else{
      return {bgColor:'#EEEEEE', status: '-', icon:<Icon icon='AlertOctagon' size={20} color='dimmed' />};
    }
  };

  const handleAddStreamHover = () => {
    setIsAddToGroupHover(true);
  };

  const handleAddStreamHoverLeave = () => {
    setIsAddToGroupHover(false);
  };

  const handleCheckboxChange = useCallback( async (streamId:string, groupId: number, isChecked:boolean) => {
    let newArray = checkGrpList;
    if(!isChecked){
      newArray = [...checkGrpList,groupId];
    }else{
      newArray = checkGrpList.filter((id)=>id !== groupId);
    }
    try{
      const message = newArray.length > checkGrpList.length ? t('Camera added to group successfully!') : t('Camera removed from group successfully!');
      // Modifying API payload such that if camera is removed from all the groups then add it to the No Group and if Camera is being added to one group then remove it from No Group
      const data = await streamToGroupsMutation({variables:{payload:{streamId: streamId, groupList: newArray.length === 0 ? [-1] : newArray.filter(id => id !== -1)}}});
      if(data.data.updateStreamToGroup.statusCode === StatusCode.SUCCESS){
        setMessage({text: message, id:'',type:'success'});
        setCheckGrpList(newArray);
      }else{
        setMessage({text: t('Failed to update camera to group'), id:'',type:'danger'});
      }
    }catch(err) {
      console.error(err);
      setMessage({text: t('Failed to update camera to group'), id:'',type:'danger'});
    }
  },[checkGrpList,streamToGroupsMutation,t, setMessage]);

  const handleDeleteStream = useCallback(async () => {
    try{
      const data = await deleteStreamMutation({variables:{streamId: stream.instanceId}});
      if(data && data?.data?.deleteStream?.statusCode === StatusCode.SUCCESS){
        setMessage({text: t('Camera deleted successfully'), id:'',type:'success'});
      }else{
        setMessage({text: t('Some Error occured!'), id:'',type:'danger'});
      }
    }catch(e){
      console.error(e);
    }
  },[deleteStreamMutation, stream, t, setMessage]); 

  const handleEnableDisable = useCallback(async(toggleStatus: boolean) => {
    const action = toggleStatus ? 'stop' : 'start';
    const data = await streamToggleMutation({variables:{payload:{streamId: stream.instanceId, action: action}}, refetchQueries: [{query: GET_ALL_STREAMS}]});
    if(data && data?.data?.postStream?.statusCode === StatusCode.SUCCESS){
      setMessage({text: action === 'stop' ? t('Camera disabled successfully') : t('Camera enabled successfully'), id:'',type:'success'});
    }else{
      setMessage({text: t('Error occurred while updating camera. Please try again!'), id:'',type:'danger'});
    }
    setEnableStreamModal(prev => !prev);
  },[stream, streamToggleMutation, t, setMessage]);

  return (
    <Container isHighLight={highLightStreamId === stream?.instanceId} gridViewOption={gridViewOption} isGroupView={isGroupView}>
      <CameraStream>
        <StreamHeaders>
          {streamStatus && streamStatus !== EdgeStatus.RUNNING  ?
            <StreamStatusInfo>
              <StreamStatus onClick={()=>setExtraInfo(!extraInfo)}>{getStatus(streamStatus).icon}</StreamStatus>
              <StatusMsgContainer isVisible={extraInfo} >
                <StatusArrow bgColor={getStatus(streamStatus).bgColor}  />
                <StreamStatusMsg bgColor={getStatus(streamStatus).bgColor}>{t(`Video ${streamStatus}`)}</StreamStatusMsg>
              </StatusMsgContainer>
            </StreamStatusInfo>
            : <div></div> // returning empty div if the status is running to maintain position of algorithms container fixed
          }
        </StreamHeaders>
        <CameraStreamWrapper disabled={!stream.enabled} onClick={()=>setIsModalOpen(!isModalOpen)} gridViewOption={gridViewOption} isGroupView={isGroupView}>
          <LineViewer isCard showAlgorithmIcons hasStreamToggleOptions={false} state={[]} streamID={stream.instanceId} width='100%' height='100%' />
          <StreamBackDiv>
            BackCard{/* To Do */}
          </StreamBackDiv>
        </CameraStreamWrapper>
        <Modal isModalOpen={isModalOpen} closeModal={setIsModalOpen} width='60vw'>
          <FullScreenView streamDetails={stream} />
        </Modal>
      </CameraStream>
      <CameraCardFooter>
        <StreamInfoContainer onClick={()=>history.push(`/cameras/${stream.instanceId}`)}>
          <StreamNameWrapper>
            <StreamName onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>{stream.name}</StreamName>
            {Config.showDetectionCountOnCameraCard && stream?.algorithmList && stream?.algorithmList?.findIndex(algo => algo.code === ALGORITHM_CODES.traffic_pulse_detection) !== -1 &&
            <DetectionData>
              <DetectionInfo><div>{t('Total')}: </div>{detectionLoading ? (<div>...</div>): <Odometer value={detectionData.totalCount} fontSize={12} />}</DetectionInfo>
              <DetectionInfo><div>{t('Up')}: </div>{detectionLoading ? (<div>...</div>) : <Odometer value={detectionData.upCount} fontSize={12} />}</DetectionInfo>
              <DetectionInfo><div>{t('Down')}: </div>{detectionLoading ? (<div>...</div>) : <Odometer value={detectionData.downCount} fontSize={12} />}</DetectionInfo>
            </DetectionData>}
          </StreamNameWrapper>
        </StreamInfoContainer>
        {stream.notes && <StreamDescription visibility={showTooltip}>{stream.notes}</StreamDescription>}
        <StreamFooterActions>
          {showAlertIcon && <AlertsContainer>
            <Icondiv title={('Alerts')} isEnabled={stream.enabled} isBlinking={stream?.alertStatus?.hasActiveAlert} onClick={()=>history.push(`/cameras/${stream.instanceId}?selectedTab=alerts`)}>
              <Icon icon='Bell' />
            </Icondiv>
          </AlertsContainer>}<MoreOptionsContainer ref={moreOptionsRef}>
            {editCameraAccess && <Icondiv title={('More Options')} active={moreOptions} onClick={()=>setMoreOptions(!moreOptions)}>
              <Icon icon='MoreVertical' color='mono' />
            </Icondiv>}
            {
              moreOptions &&
              <MoreOptions>
                { editGroupAccess && <IndividualOption disabled={(groups?.length !== undefined && groups?.length !== 0) ? false : true} onMouseEnter={handleAddStreamHover} onMouseLeave={handleAddStreamHoverLeave}>
                  <OptionText>{t('addToGroup')}</OptionText>
                  {
                    groups?.length > 0 ?
                      <Submenu isAddToGroupHover={isAddToGroupHover} onMouseEnter={handleAddStreamHover} onMouseLeave={handleAddStreamHoverLeave}>
                        {
                          <GroupsWrapper>
                            {
                              groups?.length > 0  && 
                                groups?.map((group) => {
                                  const isChecked = checkGrpList?.includes(group.id);
                                  return (
                                    <GroupLabel key={group.id} onClick={()=>handleCheckboxChange(stream.instanceId, group.id, isChecked)}>
                                      <Checkbox
                                        type='checkbox'
                                        checked={isChecked}
                                      />
                                      <GroupLabelText title={group.name}>{group.name}</GroupLabelText>
                                    </GroupLabel>
                                  );
                                })}
                          </GroupsWrapper>
                        }
                      </Submenu>:
                      <Submenu isAddToGroupHover={isAddToGroupHover} onMouseEnter={handleAddStreamHover} onMouseLeave={handleAddStreamHoverLeave}>
                        <NoGroupText>{t('No group created')}</NoGroupText>
                      </Submenu>
                  }
                </IndividualOption>}
                { deleteCameraAccess && <IndividualOption onClick={()=>setDeleteStreamModal(!deleteStreamModal)}>
                  <OptionText>{t('Delete')}</OptionText>
                </IndividualOption>}
                { editCameraAccess && <IndividualOption onClick={() => history.push(`/cameras/${stream.instanceId}/configurations?selectedTab=basicConfigs&camera-name=${stream.instanceId}&camera-type=${stream.streamType}`)}>
                  <OptionText>{t('Edit')}</OptionText>
                </IndividualOption>}
                {editCameraAccess && <IndividualOption onClick={()=>setEnableStreamModal(prev => !prev)}>
                  <OptionText>{t(`${stream.enabled ? 'Disable' : 'Enable'}`)}</OptionText>
                </IndividualOption>}
              </MoreOptions>
            }
            {
              deleteStreamModal && 
              <ConfirmationModal modalOpen={deleteStreamModal} setModalOpen={setDeleteStreamModal} onSubmit={()=>handleDeleteStream()} hasConfirmationField={true} placeholder={t('Enter Camera Name')} textToCheck={(stream.name).trim()}
                titleText={t('Delete Camera') + ': ' + stream.name + '?'} confirmDescription={t('Are you sure you want to delete this Camera? Please enter the camera name to confirm.')} noteText={t('Once deleted this camera will be removed from event pipeline')} />}
            {
              enableStreamModal && 
              <TextOnlyConfirmationModal isLoading={streamToggleLoading} onYes={()=>handleEnableDisable(stream?.enabled)} setModalOpen={setEnableStreamModal} modalOpen={enableStreamModal}
                titleText={t(`${stream.enabled ? 'Disable' : 'Enable'} Stream?`)} confirmDescription={t('enableDisableStreamText').replace('{X}', `${stream.enabled ? 'disable' : 'enable'}`)} />
            }
          </MoreOptionsContainer>
        </StreamFooterActions>
      </CameraCardFooter>   
    </Container>
  );
};

export default React.memo(NewCard);