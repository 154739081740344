import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgCategory = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M4 4h6v6H4V4Zm10 0h6v6h-6V4ZM4 14h6v6H4v-6Zm10 3a3 3 0 1 0 6 0 3 3 0 0 0-6 0Z"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={"non-scaling-stroke"}
      />
    </svg>
  );
};
export default SvgCategory;
