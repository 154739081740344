import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import HandleUnit from './HandleUnit';
import LineUnit from './LineUnit';
import update from 'immutability-helper';
import { LineSetContext } from './Contexts';
import { IPointSet, IDragLineUISharedOptions, IVector2, IRestrictionPointsData } from '.';
import styled, { css } from 'styled-components';
import { ReactComponent as CameraSvg } from '../../svgs/zoneCamera.svg';
import { pointInPolygon } from 'utils/utils';

const FilledPolygon = styled.polygon<{ color: string; opacity: number }>`
  fill: ${({ color }) => color };
  opacity: ${({ opacity }) => opacity };
`;

const Point = styled.circle<{styling: string}>`
  fill: ${({theme, styling}) => theme.custom.lines[styling].point.fill};
`;

const AreaLabelText = styled.text<{styling: string, showAreaLabelShadow: boolean}>`
  text-align: center;
  fill: ${({theme, styling}) => theme.custom.lines[styling].label.fill};
  font-weight: bold;
  transition: opacity 250ms ease;
  pointer-events: none;
  ${({showAreaLabelShadow}) => showAreaLabelShadow && css`
    text-shadow:
      -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000;
  `}
`;

interface ILineSetProps {
  lineSetId: number,
  boundaries: any,
  size: number,
  unit: number,
  lineData: IPointSet,
  options: IDragLineUISharedOptions,
  onLineMoveEnd: () => void;
  onLineClick: (lineSetId: number) => void;
  getCTM: () => DOMMatrix | null;
  lineClickSensingBorder?: string;
  hasClickSensingBorder?: boolean;
  restrictionPoints?: IRestrictionPointsData;
  isCamera: boolean;
}

interface AreaLabelProps {
  lineSetData: IPointSet,
  unit: number,
  showAreaLabelShadow: boolean
}

const AreaLabel : React.FC<AreaLabelProps> = ( { lineSetData, unit, showAreaLabelShadow } ) => {
  const pointsLength = lineSetData.points?.length;
  if (pointsLength < 3) return null;
  let midpoint = { x: 0, y: 0 };

  lineSetData.points?.map(({ x, y }) => {
    midpoint.x += x;
    midpoint.y += y;
    return null;
  });

  midpoint = { x: midpoint.x / pointsLength, y: midpoint.y / pointsLength };
  const Textlen = lineSetData.areaName?.length || 1;
  return <AreaLabelText fontSize={`${unit * 14}px`} styling={lineSetData.styling || 'primary'} x={midpoint.x - (4 * Textlen * unit)} y={midpoint.y + (6 * unit)} showAreaLabelShadow={showAreaLabelShadow}>{lineSetData.areaName}</AreaLabelText>;
};

const ZoneLineSet: React.FC<ILineSetProps> = ({ getCTM, boundaries, unit, size, lineSetId, options, onLineMoveEnd, onLineClick, lineClickSensingBorder, hasClickSensingBorder, restrictionPoints = {data: [], selectedIndex: -1}, lineData, isCamera= false }) => {
  const {state, dispatch} = useContext(LineSetContext);
  const { showLabelShadow = false } = options;
  const [handleAngles, setHandleAngles] = useState<number[]>([]);
  const handleRelation = useRef<{offsetX: number, offsetY: number}[]>([]);
  const handleRadius : number = size / 2;
  const handleUsesAngles : boolean = lineData.points?.length === 2;
  const selectedLineSetId = localStorage.getItem('selectedIndex');


  /**
   * Ensure provided position vector is within the boundaries (of the container)
   * @param {IVector2} position Vector to be enforced.
   * @returns {IVector2} The position updated clamped to range.
   */
  const enforceBoundaries = useCallback((position: IVector2) => {

    if(position.x < boundaries.x.min){
      position.x = boundaries.x.min;
    } else if (position.x > boundaries.x.max){
      position.x = boundaries.x.max;
    }

    if(position.y < boundaries.y.min){
      position.y = boundaries.y.min;
    } else if (position.y > boundaries.y.max){
      position.y = boundaries.y.max;
    }
    return position;
  }, [boundaries]);

  const handleMoveCallback = useCallback((pointerPosition: IVector2, index: number) => {
    const screenCTM = getCTM();
    if (!screenCTM) {
      return;
    }
    const pointer = enforceBoundaries({
      x: ((pointerPosition.x - screenCTM.e) / screenCTM.a),
      y: ((pointerPosition.y - screenCTM.f) / screenCTM.d)
    });

    const isInsideRestrictedPoints = pointInPolygon({
      x: ((pointerPosition.x - screenCTM.e) / screenCTM.a),
      y: ((pointerPosition.y - screenCTM.f) / screenCTM.d)
    }, restrictionPoints?.data[restrictionPoints.selectedIndex]?.points as IVector2[]);

    const pointsData = lineData.points;
    let pointsValue = {x:0, y: 0};
    if (isInsideRestrictedPoints) {
      pointsValue = {
        x: ((pointerPosition.x - screenCTM.e) / screenCTM.a),
        y: ((pointerPosition.y - screenCTM.f) / screenCTM.d)
      };

      pointsData[index] = {
        x: Math.round(pointsValue?.x),
        y: Math.round(pointsValue?.y)
      };
    } 
    else {
      pointsValue = {
        x: pointsData[index].x,
        y: pointsData[index].y
      };

      pointsData[index] = {
        x: Math.round(pointsValue?.x),
        y: Math.round(pointsValue?.y)
      };
    }
    const point = {
      x: Math.round(pointsValue?.x),
      y: Math.round(pointsValue?.y)
    };
    const boundPoint = {
      x: Math.round(pointer?.x),
      y: Math.round(pointer?.y)
    };
    const newLineSetData = update(lineData, {points:{[index]: {$merge: restrictionPoints.data?.length === 0 ? boundPoint : point}}});
    dispatch({type: 'UPDATE', index: lineSetId, data: newLineSetData });
    console.debug(state, 'state');
  }, [getCTM, enforceBoundaries, restrictionPoints, lineData, dispatch, lineSetId, state]);

  const lineDragStart = useCallback((pointerPosition: IVector2) => {
    const screenCTM = getCTM();
    if(!screenCTM){ return; }
    const pointer = {
      x: ((pointerPosition.x - screenCTM.e ) / screenCTM.a) - handleRadius,
      y: ((pointerPosition.y - screenCTM.f ) / screenCTM.d) - handleRadius
    };
    handleRelation.current = lineData.points?.map((handle) => {
      const xDiff = pointer.x - handle.x;
      const yDiff = pointer.y - handle.y;
      return {
        offsetX: xDiff,
        offsetY: yDiff
      };
    });
  },[handleRadius, lineData.points, getCTM]);

  const lineDragUpdate = useCallback((pointerPosition: IVector2) => {
    const screenCTM = getCTM();
    if(!screenCTM){ return; }
    const { points } = lineData;
    const pointer = {
      x: ((pointerPosition.x - screenCTM.e ) / screenCTM.a) - handleRadius,
      y: ((pointerPosition.y - screenCTM.f ) / screenCTM.d) - handleRadius
    };
    const newPoints = points?.map((_handle, index) => {
      const {offsetX=0, offsetY=0} = handleRelation.current[index]||{};
      return enforceBoundaries({
        x: Math.round(pointer.x - offsetX),
        y: Math.round(pointer.y - offsetY)
      });
    });
    dispatch({type: 'UPDATE', index: lineSetId, data: { ...lineData, points: [...newPoints] } });
  }, [getCTM, lineData, handleRadius, dispatch, lineSetId, enforceBoundaries]);

  const calcAngle = (originX: number, originY: number, towardsX: number, towardsY: number) => {
    const angle = Math.atan2(towardsY-originY,towardsX-originX);
    const degrees = angle*180/Math.PI + 90;
    return degrees;
  };

  const updateHandleAngles = useCallback((inputHandleData: IPointSet) => {
    const { points } = inputHandleData;
    const outputData : number[] = [];
    inputHandleData.points.forEach((handle, index) => {
      const nextIndex = ( index + 2 > points?.length ) ? 0 : index + 1 ;
      const nextHandle =  points[nextIndex];
      const angle = calcAngle(handle.x, handle.y, nextHandle.x, nextHandle.y);
      outputData.push(angle);
    });
    setHandleAngles(outputData);
  }, []);

  useEffect(() => {
    if(handleUsesAngles){
      updateHandleAngles(lineData);
    }
  }, [lineData, updateHandleAngles, handleUsesAngles]);

  const handles = (lineData?.showPointHandle === undefined || lineData?.showPointHandle) &&
    lineData.points?.map(({ x, y }, index) =>
      <HandleUnit
        key={index+lineSetId}
        lineSetId={lineSetId}
        rotate={lineData.rotate}
        Icon={lineData.icon}
        index={index}
        unit={isCamera ? 5 : unit}
        size={size}
        useAngles={handleUsesAngles}
        angle={handleAngles[index]}
        x={x}
        y={y}
        moveEndCB={onLineMoveEnd}
        moveCallback={handleMoveCallback}
        options={options}
        styling={lineData.styling}
        readOnlyHandle={lineData.readOnly}
        cameraName={lineData.cameraName}
      />
    );

  const points = options.showPoint && (
    lineData.points?.map(({ x, y }, index) => {
      const isSelected = Number(selectedLineSetId) - 1 === lineSetId;
      return (
        !isCamera ?
          <Point styling={lineData.styling||'primary'} key={index} r={unit} cx={x} cy={y} />
          :
          <g key={index} style={{ cursor: 'pointer' }} onClick={() => onLineClick(lineSetId)}>
            {<title>{lineData.cameraName}</title>}
            { !isSelected && <circle cx={x} cy={y} r={28} fill="#2994E3" /> }
            <g transform={`translate(${x - 18}, ${y - 22})`}>
              <CameraSvg width={40} height={40} color='white' />
            </g>
          </g>
      );
    })
  );

  const lines = lineData.points?.map(({x:x1,y:y1}, index) => {
    const {points, name, styling = 'primary'} = lineData;
    const nextIndex = ( index + 1 >= points?.length ) ? 0 : index + 1 ;
    if(index === 1 && nextIndex === 0){
      return null;
    }
    const {x:x2,y:y2} = points[nextIndex];

    return (
      <LineUnit
        key={index}
        moveEndCB={onLineMoveEnd}
        lineSetId={lineSetId}
        options={options}
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
        unit={unit}
        label={name}
        styling={styling}
        lineClickCallback={index !== 0 ? onLineClick : () => {}}
        lineMoveCallback={lineDragUpdate}
        lineMoveStartCallback={lineDragStart}
        showSmallDirectionMark={lineData.showSmallDirectionMark}
        overrideShowMoveHandle={lineData.showMoveHandle}
        lineClickSensingBorder={lineClickSensingBorder}
        hasClickSensingBorder={hasClickSensingBorder}
      />
    );});

  const polygonPoints = lineData.points?.map((point) => `${point.x},${point.y}`).join(' ');
  
  return (
    <g>
      <FilledPolygon points={polygonPoints} color={lineData.areaFillColor ? lineData.areaFillColor : 'transparent'} opacity={lineData.areaTransparencyLevel ? lineData.areaTransparencyLevel / 100 : 0} />
      {lines}
      {handles}
      {points}
      <AreaLabel lineSetData={lineData} unit={unit} showAreaLabelShadow={showLabelShadow} />
    </g>
  );
};

export default ZoneLineSet;