import { useState, useEffect } from 'react';

const useProgress = (
  initialProgress: number = 0, 
  increment: number = 1, 
  interval: number = 100
) => {
  const [progress, setProgress] = useState<number>(initialProgress);
  const [start, setStart] = useState<boolean>(false);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (start && progress < 100) {
      timer = setInterval(() => {
        setProgress((prevProgress) => Math.min(prevProgress + increment, 100));
      }, interval);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [start, progress, increment, interval]);

  const startProgress = () => setStart(true);
  const resetProgress = () => {
    setProgress(initialProgress);
    setStart(false);
  };

  return { progress, startProgress, resetProgress };
};

export default useProgress;


// import { useState, useEffect } from 'react';

// const useProgress = (totalFiles: number) => {
//   const [progress, setProgress] = useState<number>(0);
//   const [currentFile, setCurrentFile] = useState<number>(0);
//   const [start, setStart] = useState<boolean>(false);

//   useEffect(() => {
//     let timer: NodeJS.Timeout | undefined;
//     if (start && currentFile < totalFiles) {
//       timer = setInterval(() => {
//         setCurrentFile((prevFile) => {
//           const newFile = Math.min(prevFile + 1, totalFiles);
//           setProgress(Math.round((newFile / totalFiles) * 100));
//           return newFile;
//         });
//       }, 100); // Adjust the interval as needed
//     }
//     return () => {
//       if (timer) {
//         clearInterval(timer);
//       }
//     };
//   }, [start, currentFile, totalFiles]);

//   const startProgress = () => setStart(true);
//   const resetProgress = () => {
//     setProgress(0);
//     setCurrentFile(0);
//     setStart(false);
//   };

//   return { progress, currentFile, startProgress, resetProgress };
// };

// export default useProgress;

