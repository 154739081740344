import { gql } from '@apollo/client';

export const GET_ALL_STREAMS = gql`
  query getAllStreams {
    getAllStreams{
      result {
        deleted
        name
        enabled
        id
        instanceId
        algorithmList
        location {
          locationName
          enabledLocation
          locationName
          latitude
          longitude
        }
        instanceId
        streamType
        zone
        notes
        groupList
      }
      statusCode
      statusMessage
    }
  }
`;

export const GET_ALL_STREAMS_CLOUD = gql`
  query getAllStreams($cognitoID: String!) {
    getAllStreams(cognitoID: $cognitoID){
      data {
        id
        name
        streamType
        streamUrl
        user_id
      }
    }
  }
`;

export const GET_ALL_GROUPS = gql`
  query getAllGroup{
    getAllGroup {
      result {
        description
        name
        id
      }
    }
}`;

export const GET_RTSP_STREAM = gql`
  query getStream($streamId: String!){
    getStream(streamId: $streamId) {
      result {
        enabled
        name
        id
        instanceId
        notes
        serverId
        groupList
        streamType
        rtspUrl
        username
        password
      }
    }
}`;

export const GET_USB_STREAM = gql`
  query getStream($streamId: String!){
    getStream(streamId: $streamId) {
      result {
        enabled
        name
        id
        notes
        serverId
        groupList
        streamType
        location {
          enabledLocation
          latitude
          longitude
        }
        zone
        usbDeviceDescription
        usbDeviceName
        usbVideoSourceType
        usbDeviceInfoDict
      }
    }
}`;

export const GET_RTMP_STREAM = gql`
  query getStream($streamId: String!){
    getStream(streamId: $streamId) {
      result {
        enabled
        name
        id
        groupList
        notes
        streamType
        location {
          enabledLocation
          latitude
          longitude
        }
        zone
        rtmpServerType
        rtmpSourceUrl
        rtmpStreamKey
        rtmpStreamKeyType
        rtmpUrlType
      }
    }
  }
`;

export const GET_HLS_STREAM = gql`
  query getStream($streamId: String!){
    getStream(streamId: $streamId) {
      result {
        enabled
        name
        id
        groupList
        notes
        streamType
        location {
          enabledLocation
          latitude
          longitude
        }
        zone
        httpSourceUrl
      }
    }
  }
`;

export const GET_RTP_STREAM = gql`
  query getStream($streamId: String!){
    getStream(streamId: $streamId) {
      result {
        enabled
        name
        id
        notes
        groupList
        streamType
        zone
        videoCodec
        transportEncapsulation
        videoMulticastAddress
        videoPortRtp
        videoInterlaceMode
        videoPayloadType
        videoPortRtcp
      }
    }
  } 
`;

export const GET_ALL_DEVICES = gql`
query getAllDevices {
  getAllDevices {
    count
    result {
      allDevices {
        description
        name
      }
      availableDevices {
        description
        name
      }
    }
  }
}
`;

export const GET_OBJECT = gql`
  query getObject($objectId: Int!) {
    getObject(objectId: $objectId) {
      result {
        id
        objectTypeId
        objectProperties
        uniqueAttr
      }
      statusCode
      statusMessage
    }
  }
`;

export const GET_ALL_ALERTS = gql`
  query getAlerts($payload: AlertInput!) {
    getAlerts(payload: $payload) {
      statusCode
      statusMessage
      count
      result {
        statusCount
        data {
          alertData
          algoCode
          displayName
          eventType
          id
          imagePath
          croppedImagePath
          lastUpdate
          videoPath
          timestamp
          streamId
          status
          pinned
          ptzErrorStatus
          numberPlate
          statusReason
          statusComments
        }
      }
    }
  }
`;

export const GET_LATEST_ALERTS  = gql`
  query getLatestAlert($payload: GetLatestAlertInput!) {
    getLatestAlert(payload: $payload) {
      count
      statusCode
      statusMessage
      result {
        displayName
        id
        imagePath
        lastUpdate
        ptzErrorStatus
        status
        timestamp
        videoPath
        eventType
        alertData
        algoCode
        streamId
      }
    }
  }
`;

export const DOWNLOAD_ALERTS = gql`
  query downloadAlerts($payload: DownloadAlertsInput!){
    downloadAlerts(payload: $payload){
      result {
        file
      }
      statusCode
      statusMessage
    }
  }
`;

export const GET_STREAM = gql`
  query getStream($streamId: String!){
    getStream(streamId: $streamId) {
      result {
        id
        instanceId
        name
        deleted
        enabled
        streamType
        groupList
        username
        location {
          enabledLocation
          locationName
          latitude
          longitude
        }
        notes
        password
        suppressionSchedules
        algorithmList
      }
      statusCode
      statusMessage

    }
  }
`;

export const GET_EMAIL_CLIENT = gql`
  query getEmailClient($clientId: String!){
    getEmailClient(clientId: $clientId){
      result {
        awsAccessKey
        awsRegion
        awsSecretKey
        days
        defaultFrom
        defaultTo
        enabled
        instanceId
        hours
        minutes
        namespace
        nickname
        serviceType
        type
      }
      statusCode
      statusMessage
    }
  }
`;

export const GET_ALL_OBJECTS = gql`
query getAllObjects($payload: ObjectInput!) {
  getAllObjects(payload: $payload)  {
    count
    result {
      id
      uniqueAttr
      objectTypeId
      objectProperties
    }
    statusCode
    statusMessage
  }
}
`;

export const GET_ADV_CONFIG = gql`
  query getAdvConfig($streamId: String!){
    getAdvConfig(streamId: $streamId) {
      result {
        rtspPort
        hlsEnabled
        rtspEnabled
        services {
          recorder {
            enableSaveMp4
            enableSnapshotTimeOverlay
            audioStream
            cameraType
            enableHwAcceleration
            enableLocalStreams
            enableMp4TimeOverlay
            enabled
            webrtcPort
            frameTime
            maximumPacketDelay
            maximumPacketDelayType
            h264Profile
            lowLatencyDecode
            maximumPacketDelay
            periodicSnapshot
            periodicSnapshotResolution
            recorderType
            recordingQuality
            rtmpServerType
            rtmpSourceUrl
            rtmpStreamKey
            rtmpStreamKeyType
            rtmpUrlType
            rtspSourceBufferTime
            rtspSourcePass
            rtspSourceShortHeader
            rtspSourceUrl
            rtspSourceUser
            saveInterval
            saveOriginalSnapshot
            serviceName
            specifySnapshotResolution
            type
            videoDevice
            videoDuration
            videoFileMaxSize
            videoFlipMethod
            videoFps
            videoHeight
            enableVideoMulticast
            pixelAspectRatio
            videoPortRtcp
            enableVideoRtcpMuxed
            enableAudio
            rtpAudioSourceType
            videoPayloadType
            enableAudioMulticast
            audioMulticastAddress
            audioPayloadType
            audioPortRtp
            enableAudioRtcpMuxed
            audioPortRtcp
            videoSourceType
            videoSplitMode
            videoWidth
          }
        }
      }
      statusCode
      statusMessage
    }
  }  
`;

export const GET_DEVICE_INFO = gql`
 query getDeviceInfo($serverId: Int) {
  getDeviceInfo(serverId: $serverId) {
    count
    result {
      deviceId
      networks {
        interface
        ipAddress
        isAp
        macAddress
      }
    }
    statusCode
    statusMessage
  }
}
`;

export const GET_SCHEDULES_LIST = gql`
  query getSchedules($pageNumber: Int, $pageSize: Int) {
    getSchedules(pageNumber: $pageNumber, pageSize: $pageSize)  {
      result {
        id
        name
        assignedCameras
        rules {
            days
            endTime
            startTime
          }
        }
        count
        statusCode
        statusMessage
    }
  }
`;

export const GET_SCHEDULE = gql`
  query getSchedule($scheduleId: Int!) {
    getSchedule(scheduleId: $scheduleId)  {
      result {
        id
        name
        rules {
            days
            endTime
            startTime
          }
        }
        statusCode
        statusMessage
    }
  }
`;

export const GET_ANALYSIS_CONFIGS = gql`
  query getAnalysisConfig($streamId: String!) {
    getAnalysisConfig(streamId: $streamId) {
      result {
        algorithmCode
        algorithmName
        configs
        enabled
        id
        scheduleId
        algoType
        streamId
      }
    }
  }
`;

export const GET_FLOOR_PLAN = gql`
  query getAllFloorPlans {
    getAllFloorPlans {
      result {
        data
        id
        image
        level
      }
      statusCode
      statusMessage
      count
    }
  }
`;


export const GET_CONFIGURED_ALGOS = gql`
  query getAnalysisConfig($streamId: String!) {
    getAnalysisConfig(streamId: $streamId) {
      result {
        algorithmId
      }
    }
  }
`;
    
export const GET_EVENT_PIPELINES = gql`
  query getEventPipelines($pageNumber: Int, $pageSize: Int) {
    getEventPipelines(pageNumber: $pageNumber, pageSize: $pageSize) {
      count
      result {
        id
        actions {
          id
          data
          templateId
          type
        }
        algoCode
        event
        filters
        name
        scheduleId
        enabled
      }
      statusCode
      statusMessage
    }
  }
`;

export const GET_ALL_ALGORITHMS = gql`
  query getAllAlgorithm {
    getAllAlgorithm {
      result {
        description
        id
        name
        code
        actions
        events
      }
      statusCode
      statusMessage
    }
  }
`;

export const GET_STREAM_STATUS = gql`
  query getStreamStatus($streamId: String!) {
    getStreamStatus(streamId: $streamId) {
      result {
        status
      }
      statusCode
      statusMessage
    }
  }
`;

export const GET_EVENT_PIPELINE = gql`
  query getEventPipeline($epId: Int!) {
    getEventPipeline(epId: $epId)  {
      result {
        actions {
          data
          id
          templateId
          type
        }
        algoCode
        filters
        event
        id
        name
        scheduleId
        enabled
      }
      count
      statusCode
      statusMessage
    }
  }
`;

export const GET_ALERT_AUDIO = gql`
  query getAlertAudio($recordType: String!) {
    getAlertAudio(recordType: $recordType) {
      result
      statusCode
      statusMessage
    }
  }
`;

export const GET_INTEGRATION_SETTING= gql`
  query getIntegrationSetting($recordType: String!) {
    getIntegrationSetting(recordType: $recordType) {
      result
      statusCode
      statusMessage
    }
  }
`;

export const GET_EXPORT_SETTING = gql`
  query getExportSetting($recordType: String!) {
    getExportSetting(recordType: $recordType) {
      result
      statusCode
      statusMessage
    }
  }
`;

export const GET_DASHBOARD_INFO = gql`
  query getDashboardsInfo {
    getDashboardsInfo {
      result {
        chartsInfo {
          datasource
          dashboardId
          description
          permalink
          sliceId
          sliceName
          sequenceId
          url
          vizType
        }
        id
        permalink
        status
        thumbnailUrl
        title
        url
      }
      statusCode
      statusMessage
    }
  }
`;

export const GET_STREAM_ALERT_STATUS = gql`
  query getAlertStatusByStreamId($streamId: String!, $relativeLatestId: Int){
    getAlertStatusByStreamId(streamId: $streamId, relativeLatestId: $relativeLatestId) {
      statusCode
      statusMessage
      result {
        hasActiveAlert
        relativeLatestId
      }
    }
  }
`;

export const GET_DASHBOARD_CHARTS = gql`
  query getStoredChartsInfo($pageName: String!, $username: String!) {
    getStoredChartsInfo(pageName: $pageName, username: $username) {
      result {
        data {
          id
          permalink
          status
          thumbnailUrl
          title
          url
          chartsInfo {
            datasource
            dashboardId
            description
            permalink
            sliceId
            linkStatus
            sequenceId
            width
            height
            sliceName
            url
            vizType
          }
        }
        pageName
      }
    }
  }
`;

export const GET_WHATSAPP_NUMBERS = gql`
  query getWhatsappDetails($payload: GetWhatsappDetails!){
    getWhatsappDetails(payload: $payload){
      statusCode
      statusMessage
      result
    }
  }
`;

export const EXPORT_ALERT_DATA = gql`
  query exportData($payload: ExportDataInput!){
    exportData(payload: $payload){
      result { file, smbDetails }
      statusCode
      statusMessage
    }
  }
`;

export const GET_USER_DETAILS = gql`
  query getUser($username: String!){
    getUser(username: $username) {
      result {
        id
        name
        username
        email
        countryCode
        phoneNumber
        roleId
        isActive
        createdAt
        updatedAt
        lastLogin
        imagePath
      }
      statusCode
      statusMessage
    }
  }
  `;

export const EXPORT_SYSTEM_CONFIGURATION = gql`
  query exportSystemConfiguration{
    exportSystemConfiguration{
      result { file }
      statusCode
      statusMessage
    }
  }
`;

export const GET_ALL_OBJECT_TYPES = gql`
  query getAllObjectTypes($pageNumber: Int, $pageSize: Int) {
    getAllObjectTypes(pageNumber: $pageNumber, pageSize: $pageSize) {
      result {
        description
        id
        isDefault
        name
        properties
      }
      statusCode
      statusMessage
    }
  }
`;

export const GET_TEMPLATES = gql`
  query getTemplates($type: String){
    getTemplates(type: $type){
      result {
        templates {
          body
          subject
          id 
          name
          templateType
        }
      }
      statusCode
      statusMessage
    }
  }
`;

export const GET_OBJECT_TYPE = gql`
  query getObjectType($id: Int!) {
    getObjectType(id: $id) {
      result {
        description
        id
        isDefault
        name
        properties
      }
      statusCode
      statusMessage
    }
  }
`;

export const GET_ROLES_LIST = gql`
  query listRoles($pageNumber: Int, $pageSize: Int){
    listRoles(pageNumber: $pageNumber, pageSize: $pageSize) {
      statusMessage
      statusCode
      count
      result {
        id
        name
        description
        assignedUsers
      }
    }
}`;

export const EXPORT_OBJECTS = gql`
  query exportObjects($payload: ExportObjects!){
    exportObjects(payload: $payload){
      statusCode
      statusMessage
      result {
        file
      }
    }
  }
`;

export const GET_SMTP_CLIENT = gql`
  query getSmtpClient{
    getSmtpClient{
      result {
        smtpSenderEmail
        smtpServerPort
        smtpSenderPassword
        smtpServerAddress
      }
      statusCode
      statusMessage
    }
  }
`;

export const GET_ALL_DETECTIONS = gql`
  query getDetections($payload: DetectionInput!) {
    getDetections(payload: $payload) {
      statusCode
      statusMessage
      count
      result {
        data {
          algoCode
          confidence
          detectionDate
          detectionData
          detectionDatetime
          detectionHour
          displayName
          h
          id
          imagePath
          labelName
          objectName
          streamId
          trackingId
          videoPath
          w
          x
          y
        }
      }
    }
  }
`;

export const DOWNLOAD_DETECTIONS = gql`
  query downloadDetections($payload: DownloadDetectionsInput!){
    downloadDetections(payload: $payload){
      result {
        file
      }
      statusCode
      statusMessage
    }
  }
`;

export const GET_ALERT_STATUS_BY_STREAM_IDS = gql`
  query getAlertStatusByStreamIds {
    getAlertStatusByStreamIds {
        statusCode
        statusMessage
        count
        result {
            streamId
            hasActiveAlert
        }
    }
  }
`;

export const GET_GUEST_USER_INFO = gql`
  query getUserMgmtInfo($recordType: String!) {
    getUserMgmtInfo(recordType: $recordType) {
      result
      statusCode
      statusMessage
      count
    }
  }
`;

export const GET_USERMANAGEMENT_INFO = gql`
  query getUserMgmtInfo {
    getUserMgmtInfo(recordType: "user_management_info") {
      result
      statusCode
      statusMessage
    }
  }
`;

export const GET_ALL_GRAFANA_CHARTS = gql`
  query getGrafanaChartsInfo {
    getGrafanaChartsInfo {
      result {
        dashboards {
          chartInfo {
            dashboardId
            description
            height
            id
            title
            url
            width
          }
          id
          title
          uid
        }
        hostIp
      }
      statusCode
      statusMessage
    }
  }
`;

export const GET_STORED_GRAFANA_CHARTS = gql`
  query getStoredGrafanaCharts($pageName: String!, $username: String!) {
    getStoredGrafanaCharts(pageName: $pageName, username: $username) {
      result {
        dashboards {
          chartInfo {
            description
            height
            id
            sequenceId
            title
            url
            width
          }
          id
          title
          uid
        }
        hostIp
      }
      count
      statusCode
      statusMessage
    }
  }
`;

export const GET_VIDEO_TIMELINE = gql`
  query getVideoTimeline($payload: TimelineInput!) {
    getVideoTimeline(payload: $payload) {
      result {
        videoTimelines {
          id
          streamId
          videoDuration
          videoEndTime
          videoName
          videoPath
          videoStartTime
          videoThumbnailPath
        }
      }
      statusCode
      statusMessage
    }
  }
`;

export const DOWNLOAD_GRAFANA_CHARTS_PDF = gql`
  query storeGrafanaChartsPdf($payload: StoreChartsPDFInput!) {
    storeGrafanaChartsPdf(payload: $payload) {
      result
      statusCode
      statusMessage
    }
  }
`;

export const GET_DETECTIONS_COUNT = gql`
  query getDetectionCount($payload: DetectionsCountInput!){
    getDetectionCount(payload: $payload) {
      result {
        totalCount
        data
      }
      statusCode
      statusMessage
    }
  }
`;

export const GET_SERVER = gql`
  query getServer($serverId: Int!){
    getServer(serverId: $serverId) {
      result {
        description
        id
        mdnsName
        name
        serverIp
        isMaster
      }
      statusCode
      statusMessage
    }
  }
`;

export const GET_ALL_SERVER = gql`
  query getAllServers{
    getAllServers{
      result {
        mdnsName
        name
        serverIp
        id
        description
        isMaster
      }
      statusCode
      statusMessage
    }
  }
`;

export const GET_FILTERED_CHARTS = gql`
  query getFilteredCharts($payload: FilterChartsInput!) {
    getFilteredCharts(payload: $payload) {
      result {
        chartsInfo {
          dashboardId
          datasource
          description
          height
          linkStatus
          permalink
          sequenceId
          sliceId
          sliceName
          url
          vizType
          width
        }
        id
        permalink
        status
        thumbnailUrl
        title
        url
      }
      count
      statusCode
      statusMessage
    }
  }
`;

export const GET_FOLDER = gql`
  query getDevice($device_id: String!){
    getDevice(device_id: $device_id) {
      statusMessage
	    statusCode
	    data {
        enabled
        name
        id
        notes
        groupList
        streamType
        location {
          enabledLocation
          latitude
          longitude
        }
      }
    }
  } 
`;
