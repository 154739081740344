import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgExpand = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="m3.8 3.8 16.4 16.4m0-16.4L3.8 20.2M15 3h6v6M9 3H3v6m12 12h6v-6M9 21H3v-6"
        stroke={props.color}
        strokeWidth={props.weight}
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={"non-scaling-stroke"}
      />
    </svg>
  );
};
export default SvgExpand;
