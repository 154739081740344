import { useQuery } from '@apollo/client';
import { GET_ALERT_STATUS_BY_STREAM_IDS } from 'api_configs/queries';
import  { useCallback } from 'react';

interface IProps {
  fetchApi: boolean
}

export const useStreamAlertStatus = ({fetchApi}: IProps) => {
  const { refetch: refetchForAllStreams, loading: refetchForAllStreamsLoading } = useQuery(GET_ALERT_STATUS_BY_STREAM_IDS, {skip: !fetchApi });

  const getAlertStatusForAllStreams = useCallback(async () => {
    if(fetchApi) {
      const res = await refetchForAllStreams();
      if(res && res.data?.getAlertStatusByStreamIds?.result !== null){
        return res.data?.getAlertStatusByStreamIds?.result;
      }
    }
    return [];
  }, [refetchForAllStreams, fetchApi]);

  return {
    getAlertStatusForAllStreams,
    refetchForAllStreamsLoading
  };
};