import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgImageFileUpload = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M3.267 19.813h15.2V21H2V2h11.034l5.433 5.093v.845h-6.334v-4.75H3.267v16.625ZM13.4 6.75h2.9l-2.9-2.718V6.75ZM21 9.125v9.5H7.067v-9.5H21ZM8.333 10.313v3.905l3.167-2.96 4.117 3.86 1.583-1.484 2.533 2.375v-5.697h-11.4Zm9.768 7.124h1.375L17.2 15.305l-.683.65 1.584 1.483Zm-9.768 0H16.3L11.5 12.93l-3.167 2.978v1.53Zm9.5-4.75a.63.63 0 0 1-.445-.176.554.554 0 0 1-.188-.417c0-.161.063-.3.188-.418a.63.63 0 0 1 .445-.176.63.63 0 0 1 .446.176.553.553 0 0 1 .188.418c0 .16-.063.3-.188.417a.63.63 0 0 1-.446.177Z"
        fill={props.color}
      />
    </svg>
  );
};
export default SvgImageFileUpload;
