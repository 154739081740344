import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { FiMinus } from 'react-icons/fi';
import styled from 'styled-components';
import { IoMdAdd } from 'react-icons/io';
import { GET_FLOOR_PLAN } from 'api_configs/queries';
import { useQuery } from '@apollo/client';
import ZoneMapLineViewer from 'components/ZoneMapLineViewer';
import { getFillAreaColor } from 'utils/utils';
import { IFloorPlanConfigs, IFloorPlanConfigsRes, IPoints } from 'interface';
import { LineUIType } from '../../constants';
import { IPointSet, IRestrictionPointsData, LineReducer } from 'components/LineUI';
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
} from 'react-zoom-pan-pinch';
import { MdMyLocation } from 'react-icons/md';
import Toggle from 'components/Toggle';

const ModalContainer = styled.div`
  border: 2px solid ${({theme}) => theme.divider};
  border-radius: 6px;
  width: 100%;
  height: 100%;
`;

const FloorPlanWrapper = styled.div<{width: string, height: string}>`
  position: relative;
  background: 'white';
  width: ${({width}) => width};
  height: ${({height}) => height};
`;

const ZoomInContainer = styled.div<{disable: boolean}>`
  border-bottom: 2px solid ${({theme}) => theme.divider};
  width: 35px;
  height: 35px;
  text-align: center;
  pointer-events: ${({disable})=> disable ? 'none' : 'auto'};
  cursor: ${({disable})=> disable ? 'auto' : 'pointer'};
  color: ${({disable})=> disable ? 'gray' : ''};
  > svg {
    margin-top: 4px;
  }
`;

const ZoomOutContainer = styled.div<{disable: boolean}>`
  width: 35px;
  height: 35px;
  text-align: center;
  pointer-events: ${({disable})=> disable ? 'none' : 'auto'};
  cursor: ${({disable})=> disable ? 'auto' : 'pointer'};
  color: ${({disable})=> disable ? 'gray' : ''};
  > svg {
    margin-top: 4px;
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 2px solid ${({theme}) => theme.divider};
  left: 10px;
  top: 10px;
  position: absolute;
  z-index: 1000 ;
  background-color: white;
`;

const ToggleWrapper =styled.div`
  display: flex;
  left: 55px;
  top: 10px;
  position: absolute;
  z-index: 1000 ;
`;

const ResetButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  border: 2px solid ${({theme}) => theme.divider};
  right: 50px;
  top: 10px;
  position: absolute;
  z-index: 1000 ;
  width: 36px;
  height: 36px;
  background-color: white;
  cursor: pointer;
`;

interface ZoneMapProps {
  defaultZoom: number;
  height: string;
  width: string;
  instanceId: string;
  isZoneMapTab?: boolean;
}

const ZoneMap: React.FC<ZoneMapProps> = ({ defaultZoom, height, width, instanceId='', isZoneMapTab= false}) => {
  const { data: floorPlan } = useQuery(GET_FLOOR_PLAN);
  const [file, setFile] = useState({filename: '', base64: ''});
  const [state, dispatch] = useReducer(LineReducer,[]);
  const [selectedAlgoLineDetails, setSelectedAlgoLineDetails] = useState<IPointSet[]>([]);
  const [ allLayerConfigDetails, setAllLayerConfigDetails] = useState<IFloorPlanConfigsRes[]>([{ id: 0, level: '', data: [], image: ''}]);
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);
  const [restrictionPoints, setRestrictionPoints] = useState<IRestrictionPointsData>();
  const [selectedTab, setSelectedTab] = useState(0);

  const getLineDataOfSelectedAlgorithm = useCallback((configs: (IFloorPlanConfigs)[]): IPointSet[]=>{
    const lineData: IPointSet[] = [];
    configs?.map((config)=>{
      if(config?.lineType === LineUIType.AREA){
        const areaDetails = {
          areaName: config.name,
          points: (config as IFloorPlanConfigs)?.points as IPoints[],
          styling: config.styling,
          showMoveHandle: false,
          showPointHandle: false,
          showSmallDirectionMark: false,
          areaFillColor: getFillAreaColor(config.styling),
          areaTransparencyLevel: 40
        };
        lineData.push(areaDetails);
      } 
      return null;
    });
    return lineData;
  },[]);

  useEffect(()=>{
    const floorPlanData = floorPlan?.getAllFloorPlans?.result !== null ? floorPlan?.getAllFloorPlans?.result : [];
    let selectedLayerDetails= [];
    let linedata = [];
    localStorage.setItem('selectedIndex', '0');
    if( isZoneMapTab || instanceId !== ''){
      const CameraData = floorPlanData?.filter((item :IFloorPlanConfigsRes) => item.level === 'THIRD');
      const selectedZone = CameraData && CameraData[0]?.data?.filter((item: IFloorPlanConfigs) => item.name === instanceId );
      selectedLayerDetails = floorPlanData?.filter((item :IFloorPlanConfigsRes) => item.level === 'SECOND');
      const restrictionLinedata = getLineDataOfSelectedAlgorithm(selectedLayerDetails && selectedLayerDetails?.[0]?.data.filter((item: IFloorPlanConfigs) => item.name === selectedZone?.[0]?.zoneName ));
      setRestrictionPoints({data:restrictionLinedata, selectedIndex: -1});
      linedata = getLineDataOfSelectedAlgorithm(selectedZone && selectedZone);
    }else{
      selectedLayerDetails = floorPlanData?.filter((item :IFloorPlanConfigsRes) => item.level === 'FIRST');
      linedata = getLineDataOfSelectedAlgorithm(selectedLayerDetails && selectedLayerDetails[0]?.data);
      setRestrictionPoints({data:[], selectedIndex: -1});
    }
    setAllLayerConfigDetails(floorPlanData);
    setSelectedAlgoLineDetails(linedata);
    if(selectedLayerDetails && selectedLayerDetails[0]?.data) setFile({filename: '', base64: selectedLayerDetails[0]?.image ? selectedLayerDetails[0]?.image as string : ''});
    dispatch({
      type: 'LOAD',
      state: linedata,
    });
  },[floorPlan, getLineDataOfSelectedAlgorithm, instanceId, isZoneMapTab]);

  const handleImageClick = useCallback((index: number) => {
    setSelectedTab(index);
    let linedata=[];
    if(index === 1){
      const selectedLayerDetails = allLayerConfigDetails?.filter((item :IFloorPlanConfigsRes) => item.level === 'SECOND');
      const restrictionLinedata = getLineDataOfSelectedAlgorithm(selectedLayerDetails && (selectedLayerDetails[0]?.data) as IFloorPlanConfigs[]);
      setRestrictionPoints({data:restrictionLinedata, selectedIndex: -1});
      const CameraData = allLayerConfigDetails?.filter((item :IFloorPlanConfigsRes) => item.level === 'THIRD');
      linedata = getLineDataOfSelectedAlgorithm(CameraData && (CameraData[0]?.data) as IFloorPlanConfigs[]);
    
    }else{
      const selectedLayerDetails = allLayerConfigDetails?.filter((item :IFloorPlanConfigsRes) => item.level === 'FIRST');
      linedata = getLineDataOfSelectedAlgorithm(selectedLayerDetails && (selectedLayerDetails[0]?.data) as IFloorPlanConfigs[]);
      setRestrictionPoints({data:[], selectedIndex: -1});
    }
    setSelectedAlgoLineDetails(linedata);
    dispatch({
      type: 'LOAD',
      state: linedata,
    });
    console.debug(state, 'state'); //required
  },[allLayerConfigDetails, getLineDataOfSelectedAlgorithm, state]);

  const zoomToImage = () => {
    if (transformComponentRef.current) {
      const { zoomToElement } = transformComponentRef.current;
      zoomToElement('imgExample');
    }
  };

  const tabButtons = [
    { label: 'Area', selected: selectedTab === 0 },
    { label: 'Zones', selected: selectedTab === 1 },
  ];

  return (
    <>
      <ModalContainer>
        <TransformWrapper
          initialScale={defaultZoom}
          initialPositionX={0}
          initialPositionY={0}
          ref={transformComponentRef}
        >
          {(utils) => (
            <React.Fragment>
              <IconsWrapper>
                <ZoomInContainer disable={false} onClick={() => utils.zoomIn()}>
                  <IoMdAdd size={22} />
                </ZoomInContainer>
                <ZoomOutContainer disable={false} onClick={() => utils.zoomOut()}>
                  <FiMinus size={22} />
                </ZoomOutContainer>
              </IconsWrapper>
              { instanceId === '' &&<ToggleWrapper>
                <Toggle enabled={true} tabButtons={tabButtons} selected={selectedTab} onTabClick={(index) => {handleImageClick(index);utils.resetTransform();} } />
              </ToggleWrapper>}
              <ResetButton onClick={() => utils.resetTransform()}>
                <MdMyLocation size={24} />
              </ResetButton>
              <TransformComponent>
                <FloorPlanWrapper id='imgExample' width={width} height={height}>
                  <ZoneMapLineViewer onLineClickCallback={() => { }} showDirectionMark={false}  width='100%' height='100%' state={selectedAlgoLineDetails} dispatch={dispatch} hasFullScreenToggle={false} floorMapImg={file?.base64 ? file.base64 : ''} restrictionPoints={ restrictionPoints?.data?.length !== 0 ? restrictionPoints : { data: [], selectedIndex: -1}} isCamera={restrictionPoints?.data?.length !== 0} />
                </FloorPlanWrapper>
                <div onClick={zoomToImage}></div>
              </TransformComponent>
            </React.Fragment>
          )} 
        </TransformWrapper>
      </ModalContainer>
    </>
  );
};

export default ZoneMap;