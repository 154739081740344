import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Input from './Input';
import Button from './Button';
import TokenService from 'api_configs/tokenService';
import { useMutation } from '@apollo/client';
import { UPDATE_PASSWORD } from 'api_configs/mutations';
import { IAlertMessageType } from 'interface';
import AlertBar from './AlertBar';
import { IconType, StatusCode } from '../constants';
import ButtonWithLoading from './ButtonWithLoading';

const Container = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
`;

const PasswordContainer = styled.div`
  margin-top: 30px;
`;

const StatusBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: -12px;
`;

const StatusBar = styled.div<{ color: string }>`
  width: 130px;
  height: 5px;
  margin: -6px 0 37px 0;
  border-radius: 2.5px;
  background-color: ${({ color }) => color};
`;

const StatusText = styled.div`
  font-size: 16px;
  margin-bottom: 0;
  margin-top: -13px;
  span{
    margin-bottom: 0;
    margin-left:12px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
  justify-content: center;
`;

const StatusContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 202px;
  right: 26px;
  > label {
    margin-left: auto;
  }
`;

const InputBox = styled.div`
  margin-bottom: 15px;
  label > div > div:nth-child(2) > div > div {
    height: 20px;
  }
`;

interface IUpdatePassword {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdatePassword: React.FC<IUpdatePassword> = ({setModalOpen}) => {
  const {t} = useTranslation(['common']);
  const [password, setPassword] = useState({old_password:'', new_password: '', confirmPassword: '' });
  const [passwordStatus, setPasswordStatus] = useState('#d9d9d9');
  const [goodPassword, setGoodPassword] = useState('');
  const [newPasswordStatus, setNewPasswordStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'});
  const [userUpdatePassword] = useMutation(UPDATE_PASSWORD);

  useEffect(() => {
    if (password.old_password !== '') {
      if(password.new_password === ''){
        setPasswordStatus('#d9d9d9');
        setNewPasswordStatus('Enter New Password');
      } else {
        if(password.old_password === password.new_password){
          setPasswordStatus('#ff6666');
          setNewPasswordStatus('Old and New password can not be same');
          return;
        } else {
          setPasswordStatus('#d9d9d9');
          setNewPasswordStatus('');
        }
      }
    } else {
      setPasswordStatus('#d9d9d9');
      setNewPasswordStatus('');
    }
    if(password.confirmPassword === '' && password.new_password === ''){
      setPasswordStatus('#d9d9d9');
      setGoodPassword('');
    } else {
      if(password.confirmPassword === password.new_password){
        setPasswordStatus('#9dd09d');
        setGoodPassword('Matched');
      } else {
        setPasswordStatus('#ff6666');
        setGoodPassword('Not Matched');
      }
    } 
  }, [password]);

  const UpdatePassword = useCallback(async () => {
    setLoading(true);
    const { old_password, new_password, confirmPassword } = password;
    if (new_password.trim() === '') {
      setMessage({ text: t('Please enter a valid password. Only spaces are not allowed.'), id: '', type: 'danger' });
      return;
    }
    if (confirmPassword === new_password) {
      const useraname = TokenService.getUser().username;
      try {
        const data = await userUpdatePassword({variables: {input: {
          username: useraname, 
          newPassword: new_password, oldPassword: old_password}}});
        if (data.data.updateUserPassword.statusCode === StatusCode.SUCCESS) {
          setMessage({ text: t('Password updated successfully!'), id: '', type: 'success' });
          setTimeout(() => {
            setModalOpen(false);
            TokenService.removeUser();
            window.location.href = '/login';
          }, 2000); 
        }else if (data.data.updateUserPassword.statusCode === StatusCode.FAILED) {
          setMessage({ text: t('Old password doesnot match'), id: '', type: 'danger' });
        }
        else {
          setMessage({ text: t('Something wrong'), id: '', type: 'danger' });
        }
      } catch (error) {
        setMessage({ text: t('Failed to communicate with the system'), id: '', type: 'danger' });
        console.error((error as Error).message);
      }
    }
    setLoading(false);
  }, [password, t, userUpdatePassword, setModalOpen]);

  const isEnableUpdate = useCallback(() => {
    if (password.old_password===''|| password.confirmPassword === '' || password.new_password === ''){
      return true;
    }
    if (password.new_password === password.confirmPassword && password.old_password !== password.new_password){
      return false;
    }
    return true;
  }, [password]);

  return (
    <Container>
      <Title>{t('Update Password')}</Title>
      <PasswordContainer>
        <InputBox>
          <Input
            id='oldpassword'
            type='password'
            label={t('Old Password')}
            onChange={(e) => setPassword({...password,[e.target.name]:e.target.value}) }
            value={password.old_password}
            name='old_password'
            maxLength={32}
            width={'400px'}
          />
        </InputBox>
        <InputBox>
          <Input
            id='newpassword'
            type='password'
            label={t('New Password')}
            onChange={(e) => setPassword({...password,[e.target.name]:e.target.value}) }
            value={password.new_password}
            name='new_password'
            maxLength={32}
            width={'400px'}
          />
        </InputBox>
        {newPasswordStatus !== '' &&
          <StatusContainer>
            <StatusText>{t(newPasswordStatus)} </StatusText>
          </StatusContainer> 
        }
        <InputBox>
          <Input
            id='confirmpassword'
            type='password'
            label={t('Confirm New Password')}
            onChange={(e) => setPassword({...password,[e.target.name]:e.target.value}) }
            value={password.confirmPassword}
            name='confirmPassword'
            maxLength={32}
            width={'400px'}
          />
        </InputBox>
        <StatusBox>
          <StatusBar color={passwordStatus} />
          <StatusText>{t(goodPassword)} </StatusText>
        </StatusBox>
        {message && <AlertBar message={t(message.text)} setMessage={setMessage} 
          type={message.type as IconType} />}
        <ButtonWrapper>
          <Button variant='secondary' onClick={() => { setModalOpen(false); }} >{t('Cancel')}</Button>
          <ButtonWithLoading loading={loading} variant='primary' disabled={isEnableUpdate()} onClick={UpdatePassword} type='submit'>{t('Update Password')}</ButtonWithLoading>
        </ButtonWrapper>
      </PasswordContainer>
    </Container>    
  );
};

export default UpdatePassword;