import React, {TextareaHTMLAttributes} from 'react';
import styled from 'styled-components';
import Label from './Label';

interface OwnProps {
  rows: number,
  cols: number,
  width?: string,
  height?: string,
  label?: string,
  isRequired?: boolean
}

type Props = OwnProps & TextareaHTMLAttributes<HTMLTextAreaElement>

const TextAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Notes = styled.textarea<{width:string, height: string}>`
  resize: none;
  border-radius: 5px;
  width: ${({width})=>width};
  font-size: 18px;
  height: ${({height})=>height};
  border: 1px solid lightgrey;
  font-family: Arial, Helvetica, sans-serif;
  padding: 5px;
  margin-bottom: 16px;
  &:focus{
    outline: none;
    border-color: ${({theme}) => theme.input.focus};
  }
  &:disabled{
    cursor: no-drop;
  }
`;

const TextArea: React.FC<Props> = ({rows, cols, onChange, value, width='450px', name, label='', height='40px', isRequired=false, ...props}) => {
  return (
    <TextAreaContainer>
      <Label labelText={label} isMandatory={isRequired} />
      <Notes rows={rows} height={height} name={name} cols={cols} value={value} onChange={onChange} width={width} {...props}/>
    </TextAreaContainer>
  );
};

export default TextArea;