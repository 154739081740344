import { LatLngBoundsExpression } from 'leaflet';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet';
import MapMarker from '../../svgs/camera.svg';
import styled, { keyframes } from 'styled-components';
import LineViewer from 'components/LineViewer';
import { IStream } from 'interface';
import Config from '../../configs/config.json';
import { LEAFLET_ONLINE_URL, LEAFLET_TILE_URL, MAP_CENTER } from '../../constants';
import { renderToStaticMarkup } from 'react-dom/server';
import { MdMyLocation } from 'react-icons/md';
import { useAnnotation } from 'contexts/AnnotaionContext';
import { useHistory } from 'react-router';
import l from 'leaflet';
import { css } from 'styled-components';
import { useStreamAlertStatus } from 'hooks/useStreamAlertStatus';
import FitMapBoundsMemo from './FitMapBounds';
import { AlertsContext } from 'contexts/AlertsContext';

const CustomMarker = styled(Marker)``;

const CustomPopUp = styled(Popup)`
  :first-child>div{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: auto;
    border-radius: 6px;
  }
  .leaflet-popup-content{
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 5px;
  }
  .leaflet-container a.leaflet-popup-close-button {
    top: 2px;
    right: 2px;
  }
  .leaflet-popup-tip-container {
    visibility: hidden;
  }
`;

const CameraName = styled.div`
  font-size: 14px;
  cursor: pointer;
  color: #0B2588;
`;

const ToolTipImg = styled.div`
  width: 250px;
  height: 100%;
  aspect-ratio: 16/9;
  object-fit: contain;
`;

const ResetButton = styled.div`
  position: absolute;
  cursor: pointer;
  width: 36px;
  height: 36px;
  padding: 6px;
  background-color: ${({theme}) => theme.backgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 2px solid ${({theme}) => theme.divider};
  top: 10px;
  right: 10px;
  z-index: 1000;
  :hover{
    background-color: #F5F6FA;
  }
`;

const CustomMarkerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100px;
  background-color: transparent;
  margin-left: -14px;
  position: relative;
`;

const CameraIcon = styled.img`
  width: 48px;
  height: 48px;
`;

const LocationDiv = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  margin-top: -4px;
  margin-left: -5px;
  width: 100px;
  padding:5px;
`;

const pulse = keyframes`
  100%{
    transform: scale(1.7);
    opacity: 0;
  }
`;

const BlinkDiv = styled.div<{isBlinking: boolean, isEnabled: boolean}>`
  ${({ isBlinking, isEnabled }) =>
    isBlinking ?
      isEnabled ? 
        css`
          &:before,&:after{
            content: "";
            position: absolute;
            left: 30px;
            width: 46px;
            height: 46px;
            border-radius: 50%;
            background-color: #fe190083;
            z-index: 6;
            opacity: 0.6;
          }
          &:before{
            animation: ${pulse} 2s ease-out infinite;
          }
          &:after{
            animation: ${pulse} 2s 1s ease-out infinite;
          }
        `:
        css`
        &:before{
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          top: 6px;
          right: 30px;
          border-radius: 50%;
          background-color: #fe1900;
          z-index: 6;
        }
    ` : null}
`;

interface IRecenterProps {
  bounds: number[][];
  boundsPresent?: boolean; 
}

export const ResetMapView: React.FC<IRecenterProps> = ({bounds}) => {
  const map = useMap();
  useEffect(() => {
    map.flyToBounds(bounds as LatLngBoundsExpression, {padding: [40, 40]});
  }, [bounds, map]);
  return null;
};

interface IProps {
  setHighlightStreamId: React.Dispatch<React.SetStateAction<string>>;
  streamsList?: IStream[];
}

const Map: React.FC<IProps> = ({setHighlightStreamId, streamsList=[]}) => {
  const [allStreams, setAllStreams] = useState<IStream[]>([...streamsList]);
  const [locations, setLocations] = useState<number[][]>([]);
  const {isOfflineMap} = useAnnotation();
  const { push } = useHistory();
  const [mapCenterClicked, setMapCenterClicked] = useState<boolean>(false);
  const { getAlertStatusForAllStreams } = useStreamAlertStatus({fetchApi: Config.enableAlertStatusFilterOnCamerasPage});
  const { alertRefreshInterval } = useContext(AlertsContext);

  const fetchAlertStatusOfStreams = useCallback(async () => {
    const allStreamsAlertStatus: { hasActiveAlert: boolean, streamId: string }[] = await getAlertStatusForAllStreams();
    setAllStreams(prev => {
      let tempStreams = [...prev];
      tempStreams = tempStreams.map(stream => {
        const streamAlertStatus = allStreamsAlertStatus?.find(item => item.streamId === stream.instanceId)?.hasActiveAlert;
        return { ...stream, alertStatus: { ...stream.alertStatus, hasActiveAlert: streamAlertStatus ?? false } };
      });
      return tempStreams;
    });
  }, [getAlertStatusForAllStreams]);

  useEffect(() => {
    if(Config.enableAlertStatusFilterOnCamerasPage) {
      fetchAlertStatusOfStreams();
      let fetchInterval: any;
      if(alertRefreshInterval !== 'none'){
        fetchInterval = setInterval(() => {
          fetchAlertStatusOfStreams();
        }, parseInt(alertRefreshInterval)*1000);
      }
      return () => clearInterval(fetchInterval);
    }
  }, [fetchAlertStatusOfStreams, alertRefreshInterval]);

  const getAllStreams = useCallback(async()=>{
    const configuredCameraLocations = allStreams.filter(item=> item.location !== null && item.location.enabledLocation)?.map(item =>{
      return [Number(item.location.latitude), Number(item.location.longitude)];
    });
    setLocations(configuredCameraLocations);
  },[allStreams]);

  useEffect(()=>{
    getAllStreams();
  },[getAllStreams]);

  const CustomIconContent = ({streamDetails}: {streamDetails: IStream}) => (
    <CustomMarkerWrapper>
      <BlinkDiv isBlinking={streamDetails?.alertStatus?.hasActiveAlert} isEnabled={streamDetails?.enabled} />
      <CameraIcon src={MapMarker} alt="Icon" style={{backgroundColor: 'transparent'}}/>
      <LocationDiv>{streamDetails?.location?.locationName}</LocationDiv>
    </CustomMarkerWrapper>
  );

  const getCustomIcon = (streamDetails: IStream) => {
    return l.divIcon({
      html: renderToStaticMarkup(<CustomIconContent {...{streamDetails}} />), 
      iconSize: [50, 50],
    });
  };

  const handleResetMapView = useCallback(() => {
    setMapCenterClicked(true);
    setTimeout(()=>{
      setMapCenterClicked(false);
    },2000);
  },[]);

  return (
    <MapContainer bounds={locations?.length > 0 ? locations as LatLngBoundsExpression : [MAP_CENTER] as LatLngBoundsExpression} boundsOptions={{padding: [50, 50]}} style={{height: '100%', width: '100%'}}>
      <TileLayer url={isOfflineMap ? LEAFLET_TILE_URL : LEAFLET_ONLINE_URL} maxZoom={isOfflineMap ? 15 : 20} minZoom={isOfflineMap ? 12 : 3}/>
      {
        allStreams?.map((stream) => {
          if(stream.location !== null && stream.location.enabledLocation)
            return (
              <CustomMarker 
                position={[Number(stream?.location?.latitude), Number(stream?.location?.longitude)]} 
                icon={getCustomIcon(stream)}
                eventHandlers={{
                  click: () => {
                    setHighlightStreamId(stream?.instanceId);
                    setTimeout(()=>{
                      setHighlightStreamId('');
                    }, 3000);
                  }
                }}>
                <CustomPopUp>
                  <CameraName onClick={() => push(`/cameras/${stream.instanceId}`)}>{stream.name}</CameraName>
                  <ToolTipImg>
                    <LineViewer hasStreamToggleOptions={false} streamID={stream.instanceId} state={[]} width='100%' height='100%' />
                  </ToolTipImg>
                </CustomPopUp>
              </CustomMarker>);
          else 
            return null;
        })
      }
      {mapCenterClicked && <ResetMapView bounds={locations?.length > 0 ? locations  : [MAP_CENTER] } />}
      <FitMapBoundsMemo locations={locations?.length > 0 ? JSON.stringify(locations)  : JSON.stringify([MAP_CENTER])} boundsPresent={locations?.length > 0} />
      <ResetButton onClick={handleResetMapView}><MdMyLocation size={24} /></ResetButton>
    </MapContainer>
  );
};

export default Map;