import Input from 'components/Input';
import SelectField from 'components/SelectField';
import { RTP_INTERLACED_MODE_OPTIONS, RTP_TRANSPORT_ENCAPSULATION, TRANSPORT_ENCAPSULATION_VALUES } from '../../constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { allowOnlyNumbers, allowOnlyNumbersWithDecimal } from 'utils/utils';

const VideoConfigurationsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const VerticalLine = styled.div`
  width: 980px;
  height: 10px;
  border-bottom: 1px solid lightgrey;
  margin-top: 2px;
  margin-bottom: 15px;
  margin-left: 18px;
  @media (max-width: 768px) {
    width: 450px;
  }
`;

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SelectedCameraVideoConfigurations = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`;

const LeftSideWrapper = styled.div`
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Heading = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-left: 18px;
`;

interface IRTPData {
  transportEncapsulation: string;
  videoMulticastAddress: string;
  videoPortRtp: string;
  videoCodec: string;
  videoInterlaceMode?: string;
  videoPortRtcp: string;
  videoPayloadType: number
}

interface IRTPCamera {
  rtpData: IRTPData,
  setRtpData: React.Dispatch<React.SetStateAction<IRTPData>>,
  isEditAccess?: boolean
}

const RTPCamera = ({rtpData, setRtpData, isEditAccess=false}: IRTPCamera) => {
  const {t} = useTranslation(['common']);

  const handleTransportEncapsulationChange = (value: string) => {
    if(value === TRANSPORT_ENCAPSULATION_VALUES.MPEG2TS){
      setRtpData({...rtpData, transportEncapsulation: value, videoPayloadType: 33, videoInterlaceMode: 'progressive'});
    }else if(value === TRANSPORT_ENCAPSULATION_VALUES.MPEG2PS) {
      setRtpData({...rtpData, transportEncapsulation: value, videoPayloadType: 96, videoCodec: TRANSPORT_ENCAPSULATION_VALUES.MPEG2PS});
    }else if(value === TRANSPORT_ENCAPSULATION_VALUES.H264) {
      setRtpData({...rtpData, transportEncapsulation: value, videoPayloadType: 96});
    }else {
      setRtpData({...rtpData, transportEncapsulation: value, videoPayloadType: 103, videoInterlaceMode: 'progressive'});
    }
  };

  const handleRtpPortChange = (value: string) => {
    setRtpData({...rtpData, videoPortRtp: value, videoPortRtcp: (Number(value)+1).toString()});
  };

  return (
    <VideoConfigurationsWrapper>
      <HeadingWrapper>
        <Heading>{t('Video Configurations')}</Heading>
      </HeadingWrapper>
      <VerticalLine /> 
      <SelectedCameraVideoConfigurations>
        <LeftSideWrapper>
          <SelectField label={t('Transport Encapsulation')} isRequired disabled={isEditAccess} options={RTP_TRANSPORT_ENCAPSULATION} defaultValue={rtpData.transportEncapsulation} onChange={handleTransportEncapsulationChange}/>
          <Input label={t('Multicast Address')} type='text' id='MulticastAddress' isRequired disabled={isEditAccess} onKeyPress={allowOnlyNumbersWithDecimal} maxLength={100} value={rtpData.videoMulticastAddress} onChange={(e) => setRtpData({...rtpData, videoMulticastAddress: e.target.value})} />
        </LeftSideWrapper>
        <LeftSideWrapper>
          <Input label={t('RTP Port')} type='text' id='RtpPort' isRequired onKeyPress={allowOnlyNumbers} disabled={isEditAccess} maxLength={5} value={rtpData.videoPortRtp} onChange={(e) => handleRtpPortChange(e.target.value)}/>
          {
            ((rtpData.transportEncapsulation !== TRANSPORT_ENCAPSULATION_VALUES.H264) && (rtpData.transportEncapsulation !== TRANSPORT_ENCAPSULATION_VALUES.MPEG2PS)) && <SelectField label={t('Interlace Mode')} options={RTP_INTERLACED_MODE_OPTIONS} onChange={(value) => setRtpData({...rtpData, videoInterlaceMode: value})} disabled={isEditAccess}/>
          }
        </LeftSideWrapper>
      </SelectedCameraVideoConfigurations>
    </VideoConfigurationsWrapper>
  );
};

export default RTPCamera;