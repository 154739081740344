import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import WhatsAppNumberField from '../../../components/WhatsAppNumberField';
import Modal from 'components/Modal';
import { useTranslation } from 'react-i18next';
import AlertBar from 'components/AlertBar';
import { COUNTRY_CODES_ONLY_NUMBERS, IconType } from '../../../constants';
import { IAlertMessageType } from 'interface';
import SelectField from 'components/SelectField';
import Button from 'components/Button';

const InfoModal = styled.div`
  width: 100%;
  min-height: 200px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TemplateInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Span = styled.span<{isSmall?: boolean}>`
  font-size: ${({isSmall}) => isSmall ? '14px' : ''};
  font-weight: 400;
  color: ${({isSmall}) => isSmall ? '#ADADAD' : ''};
`; 

const SubjectWrapper = styled.div`
  padding: 10px;
  border: 1px solid ${({theme}) => theme.divider};
  border-radius: 4px;
  background-color: #eeecec;
  display: flex;
  flex-direction: column;
  min-height: 40px;
`;

const WhatsappNumbersInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
`;

const NumberfieldWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  position: absolute;
  top: 0;
  right: 0;
  >button{
    height: 24px;
    padding: 3px 8px;
  }
`;

interface IWhatsAppConfigurationProps{
  modalOpen: boolean;
  setModalOpen: (a: boolean) => void;
  subject: string;
  body: string;
  numberList: string[];
  handleAddNumbersListCallback: (value: string, list: string[], countryCode: string) => void;
  selectedAction: string;
  isReadOnly?: boolean;
  countryCode?: string;
}

const WhatsappConfiguration: React.FC<IWhatsAppConfigurationProps> = ({setModalOpen, countryCode='91', modalOpen,isReadOnly=false, subject, body, numberList, handleAddNumbersListCallback, selectedAction}) => {
  const {t} = useTranslation(['common']);
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'});
  const [currentCountryCode, setCurrentCountryCode] = useState<string>(countryCode);
  const [selectedMobileNumbers, setSelectedMobileNumbers] = useState<string[]>([...numberList]);

  const handleSelectedNumbers = useCallback((data: string[]) => {
    setSelectedMobileNumbers([...data]);
  },[]);
    
  const handleSaveClick = useCallback(() => {
    if(selectedMobileNumbers.length > 0){
      handleAddNumbersListCallback(selectedAction, selectedMobileNumbers, currentCountryCode);
      setModalOpen(!modalOpen);
    }else{
      setMessage({text: t('Please enter mobile numbers.'), id: '', type: 'danger'});
    }
  },[selectedMobileNumbers, handleAddNumbersListCallback, selectedAction, currentCountryCode, setModalOpen, modalOpen, t]);

  return (
    <Modal closeModal={setModalOpen} isModalOpen={modalOpen} width='640px' >
      <InfoModal>
        {message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
        <WhatsappNumbersInfo>
          <ButtonWrapper>
            <Button variant='primary' size='small' onClick={handleSaveClick}>{t('Save')}</Button>
          </ButtonWrapper>
          <Span isSmall>{t('Mobile Numbers')}</Span>
          <NumberfieldWrapper>
            <SelectField disabled={isReadOnly} width='90px' defaultValue={currentCountryCode} onChange={(value) => setCurrentCountryCode(value)} options={COUNTRY_CODES_ONLY_NUMBERS} />
            <WhatsAppNumberField readOnlyMode={isReadOnly} selectedNumbers={(data)=>handleSelectedNumbers(data)} WhatsAppNumberList={numberList ?? []} onSelect={()=>{}} />
          </NumberfieldWrapper>
        </WhatsappNumbersInfo>
        <TemplateInfo>
          <Span isSmall>{t('Template Details')}</Span>
          <Span>{t('Subject')}</Span>
          <SubjectWrapper>{subject}</SubjectWrapper>
          <Span>{t('Body')}</Span>
          <SubjectWrapper>{body.split('\n').map((line, index) => <p key={index}>{line}</p>)}</SubjectWrapper>
        </TemplateInfo>
      </InfoModal>
    </Modal>
  );
};

export default WhatsappConfiguration;