const HOST = window.location.hostname;
export const SERVER_URL = window.location.protocol + '//' + HOST;
export const API_BASE_URL = SERVER_URL + ':5000/graphql';
export const SUBSCRIPTION_BASE_URL = 'ws://' + HOST + ':5000/graphql';
export const USER_MGMT_BASE_URL = SERVER_URL + ':4000/graphql';
export const USER_IMG_BASE_URL = SERVER_URL + '/data/dais/profiles/';
export const EDGE_API_URL = SERVER_URL + ':20001/api/v1';
export const SUPERSET_URL = SERVER_URL + ':9097';
export const WEBSOCKET_URL = SERVER_URL + '/video-frame';
export const SNAPSHOT_URL = EDGE_API_URL + '/stacks/{X}/snapshot?&fallback=true&timestamp={Y}';
export const STATUS_URL = EDGE_API_URL + '/stacks/{X}/status/all';
export const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];
export const SUPERSET_REFRESH_INTERVAL = ['5s', '10s', '15s', '30s', '45s'];
export const GRAFANA_REFRESH_INTERVAL = ['1m' , '5m', '15m', '30m', '1h', '2h'];
export const DEFAULT_PAGE_SIZE = 10;
export type IconType = 'mono' | 'dimmed' | 'subtle' | 'inverse' | 'primary' | 'danger' | 'success' | 'warning' | 'neutral';
export const TIME_FORMAT = 'HH:mm';
export const DATE_FORMAT_WITH_DASH = 'yyyy-MM-dd';
export const DEFAULT_AREA_POINTS = [{x: 380, y: 300}, {x: 830, y: 300}, {x: 830, y: 620}, {x: 380, y: 620}];
export const DEFAULT_LINE_POINTS = [{x: 450, y: 610}, {x: 450, y: 165}];
export const DEFAULT_TRAFFIC_PULSE_LINE_POINTS = [{x: 777, y: 168}, {x: 321, y: 163}];
export const DEFAULT_ROUTE_DEVIATION_LINE1 = [{x: 440, y: 296}, {x: 442, y: 70}];
export const DEFAULT_ROUTE_DEVIATION_LINE2 = [{x: 442, y: 575}, {x: 442, y: 356}];
export const DEFAULT_CAMERA_POSITION_POINTS = { left: 142, top: 20 };
export const DEFAULT_FLOOR_PLAN_AREA_POINTS = [{x: 158, y: 45}, {x: 323, y: 45}, {x: 325, y: 190}, {x: 158, y: 190}];
export const PROPERTIES_CUSTOM_TYPES = [{name: 'Text', value: 'text'}, {name: 'Image', value: 'image'}];
export const DATE_FORMAT_WITH_SEC = 'yyyy-MM-dd HH:mm:ss';
export const DATE_PICKER_DATE_TIME_FORMAT = 'DD/MM/YYYY hh:mm:ss A';
export const DATE_TIME_FORMAT = 'dd/MM/yyyy hh:mm:ss a';
export const DATE_TIME_FORMAT_WITHOUT_AMPM = 'DD/MM/YYYY HH:mm:ss';
export const ALERT_IMG_BASE_URL = SERVER_URL + '/data/dais/alerts/images/';
export const DETECTION_IMG_BASE_URL = SERVER_URL + '/data/dais/alerts/images/';
export const SUPERSET_PORT = 9097;
export const GRAFANA_URL = ':25000/grafana/login';
export const RELEASE_VERSION = process.env.REACT_APP_VERSION ?? 'version';

export const TRAFFIC_PULSE_CLASSES = {
  auto: 'Auto',
  bus: 'Bus',
  tractor: 'Tractor',
  vehicle_truck: 'Truck',
  two_wheelers: 'Two Wheelers',
  bicycle: 'Bicycle',
  car: 'Car',
  tempo: 'Tempo',
  truck: 'Truck',
  person: 'Person',
  'Car' : 'Car',
  'Special Purpose Vehicle': 'Special Purpose Vehicle',
  'Bicycle': 'Bicycle',
  'Vehicle': 'Vehicle',
  'Pedestrian': 'Pedestrian',
  'Motorcycle': 'Motorcycle',
  'Bus': 'Bus',
  'Truck': 'Truck',
  'Kei Truck': 'Kei Truck'
};

export const VIDEO_ARCHIVES_ALERT_TABLE_COLUMN_IDS = {
  THUMBNAIL: 'thumbnail',
  MESSAGE: 'message',
  METADATA: 'metaData',
  TIMESTAMP: 'timestamp',
  NUMBER_PLATE_TYPE: 'numberPlate'
};

export const WHATS_APP_MAX_NUMBERS=5;

export const FILTER_OPTIONS = [
  {name: 'By Cameras', value: 'cameras'},
  {name: 'By Groups', value: 'groups'}
];

export const FLOOR_PLAN_TABS = [
  { id: 0 , name: 'Select Floor Plan', level: 'ZERO'},
  { id: 1 , name: 'Area Configuration', level: 'FIRST'},
  { id: 2 , name: 'Zone Configuration', level: 'SECOND'},
  { id: 3 , name: 'Camera Configuration', level: 'THIRD'}
];

export const ALERT_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'In Active'
};

export const ADVANCE_FILTER_KEYNAMES = {
  ANALYSIS: 'analysis',
  DATE_RANGE: 'dateRange',
  ALERT_STATUS: 'hasActiveAlert',
  NUMBER_PLATE: 'isNumberPlate'
};

export const ACTION_OPTIONS = [
  {name: 'WhatsApp', value: 'WhatsApp'},
  {name: 'SMS', value: 'SMS'},
  {name: 'Email', value: 'Email'},
  {name: 'In-App Notification', value: 'In-App Notification'},
  {name: 'In-App Sound', value: 'In-App Sound'},
  {name: 'Relay Controller', value: 'Relay Controller'},
  {name: 'Send Challan', value: 'Challan'},
];

export const EP_FILTERS = {
  CAMERAS: 'cameras',
  GROUPS: 'groups'
};

export const EP_ACTION_TEMPLATES = {
  WHATSAPP: 'WhatsApp',
  SMS: 'SMS',
  EMAIL: 'Email'
};

export const NODE_ID = {
  ALGORITHM: 'algorithm',
  FILTER_START: 'filterStart',
  FILTER_END: 'filterEnd',
  FILTER_TOP: 'filterTop',
  FILTER_BOTTOM: 'filterBottom',
  ACTION_START: 'actionStart',
  ACTION_END: 'actionEnd',
  SCHEDULE_START: 'scheduleStart',
  SCHEDULE_END: 'scheduleEnd',
  ADD_NEW_NODE: 'addNewNode'
};

export const NODE_TYPE = {
  SCHEDULE: 'schedule',
  FILTER: 'filter',
  ACTION: 'action'
};

export const ALGORITHM_CODES = {
  rich_counting: 'RICHS_PRODUCT_COUNTING',
  rich_anomaly: 'RICHS_ANAMOLY_DETECTION',
  low_speed: 'LOW_SPEED_DETECTION',
  high_speed: 'HIGH_SPEED_DETECTION',
  speed_detection: 'SPEED_DETECTION',
  traffic_jam_detection: 'TRAFFIC_JAM_DETECTION',
  opposite_direction_detection: 'OPPOSITE_DIRECTION_DETECTION',
  route_deviation_detection: 'ROUTE_DEVIATION_DETECTION',
  traffic_pulse_detection: 'TRAFFIC_PULSE_DETECTION',
  stop_vehicle_detection: 'STOPPED_VEHICLE_DETECTION',
  noentry_vehicle_detection: 'NOENTRY_VEHICLE_DETECTION',
  parking_violation_detection: 'PARKING_VIOLATION_DETECTION',
  license_number_plate_detection: 'LICENSE_NUMBER_PLATE_DETECTION',
  face_analysis: 'FACE_ANALYSIS',
  face_recognition: 'FACE_RECOGNITION',
  all_algorithms: 'ALL_ALGORITHMS',
  red_light_violation: 'RED_LIGHT_VIOLATION',
  no_helmet_detection: 'HELMET_DETECTION',
  triple_seat_riding_detection: 'TRIPLE_RIDER_DETECTION',
  people_counting_and_classification: 'PEOPLE_COUNTING_AND_CLASSIFICATION',
  restricted_area_monitoring: 'RESTRICTED_AREA_MONITORING'
};

export const ZONE_LINEUI_CODES = ['ZONE_1','ZONE_2','ZONE_3','ZONE_4','ZONE_5','ZONE_6','ZONE_7','ZONE_8','ZONE_9','ZONE_10'];

export const PERMISSION_CODE = {
  add_camera: 'ADD_CAMERA',
  delete_camera: 'DELETE_CAMERA',
  edit_camera: 'EDIT_CAMERA',
  camera_list: 'CAMERA_LIST',
  add_group: 'ADD_GROUP',
  edit_group: 'EDIT_GROUP',
  delete_group: 'DELETE_GROUP',
  group_list: 'GROUP_LIST',
  add_analysis_configuration: 'ADD_ANALYSIS_CONFIGURATION',
  edit_analysis_configuration:'EDIT_ANALYSIS_CONFIGURATION',
  alerts_dismiss_all: 'ALERT_DISMISS_ALL',
  update_alert_status: 'UPDATE_ALERT_STATUS',
  alert_list: 'ALERT_LIST',
  delete_alert: 'DELETE_ALERT',
  download_alert: 'DOWNLOAD_ALERT',
  manage_charts: 'MANAGE_CHARTS',
  export_system_config: 'EXPORT_SYSTEM_CONFIG',
  import_system_config: 'IMPORT_SYSTEM_CONFIG',
  export_alerts: 'EXPORT_ALERTS',
  update_password: 'UPDATE_PASSWORD',
  update_name: 'UPDATE_NAME',
  update_email: 'UPDATE_EMAIL',
  update_profile_photo: 'UPDATE_PROFILE_PHOTO',
  update_contact_number: 'UPDATE_CONTACT_NUMBER',
  edit_integration: 'EDIT_INTEGRATION',
  edit_alert_audio: 'EDIT_ALERT_AUDIO',
  edit_export_setting: 'EDIT_EXPORT_SETTING',
  add_schedule: 'ADD_SCHEDULE',
  edit_schedule: 'EDIT_SCHEDULE',
  delete_schedule: 'DELETE_SCHEDULE',
  schedule_list: 'SCHEDULE_LIST',
  add_object: 'ADD_OBJECT',
  edit_object: 'EDIT_OBJECT',
  delete_object: 'DELETE_OBJECT',
  edit_object_type: 'EDIT_OBJECT_TYPE',
  add_object_type: 'ADD_OBJECT_TYPE',
  import_objects: 'IMPORT_OBJECTS',
  export_objects: 'EXPORT_OBJECTS',
  store_charts: 'STORE_CHARTS',
  view_charts: 'VIEW_CHARTS',
  add_event_pipeline: 'ADD_EVENT_PIPELINE',
  edit_event_pipeline: 'EDIT_EVENT_PIPELINE',
  delete_event_pipeline: 'DELETE_EVENT_PIPELINE',
  event_pipeline_list: 'EVENT_PIPELINE_LIST',
  add_email_client: 'ADD_EMAIL_CLIENT',
  view_advanced_config: 'VIEW_ADVANCED_CONFIG',
  edit_advanced_config: 'EDIT_ADVANCED_CONFIG',
  edit_email_client: 'EDIT_EMAIL_CLIENT',
  view_email_client: 'VIEW_EMAIL_CLIENT',
  delete_email_client: 'DELETE_EMAIL_CLIENT'
};

export const PERMISSION_CODE_CLOUD = [
  'ADD_CAMERA',
  'DELETE_CAMERA',
  'EDIT_CAMERA',
  'CAMERA_LIST',
  'ADD_GROUP',
  'EDIT_GROUP',
  'DELETE_GROUP',
  'GROUP_LIST',
  'ADD_ANALYSIS_CONFIGURATION',
  'EDIT_ANALYSIS_CONFIGURATION',
  'ALERT_DISMISS_ALL',
  'UPDATE_ALERT_STATUS','ALERT_LIST',
  'DELETE_ALERT',
  'DOWNLOAD_ALERT',
  'MANAGE_CHARTS',
  'EXPORT_SYSTEM_CONFIG',
  'IMPORT_SYSTEM_CONFIG',
  'EXPORT_ALERTS',
  'UPDATE_PASSWORD',
  'UPDATE_NAME',
  'UPDATE_EMAIL',
  'UPDATE_PROFILE_PHOTO',
  'UPDATE_CONTACT_NUMBER',
  'EDIT_INTEGRATION',
  'EDIT_ALERT_AUDIO',
  'EDIT_EXPORT_SETTING',
  'ADD_SCHEDULE',
  'EDIT_SCHEDULE',
  'DELETE_SCHEDULE',
  'SCHEDULE_LIST','ADD_OBJECT',
  'EDIT_OBJECT',
  'DELETE_OBJECT',
  'EDIT_OBJECT_TYPE',
  'ADD_OBJECT_TYPE',
  'IMPORT_OBJECTS',
  'EXPORT_OBJECTS',
  'STORE_CHARTS',
  'VIEW_CHARTS',
  'ADD_EVENT_PIPELINE',
  'EDIT_EVENT_PIPELINE',
  'DELETE_EVENT_PIPELINE',
  'EVENT_PIPELINE_LIST',
  'ADD_EMAIL_CLIENT',
  'VIEW_ADVANCED_CONFIG',
  'EDIT_ADVANCED_CONFIG',
  'EDIT_EMAIL_CLIENT',
  'VIEW_EMAIL_CLIENT',
  'DELETE_EMAIL_CLIENT'
];

export const EVENT_ACTIONS = {
  IN_APP_NOTIFICATION: 'in_app_notification',
  EMAIL: 'email',
  IN_APP_SOUND: 'in_app_sound',
  twitter: 'twitter',
  whatsApp: 'whatsApp',
  relay_controller: 'relay_controller'
};

export const LineUIType= {
  LINE: 'line',
  AREA: 'area',
  LINE_SET: 'lineSet', 
  LINE_SET_ARRAY: 'lineSetArray',
  LINE_AREA_SET: 'lineAreaSet'
};

export const AlertMessageType:any = {
  TRAFFIC_JAM_EVENT: { message: 'Traffic jam detected', unit: 'vehicles', analysis: 'Traffic Jam Detection'},
  STOP_VEHICLE_EVENT: { message: 'Stopped vehicle detected', unit: 'seconds', analysis: 'Stopped Vehicle Detection'},
  LOW_SPEED_EVENT: {message: 'Low speed detected', unit: 'km/hr', analysis: 'Vehicle Speed Detection'},
  HIGH_SPEED_EVENT: { message: 'High speed detected', unit: 'km/hr', analysis: 'Vehicle Speed Detection'},
  OPPOSITE_DIRECTION_EVENT: {message: 'Opposite direction detected', unit: 'seconds', analysis: 'Opposite Direction Detection'},
  ROUTE_DEVIATION_EVENT: {message: 'Route deviation detected', unit: 'Lane', analysis: 'Route Deviation Detection'},
  HELMET_EVENT : {message: 'No helmet detected', unit: '', analysis: 'No Helmet Detection'},
  TRIPLE_RIDER_EVENT: {message: 'Triple seat detected', unit: '', analysis: 'Triple Seat Riding Detection'}
};

export const DetectionMessageType:any = {
  TRAFFIC_JAM_DETECTION: { message: 'Traffic jam detected', unit: 'vehicles'},
  STOPPED_VEHICLE_DETECTION: { message: 'Stopped vehicle detected', unit: 'seconds'},
  SPEED_DETECTION: {message: 'Vehicle speed detected', unit: 'km/hr'},
  OPPOSITE_DIRECTION_DETECTION: {message: 'Opposite direction detected', unit: 'seconds'},
  ROUTE_DEVIATION_DETECTION: {message: 'Route deviation detected', unit: 'Lane'}
};

export const LineUIStyling = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary'
};

export enum EdgeStatus {
  RECOVERING = 'recovering',
  RUNNING = 'running',
  FAILED = 'failed',
  PAUSED = 'paused',
  DISABLED = 'disabled'
}

export enum StatusColor {
  RECOVERING = '#FFA943',
  RUNNING = '#51CF6D',
  FAILED = '#D63031',
  PAUSED = '#A6A6A6',
  DISABLED = '#737D81'
}

export const VIDEO_FPS_OPTIONS = [{name: 'auto', value: 'auto'},
  {name: '1fps', value: '1/1'},
  {name: '5fps', value: '5/1'},
  {name: '7fps', value: '7/1'},
  {name: '10fps', value: '10/1'},
  {name: '12fps', value: '12/1'},
  {name: '15fps', value: '15/1'},
  {name: '20fps', value: '20/1'},
  {name: '25fps', value: '25/1'},
  {name: '30fps', value: '30/1'},
  {name: '1/5fps', value: '1/5'},
  {name: '1/15fps', value: '1/15'},
  {name: '1/30fps', value: '1/30'},
  {name: '1/60fps', value: '1/60'},
  {name: '1/180fps', value: '1/180'},
  {name: '1/600fps', value: '1/600'}
];

export const SHORT_HEADER_OPTIONS = [
  {name: 'Yes', value: 1},
  {name: 'No', value: 0}
];

export const VIDEO_MAX_FILE_SIZE = '52428100';

export const VIDEO_SOURCE_TYPE = [
  { name: 'IP Camera (RTSP)', value: 'RTSP' },
  { name: 'USB Camera', value: 'USB' },
  { name: 'RTMP', value: 'RTMP' },
  { name: 'HLS', value: 'HTTP' },
  { name: 'RTP', value: 'RTP' },
  { name: 'Folder', value: 'Folder' }
];

export const MAX_IMAGES_VALUES = [
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 7,
    label: '7',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 9,
    label: '9',
  },
  {
    value: 10,
    label: '10',
  }
];


export const MAX_PACKET_DELAY = [
  { name: 'Auto', value: 'auto'},
  { name: 'Custom', value: 'custom'}
];

export const RTMP_INTERNAL_SERVER_URL = 'rtmp://{X}:1935/live';

export const RTMP_STREAM_NAME_OPTIONS = [{name: 'Same as Stream Name', value: 'default_rtmp_stream_key'}, {name: 'Custom', value: 'custom_rtmp_stream_key'}];

export const ALGORITHMS = [
  {name: 'Select Algorithm', value: ''},
  {name: 'Counting', value: 'counting'},
  {name: 'Anomaly Detection', value: 'anomaly'},
];

export const SNAPSHOT_RESOLUTIONS = [{name:'1920x1080 (FHD) ', value: '1920x1080'},
  {name:'1920x1440', value: '1920x1440'},
  {name:'1280x720 (HD)', value: '1280x720'},
  {name:'1280x960', value: '1280x960'},
  {name:'640x480 (VGA)', value: '640x480'},
  {name:'640x360 (nHD)', value: '640x360'},
  {name:'320x240 (QVGA)', value:'320x240'},
  {name:'320x180', value:'320x180'},
];

export const VIDEO_FLIP_METHODS = [{name: 'none', value: 'none'},
  {name: 'Rotate-180', value: 'rotate-180'},
  {name: 'Clockwise', value: 'clockwise'},
  {name: 'Counterclockwise', value: 'counterclockwise'},
  {name: 'Horizontal-flip', value: 'horizontal-flip'},
  {name: 'Vertical-flip', value: 'vertical-flip'},
  {name: 'Upper-left-diagonal', value: 'upper-left-diagonal'},
  {name: 'Upper-right-diagonal', value: 'upper-right-diagonal'}];

export const DIRECTION_OPTIONS = [
  {name: 'Automatic', value: 'automatic'},
  {name: 'Manual', value: 'manual'},
];

export const StreamType = {
  RTSP: 'RTSP',
  USB: 'USB',
  RTP: 'RTP',
  RTMP: 'RTMP',
  HLS: 'HTTP',
  Folder: 'Folder'
};

export const OBJECTS_LIST = [{name: 'Car', value: 'car'},
  {name: 'Truck', value: 'truck'},
  {name: 'Motor Cycle', value: 'motorcycle'},
];

export const RTMP_SERVER_TYPES = {internal: 'internal', external: 'external'};

export const RTMP_SERVER_URL_TYPES = {mDNS: 'mDNS', IP: 'IP'};

export const MAP_CENTER = process.env.REACT_APP_MAP_CENTER?.split(',').map(item => Number(item)) ?? [18.5204,73.8567];

export const GUEST_USERNAME = process.env.REACT_APP_GUEST_USERNAME ?? 'dais_guest';

export const LEAFLET_TILE_URL = SERVER_URL + '/data/mapdir/{z}/{x}/{y}.png';
export const LEAFLET_ONLINE_URL = 'https://tile.openstreetmap.org/{z}/{x}/{y}.png';

export const S3_BUCKET_REGION =  'us-east-2';
export const ALERT_EXPORT_OPTIONS_EXPORT_TO_S3 = 'export_to_s3';
export const ALERT_SETTING_IN_APP_SOUND = 'in_app_sound';

export const StatusCode = {
  SUCCESS_200: 200,
  SUCCESS: 1000,
  ERROR: 3001,
  FAILED: 3000,
  EMPTY_PARAMS: 2000,
  NO_RESULT: 1001,
  NOT_FOUND: 1004,
  DESIRED: 2002,
  ACCESS_TOKEN_EXPIRED : 4003,
  REFRESH_TOKEN_EXPIRED : 4004,
  USER_NOT_FOUND: 1002,
  INACTIVE: 5000,
  INVALID_OTP: 3008,
  DUPLICATE_ENTRY: 3006,
  NO_EMAIL_CLIENT: 1003,
  TYPE_MISMATCH: 3005,
  API_CALL_ERROR: 3002
};

export const AlertTypes = {
  success: 'success',
  warning: 'warning',
  neutral: 'neutral',
  danger: 'danger'
};

export const ALERTS_REFRESH_INTERVAL = 5000;

export const USB_VIDEO_SOURCE_TYPE = [{name: 'UVC_I420', value: 'UVC_I420'}, {name: 'UVC_MJPG', value: 'UVC_MJPG'}];

export const USB_VIDEO_SOURCE_RESOLUTION = [{ name: 'HD (1280 X 720)', value: '1280x720' }, { name: 'VGA (640 X 480)', value: '640x480' }, { name: 'QVGA (320 X 240)', value: '320x240' }];

export const RTP_TRANSPORT_ENCAPSULATION = [{name: 'H-264', value: 'H264'},{name: 'MPEG-2 TS', value: 'MPEG2TS'},{name: 'MPEG-2 TTS', value: 'MPEG2TTS'}, {name: 'MPEG-2 PS', value: 'MPEG2PS'}];

export const RTP_CODEC_OPTIONS = {MPEG2TS: [{name: 'H-264', value: 'H264'}], MPEG2TTS: [{name: 'H-264', value: 'H264'}], MPEG2PS: [{name: 'MPEG-2', value: 'H264'}]};

export const getDayTextValue: {[key: string]: string } = { 1: 'SUN', 2: 'MON', 3: 'TUE', 4: 'WED', 5: 'THU', 6: 'FRI', 7: 'SAT' };

export const getDayNumberValue: {[key: string]: number} = { 'SUN': 1, 'MON': 2, 'TUE': 3, 'WED': 4, 'THU': 5, 'FRI': 6, 'SAT': 7 };

export const AWS_REGIONS = [
  { 'name': 'US East (Ohio)', 'value': 'us-east-2'},
  { 'name': 'US East (N. Virginia)', 'value': 'us-east-1'},
  { 'name': 'US West (N. California)', 'value': 'us-west-1'},
  { 'name': 'US West (Oregon)', 'value': 'us-west-2'},
  { 'name': 'Africa (Cape Town)', 'value': 'af-south-1'},
  { 'name': 'Asia Pacific (Hong Kong)', 'value': 'ap-east-1'},
  { 'name': 'Asia Pacific (Mumbai)', 'value': 'ap-south-1'},
  { 'name': 'Asia Pacific (Osaka)', 'value': 'ap-northeast-3'},
  { 'name': 'Asia Pacific (Seoul)', 'value': 'ap-northeast-2'},
  { 'name': 'Asia Pacific (Singapore)', 'value': 'ap-southeast-1'},
  { 'name': 'Asia Pacific (Sydney)', 'value': 'ap-southeast-2'},
  { 'name': 'Asia Pacific (Tokyo)', 'value': 'ap-northeast-1'},
  { 'name': 'Canada (Central)', 'value': 'ca-central-1'},
  { 'name': 'China (Beijing)', 'value': 'cn-north-1'},
  { 'name': 'China (Ningxia)', 'value': 'cn-northwest-1'},
  { 'name': 'Europe (Frankfurt)', 'value': 'eu-central-1'},
  { 'name': 'Europe (Ireland)', 'value': 'eu-west-1'},
  { 'name': 'Europe (London)', 'value': 'eu-west-2'},
  { 'name': 'Europe (Milan)', 'value': 'eu-south-1'},
  { 'name': 'Europe (Paris)', 'value': 'eu-west-3'},
  { 'name': 'Europe (Stockholm)', 'value': 'eu-north-1'},
  { 'name': 'Middle East (Bahrain)', 'value': 'me-south-1'},
  { 'name': 'South America (São Paulo)', 'value': 'sa-east-1'}
];

export const HOURS = [
  { name: '00', value: '0' },
  { name: '01', value: '1' },
  { name: '02', value: '2' },
  { name: '03', value: '3' },
  { name: '04', value: '4' },
  { name: '05', value: '5' },
  { name: '06', value: '6' },
  { name: '07', value: '7' },
  { name: '08', value: '8' },
  { name: '09', value: '9' },
  { name: '10', value: '10' },
  { name: '11', value: '11' },
  { name: '12', value: '12' },
  { name: '13', value: '13' },
  { name: '14', value: '14' },
  { name: '15', value: '15' },
  { name: '16', value: '16' },
  { name: '17', value: '17' },
  { name: '18', value: '18' },
  { name: '19', value: '19' },
  { name: '20', value: '20' },
  { name: '21', value: '21' },
  { name: '22', value: '22' },
  { name: '23', value: '23' }
];

export const MINUTES = [
  { name: '00', value: '0' },
  { name: '01', value: '1' },
  { name: '02', value: '2' },
  { name: '03', value: '3' },
  { name: '04', value: '4' },
  { name: '05', value: '5' },
  { name: '06', value: '6' },
  { name: '07', value: '7' },
  { name: '08', value: '8' },
  { name: '09', value: '9' },
  { name: '10', value: '10' },
  { name: '11', value: '11' },
  { name: '12', value: '12' },
  { name: '13', value: '13' },
  { name: '14', value: '14' },
  { name: '15', value: '15' },
  { name: '16', value: '16' },
  { name: '17', value: '17' },
  { name: '18', value: '18' },
  { name: '19', value: '19' },
  { name: '20', value: '20' },
  { name: '21', value: '21' },
  { name: '22', value: '22' },
  { name: '23', value: '23' },
  { name: '24', value: '24' },
  { name: '25', value: '25' },
  { name: '26', value: '26' },
  { name: '27', value: '27' },
  { name: '28', value: '28' },
  { name: '29', value: '29' },
  { name: '30', value: '30' },
  { name: '31', value: '31' },
  { name: '32', value: '32' },
  { name: '33', value: '33' },
  { name: '34', value: '34' },
  { name: '35', value: '35' },
  { name: '36', value: '36' },
  { name: '37', value: '37' },
  { name: '38', value: '38' },
  { name: '39', value: '39' },
  { name: '40', value: '40' },
  { name: '41', value: '41' },
  { name: '42', value: '42' },
  { name: '43', value: '43' },
  { name: '44', value: '44' },
  { name: '45', value: '45' },
  { name: '46', value: '46' },
  { name: '47', value: '47' },
  { name: '48', value: '48' },
  { name: '49', value: '49' },
  { name: '50', value: '50' },
  { name: '51', value: '51' },
  { name: '52', value: '52' },
  { name: '53', value: '53' },
  { name: '54', value: '54' },
  { name: '55', value: '55' },
  { name: '56', value: '56' },
  { name: '57', value: '57' },
  { name: '58', value: '58' },
  { name: '59', value: '59' }
];

export const DEFAULT_EMAIL_CLIENT_DATA = {
  instanceId: 'emailclient_dais', 
  nickname: '',
  region: 'us-east-2',
  accessKey: '',
  secretKey: '',
  defaultFrom: '',
  defaultTo: '',
  days: '3',
  hours: 0,
  minutes: 0
};

export const USB_VIDEO_SOURCE_FRAME_RATE = [{ name: 'Auto', value: 'auto' }, { name: '7 fps', value: '7fps' }, { name: '5 fps', value: '5fps' }];

export const VIDEO_QUALITY_OPTIONS = [{ name: 'Low', value: 'low' }, { name: 'Medium', value: 'middle' }, { name: 'High', value: 'high' }];

export const H264PROFILE = [{ name: 'Auto', value: 'auto' }, { name: 'Base Line', value: 'baseline' }, { name: 'Constrained Base Line', value: 'constrained-baseline' }, { name: 'Main', value: 'main' }];

export const IMAGE_URL = '{X}/data/objects/{Y}/{Z}';

export const PRODUCT_PROPERTY_IMAGE_URL = '{SERVER_URL}/data/objects/{ID}/properties/{imageUrl}';

export const PRODUCT_IMAGES_URL = '{SERVER_URL}/data/objects/{ID}/{imageUrl}';

export const basePath = '{SERVER_URL}/data/objects/{selectedProduct}/';

export const ImageBasePath = '{SERVER_URL}/data/objects/{selectedProduct}/images/';

export const OBJECT_MAX_IMAGES = 8;

export const HLS_MDNS_URL = 'http://{deviceId}.local:1835/live/{instanceId}/playlist.m3u8';

export const HLS_IP_URL = 'http://{networkId}:1835/live/{instanceId}/playlist.m3u8';

export const WEB_RTC_MDNS_URL = 'ws://{deviceId}.local:20001/websocket-{webRTCPort}';

export const WEB_RTC_IP_URL = 'ws://{networkId}:20001/websocket-{webRTCPort}';

export const RTSP_MDNS_URL = 'rtsp://{deviceId}.local:{rtspPort}/{instanceId}';

export const RTSP_IP_URL = 'rtsp://{networkId}:{rtspPort}/{instanceId}';

export const ALERT_EXPORT_OPTIONS = [
  {name: 'Download', value: 'DOWNLOAD_ON_LOCAL'},
  {name: 'AWS S3', value: 'EXPORT_TO_S3'},
  {name: 'Export To External Server', value: 'EXPORT_TO_EXTERNAL_SERVER'},
  {name: 'Windows Share Drive(SMB)', value: 'EXPORT_TO_SAMBA_SERVER'}
];

export const COUNTRY_CODES = [
  { name: 'India +91', value: '91' },
  { name: 'United States +1', value: '1' },
  { name: 'United Kingdom +44', value: '44' },
  { name: 'Afghanistan +93', value: '93' },
  { name: 'Albania +355', value: '355' },
  { name: 'Algeria +213', value: '213' },
  { name: 'Andorra +376', value: '376' },
  { name: 'Angola +244', value: '244' },
  { name: 'Argentina +54', value: '54' },
  { name: 'Australia +61', value: '61' },
  { name: 'Austria +43', value: '43' },
  { name: 'Brazil +55', value: '55' },
  { name: 'China +86', value: '86' },
  { name: 'Colombia +57', value: '57' },
  { name: 'Croatia +385', value: '385' },
  { name: 'Czech Republic +420', value: '420' },
  { name: 'Denmark +45', value: '45' },
  { name: 'Egypt +20', value: '20' },
  { name: 'Finland +358', value: '358' },
  { name: 'France +33', value: '33' },
  { name: 'Germany +49', value: '49' },
  { name: 'Greece +30', value: '30' },
  { name: 'Hong Kong +852', value: '852' },
  { name: 'Indonesia +62', value: '62' },
  { name: 'Italy +39', value: '39' },
  { name: 'Japan +81', value: '81' },
  { name: 'Kenya +254', value: '254' },
  { name: 'South Korea +82', value: '82' },
  { name: 'Kuwait +965', value: '965' },
  { name: 'Lebanon +961', value: '961' },
  { name: 'Luxembourg +352', value: '352' },
  { name: 'Malaysia +60', value: '60' },
  { name: 'Mexico +52', value: '52' },
  { name: 'Netherlands +31', value: '31' },
  { name: 'New Zealand +64', value: '64' },
  { name: 'Nigeria +234', value: '234' },
  { name: 'Norway +47', value: '47' },
  { name: 'Oman +968', value: '968' },
  { name: 'Pakistan +92', value: '92' },
  { name: 'Peru +51', value: '51' },
  { name: 'Philippines +63', value: '63' },
  { name: 'Poland +48', value: '48' },
  { name: 'Portugal +351', value: '351' },
  { name: 'Qatar +974', value: '974' },
  { name: 'Russia +7', value: '7' },
  { name: 'Saudi Arabia +966', value: '966' },
  { name: 'Singapore +65', value: '65' },
  { name: 'South Africa +27', value: '27' },
  { name: 'Spain +34', value: '34' },
  { name: 'Sweden +46', value: '46' },
  { name: 'Switzerland +41', value: '41' },
  { name: 'Taiwan +886', value: '886' },
  { name: 'Thailand +66', value: '66' },
  { name: 'Turkey +90', value: '90' },
  { name: 'United Arab Emirates +971', value: '971' },
  { name: 'Uruguay +598', value: '598' },
  { name: 'Uzbekistan +998', value: '998' },
  { name: 'Venezuela +58', value: '58' },
  { name: 'Vietnam +84', value: '84' },
  { name: 'Yemen +967', value: '967' },
  { name: 'Zambia +260', value: '260' },
  { name: 'Zimbabwe +263', value: '263' }
];

export const RTP_INTERLACED_MODE_OPTIONS = [{name: 'Progressive', value: 'progressive'}, {name: 'Interlaced', value: 'interlaced'}];

export const TRANSPORT_ENCAPSULATION_VALUES = {
  MPEG2TS: 'MPEG2TS',
  MPEG2PS: 'MPEG2PS',
  H264: 'H264',
  MPEG2TTS: 'MPEG2TTS'
};

export const ALERT_STATUS_VALUES = {
  'Active': 1,
  'Dismissed': 2,
  'Ignored': 3,
  'Expired': 4,
  'Pinned': 5
};

export const RTP_PIXEL_ASPECT_RATIO_OPTIONS = [{name: 'Auto', value: 'auto'}, {name: '1:1', value: '1:1'}, {name: '4:4', value: '4:4'}];

export const EXPORT_OPTIONS = {
  DOWNLOAD_ON_LOCAL: 'download_on_local',
  EXPORT_TO_S3: 'export_to_s3',
  EXPORT_TO_EXTERNAL_SERVER: 'export_to_external_server',
  EXPORT_TO_SAMBA_SERVER: 'export_to_samba_server'
};

export const OBJECT_TYPE_OPTIONS = [{name: 'Person Registration', value: 'PERSON'}, {name: 'Number Plate Registration', value: 'NUMBER_PLATE'}];

export const OBJECT_TYPE = {
  PERSON: 'PERSON',
  NUMBER_PLATE: 'NUMBER_PLATE'
};

export const NUMBER_PLATE_TYPE = [{name: 'Listed', value: 'listed'}, {name: 'Important', value: 'important'}];

export const GENDER_OPTIONS = [{name: 'Male', value: 'male'}, {name: 'Female', value: 'female'}];

export const PERSON_TYPES = [{name: 'Listed', value: 'listed'}, {name: 'Important', value: 'important'}];

export const INPUT_TYPE_OPTIONS = [{ name: 'Text', value: 'text' }, { name: 'Image', value: 'image' }, { name: 'Multi-Image', value: 'multi-image' }, { name: 'Select Field', value: 'select-field' }, { name: 'Text Area', value: 'text-area' }];

export const PROPERTY_TYPE = {
  Text : 'text',
  TextArea : 'text-area',
  Image : 'image',
  SelectField : 'select-field',
  MultiImage : 'multi-image'
};

export const FEATURE_REGISTRATION_OPTION = [{name: 'None', value: 'none'},{name: 'Person', value: 'PERSON'}, {name: 'Number Plate', value: 'NUMBER_PLATE'}];

export const NO_USER_MANAGEMENT_INFO = '{"tokenType":"bearer","username":"Guest User","permissions":["ALL"]}';

export const STRONG_PASSWORD_MIN_MAX_VALUES = {
  MIN_VALUE: 5,
  MAX_VALUE: 16
};

export const GRAFANA_CHART_RANGE_OPTIONS = [{name: 'Last 1 hour', value: 'from=now-1h&to=now-1m'}, {name: 'Last 4 hours', value: 'from=now-4h&to=now-1m'}, {name: 'Last 8 hours', value: 'from=now-8h&to=now-1m'}, {name: 'Last 12 hours', value: 'from=now-12h&to=now-1m'}, {name: 'Last 24 hours', value: 'from=now-24h&to=now-1m'}, {name: 'Last week', value: 'from=now-7d&to=now-1m'}, {name: 'Last 2 weeks', value: 'from=now-15d&to=now-1m'}, {name: 'Last 3 weeks', value: 'from=now-21d&to=now-1m'}, {name: 'Last 1 Month', value: 'from=now-30d&to=now-1m'}];

export const ALERT_STATUS_INDICATOR = {
  1:{'name':'Active', 'color': '#FF0000'},
  2:{'name':'Dismissed', 'color': '#00FF94'},
  3:{'name':'Ignored', 'color': '#595959'},
  4:{'name':'Expired', 'color': '#B2B1B1'},
  5:{'name':'Pinned', 'color': '#ADD8E6'}
};

export const COUNTRY_CODES_ONLY_NUMBERS = [
  { name: '+91', value: '91' },
  { name: '+1', value: '1' },
  { name: '+44', value: '44' },
  { name: '+93', value: '93' },
  { name: '+355', value: '355' },
  { name: '+213', value: '213' },
  { name: '+376', value: '376' },
  { name: '+244', value: '244' },
  { name: '+54', value: '54' },
  { name: '+61', value: '61' },
  { name: '+43', value: '43' },
  { name: '+55', value: '55' },
  { name: '+86', value: '86' },
  { name: '+57', value: '57' },
  { name: '+385', value: '385' },
  { name: '+420', value: '420' },
  { name: '+45', value: '45' },
  { name: '+20', value: '20' },
  { name: '+358', value: '358' },
  { name: '+33', value: '33' },
  { name: '+49', value: '49' },
  { name: '+30', value: '30' },
  { name: '+852', value: '852' },
  { name: '+62', value: '62' },
  { name: '+39', value: '39' },
  { name: '+81', value: '81' },
  { name: '+254', value: '254' },
  { name: '+82', value: '82' },
  { name: '+965', value: '965' },
  { name: '+961', value: '961' },
  { name: '+352', value: '352' },
  { name: '+60', value: '60' },
  { name: '+52', value: '52' },
  { name: '+31', value: '31' },
  { name: '+64', value: '64' },
  { name: '+234', value: '234' },
  { name: '+47', value: '47' },
  { name: '+968', value: '968' },
  { name: '+92', value: '92' },
  { name: '+51', value: '51' },
  { name: '+63', value: '63' },
  { name: '+48', value: '48' },
  { name: '+351', value: '351' },
  { name: '+974', value: '974' },
  { name: '+7', value: '7' },
  { name: '+966', value: '966' },
  { name: '+65', value: '65' },
  { name: '+27', value: '27' },
  { name: '+34', value: '34' },
  { name: '+46', value: '46' },
  { name: '+41', value: '41' },
  { name: '+886', value: '886' },
  { name: '+66', value: '66' },
  { name: '+90', value: '90' },
  { name: '+971', value: '971' },
  { name: '+598', value: '598' },
  { name: '+998', value: '998' },
  { name: '+58', value: '58' },
  { name: '+84', value: '84' },
  { name: '+967', value: '967' },
  { name: '+260', value: '260' },
  { name: '+263', value: '263' }
];
