import React from 'react';
import AdvancedRTSP from './AdvancedRTSP';
import AdvancedUSB from './AdvancedUSB';
import { StreamType } from '../../constants';
import AdvancedRTMP from './AdvancedRTMP';
import AdvancedHLS from './AdvancedHLS';
import AdvancedRTP from './AdvancedRTP';

interface IAdvancedConfig {
  streamType: string | undefined,
  instanceId: string | undefined
}

const AdvancedConfig = ({streamType, instanceId}: IAdvancedConfig) => {
  return (
    <>
      {
        streamType === StreamType.RTSP && <AdvancedRTSP instanceId={instanceId} streamType={streamType} />
      }
      {
        streamType === StreamType.USB && <AdvancedUSB instanceId={instanceId} streamType={streamType} />
      }
      {
        streamType === StreamType.RTMP && <AdvancedRTMP instanceId={instanceId} streamType={streamType} />
      }
      {
        streamType === StreamType.HLS && <AdvancedHLS instanceId={instanceId} streamType={streamType} />
      }
      {
        streamType === StreamType.RTP && <AdvancedRTP streamType={streamType} instanceId={instanceId} />
      }
    </>
  );
};

export default AdvancedConfig;