import { IStream } from 'interface';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import Icon from './Icon';
import { useQuery } from '@apollo/client';
import { GET_ALL_STREAMS } from 'api_configs/queries';
import { SNAPSHOT_URL } from '../constants';
import AwaitingStreamEng from '../svgs/Awaiting_Stream-ENG.svg';
import LineViewer from './LineViewer';
import { CiPlay1 } from 'react-icons/ci';
import { GoDownload } from 'react-icons/go';
import { getCachedImg } from 'utils/utils';
import { ImageCache } from 'utils/utils';
import JSZip from 'jszip';
import { LiaFileDownloadSolid } from 'react-icons/lia';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-height: 80vh;
  gap: 10px;
  overflow-y: auto;
`;

const StreamContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 90vh;
  max-height: 70vh;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  @media (min-width: 1366px) and (max-width:1440px) {
    height: 61vh;
  }
`;

const StreamHeader = styled.div`
  position: absolute;
  top: 0;
  height: 8vh;
  background: #c5c7e68e;
  width: 100%;
  z-index: 99;
  padding: 10px 20px;
  display: flex;
  :hover{
    opacity: 1;
  }
  > div:first-child {
    background-color: #ffffff82;
  }
`;

const StreamsWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 15vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  background-color: ${({theme})=>theme.backgroundColor};
`;

const Title = styled.div`
  font-size: 18px;
  padding: 5px 10px;
  border-radius: 10px;
  width: max-content;
  height: max-content;
`;

const StreamSliderContainer = styled.div`
  height: 8vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
`;

const StreamToggleButtonCSS = css`
  width: 42px;
  height: 84px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({theme})=>theme.backgroundColor};
  position: absolute;
  cursor: pointer;
`;

const PreviousButton = styled.div`
  ${StreamToggleButtonCSS}
  height: 100%;
  left: -15px;
`;

const NextButton = styled.div`
  ${StreamToggleButtonCSS}
  height: 100%;
  right: -15px;
`;

const SwiperContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 90%;
`;

const CustomeSwiperSlide = styled(SwiperSlide)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StreamThumbnail = styled.img<{isSelected:boolean}>`
  object-fit: contain;
  border-radius: 5px;
  width: 135px;
  overflow: hidden;
  transform: scale(${({isSelected})=>isSelected ? '1': '0.8'});
  border: ${({isSelected})=>isSelected ? '2px solid rgb(11, 37, 136)': 'none'};
  cursor: pointer;
`;

const IconWrapper = styled.div`
  justify-content: center;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff82;
  cursor: pointer;
  > div > svg {
    fill: rgb(79 84 85);
  }
  > svg {
    fill: rgb(79 84 85);
  }
`;

const IconsContainer = styled.div`
  display: flex;
  column-gap: 20px;
  margin-left: 20px;
  margin-top: 2px;
`;

const DownloadIconWraper = styled.div`
  cursor: pointer;
  > svg {
    fill: rgb(79 84 85);
  }
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff82;
  cursor: pointer;
`;

const AllSnapshotDownloadIconWrapper = styled.div`
  cursor: pointer;
  > svg {
    fill: rgb(79 84 85);
  }
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff82;
  cursor: pointer;
`;

interface IProps{
  streamDetails: IStream
}

const FullScreenView: React.FC<IProps> = ({streamDetails}) => {
  const [streams,setStreams] = useState<IStream[]>([]);
  const {data: getAllStreamsResponse, refetch} = useQuery(GET_ALL_STREAMS);
  const [selectedStream,setSelectedStream] = useState<IStream>(streamDetails);
  const [selectedIndex,setSelectedIndex] = useState(0);
  const swiperRef = useRef<SwiperRef>(null);
  const [isPlaying , setPlaying] = useState(false);
  const [itemIndex, setItemIndex] = useState(0);

  useEffect(() =>{
    setItemIndex(streams?.findIndex(item => item?.id === selectedStream?.id));
  },[streams, selectedStream]);

  const getAllStreams = useCallback(async ()=>{
    const allStreams = await getAllStreamsResponse?.getAllStreams?.result !== null ? getAllStreamsResponse?.getAllStreams?.result : [];
    setStreams(allStreams);
  },[getAllStreamsResponse]);

  useEffect(()=>{
    refetch();
    getAllStreams();
  },[refetch, getAllStreams]);

  const handleSlidechange = useCallback(() => {
    const currentIndex = swiperRef.current?.swiper.realIndex;
    setSelectedStream(streams[currentIndex as number]);
  },[streams]);

  useEffect(()=>{
    const currentIndex = streams?.findIndex(stream => stream?.instanceId === selectedStream?.instanceId);
    setSelectedIndex(currentIndex);
  },[selectedStream, streams]);

  useEffect(() => {
    swiperRef.current?.swiper.slideTo(selectedIndex);
  }, [selectedIndex]);

  const getImageSource = (instanceId: string) => {
    const url =  SNAPSHOT_URL.replace('{X}',instanceId).replace('{Y}',Math.ceil(Date.now()/1000).toString());
    if(url){
      return url;
    }
  };

  useEffect(() => {
    let intervalId: NodeJS.Timer;
    if (isPlaying) {
      intervalId = setInterval(() => {
        setSelectedStream(streams[itemIndex as number]);
        setItemIndex((prevIndex) => (prevIndex + 1) % streams?.length);
      },3000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isPlaying, streams, itemIndex]);

  const onHandlePlayAndPauseStream = () =>{
    setPlaying(!isPlaying);
  };

  const handleDownloadSingleSnapshot = useCallback((instanceId: string) => {
    const cachedImg = getCachedImg(instanceId);
    if(cachedImg){
      const base64Image = cachedImg.replace(/^data:image\/(jpeg|jpg);base64,/, '');
      downloadBase64File(base64Image, '/image/jpg', `${instanceId}.jpg`);
    }
  }, []);

  const handleDownloadAllSnapshots = useCallback(() =>{
    const zip = new JSZip();
    Object.keys(ImageCache).forEach(key => {
      const base64Image = ImageCache[key]?.replace(/^data:image\/(jpeg|jpg);base64,/, '');
      zip.file(`${key}.jpg`, base64Image!, { base64: true });
    });

    zip.generateAsync({ type: 'blob' }).then(blob => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'All Snapshots.zip';
      a.click();
      URL.revokeObjectURL(url);
    });
  },[]);

  return (
    <Container>
      <StreamContainer>
        <LineViewer showAlgorithmIcons state={[]} streamID={selectedStream?.instanceId} hasStreamToggleOptions={false} width='100%' height='100%' />
        <StreamHeader>
          <Title>{selectedStream?.name}</Title>
          <IconsContainer>
            <DownloadIconWraper title='Single snapshot' onClick={() => handleDownloadSingleSnapshot(selectedStream?.instanceId)}>
              <GoDownload  size={24} />
            </DownloadIconWraper>
            <IconWrapper title='Play and pause' onClick={onHandlePlayAndPauseStream} >
              {isPlaying ? <Icon icon='Pause' size={24} /> : <CiPlay1 size={24}/>}
            </IconWrapper>
            <AllSnapshotDownloadIconWrapper title='All snapshots'>
              <LiaFileDownloadSolid size={24} onClick={handleDownloadAllSnapshots} />
            </AllSnapshotDownloadIconWrapper>
          </IconsContainer>
        </StreamHeader>
      </StreamContainer>
      <StreamsWrapper>
        <StreamSliderContainer>
          <PreviousButton onClick={()=>swiperRef.current?.swiper.slidePrev()}>
            <Icon icon='Left' size={30} weight='heavy' />
          </PreviousButton>
          <SwiperContainer>
            <Swiper
              ref={swiperRef}
              slidesPerView={streams?.length > 5 ? 5 : streams?.length}
              spaceBetween={0}
              centeredSlides={true}
              navigation={true}
              slideToClickedSlide={true} 
              onSlideChange={handleSlidechange}
            >
              {
                streams?.map((stream,index)=>(
                  <CustomeSwiperSlide key={index} id='swiperSlide'>
                    <StreamThumbnail 
                      src={getImageSource(stream.instanceId)}
                      isSelected={index===selectedIndex}
                      onError={e => (e.target as HTMLSourceElement).src = AwaitingStreamEng}
                      loading='eager'
                    />
                  </CustomeSwiperSlide>
                ))
              }
            </Swiper>
          </SwiperContainer>
          <NextButton onClick={()=>swiperRef.current?.swiper.slideNext()}>
            <Icon icon='Right' size={30} weight='heavy' />
          </NextButton>
        </StreamSliderContainer>
      </StreamsWrapper>
    </Container>
  );
};

export default FullScreenView;

function downloadBase64File(base64Data: string, contentType: string, fileName: string) {
  const linkSource = `data:${contentType};base64,${base64Data}`;
  const downloadLink = document.createElement('a');
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}
