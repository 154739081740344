import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgNotification = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M11.5 4v1.417H5.833a1.417 1.417 0 0 0-1.416 1.416v11.334a1.417 1.417 0 0 0 1.416 1.416h11.334a1.417 1.417 0 0 0 1.416-1.416V12.5H20v5.667A2.833 2.833 0 0 1 17.167 21H5.833A2.833 2.833 0 0 1 3 18.167V6.833A2.833 2.833 0 0 1 5.833 4H11.5Z"
        fill={props.color}
      />
      <path
        d="M17 9.667a2.667 2.667 0 1 0 0-5.334 2.667 2.667 0 0 0 0 5.334ZM17 11a4 4 0 1 1 0-8 4 4 0 0 1 0 8Z"
        fill={props.color}
      />
    </svg>
  );
};
export default SvgNotification;
