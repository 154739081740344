import React,{ useState, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Container } from 'CommonStyles';
import { useTranslation } from 'react-i18next';
import RadioButton from 'components/RadioButton';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import Checkbox from 'components/Checkbox';
import { IAlertMessageType, IStream } from 'interface';
import { useQuery } from '@apollo/client';
import { GET_ALL_STREAMS } from 'api_configs/queries';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Config from '../configs/config.json';
import format from 'date-fns/format';
import { ALERT_EXPORT_OPTIONS, DATE_FORMAT_WITH_SEC, DATE_PICKER_DATE_TIME_FORMAT, IconType, PERMISSION_CODE, SERVER_URL, StatusCode } from '../constants';
import { EXPORT_ALERT_DATA } from 'api_configs/queries';
import AlertBar from 'components/AlertBar';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import SelectField from 'components/SelectField';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { useHeader } from 'contexts/HeaderContext';
import ButtonWithLoading from 'components/ButtonWithLoading';
import TokenService from 'api_configs/tokenService';

const DataSourceContainer = styled.div`
  margin-left: 20px;
  margin-top: 20px;
`;

const DataSourceLabel = styled.div`
  font-size: 20px;
`;

const TimeRangeLabel = styled.div`
  font-size: 20px;
`;

const IncludeLabel = styled.div`
  font-size: 20px;
  margin-top: 30px;
`;

const CamerasContainer = styled.div`
  display: flex;
  column-gap: 40px;
  margin-top: 20px;
`;

const TimeRangeContainer = styled.div`
  margin-top: 22px;
 `;

const ErrorMessage = styled.div`
  font-size: 13px;
  color: red;
`;

const DatePickersWrappers = styled.div`
  margin-top: 20px;
  > div {
    display: flex;
    flex-direction: row;
    column-gap: 80px;
    top: 0;
    > div:nth-child(2){
       margin-top: 0;
    }
  }
`;

const TextLabel = styled.div``;

const IncludeLabelWrapper = styled.div``;

const DropdownContainer = styled.div<{display?:boolean}>`
  margin-top: 20px;
  > div > label {
    font-size: 20px;
    margin-bottom: 20px;
  }
  > div > div {
    width: 270px;
    > div {
      display: ${({display})=> display ? 'none' : 'block'};
    }
  }
`;

const CheckboxAndLabelWrapper = styled.div`
  display: flex;
  column-gap: 2px;
`;

const StartExportButtonWrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 200px;
  > button {
    width: max-content;
  }
`;

const EmptyLabelWrapper = styled.div`
  border: 1px solid #E0E0E0;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  width: 620px;
`;

const EmptyLabel = styled.div`
  font-size: 22px;
`;

const EndDateConatainer = styled.div`
  display: flex ;
  flex-direction: column ;
  gap: 8px;
`;

const CameraName = styled.div``;

const TableContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 50px;
  width: 620px;
  max-height: 360px !important;
  .MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeaders{
    background-color: #F5F8FF;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle{
    font-weight: 600;
  }
  > div {
    max-height: 360px;
  }
  > div:nth-child(1) > div:nth-child(2){
    display: none;
  }
  > div > div:first-child{
  > div:first-child{
    font-size: 14px;
    color: rgb(90, 98, 105);
    > div > div > div > div:nth-child(2) > svg {
     display: none;
    }
  }
}
`;

const SelectCameraLabel = styled.div`
  margin-top: 20px;
  font-size: 20px;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  column-gap: 30px;
  margin-top: 20px;
`;

const ExportAlerts: React.FC<{}> = () => {
  const {t} = useTranslation(['common']);
  const [selectedCameraCategory, setSelectedCameraCategory] = useState('allCameras');
  const [startDate, setStartDate] = useState<Dayjs| null>(dayjs().subtract(1, 'day'));
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());
  const [downloadOptions, setDownloadOptions] = useState({text: true, image: false, video: false});
  const [streams, setStreams] = useState<IStream[]>([]);
  const {data: getAllStreamsResponse} = useQuery(GET_ALL_STREAMS);
  const {refetch} = useQuery(EXPORT_ALERT_DATA);
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'});
  const [allCamerasList, setAllCamerasList] = useState<string[]>([]); 
  const [selectedId, setSelectedId] = useState<GridRowSelectionModel>([]);
  const [alertExportOption, setAlertExportOption] = useState('DOWNLOAD_ON_LOCAL');
  const {updateHeaderTitle} = useHeader();
  const [loading, setLoading] = useState(false);
  const exportAlertsAccess = TokenService.hasAccess(PERMISSION_CODE.export_alerts);
  const [dateError, setDateError] = useState<boolean>(false);

  useEffect(()=>{
    updateHeaderTitle(t('Export Alerts'), 'Export');
  },[updateHeaderTitle, t]);

  useEffect(()=>{
    setStreams(getAllStreamsResponse?.getAllStreams?.result);
    setAllCamerasList(getAllStreamsResponse?.getAllStreams?.result?.map((streams: IStream) => streams.instanceId));
  },[getAllStreamsResponse]);

  const columns: GridColDef[]  = useMemo(()=> [
    { 
      field: 'name', 
      headerName: t('Camera Name '),
      width: 300,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => {
        return (
          <CameraName>{params.row.name}</CameraName>
        );
      }
    }
  ],[t]);

  const generateRowData = useCallback(() =>{
    return streams?.map((item: IStream)=>(
      { 
        id: item.instanceId, 
        name: item?.name,
      }
    ));
  },[streams]);

  const handleChange = (value: string) => {
    setSelectedCameraCategory(value);
  };

  const getConfiguredColumns = useCallback(()=>{
    const configuredColumns = columns.filter((item)=>{
      return Config.ExportSpecificCameraListColumns.indexOf(item.field) !== -1;
    });
    return configuredColumns;
  },[columns]);

  const onHandleExport = async() => {
    const payload = {
      streamIdList: selectedCameraCategory === 'allCameras' ? allCamerasList : selectedCameraCategory === 'specificCameras' ? selectedId : [] ,
      startDate: startDate !== null && format(startDate.toDate(), DATE_FORMAT_WITH_SEC),
      endDate: endDate !== null && format(endDate.toDate(), DATE_FORMAT_WITH_SEC),
      exportType: alertExportOption,
      includeImages: downloadOptions.image,
      includeVideos: downloadOptions.video
    };
    if(selectedCameraCategory === 'specificCameras' && (!payload?.streamIdList || payload?.streamIdList?.length === 0)){
      setMessage({text: t('Please select a specific camera'), id:'',type:'danger'});
      return ;
    }else if(!startDate && !endDate && dateError){
      setMessage({text: t('Please select a valid daterange'), id:'',type:'danger'});
      return ;
    }
    setLoading(true);
    try {
      const result = await refetch({ payload: payload });
      if(result.data.exportData.statusCode === StatusCode.SUCCESS){
        if(alertExportOption === 'DOWNLOAD_ON_LOCAL'){
          downloadLink(SERVER_URL + result.data.exportData.result.file);
          setMessage({text: t('Data exported successfully'), id:'',type:'success'});
        }
        if(alertExportOption === 'EXPORT_TO_S3'){
          setMessage({text: t('Data exported successfully on s3 bucket'), id:'',type:'success'});
        }
        if(alertExportOption === 'EXPORT_TO_EXTERNAL_SERVER'){
          setMessage({text: t('Data exported successfully on external server'), id:'',type:'success'});
        }
        if(alertExportOption === 'EXPORT_TO_SAMBA_SERVER'){
          setMessage({text: t('Data exported successfully on samba server'), id:'',type:'success'});
        }
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else{
        setMessage({text: t('apiError'), id:'',type:'danger'});
      }
    } catch(e){
      setMessage({text: t('apiError'), id:'',type:'danger'});
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const handleRowSelectionModelChange = (params: GridRowSelectionModel) => {
    setSelectedId(params ? params : []);
  };

  const onHandleExportOption = (value: string) => {
    setAlertExportOption(value);
  };

  const handleStartDate = (value: Dayjs | null) => {
    setStartDate(value);
  };

  const handleEndDate = (value: Dayjs | null) => {
    setEndDate(value);
  };

  const getConfiguredOptions = useCallback(() => {
    const configuredOptions = ALERT_EXPORT_OPTIONS.filter((item) => {
      return Config.AlertExportOption?.indexOf(item.value) !== -1;
    });
    return configuredOptions;
  }, []);

  return(
    <Container>
      {message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
      <DataSourceContainer>
        <DataSourceLabel>{t('Data Source')}</DataSourceLabel>
        <CamerasContainer>
          <RadioButton 
            id='allCameras'
            value='allCameras'
            label={t('All Cameras')} 
            name='cameraCategory'
            checked={selectedCameraCategory === 'allCameras'}
            onChangeCallback={handleChange}
            currentChecked={selectedCameraCategory}
          />
          <RadioButton 
            id='specificCameras'
            value='specificCameras'
            label={t('Specific Cameras')} 
            name='cameraCategory'
            checked={selectedCameraCategory === 'specificCameras'}
            onChangeCallback={handleChange}
            currentChecked={selectedCameraCategory}
          />
        </CamerasContainer>
        { selectedCameraCategory === 'specificCameras' && <> {streams?.length > 0 ? 
          <>
            <SelectCameraLabel>{t('Select Cameras')}</SelectCameraLabel>
            <TableContainer>
              <DataGrid
                rows={generateRowData() ? generateRowData() : []}
                columns={getConfiguredColumns()}
                disableRowSelectionOnClick 
                checkboxSelection={true}
                disableColumnMenu
                hideFooterPagination
                onRowSelectionModelChange={handleRowSelectionModelChange}
                rowSelectionModel={selectedId}
              /> 
            </TableContainer> 
          </>
          : <EmptyLabelWrapper>
            <EmptyLabel>{t('No Cameras Found')}</EmptyLabel>
          </EmptyLabelWrapper> }</>}
        <TimeRangeContainer>
          <TimeRangeLabel>{t('Time Range')}</TimeRangeLabel>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePickersWrappers>
              <DemoContainer components={['DateTimePicker']}>
                <DateTimePicker
                  label={t('From Date')}
                  value={startDate}
                  defaultValue={null}
                  disableFuture
                  onChange={(newValue) => handleStartDate(newValue)}
                  onError={(err) => setDateError(!!err)}
                  format={DATE_PICKER_DATE_TIME_FORMAT}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                />
                <EndDateConatainer>
                  <DateTimePicker
                    label={t('To Date')}
                    value={endDate}
                    defaultValue={null}
                    disableFuture
                    onChange={(newValue) => handleEndDate(newValue)}
                    minDateTime={startDate}
                    onError={(err) => setDateError(!!err)}
                    format={DATE_PICKER_DATE_TIME_FORMAT}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                  />
                  {startDate !== null && endDate!== null  && (startDate > endDate) && <ErrorMessage>{t('To Date must be greater than From Date')}</ErrorMessage>} 
                </EndDateConatainer>
              </DemoContainer>
            </DatePickersWrappers>
          </LocalizationProvider>
        </TimeRangeContainer>
        <DropdownContainer display={getConfiguredOptions()?.length === 1}>
          <SelectField 
            label={t('Target')}
            onChange={onHandleExportOption} 
            defaultValue={alertExportOption}
            options={getConfiguredOptions()}
            disabled={getConfiguredOptions()?.length === 1}
          />
        </DropdownContainer>
        <IncludeLabelWrapper>
          <IncludeLabel>{t('Include')}</IncludeLabel>
          <CheckBoxContainer>
            <CheckboxAndLabelWrapper>
              <Checkbox checked={downloadOptions.text} size={18} id='text' onChangeCallback={() => setDownloadOptions({...downloadOptions, text: true})} />
              <TextLabel>{t('Text')}</TextLabel>
            </CheckboxAndLabelWrapper>
            <CheckboxAndLabelWrapper>
              <Checkbox checked={downloadOptions.image} size={18} id='image' onChangeCallback={() => setDownloadOptions({ ...downloadOptions, image: !downloadOptions.image })}/>
              <TextLabel>{t('Image')}</TextLabel>
            </CheckboxAndLabelWrapper>
            <CheckboxAndLabelWrapper>
              <Checkbox checked={downloadOptions.video} id='video' size={18}  onChangeCallback={() => setDownloadOptions({...downloadOptions, video: !downloadOptions.video})}/>
              <TextLabel>{t('Video')}</TextLabel>
            </CheckboxAndLabelWrapper>
          </CheckBoxContainer>
        </IncludeLabelWrapper>
        <StartExportButtonWrapper>
          <ButtonWithLoading 
            loading={loading}
            variant='primary'
            onClick={onHandleExport}
            disabled={!exportAlertsAccess || (startDate !== null && endDate!== null  && (startDate > endDate)) || allCamerasList === undefined || allCamerasList?.length === 0}
          >
            {alertExportOption === 'DOWNLOAD_ON_LOCAL' ? t('Download') : t('Start Export')}
          </ButtonWithLoading>
        </StartExportButtonWrapper>
      </DataSourceContainer>
    </Container>
  );
};

export default ExportAlerts;

const downloadLink = (link: string) => {
  if(!link){return;}
  const anchor = document.createElement('a');
  anchor.href = link;
  document.body.appendChild(anchor);
  anchor.click();
  anchor.remove(); 
};