import env from './config';
import Auth from '@aws-amplify/auth';
const {
  REGION: region,
  COGNITO_USER_POOL_ID: userPoolId,
  COGNITO_IDENTITY_POOL_ID: identityPoolId,
  COGNITO_CLIENT_ID: userPoolWebClientId,
  APPSYNC_URL,
  API_KEY
} = env;

const awsConfig = {
  aws_appsync_graphqlEndpoint: APPSYNC_URL,
  aws_appsync_region: region,
  aws_appsync_authenticationType: 'AWS_IAM',

  Auth: {
    region: region,
    identityPoolId: identityPoolId,
    userPoolId: userPoolId,
    userPoolWebClientId: userPoolWebClientId,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    mandatorySignIn: false
  },
};

export default awsConfig;

export const API_key = API_KEY;

export const getCredential = async() => {
  const result = await Auth.currentUserCredentials();
  return result;
};
