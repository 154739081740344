import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import Icon from './Icon';
import styled from 'styled-components';
import Button from './Button';
import TimePicker from './TimePicker';
import { format } from 'date-fns';
import { TIME_FORMAT, IconType } from '../constants';
import i18n from 'i18next';
import { isEqual } from 'lodash';
import FilterDropdownContainer from './FilterDropdownContainer';
import { useClickOutside } from '../hooks/useClickOutside';
import AlertBar from './AlertBar';

const EnableContainer = styled.div`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
`;

const EditButton =styled(Button)`
  height: 30px;
  border-radius: 3px;
  font-size: 12px;
  margin-top: 6px;
  margin-left: auto; 
  margin-right: 0;  
`;

const EmptyRuleContainer = styled.span`
  margin-left: 5px;
  font-size: 14px;
`;

const DetailsContainer = styled.div<{width:string}>`
  display: flex;
  width: ${({width}) => width};
  height: 40px;
  border: 1px solid #eee;
  padding: 9px;
  border-radius: 5px;
  margin-bottom: 11px;
  margin-top: 20px;
  background-color: #fff;
`;

const DetailsContainerOuter = styled.div<{width:string}>`
  display: flex;
  flex-direction: column;
  width: ${({width}) => width};
  justify-content: flex-end;
  margin-top: 40px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  > button {
    width: max-content;
  }
`;

const IconContainer = styled.button<{isEditAction:boolean}>`
  border: none;
  background-color: ${({isEditAction}) => isEditAction? '#ffffff':'#f9fafb'};
  height: 16px;
  margin-left: -2px;
`;

const ArrowContainer = styled.div`
  margin-left: -3px;
  margin-top: auto;
  margin-bottom: auto;
`;

const AddRuleButtonContainer = styled.div<{marginTop:boolean}>`
  display: flex;
  justify-content: end;
  width: 560px;
  gap: 20px;
  margin-top: ${({marginTop}) => marginTop ? '0' : '45px' };
  > button {
    width: max-content;
  }
`;

const RuleRowContainer = styled.div<{isEditAction:boolean, detailsContainerWidth:string, marginTop: boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  width: ${({isEditAction, detailsContainerWidth}) => isEditAction? '560px':detailsContainerWidth};
  height:  40px;
  margin-bottom: 10px;
  ${({isEditAction}) => isEditAction? 'background-color: #fff':''};
  border: 1px solid #eee;
  border-radius: 5px;
  padding-left: 7px;
  margin-top: ${({marginTop}) => marginTop ? '0' : '3px' };
`;

const RuleContainerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  width: 550px;
  height: 40px;
  margin-top: 0px;
  background-color: #fff;
  padding-left: 7px;
  border-radius: 5px;
`;

const DayWrapper=styled.span`
  margin-left: 12px;
`;

const Days = styled.div`
  width: 210px;
  height: 15px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin-top: 4px;
  line-height: 1.07;
  color: #8b9196;
`;

const Divider = styled.div`
  height: 1px;
  border-radius: 3px;
  background-color: #eee;
  width: 210px;
  margin: 3px 0 0 0px;
`;

const DividerFull = styled.div`
  height: 1px;
  border-radius: 3px;
  background-color: #eee;
  width: 560px;
  margin: 3px 0 14px 0;
`;

const TimeContainer = styled.div`
  width: 170px;
  display: flex;
  font-size: 14px;
  margin-left: 64px;
`;

const Time = styled.span`
  margin-right: 10px;
  font-size: 14px;
  margin-left: 10px;
`;

const TimeDivider = styled.div`
  height: 1px;
  border-radius: 3px;
  background-color: #eee;
  width: 37px;
  margin: 3px 0 0 0;
`;

const TimeDividerEdit = styled(TimeDivider)`
  margin: 3px 0 0 8px;
`;

const ActionsContainer = styled.div`
  width: 50px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 15px;
  margin-right: 10px;
  gap: 13px;
`;

const IconWrap = styled.span`
  cursor: pointer;
`;

const RuleContainer = styled.div<{validationError: boolean, marginTop: boolean}>`
  display: flex;
  flex-direction: column;
  border: ${({validationError})=>validationError ? '1px solid #f34848 ': '1px solid #dcdcdc'};
  background-color: white;
  height: 130px;
  width: 560px;
  margin-bottom: 15px;
  border-radius: 5px;
  margin-top: ${({marginTop})=>marginTop ? '0': '60px'};
`;

const DoneButton = styled(Button)`
  min-width: 45px;
  height: 20px;
  display: flex;
  align-items: center;
  font-size: 12px;
  border-radius: 3px;
  padding-left: 6px;
  padding-right: 5px;
  margin-right: 5px;
`;

const CancelButton = styled(Button)<{btnWidth: string}>`
  width: ${({btnWidth})=>btnWidth};
  min-width: 50px;
  height: 20px;
  display: flex;
  align-items: center;
  font-size: 12px;
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 5px;
  background-color: #e4edf4;
`;

const SelectDaysWrapper = styled.span`
  font-size: 14px;
  margin-left: 10px;
`;

const CheckWrapper = styled.div`
  width: 61px;
  display: flex;
  height: 20px;
`;

const DayLabel = styled.div`
  margin-left: 7px;
`;

const DaysContainer = styled.div`
  display: flex;
  width: 550px;
  height: 36px;
  margin-top: 9px;
  background-color: #fff;
  padding-left: 8px;
  padding-right: 10px;
  justify-content: space-between;
  font-size: 14px;
`;

const TimeView = styled.div`
  width: 54px;
  height: 20px;
  background-color: white;
  opacity: 100%;
  color: #8b9196;
  font-size: 12px;
`;

const TimeWrapper = styled.div`
  cursor: pointer;
`;

const Input = styled.input``;

const RuleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RollOverWrap = styled.div`
  font-size: 12px;
  font-style: italic;
  color: rgba(120, 138, 144, 0.72);
  width: 50px;
  margin-top: 2px;
`;

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  row-gap: 20px;
  height: auto;
  align-items: center;
  width: 621px;
`;

const LoadingText = styled.label`
  font-size: 14px;
  font-weight: 500;
  color: #8b9196;
  margin: 0;
  padding: 0;
`;

const ScheduleComponentContainer = styled.div<{isEditAction:boolean, detailsContainerWidth:string, marginTop: string}>`
  display: flex;
  flex-direction: column;
  margin-top: ${({marginTop}) => marginTop};
  width: ${({isEditAction, detailsContainerWidth}) => isEditAction? '560px':detailsContainerWidth};
`;

interface IWeeks {
  id:number;
  day:string;
}

const weekDays:{[key: number]: string} ={1:'Sun',2:'Mon',3:'Tue',4:'Wed',5:'Thu',6:'Fri',7:'Sat'};
const weekDaysOne = [{id:1,day:'Sun'},{id:2,day:'Mon' },{ id:3, day:'Tue'},{id:4, day:'Wed'},{id:5,day:'Thu'},{id:6,day:'Fri'},{id:7,day:'Sat'}, ];

export interface ISchedules {
  startTime?:string;
  endTime?:string;
  days?:number[];
}

interface IAlertMessageType {
  text: string,
  id: string,
  type: string | IconType,
}

interface IScheduleProps {
  width?: string;
  hideAddRuleButton?: boolean;
  scheduleDetails?: ISchedules[];
  detailsContainerWidth?: string;
  marginTop?: string;
  isEditView?: (item: object) => void;
  isDetailPage?: boolean;
  actionIcons?: boolean;
  hideActionButtons?: boolean;
  getScheduleList?: (item: object) => void;
  isUpdateList?: boolean;
  isModal?: boolean;
  setNotificationMessage?: (item: object) => void;
  isEmptySchedule?: boolean;
}

const ScheduleDays: React.FC<IScheduleProps> = ({width = '560px', hideAddRuleButton, scheduleDetails, detailsContainerWidth= '494px', marginTop= '-60px', isEditView, isDetailPage, actionIcons, hideActionButtons, isUpdateList, getScheduleList, isModal, setNotificationMessage, isEmptySchedule }) => {
  const { t } = useTranslation(['common']);
  const [ checkedDays, setCheckedDays ] = useState<number[]>([]);
  const currDateTime = new Date().setMinutes(0);
  const [ startTime, setStartTime ] = useState(new Date(currDateTime));
  const [ endTime, setEndTime ] = useState(new Date(currDateTime + 60*60*1000));
  const [ showAddRuleView, setShowAddRuleView ] = useState(false);
  const [ showEditRuleView, setShowEditRuleView ] = useState(false);
  const [ editAction, setEditAction ] = useState(false);
  const [ isValidationError, setValidationError ] = useState(false);
  const [editBoxID, setEditBoxID]=useState<number>();
  const [schedules, setSchedules]=useState<ISchedules[]>(scheduleDetails || []);
  const [enableRule, setEnableRule] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ showTimePickerTypeStart, setShowTimePickerTypeStart ] = useState(false);
  const [ showTimePickerTypeEnd, setShowTimePickerTypeEnd ] = useState(false);
  const startTimeRef = useRef<HTMLDivElement>(null);
  const endTimeRef = useRef<HTMLDivElement>(null);
  const [edited, setEdited] = useState(false);
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'}); 
  const [noScheduleSet, setNoScheduleSet] = useState(true);
  useClickOutside(startTimeRef, () => setShowTimePickerTypeStart(false));
  useClickOutside(endTimeRef, () => setShowTimePickerTypeEnd(false));

  useEffect(() => {
    setSchedules(scheduleDetails || []);
  },[scheduleDetails]);

  useEffect(()=>{
    if(actionIcons){
      if(schedules.length > 0){
        setEditAction(true);
      }
    }
  },[actionIcons, schedules.length]);

  useEffect(() =>{
    if(isEmptySchedule){
      setSchedules([]);
      setShowAddRuleView(true);
      setEditAction(false);
      setShowAddRuleView(false);
      setShowEditRuleView(false);
      setCheckedDays([]);
      setValidationError(false);
      setEditBoxID(undefined);
    }
  },[isEmptySchedule]);

  useEffect(() => {
    if(isUpdateList){
      if(getScheduleList){
        getScheduleList(schedules);
      }
    }
  },[getScheduleList, schedules, isUpdateList]);
  
  const fetchScheduleDays = useCallback(async()=>{
    try{
      // const { data: { status_code, data } } = await getAlertSupression(streamName);
      const status_code= 0;
      const data = {schedules: undefined, enabled: true }; // taken dummy data
      if(status_code === 0 && data.schedules !== undefined){
        setSchedules(data.schedules);
      }
      setEnableRule(data?.enabled ? true : false);
      setLoading(false);
    }catch(e){
      console.error(e);
      setMessage({text: t('Failed to communicate with the system'), id:'',type:'error'});
      setLoading(false);
    }
  },[t]);

  const addSchedules = useCallback(async(scheduleList: ISchedules[], ruleEnabled: boolean, action: string)=>{
    console.log(scheduleList, ruleEnabled, typeof(ruleEnabled), '123');
    try{
      // const res = await addAlertSuppRules(streamName, params);  service called from here
      const res = 0;    // taken dummy data
      if(res===0){
        fetchScheduleDays();
        if(action === 'add' || action === 'edit'){
          if(isModal){
            // if(setNotificationMessage){
            //   setNotificationMessage({type: 'success', message: t('Rule saved successfully')});
            // }
          }
          // notifictaionRef.current({ type: 'success', message: t('Rule saved successfully')});
        } else if(action === 'remove' ){
          if(isModal){
            // if(setNotificationMessage){
            //   setNotificationMessage({type: 'success', message: t('Rule deleted successfully')});
            // }
          }
          // notifictaionRef.current({ type: 'success', message: t('Rule deleted successfully')});
        }
      }
    }catch(e){
      console.error(e);
      setMessage({text: t('Failed to communicate with the system'), id:'',type:'danger'});
    }
  },[fetchScheduleDays, t, isModal]);

  const onChangeStartTime = useCallback((date?: Date) => {
    setEdited(true);
    setStartTime(date as Date);
  }, []);

  const onChangeEndTime = useCallback((date?: Date) => {
    setEdited(true);
    setEndTime(date as Date);
  }, []);

  const getSelectedDays = useCallback((data:{[key: number]: string}, key?: number[] ) => {
    const days: string[] = [];
    let formatted = '';
    if(key!== undefined) {
      Object.values(key).map((i :number)=>{
        return days.push(data[i] !== undefined ? t(`${data[i]}`) : '');
      });
      formatted = days.join(', ');
    } else {
      formatted = '';
    }
    return formatted;
  },[t]);

  const showTimePicker = useCallback((pickerFor: string) => {
    switch (pickerFor) {
    case 'start':
      setShowTimePickerTypeStart(prev => !prev);
      break;
    case 'end':
      setShowTimePickerTypeEnd(prev => !prev);
      break;
    default:
      setShowTimePickerTypeStart(false);
      setShowTimePickerTypeEnd(false);
    }
  }, []);

  const scrollToBottom = useCallback(() => {
    const check : HTMLElement | null = document.getElementById('messageBody');
    setTimeout(() => {
      if(check !== null){
        check.scrollTop = check.offsetHeight;
      }
    }, 100);
  },[]);

  const onClickAddRule = useCallback(() => {
    setShowAddRuleView(true);
    scrollToBottom();
  },[scrollToBottom]);

  const cancleRule = useCallback(() =>{
    setEditAction(false);
    if(actionIcons){
      if(schedules.length > 0){
        setEditAction(true);
      }
    }
    setShowAddRuleView(false);
    setShowEditRuleView(false);
    setCheckedDays([]);
    setValidationError(false);
    setEditBoxID(undefined);
    fetchScheduleDays();
  },[fetchScheduleDays, actionIcons, schedules.length]);

  const onClickEditButton = () => {
    setEditAction(prev => (!prev));
    if(isDetailPage){
      if (isEditView){
        isEditView({});
      }
    }
    else {
      if(actionIcons){
        // if(schedules.length > 0){ //need this code 
        // setEditAction(true);
        // }
      }
      setEditBoxID(undefined);
      setShowEditRuleView(false);
      setShowAddRuleView(false);
    }
  };

  const handleDays = useCallback((e: React.ChangeEvent<HTMLInputElement>, index:number) => {
    setEdited(true);
    setValidationError(false);
    if(e.target.checked){
      const updatedList = [...checkedDays, index];
      setCheckedDays(updatedList.sort());
    } else {
      const updatedList = [...checkedDays];
      updatedList.splice(checkedDays.indexOf(index), 1);
      setCheckedDays(updatedList.sort());
    }
  },[checkedDays]);

  const isScheduleEqual = useCallback((...item: Array<{}>) => { 
    // return item.every((obj: object) => JSON.stringify(obj) === JSON.stringify(item[0]));    
    return item.every((obj: object) => (isEqual(obj,item[0])));
  },[]);

  const doneRules = useCallback(() => {
    const startTimeConversion = format(startTime, 'HH:mm');
    const endTimeConversion = format(endTime, 'HH:mm');
    if(endTimeConversion !== '00:00'){
      if(startTimeConversion > endTimeConversion){
        setMessage({text: t('Please select a start time smaller than the end time'), id:'',type:'danger'});
        setValidationError(true);
        return;
      } else if (startTimeConversion === endTimeConversion) {
        setMessage({text: t('Start time and end time cannot be same'), id:'',type:'danger'});
        setValidationError(true);
        return;
      }
    } 
    const rules: ISchedules = {startTime: format(startTime, 'HH:mm'), endTime: format(endTime, 'HH:mm'), days: checkedDays};
    const timeDiff = Math.floor((endTime.getTime() - startTime.getTime())/(1000*60));
    if(rules?.days?.length){
      if(schedules && schedules.length > 0){
        const isScheduleExist = schedules?.find((schedule) => isScheduleEqual(schedule, rules));
        if((isScheduleExist && editBoxID === undefined) || (isScheduleExist && editBoxID !== undefined && edited)){
          if(isModal){
            if(setNotificationMessage){
              setMessage({text: t('Rule already exists'), id:'',type:'danger'});
            }
          }
          setEdited(false);
          return setMessage({text: t('Rule already exists'), id:'',type:'danger'});
        } else {
          setSchedules((schedules)=> [...schedules, rules]);  // temporarily added rules into local use state hook
          setEdited(false);
        }
      } else {
        setSchedules((schedules)=> [...schedules, rules]);  // temporarily added rules into local use state hook
        setEdited(false);
      }
    }
    if(timeDiff <= 0){
      // Day is rollover so calculate the time differnce of end time and start time
      const finalEndTime = 24 - parseInt(format(startTime, 'HH')) + parseInt(format(endTime, 'HH'));
      if(finalEndTime >= 24) {
        if(isModal){
          if(setNotificationMessage){
            setNotificationMessage({type: 'error', message: t('Please select a start time smaller than the end time')});
          }
        }
      }
    }

    if(checkedDays?.length!==0){
      if(editBoxID !== undefined){
        const tempSchedules = [...schedules];
        tempSchedules[editBoxID] = rules;
        addSchedules(tempSchedules, enableRule, 'edit');
        setSchedules(tempSchedules);
      } else {
        const tempSchedules = [...schedules, rules];
        addSchedules(tempSchedules, enableRule, 'add');
      }
      setEditAction(prev => (!prev));
      if(actionIcons){
        if(schedules.length > 0){
          setEditAction(true);
        }
      }
      setCheckedDays([]);
      setValidationError(false);
      setEditBoxID(undefined);
      setShowAddRuleView(false);
      setShowEditRuleView(false);
    } else {
      setValidationError(true);
      if(isModal){
        if(setNotificationMessage){
          setMessage({text: t('Please select a day for schedule'), id:'',type:'danger'});
        }
      }
      setMessage({text: t('Please select a day for schedule'), id:'',type:'danger'});
    }
  },[startTime, endTime, checkedDays, editBoxID, schedules, t, addSchedules, enableRule, isScheduleEqual, isModal, setNotificationMessage, actionIcons, edited]);

  const editRules = (index:number)=>{
    setShowAddRuleView(false);
    scrollToBottom();
    const items = schedules.filter((ele, i)=>{
      return i === index;
    });
    const editItem = items[0];
    if(editItem){
      const tempStartDate = new Date();
      const startTimeArr = editItem?.startTime?.split(':');
      tempStartDate.setHours(parseInt(startTimeArr ? startTimeArr[0]: ''));
      tempStartDate.setMinutes(parseInt(startTimeArr ? startTimeArr[1] : ''));
      setStartTime(tempStartDate);
      const tempEndDate = new Date();
      const endTimeArr = editItem?.endTime?.split(':');
      tempEndDate.setHours(parseInt(endTimeArr ? endTimeArr[0]: ''));
      tempEndDate.setMinutes(parseInt(endTimeArr ? endTimeArr[1]: ''));
      setEndTime(tempEndDate);
      setCheckedDays(editItem?.days || []);
      setEditBoxID(index);
      setShowEditRuleView(true);
    }
  };

  const removeRules = useCallback((index:number) =>{
    const updatedSchedules = schedules?.filter((ele, i)=>{
      return i !== index;
    });
    setSchedules(updatedSchedules);
    addSchedules(updatedSchedules, updatedSchedules.length > 0 ? enableRule : false, 'remove');
    setEditAction(prev => (!prev));
    if(actionIcons){
      if(schedules.length > 0){
        setEditAction(true);
      }
    }
    setCheckedDays([]);
    setValidationError(false);
    setEditBoxID(undefined);
    setShowAddRuleView(false);
    setShowEditRuleView(false);
    setEditAction(false);
    setNoScheduleSet(true);
  },[addSchedules, schedules, enableRule, actionIcons]);

  const getRollOverDate = useCallback((date_str: string) => {
    const tempDate = new Date();
    const timeArr = date_str?.split(':');
    tempDate.setHours(parseInt(timeArr[0]));
    tempDate.setMinutes(parseInt(timeArr[1])); 
    tempDate.setSeconds(0);
    return tempDate;
  },[]);

  useEffect(() => {
    setLoading(true);
    fetchScheduleDays();
  }, [fetchScheduleDays]);

  const onSelectAllDays = (val: boolean) => {
    if(val){
      const allDays: number[] = [];
      weekDaysOne.map(item => allDays.push(item.id));
      setCheckedDays(allDays);
    } else {
      setCheckedDays([]);
    }
  };

  const getRuleContainer = useCallback(() => {
    return(
      <RuleContainer validationError={isValidationError} marginTop={schedules.length > 0}>
        <RuleContainerWrapper>
          <IconContainer isEditAction={editAction}> <Icon size={18} icon='Schedule' /> </IconContainer>
          <DayWrapper>
            <Days>{getSelectedDays(weekDays, checkedDays)}</Days>
            <Divider />
          </DayWrapper>
          <TimeContainer>
            <TimeView ref={startTimeRef}>
              <TimeWrapper onClick={() => showTimePicker('start')}>
                <Time>{`${format(startTime, TIME_FORMAT)}`}</Time>
              </TimeWrapper>
              <TimeDividerEdit />
              <TimeWrapper ref={startTimeRef}>
                {(showTimePickerTypeStart) &&
                  <FilterDropdownContainer>
                    <TimePicker time={startTime} onSave={onChangeStartTime} showTime={showTimePicker} hourIncrementBy={1} />
                  </FilterDropdownContainer>}
              </TimeWrapper>
            </TimeView>
            <ArrowContainer>
              <Icon size={18} icon='Right' />
            </ArrowContainer>
            <TimeView ref={endTimeRef}>
              <TimeWrapper onClick={() => showTimePicker('end')}>
                <Time>{`${format(endTime, TIME_FORMAT)}`}</Time>
              </TimeWrapper>
              <TimeDividerEdit />
              <TimeWrapper ref={endTimeRef}>
                {(showTimePickerTypeEnd) &&
                  <FilterDropdownContainer>
                    <TimePicker time={endTime} onSave={onChangeEndTime} showTime={showTimePicker} hourIncrementBy={1} />
                  </FilterDropdownContainer>}
              </TimeWrapper>
            </TimeView>
          </TimeContainer>
          <CancelButton variant='secondary' btnWidth={i18n.language === 'ja' ? '100px' : '60px'} onClick={()=>cancleRule()}>{t('Cancel')}</CancelButton> 
          <DoneButton variant='primary' onClick={() => doneRules()}>{t('Done')}</DoneButton>
        </RuleContainerWrapper>
        <DividerFull />
        <SelectDaysWrapper>{t('Select Days')}</SelectDaysWrapper>
        <DaysContainer>
          <CheckWrapper>
            <Input type='checkbox' checked={checkedDays.length === weekDaysOne.length ? true : false} onChange={(e)=> onSelectAllDays(e.target.checked)} />
            <DayLabel>{t('All')}</DayLabel>
          </CheckWrapper>
          {weekDaysOne.map((item: IWeeks) => {
            return (
              <>
                <CheckWrapper key={item.id}>
                  <Input type='checkbox' value={item.day} checked={checkedDays.includes(item.id) ? true : false} onChange={(e)=>handleDays(e, item.id)} />
                  <DayLabel>{t(`${item.day}`)}</DayLabel>
                </CheckWrapper>
              </>
            );
          })}
        </DaysContainer>
      </RuleContainer>
    );
  },[isValidationError, checkedDays, editAction, doneRules, cancleRule, endTime, getSelectedDays, handleDays, onChangeEndTime, onChangeStartTime, showTimePicker, startTime, t, showTimePickerTypeEnd, showTimePickerTypeStart, schedules ]);

  return (
    <>
      {message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
      <ScheduleComponentContainer isEditAction={editAction} detailsContainerWidth={detailsContainerWidth} marginTop={marginTop}>
        {loading ?
          <SpinnerContainer>
            <CircularProgress />
            <LoadingText>{t('Loading')}</LoadingText>
          </SpinnerContainer>
          :
          <>
            {schedules?.length > 0 &&
              <EnableContainer>
                {( hideActionButtons || 
                 <EditButton 
                   onClick={() => onClickEditButton()}
                   variant={editAction ? 'secondary' : 'primary'}>
                   {editAction ? t('Cancel') : t('Edit')}
                 </EditButton>)}
              </EnableContainer>
            }
            {(schedules?.length === 0 && editAction === false && showAddRuleView === false) ?
              <DetailsContainerOuter id='noRuleMessage' width={width}>
                {noScheduleSet &&
                  <DetailsContainer width={width}>
                    <IconContainer isEditAction={editAction}> <Icon size={18} icon='Schedule' /> </IconContainer>
                    <EmptyRuleContainer>
                      {t('No Rule Set')}
                      <Divider />
                    </EmptyRuleContainer>
                  </DetailsContainer>}
                {showAddRuleView ||
                 (hideAddRuleButton !==true && 
                   <ButtonWrapper>
                     <Button variant='primary' onClick={()=>onClickAddRule()}>{t('Add Rule')}</Button>
                   </ButtonWrapper>)}
              </DetailsContainerOuter>: ''}        
            {schedules?.map((item: ISchedules,index: number)=>{              
              return(
                <RuleWrapper key={index + 'Rule'}>
                  {editBoxID === index ?
                    getRuleContainer()
                    :
                    <RuleRowContainer key={index} isEditAction={editAction} detailsContainerWidth={detailsContainerWidth} marginTop={schedules.length > 1}>
                      <IconContainer isEditAction={editAction}>
                        <Icon size={18} icon='Schedule' />
                      </IconContainer>
                      <DayWrapper>
                        <Days>{getSelectedDays(weekDays, item.days)}</Days>
                        <Divider />
                      </DayWrapper>
                      {(getRollOverDate(item?.startTime ? item?.startTime : '').getTime() > getRollOverDate(item?.endTime ? item?.endTime : '').getTime()) ?
                        <TimeContainer>
                          <Time>{item.startTime}<TimeDivider /></Time>
                          <ArrowContainer>
                            <Icon size={18} icon='Right' />
                          </ArrowContainer>
                          <Time> {item.endTime} <TimeDivider /> </Time>
                          {item.endTime === '00:00' ? '' :<RollOverWrap>+1 {t('day')}</RollOverWrap>}
                        </TimeContainer>:
                        <TimeContainer>
                          <Time>{item.startTime}<TimeDivider /></Time>
                          <ArrowContainer>
                            <Icon icon='Right' size={9} weight='regular' />
                          </ArrowContainer>
                          <Time> {item.endTime} <TimeDivider /> </Time>
                          <RollOverWrap> </RollOverWrap>
                        </TimeContainer>}
                      {editAction &&
                        <ActionsContainer>
                          <IconWrap onClick={()=>editRules(index)}>
                            <Icon size={18} icon='Edit'/>
                          </IconWrap>
                          <IconWrap onClick={()=>removeRules(index)}>
                            <Icon size={18} icon='Close' />
                          </IconWrap>
                        </ActionsContainer>}
                    </RuleRowContainer>}
                </RuleWrapper>
              );
            })}
            {showAddRuleView &&
            getRuleContainer()}
            <AddRuleButtonContainer marginTop={schedules.length > 0}>
              {editAction && !showAddRuleView && !showEditRuleView &&
                <Button disabled={schedules.length > 19 ? true : false} variant='primary' onClick={()=>onClickAddRule()}>{t('Add Rule')}</Button>}
            </AddRuleButtonContainer>
          </>}
      </ScheduleComponentContainer>
    </>
  );
};

export default ScheduleDays;