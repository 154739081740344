import Input from 'components/Input';
import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PipelineArea from './EventPipelineTemplates/PipelineArea';
import { CircularProgress } from '@mui/material';
import usePipelineInfo from 'hooks/usePipelineInfo';
import ButtonWithLoading from 'components/ButtonWithLoading';
import Button from 'components/Button';
import { IAlertMessageType, IBreadcrum, IPipelineInfo } from 'interface';
import { useHistory } from 'react-router';
import { EP_ACTION_TEMPLATES, IconType, StatusCode } from '../../constants';
import {useMutation} from '@apollo/client';
import { UPDATE_EVENT_PIPELINE } from 'api_configs/mutations';
import AlertBar from 'components/AlertBar';
import { useHeader } from 'contexts/HeaderContext';
import CancelConfirmationModal from 'pages/configurations/CancelConfirmationModal';
import lodash from 'lodash';
import { descriptionRegex } from 'utils/utils';
import Switch from 'components/Switch';

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 300px;
  gap: 10px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const Loading = styled.span``;

const HeaderLeftContainer = styled.div`
  display: flex;
  gap: 30px;
  position: relative;
`;

const SwitchWrapper = styled.div`
  display: flex;
  gap: 10px;
  position: absolute;
  right: 0;
`;

const Span = styled.span``;

interface IParams {
  id: string;
}

const EditEventPipeline = () => {
  const {updateHeaderTitle} = useHeader();
  const params : IParams = useParams();
  const {t} = useTranslation(['common']);
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'});
  const { pipelineInfo, setPipelineInfo, actionNodes, filterNodes, scheduleNodes, epLoading, loading, pipelineInfoBeforeEdit, refetchPipelineInfo } = usePipelineInfo(parseInt(params.id));
  const [currentFilters, setCurrentFilters] = useState<number>(0);
  const {push} = useHistory();
  const [updateEventPipeline, {loading: updateLoading}] = useMutation(UPDATE_EVENT_PIPELINE);
  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false);

  useEffect(()=>{
    const editPipelineBreadcrum: IBreadcrum[] = [
      {label: t('Event Pipelines'), to: '/settings/event-pipeline'}
    ];
    updateHeaderTitle(t('Edit Event Pipeline'), 'EventPipeline', editPipelineBreadcrum);
  },[updateHeaderTitle, t]);

  const getLoading = useCallback(()=>{
    return (
      <LoadingContainer>
        <CircularProgress />
        <Loading>{t('Loading')}</Loading>
      </LoadingContainer>
    );
  },[t]);

  const handleCancelclick = useCallback(()=>{
    if(lodash.isEqual(pipelineInfoBeforeEdit, pipelineInfo)){
      push('/settings/event-pipeline');
    }else{
      setCancelModalOpen(prev => !prev);
    }
  },[push, pipelineInfoBeforeEdit, pipelineInfo]);

  const onCancelYes = useCallback(() => {
    push('/settings/event-pipeline');
  },[push]);

  const onCancelNo = useCallback(() => {
    setCancelModalOpen(prev => !prev);
  },[]);

  const handleUpdatePipeline = useCallback(async()=>{
    const updatedPayload: IPipelineInfo = currentFilters === 0 ? {...pipelineInfo, filters: {cameras: ['ALL']}} : {...pipelineInfo};
    const pipelineName = updatedPayload.name.trim();
    if(pipelineName.length === 0){
      setMessage({text: t('Please enter event pipeline name.'), id:'',type:'danger'});
      return;
    }
    if(updatedPayload.event.length === 0){
      setMessage({text: t('Please select algorithm'), id:'',type:'danger'});
      return;
    }if(updatedPayload.actions.length === 0){
      setMessage({text: t('No action configured.'), id:'',type:'danger'});
      return;
    }
    if(updatedPayload.actions.length > 0){
      let isErrorInActionsData = false;
      updatedPayload.actions.forEach(action => {
        if(action.type === EP_ACTION_TEMPLATES.WHATSAPP || action.type === EP_ACTION_TEMPLATES.SMS || action.type === EP_ACTION_TEMPLATES.EMAIL){
          if(action.templateId === null ){
            setMessage({text: t(`Please select ${action.type} template`), id:'',type:'danger'});
            isErrorInActionsData = true;
            return;
          }
          if(!('data' in action)){
            setMessage({text: t('Please enter configuration details for each action.'), id:'',type:'danger'});
            isErrorInActionsData = true;
            return;
          }
          if(!('mobile_no' in action.data) && !(action.type === EP_ACTION_TEMPLATES.EMAIL)){
            setMessage({text: t('Please enter mobile numbers.'), id:'',type:'danger'});
            isErrorInActionsData = true;
            return;
          }
          if(action.type === EP_ACTION_TEMPLATES.EMAIL){
            if(!('to' in action.data)){
              setMessage({text: t('Please enter valid email recipients'), id:'',type:'danger'});
              isErrorInActionsData = true;
              return;
            }
          }
        }
      });
      if(isErrorInActionsData){
        return;
      }
    }
    const delActionIds = pipelineInfoBeforeEdit.actions.map(action => action.id).filter(id => !pipelineInfo.actions.map(action => action.id).includes(id));
    const {id, ...other} = updatedPayload;
    const updatedActions = [...updatedPayload.actions].map(action => {
      if('id' in action){
        return action;
      }else{
        // Add id:0 if the new action is configured.
        return {...action, id: 0};
      }
    });
    try{
      const res = await updateEventPipeline({variables: {payload: { ...other, name: pipelineName, epId: id, delAction: delActionIds.length > 0 ? delActionIds : null, actions: [...updatedActions]}}});
      if(res && res?.data?.updateEventPipeline?.statusCode === StatusCode.SUCCESS){
        setMessage({text: t('Event pipeline updated successfully!'), id:'',type:'success'});
        refetchPipelineInfo();
        setTimeout(()=>{
          push('/settings/event-pipeline');
        },1000);
      }else if(res && res?.data?.updateEventPipeline?.statusCode === StatusCode.DUPLICATE_ENTRY){
        setMessage({text: t('Event pipeline with this name already exist. Please try different name'), id:'',type:'danger'});
      }else{
        setMessage({text: res?.data?.updateEventPipeline?.statusMessage, id:'',type:'danger'});
      }
    }catch(e){
      console.log(e);
    }
  },[updateEventPipeline, pipelineInfo, t, currentFilters, pipelineInfoBeforeEdit, push, refetchPipelineInfo]);

  return (
    <Container>
      {(epLoading || loading) ? getLoading() : 
        <>
          <Header>
            {message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
            <HeaderLeftContainer>
              <Input type='text' id='name' value={pipelineInfo?.name as string} maxLength={100} placeholder={t('Pipeline name')} onChange={(e) => setPipelineInfo(prev => ({...prev, name: e.target.value?.replace(descriptionRegex, '')}))} label={t('Pipeline name')} isRequired={true} />
              <SwitchWrapper>
                <Span>{pipelineInfo?.enabled ? t('Enabled'): t('Disabled')}</Span>
                <Switch checked={pipelineInfo.enabled}  onChange={() => setPipelineInfo(prev => ({...prev, enabled: !prev.enabled}))} />
              </SwitchWrapper>
            </HeaderLeftContainer>
            <ButtonsContainer>
              <ButtonWithLoading loading={updateLoading} variant='primary' onClick={handleUpdatePipeline}>{t('Save')}</ButtonWithLoading>
              <Button variant='secondary' onClick={handleCancelclick}>{t('Cancel')}</Button>
            </ButtonsContainer>
          </Header>
          <PipelineArea 
            action_nodes={actionNodes} 
            filter_nodes={filterNodes} 
            schedule_nodes={scheduleNodes}
            pipelineInfo={pipelineInfo} 
            setPipelineInfo={setPipelineInfo}
            getFilterLength={(length) => setCurrentFilters(length)}
          />
        </>
      }      
      {cancelModalOpen && <CancelConfirmationModal modalOpen={cancelModalOpen} setModalOpen={setCancelModalOpen} onCancelNo={onCancelNo} onCancelYes={onCancelYes} />}
    </Container>
  );
};

export default EditEventPipeline;