import { useQuery } from '@apollo/client';
import { GET_ALL_ALERTS, GET_ALERT_AUDIO } from 'api_configs/queries';
import { ALERT_SETTING_IN_APP_SOUND, ALERT_STATUS_VALUES, StatusCode } from '../constants';
import React, { ReactNode, createContext, useEffect, useState, useCallback } from 'react';
import { getAudioAlertName } from 'utils';
import Config from '../configs/config.json';

export const AlertsContext = createContext<any | undefined>(undefined);

type AlertsContextProps = {
  children: ReactNode;
};

interface IGlobalAlertSoundSetting {
  volume: number,
  audioName: string,
  enabled: boolean,
}

const getIntialAlertRefreshInterval = (): string => {
  const storedValue = localStorage.getItem('alertRefreshInterval');
  return storedValue !== null ? JSON.parse(storedValue) : 'none';
};

export const AlertsContextProvider = ({ children }: AlertsContextProps) => {
  const { refetch } = useQuery(GET_ALL_ALERTS, {skip: !Config.getAlertsAPIEnable, variables: {payload: {}}});
  const [globalAlertSoundSettings, setGlobalAlertSoundSettings] = useState<IGlobalAlertSoundSetting>();
  const [previousAlertId, setPreviousAlertId] = useState(0);
  const { data } = useQuery(GET_ALERT_AUDIO, { 
    variables: {recordType: ALERT_SETTING_IN_APP_SOUND}, skip: !Config.getAlertsAPIEnable});
  const [alertRefreshInterval, setAlertContextRefreshInterval] = useState<string>(getIntialAlertRefreshInterval);
  
  useEffect(()=>{
    if(data && data?.getAlertAudio?.statusCode === StatusCode.SUCCESS){
      const convertData = JSON.parse(data?.getAlertAudio?.result?.data);
      setGlobalAlertSoundSettings(convertData);
    }
  },[data]);

  const playAlertSound = useCallback(() => {
    if(globalAlertSoundSettings){
      const selectedAudio = getAudioAlertName(globalAlertSoundSettings.audioName);
      const audio = new Audio(selectedAudio);
      audio.volume = globalAlertSoundSettings.volume / 100;
      if(globalAlertSoundSettings.enabled){
        audio.play().catch(err => console.log(err, 'Play Error'));
      }
    }
  },[globalAlertSoundSettings]);

  useEffect(()=>{
    if(Config.getAlertsAPIEnable) {
      const fetchAlerts = async () => {
        if (refetch) {
          const res = await refetch({
            payload: {
              pageSize: Number(localStorage.getItem('alertsPageSize')) || 10,
              pageNumber: 1,
              status: ALERT_STATUS_VALUES.Active,
            },
          });
          if(res && res?.data?.getAlerts?.result?.data && res?.data?.getAlerts?.statusCode === StatusCode.SUCCESS){
            const latestAlertId = res?.data?.getAlerts?.result?.data[0]?.id;
            if (latestAlertId && previousAlertId < latestAlertId) {
              playAlertSound();
            }
            setPreviousAlertId(latestAlertId);
          }
        }
      };
      fetchAlerts();
      let fetchInterval: any;
      if(alertRefreshInterval !== 'none'){
        fetchInterval = setInterval(() => {
          fetchAlerts();
        }, parseInt(alertRefreshInterval)*1000);
      }
      return () => {
        clearInterval(fetchInterval);
      };
    }
  },[refetch, playAlertSound, previousAlertId, alertRefreshInterval]);

  return (
    <AlertsContext.Provider value={{setAlertContextRefreshInterval, alertRefreshInterval}}>
      {children}
    </AlertsContext.Provider>
  );
};