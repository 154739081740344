import { Breadcrumbs } from '@mui/material';
import React from 'react';
import Icon from './Icon';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { IBreadcrum } from 'interface';

const BreadcrumbContainer = styled.div``;

const CustomLink = styled(Link)`
  text-decoration: none;
  color: #575757;
  :hover{
    color: #25292A;
  }
  font-size: 12px;
  >div{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 400px;
  }
`;

interface IBreadcrumbProps{
  breadCrumbs: IBreadcrum[]
}

const BreadCrumbs: React.FC<IBreadcrumbProps> = ({breadCrumbs}) => {
  return (
    <BreadcrumbContainer>
      <Breadcrumbs separator={<Icon icon='Right' size={18} />}>
        {breadCrumbs?.map((item)=>(
          <CustomLink to={item.to}>
            <div>{item.label}</div>
          </CustomLink>
        ))}
      </Breadcrumbs>
    </BreadcrumbContainer>
  );
};

export default BreadCrumbs;