import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgVideo = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M15.153 9.569 11 6.84a.7.7 0 0 0-.979.218.674.674 0 0 0-.098.35v5.455a.674.674 0 0 0 .366.601.702.702 0 0 0 .71-.034l4.154-2.727a.687.687 0 0 0 .31-.568.674.674 0 0 0-.31-.567Zm-3.845 2.02V8.688l2.213 1.45-2.213 1.452ZM19.615 4H4.385c-.368 0-.72.144-.98.4-.26.255-.405.602-.405.964v9.545c0 .362.146.709.406.964.26.256.611.4.979.4h15.23c.368 0 .72-.144.98-.4.26-.255.405-.602.405-.964V5.364c0-.362-.146-.709-.405-.965-.26-.255-.612-.399-.98-.399Zm0 10.91H4.385V5.363h15.23v9.545ZM21 18.317c0 .181-.073.354-.203.482a.697.697 0 0 1-.49.2H3.693a.698.698 0 0 1-.49-.2.677.677 0 0 1 0-.964c.13-.128.307-.2.49-.2h16.616c.183 0 .36.072.49.2s.202.301.202.482Z"
        fill={props.color}
      />
    </svg>
  );
};
export default SvgVideo;
