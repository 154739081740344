import { useMutation, useQuery } from '@apollo/client';
import { GET_FLOOR_PLAN } from 'api_configs/queries';
import { IAlertMessageType, IFloorPlanConfigsRes, IGetStream, IPoints, IAnomalyDetectionConfigs, ISpeedDetectionConfigs, ITrafficPulseDetectionConfigs, IRouteDeviationDetectionConfigs, IFloorPlanConfigs} from 'interface';
import React, { useState, useEffect, useReducer, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FLOOR_PLAN_TABS, IconType, LineUIType, StatusCode, ZONE_LINEUI_CODES } from '../../constants';
import { IPointSet, IRestrictionPointsData, LineReducer, LineSetContext } from 'components/LineUI';
import Button from 'components/Button';
import { CREATE_FLOOR_PLAN_CONFIG, DELETE_FLOOR_PLAN, EDIT_FLOOR_PLAN_CONFIG } from 'api_configs/mutations';
import AlertBar from 'components/AlertBar';
import lodash from 'lodash';
import CancelConfirmationModal from '.././configurations/CancelConfirmationModal';
import { checkUniqueNames, getFillAreaColor, getLineUIColor } from 'utils/utils';
import ButtonWithLoading from 'components/ButtonWithLoading';
import ZoneMapLineViewer from 'components/ZoneMapLineViewer';
import ZoneConfiguration from 'components/mapview/ZoneConfiguration';
import { useHeader } from 'contexts/HeaderContext';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import { CircularProgress } from '@mui/material';
import UploadImage from 'components/UploadImage';
import CameraConfigurationTab from 'components/mapview/CameraConfigurationTab';
import { useHistory, useLocation } from 'react-router';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ConfigurationDetails = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #F5F5F5;
  border-radius: 5px;
  margin: 20px;
  padding: 10px;
  gap: 20px;
  width: 1202px;
`;

const ConfigurationFieldsWrapper = styled.div`
  display: flex;
  gap: 30px;
`;

const WizardContainer = styled.div`
  margin-top: 20px;
  background-color: white;
  padding: 0.2em 0em 0.2em 0.2em;
`;

const SmallWizard = styled(WizardContainer)`
  margin-left: 15px;
`;

const LineViewerWrapper = styled.div`
  width: 40vw;
  gap: 5px;
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid rgb(245, 245, 245);
  border-radius: 5px;
  height: 360px;
  @media only screen and (min-width: 1366px) {
    width: 540px;
  }
  @media only screen and (min-width: 1920px){
    width: 550px;
  }
`;

const AdvancedTabWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DeleteWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 18px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  button{
    width: 100px;
    height: 30px;
  }
`;

const Heading = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-left: 18px;
`;

const VerticalLine = styled.div`
  width: 1202px;
  height: 10px;
  border-bottom: 1px solid #e3e3e3;
  margin-top: 2px;
  margin-bottom: 15px;
  margin-left: 18px;
  @media (max-width: 768px) {
    width: 450px;
  }
`;

const SelectedTabContainer = styled.div`
  border-radius: 5px;
`;

const ButtonsWrapper = styled.div`
  width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
`;

const WizardLink = styled.a<{ isCurrent: boolean, isPastStep: boolean, isFirstTab: boolean, isLastTab: boolean }>`
  padding: 13px 12px 13px;
  position: relative;
  display: inline-block;
  text-decoration: none;
  min-width: 300px;
  height: 60px;
  margin-left: 3px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  color: ${({ isCurrent, isPastStep }) => (isCurrent || isPastStep ? '#ffffff' : 'rgb(44 23 23)')};;
  font-weight: 600;
  background: ${({ isCurrent, isPastStep }) => (isCurrent ? '#435FCA' : isPastStep ? '#435FCA' : 'rgb(206, 208, 214)')};
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  &:first-child {
    margin-left: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &:before {
    width: 0;
    height: 0;
    border-top: ${({isFirstTab}) => isFirstTab ? '' : '30px inset transparent'};
    border-bottom: ${({isFirstTab}) => isFirstTab ? '' : '30px inset transparent'};
    border-left: 30px solid #FFFFFF;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
  }

  &:after {
    width: 0;
    height: 0;
    border-top: ${({isLastTab}) => isLastTab ? '' : '30px inset transparent'};
    border-bottom: ${({isLastTab}) => isLastTab ? '' : '30px inset transparent'};
    border-left: 30px solid ${({ isCurrent, isPastStep }) => (isCurrent ? '#435FCA' : isPastStep ? '#435FCA' : 'rgb(206, 208, 214)')};
    position: absolute;
    content: '';
    top: 0;
    right: -30px;
    z-index: 2;
    }
`;

const CurrentLink = styled(WizardLink)`

  background: #435FCA;
  color: #fff;
  &:after {
      border-left-color: #435FCA !important;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 1202px;
  min-height: 400px;
  gap: 10px;
`;

const Loading = styled.span``;

const Badge = styled.div`
  margin: 7px 5px 0 18px;
  position: relative;
  top: -1px;
`;

interface IStateValue {
  name: string,
  points: IPoints[],
  readOnly: boolean,
  styling: string,
  showSmallDirectionMark: boolean,
  showPointHandle: boolean
}

interface IFloorMapProps {
  streamDetails: IGetStream;
}

interface ITab {
  id: number;
  name: string;
  level: string;
}

const defaultFloorPlanConfigsRes = {
  id: 0,
  data: [], 
  level: '',
  image: ''
};

const FloorMap: React.FC<IFloorMapProps> = ({streamDetails}) => {
  const {t} = useTranslation(['common']);
  const [selectedLayerConfigDetails, setSelectedLayerConfigDetails] = useState<IFloorPlanConfigsRes>(defaultFloorPlanConfigsRes);
  const [selectedInitialLayerConfigDetails, setSelectedInitialLayerConfigDetails] = useState<IFloorPlanConfigsRes>(defaultFloorPlanConfigsRes);
  const [ allLayerConfigDetails, setAllLayerConfigDetails] = useState<IFloorPlanConfigsRes[]>([defaultFloorPlanConfigsRes]);
  const { data: floorPlan, refetch, loading } = useQuery(GET_FLOOR_PLAN);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [state, dispatch] = useReducer(LineReducer,[]);
  const history = useHistory();
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'});
  const [cancelModal, setCancelModal] = useState<boolean>(false);
  const [selectedLayerLineDetails, setSelectedLayerLineDetails] = useState<IPointSet[]>([]);
  const [selectedLayerConfig, setSelectedLayerConfig] = useState<(IFloorPlanConfigs)[]>([]);
  const [ updateFloorPlanConfigMutation ] = useMutation(EDIT_FLOOR_PLAN_CONFIG);
  const [ createFloorPlan ] = useMutation(CREATE_FLOOR_PLAN_CONFIG);
  const [ deleteFloorPlan ] = useMutation(DELETE_FLOOR_PLAN);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [file, setFile] = useState({filename: '', base64: ''});
  const {updateHeaderTitle} = useHeader();
  const [selectedTab, setSelectedTab] = useState<ITab>({id:0, name:'Select Floor Plan', level: 'ZERO'});
  const [isEditFloorPlan, setIsEditFloorPlan] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [stateCount] = useState<number>(4);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [restrictionPoints, setRestrictionPoints] = useState<IRestrictionPointsData>();
  const [selectedZoneConfig, setSelectedZoneConfig] = useState<(IFloorPlanConfigs)[]>([]);
  const [selectedZoneIndex, setSelectedZoneIndex] = useState<number>(0);
  const [isDeleteFloorPlan, setIsDeleteFloorPlan] = useState<boolean>(false);
  const location = useLocation();

  useEffect(()=>{
    updateHeaderTitle(t('Floor Plan'),'List');
  },[updateHeaderTitle, t]);

  const getLineDataOfSelectedLayer = useCallback((configs: (IFloorPlanConfigs)[], defaultIndex?: number): IPointSet[]=>{
    const lineData: IPointSet[] = [];
    configs?.map((config, index)=>{
      if(config?.lineType === LineUIType.AREA){
        const areaDetails = {
          areaName: config.name,
          points: (config as IFloorPlanConfigs)?.points as IPoints[],
          styling: config.styling,
          showMoveHandle: defaultIndex === index ? true : false,
          showPointHandle: defaultIndex === index ? true : false,
          showSmallDirectionMark: false,
          areaFillColor: getFillAreaColor(config.styling),
          areaTransparencyLevel: 40,
          cameraName: config?.cameraName ? config?.cameraName : ''
        };
        lineData.push(areaDetails);
      } 
      return null;
    });
    return lineData;
  },[]);

  useEffect(()=>{
    const floorPlanData = floorPlan?.getAllFloorPlans?.result !== null ? floorPlan?.getAllFloorPlans?.result : [];
    const selectedLayerDetails = floorPlanData?.filter((item :IFloorPlanConfigsRes) => item.level === 'FIRST');
    setAllLayerConfigDetails(floorPlanData);
    if(selectedLayerDetails && selectedLayerDetails[0]?.level === 'FIRST') setFile({filename: '', base64: selectedLayerDetails[0]?.image ? selectedLayerDetails[0]?.image as string : ''});
    const cameraLayerDetails = floorPlanData?.filter((item :IFloorPlanConfigsRes) => item.level === 'THIRD') ?? [];
    setIsDeleteFloorPlan(cameraLayerDetails[0]?.data?.length !== 0 && cameraLayerDetails[0]?.data !== undefined);
  },[floorPlan, getLineDataOfSelectedLayer]);
  
  useEffect(() =>{
    let selectedLayerDetails: IFloorPlanConfigsRes[] = [];
    let zoneData: IFloorPlanConfigsRes[] = [];
    if(selectedTab.level === FLOOR_PLAN_TABS[1].level){
      selectedLayerDetails = allLayerConfigDetails?.filter((item :IFloorPlanConfigsRes) => item.level === 'FIRST') ?? [];
      (selectedLayerDetails && selectedLayerDetails[0]) ? setSelectedLayerConfigDetails(selectedLayerDetails && selectedLayerDetails[0]) : setSelectedLayerConfigDetails(prev => ({...prev, data: []}));
      (selectedLayerDetails && selectedLayerDetails[0]) ? setSelectedInitialLayerConfigDetails(selectedLayerDetails && selectedLayerDetails[0]) : setSelectedInitialLayerConfigDetails(prev => ({...prev, data: []}));
      setIsEditFloorPlan(selectedLayerDetails?.length !== 0);
      setSelectedLayerConfig(selectedLayerDetails && selectedLayerDetails[0]?.data ? (selectedLayerDetails[0]?.data) as IFloorPlanConfigs[] : []);
    }else if(selectedTab.level === FLOOR_PLAN_TABS[2].level) {
      selectedLayerDetails = allLayerConfigDetails?.filter((item :IFloorPlanConfigsRes) => item.level === 'SECOND') ?? [];
      (selectedLayerDetails && selectedLayerDetails[0]) ? setSelectedLayerConfigDetails(selectedLayerDetails && selectedLayerDetails[0]) : setSelectedLayerConfigDetails(prev => ({...prev, data: []}));
      (selectedLayerDetails && selectedLayerDetails[0]) ? setSelectedInitialLayerConfigDetails(selectedLayerDetails && selectedLayerDetails[0]) : setSelectedInitialLayerConfigDetails(prev => ({...prev, data: []}));
      setIsEditFloorPlan(selectedLayerDetails?.length !== 0);
      setSelectedLayerConfig(selectedLayerDetails && selectedLayerDetails[0]?.data ? (selectedLayerDetails[0]?.data) as IFloorPlanConfigs[] : []);
      setSelectedZoneConfig(selectedLayerDetails && selectedLayerDetails[0]?.data ? (selectedLayerDetails[0]?.data) as IFloorPlanConfigs[] : []);
    }else if(selectedTab.level === FLOOR_PLAN_TABS[3].level) {
      selectedLayerDetails = allLayerConfigDetails?.filter((item :IFloorPlanConfigsRes) => item.level === 'THIRD') ?? [];
      zoneData = allLayerConfigDetails?.filter((item :IFloorPlanConfigsRes) => item.level === 'SECOND') ?? [];
      (selectedLayerDetails && selectedLayerDetails[0]) ? setSelectedLayerConfigDetails(selectedLayerDetails && selectedLayerDetails[0]) : setSelectedLayerConfigDetails(prev => ({...prev, data: []}));
      (selectedLayerDetails && selectedLayerDetails[0]) ? setSelectedInitialLayerConfigDetails(selectedLayerDetails && selectedLayerDetails[0]) : setSelectedInitialLayerConfigDetails(prev => ({...prev, data: []}));
      setIsEditFloorPlan(selectedLayerDetails?.length !== 0);
      setSelectedZoneConfig(zoneData && zoneData[0]?.data ? (zoneData[0]?.data) as IFloorPlanConfigs[] : []);
      setSelectedLayerConfig(selectedLayerDetails && selectedLayerDetails[0]?.data ? (selectedLayerDetails[0]?.data) as IFloorPlanConfigs[] : []);
    }
    let defaultIndex = 0;
    if ((selectedTab.id !== 3) && selectedLayerDetails && selectedLayerDetails[0]?.data?.length > 0) {
      defaultIndex = 1;
    }
    setSelectedIndex(defaultIndex);
    const linedata = getLineDataOfSelectedLayer(selectedLayerDetails && (selectedLayerDetails[0]?.data) as IFloorPlanConfigs[], defaultIndex -1);
    setSelectedLayerLineDetails(linedata);
    dispatch({
      type: 'LOAD',
      state: linedata,
    });
  },[allLayerConfigDetails, getLineDataOfSelectedLayer, selectedTab.id, selectedTab.level]);

  useEffect(() =>{
    let selectedLayerDetails: IFloorPlanConfigsRes[] = [];
    if(selectedTab.level === FLOOR_PLAN_TABS[2].level) {
      const restrictionPointsData = allLayerConfigDetails?.filter((item :IFloorPlanConfigsRes) => item.level === 'FIRST');
      const linedata = getLineDataOfSelectedLayer(restrictionPointsData && (restrictionPointsData[0]?.data) as IFloorPlanConfigs[]);
      setRestrictionPoints({data:linedata, selectedIndex: selectedZoneIndex});
    }else if(selectedTab.level === FLOOR_PLAN_TABS[3].level) {
      selectedLayerDetails = allLayerConfigDetails?.filter((item :IFloorPlanConfigsRes) => item.level === 'SECOND') ?? [];
      const linedata = getLineDataOfSelectedLayer(selectedLayerDetails && (selectedLayerDetails[0]?.data) as IFloorPlanConfigs[]);
      setRestrictionPoints({data:linedata, selectedIndex: selectedZoneIndex});
    }
  },[allLayerConfigDetails, getLineDataOfSelectedLayer, selectedTab.level, selectedZoneIndex]);

  useEffect(()=>{
    localStorage.setItem('line_data', JSON.stringify(state));
    localStorage.setItem('selectedIndex', JSON.stringify(selectedIndex));
    setSelectedLayerLineDetails(state);
    if (selectedIndex > 0){
      setSelectedLayerConfig(prev => {
        const selectedLayerConfig = [...prev];
        const selectedLineConfig = {...selectedLayerConfig[selectedIndex -1]};
        if(selectedLineConfig && 'points' in selectedLineConfig){
          selectedLineConfig.points = state[selectedIndex - 1]?.points &&  [...state[selectedIndex - 1].points];
        }
        selectedLayerConfig[selectedIndex - 1] = selectedLineConfig;
        return selectedLayerConfig;
      });
    }
  },[state, selectedIndex]);
  
  useEffect(()=>{
    setSelectedLayerConfigDetails(prev => ({...prev, data: selectedLayerConfig? selectedLayerConfig : []}));
  },[selectedLayerConfig, getLineDataOfSelectedLayer]);

  const addLine = useCallback((name: string, lineArray: IPoints[][], cameraName: string, prevLength: number= -1, lineIndex?:number) => {
    const state_value = localStorage.getItem('line_data');
    const stateValue = JSON.parse(state_value as string);
    const deselectLineIndex: number[] = stateValue?.map((item: IStateValue, index: number) => item.showPointHandle ? index : null).filter((index: number | null) => index !== null);
    const newLineData: IPointSet[] = [];
    deselectLineIndex.forEach((index)=>{
      dispatch({
        type: 'UPDATE_SET_OPTIONS',
        index: index,
        data: {
          showPointHandle: false,
          showMoveHandle: false
        }
      });
    });

    lineArray?.map((line)=>{
      const lineData = {
        cameraName: cameraName ? cameraName : '',
        areaName: name,
        points: line,
        styling:getLineUIColor(ZONE_LINEUI_CODES[selectedTab.id !== 2 ? 0 : selectedLayerConfig?.length + 1]),
        showMoveHandle: true,
        showPointHandle: true,
        showSmallDirectionMark: true,
        areaFillColor: getFillAreaColor(getLineUIColor(ZONE_LINEUI_CODES[selectedTab.id !== 2 ? 0 : selectedLayerConfig?.length + 1]) as string),
        areaTransparencyLevel: 40
      };
      newLineData.push(lineData);
      return line;
    });

    setSelectedLayerLineDetails(prev => [...prev, ...newLineData]);
    if(prevLength !== -1){
      newLineData.forEach((line)=>{
        dispatch({
          type: 'ADD_SET_ARRAY',
          index: prevLength + (lineIndex as number),
          data: line
        });
      });
    }else{
      newLineData.forEach((line)=>{
        dispatch({
          type: 'ADD_SET',
          data: line
        });
      });
    }
  },[selectedLayerConfig?.length, selectedTab.id]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const selectedTab = searchParams.get('selectedTab');

    if(selectedTab){
      setCurrentStep(Number(selectedTab));
      setSelectedTab(FLOOR_PLAN_TABS[Number(selectedTab) -1]);
    }
  }, [location.search]);

  const onAddNewLine = useCallback((cameraName: string, data: ITrafficPulseDetectionConfigs | IAnomalyDetectionConfigs | ISpeedDetectionConfigs | IRouteDeviationDetectionConfigs, currentLength: number) => {
    if ((data.lineType === LineUIType.AREA || data.lineType === LineUIType.LINE) && 'points' in data) {
      addLine(data.name, [data?.points] as IPoints[][], cameraName);
    } else if (data.lineType === LineUIType.LINE_SET && 'line_set' in data) {
      addLine(data.name, [data?.line_set?.line1, data?.line_set?.line2] as IPoints[][], cameraName);
    } else if (data.lineType === LineUIType.LINE_SET_ARRAY && 'line_set_array' in data) {
      data.line_set_array?.forEach((lineSet) => {
        if (lineSet?.points && lineSet.line_name) {
          addLine(lineSet.line_name, [lineSet?.points] as IPoints[][], cameraName);
        }
      });
    }
    setSelectedIndex(currentLength + 1);
    setSelectedLayerConfigDetails(prev => {
      const algoConfigDetails = { ...prev };
      algoConfigDetails.data = [...algoConfigDetails.data, data];
      return algoConfigDetails;
    });
  },[addLine]);

  const handleSaveConfigs = useCallback(async ()=>{
    setSaveLoading(true);
    const selectedAlgoConfigurations = selectedLayerConfig;
    if(selectedTab.id === 0){
      setSelectedTab(FLOOR_PLAN_TABS[1]);
      setCurrentStep(2);
      history.push('?selectedTab=2');
    }else{
      if(checkUniqueNames(selectedAlgoConfigurations)){
        if(isEditFloorPlan){
          const data = await updateFloorPlanConfigMutation({variables:{payload: {id: selectedLayerConfigDetails?.id, data:selectedAlgoConfigurations, level:selectedTab.level, image: file.base64}}});
          if(data && data?.data?.editFloorPlan?.statusCode === StatusCode.SUCCESS){
            if(selectedTab.id === 3) setMessage({text: t('Floor plan configuration saved successfully'), id:'',type:'success'});
            if(selectedTab.id === 1){
              setSelectedTab(FLOOR_PLAN_TABS[2]);
              setCurrentStep(3);
              history.push('?selectedTab=3');
            }else if(selectedTab.id === 2){
              setSelectedTab(FLOOR_PLAN_TABS[3]);
              setCurrentStep(4);
              history.push('?selectedTab=4');
            }
            refetch();
          }else{
            if(selectedTab.id === 3) setMessage({text: t('Failed to save floor plan configuration. Please try again!'), id:'',type:'danger'});
          }
        }else{
          const data = await createFloorPlan({variables:{payload: {data: selectedAlgoConfigurations, level:selectedTab.level, image: file.base64}}});
          if(data && data?.data?.createFloorPlan?.statusCode === StatusCode.SUCCESS){
            if(selectedTab.id === 3) setMessage({text: t('Floor plan configuration saved successfully'), id:'',type:'success'});
            if(selectedTab.id === 1){
              setSelectedTab(FLOOR_PLAN_TABS[2]);
              setCurrentStep(3);
              history.push('?selectedTab=3');
            }else if(selectedTab.id === 2){
              setSelectedTab(FLOOR_PLAN_TABS[3]);
              setCurrentStep(4);
              history.push('?selectedTab=4');
            }
            refetch();
          }else{
            if(selectedTab.id === 3) setMessage({text: t('Failed to save floor plan configuration. Please try again!'), id:'',type:'danger'});
          }
        }
      }
      else{
        setMessage({text: t('Duplicate Zone Name values are not allowed'), id:'',type:'danger'});
      }
    }
    setSaveLoading(false);
  },[selectedLayerConfig, isEditFloorPlan, history, updateFloorPlanConfigMutation, selectedLayerConfigDetails?.id, selectedTab.id, selectedTab.level, file.base64, t, refetch, createFloorPlan]);

  const onLineClickCallback = useCallback((lineId: number, isSet?: boolean, prevLength: number= -1, length?:number) => {
    setSelectedIndex(lineId+1);
    const deselectLineIndex = state?.map((item: IPointSet, index: number) => item.showPointHandle ? index : null).filter((index: number | null) => index !== null);
    deselectLineIndex.length > 0 && deselectLineIndex.forEach((index)=>{
      dispatch({
        type: 'UPDATE_SET_OPTIONS',
        index: index as number,
        data: {
          showPointHandle: false,
          showMoveHandle: false
        }
      });
    });
    if(prevLength !== -1){
      for(let i=prevLength; i<prevLength + (length as number); i++){
        dispatch({
          type: 'UPDATE_SET_OPTIONS',
          index: i,
          data: {
            showPointHandle: true,
            showMoveHandle: true
          }
        });
      }
    }else{
      if(isSet){
        const indexArray = [lineId*2, lineId*2 + 1];
        indexArray.forEach((index)=>{
          dispatch({
            type: 'UPDATE_SET_OPTIONS',
            index: index,
            data: {
              showPointHandle: true,
              showMoveHandle: true
            }
          });
        });
      }else{
        dispatch({
          type: 'UPDATE_SET_OPTIONS',
          index: lineId,
          data: {
            showPointHandle: true,
            showMoveHandle: true
          }
        });
      }
    }
  }, [state]);

  const handleRemoveLine = useCallback((index: number, length: number, isSet: boolean = false, prevLength: number = -1, lineIndex?:number)=>{
    let nextIndex = 0;
    if(length === 1 && index === 0){
      nextIndex = 0;
    }else if (length-1 === index){
      nextIndex = index;
    }else{
      nextIndex = index + 1;
    }
    setSelectedLayerConfig(prev => {
      return [...prev.filter((_,index2)=>index2 !== index)];
    });
    onLineClickCallback(nextIndex-1, isSet);
    setSelectedLayerConfigDetails(prev => {
      const algoConfigDetails = {...prev};
      const algoConfigs = [...algoConfigDetails.data.filter((_,index2)=>index2 !== index)];
      algoConfigDetails.data = algoConfigs;
      return algoConfigDetails;
    });
    if(prevLength !== -1){
      dispatch({
        type: 'REMOVE_SET_ARRAY',
        index: prevLength,
        lineIndex: lineIndex as number,  
      }); 
    }else{
      if(isSet){ 
        const indexArray = [index*2, index*2 + 1];
        indexArray.forEach(()=>{
          dispatch({
            type: 'REMOVE_SET',
            index: index*2,
          });
        });
      }else{
        dispatch({
          type: 'REMOVE_SET',
          index: index,
        });
      }
    }
  },[onLineClickCallback]);

  const onDeleteFloorPlan = useCallback(async () => {
    const idList = allLayerConfigDetails?.map((item) => item.id);
    try {
      const result = await deleteFloorPlan({variables: { idList: idList}});
      if(result.data.deleteFloorPlans.statusCode === StatusCode.SUCCESS){
        setMessage({text: t('Floor plan deleted successfully'), id:'',type:'success'});
        refetch();
        setSelectedTab(FLOOR_PLAN_TABS[0]);
        setFile({filename:'', base64: ''});
        setCurrentStep(1);
        history.push('?selectedTab=1');
      }else {
        setMessage({text: t('apiError'), id:'',type:'danger'});
      }
    } catch(e){
      setMessage({text: t('apiError'), id:'',type:'danger'});
    }
  },[allLayerConfigDetails, history, deleteFloorPlan, t, refetch]);

  const handleStepChange = (step: number) => {
    if( isDeleteFloorPlan && (step === currentStep || step === currentStep -1 || step === currentStep + 1 )){
      setCurrentStep(step);
      history.push(`?selectedTab=${step}`);
      setSelectedTab(FLOOR_PLAN_TABS[step - 1]);
    }
  };

  const handleCancel = useCallback(()=>{
    if(selectedInitialLayerConfigDetails?.data.length !== 0){
      setCancelModal(prev => !prev);
    }else{
      setSelectedIndex(0);
      setSelectedLayerConfig([]);
    }
  },[selectedInitialLayerConfigDetails]);

  const onCancelYes = useCallback((isEdited: boolean)=>{
    if(isEdited){
      setSelectedIndex(1);
      setCancelModal(prev => !prev);
      setSelectedLayerConfig(selectedInitialLayerConfigDetails?.data);

    }else{
      setCancelModal(prev => !prev);
    }
  },[selectedInitialLayerConfigDetails]);

  const onCancelNo = useCallback(()=>{
    setCancelModal(prev => !prev);
  },[]);

  return (
    <>
      <Container>
        { message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
        {isDeleteModalOpen &&
      <ConfirmationModal modalOpen={isDeleteModalOpen} setModalOpen={setIsDeleteModalOpen} onSubmit={()=> onDeleteFloorPlan()} hasConfirmationField={false}
        titleText={t('Delete floor plan?')} confirmDescription={t('Are you sure you want to delete floor plan?')} noteText={t('Once deleted this action cannot be undone.')} />
        }
        <HeaderContainer>
          <SmallWizard>
            {Array.from({ length: stateCount }, (_, i) => {
              let stepName;
              const step = i + 1;
              const isCurrent = step === currentStep;
              const StepLink = isCurrent ? CurrentLink : WizardLink;
              if(step === 1) {
                stepName = t('Select Floor Plan');
              }else if(step === 2) {
                stepName = t('Area Configuration');
              }else if(step === 3) {
                stepName = t('Zone Configuration');
              }else if(step === 4) {
                stepName = t('Camera Configuration');
              }
              return (
                <StepLink key={step} onClick={() => handleStepChange(step)} isCurrent={isCurrent} isPastStep={Number(step) < Number(currentStep)} isFirstTab={step === 1} isLastTab={step === 4}>
                  <Badge>{`${stepName}`}</Badge>
                </StepLink>
              );
            })}
          </SmallWizard>
        </HeaderContainer>
        {loading ? 
          <LoadingContainer>
            <CircularProgress />
            <Loading>{t('Loading...')}</Loading>
          </LoadingContainer>
          :<ConfigurationDetails>
            <><><ConfigurationFieldsWrapper>
              <SelectedTabContainer>
                {selectedTab.id === 0 && <UploadImage
                  file={file}
                  setFile={setFile}
                />}
                {selectedTab.id === 1 && <ZoneConfiguration
                  onAddNewLine={onAddNewLine}
                  setSelectedLayerConfig={setSelectedLayerConfig}
                  selectedLayerConfig={selectedLayerConfig}
                  handleRemoveLine={handleRemoveLine}
                  selectedIndex={selectedIndex}
                  selectedTab={selectedTab.id}
                  selectedLayerConfigLength={selectedLayerConfig?.length}
                  dispatch={dispatch}
                  onLineClickCallback={onLineClickCallback} />}
                {selectedTab.id === 2 && <ZoneConfiguration
                  onAddNewLine={onAddNewLine}
                  setSelectedLayerConfig={setSelectedLayerConfig}
                  selectedLayerConfig={selectedLayerConfig}
                  handleRemoveLine={handleRemoveLine}
                  selectedIndex={selectedIndex}
                  selectedTab={selectedTab.id}
                  selectedLayerConfigLength={selectedLayerConfig?.length}
                  dispatch={dispatch}
                  onLineClickCallback={onLineClickCallback} />}
                {selectedTab.id === 3 && <CameraConfigurationTab
                  onAddNewLine={onAddNewLine}
                  setSelectedLayerConfig={setSelectedLayerConfig}
                  selectedLayerConfig={selectedLayerConfig}
                  selectedZoneConfig={selectedZoneConfig}
                  handleRemoveLine={handleRemoveLine}
                  selectedIndex={selectedIndex}
                  selectedTab={selectedTab.id}
                  selectedLayerConfigLength={selectedLayerConfig?.length}
                  dispatch={dispatch}
                  onLineClickCallback={onLineClickCallback} 
                  setSelectedZoneIndex={setSelectedZoneIndex}
                  selectedZoneIndex={selectedZoneIndex}
                  allLayerConfigDetails= {allLayerConfigDetails}
                />}
              </SelectedTabContainer>
              { selectedTab.id !== 0 && <LineViewerWrapper>
                <LineSetContext.Provider value={{ state, dispatch }}>
                  <ZoneMapLineViewer isCamera={selectedTab.id === 3} onLineClickCallback={selectedLayerConfig && (selectedLayerConfig[selectedIndex - 1]?.lineType === LineUIType.LINE_SET || selectedLayerConfig[selectedIndex - 1]?.lineType === LineUIType.LINE_SET_ARRAY) ? () => { } : onLineClickCallback} showDirectionMark={selectedLayerConfig && (selectedLayerConfig[selectedIndex - 1]?.lineType === LineUIType.LINE_SET || selectedLayerConfig[selectedIndex - 1]?.lineType === LineUIType.LINE)} streamID={streamDetails?.instanceId} width='100%' height='100%' state={selectedLayerLineDetails} dispatch={dispatch} hasFullScreenToggle={true} floorMapImg={file?.base64 ? file.base64 : ''} restrictionPoints={selectedTab.id === 1 ? {data: [], selectedIndex:-1} : restrictionPoints} />
                </LineSetContext.Provider>
              </LineViewerWrapper>}
            </ConfigurationFieldsWrapper></><ButtonsWrapper>
              <Button disabled={selectedTab.id === 0} variant='secondary' onClick={() => handleStepChange(currentStep - 1)}>{t('Previous')}</Button>
              <ButtonWithLoading loading={saveLoading} disabled={(selectedTab.id !== 0 && lodash.isEqual(selectedInitialLayerConfigDetails, selectedLayerConfigDetails)) || file.base64 === ''} variant='primary' type='submit' onClick={handleSaveConfigs}>{selectedTab.id !== 3 ? t('Next') : t('Done')}</ButtonWithLoading>
              <Button disabled={(selectedTab.id === 0 || lodash.isEqual(selectedInitialLayerConfigDetails, selectedLayerConfigDetails)) } variant='secondary' onClick={handleCancel}>{t('Cancel')}</Button>
            </ButtonsWrapper></>
            {cancelModal && <CancelConfirmationModal modalOpen={cancelModal} setModalOpen={setCancelModal} onCancelYes={() => onCancelYes(!lodash.isEqual(selectedInitialLayerConfigDetails, selectedLayerConfigDetails))} onCancelNo={onCancelNo} />}
          </ConfigurationDetails>}
        {!loading && isDeleteFloorPlan && <AdvancedTabWrapper>
          <Heading>{t('Delete floor plan')}</Heading>
          <VerticalLine />
          <DeleteWrapper> <Button variant='danger' onClick={() => setIsDeleteModalOpen(true)}>{t('Delete')}</Button></DeleteWrapper>
        </AdvancedTabWrapper>}
      </Container>
    </>
  );
};

export default FloorMap;