import { IReducerActions } from 'components/LineUI/LineReducer';
import { ALGORITHM_CODES, DEFAULT_LINE_POINTS, IconType, LineUIType, PERMISSION_CODE } from '../../../constants';
import { IAlertMessageType, IOption, ISpeedDetectionConfigs, INewAnalysisConfigsRes } from 'interface';
import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import AlertBar from 'components/AlertBar';
import { AddNewLineWrapper,  DeleteIconDiv, HorizontalDivider, IconDiv, Label, LabelWrapper, LineDetailContainer, LineName, LineNameWrapper, LinesContainer, LinesListContainer } from './algoConfigStyles';
import Icon from 'components/Icon';
import { useTranslation } from 'react-i18next';
import Config from '../../../configs/config.json';
import Input from 'components/Input';
import InputWithUnit from 'components/InputWithUnit';
import { allowDegree, checkUniqueNames, getLineUIColor, isNullOrEmpty } from 'utils/utils';
import { Slider } from '@mui/material';
import Switch from 'components/Switch';
import TokenService from 'api_configs/tokenService';

const ConfigurationForm = styled.div`
  display: flex;
  gap: 20px;
`;

const LowHighSpeedConfigs = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
`;

const SpeedDetectionConfig = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 200px;
`;

const SpeedDetectionLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NoLineData = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 100%;
  min-height: 100%;
  border-radius: 5px;
  border: 1px solid #D0D7F2;
  min-height: 310px;
  min-width: 600px;
`;

const LimitWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: -10px;
`;

const LimitText = styled.div`
  font-size: 12px;
  font-weight: 500;
`;

const NoLineText = styled.div`
  max-width: 60%;
  font-size: 14px;
  color: ${({theme})=>theme.text.secondaryText};
  text-align: center;
`;
 
const AddNewLineButton = styled.div<{disabled:boolean}>`
  background: ${({disabled}) => disabled ? '#8E9FDF' : 'linear-gradient(to top, #1538BD, #7287D7)' } ;
  color: #FFFFFF;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: ${({disabled}) => disabled ? 'not-allowed' : 'pointer' } ;
`;

interface ISpeedDetectionProps {
  selectedAlgorithmConfigsLength: number;
  selectedIndex: number;
  onAddNewLine: (a: boolean, data: ISpeedDetectionConfigs, currentLength: number)=>void;
  handleRemoveLine?: (index: number, length: number, isSet?: boolean)=>void;
  dispatch: React.Dispatch<IReducerActions>;
  onLineClickCallback?: (id: number, isSet?: boolean) => void;
  setSelectedAlgoConfigs: React.Dispatch<React.SetStateAction<ISpeedDetectionConfigs[]>>;
  selectedAlgoConfigs: ISpeedDetectionConfigs[];
  scheduleOptions:IOption[];
  selectedAlgorithmConfigDetails: INewAnalysisConfigsRes;
  setSelectedAlgorithmConfigDetails: React.Dispatch<React.SetStateAction<INewAnalysisConfigsRes>>;
  setValidationMessage: React.Dispatch<React.SetStateAction<string>>;
}

const SpeedDetection: React.FC<ISpeedDetectionProps> = ({selectedAlgorithmConfigsLength, handleRemoveLine=()=>{}, dispatch, setValidationMessage, selectedAlgoConfigs, onAddNewLine, setSelectedAlgoConfigs, onLineClickCallback=()=>{}, selectedIndex}) => {
  const {t} = useTranslation(['common']);
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'});

  const editAnalysisAccess = TokenService.hasAccess(PERMISSION_CODE.edit_analysis_configuration);

  const AddNewLine = useCallback(() => {
    if(selectedAlgorithmConfigsLength + 1 > Config.algorithmConfigurations.lineSetsLimit){
      setMessage({text: t('configurationLimitWarning').replace('{X}',`${Config.algorithmConfigurations.lineSetsLimit}`), id:'',type:'danger'});
    }else{
      const newLineData: ISpeedDetectionConfigs = {
        name: 'Lane' + (selectedAlgorithmConfigsLength + 1),
        index: selectedAlgorithmConfigsLength + 1,
        lineType: LineUIType.LINE_SET,
        styling: getLineUIColor(ALGORITHM_CODES.speed_detection) as string,
        direction: '',
        line_set: {
          line1: DEFAULT_LINE_POINTS.map((item) => ({x: item.x + (selectedAlgorithmConfigsLength * 80), y: item.y})),
          line2: DEFAULT_LINE_POINTS.map((item) => ({x: item.x + 100 + (selectedAlgorithmConfigsLength * 80), y: item.y})),
        },
        low_speed_enabled: true,
        high_speed_enabled: true,
      };
      onAddNewLine(true, {...newLineData}, selectedAlgorithmConfigsLength);
      setSelectedAlgoConfigs(prev => [...prev, newLineData]);
    }
  },[selectedAlgorithmConfigsLength, setSelectedAlgoConfigs, onAddNewLine, t]);

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAlgoConfigs((prev: ISpeedDetectionConfigs[]) => {
      const selectedAlgoConfigs = [...prev];
      const selectedLineConfig = JSON.parse(JSON.stringify(selectedAlgoConfigs[selectedIndex - 1])); 
      selectedLineConfig[e.target.name] = e.target.name !== 'name' ? parseInt(e.target.value) : e.target.value;
      selectedAlgoConfigs[selectedIndex - 1] = selectedLineConfig;
      return selectedAlgoConfigs;
    });
    if(e.target.name === 'name'){
      dispatch({
        type: 'RENAME_SET',
        index: selectedIndex - 1,
        data: {
          name: e.target.value
        }
      });
      dispatch({
        type: 'RENAME_SET',
        index: selectedIndex,
        data: {
          name: e.target.value
        }
      });
    }
  },[setSelectedAlgoConfigs, selectedIndex, dispatch]);

  const handleDistanceChange = useCallback((e: React.ChangeEvent<HTMLInputElement>)=>{
    if (/^[0-9]*[.]?[0-9]*$/.test(e.target.value) && (parseFloat(e.target.value) < 1000 || Number.isNaN(parseFloat(e.target.value)))) {
      setSelectedAlgoConfigs((prev: ISpeedDetectionConfigs[]) => {
        const selectedAlgoConfigs = [...prev];
        const selectedLineConfig = JSON.parse(JSON.stringify(selectedAlgoConfigs[selectedIndex - 1])); 
        selectedLineConfig[e.target.name] = e.target.value;
        selectedAlgoConfigs[selectedIndex - 1] = selectedLineConfig;
        return selectedAlgoConfigs;
      });
    }
  },[setSelectedAlgoConfigs, selectedIndex]);

  const handleSwitchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>)=>{
    setSelectedAlgoConfigs((prev: ISpeedDetectionConfigs[]) => {
      const selectedAlgoConfigs = [...prev];
      const selectedLineConfig = JSON.parse(JSON.stringify(selectedAlgoConfigs[selectedIndex - 1])); 
      selectedLineConfig[e.target.name] = e.target.checked;
      selectedAlgoConfigs[selectedIndex - 1] = selectedLineConfig;
      return selectedAlgoConfigs;
    });
  },[selectedIndex, setSelectedAlgoConfigs]);

  const handleHighSpeedChange = (newValue: number) => {
    setSelectedAlgoConfigs((prev: ISpeedDetectionConfigs[]) => {
      const selectedAlgoConfigs = [...prev];
      const selectedLineConfig = JSON.parse(JSON.stringify(selectedAlgoConfigs[selectedIndex - 1]));
      selectedLineConfig.high_speed_threshold = newValue;
      selectedAlgoConfigs[selectedIndex - 1] = selectedLineConfig;
      return selectedAlgoConfigs;
    });
  };

  const handleLowSpeedChange = (newValue: number) => {
    setSelectedAlgoConfigs((prev: ISpeedDetectionConfigs[]) => {
      const selectedAlgoConfigs = [...prev];
      const selectedLineConfig = JSON.parse(JSON.stringify(selectedAlgoConfigs[selectedIndex - 1]));
      selectedLineConfig.low_speed_threshold = newValue;
      selectedAlgoConfigs[selectedIndex - 1] = selectedLineConfig;
      return selectedAlgoConfigs;
    });
  };

  useEffect(() => {
    if (!checkUniqueNames(selectedAlgoConfigs)) {
      setValidationMessage('Duplicate Lane Name values are not allowed');
    } else {
      let anyConditionTrue = false;

      for (const config of selectedAlgoConfigs) {
        if (isNullOrEmpty(config.name)) {
          setValidationMessage('Lane Name should not be empty or null');
          anyConditionTrue = true;
          break;
        } else if (isNullOrEmpty(config.distance)) {
          setValidationMessage('Line Distance should not be empty or null');
          anyConditionTrue = true;
          break;
        }
        else if (config.low_speed_enabled || config.high_speed_enabled) {
          if(config.low_speed_enabled && isNullOrEmpty(config.low_speed_threshold)){
            setValidationMessage('Low Speed Limit should not be empty or null');
            anyConditionTrue = true;
            break;
          }
          if(config.high_speed_enabled && isNullOrEmpty(config.high_speed_threshold)){
            setValidationMessage('High Speed Limit should not be empty or null');
            anyConditionTrue = true;
            break;
          }
        }
      }

      if (!anyConditionTrue) {
        setValidationMessage('');
      }
    }
  }, [selectedAlgoConfigs, setValidationMessage]);

  return (
    <ConfigurationForm>
      { message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
      {selectedIndex === 0  ? 
        <NoLineData>
          <NoLineText>{t('No Lines Sets been configured yet, Please add Line Set')}</NoLineText>
          <AddNewLineButton onClick={editAnalysisAccess ? AddNewLine : undefined} disabled={!editAnalysisAccess}>{t('Add Line Set')}</AddNewLineButton>
        </NoLineData>:
        <><>
          <LinesContainer>
            <AddNewLineWrapper>
              <LabelWrapper>
                <Icon icon='Line' size={20} />
                <Label>{t('Lanes')}</Label>
              </LabelWrapper>
              <IconDiv title={t('Add New Lane')} onClick={AddNewLine}>
                {editAnalysisAccess && <Icon icon='Plus' size={16} />}
              </IconDiv>
            </AddNewLineWrapper>
            {selectedIndex !== 0 &&
              <LinesListContainer>
                {(selectedAlgoConfigs as ISpeedDetectionConfigs[])?.map((line, index) => (
                  <>
                    <LineNameWrapper isSelected={selectedIndex === index + 1} onClick={() => onLineClickCallback(index, true)}>
                      <div>{index + 1}.</div>
                      <LineName>{line?.name}</LineName>
                    </LineNameWrapper>
                    {index !== selectedAlgoConfigs.length - 1 && <HorizontalDivider />}
                  </>
                ))}
              </LinesListContainer>}
          </LinesContainer>
          <LineDetailContainer>
            {editAnalysisAccess &&
              <DeleteIconDiv onClick={() => handleRemoveLine(selectedIndex - 1, selectedAlgorithmConfigsLength, true)}>
                <Icon icon='Delete' size={16} />
              </DeleteIconDiv>
            }
            <Input id='name' disabled={!editAnalysisAccess} isRequired type='text' name='name' label={t('Lane Name')} value={selectedAlgoConfigs[selectedIndex - 1]?.name} onChange={handleInputChange} maxLength={32} />
            <InputWithUnit unit={t('m')} required disabled={selectedIndex === 0 || !editAnalysisAccess} id='distance' type='text' name='distance' label={t('Line Distance')} value={selectedAlgoConfigs[selectedIndex - 1]?.distance ? selectedAlgoConfigs[selectedIndex - 1]?.distance : ''} onChange={handleDistanceChange} maxLength={16} />
            <LowHighSpeedConfigs>
              <SpeedDetectionConfig>
                <SpeedDetectionLabelWrapper>
                  <Label>{t('Low Speed Limit')}</Label>
                  <Switch name='low_speed_enabled' checked={!!selectedAlgoConfigs[selectedIndex - 1].low_speed_enabled} onChange={handleSwitchChange} disabled={!editAnalysisAccess}/>
                </SpeedDetectionLabelWrapper>
                <Slider
                  aria-label="Temperature"
                  defaultValue={selectedAlgoConfigs[selectedIndex - 1]?.low_speed_threshold || 40} // Set the default value based on your data
                  getAriaValueText={(value: number) => value.toString()}
                  value={selectedAlgoConfigs[selectedIndex - 1]?.low_speed_threshold || 0}
                  valueLabelDisplay="auto"
                  step={5}
                  min={10}
                  max={360}
                  disabled={!selectedAlgoConfigs[selectedIndex - 1]?.low_speed_enabled || !editAnalysisAccess}
                  onChange={(event, newValue) => handleLowSpeedChange(newValue as number)} />
                <LimitWrapper> 
                  <LimitText>{('0')} {t('kmhr')}</LimitText>
                  <LimitText>{('360 ')} {t('kmhr')}</LimitText>
                </LimitWrapper> 
                <InputWithUnit
                  unit={t('kmhr')}
                  disabled={!selectedAlgoConfigs[selectedIndex - 1]?.low_speed_enabled || !editAnalysisAccess}
                  id='low_speed_threshold'
                  type='text'
                  name='low_speed_threshold'
                  onKeyPress={allowDegree}
                  value={selectedAlgoConfigs[selectedIndex - 1]?.low_speed_threshold ? selectedAlgoConfigs[selectedIndex - 1]?.low_speed_threshold : ''}
                  onChange={handleInputChange}
                  maxLength={3} />
              </SpeedDetectionConfig>
              <SpeedDetectionConfig>
                <SpeedDetectionLabelWrapper>
                  <Label>{t('High Speed Limit')}</Label>
                  <Switch name='high_speed_enabled' checked={!!selectedAlgoConfigs[selectedIndex - 1].high_speed_enabled} onChange={handleSwitchChange} disabled={!editAnalysisAccess} />
                </SpeedDetectionLabelWrapper>
                <Slider
                  aria-label="Temperature"
                  defaultValue={selectedAlgoConfigs[selectedIndex - 1]?.high_speed_threshold || 60} // Set the default value based on your data
                  getAriaValueText={(value: number) => value.toString()}
                  valueLabelDisplay="auto"
                  value={selectedAlgoConfigs[selectedIndex - 1]?.high_speed_threshold || 0} // Set the value based on your data
                  step={5}
                  min={10}
                  max={360}
                  disabled={!selectedAlgoConfigs[selectedIndex - 1]?.high_speed_enabled || !editAnalysisAccess}
                  onChange={(event, newValue) => handleHighSpeedChange(newValue as number)} />
                <LimitWrapper> 
                  <LimitText>{('0')} {t('kmhr')}</LimitText>
                  <LimitText>{('360 ')} {t('kmhr')}</LimitText>
                </LimitWrapper> 
                <InputWithUnit
                  unit={t('kmhr')}
                  disabled={!selectedAlgoConfigs[selectedIndex - 1]?.high_speed_enabled || !editAnalysisAccess}
                  id='high_speed_threshold'
                  type='text'
                  name='high_speed_threshold'
                  onKeyPress={allowDegree}
                  value={selectedAlgoConfigs[selectedIndex - 1]?.high_speed_threshold ? selectedAlgoConfigs[selectedIndex - 1]?.high_speed_threshold : ''}
                  onChange={handleInputChange}
                  maxLength={3} />
              </SpeedDetectionConfig>
            </LowHighSpeedConfigs>
          </LineDetailContainer>
        </></>
      }
    </ConfigurationForm>
  );
};

export default SpeedDetection;