import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import WhatsAppNumberField from '../../../components/WhatsAppNumberField';
import Modal from 'components/Modal';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import AlertBar from 'components/AlertBar';
import { IconType } from '../../../constants';
import { IAlertMessageType, IEmailActionData } from 'interface';
import Switch from 'components/Switch';
import { ScrollBarCss } from 'CommonStyles';

const InfoModal = styled.div`
  width: 100%;
  min-height: 200px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 75vh;
  overflow-y: auto;
  ${ScrollBarCss};
  padding-right: 2px;
`;

const TemplateInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Span = styled.span<{isSmall?: boolean}>`
  font-size: ${({isSmall}) => isSmall ? '14px' : ''};
  font-weight: 400;
  color: ${({isSmall}) => isSmall ? '#ADADAD' : ''};
`; 

const SubjectWrapper = styled.div`
  padding: 10px;
  border: 1px solid ${({theme}) => theme.divider};
  border-radius: 4px;
  background-color: #eeecec;
  display: flex;
  flex-direction: column;
  min-height: 40px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  >button{
    height: 24px;
    padding: 3px 8px;
  }
`;

const WhatsappNumbersInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const EmailSettings = styled.div<{flexDirection?: string}>`
  display: flex;
  flex-direction: ${({flexDirection}) => flexDirection ? flexDirection : 'column'};
  width: 100%;
  gap: 8px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: fit-content;
  cursor: pointer;
  >:nth-child(1){
    width: 130px;
  }
`;

interface IEmailConfigurationModalProps{
  modalOpen: boolean;
  setModalOpen: (a: boolean) => void;
  subject: string;
  body: string;
  selectedAction: string;
  isReadOnly?: boolean;
  handleEmailConfigurationsCallback: (value: string, emailData: IEmailActionData) => void;
  emailActionData?: IEmailActionData
}

const emailInitialData: IEmailActionData = {
  cc: [],
  bcc: [],
  to: [],
  snapshot: false,
  movie_clip: false
};

const EmailConfigurationModal: React.FC<IEmailConfigurationModalProps> = ({setModalOpen,isReadOnly=false, modalOpen,subject, body, handleEmailConfigurationsCallback, selectedAction, emailActionData=emailInitialData}) => {
  const {t} = useTranslation(['common']);
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'});
  const [cc, setCc] = useState<boolean>((emailActionData?.cc && emailActionData?.cc?.length > 0) ?? false);
  const [bcc, setBcc] = useState<boolean>((emailActionData?.bcc && emailActionData?.bcc?.length > 0) ?? false);
  const [emailActionInfoData, setEmailActionInfoData] = useState<IEmailActionData>({...emailActionData});

  const handleCc = useCallback((cc: boolean)=>{
    setCc(!cc);
    if(cc){
      handleEmailConfigurationsCallback(selectedAction, {...emailActionData, cc: []});
    }
  },[selectedAction, handleEmailConfigurationsCallback, emailActionData]);

  const handleBcc = useCallback((bcc: boolean)=>{
    setBcc(!bcc);
    if(bcc){
      handleEmailConfigurationsCallback(selectedAction, {...emailActionData, bcc: []});
    }
  },[handleEmailConfigurationsCallback, selectedAction, emailActionData]);

  const handleEmailList = useCallback((data: string[], key: string) => {
    setEmailActionInfoData(prev => ({...prev, [key] : [...data]}));
  },[]);

  const handleAttachments = useCallback((value: boolean, key: string) => {
    setEmailActionInfoData(prev => ({...prev, [key] : value}));
  },[]);

  const handleSaveClick = useCallback(() => {
    if(emailActionInfoData.to.length > 0){
      handleEmailConfigurationsCallback(selectedAction, {...emailActionInfoData});
      setModalOpen(!modalOpen);
    }else{
      setMessage({text: t('Please enter at least one mail recipient.'), id: '', type: 'danger'});
    }
  },[emailActionInfoData, selectedAction, handleEmailConfigurationsCallback, t, modalOpen, setModalOpen]);

  return (
    <Modal closeModal={setModalOpen} isModalOpen={modalOpen} width='640px' >
      <InfoModal>
        {message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
        <WhatsappNumbersInfo>
          <Header>
            <Span isSmall>{t('Email Recipients')}</Span>
            <ButtonWrapper>
              { !isReadOnly && 
              <>
                <Button variant='secondary' size='small' onClick={() => handleCc(cc)}>{ cc ? t('Remove Cc') : t('Add Cc')}</Button>
                <Button variant='secondary' size='small' onClick={() => handleBcc(bcc)}>{ bcc ? t('Remove Bcc') : t('Add Bcc')}</Button>
                <Button variant='primary' size='small' onClick={handleSaveClick}>{t('Save')}</Button>
              </>
              }
            </ButtonWrapper>
          </Header>
          <WhatsAppNumberField readOnlyMode={isReadOnly} onlyNumbers={false} selectedNumbers={(data) => handleEmailList(data, 'to')} WhatsAppNumberList={emailActionData?.to ?? []}  onSelect={()=>{}}/>
          {cc && 
          <EmailSettings>
            <Span isSmall>{t('Cc')}</Span>
            <WhatsAppNumberField readOnlyMode={isReadOnly} onlyNumbers={false} selectedNumbers={(data) => handleEmailList(data, 'cc')} WhatsAppNumberList={emailActionData?.cc ?? []}  onSelect={()=>{}}/>
          </EmailSettings>}
          {bcc && 
          <EmailSettings>
            <Span isSmall>{t('Bcc')}</Span>
            <WhatsAppNumberField readOnlyMode={isReadOnly} onlyNumbers={false} selectedNumbers={(data) => handleEmailList(data, 'bcc')} WhatsAppNumberList={emailActionData?.bcc ?? []}  onSelect={()=>{}}/>
          </EmailSettings>}
          <EmailSettings>
            <Span isSmall>{t('Attachments')}</Span>
            <CheckboxWrapper>
              <Span>{t('Include Snapshots')}</Span>
              <Switch disabled={isReadOnly} checked={!!emailActionInfoData?.snapshot} onChange={(e) => handleAttachments(e.target.checked, 'snapshot')}/>
            </CheckboxWrapper>
            <CheckboxWrapper>
              <Span>{t('Include Videos')}</Span>
              <Switch disabled={isReadOnly} checked={!!emailActionInfoData?.movie_clip} onChange={(e) => handleAttachments(e.target.checked, 'movie_clip')}/>
            </CheckboxWrapper>
          </EmailSettings>
        </WhatsappNumbersInfo>
        <TemplateInfo>
          <Span isSmall>{t('Template Details')}</Span>
          <Span>{t('Subject')}</Span>
          <SubjectWrapper>{subject}</SubjectWrapper>
          <Span>{t('Body')}</Span>
          <SubjectWrapper>{body.split('\n').map((line, index) => <p key={index}>{line}</p>)}</SubjectWrapper>
        </TemplateInfo>
      </InfoModal>
    </Modal>
  );
};

export default EmailConfigurationModal;