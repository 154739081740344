import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import AwaitingStreamEng from '../svgs/Awaiting_Stream-ENG.svg';
import Modal from './Modal';
import { FaPlayCircle } from 'react-icons/fa';

const ThumbnailContainer = styled.div`
  width: 56px;
  height: 42px;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
  :hover{
    transform: scale(1.2);
    transition: all 0.3s ease-out;
  }
  position: relative;
`;

const ThumbnailImg = styled.img<{size?: number, width?: string, objectFit: string}>`
  width: ${({ width, size }) => width ? width : size ? `${size}px` : '100%'};
  height: ${({ size }) => size ? `${size}px` : '100%'};
  object-fit: ${({objectFit}) => objectFit};
  border-radius: 5px;
  background-color: #f1f1f1;
`;

const ThumbnailVideo = styled.video<{size?: number}>`
  width: ${({ size }) => size ? `${size}px` : '100%'};
  height: ${({ size }) => size ? `${size}px` : '100%'};
  object-fit: cover;
  border-radius: 5px;
`;

const ModalThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-bottom: -4px;
`;

const ModalVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-bottom: -4px;
`;

const IconWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 26%;
  left: 18%;
`;

interface IThumbnailProps{
  media?: 'image' | 'video' | 'unknown';
  src: string;
  size?: number;
  disableModal?: boolean;
  width?: string;
  objectFit?: 'cover' | 'contain' | 'scale-down';
}

const Thumbnail: React.FC<IThumbnailProps> = ({src, size, disableModal=false, media='image', width='56px', objectFit='cover'}) => {
  const [isModalOpen,setModalOpen] = useState<boolean>(false);
  const handleThumbnailError = useCallback((e:React.SyntheticEvent<HTMLElement>)=>{
    (e.target as HTMLSourceElement).src = AwaitingStreamEng;
  },[]);

  const handleThumbnailClick = useCallback(()=>{
    if(!disableModal){
      setModalOpen(true);
    }
  },[disableModal]);

  return (
    <>
      <ThumbnailContainer onClick={handleThumbnailClick}>
        {media === 'video' ?
          <ThumbnailVideo src={src} size={size} controls={false} />:
          <ThumbnailImg objectFit={objectFit} src={src} width={width} size={size && size} onError={handleThumbnailError} />}
        {media === 'video' && <IconWrapper><FaPlayCircle color='white' size={20} /></IconWrapper>}
      </ThumbnailContainer>
      {isModalOpen && !disableModal &&
        <Modal isModalOpen={isModalOpen} closeModal={setModalOpen} width='60vw' padding='0'>
          {media === 'video' ?
            <ModalVideo src={src} controls />:
            <ModalThumbnail src={src} onError={handleThumbnailError} />}
        </Modal>
      }
    </>
  );
};

export default Thumbnail;