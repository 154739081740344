import { formatDistance, format } from 'date-fns';
import { enUS, te, hi } from 'date-fns/locale';
import Wrong_Answer from '../assets/Wrong-Answer.mp3';
import Surprise_Sound from '../assets/Surprise-Sound.mp3';
import Interface from '../assets/Interface.mp3';
import Game_Bonus from '../assets/Game-Bonus.mp3';
import Fairy_Sparkle from '../assets/Fairy-Sparkle.mp3';
import Ding_Idea from '../assets/Ding-Idea.mp3';
import Click_Button from '../assets/Click-Button.mp3';
import Cash_Register from '../assets/Cash-Register.mp3';
import Appears from '../assets/Appears.mp3';
import { DATE_TIME_FORMAT } from '../constants';
import i18n from 'i18next';

export const getTimeSince = (date: string) => {

  const getLocale = (language: string) => {
    switch (language) {
    case 'en':
      return enUS;
    case 'te':
      return te;
    case 'hi':
      return hi;
    default:
      return enUS;
    }
  };
  
  try {
    if (date) {
      const result = formatDistance(
        new Date(date), new Date(),
        { locale: getLocale(i18n.language), addSuffix: true, includeSeconds: true }
      );
      const resultStr = (result.charAt(0).toUpperCase() + result.slice(1));
      return resultStr;
    }
  } catch (err) {
    return '-';
  }
};

export const getOnlyDate = (timeStamp: string) => {
  try{
    if(timeStamp) {
      const date = format(new Date(timeStamp), DATE_TIME_FORMAT);
      return date;
    }
  }catch(err){
    return '-';
  }
};

export const getAudioAlertName = (audioName: string) => {
  switch (audioName) {
  case 'Wrong-Answer':
    return Wrong_Answer;
  case 'Surprise-Sound':
    return Surprise_Sound;
  case 'Interface':
    return Interface;
  case 'Game-Bonus':
    return Game_Bonus;
  case 'Fairy-Sparkle':
    return Fairy_Sparkle;
  case 'Ding-Idea':
    return Ding_Idea;
  case 'Click-Button':
    return Click_Button;
  case 'Cash-Register':
    return Cash_Register;
  case 'Appears':
    return Appears;
  default:
    return Fairy_Sparkle;
  }
};

export const formatTime = (time: number) => {
  let seconds: string|number = Math.floor(time % 60);
  let  minutes: string|number = Math.floor(time / 60) % 60;
  let  hours: string|number = Math.floor(time / 3600);

  seconds = seconds < 10 ? `0${seconds}` : seconds;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  hours = hours < 10 ? `0${hours}` : hours;

  if(hours === 0 || hours === '00') {
    return `${minutes}:${seconds}`;
  }
  return `${hours}:${minutes}:${seconds}`;
};

export const convertToJST = (dateString: string) => {
  const [datePart, timePart, period] = dateString.split(' ');
  const [day, month, year] = datePart.split('/');
  const [hours, minutes, seconds] = timePart.split(':');

  let hours24 = parseInt(hours, 10);
  if (period === 'PM' && hours24 < 12) {
    hours24 += 12;
  } else if (period === 'AM' && hours24 === 12) {
    hours24 = 0;
  }

  const dateInIST = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), hours24, parseInt(minutes), parseInt(seconds));
  const JSTOffset = 3.5 * 60 * 60 * 1000;

  const dateInJST = new Date(dateInIST.getTime() + JSTOffset);
  const yearJST = dateInJST.getFullYear();
  const monthJST = String(dateInJST.getMonth() + 1).padStart(2, '0');
  const dayJST = String(dateInJST.getDate()).padStart(2, '0');
  const hoursJST = String(dateInJST.getHours()).padStart(2, '0');
  const minutesJST = String(dateInJST.getMinutes()).padStart(2, '0');
  const secondsJST = String(dateInJST.getSeconds()).padStart(2, '0');
  const resultStr = `${yearJST}-${monthJST}-${dayJST} ${hoursJST}:${minutesJST}:${secondsJST}`;
  return resultStr;
};

export const convertToIST = (dateString: string) => {
  const [datePart, timePart, period] = dateString.split(' ');
  const [day, month, year] = datePart.split('/');
  const [hours, minutes, seconds] = timePart.split(':');

  let hours24 = parseInt(hours, 10);
  if (period === 'PM' && hours24 < 12) {
    hours24 += 12;
  } else if (period === 'AM' && hours24 === 12) {
    hours24 = 0;
  }

  const dateInIST = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), hours24, parseInt(minutes), parseInt(seconds));
  const yearJST = dateInIST.getFullYear();
  const monthJST = String(dateInIST.getMonth() + 1).padStart(2, '0');
  const dayJST = String(dateInIST.getDate()).padStart(2, '0');
  const hoursJST = String(dateInIST.getHours()).padStart(2, '0');
  const minutesJST = String(dateInIST.getMinutes()).padStart(2, '0');
  const secondsJST = String(dateInIST.getSeconds()).padStart(2, '0');
  const resultStr = `${yearJST}-${monthJST}-${dayJST} ${hoursJST}:${minutesJST}:${secondsJST}`;
  return resultStr;
};

export const convertToISTWithDash = (jstDateTime: any) => {
  const date = new Date(jstDateTime);
  date.setMinutes(date.getMinutes() - (3 * 60 + 30));
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;

  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds} ${ampm}`;
};

