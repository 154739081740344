import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import Icon from './Icon';
import { useHistory } from 'react-router';

const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e8e0e0; 
  gap: 5px;
`;

const Tab = styled.div<{isActive:boolean, iconSize?: string, activeMode?: boolean, disabled?: boolean, padding?: string}>`
  color: ${({isActive, activeMode}) => activeMode === false ? isActive ? '#0097CE' : '#FFFFF' : ''};
  /* border-bottom: ${({isActive, activeMode}) => activeMode ? isActive ? '2px solid #0097CE' : '2px solid #eee' : '' }; */
  background: ${({isActive, activeMode, disabled}) => activeMode ? disabled ? '#CED0D6' : isActive ? '#435FCA': '#eee' : ''};
  color: ${({isActive, activeMode,theme, disabled}) => activeMode ? disabled ? '#938e8e' : isActive ? theme.text.primaryText : '#333' : ''};
  font-weight: ${({isActive}) => isActive ? '600' : ''};
  display: flex;
  border-radius: 5px 5px 0 0;
  font-size: 30px;
  gap: 10px;
  align-items: center;
  pointer-events: ${({disabled}) => disabled ? 'none': ''} !important;
  cursor: ${({disabled}) => disabled ? 'not-allowed': 'pointer'} !important;
  font-size: 16px;
  transition: all 0.3s ease;
  padding: ${({padding}) => padding};
`;

const TabContent = styled.div`
  margin-top: 20px;
`;

const NoTextTab = styled.div`
  width: 30px;
`;

const IconWrapper = styled.div<{isActive:boolean, iconSize?: string, activeMode?: boolean, isTopAlign?: boolean, isIconOnly?: boolean}>`
  margin-left: ${({isIconOnly}) => isIconOnly ? '20px': ''};
  > div {
    justify-content: ${({ isTopAlign }) => (isTopAlign ? 'initial' : 'center')};
    margin-right: ${({isIconOnly}) => isIconOnly ? '-20px': ''};
  }
  > div > svg {
    height: ${({ iconSize }) => iconSize ? `${iconSize}px` : '17px'};
    width: ${({ iconSize }) => iconSize ? `${iconSize}px` : '17px'};
    > path {
      stroke: ${({ isActive, activeMode }) => activeMode ? (isActive ? '#eee' : '#333') : ( isActive ? '#0097CE' : '#333' )};
    }
  }
`;

const NameWithSubtitle = styled.div<{color?: string, iconPosition: boolean, disabled: boolean}>`
  display: flex;
  flex-direction: ${({ iconPosition }) => iconPosition ? 'column' : 'initial'};
  row-gap: 3px;
  text-align: ${({ iconPosition }) => iconPosition ? 'center' : 'initial'};
  padding: 10px 20px;
  height: 100%;
  pointer-events: ${({disabled}) => disabled ? 'none': ''} !important;
  cursor: ${({disabled}) => disabled ? 'no-drop': 'pointer'} !important;
  align-items: center;
  justify-content: center;
`;

const SubTitle = styled.div<{color?: string}>`
  font-size: 12px;
  color: ${({ color }) => color ? color: '#A6A6A6'};
`;

const TabNameAndSubTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TabName = styled.div<{isActive: boolean}>`
  font-weight: 600;
  color: ${({isActive}) => isActive ? '#FFFFFF' : ''};
`;

interface ISubTitle {
  text?: string;
  color?: string;
}

export interface ITabs {
  id?: string,
  icon?: string;
  name?: string;
  iconSize?: string;
  disabled?: boolean;
  subTitle?: ISubTitle;
  content?: React.ReactNode;
  isIconOnly?: boolean
}

interface IProps {
  tabs: ITabs[];
  iconPosition?: string;
  activeMode?: string;
  padding?: string
}

const Tabs: React.FC<IProps> = ({tabs, iconPosition, activeMode, padding='0'}) => {
  const [activeTab, setActiveTab] = useState(0);
  const getTextColor:{[key: string]: string} = { primary: '#4DB5FF', success: '#4BB543', danger: '#FF6666', mono: '#404040', inverse: '#FFFFFF' };
  const { replace } = useHistory();
  const [selectedTabId, setSelectedTabId] = useState<string>(tabs[0]?.id as string);

  const onHandleTabChange = useCallback((tabIndex: number, tabId: string) => {
    const params = new URLSearchParams(window.location.search);
    params.set('selectedTab', tabId);
    replace(`${window.location.pathname}?${params.toString()}`);
    setSelectedTabId(tabId);
    setActiveTab(tabIndex);
  }, [replace]);

  const fetchHistoryParams = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    const newParam = params.get('selectedTab') as string;
    const tabIdx = tabs.findIndex((tab) => tab.id === newParam);
    if(tabIdx !== -1){
      setActiveTab(tabIdx);
    }
    setSelectedTabId(newParam);
  },[tabs]);
  
  useEffect(() => {
    fetchHistoryParams();
  },[fetchHistoryParams, selectedTabId]);

  return (
    <div>
      <TabContainer>
        {tabs?.map((tab:ITabs, index:number) => (
          <Tab
            key={index}
            isActive={activeTab === index}
            onClick={() => onHandleTabChange(index, tab?.id as string)}
            activeMode={activeMode === 'box' ? true : false}
            disabled={tab.disabled}
            padding={padding}
          >
            {iconPosition !== 'top' && (tab.icon && <IconWrapper activeMode={activeMode === 'box' ? true : false} isTopAlign isActive={activeTab === index} iconSize={tab.iconSize} isIconOnly={tab.isIconOnly}><Icon icon={tab.icon} /></IconWrapper>)}
            {!tab.isIconOnly && <NameWithSubtitle iconPosition={iconPosition === 'top' ? true : false} disabled={tab.disabled as boolean}>
              {iconPosition === 'top' && (tab.icon && <IconWrapper isIconOnly={tab.isIconOnly} activeMode={activeMode === 'box' ? true : false} isActive={activeTab === index} iconSize={tab.iconSize}><Icon icon={tab.icon} /></IconWrapper>)}
              <TabNameAndSubTextWrapper>
                <TabName isActive={activeTab === index}>{tab.name}</TabName>
                {tab?.subTitle?.text && <SubTitle color={tab?.subTitle?.color && getTextColor[tab?.subTitle?.color]}>{tab?.subTitle?.text}</SubTitle> }
              </TabNameAndSubTextWrapper>
            </NameWithSubtitle>}
            {
              tab.isIconOnly && <NoTextTab></NoTextTab>
            }
          </Tab>
        ))}
      </TabContainer>
      <TabContent>{tabs[activeTab]?.content}</TabContent>
    </div>
  );
};
export default Tabs;