import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgShowEye = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M21.945 11.757c-.027-.06-.698-1.468-2.189-2.88C17.77 6.993 15.261 6 12.5 6s-5.27.995-7.256 2.876c-1.491 1.413-2.165 2.824-2.19 2.881a.573.573 0 0 0 0 .488c.028.059.699 1.467 2.19 2.88C7.23 17.005 9.739 18 12.5 18s5.27-.994 7.256-2.876c1.491-1.412 2.162-2.82 2.19-2.88a.573.573 0 0 0 0-.487ZM12.5 16.8c-2.437 0-4.566-.84-6.328-2.494A10.078 10.078 0 0 1 4.346 12a10.065 10.065 0 0 1 1.826-2.306C7.934 8.039 10.063 7.2 12.5 7.2c2.437 0 4.566.84 6.328 2.494A10.074 10.074 0 0 1 20.658 12c-.57 1.01-3.057 4.8-8.158 4.8Zm0-8.4c-.752 0-1.486.211-2.111.607a3.65 3.65 0 0 0-1.4 1.615 3.425 3.425 0 0 0-.216 2.08c.147.699.509 1.34 1.04 1.844a3.87 3.87 0 0 0 1.946.985 3.997 3.997 0 0 0 2.195-.205A3.764 3.764 0 0 0 15.66 14c.417-.592.64-1.288.64-2a3.51 3.51 0 0 0-1.114-2.544A3.914 3.914 0 0 0 12.5 8.4Zm0 6c-.501 0-.99-.14-1.407-.405a2.432 2.432 0 0 1-.934-1.077 2.283 2.283 0 0 1-.144-1.386c.098-.466.34-.893.694-1.23a2.58 2.58 0 0 1 1.297-.656c.491-.092 1-.045 1.463.137.463.181.859.489 1.137.884.279.394.427.858.427 1.333 0 .636-.267 1.247-.742 1.697-.475.45-1.12.703-1.791.703Z"
        fill={props.color}
      />
    </svg>
  );
};
export default SvgShowEye;
