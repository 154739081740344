import React, {useEffect, useState} from 'react';
import { useHeader } from 'contexts/HeaderContext';
import { useTranslation } from 'react-i18next';
import { MdOutlineMail } from 'react-icons/md';
import EmailSetting from './EmailSetting';
import Tabs, { ITabs } from 'components/Tabs';
import styled from 'styled-components';
import { Container } from 'CommonStyles';
import SMTPSetting from './SMTPSetting';
import { GET_EMAIL_CLIENT, GET_SMTP_CLIENT } from 'api_configs/queries';
import { useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import { DEFAULT_EMAIL_CLIENT_DATA, PERMISSION_CODE, StatusCode } from '../../constants';
import Button from 'components/Button';
import TokenService from 'api_configs/tokenService';

const TabsContainer = styled.div``; 

const EmptyLabelWrapper = styled.div`
  height: 700px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 15px;
  align-items: center;
  margin-right: 20px;
  width: 100%;
`;

const EmptyLabel = styled.div`
  font-size: 22px;
`;

const Label = styled.div``;

const SpinnerBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 89vh;
  align-items: center;
  justify-content: center;
`;

const NoPermissionContainer = styled.div`
  width: 100%;
  height: 60vh;
  margin-top: 20px;
  border: 1px solid #F5F6F6;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  gap: 20px;
`;

const NoPermissionMessage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ClientConfiguration = () =>{
  const {updateHeaderTitle} = useHeader();
  const {t} = useTranslation(['common']);
  const {data: GetEmailDetailsData, loading: GetEmailDetailsLoader} = useQuery(GET_EMAIL_CLIENT, { variables: {clientId: DEFAULT_EMAIL_CLIENT_DATA.instanceId} });
  const {data: SMTPDetails, loading: GetSMTPDetailsLoader} = useQuery(GET_SMTP_CLIENT);
  const [loader, setLoader] = useState(false); 
  const [isEmailClientAvailable, setEmailClientAvailable] = useState(false);
  const [isSMTPClientAvailable, setSMTPClientAvailable] = useState(false);
  const [smtpLoader, setSMTPLoader] = useState(false); 
  const addEmailClientAccess = TokenService.hasAccess(PERMISSION_CODE.add_email_client);
  const viewEmailClientAccess = TokenService.hasAccess(PERMISSION_CODE.view_email_client);

  useEffect(()=> {
    setLoader(GetEmailDetailsLoader);
  },[GetEmailDetailsLoader]);

  useEffect(()=> {
    setSMTPLoader(GetSMTPDetailsLoader);
  },[GetSMTPDetailsLoader]);

  useEffect(() =>{
    if(GetEmailDetailsData?.getEmailClient){
      const { statusCode } = GetEmailDetailsData.getEmailClient;
      if (statusCode === StatusCode.SUCCESS) {
        setEmailClientAvailable(true);
      }
    }
  },[GetEmailDetailsData?.getEmailClient, t]);

  useEffect(() =>{
    if(SMTPDetails?.getSmtpClient?.statusCode === StatusCode.SUCCESS && SMTPDetails.getSmtpClient.result !== null){
      setSMTPClientAvailable(true);
    }
  },[SMTPDetails]);

  useEffect(()=>{
    updateHeaderTitle(t('Client Configuration'), <MdOutlineMail size={24} fill='#FFFFFF' />);
  },[updateHeaderTitle, t]);

  const clientConfigTabs: ITabs[] = [
    {
      id: 'awsSesTab',
      name: t('AWS SES'),
      content: <EmailSetting />,
    },
    {
      id: 'smtpTab',
      name: t('SMTP'),
      content: <SMTPSetting />
    }
  ];

  if (loader || smtpLoader){
    return (
      <SpinnerBox>
        <CircularProgress />
        <Label>{t('Loading...')}</Label>
      </SpinnerBox>
    );
  }
  
  return(
    <Container>
      {!viewEmailClientAccess ? 
        <NoPermissionContainer>
          <NoPermissionMessage>{t('Unfortunately, you do not have permission to view Email Client at this time. Please contact your administrator for assistance.')}</NoPermissionMessage>
        </NoPermissionContainer>
        :(!isEmailClientAvailable && !isSMTPClientAvailable) ? 
          <EmptyLabelWrapper>
            <EmptyLabel>{t('No Email Client Found')}</EmptyLabel>
            <Button variant='primary' disabled={!addEmailClientAccess} onClick={() => setEmailClientAvailable(true)}>{t('Add Email Client')}</Button>
          </EmptyLabelWrapper>
          :
          <TabsContainer>
            <Tabs tabs={clientConfigTabs} activeMode='box' />
          </TabsContainer>}
    </Container>
  );
};

export default ClientConfiguration;