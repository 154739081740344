import React, { useEffect } from 'react';
import { useHeader } from 'contexts/HeaderContext';
import styled from 'styled-components';
import ButtonWithIcon from 'components/ButtonWithIcon';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

const NoObjectsMessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  margin-top: 15%;
`;

const NewTypeButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
`;

const NoObjectsPage = () => {
  const {updateHeaderTitle} = useHeader();
  const {t} = useTranslation(['common']);
  const history = useHistory();

  useEffect(() => {
    updateHeaderTitle(t('Objects'), 'Object');
  }, [updateHeaderTitle, t]);

  return (
    <NoObjectsMessageWrapper>
      <MessageWrapper>{t('There is no object type in the application. Please create new object type to add objects.')}</MessageWrapper>
      <NewTypeButton>
        <ButtonWithIcon variant='primary' icon='Plus' onClick={() => history.push('/objects/addobjecttype')}>{t('New Object Type')}</ButtonWithIcon>
      </NewTypeButton>
    </NoObjectsMessageWrapper>
  );
};

export default NoObjectsPage;