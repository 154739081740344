import Label from 'components/Label';
import SelectField from 'components/SelectField';
import Switch from 'components/Switch';
import Title from 'components/Title';
import { HLS_IP_URL, HLS_MDNS_URL, IconType, PERMISSION_CODE, RTSP_IP_URL, RTSP_MDNS_URL, StatusCode, StreamType, VIDEO_FPS_OPTIONS, WEB_RTC_IP_URL, WEB_RTC_MDNS_URL } from '../../constants';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import MP4Recording from './CameraComponents/MP4Recording';
import PeriodicSnapshot from './CameraComponents/PeriodicSnapshot';
import Button from 'components/Button';
import { useHistory } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ADV_CONFIG, GET_DEVICE_INFO, GET_RTMP_STREAM } from 'api_configs/queries';
import { PATCH_RTMP_CAMERA } from 'api_configs/mutations';
import AlertBar from 'components/AlertBar';
import { IAlertMessageType } from 'interface';
import { CircularProgress } from '@mui/material';
import MdnsIpInput from 'components/MdnsIpInput';
import TokenService from 'api_configs/tokenService';
const viewAdvancedConfigAccess = TokenService.hasAccess(PERMISSION_CODE.view_advanced_config);
const editAdvancedConfigAccess = TokenService.hasAccess(PERMISSION_CODE.edit_advanced_config);

const AdvancedConfigWrapper = styled.div<{isEditPermission: boolean}>`
  pointer-events: ${({isEditPermission}) => isEditPermission ? '': 'none'};
`;

const VideoConfigurationWrapper = styled.div`
  display: flex;
  width: 1060px;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 768px) {
    width: 530px;
  }
`;

const Heading = styled.div`
  font-size: 20px;
  font-weight: 700;
`;

const VerticalLine = styled.div`
  width: 1300px;
  height: 10px;
  border-bottom: 1px solid lightgrey;
  margin-top: 2px;
  margin-bottom: 32px;
  @media (max-width: 768px) {
    width: 450px;
  }
`;

const NoPermissionMessage = styled.div`
  width: 1040px;
  height: 800px;
  display: flex;
  padding-top: 160px;
  justify-content: center;
`;

const MessageWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
`;

const PeriodicSnapshotWrapping = styled.div``;

const HWAccelerationWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Configurations = styled.div`
  display: flex;
  gap: 200px;
`;

const LeftSideWrapper = styled.div``;

const RightSideWrapper = styled.div``;

const SwitchWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 15px;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const MP4RecordingWrapping = styled.div``;

const ButtonsWrapper = styled.div`
  display: flex;
  width: 1060px;
  justify-content: center;
  gap: 20px;
  margin-top: 15px;
`;

const SpinnerBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 89vh;
  align-items: center;
  justify-content: center;
`;

const EndPointsWrapper = styled.div`
  width: 1060px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const VideoSourceProperties = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

interface IAdvancedConfig {
  streamType: string | undefined,
  instanceId: string | undefined
}

const AdvancedRTMP = ({streamType, instanceId}: IAdvancedConfig) => {
  const [basicInfo, setBasicInfo] = useState({name: '', notes: '', streamType: '', groupList: [], rtmpServerType: '', rtmpSourceUrl: '', rtmpStreamKey: '', rtmpStreamKeyType: '', rtmpUrlType: ''});
  const [advancedRtmp, setAdvancedRtmp] = useState({rtspPort: '', hlsEnabled: false, rtspEnabled: false, services: {recorder: {enableHwAcceleration: true, videoFps: 'auto', rtspSourceBufferTime: '5', videoFlipMethod: 'auto', webrtcPort: '', maximumPacketDelay: 'auto', maximumPacketDelayType: 'auto', rtspSourceShortHeader: false, videoWidth: '1920', h264Profile: 'auto', videoHeight: '1080', enableSaveMp4: true, videoSplitMode: 'time', enableMp4TimeOverlay: false, recordingQuality: 'low', videoFileMaxSize: '52428100', videoDuration: '600', periodicSnapshot: true, enableSnapshotTimeOverlay: false, saveInterval: '600', specifySnapshotResolution: false, periodicSnapshotResolution: 'auto', saveOriginalSnapshot: false, videoPayloadType: 96, enableVideoMulticast: true, videoPortRtcp: '1063', pixelAspectRatio: 'auto', enableVideoRtcpMuxed: false, enableAudio: false, rtpAudioSourceType: 'acc', audioPayloadType: 103, enableAudioMulticast: false, audioMulticastAddress: '', audioPortRtp: '', enableAudioRtcpMuxed: false, audioPortRtcp: ''}}});
  const [currentResponse, setCurrentResponse] = useState({rtspPort: '', hlsEnabled: false, rtspEnabled: false, services: {recorder: {enableHwAcceleration: false, videoFps: 'auto', rtspSourceBufferTime: '5', videoFlipMethod: 'auto', maximumPacketDelay: 'auto', webrtcPort: '', maximumPacketDelayType: 'auto', rtspSourceShortHeader: false, videoWidth: '1920', h264Profile: 'auto', videoHeight: '1080', enableSaveMp4: true, videoSplitMode: 'time', enableMp4TimeOverlay: false, recordingQuality: 'low', videoFileMaxSize: '52428100', videoDuration: '600', periodicSnapshot: true, enableSnapshotTimeOverlay: false, saveInterval: '600', specifySnapshotResolution: false, periodicSnapshotResolution: 'auto', saveOriginalSnapshot: false, videoPayloadType: 96, enableVideoMulticast: true, videoPortRtcp: '1063', pixelAspectRatio: 'auto', enableVideoRtcpMuxed: false, enableAudio: false, rtpAudioSourceType: 'acc', audioPayloadType: 103, enableAudioMulticast: false, audioMulticastAddress: '', audioPortRtp: '', enableAudioRtcpMuxed: false, audioPortRtcp: ''}}});
  const [patchRTMPStreamMutation] = useMutation(PATCH_RTMP_CAMERA);
  const [currentDeviceInfo, setCurrentDeviceInfo] = useState({deviceId: '', networkId: ''});
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: ''});  
  const { data: advancedConfigData, loading: getAdvancedConfigLoading, refetch } = useQuery(GET_ADV_CONFIG, { variables: {streamId: instanceId} });
  const {data: deviceInfo, loading: getDeviceInfoLoading} = useQuery(GET_DEVICE_INFO);
  const {data: getRTMP, loading: getStreamLoading} = useQuery(GET_RTMP_STREAM, { variables: {streamId: instanceId} });
  const [pageLoading, setPageLoading] = useState(false);
  const {t} = useTranslation(['common']);
  const history = useHistory();

  useEffect(() => {
    refetch();
    if(!getAdvancedConfigLoading && !getStreamLoading && !getDeviceInfoLoading) {
      if(advancedConfigData?.getAdvConfig?.result){
        setAdvancedRtmp(advancedConfigData?.getAdvConfig?.result);
        setCurrentResponse(advancedConfigData?.getAdvConfig?.result);
        setBasicInfo(getRTMP?.getStream?.result);
        setCurrentDeviceInfo(prevState => ({
          ...prevState,
          deviceId: deviceInfo?.getDeviceInfo?.result.deviceId,
          networkId: deviceInfo?.getDeviceInfo?.result?.networks[0]?.ipAddress?.split('/')[0]
        }));
      }
    } 
  },[advancedConfigData, getDeviceInfoLoading, getAdvancedConfigLoading, getRTMP, deviceInfo, getStreamLoading, refetch]);

  const handleSelectFieldChange = (value: string, id: string) => {
    setAdvancedRtmp(prevState => ({
      ...prevState,
      services: {
        ...prevState?.services,
        recorder: {
          ...prevState?.services?.recorder,
          [id]: value
        }
      }
    }));  
  };

  const handlePatchRTMPStream = async () => {
    const advancedRtmpPatchPayload = {streamId: instanceId, groupList: basicInfo.groupList, streamType: StreamType.RTMP, name: basicInfo.name, notes: basicInfo.notes, rtspEnabled: advancedRtmp.rtspEnabled, hlsEnabled: advancedRtmp.hlsEnabled, videoConfiguration: {rtmpServerType: basicInfo.rtmpServerType, rtmpSourceUrl: basicInfo.rtmpSourceUrl, rtmpStreamKey: basicInfo.rtmpStreamKey, rtmpStreamKeyType: basicInfo.rtmpStreamKeyType, rtmpUrlType: basicInfo.rtmpUrlType, enableHwAcceleration: advancedRtmp.services.recorder.enableHwAcceleration, videoFps: advancedRtmp.services.recorder.videoFps, enableSaveMp4: advancedRtmp.services.recorder.enableSaveMp4, videoSplitMode: advancedRtmp.services.recorder.videoSplitMode, videoDuration: advancedRtmp.services.recorder.videoDuration, h264Profile: advancedRtmp.services.recorder.h264Profile, periodicSnapshot: advancedRtmp.services.recorder.periodicSnapshot, enableSnapshotTimeOverlay: advancedRtmp.services.recorder.enableSnapshotTimeOverlay, saveInterval: advancedRtmp.services.recorder.saveInterval, specifySnapshotResolution: advancedRtmp.services.recorder.specifySnapshotResolution, periodicSnapshotResolution: advancedRtmp.services.recorder.periodicSnapshotResolution, saveOriginalSnapshot: advancedRtmp.services.recorder.saveOriginalSnapshot}};
    try{
      const data = await patchRTMPStreamMutation({variables: {payload: advancedRtmpPatchPayload}});
      if(data && data?.data?.patchStream?.statusCode === StatusCode.SUCCESS_200){
        setMessage({text: t('Data updated successfully'), id:'', type:'success'});
        refetch();
        setPageLoading(false);
      }else{
        setMessage({text: t('apiError'), id:'', type:'danger'});
      }
    }catch(e){
      setMessage({text: t('apiError'), id:'', type:'danger'});
      console.error(e);
    }
  };

  const handleSwitchChange = (state: boolean, id: string) => {
    setAdvancedRtmp(prevState => ({
      ...prevState,
      services: {
        ...prevState?.services,
        recorder: {
          ...prevState?.services?.recorder,
          [id]: !state
        }
      }
    }));
  };

  if(!viewAdvancedConfigAccess) {
    return <NoPermissionMessage>
      <MessageWrapper>{t('Unfortunately, you do not have permission to view advanced configurations at this time. Please contact your administrator for assistance.')}</MessageWrapper>
    </NoPermissionMessage>;
  }

  return (
    (getAdvancedConfigLoading || pageLoading) ? 
      <SpinnerBox>
        <CircularProgress />
        <Label labelText={t('Loading...')}/>
      </SpinnerBox> : 
      <AdvancedConfigWrapper isEditPermission={editAdvancedConfigAccess}>
        {
          message && <AlertBar message={message.text} type={message.type as IconType} setMessage={setMessage} />
        }
        <EndPointsWrapper>
          <Heading>{t('Endpoints')}</Heading>
          <VerticalLine />
          <MdnsIpInput defaultValue={advancedRtmp?.hlsEnabled} name={t('HLS Enabled')} mdnsValue={advancedRtmp?.hlsEnabled === false ? '' : HLS_MDNS_URL.replace('{deviceId}', currentDeviceInfo.deviceId).replace('{instanceId}', instanceId as string)} ipValue={advancedRtmp?.hlsEnabled === false ? '' : HLS_IP_URL.replace('{networkId}', currentDeviceInfo.networkId).replace('{instanceId}', instanceId as string)} onEnabled={() => {setAdvancedRtmp(prevState => ({...prevState, hlsEnabled: !advancedRtmp?.hlsEnabled}));}} />
          <MdnsIpInput defaultValue={true} name={t('WebRTC Enabled')} mdnsValue={WEB_RTC_MDNS_URL.replace('{deviceId}', currentDeviceInfo.deviceId).replace('{webRTCPort}', advancedRtmp?.services?.recorder?.webrtcPort)} ipValue={WEB_RTC_IP_URL.replace('{networkId}', currentDeviceInfo.networkId).replace('{webRTCPort}', advancedRtmp?.services?.recorder?.webrtcPort)} />
          <MdnsIpInput defaultValue={advancedRtmp?.rtspEnabled} name={t('RTSP Enabled')} mdnsValue={advancedConfigData?.getAdvConfig?.result?.rtspPort === '' ? t('Please save endpoints to generate URL') : advancedRtmp?.rtspEnabled === false ? '' : RTSP_MDNS_URL.replace('{deviceId}', currentDeviceInfo.deviceId).replace('{rtspPort}', advancedRtmp?.rtspPort).replace('{instanceId}', instanceId as string)} ipValue={advancedConfigData?.getAdvConfig?.result?.rtspPort === '' ? t('Please save endpoints to generate URL') : advancedRtmp?.rtspEnabled === false ? '' : RTSP_IP_URL.replace('{networkId}', currentDeviceInfo.networkId).replace('{rtspPort}', advancedRtmp.rtspPort).replace('{instanceId}', instanceId as string)} onEnabled={() => {setAdvancedRtmp(prevState => ({...prevState, rtspEnabled: !advancedRtmp?.rtspEnabled}));}} />
        </EndPointsWrapper>
        <VideoConfigurationWrapper>
          <Heading>{t('Video Configurations')}</Heading>
          <VerticalLine />
          <Configurations>
            <LeftSideWrapper>
              <HWAccelerationWrapper>
                <Title size='18px' text={t('HW Acceleration')} />
                <SwitchWrapper>
                  <Switch checked={advancedRtmp?.services?.recorder?.enableHwAcceleration} onChange={() => handleSwitchChange(advancedRtmp?.services?.recorder?.enableHwAcceleration, 'enableHwAcceleration')} />
                  <Label labelText={advancedRtmp?.services?.recorder?.enableHwAcceleration ? t('Enabled'): t('Disabled')} />
                </SwitchWrapper>  
              </HWAccelerationWrapper>
              <SectionWrapper>
                <Title size='18px' text={t('RTMP')} />
                <VideoSourceProperties>
                  <SelectField id='videoFps' label={t('FrameRate of Analysis')} options={VIDEO_FPS_OPTIONS} defaultValue={advancedRtmp?.services?.recorder?.videoFps} onChange={(value) => handleSelectFieldChange(value, 'videoFps')} />
                </VideoSourceProperties>
              </SectionWrapper>
            </LeftSideWrapper> 
            <RightSideWrapper>
              <MP4RecordingWrapping>
                <MP4Recording streamType={streamType} recordingFields={advancedRtmp} setRecordingFields={setAdvancedRtmp} />
              </MP4RecordingWrapping>
              <PeriodicSnapshotWrapping>
                <PeriodicSnapshot streamType={streamType} snapshotFields={advancedRtmp} setSnapshotFields={setAdvancedRtmp} />
              </PeriodicSnapshotWrapping>
            </RightSideWrapper>
          </Configurations>
          <ButtonsWrapper>
            <Button variant='primary' disabled={(JSON.stringify(advancedRtmp) === JSON.stringify(currentResponse))} onClick={handlePatchRTMPStream}>{t('Save')}</Button>
            <Button variant='secondary' onClick={() => history.push('/')}>{t('Cancel')}</Button>
          </ButtonsWrapper>
        </VideoConfigurationWrapper>
      </AdvancedConfigWrapper>
  );
};

export default AdvancedRTMP;