import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgDelete = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="m6.176 9.26.72 10.806a1 1 0 0 0 .998.934h8.303a1 1 0 0 0 .998-.934l.72-10.805M5 9.26h14.087M5 9.26V6.849a1.5 1.5 0 0 1 1.5-1.5h11.087a1.5 1.5 0 0 1 1.5 1.5V9.26M9.308 5.348V4.5a1.5 1.5 0 0 1 1.5-1.5h2.478a1.5 1.5 0 0 1 1.5 1.5v.848M10.438 11.176v7.126M13.557 11.176v7.126"
        stroke={props.color}
        strokeWidth={props.weight}
        strokeLinecap="round"
        vectorEffect={"non-scaling-stroke"}
      />
    </svg>
  );
};
export default SvgDelete;
