// import { IBreadcrum, ICameraInfo } from 'interface';
import React, { createContext, useContext, ReactNode, useState, useCallback, useEffect } from 'react';

type AnnotaionsContextType = {
  annotaionEnabled: boolean;
  isOfflineMap: boolean;
  updateAnnotationEnabled: (annotaionEnabled: boolean) => void;
  updateMapTile: (isOfflineMap: boolean) => void;
};

const AnnotationContext = createContext<AnnotaionsContextType | undefined>(undefined);

type AnnotationsProviderProps = {
  children: ReactNode;
};

const getInitialAnnotationEnabled = (): boolean => {
  const storedValue = localStorage.getItem('annotationEnabled');
  return storedValue !== null ? JSON.parse(storedValue) : true;
};

const getInitialIsOfflineMap = (): boolean => {
  const storedValue = localStorage.getItem('isOfflineMap');
  return storedValue !== null ? JSON.parse(storedValue) : false;
};

export const AnnotationsProvider = ({ children }: AnnotationsProviderProps) => {
  const [annotaionEnabled, setAnnotationsEnabled] = useState<boolean>(getInitialAnnotationEnabled);
  const [isOfflineMap, setIsOfflineMap] = useState<boolean>(getInitialIsOfflineMap);

  useEffect(() => {
    localStorage.setItem('annotationEnabled', JSON.stringify(annotaionEnabled));
  }, [annotaionEnabled]);

  useEffect(() => {
    localStorage.setItem('isOfflineMap', JSON.stringify(isOfflineMap));
  }, [isOfflineMap]);
 
  const updateMapTile = useCallback((isOfflineMap: boolean) => {
    setIsOfflineMap(isOfflineMap);
  },[]);

  const updateAnnotationEnabled = useCallback((annotationsEnabled: boolean) => {
    setAnnotationsEnabled(annotationsEnabled);
  },[]);

  return (
    <AnnotationContext.Provider value={{ annotaionEnabled, updateAnnotationEnabled, isOfflineMap, updateMapTile}}>
      {children}
    </AnnotationContext.Provider>
  );
};

export const useAnnotation = () => {
  const context = useContext(AnnotationContext);
  if (context === undefined) {
    throw new Error('useHeader must be used within a AnnotationsProvider');
  }
  return context;
};
