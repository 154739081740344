import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgHideEye = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <g clipPath="url(#a)" fill={props.color}>
        <path d="M15.998 13.334a3.811 3.811 0 0 0-3.572-5.144c-.45 0-.895.08-1.317.238l.895.923A2.706 2.706 0 0 1 15.126 12c0 .137-.012.275-.033.41l.905.923Z" />
        <path d="M21.052 11.74c-1.873-3.462-5.156-5.556-8.79-5.556a9.344 9.344 0 0 0-2.91.472l.894.9a8.211 8.211 0 0 1 2.017-.261c3.127 0 5.972 1.744 7.666 4.683a9.86 9.86 0 0 1-2.427 2.833l.789.79a11.072 11.072 0 0 0 2.777-3.334l.145-.267-.161-.26ZM4.704 5.21 7.18 7.689a10.844 10.844 0 0 0-3.716 4.05L3.32 12l.145.267c1.872 3.461 5.155 5.556 8.789 5.556a9.406 9.406 0 0 0 4.094-.94l2.778 2.779.972-.834L5.654 4.383l-.95.828Zm5.416 5.417 3.695 3.695a2.672 2.672 0 0 1-1.39.4 2.706 2.706 0 0 1-2.705-2.723c.007-.485.145-.96.4-1.372Zm-.805-.805a3.806 3.806 0 0 0 5.305 5.305l.89.89a8.282 8.282 0 0 1-3.256.666c-3.128 0-5.973-1.745-7.667-4.684a9.605 9.605 0 0 1 3.4-3.5l1.328 1.323Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="translate(2 2)" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgHideEye;
