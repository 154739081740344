import React, { useState, useEffect, useRef, useCallback, RefObject, useContext } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHeader } from 'contexts/HeaderContext';
import 'leaflet/dist/leaflet.css';
import Icon from 'components/Icon';
import { PiCardsFill } from 'react-icons/pi';
import { AiOutlineBarChart } from 'react-icons/ai';
import { MdGridOff, MdGridOn, MdOutlineHideImage } from 'react-icons/md';
import SearchInputFilter, { ISearchQueryKeys } from 'components/SearchInputFilter';
import { GET_ALL_ALGORITHMS, GET_ALL_GROUPS, GET_ALL_STREAMS, GET_ALL_STREAMS_CLOUD, GET_DASHBOARD_CHARTS, GET_DASHBOARD_INFO } from 'api_configs/queries';
import { useMutation, useQuery } from '@apollo/client';
import { IAlertMessageType, IGetAllAlgorithmRes, IStream } from 'interface';
import { useHistory, useLocation } from 'react-router';
import { ScrollBarCss } from 'CommonStyles';
import NewCard from 'components/Card';
import CreateEditGroupModal from 'components/modals/CreateEditGroupModal';
import AlertBar from 'components/AlertBar';
import { ADVANCE_FILTER_KEYNAMES, ALERT_STATUS, ALGORITHM_CODES, IconType, PERMISSION_CODE, SUPERSET_REFRESH_INTERVAL, SUPERSET_URL, StatusCode } from '../constants';
import { useClickOutside } from 'hooks/useClickOutside';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import { DELETE_GROUP, SUPERSET_DASHBOARD_PAGE_MUTATION } from 'api_configs/mutations';
import CameraList from './CameraList';
import { useAllStreamStatus } from 'hooks/useAllStreamStatus';
import { CircularProgress, Slider } from '@mui/material';
import Config from '../configs/config.json';
import TokenService from 'api_configs/tokenService';
import Modal from 'components/Modal';
import Map from 'components/mapview/Map';
import Alerts from 'components/Alerts';
import { useStreamAlertStatus } from 'hooks/useStreamAlertStatus';
import ZoneMap from 'components/mapview/ZoneMap';
import Toggle from 'components/Toggle';
import { FaMapMarkerAlt } from 'react-icons/fa';
import Skeleton from 'components/skeletons/Skeleton';
import { DetectionProvider } from 'contexts/DetectionContext';
import { AlertsContext } from 'contexts/AlertsContext';
import { useIdentityID } from 'hooks/useIdentityID';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 5px 20px 5px 20px;
  background-color: ${({ theme }) => theme.backgroundColor};
  gap: 15px;
`;

const VisualizationContainer = styled.div<{ isVisible: boolean }>`
  width: ${({ isVisible }) => (isVisible ? '100%' : '0')};
  height: 300px;
  max-height: ${({ isVisible }) => (isVisible ? '300px' : '0')};
  border: 1px solid ${({ theme }) => theme.divider};
  border-radius: 10px;
  font-size: 18px;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  overflow: hidden;
  transition: all 0.6s ease-in-out;
`;

const CamerasMainContainer = styled.div`
  display: flex;
  gap: 20px;
  position: sticky;
  top: 50px;
  height: calc(100vh - 60px);
`;

const CamerasLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
`;

const CamerasRightContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const FeedStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 26px;
`;

const NewCameraButton = styled.div<{ disabled: boolean }>`
  height: 40px;
  background: ${({ disabled }) => disabled ? '#8E9FDF' : 'linear-gradient(to top, #1538BD, #7287D7)'} ;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'} ;
`;

const NewCameraText = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.text.lightText};
`;

const Label = styled.div<{ isGroupName?: boolean, isSelected?: boolean }>`
  font-size: 14px;
  cursor: pointer;
  ${({ isGroupName }) => isGroupName && css`
    padding: 4px 0px 4px 10px;
    width: fill-available;
    max-width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 4px;
  `}
  color: ${({ isSelected }) => isSelected ? '#FFFFFF' : ''};
`;

const GroupsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const AddNewGroupContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LabelContainer = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

const VisualizationToggleWrapper = styled.span<{isDefaultPage: boolean}>`
  position: absolute;
  top: 14px;
  z-index: 9;
  right: ${({isDefaultPage}) => !isDefaultPage ? '116px' : '172px'};
  cursor: pointer;
`;

const AllGroupsContainer = styled.div`
  border: 2px solid ${({ theme }) => theme.divider};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  ${ScrollBarCss}
`;

const GroupLabelContainer = styled.div<{ isSelected: boolean }>`
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ isSelected }) => isSelected ? '#7287D7' : '#F5F8FF'} ;
  cursor: pointer;
`;

const AllGroupsWrapper = styled.div`
  max-height: 200px;
  overflow-y: auto;
  ${ScrollBarCss}
`;

const CameraActivityStatusWrapper = styled.div<{ isSelected: boolean }>`
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  cursor: pointer;
  background-color: ${({ isSelected }) => isSelected ? '#7287D7' : '#F5F8FF'};
  >:nth-child(2){
    width: 88px;
    flex: 6;
    margin-bottom: 2px;
    color: ${({ isSelected }) => isSelected ? '#FFF' : '#000'};
  }
  >:nth-child(3){
    flex: 1;
    margin-bottom: 2px;
    color: ${({ isSelected }) => isSelected ? '#FFF' : '#000'};
  }
`;

const CameraStatusIndicator = styled.div<{ status: string }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${({ status }) => status === 'allCameras' ? '#1538BD' : status === 'active' ? '#00FF94' : '#FF0000'};
`;

const HorizontalDivider = styled.div<{ hasMargin?: boolean }>`
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.divider};
  margin: ${({ hasMargin }) => hasMargin ? '6px 0' : '0'};
`;

const ListGridToggle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid ${({ theme }) => theme.divider};
  border-radius: 8px;
  height: 40px;
  >:first-child{
    border-right: 2px solid ${({ theme }) => theme.divider};
    border-radius: 6px 0 0 6px;
  }
  >:nth-child(2){
    border-radius: 0 6px 6px 0;
  }
`;

const MapToggle = styled.div`
  width: 40px;
  height: 40px;
  border: 2px solid ${({ theme }) => theme.divider};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
`;

const ToggleWrapper = styled.div<{ isSelected: boolean }>`
  height: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  background-color: ${({ isSelected }) => isSelected ? '#7287D7' : '#fffff'};
  color: ${({ isSelected }) => !isSelected ? '' : '#FFF'};
  cursor: pointer;
`;

const DensityHandler = styled.div``;

const SearchContainer = styled.div`
  display: flex;
  gap: 15px;
  padding-left: 10px;
`;

const NoDataContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  min-height: 400px;
  width: 99%;
  border: 1px solid ${({ theme }) => theme.divider};
  margin-left: 10px;
  border-radius: 6px;
  font-size: 18px;
`;

const NotFoundTitle = styled.label`
  font-size: 16px;
  font-weight: 600;
`;

const NotFoundDescription = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.text.secondaryText};
  max-width: 500px;
  text-align: center;
`;

const AllCameraCardsContainer = styled.div<{columns?: number}>`
  max-height: calc(100vh - 140px);
  overflow-y: auto;
  padding: 10px;
  flex: 1;
  align-content: flex-start;
  scrollbar-gutter: stable;
  ${ScrollBarCss}
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  gap: 20px;
  width: ${(props) => (props.columns === 2 ? '50%' : '100%')};
  transition: all 0.3s;
`;

const IconDiv = styled.div<{ isSelected?: boolean }>`
  ${({ isSelected }) => isSelected && css`
    >div>svg>path{
      stroke: #FFFFFF;
    }
  `}
  cursor: pointer;
`;

const GroupMoreOptions = styled.div`
  position: absolute;
  right: -75px; 
  background-color: ${({ theme }) => theme.backgroundColor};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  z-index: 9;
  overflow: hidden;
`;

const ModalMapViewContainer = styled.div`
  border-radius: 6px;
  display: flex ;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  margin-top: 10px;
  height: calc(100vh - 130px);
  width: 1180px;
  gap: 10px;
  >div > div :nth-child(4) > div {
    background-color: transparent;
    border: none;
  } 
  @media only screen and (min-width: 1366px){
    width: 1182px;
  }
  @media only screen and (min-width: 1440px){
    width: 1250px;
  }
  @media only screen and (min-width: 1600px){
    width: 1390px;
  }
  @media only screen and (min-width: 1680px){
    width: 1470px;
  }
  @media only screen and (min-width: 1920px){
    width: 1685px;
  }
  @media only screen and (min-width: 2560px){
    width: 2260px;
  }
  @media only screen and (min-width: 3840px){
    width: 3416px;
  }
`;

const GroupOption = styled.div`
  cursor: pointer;
  padding: 5px;
  font-size: 14px;
  :hover{
    background-color: ${({ theme }) => theme.sidemenu.itemIconBackground};
  }
`;

const CameraPanelsContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  min-height: calc(100vh - 120px);
`;

const ActionIcon = styled.div<{ isZoneMap: boolean }>`
  position: absolute;
  cursor: pointer;
  width: 36px;
  height: 36px;
  padding: 6px;
  background-color: ${({ theme }) => theme.backgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 2px solid ${({ theme }) => theme.divider};
  top: 10px;
  right: ${({ isZoneMap }) => isZoneMap ? '10px' : '48px'} ;
  z-index: 1000;
  :hover{
    background-color: #F5F6FA;
  }
`;

const MapViewContainer = styled.div`
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  margin-top: 10px;
  height: calc(100vh - 160px);
  width: 350px;
  >div > div :nth-child(4) > div {
    background-color: transparent;
    border: none;
  } 
  width: 50%;
  @media only screen and (min-width: 1366px){
    width: 40%;
  }
  @media only screen and (min-width: 1920px){
    width: 50%;
  }
`;

const CameraActivityStatusContainer = styled.div`
  border: 2px solid ${({ theme }) => theme.divider};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const CardSkeletonFooter = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const SkeletonIcons = styled.div`
  display: flex;
  gap: 10px;
`;

const ChartsWrapper = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
  display: flex;
  gap: 18px;
  overflow: auto;
`;

const SingleChartWrapper = styled.div`
  > iframe {
    border-radius: 10px;
  }
`;

const ChartTitle = styled.div`
  margin-bottom: 8px;
`;

const EmbeddedChart = styled.iframe<{ isDailyAnalysis?: boolean }>`
  border: 0.8px solid lightgrey;
  @media only screen and (min-width: 1270px){
    width: ${({ isDailyAnalysis }) => isDailyAnalysis ? '700px' : '350px'};
  }
  @media only screen and (min-width: 1350px){
    width: ${({ isDailyAnalysis }) => isDailyAnalysis ? '800px' : '350px'};
  }
  @media only screen and (min-width: 1395px){
    width: ${({ isDailyAnalysis }) => isDailyAnalysis ? '800px' : '375px'};
  }
  @media only screen and (min-width: 1590px){
    width: ${({ isDailyAnalysis }) => isDailyAnalysis ? '880px' : '490px'};
  }
  @media only screen and (min-width: 1670px){
    width: ${({ isDailyAnalysis }) => isDailyAnalysis ? '940px' : '510px'};
  }
  @media only screen and (min-width: 1900px){
    width: ${({ isDailyAnalysis }) => isDailyAnalysis ? '1050px' : '635px'};
  }
  @media only screen and (min-width: 2100px){
    width: ${({ isDailyAnalysis }) => isDailyAnalysis ? '1300px' : '600px'};
  }
`;

const SpinnerBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  margin: auto;
  margin-top: 100px;
  height: 89vh;
`;

const AllChartOptionsContainer = styled.div`
  position: relative;
`;

const AllChartOptionsWrapper = styled.div<{ visualizationIconToggle: boolean }>`
  display: ${({ visualizationIconToggle }) => visualizationIconToggle ? 'flex' : 'none'};
  flex-direction: column;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  position: absolute;
  background-color: ${({ theme }) => theme.backgroundColor};
  border-radius: 5px;
  height: fit-content;
  border-radius: 10px;
  right: 0;
  top: 0;
  margin-top: 11px;
  z-index: 9;
`;

const Arrow = styled.div`
  width: 15px;
  height: 15px;
  background-color: ${({ theme }) => theme.backgroundColor};
  position: absolute;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  right: 8px;
  top: -8px;
  transform: rotate(45deg);
  border-top: 1px solid #DCE2E4;
  border-left: 1px solid #DCE2E4;
`;

const ChartOptionsWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 49px;
  cursor: pointer;
  background-color: #FFFFFF;
  border: 1px solid #DCE2E4;
  :hover{
    background-color: #EEFCFF;
  }
  padding: 10px;
  height: fit-content;
  z-index: 9;
`;

const RefreshLabel = styled.div`
  font-size: 16px;
`;

const SelectOptionField = styled.select`
  border-radius: 8px;
  height: 35px;
  margin: auto;
  border: solid 1px #BFCDD2;
  padding: 0 6px 0 0px;
`;

const PaginationWrapper = styled.div`
 display: flex;
 gap: 8px;
 height: 49px;
 align-items: center;
 padding-right: 22px
  > nav {
    margin: 0 auto;
  }
`;

const ChartOption = styled.div`
  min-width: 140px;
`;

const VisualizationWrapper = styled.div`
  z-index: 9;
`;

const VisualizationIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const NoChartsMessage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ToggleDiv = styled.div`
  display: flex;
  height: 40px;
`;

interface EditGroup {
  id: number | null;
  name: string;
  description: string;
}

interface IGroups {
  id: number,
  name: string,
  description?: string,
  streams: IStream[]
}

interface IModifiedStreamTypeForSearch {
  name: string,
  streamType: string,
  location?: string,
  analysis?: string[],
  hasActiveAlert?: boolean
}

interface IChartsInfo {
  datasource: string,
  description: string | null,
  permalink: string,
  linkStatus: string,
  sliceId: number,
  sliceName: string,
  url: string,
  vizType: string
}

interface IDashboardInfo {
  id: number,
  title: string,
  url: string,
  chartsInfo: IChartsInfo[]
}

interface IDashboard {
  data: IDashboardInfo[]
  id?: number
  chartsInfo?: IChartsInfo[]
}

interface IAppliedFilter {
  displayName: string | string[];
  query: string | string[] | boolean;
  keyName: string | string[];
}

const CameraManagement = () => {
  const { data: getAllStreamsResponse, refetch: getAllStreamsRefetch, loading } = useQuery(GET_ALL_STREAMS);
  const { data: getAllGroupsResponse, refetch } = useQuery(GET_ALL_GROUPS);
  const [groups, setGroups] = useState<IGroups[]>([]);
  const [allStreams, setAllStreams] = useState<IStream[]>([]);
  const { push, replace } = useHistory();
  const { t } = useTranslation(['common']);
  const { updateHeaderTitle } = useHeader();
  const [selectedView, setSelectedView] = useState<string>('grid');
  const [editGroupDetails, setEditGroupDetails] = useState<EditGroup>({ id: 0, name: '', description: '' });
  const [message, setMessage] = useState<IAlertMessageType>({ text: '', id: '', type: 'neutral' });
  const [isGroupEdit, setGroupEdit] = useState(false);
  const [visualizationIconToggle, setVisualizationIconToggle] = useState(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const moreOptionsRef = useRef<HTMLDivElement>(null);
  const visualizationIconRef = useRef<HTMLInputElement>(null);
  const [dashboardCharts, setDashboardCharts] = useState<IDashboard | null>();
  const [grpMoreOptionsOpen, setGrpMoreOptionsOpen] = useState<boolean>(false);
  const [grpIndex, setGrpIndex] = useState<number | null>(null);
  const [updateChartsData, setUpdateChartsData] = useState<IDashboard[]>([]);
  const [deleteGroupModal, setDeleteGroupModal] = useState<boolean>(false);
  const [mapViewEnabled, setMapViewEnabled] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<number | null>(null);
  const [isNewCharts, setIsNewCharts] = useState(false);
  const [filteredStreams, setFilteredStreams] = useState<IStream[]>([]);
  const { activeStreamsCount, inActiveStreamsCount, activeStreams, inActiveStreams } = useAllStreamStatus({ allStreams });
  const [highLightStreamId, setHighlightStreamId] = useState<string>('');
  const [isVisible, setIsVisible] = useState(JSON.parse(localStorage.getItem('isShowCharts') as string).cameraspage === true || false);
  const [supersetDataRefetchInterval, setSupersetDataRefetchInterval] = useState(localStorage.getItem('camerasChartsRefresh') as string || '15s');
  const [deleteGroupMutation] = useMutation(DELETE_GROUP, { refetchQueries: [{ query: GET_ALL_GROUPS }], });
  const { data: allDashboards, loading: allDashboardsLoading } = useQuery(GET_DASHBOARD_INFO, {skip: !isVisible});
  const { data: getStoreChartsInfo, loading: dashboardChartsLoading, refetch: refetchCamerasCharts } = useQuery(GET_DASHBOARD_CHARTS, { variables: { pageName: 'cameraspage', username: JSON.parse(localStorage.getItem('user_login_info') as string).username }, skip: !isVisible });
  const [algorithmList, setAlgorithmList] = useState<IGetAllAlgorithmRes[]>([]);
  const { data: allAlgorithms } = useQuery(GET_ALL_ALGORITHMS);
  const [storeChartsInfo] = useMutation(SUPERSET_DASHBOARD_PAGE_MUTATION);
  useClickOutside(moreOptionsRef, () => { setGrpMoreOptionsOpen(false); });
  const [locationsLoading, setLocationsLoading] = useState<boolean>(true);
  const [currentDensity, setCurrentDensity] = useState<number>(2);
  const [cameraActivityFilter, setCameraActivityFilter] = useState<string>('');
  const { getAlertStatusForAllStreams, refetchForAllStreamsLoading } = useStreamAlertStatus({fetchApi: Config.enableAlertStatusFilterOnCamerasPage});
  const viewChartsAccess = TokenService.hasAccess(PERMISSION_CODE.view_charts);
  const manageChartsAccess = TokenService.hasAccess(PERMISSION_CODE.manage_charts);
  const storeChartsAccess = TokenService.hasAccess(PERMISSION_CODE.store_charts);
  const addGroupAccess = TokenService.hasAccess(PERMISSION_CODE.add_group);
  const deleteGroupAccess = TokenService.hasAccess(PERMISSION_CODE.delete_group);
  const addCameraAccess = TokenService.hasAccess(PERMISSION_CODE.add_camera);
  const params = useLocation().search;
  const [historyParams] = useState(params);
  const [isMapModalOpen, setIsMapModalOpen] = useState<boolean>(false);
  const [appliedFiltersLength, setAppliedFiltersLength] = useState<number>(0);
  const viewGroupListAccess = TokenService.hasAccess(PERMISSION_CODE.group_list);
  const [selectedTab, setSelectedTab] = useState(0);
  const { alertRefreshInterval } = useContext(AlertsContext);
  const {userId} = useIdentityID();
  const {data: getStreamsData} = useQuery(GET_ALL_STREAMS_CLOUD, { variables: {cognitoID: userId} });

  const cloudFetchStream = useCallback(async() => {
   
    console.log(getStreamsData , 'cloudFetchStream');

  },[getStreamsData]);

  useEffect(() => {
    cloudFetchStream();
  },[cloudFetchStream]);

  useEffect(() => {
    if (cameraActivityFilter.length === 0 && activeTab === null && appliedFiltersLength === 0) {
      setFilteredStreams(allStreams);
    }
  }, [allStreams, activeTab, cameraActivityFilter, appliedFiltersLength]);

  const setParams = useCallback(() => {
    const params = '?currentView=' + selectedView + '&mapViewEnabled=' + mapViewEnabled + '&alertMapToggle=' + selectedTab + '&density=' + currentDensity;
    return params;
  }, [selectedView, mapViewEnabled, currentDensity, selectedTab]);

  useEffect(() => {
    replace(window.location.pathname + setParams());
  }, [replace, setParams]);

  const fetchHistoryParams = useCallback(() => {
    const params = new URLSearchParams(historyParams);

    const currentView = params.get('currentView');
    const mapViewEnabled = params.get('mapViewEnabled');
    const density = params.get('density');
    const alertMapToggle = params.get('alertMapToggle');

    setSelectedView(currentView === 'grid' || currentView === 'list' ? currentView : 'grid');
    setCurrentDensity((density === '2' || density === '3' || density === '4') ? parseInt(density) : 2);
    setMapViewEnabled(mapViewEnabled === 'false' ? false : true);
    setSelectedTab((alertMapToggle === '0' || alertMapToggle === '1') ? parseInt(alertMapToggle) : 1);

  }, [historyParams]);

  useEffect(() => {
    fetchHistoryParams();
  }, [fetchHistoryParams]);

  useEffect(() => {
    if(isVisible) {
      if (JSON.parse(localStorage.getItem('cameraspage') as string)) {
        refetchCamerasCharts();
        localStorage.removeItem('cameraspage');
      }
    }
  }, [refetchCamerasCharts, isNewCharts, isVisible]);

  useEffect(() => {
    setGroups(getAllGroupsResponse?.getAllGroup?.result === null ? [] : getAllGroupsResponse?.getAllGroup?.result);
  }, [getAllGroupsResponse]);

  const getAllStreamss = useCallback(async () => {
    setLocationsLoading(true);
    const allStreamDataResponse = await getAllStreamsResponse;
    if (allStreamDataResponse && allStreamDataResponse.getAllStreams.statusCode === StatusCode.SUCCESS && allStreamDataResponse.getAllStreams.result !== null) {
      const allStreams: IStream[] = allStreamDataResponse.getAllStreams.result;
      setAllStreams(allStreams);
    }else if(allStreamDataResponse && allStreamDataResponse.getAllStreams.statusCode === StatusCode.NO_RESULT){
      setAllStreams([]);
      setFilteredStreams([]);
    }
    setLocationsLoading(false);
    await getAllStreamsRefetch();
    refetch();
  }, [getAllStreamsRefetch, refetch, getAllStreamsResponse]);

  const fetchAlertStatusOfStreams = useCallback(async () => {
    const allStreamsAlertStatus: { hasActiveAlert: boolean, streamId: string }[] = await getAlertStatusForAllStreams();
    setAllStreams(prev => {
      let tempStreams = [...prev];
      tempStreams = tempStreams.map(stream => {
        const streamAlertStatus = allStreamsAlertStatus?.find(item => item.streamId === stream.instanceId)?.hasActiveAlert;
        return { ...stream, alertStatus: { ...stream.alertStatus, hasActiveAlert: streamAlertStatus ?? false } };
      });
      return tempStreams;
    });
  }, [getAlertStatusForAllStreams]);

  useEffect(() => {
    if(Config.enableAlertStatusFilterOnCamerasPage) {
      fetchAlertStatusOfStreams();
      let fetchInterval: any;
      if(alertRefreshInterval !== 'none'){
        fetchInterval = setInterval(() => {
          fetchAlertStatusOfStreams();
        }, parseInt(alertRefreshInterval)*1000);
      }
      return () => clearInterval(fetchInterval);
    }
  }, [fetchAlertStatusOfStreams, alertRefreshInterval]);

  useEffect(() => {
    getAllStreamss();
  }, [getAllStreamss]);

  useEffect(() => {
    const prevData = JSON.parse(localStorage.getItem('isShowCharts') as string);
    localStorage.setItem('isShowCharts', (JSON.stringify({...prevData, cameraspage: isVisible})));
  }, [isVisible]);

  useEffect(() => {
    const allAlgorithmsData: IGetAllAlgorithmRes[] = allAlgorithms?.getAllAlgorithm?.result !== null ? allAlgorithms?.getAllAlgorithm?.result : [];
    if (allAlgorithmsData?.length > 0) {
      const configuredAlgos = allAlgorithmsData?.filter((algorithm) => {
        return Config.algorithmConfigurations.configuredAlgos.indexOf(algorithm.code) !== -1;
      });
      setAlgorithmList(configuredAlgos);
    }
  }, [allAlgorithms]);

  useEffect(() => {
    updateHeaderTitle(t('Cameras'), 'Camera');
  }, [updateHeaderTitle, t]);

  useEffect(() => {
    if (isNewCharts) {
      setTimeout(() => {
        setIsNewCharts(false);
      }, 2000);
    }
  }, [isNewCharts]);

  useEffect(() => {
    if(isVisible) {
      const interval = setInterval(() => {
        refetchCamerasCharts();
      }, (Number(Number(supersetDataRefetchInterval.substring(0, supersetDataRefetchInterval.indexOf('s')))) * 1000));
  
      return () => {
        clearInterval(interval);
      };
    }
  }, [isVisible, refetchCamerasCharts, supersetDataRefetchInterval]);

  useEffect(() => {
    if (allDashboards) {
      if (!allDashboardsLoading) {
        setUpdateChartsData(allDashboards?.getDashboardsInfo?.result);
      }
    }
  }, [allDashboards, allDashboardsLoading]);

  useEffect(() => {
    if (getStoreChartsInfo) {
      if (!dashboardChartsLoading) {
        setDashboardCharts(getStoreChartsInfo?.getStoredChartsInfo?.result);
      }
    }
  }, [getStoreChartsInfo, dashboardChartsLoading]);

  useEffect(() => {
    if (getStoreChartsInfo && !dashboardChartsLoading && getStoreChartsInfo?.getStoreChartsInfo?.result?.data[0]?.chartsInfo[0]?.linkStatus === 'invalid') {
      if (dashboardCharts && updateChartsData?.length > 0) {
        dashboardCharts?.data.forEach((dashboardItem) => {
          const existingDashboard = updateChartsData.find((chart) => chart.id === dashboardItem.id);

          if (existingDashboard) {
            const updatedLinks = existingDashboard?.chartsInfo?.filter((obj1) =>
              dashboardItem.chartsInfo.some((obj2) => obj2.sliceId === obj1.sliceId)
            );

            const updateChartsLinksPayload = {
              pageName: 'cameraspage',
              username: JSON.parse(localStorage.getItem('user_login_info') as string).username,
              data: { id: dashboardItem.id.toString(), title: dashboardItem.title, url: dashboardItem.url },
              chartsInfo: updatedLinks,
            };

            storeChartsInfo({ variables: { payload: updateChartsLinksPayload } });
          }
        });
      }
    }
  }, [dashboardCharts, updateChartsData, storeChartsInfo, getStoreChartsInfo, dashboardChartsLoading]);

  useEffect(() => {
    if (activeTab !== null && activeTab !== -1) {
      const selectedGrpStreamList = allStreams?.filter((stream) => {
        const groupList = stream.groupList !== null ? stream.groupList : [];
        return groupList?.includes(groups[activeTab as number]?.id);
      });
      setFilteredStreams(selectedGrpStreamList);
    } else if (activeTab === -1) {
      const selectedGrpStreamList = allStreams?.filter((stream) => {
        const groupList = stream.groupList !== null ? stream.groupList : [];
        return groupList?.includes(activeTab);
      });
      setFilteredStreams(selectedGrpStreamList);
    }
  }, [activeTab, allStreams, groups]);

  const handleCreateNewGroup = () => {
    setGroupEdit(false);
    setEditGroupDetails({ id: null, name: '', description: '' });
    setModalOpen(!modalOpen);
  };

  const handleDeleteGroup = useCallback(async (groupId: number) => {
    try {
      const data = await deleteGroupMutation({ variables: { groupId: groupId } });
      if (data?.data?.deleteGroup?.statusCode === StatusCode.SUCCESS) {
        setMessage({ text: t('Group deleted successfully'), id: '', type: 'success' });
      } else if (data?.data?.deleteGroup?.statusCode === StatusCode.FAILED) {
        setMessage({ text: t('This group includes cameras. First remove them from this group.'), id: '', type: 'danger' });
      } else{
        setMessage({ text: t('Some error occured!'), id: '', type: 'danger' });
      }
      setActiveTab(null);

    } catch (e) {
      setMessage({ text: t('Some error occured!'), id: '', type: 'danger' });
    }
  }, [deleteGroupMutation, t]);

  const handleCamerasActivityClick = useCallback((key: string) => {
    setCameraActivityFilter(key);
    let streamByStatus: IStream[] = [];
    if (key === 'active') {
      streamByStatus = allStreams.filter((stream) => {
        return activeStreams.includes(stream.instanceId);
      });
    } else if (key === 'inActive') {
      streamByStatus = allStreams.filter((stream) => {
        return inActiveStreams.includes(stream.instanceId);
      });
    } else if (key === 'allCameras') {
      streamByStatus = allStreams;
    }
    setActiveTab(null);
    setFilteredStreams(streamByStatus);
  }, [activeStreams, allStreams, inActiveStreams]);

  const searchFilterCallback = useCallback((appliedFilters: IAppliedFilter[]) => {
    setAppliedFiltersLength(appliedFilters.length);
    if (appliedFilters.length > 0) {
      const filteredStreams = allStreams.filter((stream) => {
        return appliedFilters.every((filter) => {
          const { keyName, query } = filter;
          const searchDataFromStream: IModifiedStreamTypeForSearch = {
            name: stream.name,
            streamType: stream.streamType,
            location: stream?.location?.locationName ?? '',
            analysis: stream.algorithmList?.map(item => item.name),
            hasActiveAlert: stream?.alertStatus?.hasActiveAlert
          };
          const value = searchDataFromStream[keyName as keyof IModifiedStreamTypeForSearch];
          if (typeof value === 'string' && value !== undefined && value.toLowerCase().includes(query.toString().toLowerCase())) {
            return true;
          } else if (typeof value === 'boolean' && keyName === ADVANCE_FILTER_KEYNAMES.ALERT_STATUS) {
            return query === ALERT_STATUS.ACTIVE ? value : !value;
          } else if (Array.isArray(value) && Array.isArray(query)) {
            return value.some((item) =>
              query.includes(item)
            );
          }
          return false;
        });
      });
      setFilteredStreams(filteredStreams);
    }
  }, [allStreams]);

  useEffect(() => {
    localStorage.setItem('camerasChartsRefresh', supersetDataRefetchInterval);
  }, [supersetDataRefetchInterval]);

  useEffect(() => {
    localStorage.setItem('camerasChartsRefresh', supersetDataRefetchInterval);
  }, [supersetDataRefetchInterval]);

  const searchQueryKeys: ISearchQueryKeys[] = [
    { keyName: 'name', displayName: t('Camera Name') },
    { keyName: 'location', displayName: t('Location') },
    { keyName: 'streamType', displayName: t('Stream Type') },
  ];

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
    setVisualizationIconToggle(false);
  };

  const handleRefreshInterval = (value: string) => {
    setSupersetDataRefetchInterval(value);
    setVisualizationIconToggle(false);
  };

  const getLoading = useCallback(() => {
    const loadingComponents = Array.from({ length: 4 }, (_, index) => (
      <Skeleton type='cardContainer' key={index}>
        <Skeleton type='card' />
        <CardSkeletonFooter>
          <Skeleton type='text' />
          <SkeletonIcons>
            <Skeleton type='icondiv' />
            <Skeleton type='icondiv' />
          </SkeletonIcons>
        </CardSkeletonFooter>
      </Skeleton>
    ));
    return (
      <CameraPanelsContainer>
        <AllCameraCardsContainer columns={currentDensity}>
          {loadingComponents}
        </AllCameraCardsContainer>
        {mapViewEnabled && <MapViewContainer>
          <Skeleton type='mapCamerasPage' />
        </MapViewContainer>}
      </CameraPanelsContainer>
    );
  }, [mapViewEnabled, currentDensity]);

  const useOutSideClick = (ref: RefObject<HTMLDivElement>) => {
    useEffect(() => {
      function handleOutsideClick(event: MouseEvent) {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          setVisualizationIconToggle(false);
        }
      }
      document.addEventListener('click', handleOutsideClick);
      return () => {
        document.removeEventListener('click', handleOutsideClick);
      };
    }, [ref]);
  };

  useOutSideClick(visualizationIconRef);

  const toggleVisualizationOptions = () => {
    setVisualizationIconToggle(!visualizationIconToggle);
  };

  const handleGroupInfoClick = useCallback((groupInfo: IGroups) => {
    setGroupEdit(true);
    setEditGroupDetails({ id: groupInfo.id, name: groupInfo.name, description: groupInfo.description || '' });
    setModalOpen(true);
  }, []);

  const handleDensityChange = (newValue: number) => {
    setMapViewEnabled(false);
    setCurrentDensity(newValue);
  };

  const handleToggleChange = useCallback((index: number) => {
    setSelectedTab(index);
  }, []);

  const tabButtons = [
    { label: 'Alerts', icon: 'Alert', selected: selectedTab === 0 },
    { label: 'mapViewText', icon: <FaMapMarkerAlt size={14} />, selected: selectedTab === 1},
  ];

  const densityMarks = [
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
    {
      value: 4,
      label: '4',
    }
  ];

  const handleToggleMapView = useCallback((mapViewEnabled: boolean) => {
    setMapViewEnabled(prev => !prev);
    setCurrentDensity(prev => !mapViewEnabled ? 2 : prev);
  }, []);

  const getNoDataFoundMessage = (activityFilter: string) => {
    if (activityFilter === 'allCameras') {
      return { title: t('No camera found.'), description: t('It looks like there are no cameras added yet. To start monitoring, you will need to add camera first.') };
    } else if (activityFilter === 'active') {
      return { title: t('No active cameras currently.'), description: t('It appears that there are not any active cameras at the moment.') };
    } else if (activityFilter === 'inActive') {
      return { title: t('No inactive cameras found.'), description: t('There are not any inactive cameras at the moment; they are all running.') };
    }
  };

  return (
    <Container>
      {message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
      <VisualizationWrapper ref={visualizationIconRef}>
        <VisualizationToggleWrapper isDefaultPage={Config.fetchLatestAlertAPIEnable} onClick={toggleVisualizationOptions}>
          <AiOutlineBarChart size={24} />
          <AllChartOptionsContainer>
            <AllChartOptionsWrapper visualizationIconToggle={visualizationIconToggle}>
              <Arrow />
              <ChartOptionsWrapper onClick={toggleVisibility}>
                <ChartOption>{isVisible ? t('Hide Charts') : t('Show Charts')}</ChartOption>
                <VisualizationIconWrapper>
                  {
                    isVisible ? <MdOutlineHideImage size={24} /> : <AiOutlineBarChart size={24} />
                  }
                </VisualizationIconWrapper>
              </ChartOptionsWrapper>
              {storeChartsAccess && 
              <ChartOptionsWrapper onClick={() => push('/cameraspage/selectcharts')}>
                <ChartOption>{t('Import Charts')}</ChartOption>
                <Icon icon='Download' />
              </ChartOptionsWrapper>}
              {manageChartsAccess && 
              <ChartOptionsWrapper onClick={() => window.open(dashboardCharts?.data[0]?.url, '_blank')}>
                <ChartOption>{t('Manage Charts')}</ChartOption>
                <Icon icon='Settings' />
              </ChartOptionsWrapper>}
              <ChartOptionsWrapper>
                <PaginationWrapper>
                  <RefreshLabel>{t('Refesh Interval')}</RefreshLabel>
                  <SelectOptionField
                    onChange={(e) => handleRefreshInterval(e.target.value)}
                    defaultValue={supersetDataRefetchInterval}
                    value={supersetDataRefetchInterval}
                  >
                    {
                      SUPERSET_REFRESH_INTERVAL.map((size: string, index: number) => <option key={index} value={size}>{size}</option>)
                    }
                  </SelectOptionField>
                </PaginationWrapper>
              </ChartOptionsWrapper>
            </AllChartOptionsWrapper>
          </AllChartOptionsContainer>
        </VisualizationToggleWrapper>
      </VisualizationWrapper>
      <VisualizationContainer isVisible={isVisible}>
        {
          viewChartsAccess && dashboardCharts ? dashboardCharts?.data[0]?.chartsInfo?.length > 0 ? <ChartsWrapper>
            {dashboardCharts.data.map((dashboardData) => (
              dashboardData.chartsInfo.map((chart) => (
                <SingleChartWrapper key={chart.sliceId}>
                  <ChartTitle>{chart.sliceName}</ChartTitle>
                  <EmbeddedChart isDailyAnalysis={chart.sliceId === 5} title={chart.sliceName} src={SUPERSET_URL + chart?.permalink?.split(':9097')[1]} height={250} />
                </SingleChartWrapper>
              ))
            ))}
          </ChartsWrapper> : <NoChartsMessage>{t('Oops! It looks like you have not selected any graphs yet. Please choose the graphs you would like to have on download page.')}</NoChartsMessage> :
            dashboardChartsLoading && viewChartsAccess && <SpinnerBox>
              <CircularProgress />
              <Label>{t('Loading...')}</Label>
            </SpinnerBox>
        }
        {
          viewChartsAccess && dashboardCharts === null && <NoChartsMessage>{t('Oops! It looks like you have not selected any graphs yet. Please choose the graphs you would like to have on cameras page.')}</NoChartsMessage>
        }
        {
          !viewChartsAccess && <NoChartsMessage>{t('Unfortunately, you do not have permission to view these charts at this time. Please contact your administrator for assistance.')}</NoChartsMessage>
        }
      </VisualizationContainer>
      <CamerasMainContainer>
        <CamerasLeftContainer>
          <FeedStatusContainer>
            <NewCameraButton disabled={!addCameraAccess} onClick={addCameraAccess ? () => push('cameras/configurations/configurations?selectedTab=basicConfigs') : () => { }}>
              <NewCameraText>{t('New Camera')}</NewCameraText>
              <Icon icon='Plus' size={20} color='inverse' />
            </NewCameraButton>
            <CameraActivityStatusContainer>
              <CameraActivityStatusWrapper isSelected={cameraActivityFilter === 'allCameras'} onClick={() => handleCamerasActivityClick('allCameras')}>
                <CameraStatusIndicator status='allCameras' />
                <Label>{t('All Cameras')}</Label>
                <Label>{allStreams?.length || activeStreamsCount + inActiveStreamsCount}</Label>
              </CameraActivityStatusWrapper>
              <HorizontalDivider hasMargin={false} />
              <CameraActivityStatusWrapper isSelected={cameraActivityFilter === 'active'} onClick={() => handleCamerasActivityClick('active')}>
                <CameraStatusIndicator status='active' />
                <Label>{t('Active')}</Label>
                <Label>{activeStreamsCount}</Label>
              </CameraActivityStatusWrapper>
              <HorizontalDivider hasMargin={false} />
              <CameraActivityStatusWrapper isSelected={cameraActivityFilter === 'inActive'} onClick={() => handleCamerasActivityClick('inActive')}>
                <CameraStatusIndicator status='inActive' />
                <Label>{t('Inactive')}</Label>
                <Label>{inActiveStreamsCount}</Label>
              </CameraActivityStatusWrapper>
            </CameraActivityStatusContainer>
          </FeedStatusContainer>
          <HorizontalDivider hasMargin={true} />
          {viewGroupListAccess && <><GroupsContainer>
            <AddNewGroupContainer>
              <LabelContainer>
                <PiCardsFill size={20} />
                <Label>{t('Groups')}</Label>
              </LabelContainer>
              {addGroupAccess && <IconDiv onClick={handleCreateNewGroup} title={t('addNewGroup')} ><Icon icon='Plus' size={20} /></IconDiv>}
            </AddNewGroupContainer>
            {modalOpen && <CreateEditGroupModal modalOpen={modalOpen} setModalOpen={setModalOpen} isGroupEdit={isGroupEdit} setMessage={setMessage} editGroupDetails={editGroupDetails} />}
            <AllGroupsWrapper>
              <AllGroupsContainer>
                {groups && groups.map((group, index) => (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <GroupLabelContainer isSelected={activeTab === index}>
                      <Label isSelected={activeTab === index} onClick={() => { setActiveTab(index); setCameraActivityFilter(''); }} isGroupName title={group.name}>{group.name}</Label>
                      <IconDiv isSelected={activeTab === index} onClick={() => { setGrpMoreOptionsOpen(!grpMoreOptionsOpen); setGrpIndex(index); }}>
                        <Icon icon='MoreVertical' size={18} />
                      </IconDiv>
                      {
                        grpIndex === index && grpMoreOptionsOpen &&
                        <GroupMoreOptions ref={moreOptionsRef}>
                          <GroupOption onClick={() => handleGroupInfoClick(group)}>{t('More Info')}</GroupOption>
                          {deleteGroupAccess && <GroupOption onClick={() => { setDeleteGroupModal(!deleteGroupModal); }}>{t('Delete')}</GroupOption>}
                        </GroupMoreOptions>
                      }
                    </GroupLabelContainer>
                    {index !== groups.length - 1 && <HorizontalDivider />}
                  </div>
                ))}
                {groups?.length > 0 && <HorizontalDivider />}
                <GroupLabelContainer isSelected={activeTab === -1} onClick={() => setActiveTab(-1)}>
                  <Label isGroupName>{t('No Group')}</Label>
                </GroupLabelContainer>
              </AllGroupsContainer>
            </AllGroupsWrapper>
            {deleteGroupModal &&
              <ConfirmationModal modalOpen={deleteGroupModal} setModalOpen={setDeleteGroupModal} onSubmit={() => handleDeleteGroup(groups[grpIndex as number]?.id)} hasConfirmationField={true}
                placeholder={t('Enter Group Name')} textToCheck={groups[grpIndex as number]?.name} titleText={t('Delete Group') + `: ${groups[grpIndex as number]?.name}?`} confirmDescription={t('Are you sure you want to delete this group?')} noteText={t('Once deleted this action cannot be undone.')}
              />}
          </GroupsContainer>
          <HorizontalDivider hasMargin={true} /></>}
          <ListGridToggle>
            <ToggleWrapper onClick={() => setSelectedView('grid')} isSelected={selectedView === 'grid'}>
              <Label>{t('Grid')}</Label>
            </ToggleWrapper>
            <ToggleWrapper onClick={() => setSelectedView('list')} isSelected={selectedView === 'list'}>
              <Label>{t('List')}</Label>
            </ToggleWrapper>
          </ListGridToggle>
          <HorizontalDivider hasMargin={true} />
          <DensityHandler>
            <Label>{t('Density')}</Label>
            <Slider
              disabled={selectedView === 'list'}
              marks={densityMarks}
              defaultValue={2} // Set the default value based on your data
              getAriaValueText={(value: number) => value.toString()}
              valueLabelDisplay="auto"
              value={currentDensity || 2} // Set the value based on your data
              step={1}
              min={2}
              max={4}
              onChange={(event, newValue) => handleDensityChange(newValue as number)} />
          </DensityHandler>
        </CamerasLeftContainer>
        <CamerasRightContainer>
          <SearchContainer>
            <SearchInputFilter showAlertActivityFilter showAlgorithm searchFilterCallback={searchFilterCallback} algorithmList={algorithmList} searchQueryKeys={searchQueryKeys} placeholder={t('Search across your cameras...')} />
            <MapToggle onClick={()=>handleToggleMapView(mapViewEnabled)}>
              {mapViewEnabled ? <MdGridOn size={22} /> : <MdGridOff size={22} />}
            </MapToggle>
            {mapViewEnabled && Config.isAlertMapToggle &&
              <ToggleDiv>
                <Toggle width='80px' iconSize={16} enabled={true} tabButtons={tabButtons} selected={selectedTab} onTabClick={handleToggleChange} />
              </ToggleDiv>
            }
          </SearchContainer>
          {
            loading ? getLoading() :
              <>
                {
                  allStreams?.length > 0 ?
                    <CameraPanelsContainer>
                      <>
                        {
                          filteredStreams?.length ?
                            <AllCameraCardsContainer columns={currentDensity}>
                              <DetectionProvider isSkip={!allStreams.some(camera =>camera?.algorithmList?.some(algorithm => algorithm.code === ALGORITHM_CODES.traffic_pulse_detection))}>
                                {
                                  selectedView === 'grid' ? filteredStreams?.map((stream) => (
                                    <NewCard key={stream.instanceId} setMessage={setMessage} message={message} stream={stream} groups={groups} showAlertIcon={Config.enableAlertBlinkOnCard} gridViewOption={mapViewEnabled ? 0 : currentDensity} highLightStreamId={highLightStreamId} />
                                  )) :
                                    <CameraList getAllStreamsRefetch={getAllStreamsRefetch} isMapViewEnabled={mapViewEnabled} streams={filteredStreams} setMessage={setMessage} />
                                }
                              </DetectionProvider>
                            </AllCameraCardsContainer>
                            :
                            <AllCameraCardsContainer>
                              {activeTab !== null ?
                                <NoDataContainer>
                                  <NotFoundTitle>{t('No cameras found in this group')}</NotFoundTitle>
                                </NoDataContainer> :
                                <>
                                  {
                                    cameraActivityFilter ?
                                      <NoDataContainer>
                                        <NotFoundTitle>{getNoDataFoundMessage(cameraActivityFilter)?.title}</NotFoundTitle>
                                        <NotFoundDescription>{getNoDataFoundMessage(cameraActivityFilter)?.description}</NotFoundDescription>
                                      </NoDataContainer> :
                                      <NoDataContainer>
                                        <NotFoundTitle>{t('No matching cameras found')}</NotFoundTitle>
                                        <NotFoundDescription>{t('Your search criteria did not match any cameras. Please try a different search to find the cameras you are looking for.')}</NotFoundDescription>
                                      </NoDataContainer>
                                  }
                                </>
                              }
                            </AllCameraCardsContainer>
                        }
                        {isMapModalOpen &&
                          <Modal isModalOpen={isMapModalOpen} closeModal={() => setIsMapModalOpen(false)} width='100vw'>
                            <ModalMapViewContainer>
                              {allStreams?.length > 0 && !locationsLoading && !Config.isZoneMap ?
                                <Map setHighlightStreamId={setHighlightStreamId} streamsList={allStreams} />
                                : <ZoneMap defaultZoom={0.9} height='500px' width='1170px' instanceId='' />
                              }
                            </ModalMapViewContainer>
                          </Modal>
                        }
                        {mapViewEnabled && selectedTab === 1 &&
                          <>
                            {
                              refetchForAllStreamsLoading ? 
                                <>
                                  <MapViewContainer>
                                    <Skeleton type='mapCamerasPage' />
                                  </MapViewContainer>
                                </> : 
                                
                                <MapViewContainer>
                                  <ActionIcon onClick={() => setIsMapModalOpen(true)} isZoneMap={Config.isZoneMap}>
                                    <Icon icon='Expand' color='mono' size={24} />
                                  </ActionIcon>
                                  {(allStreams?.length > 0 && !locationsLoading) ?
                                    <Map setHighlightStreamId={setHighlightStreamId} streamsList={allStreams} />
                                    : Config.isZoneMap && <ZoneMap defaultZoom={0.9} height='500px' width='100%' instanceId='' />
                                  }
                                </MapViewContainer>
                            }
                          </>
                        }
                        {mapViewEnabled && selectedTab === 0 &&
                          <Alerts isCameraPageAlerts={true} isSearchQuery={true} showSingleAlertDetailsPanel />
                        }
                      </>
                    </CameraPanelsContainer> :
                    <NoDataContainer>
                      <NotFoundTitle>{t('cameraNotFound')}</NotFoundTitle>
                      <NotFoundDescription>{t('cameraNotFoundDesc')}</NotFoundDescription>
                    </NoDataContainer>
                }
              </>
          }
        </CamerasRightContainer>
      </CamerasMainContainer>
    </Container>
  );
};

export default CameraManagement;