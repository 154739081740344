import React, { useState, useCallback, ChangeEvent, useRef, useEffect } from 'react';
import styled, {css} from 'styled-components';
import { useTranslation } from 'react-i18next';
import Input from 'components/Input';
import LoginBackground from '../svgs/login_background.jpg';
import LoginLogo from '../svgs/logo_login.png';
import { IAlertMessageType } from 'interface';
import AlertBar from 'components/AlertBar';
import { IconType } from '../constants';
import { useHistory } from 'react-router';
import ButtonWithLoading from 'components/ButtonWithLoading';
import { validateEmail } from 'utils/utils';
import Modal from 'components/Modal';
import Button from 'components/Button';
import { CREATE_USER } from 'api_configs/mutations';
import { useMutation } from '@apollo/client';
import { Auth } from 'aws-amplify';
import awsConfig from 'lib/amplify';
import { useIdentityID } from 'hooks/useIdentityID';

const Container = styled.div`
  position: relative;
  padding: 130px;
  width: 100%;
  min-height: 100vh;
  @media (max-width: 1366px) {
    padding: 36px;
  }
`;

const Box = styled.div<{ margin?: string; flex?: string; }>`
  margin-top: 10px;
  button{
    border-radius: 10px;
    width: 100%;
    height: 40px;
    background-color: #0139C4;
    @media (max-width: 1366px) {
      height: 35px;
  }
  }
  
  ${({ margin }) => margin && css`
    margin: ${margin};
  `}

  ${({ flex }) => flex && css`
    flex: ${flex};
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
  `}
`;

const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
`;

const LinkWrapper = styled.div<{disabled?: boolean}>`
  text-decoration: underline;
  color: ${({ disabled }) => disabled ? '#a0a0a0' : '#0139C4' };
  margin-top: 5px;
  cursor: pointer;
  ${({ disabled }) => disabled && css`
    pointer-events: none;
    cursor: not-allowed;
  `}
`;

const InputBox = styled.div`
  label > div > div:nth-child(2) > div > div {
    height: 20px;
    @media (max-width: 1366px) {
      height: 10px;
  }
  }
  >div> input{
    width: 400px;
    @media (max-width: 1366px) {
    width: 318px;
  }
  }
`;

const PasswordContainer = styled.div`
  button > div {
    margin-top: 5px;
  }
`;

const LoginWrapper = styled.div`
  width: 440px;
  padding: 20px;
  background-color: #FFFFFF;
  border-radius: 10px;
  height: 610px;
  @media (max-width: 1366px) {
    height: 610px;
    width: 360px;
  }
`;

const FormDiv = styled.div`
  max-height: 280px;
  max-width: 400px;
  margin-top: 12px;
`;

const Separator = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 6px;
  font-size: 14px;
`;

const LoginForm = styled.form`
  margin-left: auto;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  align-items: flex-end;
  @media (max-width: 1366px) {
    margin-top: 40px;
  }
`;

const AvtarImg = styled.img`
  margin-left: 90px;
  width: 200px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 36px;
  @media (max-width: 1366px) {
    width: 156px;
    margin-left: 80px;
  }
`;

const ForgotPasswordSection = styled.div`
  display: flex;
  justify-content: center;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 10px 40px;
  align-items: center;
`;

const OtpInput = styled.input`
  padding: 10px;
  font-size: 18px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 80%;
`;

const OtpContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 40px;
`;

const HeaderText = styled.div`
  max-width: 400px;
  word-wrap: break-word;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
`;

const HeaderLabel = styled.div`
  font-size: 22px;
`;

const OPTResend = styled.div`
  display: flex;
  justify-content: center;
  gap: 0 10px;
`;

const Text = styled.div`
  margin-top: 5px;
  cursor: pointer;
`;

const ResendOTPTimer = styled.div`
  margin-top: 5px;
`;

const Register = () => {
  const [form, setForm] = useState({username:'', password:'', email: '', confirmPassword: ''});
  const { t } = useTranslation(['common']);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);
  const [resendOTPTimer, setResendOTPTimer] = useState('59');
  const [resetPasswordSection, setResetPasswordSection] = useState(false);
  const [confirmOTPLoading, setConfirmOTPLoading] = useState(false);
  const [createUser] = useMutation(CREATE_USER);
  const [userSub, setUserSub] = useState('');
  const {userId} = useIdentityID();

  useEffect(() => {
    if(resetPasswordSection && resendOTPTimer !== '00') {
      if(Number(resendOTPTimer) <= 10) {
        setTimeout(() => {setResendOTPTimer(`0${(Number(resendOTPTimer) - 1)}`);}, 1000);
      }else{
        setTimeout(() => {setResendOTPTimer((Number(resendOTPTimer) - 1).toString());}, 1000);
      }
    }
  }, [resendOTPTimer, resetPasswordSection]);

  const onFieldChange = useCallback((key: 'username'|'password'|'email'|'confirmPassword') => ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setForm({...form, [key]: value.trim()});
  }, [form]);

  const validatePassword = (password: string): boolean => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  };
  

  const onSubmit = useCallback(async (e: React.FormEvent<HTMLFormElement | HTMLButtonElement>) => {
    e.preventDefault();
    if (!validateEmail(form.email)) {
      setMessage({ text: t('Invalid email address'), id: 'email', type: 'danger' });
      return;
    }
    if (!validatePassword(form.password)) {
      setMessage({ text: t('Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 8 characters long'), id: 'password', type: 'danger' });
      return;
    }
    if (form.password !== form.confirmPassword) {
      setMessage({ text: t('Passwords do not match'), id: 'confirmPassword', type: 'danger' });
      return;
    }
    setLoading(true);
    try {
      const response = await Auth.signUp({username: form.username, password: form.password, attributes: { email: form.email }});
      setUserSub(response.userSub);
      setIsModalOpen(true);
      setResendOTPTimer('59');
      setResetPasswordSection(true);
    } catch (error: unknown) {
      if (typeof error === 'object' && error !== null && 'code' in error) {
        const typedError = error as { code: string; message?: string };
        if (typedError.code === 'UsernameExistsException') {
          setMessage({ text: t('User already exists'), id: '', type: 'danger' });
        } else {
          setMessage({ text: t('Registration failed. Please try again.'), id: '', type: 'danger' });
        }
      } else {
        setMessage({ text: t('An unknown error occurred.'), id: '', type: 'danger' });
      }
    } finally {
      setLoading(false);
    }
  }, [form.confirmPassword, form.email, form.password, form.username, t]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value) || value === '') {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value !== '' && index < otp.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setResetPasswordSection(false);
    setResendOTPTimer('59');
  };

  const handleResendOTP = () => {
    try {
      // resend otp logic
      setResetPasswordSection(true);
      setResendOTPTimer('59');
    } catch (error) {
      setMessage({ text: t('Registration failed. Please try again.'), id: 'register', type: 'danger' });
    }
  };

  const handleConfirmOTP = useCallback(async() => {
    try {
      const otpCode = otp.join('');
      if (otpCode.length !== 6) {
        setMessage({ text: t('Please enter a valid 6-digit OTP'), id: 'otp', type: 'danger' });
        return;
      }
      await Auth.confirmSignUp(form.username, otpCode);

      try {
        const response = await createUser({variables: {input: {username: form.username, email: form.email, password: form.password, phone_number: null, user_id: userSub}, cognitoID: userId, cognitoIdentityPoolID: awsConfig.Auth.identityPoolId}});
        if(response.data.createUser !== null) {
          window.location.href = '/';
        } else {
          setMessage({ text: t('Registration failed. Please try again.'), id: 'register', type: 'danger' });
          setIsModalOpen(false);
          setResetPasswordSection(false);
          setResendOTPTimer('59');
        }
      } catch (error) {
        setMessage({ text: t('Registration failed. Please try again.'), id: 'register', type: 'danger' });
      }
    } catch (error) {
      console.error('Confirmation error:', error);
      setMessage({ text: t('Failed to confirm account. Please try again.'), id: 'otp', type: 'danger' });
    } finally {
      setConfirmOTPLoading(false);
    }
  },[createUser, form.email, form.password, form.username, otp, t, userId, userSub]);

  return (
    <Container>
      {message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
      <BackgroundImage src={LoginBackground} />
      <LoginForm onSubmit={(e) => onSubmit(e)}>
        <LoginWrapper>
          <FormDiv>
            <AvtarImg src={LoginLogo} />
            <InputBox>
              <Input
                id='email'
                type='email'
                label={t('Email')}
                onChange={onFieldChange('email')}
                value={form.email}
                name='email'
                maxLength={128}
                width={'100%'}
              />
            </InputBox>
            <InputBox>
              <Input
                id='username'
                type='text'
                label={t('Username')}
                onChange={onFieldChange('username')}
                value={form.username}
                name='username'
                maxLength={128}
                width={'100%'}
              />
            </InputBox>
            <PasswordContainer>
              <InputBox>
                <Input
                  id='password'
                  type='password'
                  label={t('Password')}
                  onChange={onFieldChange('password')}
                  value={form.password}
                  name='password'
                  maxLength={32}
                  width={'100%'}
                />
              </InputBox>
            </PasswordContainer>
            <PasswordContainer>
              <InputBox>
                <Input
                  id='confirmPassword'
                  type='password'
                  label={t('Confirm Password')}
                  onChange={onFieldChange('confirmPassword')}
                  value={form.confirmPassword}
                  name='confirmPassword'
                  maxLength={32}
                  width={'100%'}
                />
              </InputBox>
            </PasswordContainer>
            <Box flex='1'>
              <ButtonWithLoading loading={loading} disabled={!((form.username.length > 0) && (form.password.length > 0))} variant='primary' type='submit' onClick={(e) => onSubmit(e)}>{t('Register')}</ButtonWithLoading>
            </Box>
            <Separator>{t('OR')}</Separator>
            <ForgotPasswordSection>
              <LinkWrapper onClick={() => {history.push('/login'); window.location.reload();}}>{t('Already have account')}</LinkWrapper>
            </ForgotPasswordSection>
          </FormDiv>
        </LoginWrapper>
      </LoginForm>
      { isModalOpen &&
       <Modal isModalOpen={isModalOpen} width='32vw' closeModal={handleCloseModal}>
         <ModalContent>
           <HeaderLabel>{t('Please enter OTP to verify your account')}</HeaderLabel>
           <HeaderText>{t('A OTP has been sent via email to ') + `${form.email}`}</HeaderText>
           <OtpContainer>
             {otp.map((digit, index) => (
               <OtpInput
                 key={index}
                 type="text"
                 value={digit}
                 onChange={(e) => handleChange(e, index)}
                 onKeyDown={(e) => handleKeyDown(e, index)}
                 ref={(el) => (inputRefs.current[index] = el)}
                 maxLength={1}
               />
             ))}
           </OtpContainer>
           <OPTResend>
             <Text>{t('Did not receive OTP?')}</Text>
             <LinkWrapper onClick={handleResendOTP} disabled={resendOTPTimer !== '00'}>{t('Resend OTP')}</LinkWrapper>
             {resendOTPTimer !== '00' && <ResendOTPTimer>00 : {resendOTPTimer}</ResendOTPTimer>}
           </OPTResend>
           <ButtonsWrapper>
             <ButtonWithLoading loading={confirmOTPLoading} disabled={otp.join('') === ''} variant='primary' type='submit' onClick={handleConfirmOTP}>{t('Confirm')}</ButtonWithLoading>
             <Button variant='secondary' onClick={()=> {setIsModalOpen(false);}}>{t('Cancel')}</Button>
           </ButtonsWrapper>
         </ModalContent>
       </Modal>
      }
    </Container>
  );
};

export default Register;