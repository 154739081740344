import SelectField from 'components/SelectField';
import Input from '../components/Input';
import Checkbox from 'components/Checkbox';
import React, { useCallback, useEffect, useState } from 'react';
import { useHeader } from 'contexts/HeaderContext';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Button from 'components/Button';
import ButtonWithIcon from 'components/ButtonWithIcon';
import Label from 'components/Label';
import Modal from '../components/Modal';
import lodash from 'lodash';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Icon from 'components/Icon';
import AlertBar from 'components/AlertBar';
import { IconType, MAX_IMAGES_VALUES, INPUT_TYPE_OPTIONS, StatusCode, PROPERTY_TYPE, FEATURE_REGISTRATION_OPTION } from '../constants';
import { IAlertMessageType, IBreadcrum } from 'interface';
import { CREATE_NEW_OBJECT_TYPE, DELETE_OBJECT_TYPE, EDIT_OBJECT_TYPE } from 'api_configs/mutations';
import { useMutation, useQuery } from '@apollo/client';
import { CircularProgress, Slider } from '@mui/material';
import { useHistory, useLocation, useParams } from 'react-router';
import { GET_ALL_OBJECT_TYPES, GET_OBJECT_TYPE } from 'api_configs/queries';
import PreviewAddObject from './PreviewAddObject';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import ButtonWithLoading from 'components/ButtonWithLoading';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  padding: 15px 20px 5px 20px;
`;

const NewFieldOptionsWrapper = styled.div``;

const CheckboxWrapper = styled.div`
  display: flex;
  width: 450px;
  gap: 60px;
  justify-content: left;
`;

const SectionWrapper = styled.div`
  display: flex;
  gap: 80px;
  width: 80%;
`;

const AllPropertiesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 55px;
  min-width: 1200px;
  max-width: 1200px;

  .MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }

  .MuiDataGrid-root .MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }

  .MuiDataGrid-columnHeaderTitle {
      font-weight: bold;
      font-size: 15px;
  }

  > div > div:first-child{
    > div:first-child{
        background-color: #F5F8FF;
        font-size: 14px;
        color: #5a6269;
    }
  }

  > div > div:first-child{
    > div:first-child{
      font-size: 14px;
      color:#7b8288;
      font-weight: 800;
    }
  }

  > div:first-child {
    > div:nth-child(1) {
      > div:nth-child(2){
        min-height: 50px;
      }
    }
  }
  > div > div:nth-child(2) {
    display: none;
  }
`;

const ComponentLabel = styled.div`
  font-size: 14px;
  cursor: pointer;
`;

const SingleCheckboxWrapper = styled.div`
  display: flex;
  gap: 15px;
  >:nth-child(2) {
    margin-bottom: 10px;
  }
`;

const SelectOptionsWrapper = styled.div`
  display: flex;
  gap: 14px;
  flex-wrap: wrap;
  margin-top: 10px;
  width: 450px;
`;

const IndividualUserOptionsForSelect = styled.div`
  width: fit-content;
  padding: 3px 8px 5px 8px;
  background-color: #81cde7;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
`;

const SelectFieldWrapper = styled.div``;

const AddPropertyButtonWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
`;

const NewObjectNameWrapper = styled.div`
  display: flex;
  gap: 15px;

  >button {
    margin-bottom: -22px;
    min-width: 125px;
  }
`;

const Heading = styled.div`
  font-size: 20px;
  font-weight: 700;
`;

const AddObjectTypeWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  min-width: 1100px;
  max-width: 1100px;
  margin-top: 10px;
  margin-bottom: 15px;
`;

const DisplayName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const KeyWrapper = styled.div``;

const PropertyKey = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const OtherPropertiesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0;
  gap: 2px;
  min-height: 25px;
`;

const MaxImagesWrapper = styled.div``;

const ActionsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const ActionIcon = styled.div<{disabled?: boolean}>`
  display: flex;
  pointer-events: ${({disabled}) => disabled ? 'none' : ''};
  cursor: ${({disabled}) => disabled ? 'not-allowed' : 'pointer'};
`;

const PropertyType = styled.div``;

const DisplayNameWrapper = styled.div``;

const PropertyTypeWrapper = styled.div``;

const SelectInputWrapper = styled.div`
  > div > input {
    width: 300px;
    height: 30px;
  }
`;

const AllOptionsWrapper = styled.div`
  position: relative;
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  top: -9px;
  right: -8px;
  cursor: pointer;
`;

const NewPropertyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 1200px;
  max-width: 1200px;
`;

const AdvancedOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const VerticalLine = styled.div`
  width: 1200px;
  height: 10px;
  border-bottom: 1px solid lightgrey;
  margin-top: 2px;
  margin-bottom: 15px;
  @media (max-width: 768px) {
    width: 450px;
  }
`;

const SelectFieldNewOptionWrapper = styled.div`
  display: flex;
  gap: 34px;
  > div > div {
    width: 300px;
  }
  > button {
    margin-top: 32px;
    height: 30px;
  }
`;

const IsUniqueFieldWrapper = styled.div`
  display: flex;
  margin-top: 5px;
  column-gap: 15px;

  >:nth-child(2) {
    margin-bottom: 10px;
  }
`;

const FeatureExtractionWrapper = styled.div``;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 300px;
  gap: 10px;
`;

const DeleteWrapper = styled.div`
  display: flex;
  margin-top: 20px;
`;

const Loading = styled.span``;

interface IParams {
  editId: string
}

interface IProperty {
  displayName: string,
  featureExtraction?: string,
  isRequired: boolean,
  key: string,
  maxImages?: number,
  propertyType: string,
  sequence: string,
  sequenceId?: number,
  isTextOnly?: boolean,
  maxTextLength?: number,
  selectOptions?: string[],
  rows?: number,
  columns?: number
  isUnique?: boolean
}

interface IInitialProperties {
  name: string,
  properties: IProperty[]
}

const AddObjectType = () => {
  const [properties, setProperties] = useState<IProperty[]>([]);
  const [initialProperties, setInitialProperties] = useState<IInitialProperties>({name: '', properties: []});
  const [enableAddFields, setEnableAddFields] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { pathname } = useLocation();
  const [currentSequence, setCurrentSequence] = useState('');
  const editId: IParams = useParams();
  const editObjectTypeId = editId.editId;
  const [isEditObjectType] = useState(pathname.includes('/editobjecttype/'));
  const [key, setKey] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [objectName, setObjectName] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [editObjectType] = useMutation(EDIT_OBJECT_TYPE, {refetchQueries: [{ query: GET_ALL_OBJECT_TYPES }]});
  const [deleteObjectType] = useMutation(DELETE_OBJECT_TYPE, {refetchQueries: [{ query: GET_ALL_OBJECT_TYPES }]});
  const [isRequired, setIsRequired] = useState(false);
  const [isEditPropertyMode, setIsEditPropertyMode] = useState(false);
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: ''}); 
  const [propertyType, setPropertyType] = useState('');
  const history = useHistory();
  const [isDefault, setIsDefault] = useState();
  const [isContainUniqueProperty, setIsContainUniqueProperty] = useState(false);
  const [maxImages, setMaxImages] = useState<number | undefined>(5);
  const [featureExtraction, setFeatureExtraction] = useState('none');
  const [maxTextLength, setMaxTextLength] = useState<number | undefined>(255);
  const [isTextOnly, setIsTextOnly] = useState<boolean | undefined>(false);
  const [rows, setRows] = useState<number | undefined>(0);
  const [enablePreview, setEnablePreview] = useState(false);
  const [deletePropertyPopUp, setDeletePropertyPopUp] = useState(false);
  const [deletePropertyId, setDeletePropertyId] = useState<number | undefined>();
  const [isUnique, setIsUnique] = useState(false);
  const { t } = useTranslation(['common']);
  const [createObjectType] = useMutation(CREATE_NEW_OBJECT_TYPE, {refetchQueries: [{ query: GET_ALL_OBJECT_TYPES }]});
  const [columns, setColumns] = useState<number | undefined>(0);
  const [propertyEditId, setPropertyEditId] = useState(undefined);
  const { updateHeaderTitle } = useHeader();
  const [selectOptions, setSelectOptions] = useState<string[]>([]);
  const [newOption, setNewOption] = useState('');
  const [deleteObjectLoading, setDeleteObjectLoading] = useState(false);
  const [deleteObjectTypeConfirmation, setDeleteObjectTypeConfirmation] = useState(false);
  const { data, loading } = useQuery(GET_OBJECT_TYPE, {variables: {id: Number(editObjectTypeId)}});

  useEffect(()=>{
    const cameraPageBreadCrumb: IBreadcrum[] = [
      {label: t('Objects'), to: `/objects?selectedTab=${editObjectTypeId}`}
    ];
    updateHeaderTitle(isEditObjectType ? `Edit ${objectName}` : t('Add Object'), 'Object', cameraPageBreadCrumb);
  },[updateHeaderTitle, isEditObjectType, editObjectTypeId, objectName, t]);

  useEffect(() => {
    if(isEditObjectType) {
      if(!loading) {
        if(data) {
          setObjectName(data?.getObjectType?.result?.name);
          setProperties(data?.getObjectType?.result?.properties);
          setInitialProperties(prev => ({...prev, name: data?.getObjectType?.result?.name, properties: data?.getObjectType?.result?.properties}));
          setIsDefault(data?.getObjectType?.result?.isDefault);
          setIsEditPropertyMode(false);
        }
      }
    }
  }, [isEditObjectType, loading, data]);

  const localizationTexts = {
    noRowsLabel: t('No Data'),
  };

  const handleAddProperty = () => {
    let keyExists = false;
    properties.forEach(property => {
      if (property.key === key) {
        keyExists = true;
        return;
      }});
    if ((editObjectTypeId === undefined || propertyEditId === undefined) && keyExists) {
      if(propertyEditId || propertyEditId === 0) {
        const checkPropertyExists = properties.filter((property) => (property.key === key));
        if((checkPropertyExists?.length > 0 && checkPropertyExists[0].displayName !== displayName)) {
          setMessage({ text: t('This key already exist.'), id: '', type: 'danger' });
          return;
        }
      }else {
        setMessage({ text: t('This key already exist.'), id: '', type: 'danger' });
        return;
      }
    }   
    if(displayName.trim() === '') {
      setMessage({ text: t('Display name is required.'), id: '', type: 'danger' });
      return;
    }
    if(propertyType.trim() === '') {
      setMessage({ text: t('Property type is required.'), id: '', type: 'danger' });
      return;
    }
    const sequenceOfProperty = () => {
      if(currentSequence === '') {
        setCurrentSequence('L0');
        return 'L0';
      } else if(currentSequence.startsWith('L')) {
        setCurrentSequence(`R${currentSequence.charAt(1)}`);
        return `R${currentSequence.charAt(1)}`;
      }else {
        setCurrentSequence(`L${Number(currentSequence.charAt(1)+1)}`);
        return `L${Number(currentSequence.charAt(1)+1)}`;
      }
    };
    const newProperty = {
      key,
      displayName,
      isRequired: isRequired,
      propertyType,
      maxImages: propertyType === PROPERTY_TYPE.MultiImage ? maxImages : undefined,
      maxTextLength: propertyType === PROPERTY_TYPE.Text ? maxTextLength : undefined,
      featureExtraction: featureExtraction,
      isTextOnly: propertyType === PROPERTY_TYPE.Text ? isTextOnly : undefined,
      rows: propertyType === PROPERTY_TYPE.TextArea ? rows : undefined,
      sequence: sequenceOfProperty(),
      isUnique: propertyType === PROPERTY_TYPE.Text && isUnique ,
      columns: propertyType === PROPERTY_TYPE.TextArea ? columns : undefined,
      selectOptions: propertyType === PROPERTY_TYPE.SelectField? [...selectOptions] : undefined,
    };
    if(!isEditPropertyMode) {
      setProperties(prevProperties => [...prevProperties, newProperty]);
      handleCloseModal();
      setKey('');
      setDisplayName('');
      setIsRequired(false);
      setPropertyType('');
      setMaxImages(5);
      setIsUnique(false);
      setMaxTextLength(255);
      setIsTextOnly(false);
      setRows(14);
      setColumns(8);
      setSelectOptions([]);
      setNewOption('');
      setFeatureExtraction('none');
    }else {
      setProperties(properties.map((property, index) => {
        if(index === propertyEditId) 
          return newProperty;
        else 
          return property;
      }));
      setKey('');
      setDisplayName('');
      setIsRequired(false);
      setPropertyType('');
      setMaxImages(5);
      setMaxTextLength(255);
      setIsTextOnly(false);
      setIsUnique(false);
      setRows(14);
      setColumns(8);
      setSelectOptions([]);
      setNewOption('');
      setFeatureExtraction('none');
      setIsEditPropertyMode(false);
      setModalOpen(false);
    }
  };

  const handleEditObjectType = async () => {
    let isUniqueKeyExist = false;
    properties.forEach(property => {
      if (property.isUnique) {
        isUniqueKeyExist = true;
        return;
      }});
    if((objectName).trim() === '') {
      setMessage({text: t('Object type name is required.'), id:'',type:'danger'});
      return;
    }
    if(properties?.length === 0) {
      setMessage({text: t('Please add at least one property.'), id:'',type:'danger'});
      return;
    }
    if(!isUniqueKeyExist) {
      setMessage({text: t('Please add at least one unique property.'), id:'',type:'danger'});
      return;
    }
    setButtonDisabled(true);
    const changedObject = {name: objectName, id: Number(editObjectTypeId), description: 'description', properties: properties, isDefault: false};
    try {
      const data = await editObjectType({variables: {payload: changedObject}});
      if(data && data?.data?.editObjectType?.statusCode === StatusCode.SUCCESS){
        setMessage({text: t('Changes saved successfully'), id:'',type:'success'});
        setTimeout(() => {
          history.push(`/objects?selectedTab=${editObjectTypeId}`);
        }, 2000);
      }else if(data && data?.data?.editObjectType?.statusCode === (StatusCode.ERROR)) {
        setMessage({text: t('Object type already exists'), id:'',type:'danger'});
      }
    }catch(e) {
      console.log(e);
    }finally {
      setButtonDisabled(false);
    }
  };

  const handleDeleteObjectType = async () => {
    setDeleteObjectLoading(true);
    try {
      const data = await deleteObjectType({variables: {id: Number(editObjectTypeId)}});
      if(data && data?.data?.deleteObjectType?.statusCode === StatusCode.SUCCESS){
        setMessage({text: t('Object type deleted successfully'), id:'',type:'success'});
        setTimeout(() => {
          history.push('/objects');
        }, 2000);
      } else if(data && data?.data?.deleteObjectType?.statusCode === StatusCode.ERROR) {
        setMessage({text: t('Cannot delete object type. There are objects associated with this object type. Please delete them first.'), id:'',type:'danger'});
      } else {
        setMessage({text: t('apiError'), id:'',type:'danger'});
      }
    }catch(e) {
      console.log(e);
    }finally{
      setDeleteObjectLoading(false);
    }
  };

  const handleAddNewObjectType = async () => {
    let isUniqueKeyExist = false;
    properties.forEach(property => {
      if (property.isUnique) {
        isUniqueKeyExist = true;
        return;
      }});
    if((objectName).trim() === '') {
      setMessage({text: t('Object type name is required.'), id:'',type:'danger'});
      return;
    }
    if(!isUniqueKeyExist) {
      setMessage({text: t('Please add at least one unique property.'), id:'',type:'danger'});
      return;
    }
    if(!isContainUniqueProperty) {
      setMessage({text: t('Please add at least one unique property.'), id:'',type:'danger'});
      return;
    }
    setButtonDisabled(true);
    const newObject = {name: objectName, description: 'description', properties: properties, isDefault: false};
    try {
      const data = await createObjectType({variables: {payload: newObject}});
      if(data && data?.data?.createObjectType?.statusCode === StatusCode.SUCCESS){
        setMessage({text: t('Object type added successfully'), id:'',type:'success'});
        setTimeout(() => {
          history.push(`/objects?selectedTab=${data?.data?.createObjectType?.result}`);
        }, 2000);
      }else if(data && data?.data?.createObjectType?.statusCode === StatusCode.DUPLICATE_ENTRY) {
        setMessage({text: `${data?.data?.createObjectType?.statusMessage}`, id:'',type:'danger'});
      }
    }catch(e) {
      console.log('error');
    }finally {
      setButtonDisabled(false);
    }
  };

  const handleDeleteProperty = (deleteId: number) => {
    setProperties(prevArray => {
      return prevArray.filter((item, index) => index !== deleteId);
    });
  };

  const handleDeleteSelectOption = (deleteIndex: number) => {
    const newArray = [...selectOptions];
    if (deleteIndex >= 0 && deleteIndex < newArray.length) {
      newArray.splice(deleteIndex, 1);
    } else {
      console.error('Invalid index provided.');
    }
    setSelectOptions(newArray);
  };

  const handleAddNewOption = () => {
    if((newOption).trim() === '')
      setMessage({text: t('Option field cannot be empty.'), id:'',type:'danger'});
    else if(selectOptions.includes(newOption)) {
      setMessage({text: t('This option is already present in the list.'), id:'',type:'danger'});
    }else {
      setSelectOptions([...selectOptions, newOption]);
      setNewOption('');
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setKey('');
    setDisplayName('');
    setIsRequired(false);
    setPropertyType('');
    setMaxImages(0);
    setMaxTextLength(255);
    setIsTextOnly(false);
    setRows(14);
    setColumns(8);
    setSelectOptions([]);
    setNewOption('');
    setPropertyEditId(undefined);
  };

  const handleEditProperty = (editId: number) => {
    setIsEditPropertyMode(true);
    setModalOpen(true);
    const propertyToEdit = properties[editId];
    setKey(propertyToEdit.key);
    setDisplayName(propertyToEdit.displayName);
    setIsRequired(propertyToEdit.isRequired);
    setPropertyType(propertyToEdit.propertyType);
    setMaxImages(propertyToEdit.maxImages);
    setMaxTextLength(propertyToEdit.maxTextLength);
    setIsTextOnly(propertyToEdit.isTextOnly);
    setIsUnique((propertyToEdit.isUnique) as boolean);
    setRows(propertyToEdit.rows);
    setFeatureExtraction(propertyToEdit.featureExtraction as string);
    setColumns(propertyToEdit.columns);
    setSelectOptions(propertyToEdit.selectOptions === undefined ? [] : propertyToEdit.selectOptions);
    setNewOption('');
  };

  const generateRowData = useCallback(() =>
  {
    if(properties?.length === 0) {
      return [];
    }else {
      return properties?.map((item: any, index: number)=>(
        { 
          id: index,
          key: item.key,
          displayName: item?.displayName,
          propertyType: item?.propertyType,
          isRequired: item.isRequired,
          maxTextLength: item.maxTextLength,
          maxImages: item.maxImages,
          options: item.selectOptions,
          rows: item.rows,
          columns: item.columns,
          isUnique: item.isUnique
        }
      ));
    }
  },[properties]);

  const tableColumns: GridColDef[] =
    [
      { 
        field: 'displayName',
        headerName: t('Display Name '),
        flex: 1.2,
        headerAlign: 'left',
        align: 'left',
        sortable: false,
        renderCell: (params: any) => {
          return (
            <DisplayName title={params?.row?.displayName}>{params?.row?.displayName}</DisplayName>
          );
        }
      },
      { 
        field: 'key',
        headerName: t('Key '),
        flex: 1.2,
        headerAlign: 'left',
        align: 'left',
        sortable: false,
        renderCell: (params: any) => {
          return (
            <PropertyKey title={params?.row?.key}>{params?.row?.key}</PropertyKey>
          );
        }
      },
      { 
        field: 'propertyType',
        headerName: t('Type'),
        flex: 0.7,
        headerAlign: 'left',
        align: 'left',
        sortable: false,
        renderCell: (params: any) => {
          return (
            <PropertyType>{params?.row?.propertyType}</PropertyType>
          );
        }
      },
      { 
        field: 'isMandatory',
        headerName: t('Is Mandatory'),
        flex: 1,
        headerAlign: 'left',
        align: 'left',
        sortable: false,
        renderCell: (params: any) => {
          return (
            <PropertyType>{params?.row?.isRequired ? 'Yes': 'No'}</PropertyType>
          );
        }
      },
      {
        field: 'otherProperties',
        headerName: t('Other Properties'),
        flex: 2,
        headerAlign: 'left',
        align: 'left',
        sortable: false,
        renderCell: (params: any) => {
          return (
            <OtherPropertiesWrapper>
              {params?.row?.maxTextLength !== undefined && <div>{t('Max Characters')}:  {params?.row?.maxTextLength}</div>}
              {params?.row?.maxImages !== undefined && <div>{t('Max Images')}: { params?.row?.maxImages}</div>}
              {params.row.options !== undefined && <div>{t('Options')}: {params.row.options.join(', ')}</div>}
              {params.row.rows !== undefined && <div>{t('Rows')}: {params.row.rows}</div>}
              {params.row.columns !== undefined && <div>{t('Columns')}: {params.row.columns}</div>}
              {params.row.isUnique !== undefined && <div>{t('Is Unique')}: {params.row.isUnique ? 'Yes': 'No'}</div>}
            </OtherPropertiesWrapper>
          );
        }
      },
      {
        field: 'actions',
        headerName: t('Actions'),
        flex: 1.5,
        headerAlign: 'left',
        align: 'left',
        sortable: false,
        renderCell: (params: any) => {
          return (
            <ActionsWrapper>
              <ActionIcon disabled={isDefault} onClick={() => {setPropertyEditId(params.row.id);handleEditProperty(params.row.id);}}>
                <Icon icon='Edit' color='primary' />
              </ActionIcon>
              <ActionIcon disabled={isDefault} onClick={() => {setDeletePropertyId(params.row.id); setDeletePropertyPopUp(true);}}>
                <Icon icon='Delete' color='danger' />
              </ActionIcon>
            </ActionsWrapper>
          );
        }
      }
    ];

  if(loading && isEditObjectType) {
    return  <LoadingContainer>
      <CircularProgress />
      <Loading>{t('Loading')}</Loading>
    </LoadingContainer>;
  } 

  return (
    <Container>
      {
        message && <AlertBar message={message.text} type={message.type as IconType} setMessage={setMessage} />
      }
      <NewPropertyWrapper>
        <NewObjectNameWrapper>
          <Input type='text' id='objectName' label={t('Object Name')} value={objectName} onChange={(e) => setObjectName(e.target.value)} />
        </NewObjectNameWrapper>
        <NewObjectNameWrapper>
          <ButtonWithIcon icon='Download' position='right' variant='primary' onClick={() => history.push('/objects/importobjects/newtype')}>{t('Import Type')}</ButtonWithIcon>
          <ButtonWithIcon variant='primary' position='right' disabled={isDefault} icon='Add' onClick={() => {setEnableAddFields(!enableAddFields); setModalOpen(true);}}>{t('property')}</ButtonWithIcon>
          <ButtonWithIcon icon='Swap' position='right' variant='primary' disabled={isDefault || properties?.length <= 1} onClick={() => {setEnablePreview(true);}}>{t('Layout')}</ButtonWithIcon>
        </NewObjectNameWrapper>
      </NewPropertyWrapper>
      <SectionWrapper>
        <Modal isModalOpen={modalOpen} closeModal={handleCloseModal} width='500px'>
          <NewFieldOptionsWrapper>
            <KeyWrapper>
              <Input type='text' label={t('Key')} disabled={(editObjectTypeId !== undefined && propertyEditId) || (editObjectTypeId !== undefined && propertyEditId === 0)} value={key} id='Key' onChange={(e: React.ChangeEvent<HTMLInputElement>) => setKey(e.target.value)} />
            </KeyWrapper>
            <DisplayNameWrapper>
              <Input id="displayName" label={t('Display Name ')} type='text' value={displayName} onChange={(e) => setDisplayName(e.target.value)} />
            </DisplayNameWrapper>
            <SelectFieldWrapper>
              <SelectField placeholder={t('Select property type')} label={t('Property Type')} defaultValue={propertyType} options={INPUT_TYPE_OPTIONS} onChange={(e) => setPropertyType(e)} />
            </SelectFieldWrapper>
            {propertyType === PROPERTY_TYPE.Text && (
              <PropertyTypeWrapper>
                <Input type='number' label={t('Max Characters')} id='maxTextLength' value={maxTextLength} onChange={(e) => setMaxTextLength(parseInt(e.target.value))} />
                <CheckboxWrapper>
                  <SingleCheckboxWrapper>
                    <Label labelText={t('Text Only')} />
                    <Checkbox id="isTextOnly" checked={isTextOnly as boolean} onChangeCallback={(e) => setIsTextOnly(e)} />
                  </SingleCheckboxWrapper>
                  <SingleCheckboxWrapper>
                    <Label labelText={t('Is Mandatory')} />
                    <Checkbox id="isRequired" checked={isRequired} onChangeCallback={(e) => setIsRequired(e)} />
                  </SingleCheckboxWrapper>
                </CheckboxWrapper>
                <IsUniqueFieldWrapper>
                  <Label labelText={t('Is Unique')} />
                  <Checkbox id="isUnique" checked={isUnique} onChangeCallback={() => {setIsUnique(!isUnique); setIsContainUniqueProperty(true);}} />
                </IsUniqueFieldWrapper>
              </PropertyTypeWrapper>
            )}
            {
              propertyType === PROPERTY_TYPE.Image &&
              <PropertyTypeWrapper>
                <SingleCheckboxWrapper>
                  <Label labelText={t('Is Mandatory')} />
                  <Checkbox id="isRequired" checked={isRequired} onChangeCallback={(e) => setIsRequired(e)} />
                </SingleCheckboxWrapper>
                <FeatureExtractionWrapper>
                  <SelectField label={t('Feature Extractor')} defaultValue={featureExtraction} options={FEATURE_REGISTRATION_OPTION} onChange={(e) => setFeatureExtraction(e)} />
                </FeatureExtractionWrapper>
              </PropertyTypeWrapper>
            }
            {propertyType === PROPERTY_TYPE.MultiImage && (
              <PropertyTypeWrapper>
                <SingleCheckboxWrapper>
                  <Label labelText={t('Is Mandatory')} />
                  <Checkbox id="isRequired" checked={isRequired} onChangeCallback={(e) => setIsRequired(e)} />
                </SingleCheckboxWrapper>
                <MaxImagesWrapper>
                  <ComponentLabel>{t('Max Images')}</ComponentLabel>
                  <Slider
                    marks={MAX_IMAGES_VALUES}
                    defaultValue={2}
                    getAriaValueText={(value: number) => value.toString()}
                    valueLabelDisplay="auto"
                    value={maxImages || 5}
                    step={1}
                    min={2}
                    max={10}
                    onChange={(event, newValue) => setMaxImages(newValue as number)} />
                </MaxImagesWrapper>
              </PropertyTypeWrapper>
            )}
            {propertyType === PROPERTY_TYPE.TextArea && (
              <PropertyTypeWrapper>
                <SingleCheckboxWrapper>
                  <Label labelText={t('Is Mandatory')} />
                  <Checkbox id="isRequired" checked={isRequired} onChangeCallback={(e) => setIsRequired(e)} />
                </SingleCheckboxWrapper>
                <PropertyTypeWrapper>
                  <Input label={t('Rows')} type='number' id='rows' value={rows} onChange={(e) => setRows(parseInt(e.target.value))} />
                  <Input label={t('columns')} type='number' id='columns' value={columns} onChange={(e) => setColumns(parseInt(e.target.value))} />
                </PropertyTypeWrapper>
              </PropertyTypeWrapper>
            )}
            {propertyType === PROPERTY_TYPE.SelectField && (
              <PropertyTypeWrapper>
                <SingleCheckboxWrapper>
                  <Label labelText={t('Is Mandatory')} />
                  <Checkbox id="isRequired" checked={isRequired} onChangeCallback={(e) => setIsRequired(e)} />
                </SingleCheckboxWrapper>
                <SelectFieldNewOptionWrapper>
                  <SelectInputWrapper>
                    <Input type='text' label={t('New Option')} id="newOption" value={newOption} onChange={(e) => setNewOption(e.target.value)}/>
                  </SelectInputWrapper>
                  <ButtonWithIcon icon='Plus' variant='primary' onClick={handleAddNewOption}>{t('Option')}</ButtonWithIcon>
                </SelectFieldNewOptionWrapper>
                <SelectOptionsWrapper>
                  {selectOptions?.map((option, index) => (
                    <AllOptionsWrapper>
                      <IndividualUserOptionsForSelect key={index} style={{ backgroundColor: 'lightblue' }}>
                        {option}
                      </IndividualUserOptionsForSelect>
                      <CloseIconWrapper onClick={() => handleDeleteSelectOption(index)}>
                        <Icon icon='Close' size={20} />
                      </CloseIconWrapper>
                    </AllOptionsWrapper>
                  ))}
                </SelectOptionsWrapper>
              </PropertyTypeWrapper>
            )}
            <AddPropertyButtonWrapper>
              <Button variant='primary' onClick={handleAddProperty}>{isEditPropertyMode ? t('saveText'): t('Add')}</Button>
            </AddPropertyButtonWrapper>
          </NewFieldOptionsWrapper>
        </Modal>
        <AllPropertiesWrapper>
          <DataGrid rows={generateRowData()} localeText={localizationTexts} columns={tableColumns} getRowHeight={() => 62} />
        </AllPropertiesWrapper>
      </SectionWrapper>  
      <AddObjectTypeWrapper>
        <ButtonWithLoading loading={buttonDisabled} variant='primary' disabled={(lodash.isEqual(properties, initialProperties.properties) && objectName === initialProperties.name) || buttonDisabled || isDefault} onClick={isEditObjectType ? handleEditObjectType : handleAddNewObjectType}>{t('saveText')}</ButtonWithLoading>
        <Button variant='secondary' onClick={() => history.push(isEditObjectType ? `/objects?selectedTab=${editObjectTypeId}`: '/objects')} >{t('cancleText')}</Button>
      </AddObjectTypeWrapper>
      {isEditObjectType && <AdvancedOptionsWrapper>
        <Heading>{t('Advanced')}</Heading>
        <VerticalLine />
        <ConfirmationModal modalOpen={deleteObjectTypeConfirmation} setModalOpen={setDeleteObjectTypeConfirmation} onSubmit={()=>handleDeleteObjectType()} hasConfirmationField={true} placeholder={t('Enter object type name')} textToCheck={objectName}
          titleText={t('Delete object type')  + `: ${objectName}?`} confirmDescription={t('Are you sure you want to delete this Object type? Please enter the object type name to confirm.')} noteText={t('Once deleted this action cannot be undone.')} />
        <DeleteWrapper><ButtonWithLoading loading={deleteObjectLoading} variant='danger' onClick={() => setDeleteObjectTypeConfirmation(true)}>{t('Delete')}</ButtonWithLoading></DeleteWrapper>
      </AdvancedOptionsWrapper>}
      {
        enablePreview && <Modal isModalOpen={enablePreview} closeModal={setEnablePreview} width='1100px'>
          <PreviewAddObject objectName={objectName} properties={properties} setProperties={setProperties} setEnablePreview={setEnablePreview} />
        </Modal>
      }
      { deletePropertyPopUp &&
            <ConfirmationModal modalOpen={deletePropertyPopUp} setModalOpen={setDeletePropertyPopUp} onSubmit={()=>handleDeleteProperty(deletePropertyId as number)} hasConfirmationField={false} 
              titleText={t('Delete Property?')} confirmDescription={t('Are you sure you want to delete this property?')} noteText={t('Once deleted this action cannot be undone.')} />
      }
    </Container>
  );
};

export default AddObjectType;