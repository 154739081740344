import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgObject = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M6 14h4.8v1.333H6V14Zm0 2.667h8V18H6v-1.333Z"
        fill={props.color}
      />
      <path
        d="M19.5 3h-15A1.502 1.502 0 0 0 3 4.5v15A1.501 1.501 0 0 0 4.5 21h15a1.502 1.502 0 0 0 1.5-1.5v-15A1.502 1.502 0 0 0 19.5 3Zm-6 1.5v3h-3v-3h3Zm-9 15v-15H9V9h6V4.5h4.5v15h-15Z"
        fill={props.color}
      />
    </svg>
  );
};
export default SvgObject;
