import { IBreadcrum, IStream } from 'interface';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FaCloudUploadAlt, FaFolder } from 'react-icons/fa';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_STREAM } from 'api_configs/queries';
import { useHeader } from 'contexts/HeaderContext';
import CircularProgressBar from '../../components/CircularProgressbar';
import Button from 'components/Button';
import ProgressBar from '../../components/ProgressBar';
import SuccessIcon from '../../svgs/SuccessUpload.png';
import useProgress from '../../hooks/useProgress';
import Thumbnail from 'components/Thumbnail';
import { ScrollBarCss } from 'CommonStyles';

const VideoConfigurationsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 20px;
`;

const SelectedCameraVideoConfigurations = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  gap: 20px;
  border-color: #007bff;
`;

const Dropzone = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  width: 700px;
  height: 500px;
  margin-bottom: 20px;
  transition: border-color 0.3s;
  background: #f5f7ff;
  ${(props) => props.isActive && `
    border-color: #007bff;
  `}
`;

const FilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 350px;
  height: 450px;
  overflow: auto;
  margin-bottom: 10px;
  ${ScrollBarCss};
`;

const ListContainer = styled.div<{visibility: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  width: 35%;
  height: 500px;
  visibility: ${({visibility}) => visibility ? 'visible': 'hidden'};
  padding: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const FileInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-bottom: 1px solid #cccccc;
  height: 50px;
`;

const FileName = styled.span`
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const FileType1 = styled.span`
  font-size: 14px;
  color: #888888;
`;

const DropzoneInnerContainner = styled.div`
  border: 2px dashed #cccccc;
  padding: 50px;
  margin-bottom: 20px;
`;

const Info = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const Status = styled.div`
  font-size: 16px;
  color: #999;
  margin-left: 10px;
  text-align: center;
  margin-top: 20px; 
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  gap: 20px;
  width: 700px;
  height: 500px;
  width: 700px;
  height: 500px;
  background: #f5f7ff;
  margin-left: 20px;
`;

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  gap: 20px;
  width: 700px;
  height: 500px;
  background: #f5f7ff;
  margin-left: 20px;
`;

const SuccessTitle = styled.div`
  width: 387px;
  height: 30px;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  font-size: 20px;
  line-height: 1.5;
  text-align: center;
  color: #5a6269;
`;

const SelectedFiles = styled.div`
  width: 387px;
  height: 30px;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  font-size: 20px;
  line-height: 1.5;
  text-align: center;
`;


const SuccessMessage = styled.div`
  width: 423px;
  height: 50px;
  font-size: 14px;
  font-style: italic;
  line-height: 1.79;
  text-align: center;
  color: #8b9196;
`;

const SuccessImage = styled.img`
  width: 100px;
  height: 100px;
`;

const ThumbnailWrapper = styled.div``;

type FileType = 'image' | 'video' | 'unknown';

interface PreviewFile {
  file: File;
  fileType: FileType;
  preview: string;
}

interface IParams {
  instanceId: string;
}

const Folder = () => {
  const {t} = useTranslation(['common']);
  const {instanceId}: IParams = useParams();
  const [files, setFiles] = useState<PreviewFile[]>([]);
  const [streamDetails, setStreamDetails] = useState<IStream>();
  const {data:getStreamData } = useQuery(GET_STREAM, { variables: {streamId: instanceId} });
  const {updateHeaderTitle} = useHeader();
  const [newUpload, setNewUpload] = useState(true);
  const [loading, setLoading] = useState(false);
  const { progress, startProgress, resetProgress } = useProgress(files.length);

  useEffect(()=>{
    const cameraPageBreadCrumb: IBreadcrum[] = [
      {label: t('Folder'), to: '/cameras'},
      {label: streamDetails?.name as string, to: `/cameras/${instanceId}` as string}
    ];
    updateHeaderTitle('Upload to folder', <FaFolder size={20} color='white'/>, cameraPageBreadCrumb);
  },[updateHeaderTitle, streamDetails, t, instanceId]);

  useEffect(()=>{
    const result: IStream = getStreamData?.getStream?.result !== null &&  getStreamData?.getStream?.result !== undefined ? getStreamData?.getStream?.result : null;
    if(result !== null){
      setStreamDetails(result);
    }
  },[getStreamData]);

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (fileRejections && fileRejections.length > 0) {
      console.error('File rejected:', fileRejections[0].errors.map(error => error.message).join(', '));
    }
    if (acceptedFiles && acceptedFiles.length > 0) {
      handleFiles(acceptedFiles);
    }
  };

  const handleFiles = (selectedFiles: File[],) => {
    const newFiles: PreviewFile[] = selectedFiles.map((file) => {
      const fileType = file.type.startsWith('image') 
        ? 'image' 
        : file.type.startsWith('video') 
          ? 'video' 
          : 'unknown';
      const preview = URL.createObjectURL(file);
      return { file, fileType, preview };
    });
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop,  accept: {
    'image/*': ['.jpeg', '.jpg', '.png', '.gif', '.bmp', '.webp'],
    'video/*': ['.mp4', '.avi', '.mkv', '.mov', '.wmv', '.flv', '.webm']
  } });

  const handleFileUpload = () => {
    setLoading(true);
    setNewUpload(false);
    startProgress();
    if(progress === 100) {
      setLoading(false);
      resetProgress();
    }
  };

  const handleStartOver = useCallback(() => {
    setNewUpload(true);
    setFiles([]);
  }, []);

  return (
    <VideoConfigurationsWrapper>
      {newUpload ? 
        <SelectedCameraVideoConfigurations>
          <Dropzone {...getRootProps()} isActive={isDragActive}>
            <input {...getInputProps()} />
            <DropzoneInnerContainner>
              <FaCloudUploadAlt size={50} />
              {isDragActive ? (
                <p>{t('Drop the files here ...')}</p>
              ) : (
                <p>{t('Drag and drop image or video files here, or click to select files')}</p>
              )}
            </DropzoneInnerContainner>
    
            {files.length > 0 ? 
              <>
                <Button variant='primary'>{t('Add more files')}</Button> 
              </>
              : <Button variant='primary'>{t('Add files')}</Button> }
          </Dropzone>
          <ListContainer visibility={files.length > 0}>
            <SelectedFiles>{t('Selected Files')}</SelectedFiles>
            <FilesContainer>
              {files.map((file, index) => (
                <FileInfo key={index} title={file.file.name}>
                  <ThumbnailWrapper>
                    <Thumbnail src={file.preview} size={40} media={file.fileType} />
                  </ThumbnailWrapper>
                  <FileName>{file.file.name}</FileName>
                  <FileType1>{file.fileType}</FileType1>
                </FileInfo>
              ))}
            </FilesContainer>
            <ButtonContainer>
              <Button variant='primary' onClick={handleFileUpload}>{t('Ready to Upload') + ' ' + files.length + ' ' + t('files')}</Button> 
            </ButtonContainer>
          </ListContainer>
        </SelectedCameraVideoConfigurations> : <>
          {loading && progress < 100 ?
            <LoadingContainer>
              <Info>
                <CircularProgressBar progress={progress} />
                <ProgressBar progress={progress} />
                {/* <Status>{t(`Uploading ${currentFile}/${files.length}`)} - {progress}%</Status> */}
                <Status>{t('Please do not navigate away from this page while uploading. We will let you know once this has been completed.')}</Status>
              </Info>
            </LoadingContainer>: 
            <SuccessContainer>
              <SuccessImage src={SuccessIcon} alt='success' />
              <SuccessTitle>{t('Upload Completed')}</SuccessTitle>
              <SuccessMessage>{t('We have successfully uploaded 14 of the 14 videos selected. You may now close or navigate away from this page.')}</SuccessMessage>
              <ButtonContainer>
                <Button variant='primary'>{t('Analyse Uploaded Files')}</Button>
                <Button variant='primary' onClick={handleStartOver}>{t('Start New Upload')}</Button>
              </ButtonContainer>
            </SuccessContainer>}
        </>}
    </VideoConfigurationsWrapper>
  );
};

export default Folder;