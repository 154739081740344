import { useClickOutside } from 'hooks/useClickOutside';
import React, {useState, useCallback, useRef} from 'react';
import styled from 'styled-components';

const Container = styled.div<{width: string; disabled: boolean}>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  cursor: ${({disabled}) => disabled ? 'not-allowed': 'pointer'};
  width: ${({width})=>width};
`;

const Label = styled.div`
  font-size: 16px;
`;

const Wrapper = styled.div<{disabled: boolean}>`
  border-radius: 5px;
  border: 1px solid lightgrey;
  padding: 5px 10px;
  min-height: 40px;
  display: flex;
  position: relative;
  align-items: center;
  pointer-events: ${({disabled}) => disabled ? 'none': 'auto'};
  background-color: ${({disabled}) => disabled ? '#FAFAFA' : '#FFF'};
`;

const CheckboxGroup = styled.div`
  position: absolute;
  top: 90%;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  background-color: #F6F7F9;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  width: 100%;
  z-index: 9;
`;

const CheckboxLabel = styled.label`
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  :hover{
    background-color: #ffffff;
  }
`;

const SelectedFieldsWrapper = styled.div`
  display: flex;
  gap: 10px;
  overflow-x: auto; 
  flex-wrap: wrap;
  width: calc(100% - 30px);
`;

const Checkbox = styled.input`
  margin-right: 4px;
  cursor: pointer;
`;

const SelectedItem = styled.div`
  font-size: 12px;
  padding: 5px;
  background-color: #efeeee;
  border: 1px solid #435FCA;
  border-radius: 10px;
  white-space: nowrap;
`;

const PlaceHolder = styled.div`
  font-style: italic;
  font-size: 14px;
  color: ${({theme}) => theme.text.secondaryText};
`;

const SelectArrow = styled.div<{isExpanded: boolean}>`
  position: absolute;
  top: ${({ isExpanded }) => (isExpanded ? '60%' :  '47%' )};
  right: 15px;
  transform: translateY(-50%) rotate(${({ isExpanded }) => (isExpanded ? '136deg': '315deg')});
  pointer-events: none;
  width: 10px;
  height: 10px;
  border-left: 2px solid #555;
  border-bottom: 2px solid #555;
  transition: transform 0.2s;
  @media (max-width: 768px) {
    top: ${({ isExpanded }) => (isExpanded ? '56%' : '44%' )};
    left: 416px;
  }
`;

interface CheckboxOption {
  name: string;
  value: string | number;
}

interface MultipleSelectProps {
  label?: string;
  options: CheckboxOption[];
  selectedValues: string[];
  onChange: (selectedValues: string[]) => void;
  width?: string,
  placeholder?: string,
  disabled?: boolean,
  showSelectedOptions?: boolean;
  selectedPlaceholder?: string;
  emptyListPlaceholder?: string;
}

const MultipleSelect: React.FC<MultipleSelectProps> = ({label='',emptyListPlaceholder='data not found', options, placeholder='select',selectedPlaceholder='Selected', showSelectedOptions=true, selectedValues, onChange, width='400px', disabled=false}) => {
  const [isOpen, setIsOpen] = useState(false);
  const optionRef = useRef<HTMLDivElement>(null);
  useClickOutside(optionRef, () => setIsOpen(false));

  const handleSelectClick = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = useCallback((value: string) => {
    const updatedSelected = selectedValues.includes(value)
      ? selectedValues.filter(val => val !== value)
      : [...selectedValues, value];
    onChange(updatedSelected);
  },[selectedValues, onChange]);

  return (
    <Container width={width} ref={optionRef} disabled={disabled}>
      {label && <Label>{label}</Label>}
      <Wrapper onClick={handleSelectClick} disabled={disabled}>
        {selectedValues?.length === 0 ? 
          <PlaceHolder>{placeholder}</PlaceHolder>:
          <>
            {showSelectedOptions ? 
              <SelectedFieldsWrapper>
                {options.filter(option => selectedValues?.includes(option.value.toString())).map((item)=>(
                  <SelectedItem>{item.name}</SelectedItem>
                ))}
              </SelectedFieldsWrapper>:
              <PlaceHolder>{selectedPlaceholder}</PlaceHolder>
            }
          </>
        }
        <SelectArrow isExpanded={isOpen} />
      </Wrapper>
      { isOpen && 
      <CheckboxGroup>
        {options.length > 0 ? 
          <>
            {options.map(option => (
              <CheckboxLabel key={option.value} title={option.name}>
                <Checkbox
                  type="checkbox"
                  checked={selectedValues?.includes(option.value.toString())}
                  onChange={() => handleCheckboxChange(option.value.toString())}
                />
                {option.name}
              </CheckboxLabel>
            ))}
          </> : 
          <CheckboxLabel>{emptyListPlaceholder}</CheckboxLabel>
        }
      </CheckboxGroup>}
    </Container>
  );
};

export default MultipleSelect;
