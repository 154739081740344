import {  useQuery } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import { GET_DASHBOARD_CHARTS } from 'api_configs/queries';
import Icon from 'components/Icon';
import { IconType, SUPERSET_REFRESH_INTERVAL, PERMISSION_CODE, SUPERSET_URL } from '../constants';
import { useHeader } from 'contexts/HeaderContext';
import { IAlertMessageType } from 'interface';
import React, { useEffect, useState, useRef, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import AlertBar from 'components/AlertBar';
import Modal from 'components/Modal';
import Switch from 'components/Switch';
import { AiOutlineBarChart } from 'react-icons/ai';
import { MdOutlineHideImage } from 'react-icons/md';
import TokenService from 'api_configs/tokenService';
import Alerts from 'components/Alerts';
import Config from 'configs/config.json';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px 20px 5px 20px;
`;

const SpinnerBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const Label = styled.span``;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 10px 40px;
  align-items: center;
`;

const SwitchLabel = styled.div`
  min-width: 100px;
  margin-bottom: auto;
  font-size: 20px;
`;

const EnableCameraWrapper = styled.div`
  display: flex;
  column-gap: 20px;
`;

const DownloadAlertSettingLabel = styled.div`
  font-size: 22px;
`;

const VisualizationContainer = styled.div<{isVisible: boolean}>`
  width: ${({ isVisible }) => (isVisible ? '100%' : '0')};
  height: 300px;
  max-height: ${({ isVisible }) => (isVisible ? '300px' : '0')};
  border: 1px solid ${({theme})=>theme.divider};
  border-radius: 10px;
  font-size: 18px;
  display: flex;
  overflow: hidden;
  transition: max-height 0.6s ease-in-out;
`;

const VisualizationWrapper = styled.div`
  margin: 0 0 5px 10px;
  display: flex;
  z-index: 9;
`;

const VisualizationToggleWrapper = styled.span<{isAlertIconPresent: boolean}>`
  position: absolute;
  top: 14px;
  z-index: 9;
  right: ${({isAlertIconPresent}) => !isAlertIconPresent ? '116px' : '172px'};
  cursor: pointer;
`;

const AllChartOptionsWrapper = styled.div<{visualizationIconToggle: boolean, isAlertIconPresent: boolean}>`
  display: ${({visualizationIconToggle}) => visualizationIconToggle ? 'flex' : 'none'};
  flex-direction: column;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  position: absolute;
  background-color: ${({theme}) => theme.backgroundColor};
  border-radius: 5px;
  height: fit-content;
  width: 196px;
  right: ${({isAlertIconPresent}) => !isAlertIconPresent ? '122px' : '170px'};
  top: 40px;
  margin-top: 11px;
  z-index: 9;
`;

const Arrow = styled.div`
  width: 15px;
  height: 15px;
  background-color: ${({theme}) => theme.backgroundColor};
  position: absolute;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  right: 0;
  top: -8px;
  transform: rotate(45deg);
  border-top: 1px solid #DCE2E4;
  border-left: 1px solid #DCE2E4;
`;

const ChartOptionsWrapper = styled.div`
  display: flex;
  position: relative;
  width: 200px;
  height: 49px;
  cursor: pointer;
  background-color: #FFFFFF;
  border: 1px solid #DCE2E4;
  :hover{
    background-color: #EEFCFF;
  }
  padding: 2px 0 2px 2px;
  height: fit-content;
  z-index: 9;
`;

const ChartOption = styled.option`
  width: 108px;
  padding: 7px 0 7px 7px;
  margin: 5px 20px;
`;

const VisualizationIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PaginationWrapper = styled.div`
 display: flex;
 gap: 8px;
 height: 49px;
 padding-right: 22px
  > nav {
    margin: 0 auto;
  }
`;

const RefreshLabel = styled.div`
  font-size: 16px;
  margin: auto 2px auto 20px;
  width: 108px;
`;

const SelectOptionField = styled.select`
  border-radius: 8px;
  height: 35px;
  margin: auto;
  border: solid 1px #BFCDD2;
  padding: 0 6px 0 0px;
`;

const ChartsWrapper = styled.div`
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 10px;
  max-width: 1900px !important;
  display: flex;
  gap: 18px;
  overflow: auto;
`;

const SingleChartWrapper = styled.div`
  > iframe {
    border-radius: 10px;
  }
`;

const ChartTitle = styled.div`
  margin-bottom: 8px;
`;

const EmbeddedChart = styled.iframe<{isDailyAnalysis?: boolean}>`
  border: 0.8px solid lightgrey;
  @media only screen and (min-width: 1270px){
    width: ${({isDailyAnalysis}) => isDailyAnalysis ? '700px': '350px'};
  }
  @media only screen and (min-width: 1500px){
    width: ${({isDailyAnalysis}) => isDailyAnalysis ? '890px': '410px'};
  }
  @media only screen and (min-width: 1740px){
    width: ${({isDailyAnalysis}) => isDailyAnalysis ? '930px': '590px'};
  }
  @media only screen and (min-width: 1900px){
    width: ${({isDailyAnalysis}) => isDailyAnalysis ? '1050px': '635px'};
  }
  @media only screen and (min-width: 2100px){
    width: ${({isDailyAnalysis}) => isDailyAnalysis ? '1300px': '600px'};
  }
`;

const NoChartsMessage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface IChartsInfo {
  datasource: string,
  description: string | null,
  permalink: string,
  sliceId: number,
  sliceName: string,
  url: string,
  vizType: string
}

interface IDashboardInfo {
  id: number,
  title: string,
  url: string,
  chartsInfo: IChartsInfo[]
}

interface IDashboard {
  data: IDashboardInfo[]
}

const NewAlertsPage = () => {
  const {t} = useTranslation(['common']);
  const {updateHeaderTitle} = useHeader();
  const [isNewCharts, setIsNewCharts] = useState(false);
  const visualizationIconRef = useRef<HTMLInputElement>(null);
  const [isVisible, setIsVisible] = useState(JSON.parse(localStorage.getItem('isShowCharts') as string).allAlertsPage === true || false);
  const [dashboardCharts, setDashboardCharts] = useState<IDashboard | null>();
  const [isModalOpen,setIsModalOpen] = useState(false);
  const {data: getStoreChartsInfo, loading: dashboardChartsLoading, refetch: refetchCamerasCharts} = useQuery(GET_DASHBOARD_CHARTS, {variables: {pageName: 'alertspage', username: JSON.parse(localStorage.getItem('user_login_info') as string).username}, skip: !isVisible});
  const [downloadAlertsReqData, setDownloadAlertsReqData] = useState({image: true, video: false});
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'}); 
  const [visualizationIconToggle, setVisualizationIconToggle] = useState(false);
  const [supersetDataRefetchInterval, setSupersetDataRefetchInterval] = useState(localStorage.getItem('alertsChartsRefresh') as string || '15s');
  const history = useHistory();
  const viewChartsAccess = TokenService.hasAccess(PERMISSION_CODE.view_charts);
  const manageChartsAccess = TokenService.hasAccess(PERMISSION_CODE.manage_charts);
  const storeChartsAccess = TokenService.hasAccess(PERMISSION_CODE.store_charts);

  useEffect(() => {
    if(isNewCharts) {
      setTimeout(() => {
        setIsNewCharts(false);
      }, 2000);
    }
  }, [isNewCharts]);

  useEffect(() => {
    if(isVisible) {
      if(getStoreChartsInfo) {
        if(!dashboardChartsLoading) {
          setDashboardCharts(getStoreChartsInfo?.getStoredChartsInfo?.result);
        }
      }
    }
  }, [getStoreChartsInfo, isVisible, dashboardChartsLoading]);

  useEffect(() => {
    if(isVisible) {
      if(JSON.parse(localStorage.getItem('alertspage') as string)) {
        refetchCamerasCharts();
        localStorage.removeItem('alertspage');
      }
    }
  }, [refetchCamerasCharts, isVisible, isNewCharts]);

  useEffect(() => {
    const prevData = JSON.parse(localStorage.getItem('isShowCharts') as string);
    localStorage.setItem('isShowCharts', (JSON.stringify({...prevData, allAlertsPage: isVisible})));
  }, [isVisible]);

  useEffect(() => {
    if(isVisible) {
      const interval = setInterval(() => {
        refetchCamerasCharts();
      }, (Number(Number(supersetDataRefetchInterval.substring(0, supersetDataRefetchInterval.indexOf('s')))) * 1000));
  
      return () => {
        clearInterval(interval);
      };
    }
  }, [isVisible, refetchCamerasCharts, supersetDataRefetchInterval]);

  useEffect(()=>{
    updateHeaderTitle(t('Alerts'), 'Alert');
  },[updateHeaderTitle, t]);
  
  const handleImageSwitchChange = () => {
    const updateData = {...downloadAlertsReqData, image: !downloadAlertsReqData?.image};
    setDownloadAlertsReqData(updateData);
    localStorage.setItem('Download_Alert_Setting', JSON.stringify(updateData));
  };

  const handleVideoSwitchChange = () => {
    const updateData = {...downloadAlertsReqData, video: !downloadAlertsReqData?.video};
    setDownloadAlertsReqData(updateData);
    localStorage.setItem('Download_Alert_Setting', JSON.stringify(updateData));
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    localStorage.setItem('alertsChartsRefresh', supersetDataRefetchInterval);
  }, [supersetDataRefetchInterval]);

  const handleRefreshInterval = (value: string) => {
    setSupersetDataRefetchInterval(value);
  };

  const useOutSideClick = (ref: RefObject<HTMLDivElement>) => {
    useEffect(() => {
      function handleOutsideClick(event:MouseEvent) {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          setVisualizationIconToggle(false);
        }
      }
      document.addEventListener('click', handleOutsideClick);
      return () => {
        document.removeEventListener('click', handleOutsideClick);
      };
    }, [ref]);
  };

  useOutSideClick(visualizationIconRef);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
    setVisualizationIconToggle(false);
  };

  const toggleVisualizationOptions = () => {
    setVisualizationIconToggle(!visualizationIconToggle);
  };

  return (
    <MainContainer> 
      <VisualizationWrapper ref={visualizationIconRef}>
        <VisualizationToggleWrapper onClick={toggleVisualizationOptions} isAlertIconPresent={Config.fetchLatestAlertAPIEnable}>     
          <AiOutlineBarChart size={24}/>
        </VisualizationToggleWrapper>
        <AllChartOptionsWrapper isAlertIconPresent={Config.fetchLatestAlertAPIEnable} visualizationIconToggle={visualizationIconToggle}>
          <Arrow /> 
          <ChartOptionsWrapper onClick={toggleVisibility}>
            <ChartOption>{isVisible ? t('Hide Charts'):  t('Show Charts')}</ChartOption>
            <VisualizationIconWrapper>
              {
                isVisible ? <MdOutlineHideImage size={24} /> : <AiOutlineBarChart size={24}/>
              }
            </VisualizationIconWrapper>
          </ChartOptionsWrapper>
          { storeChartsAccess && <ChartOptionsWrapper onClick={() => history.push('/alertspage/selectcharts')}>            
            <ChartOption>{t('Import Charts')}</ChartOption>
            <Icon icon='Download' />
          </ChartOptionsWrapper>}
          { manageChartsAccess && <ChartOptionsWrapper onClick={() => window.open(dashboardCharts?.data[0]?.url, '_blank')}>
            <ChartOption>{t('Manage Charts')}</ChartOption>
            <Icon icon='Settings' />
          </ChartOptionsWrapper>}
          <ChartOptionsWrapper>
            <PaginationWrapper>
              <RefreshLabel>{t('Refesh Interval')}</RefreshLabel>
              <SelectOptionField
                onChange={(e) => handleRefreshInterval(e.target.value)}
                defaultValue={supersetDataRefetchInterval}
                value={supersetDataRefetchInterval}
              >
                {
                  SUPERSET_REFRESH_INTERVAL.map((size: string, index: number) => <option key={index} value={size}>{size}</option>)
                }
              </SelectOptionField>
            </PaginationWrapper>  
          </ChartOptionsWrapper>
        </AllChartOptionsWrapper>
      </VisualizationWrapper>
      <VisualizationContainer isVisible={isVisible}>
        {
          viewChartsAccess && dashboardCharts ? dashboardCharts?.data[0]?.chartsInfo?.length > 0 ? <ChartsWrapper>
            {dashboardCharts.data.map((dashboardData) => (
              dashboardData.chartsInfo.map((chart) => (
                <SingleChartWrapper key={chart.sliceId}>
                  <ChartTitle>{chart.sliceName}</ChartTitle>
                  <EmbeddedChart isDailyAnalysis={chart.sliceId === 5} title={chart.sliceName} src={SUPERSET_URL + chart?.permalink?.split(':9097')[1]} height={250} />
                </SingleChartWrapper>
              ))
            ))}
          </ChartsWrapper>: <NoChartsMessage>{t('Oops! It looks like you have not selected any graphs yet. Please choose the graphs you would like to have on alerts page.')}</NoChartsMessage> :      
            dashboardChartsLoading && viewChartsAccess && <SpinnerBox>
              <CircularProgress />
              <Label>{t('Loading...')}</Label>
            </SpinnerBox>
        }
        {
          viewChartsAccess && dashboardCharts === null && <NoChartsMessage>{t('Oops! It looks like you have not selected any graphs yet. Please choose the graphs you would like to have on alerts page.')}</NoChartsMessage>
        }
        {
          !viewChartsAccess && <NoChartsMessage>{t('Unfortunately, you do not have permission to view these charts at this time. Please contact your administrator for assistance.')}</NoChartsMessage>
        }
      </VisualizationContainer>
      {message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
      { isModalOpen &&
       <Modal isModalOpen={isModalOpen} width='32vw' closeModal={handleCloseModal}>
         <ModalContent>
           <DownloadAlertSettingLabel>{t('Download Alert Setting')}:</DownloadAlertSettingLabel>
           <EnableCameraWrapper>
             <SwitchLabel>{t('Include Images')}:</SwitchLabel>
             <Switch checked={downloadAlertsReqData.image} onChange={handleImageSwitchChange}/>
           </EnableCameraWrapper>
           <EnableCameraWrapper>
             <SwitchLabel>{t('Include Videos')}:</SwitchLabel>
             <Switch checked={downloadAlertsReqData.video} onChange={handleVideoSwitchChange}/>
           </EnableCameraWrapper>
         </ModalContent>
       </Modal>
      }
      <Alerts isCameraPageAlerts={false} isSearchQuery={true} showSingleAlertDetailsPanel/>
    </MainContainer>
  );
};

export default NewAlertsPage;
