import AlertBar from 'components/AlertBar';
import Icon from 'components/Icon';
import Input from 'components/Input';
import { IReducerActions } from 'components/LineUI/LineReducer';
import { IconType, LineUIType, StatusCode, ZONE_LINEUI_CODES } from '../../constants';
import { IAlertMessageType, IFloorPlanConfigs, IFloorPlanConfigsRes, IStream } from 'interface';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Config from '../../configs/config.json';
import { getLineUIColor, pointInPolygon } from 'utils/utils';
import { AddNewLineWrapper, HorizontalDivider, LabelWrapper, LineDetailContainer, LineNameWrapper, LinesContainer, LinesListContainer } from '../../pages/configurations/algorithm-config/algoConfigStyles';
import { useQuery } from '@apollo/client';
import { GET_ALL_STREAMS } from 'api_configs/queries';
import ZoneCameraList from 'pages/settings/ZoneCameraList';
import { GridRowSelectionModel } from '@mui/x-data-grid';

const NoAreaData = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 100%;
  border-radius: 5px;
  border: 1px solid #D0D7F2;
  min-height: 310px;
  min-width: 600px;
`;

const NoAreaText = styled.div`
  max-width: 60%;
  font-size: 14px;
  color: ${({theme})=>theme.text.secondaryText};
  text-align: center;
`;

const AddNewAreaButton = styled.div`
  background: linear-gradient(to top, #1538BD, #7287D7);
  color: #FFFFFF;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
`;

const PointsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
`;

const PointsConfig = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 220px;
`;

const SelectOptionsWrapper = styled.div`
  display: flex;
  gap: 14px;
  flex-wrap: wrap;
  align-items: center;
  padding: 5px;
  width: 450px;
  height: 40px;
  border: 1px solid rgb(245, 245, 245);
  border-radius: 5px;
`;

const IndividualUserOptionsForSelect = styled.div`
  width: fit-content;
  padding: 3px 10px 5px 10px;
  background-color: #f7f7f7;
  border: 1px solid rgb(195 195 195);
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
`;

const AllOptionsWrapper = styled.div`
  position: relative;
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  background-color: #DFDFDF;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  top: -6px;
  right: -6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TextDiv = styled.div`
  font-size: 14px;
  color: #636363;
  flex-grow: 1;
  text-align: center;
`;

const LineName = styled.div`
  width: 70px;
  white-space: nowrap;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Label = styled.div`
  font-size: 14px;
`;

const ConfigurationForm = styled.div`
  display: flex;
  gap: 20px;
`;

const AdvancedConfigurationSection = styled.div`
  margin-top: 20px;
`;

const ErrorMessageWrapper = styled.div`
  margin-top: -14px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ErrorMessage = styled.div`
  font-size: 15px;
  color: rgb(115, 125, 129);;
`;

const PositionTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: -20px;
  margin-top: 10px;
`;

interface ISelectedZoneCamera{
  zoneName: string;
  selectedCamera: IStream[];
}

interface IErrorMessage{
  text: string;
  id: string;
}

interface IFloorPlanConfigsProps {
  selectedLayerConfigLength: number;
  selectedIndex: number;
  selectedTab: number;
  onAddNewLine: (cameraName: string, data: IFloorPlanConfigs, currentLength: number)=>void;
  handleRemoveLine?: (index: number, length: number)=>void;
  dispatch: React.Dispatch<IReducerActions>;
  onLineClickCallback?: (id: number) => void;
  setSelectedLayerConfig: React.Dispatch<React.SetStateAction<IFloorPlanConfigs[]>>;
  selectedLayerConfig: IFloorPlanConfigs[];
  selectedZoneConfig: IFloorPlanConfigs[];
  selectedZoneIndex: number;
  setSelectedZoneIndex: React.Dispatch<React.SetStateAction<number>>;
  allLayerConfigDetails: IFloorPlanConfigsRes[];
}

const CameraConfigurationTab: React.FC<IFloorPlanConfigsProps> = ({selectedLayerConfigLength, selectedZoneConfig, setSelectedLayerConfig, selectedIndex, setSelectedZoneIndex, dispatch, onAddNewLine, onLineClickCallback=()=>{}, handleRemoveLine=()=>{}, selectedTab, selectedLayerConfig, allLayerConfigDetails}) => {
  const {t} = useTranslation(['common']);
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'});
  const {data: getAllStreamsResponse, loading:allStreamsLoading, refetch: getAllStreamsRefetch} = useQuery(GET_ALL_STREAMS);
  const [allStream, setAllStreams] = useState<IStream[]>([]);
  const [filterStream, setFilterStreams] = useState<IStream[]>([]);
  const [selectedZoneName, setSelectedZoneName] = useState('');
  const [selectedCamera, setSelectedCamera] = useState<IStream[]>([]);
  const prevSelectedCameraLength = useRef(selectedCamera.length);
  const prevSelectedCamera = useRef<IStream[]>([]);
  const [selectedId, setSelectedId] = useState<GridRowSelectionModel>([]);
  const [selectedZoneCamera, setSelectedZoneCamera] = useState<ISelectedZoneCamera[]>([]);
  const [errorMessage, setErrorMessage] = useState<IErrorMessage>({text: '', id: ''});
  const [selectedCameraId, setSelectedCameraId] = useState<number>(0);

  useEffect(() => {
    setSelectedZoneName(selectedZoneConfig ? selectedZoneConfig[0]?.name : '');
    const addedCameras = allLayerConfigDetails?.filter((item: IFloorPlanConfigsRes) => item.level === 'THIRD') ?? [];
    const initialCameraNames = addedCameras && addedCameras[0]?.data?.map(camera => camera.name);
    const newSelectedZoneCamera: ISelectedZoneCamera[] = selectedZoneConfig?.map(zone => ({
      zoneName: zone.name,
      selectedCamera: []
    }));
    const matchedStreams = allStream.filter(stream => initialCameraNames?.includes(stream.instanceId));
  
    const updatedSelectedZoneCamera = newSelectedZoneCamera?.map(zone => {
      const matchedCameras = addedCameras[0]?.data?.filter(cameraData => cameraData.zoneName === zone.zoneName);
      if (matchedCameras?.length > 0) {
        return {
          ...zone,
          selectedCamera: matchedStreams.filter(stream => matchedCameras.some(match => stream.instanceId === match.name))
        };
      }
      return zone;
    });
  
    setSelectedZoneCamera(updatedSelectedZoneCamera);
    const initialCameraIds = initialCameraNames?.map(name =>
      allStream.find(stream => stream.instanceId === name)?.id
    ).filter(Boolean);

    setSelectedId(initialCameraIds as number[]);
  }, [allLayerConfigDetails, allStream, selectedZoneConfig]);

  useEffect(() => {
    if(!allStreamsLoading && selectedCamera?.length !== 0) {
      const selectedCamerasForZone = selectedZoneCamera
        .filter(item => item.zoneName === selectedZoneName)
        .map(item => item.selectedCamera)
        .flat();

    
      const selectedCameraInstanceIds = selectedCamera?.map(camera => camera.instanceId);
    
      const notSelectedCameras = allStream.filter(stream => !selectedCameraInstanceIds?.includes(stream.instanceId));
    
      const filteredStreams = [...selectedCamerasForZone, ...notSelectedCameras].reduce((acc: IStream[], stream: IStream) => {
        if (!acc.some(existingStream => existingStream.instanceId === stream.instanceId)) {
          acc.push(stream);
        }
        return acc;
      }, []);
      setFilterStreams(filteredStreams);
    }else if(!allStreamsLoading && selectedCamera?.length === 0){
      setFilterStreams(allStream);
    }
  }, [selectedZoneName, allStreamsLoading, selectedZoneCamera, allStream, selectedCamera]);

  const getAllStreams = useCallback(async()=>{
    const allStreamDataResponse = await getAllStreamsResponse;
    if(allStreamDataResponse && allStreamDataResponse.getAllStreams.statusCode === StatusCode.SUCCESS && allStreamDataResponse.getAllStreams.result !== null){
      const allStreams: IStream[] = allStreamDataResponse.getAllStreams.result;
      setAllStreams(allStreams);
    }
    await getAllStreamsRefetch();
  },[getAllStreamsResponse, getAllStreamsRefetch]);

  useEffect(()=>{
    getAllStreams();
  },[getAllStreams]);

  const AddNewLine = useCallback(( name: string, cameraName: string) => {
    if(selectedLayerConfigLength + 1 > Config.algorithmConfigurations.areasLimit){
      setMessage({text: t('configurationLimitWarning').replace('{X}',`${Config.algorithmConfigurations.areasLimit}`), id:'',type:'danger'});
    }else{
      const CameraPoints = selectedZoneConfig.filter(layer => layer.name === selectedZoneName)[0]?.points ?? [];
      const newLineData: IFloorPlanConfigs = {
        name:  name,
        pointsCount: 1,
        index: selectedLayerConfigLength + 1,
        lineType: LineUIType.AREA,
        styling: getLineUIColor(ZONE_LINEUI_CODES[0]) as string,
        points: [CameraPoints[0]] ?? [{x: 1, y: 1}],
        zoneName: selectedZoneName,
        cameraName: cameraName
      };
      onAddNewLine(cameraName, newLineData, selectedLayerConfigLength );
      setSelectedLayerConfig(prev => [...prev, newLineData]);
    }
  },[onAddNewLine, selectedLayerConfigLength, selectedZoneConfig, selectedZoneName, setSelectedLayerConfig, t]);
 
  useEffect(() => {
    if (selectedCamera.length > prevSelectedCameraLength.current) {
      const addedItems = selectedCamera.filter(item => !prevSelectedCamera.current?.includes(item));
      const filteredAddedItems = addedItems.filter(item =>
        !selectedLayerConfig?.map(layer => layer.name)?.includes(item.instanceId)
      );
      if(filteredAddedItems?.length !== 0){
        const updatedSelectedZoneCamera = selectedZoneCamera?.map(zone => {
          if (zone.zoneName === selectedZoneName) {
            return {
              ...zone,
              selectedCamera: [...zone.selectedCamera, ...addedItems]
            };
          }
          return zone;
        });
        setSelectedZoneCamera(updatedSelectedZoneCamera);
      }
      if (!selectedLayerConfig?.map(item => item.name)?.includes(selectedCamera[prevSelectedCameraLength.current].instanceId)) {
        AddNewLine(selectedCamera[prevSelectedCameraLength.current].instanceId, selectedCamera[prevSelectedCameraLength.current].name );
      }
    }else if(selectedCamera.length < prevSelectedCameraLength.current) {
      const removedItems = prevSelectedCamera.current.filter(item => !selectedCamera?.includes(item));

      if (removedItems.length > 0) {
        const updatedSelectedZoneCamera = selectedZoneCamera?.map(zone => {
          if (zone.zoneName === selectedZoneName) {
            const updatedSelectedCamera = zone.selectedCamera.filter(camera => !removedItems?.includes(camera));
            return {
              ...zone,
              selectedCamera: updatedSelectedCamera
            };
          }
          return zone;
        });
        setSelectedZoneCamera(updatedSelectedZoneCamera);
      }
      const index = selectedLayerConfig.findIndex(item => item.name === removedItems[0]?.instanceId);
      handleRemoveLine(index, selectedLayerConfigLength);
    }
    prevSelectedCamera.current = selectedCamera;
    prevSelectedCameraLength.current = selectedCamera.length;
  }, [selectedCamera, AddNewLine, handleRemoveLine, selectedIndex, selectedLayerConfigLength, selectedLayerConfig, selectedZoneCamera, selectedZoneName] );

  const handlePointsChange = useCallback((e: React.ChangeEvent<HTMLInputElement>)=>{
    setSelectedLayerConfig((prev: IFloorPlanConfigs[]) => {
      const selectedLayerConfig = [...prev];
      const selectedLineConfig = JSON.parse(JSON.stringify(selectedLayerConfig[selectedIndex - 1])); 
      selectedLineConfig.points[0][e.target.name] = e.target.value;
      const selectedZoneIndex = selectedZoneConfig.findIndex((item) => item.name === selectedZoneName);
      if(pointInPolygon(selectedLineConfig.points[0], selectedZoneConfig[selectedZoneIndex].points ?? [])){
        setErrorMessage({text: '', id: ''});
        dispatch({type: 'UPDATE', index: selectedIndex - 1, data: selectedLineConfig });
      }else{
        setErrorMessage({text: '', id: e.target.name});
      }
      selectedLayerConfig[selectedIndex - 1] = selectedLineConfig;
      return selectedLayerConfig;
    });
  },[setSelectedLayerConfig, selectedIndex, selectedZoneConfig, selectedZoneName, dispatch]);

  const handleDeleteCamera = useCallback((item: number) => {
    setSelectedId((prev) => prev.filter((id) => id !== item));
    onLineClickCallback(-1);
  }, [onLineClickCallback]);

  useEffect(() =>{
    if (!selectedId || selectedId.length === 0) {
      setSelectedCamera([]);
      return;
    }
    const selectedCameras: IStream[] = selectedId
      ?.map(item => allStream.find(stream => stream.id === item))
      .filter((camera): camera is IStream => !!camera);

    setSelectedCamera(selectedCameras);
  }, [selectedId, setSelectedCamera, allStream]);

  useEffect(() =>{
    if(selectedLayerConfig[selectedIndex-1]?.zoneName){
      const selectedZone = selectedZoneConfig.findIndex(item => item.name === selectedLayerConfig[selectedIndex-1]?.zoneName);
      setSelectedZoneIndex(selectedZone);
      setSelectedZoneName(selectedZoneConfig[selectedZone]?.name);

      const selectedCameraId = allStream.find(item => item.instanceId === selectedLayerConfig[selectedIndex - 1].name)?.id;
      setSelectedCameraId(selectedCameraId ?  selectedCameraId : 0);
    }
    if(selectedIndex <= 0){
      setSelectedCameraId(0);
    }
  },[allStream, dispatch, selectedIndex, selectedLayerConfig, selectedZoneConfig, selectedZoneName, setSelectedZoneIndex]);

  const selectedStream = selectedZoneCamera
    .filter(item => item.zoneName === selectedZoneName)
    .map(item => item.selectedCamera)
    .flat();

  const RestrictionPointsIndex = selectedZoneConfig.findIndex((item) => item.name === selectedZoneName);

  useEffect(() =>{
    const selectedLayerPoint = selectedLayerConfig[selectedIndex - 1]?.points?.[0];
    const zonePoints = selectedZoneConfig[RestrictionPointsIndex]?.points ?? [];
    if (selectedLayerPoint && pointInPolygon(selectedLayerPoint, zonePoints)) {
      setErrorMessage({text: '', id: ''});
    }
  }, [RestrictionPointsIndex, selectedIndex, selectedLayerConfig, selectedZoneConfig]);

  return (
    <ConfigurationForm>
      { message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
      {selectedIndex === 0 && selectedZoneConfig.length === 0 ? 
        <NoAreaData>
          <NoAreaText>{selectedTab === 2 ? t('No Zone have been configured yet, Please add New Zone') :t('No area have been configured yet, please add new area')}</NoAreaText>
          <AddNewAreaButton onClick={ () => {} }  >{selectedTab === 2 ? t('Add Zone'):  selectedTab === 3 ? t('Add Camera') :t('Add Area')}</AddNewAreaButton>
        </NoAreaData>:
        <>
          <LinesContainer>
            { selectedTab !== 1 &&<AddNewLineWrapper>
              <LabelWrapper>
                <Icon icon='Line' size={20} />
                <Label>{t('Zone')}</Label>
              </LabelWrapper>
            </AddNewLineWrapper>}
            <LinesListContainer>
              {(selectedZoneConfig as IFloorPlanConfigs[])?.map((item, index) => (
                <>
                  <LineNameWrapper isSelected={selectedZoneName === item.name} onClick={() => {setSelectedZoneName(item.name); setSelectedZoneIndex(index); onLineClickCallback(-1);}}>
                    <div>{index + 1}.</div>
                    <LineName>{item.name}</LineName>
                  </LineNameWrapper>
                  {index !== selectedLayerConfig?.length - 1 && <HorizontalDivider />}
                </>
              ))}
            </LinesListContainer>
          </LinesContainer>
          <LineDetailContainer>
            <SelectOptionsWrapper>
              {selectedStream?.map((item, index) => (
                <AllOptionsWrapper>
                  <IndividualUserOptionsForSelect key={index}>
                    {item?.name}
                  </IndividualUserOptionsForSelect>
                  <CloseIconWrapper onClick={() => handleDeleteCamera(item.id)}>
                    <Icon icon='Close' size={16} />
                  </CloseIconWrapper>
                </AllOptionsWrapper>
              ))}
              {selectedStream.length === 0 && <TextDiv>{t('No camera has been configured for this zone')}</TextDiv>}
            </SelectOptionsWrapper>
            <ZoneCameraList getAllStreamsRefetch={getAllStreamsRefetch} isMapViewEnabled={true} isFloorPlan={true} streams={filterStream} setMessage={setMessage} setSelectedId={setSelectedId} selectedId={selectedId} selectedCameraId={selectedCameraId}/>
            <PositionTitle>{t('Camera Positions')}</PositionTitle>
            <PointsWrapper>
              <PointsConfig>
                <Input 
                  width={'220px'}
                  id='x' 
                  disabled={selectedLayerConfig.length === 0 || selectedIndex <= 0} 
                  type='number' 
                  name='x' 
                  label={t('X-Position')} 
                  maxLength={16} 
                  placeholder= {t('Select camera to view x-position')}
                  value={selectedLayerConfig[selectedIndex - 1]?.points?.[0]?.x ?? ''} 
                  onChange={(e) => handlePointsChange(e)} 
                  message={errorMessage}
                  isIncreamentDecreament={true}
                />
              </PointsConfig>
              <PointsConfig>
                <Input 
                  width={'220px'}
                  id='y' 
                  disabled={selectedLayerConfig.length === 0 || selectedIndex <= 0} 
                  type='number' 
                  name='y' 
                  label={t('Y-Position')} 
                  maxLength={16} 
                  placeholder= {t('Select camera to view y-position')}
                  value={selectedLayerConfig[selectedIndex - 1]?.points?.[0]?.y ?? ''} 
                  onChange={(e) => handlePointsChange(e)} 
                  message={errorMessage}
                  isIncreamentDecreament={true}
                />
              </PointsConfig>
            </PointsWrapper>
            <ErrorMessageWrapper>
              <ErrorMessage>{t('The X and Y Positions must be within the range of following points')}</ErrorMessage>
              <ErrorMessage>
                {selectedZoneConfig && selectedZoneConfig[RestrictionPointsIndex]?.points
                  ? selectedZoneConfig[RestrictionPointsIndex]?.points?.map(item => JSON.stringify(item))?.join(', ')
                  : ''}
              </ErrorMessage>
            </ErrorMessageWrapper>
            <AdvancedConfigurationSection>
            </AdvancedConfigurationSection>
          </LineDetailContainer>
        </>
      }
    </ConfigurationForm>
  );
};

export default CameraConfigurationTab;