import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Label from './Label';
import {IOption} from '../interface';
import { useTranslation } from 'react-i18next';

interface Ownprops {
  options: IOption[];
  defaultValue?: boolean | string | number;
  isRequired?: boolean;
  id?: string;
  label?: string;
  disabled?: boolean;
  onChange?: (value: string, id?: string) => void;
  emptyListText?: string;
  width?: string;
  placeholder?: string
}

const SelectContainer = styled.div<{width: string}>`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  width: ${({width}) => width};
`;

const Select = styled.select<{isPlaceholder: boolean}>`
  appearance: none;
  padding: 8px;
  border: 1px solid gray;
  border-radius: 4px;
  height: 40px;
  width: 100%;
  cursor: pointer;
  border: 1px solid lightgrey;
  background-color: ${({theme}) => theme.backgroundColor} !important;
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 30px;
  color: ${({ isPlaceholder }) => (isPlaceholder ? '#757575' : 'black' )};
  font-size: ${({ isPlaceholder }) => (isPlaceholder ? '12px' : '14px' )};
  font-style: ${({ isPlaceholder }) => (isPlaceholder ? 'italic' : 'normal' )};
  &:focus{
    outline: none;
    border-radius: 4px;
    border: 1px solid #435FCA;
  }
  &:disabled{
    cursor: no-drop;
    color: #262728;
    background-color: #EEF0F8 !important;
  }
`;

const SelectArrow = styled.div<{isExpanded: boolean}>`
  position: absolute;
  top: ${({ isExpanded }) => (isExpanded ? '56%' : '44%' )};
  right: 15px;
  transform: translateY(-50%) rotate(${({ isExpanded }) => (isExpanded ? '136deg': '315deg')});
  pointer-events: none;
  width: 10px;
  height: 10px;
  border-left: 2px solid #555;
  border-bottom: 2px solid #555;
  transition: transform 0.2s;
  @media (max-width: 768px) {
    top: ${({ isExpanded }) => (isExpanded ? '56%' : '44%' )};
    left: 416px;
  }
`;

const Option = styled.option`
  padding: 10px 20px !important;
  color: black;
  font-size: 14px;
  font-style: normal;
  text-overflow: ellipsis;
`;

const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const SelectField: React.FC<Ownprops> = ({ options, defaultValue, id, isRequired=false, emptyListText='No Data', disabled, label, onChange, width='450px', placeholder }) => {
  const {t} = useTranslation(['common']);
  const [isExpanded, setIsExpanded] = useState(false);
  const selectRef = useRef<HTMLSelectElement>(null);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    onChange?.(value);
  };

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
      setIsExpanded(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <SelectContainer width={width}>
      {label && <Label labelText={label} isMandatory={isRequired} />}
      <SelectWrapper>
        <Select
          ref={selectRef}
          id={id}
          disabled={disabled}
          value={defaultValue?.toString()}
          onClick={handleToggle}
          onChange={handleSelectChange}
          isPlaceholder={(defaultValue?.toString() === '')}
        >
          {options?.length > 0 ? 
            <>
              {placeholder && <Option id='placeholder' value='' disabled selected hidden>{placeholder}</Option>}
              {
                options.map((option) => (
                  <Option key={option.value} value={option.value}>{t(option.name)}</Option>
                ))
              }
            </>:
            <>
              {placeholder && <Option id='placeholder' value='' disabled selected hidden>{placeholder}</Option>}
              <Option disabled selected value={emptyListText}>{t(emptyListText)}</Option>
            </>
          }
        </Select>
        <SelectArrow isExpanded={isExpanded} />
      </SelectWrapper>
    </SelectContainer>
  );
};

export default SelectField;