import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgEventPipeline = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M16.902 2.4H4.8a2.4 2.4 0 0 0-2.4 2.4v10.8A2.4 2.4 0 0 0 4.8 18h3.6v2.4H6.6a.6.6 0 1 0 0 1.2h10.8a.6.6 0 0 0 0-1.2h-1.8V18h3.6a2.4 2.4 0 0 0 2.4-2.4V8.4h-1.2v7.2a1.2 1.2 0 0 1-1.2 1.2H4.8a1.2 1.2 0 0 1-1.2-1.2V4.8a1.2 1.2 0 0 1 1.2-1.2h12V3c0-.21.036-.412.102-.6Zm-2.502 18H9.6V18h4.8v2.4ZM18 3a.6.6 0 0 1 .6-.6h3.6a.6.6 0 0 1 .6.6v3.6a.6.6 0 0 1-.6.6h-3.6a.6.6 0 0 1-.6-.6V6h-.936a.6.6 0 0 0-.568.41l-.653 1.96a1.8 1.8 0 0 1-1.707 1.23H13.2v1.8a.6.6 0 0 1-.6.6H9a.6.6 0 0 1-.6-.6V7.8a.6.6 0 0 1 .6-.6h3.6a.6.6 0 0 1 .6.6v.6h.936a.6.6 0 0 0 .57-.41l.652-1.958A1.8 1.8 0 0 1 17.064 4.8H18V3Zm-6 5.4H9.6v2.4H12V8.4ZM19.2 6h2.4V3.6h-2.4V6Z"
        fill={props.color}
      />
    </svg>
  );
};
export default SvgEventPipeline;
