import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import TokenService from 'api_configs/tokenService';
import Config from './configs/config.json';
import { useUsername } from 'hooks/useusername';

export default function SecureRoute({...routeProps}: RouteProps) {
  const {username} = useUsername();
  if(Config.envType === 'edge') {
    if(TokenService.getUser()) {
      return <Route {...routeProps} />;
    } else {
      return <Redirect to='/login' />;
    }
  } else {
    if(username !== undefined) {
      return <Route {...routeProps} />;
    } else {
      return <Redirect to='/login' />;
    }
  }
}