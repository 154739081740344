import React, { useCallback } from 'react';
import styled from 'styled-components';
import { PAGE_SIZE_OPTIONS } from '../constants';
import MuiPagination from '@mui/material/Pagination';
import { useTranslation } from 'react-i18next';
import SelectFieldWithInput from './SelectFieldWithInput';

const SelectOptionField = styled.select`
  border-radius: 14px;
  border: solid 1px #BFCDD2;
  padding: 0 6px 0 4px;
  outline: none;
`;

const Label = styled.div`
  font-size: 14px;
  margin: auto 10px auto 0;
`;

const SelectFieldWrapper = styled.div`
  display: flex;
  >div:nth-child(2) {
    width: 50px;
  }
`;

const PaginationWrapper = styled.div`
 display: flex;
 justify-content: space-between;
 > nav {
  margin: 0 auto;
 }
`;

interface IProps {
  setPageSize: (e: number) => void;
  onChange: (value: number) => void;
  pageSize: number;
  count: number;
  currentPage: number;
  showPageList?: boolean;
}

const Pagination: React.FC<IProps> = ({setPageSize, pageSize, onChange, count, currentPage=1, showPageList=true}) => {
  const {t} = useTranslation(['common']);
  
  const getOptionsList = useCallback(() => {
    const optionsList = Array.from({ length: count }, (_, index) => ({name: (index+1).toString(), value: (index+1).toString()}));
    return optionsList;
  },[count]);

  const onPageNumberSelect = useCallback((value: string) => {
    onChange(parseInt(value));
  },[onChange]);

  return (
    <PaginationWrapper>
      <SelectFieldWrapper>
        <Label>{t('Page Size')}</Label>
        <SelectOptionField
          onChange={(e) => {setPageSize(parseInt(e.target.value)); onChange(1);}}
          defaultValue={pageSize}
          value={pageSize}
        >
          {
            PAGE_SIZE_OPTIONS.map((size: number, index: number) => <option key={index} value={size}>{size}</option>)
          }
        </SelectOptionField>
      </SelectFieldWrapper>
      {showPageList && <MuiPagination onChange={(e, value) => onChange(value)} page={currentPage} count={count} color="primary" />}
      <SelectFieldWrapper>
        <Label>{t('Page Number')}</Label>
        <SelectFieldWithInput defaultValue={currentPage.toString()} options={getOptionsList()} onSelect={onPageNumberSelect} />
      </SelectFieldWrapper>
    </PaginationWrapper>  
  );};

export default Pagination;
