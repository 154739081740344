import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgGroup = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M8.714 13.819H5.953a2.35 2.35 0 0 1-2.35-2.35V5.95a2.35 2.35 0 0 1 2.35-2.35h2.761a2.35 2.35 0 0 1 2.35 2.35v5.518a2.35 2.35 0 0 1-2.35 2.35ZM5.953 4.54a1.41 1.41 0 0 0-1.41 1.41v5.518a1.41 1.41 0 0 0 1.41 1.41h2.761a1.41 1.41 0 0 0 1.41-1.41V5.95a1.41 1.41 0 0 0-1.41-1.41H5.953ZM8.714 20.4h-2.76a2.35 2.35 0 0 1 0-4.7h2.76a2.35 2.35 0 0 1 0 4.7Zm-2.758-3.76a1.41 1.41 0 0 0 0 2.82h2.758a1.41 1.41 0 0 0 0-2.82H5.956Zm12.098 3.76h-2.76a2.35 2.35 0 0 1-2.35-2.35v-5.518a2.35 2.35 0 0 1 2.35-2.35h2.759a2.35 2.35 0 0 1 2.35 2.35v5.518a2.35 2.35 0 0 1-2.348 2.35Zm-2.76-9.278a1.41 1.41 0 0 0-1.41 1.41v5.518a1.41 1.41 0 0 0 1.41 1.41h2.759a1.41 1.41 0 0 0 1.41-1.41v-5.518a1.41 1.41 0 0 0-1.41-1.41h-2.759Zm2.76-2.822h-2.76a2.35 2.35 0 0 1 0-4.7h2.759a2.35 2.35 0 1 1 0 4.7h.002Zm-2.758-3.76a1.41 1.41 0 0 0 0 2.82h2.759a1.41 1.41 0 0 0 0-2.82h-2.759Z"
        fill={props.color}
      />
    </svg>
  );
};
export default SvgGroup;
