import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgMaximize = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M15 3h6v6m-7 1 6.1-6.1M9 21H3v-6m7-1-6.1 6.1"
        stroke={props.color}
        strokeWidth={props.weight}
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={"non-scaling-stroke"}
      />
    </svg>
  );
};
export default SvgMaximize;
