import { ISVGProps } from 'interface';
import React from 'react';

const VehicleIndentitySVG: React.FC<ISVGProps> = ({color, size}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 32 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M21.7773 10.6094H10.2173C9.74734 10.6094 9.27734 10.9394 9.14734 11.3394C8.94734 11.8094 9.07734 12.3394 9.47734 12.6094L11.2173 14.0794C11.4173 14.2794 11.6873 14.3494 11.9473 14.3494H20.0273C20.2973 14.3494 20.5573 14.2794 20.7573 14.0794L22.4973 12.6094C22.8273 12.3394 23.0273 11.8094 22.8273 11.3394C22.6973 10.9394 22.2273 10.6094 21.7573 10.6094H21.7773ZM21.8473 11.8094L20.1073 13.2794H11.8873L10.1473 11.8094C10.1473 11.8094 10.0773 11.7394 10.1473 11.7394C10.1473 11.6927 10.1707 11.6694 10.2173 11.6694H21.7773C21.7773 11.6694 21.8473 11.6694 21.8473 11.7394V11.8094Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M25.5927 10.8794L23.8527 12.3494C23.5227 12.6194 23.3827 13.1494 23.5227 13.6194C23.7227 14.0894 24.1227 14.3494 24.5927 14.3494H28.8027C29.4027 14.3494 29.8727 13.8794 29.8727 13.2794V11.6794C29.8727 11.0794 29.4027 10.6094 28.8027 10.6094H26.3327C26.0627 10.6094 25.8027 10.7394 25.6027 10.8794H25.5927ZM28.8027 11.6794V13.2794H24.5927C24.5927 13.2794 24.5227 13.2794 24.5227 13.2094V13.1394L26.2627 11.6694H28.8027V11.6794Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.41062 10.8794C6.21062 10.7494 5.94062 10.6094 5.68062 10.6094H3.21062C2.61062 10.6094 2.14062 11.0794 2.14062 11.6794V13.2794C2.14062 13.8794 2.61062 14.3494 3.21062 14.3494H7.42062C7.89062 14.3494 8.29062 14.0794 8.49062 13.6194C8.62062 13.1494 8.49062 12.6194 8.16062 12.3494L6.42062 10.8794H6.41062ZM7.48063 13.2194C7.48063 13.2894 7.41062 13.2894 7.41062 13.2894H3.21062V11.6894H5.68062L7.42062 13.1594C7.49062 13.1594 7.49062 13.2294 7.49062 13.2294L7.48063 13.2194Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.94969 15.9609H3.20969C2.93969 15.9609 2.67969 16.2309 2.67969 16.4909C2.67969 16.7509 2.94969 17.0209 3.20969 17.0209H6.94969C7.21969 17.0209 7.47969 16.7509 7.47969 16.4909C7.47969 16.2309 7.20969 15.9609 6.94969 15.9609Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M28.7934 15.9609H25.0534C24.7834 15.9609 24.5234 16.2309 24.5234 16.4909C24.5234 16.7509 24.7934 17.0209 25.0534 17.0209H28.7934C29.0634 17.0209 29.3234 16.7509 29.3234 16.4909C29.3234 16.2309 29.0534 15.9609 28.7934 15.9609Z" fill={color}/>
      <path d="M17.2975 22.1734H21.3175C21.4475 22.0534 21.5675 21.9134 21.6675 21.7734H16.9375C17.0475 21.9134 17.1575 22.0534 17.2875 22.1734H17.2975Z" fill={color}/>
      <path d="M10.2188 21.7709C9.88875 21.7709 9.61875 21.5009 9.61875 21.1709V16.9609C9.61875 16.6309 9.88875 16.3609 10.2188 16.3609H12.1187C12.1887 16.2209 12.2587 16.0909 12.3387 15.9609H10.2188C9.68875 15.9609 9.21875 16.4309 9.21875 16.9609V21.1709C9.21875 21.7709 9.68875 22.1709 10.2188 22.1709H11.5188C11.4788 22.0409 11.4487 21.9009 11.4187 21.7709H10.2188Z" fill={color}/>
      <path d="M29.53 7.41H30.4C31.27 7.41 32 6.74 32 5.81C32 4.94 31.27 4.21 30.4 4.21H29.33C28.86 4.21 28.39 4.48 28.13 4.81L27.13 1.8C26.73 0.73 25.73 0 24.59 0H7.42C6.28 0 5.22 0.73 4.88 1.8L3.88 4.81C3.61 4.48 3.15 4.21 2.68 4.21H1.61C0.74 4.21 0.01 4.94 0.01 5.81C0.01 6.75 0.74 7.41 1.61 7.41H2.48L0.47 9.41C0.2 9.68 0 10.08 0 10.55V17.56C0 18.23 0.47 18.83 1.07 19.03V20.77C1.07 21.64 1.8 22.37 2.67 22.37H6.41C7.28 22.37 8.01 21.64 8.01 20.77V19.17H8.81V18.1H1.6C1.33 18.1 1.07 17.83 1.07 17.57V10.56C1.07 10.43 1.14 10.29 1.2 10.16L3.94 7.42H28.06L30.73 10.16C30.86 10.29 30.93 10.43 30.93 10.56V17.57C30.93 17.84 30.66 18.1 30.4 18.1H27.14C27.23 18.45 27.3 18.8 27.34 19.17H29.86V20.77C29.86 21.04 29.59 21.3 29.33 21.3H27.28C27.22 21.66 27.14 22.02 27.03 22.37H29.33C30.2 22.37 30.93 21.64 30.93 20.77V19.03C31.53 18.83 32 18.23 32 17.56V10.55C32 10.08 31.8 9.68 31.53 9.41L29.53 7.41ZM2.14 19.16H6.95V20.76C6.95 21.03 6.68 21.29 6.42 21.29H2.68C2.41 21.29 2.15 21.02 2.15 20.76V19.16H2.14ZM3.21 6.33H1.61C1.34 6.33 1.08 6.13 1.08 5.8C1.08 5.53 1.35 5.27 1.61 5.27H2.68C2.95 5.27 3.21 5.54 3.21 5.8V6.33ZM4.48 6.33L5.88 2.12C6.08 1.45 6.75 1.05 7.42 1.05H24.59C25.26 1.05 25.86 1.45 26.13 2.12L27.53 6.33H4.48ZM28.8 5.8C28.8 5.53 29 5.27 29.33 5.27H30.4C30.67 5.27 30.93 5.54 30.93 5.8C30.93 6.13 30.66 6.33 30.4 6.33H28.8V5.8Z" fill={color}/>
      <path d="M27.5222 27.4509L23.9822 23.9109C24.3922 23.4109 24.7122 22.8409 24.9522 22.2409C25.0922 21.9009 25.1922 21.5409 25.2722 21.1809C25.3422 20.8109 25.3822 20.4309 25.3822 20.0409C25.3822 19.7409 25.3522 19.4609 25.3122 19.1709C25.2622 18.8009 25.1722 18.4509 25.0622 18.1009C24.2422 15.7009 21.9722 13.9609 19.3122 13.9609C17.5322 13.9609 15.9322 14.7409 14.8222 15.9609C14.7022 16.0909 14.5922 16.2209 14.4922 16.3609C13.7122 17.3809 13.2422 18.6609 13.2422 20.0309C13.2422 20.6309 13.3322 21.2209 13.5022 21.7709C13.5422 21.9109 13.5922 22.0409 13.6422 22.1709C14.5122 24.4609 16.7322 26.1109 19.3222 26.1109C20.7922 26.1109 22.1322 25.5809 23.1922 24.7109L26.7322 28.2509C26.8622 28.3209 27.0022 28.3809 27.1322 28.3809C27.2622 28.3809 27.4022 28.3109 27.5322 28.2509C27.7322 27.9809 27.7322 27.6509 27.5322 27.4509H27.5222ZM19.3022 24.9809C17.3322 24.9809 15.6522 23.8309 14.8622 22.1709C14.8022 22.0409 14.7422 21.9109 14.6922 21.7709C14.4922 21.2309 14.3622 20.6509 14.3622 20.0309C14.3622 18.5409 15.0222 17.2309 16.0522 16.3309C16.0722 16.3109 16.1022 16.2909 16.1222 16.2709C16.9822 15.5409 18.0822 15.0909 19.3022 15.0909C21.3522 15.0909 23.1022 16.3309 23.8522 18.1009C24.1022 18.6909 24.2422 19.3509 24.2422 20.0409C24.2422 20.4709 24.1722 20.8809 24.0722 21.2809C23.5222 23.4109 21.6122 24.9809 19.3022 24.9809Z" fill={color}/>
    </svg>

  );
};

export default VehicleIndentitySVG;