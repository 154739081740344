import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgLandSlideDetection = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.17 12.432c.36.43.9.65 1.41.65.25 0 .5-.04.72-.14l1.62-.68a1.89 1.89 0 0 0 1.15-1.66l.04-2.09c.04-.5-.14-.97-.5-1.33-.32-.36-.79-.58-1.3-.58l-2.2-.04h-.04c-.76 0-1.41.43-1.73 1.12l-.61 1.44c-.29.65-.18 1.44.29 1.98l1.15 1.33Zm-.29-2.81.61-1.44c.11-.22.32-.36.58-.36l2.2.04c.18 0 .32.07.43.18.11.11.18.29.18.43l-.07 2.09c0 .25-.14.47-.36.54l-1.62.68c-.25.11-.54.04-.72-.18l-1.15-1.33a.61.61 0 0 1-.07-.65h-.01ZM11.78 21.77l-1.37-5.33a.534.534 0 0 0-.22-.32l-3.6-3.03-.9-4.79c-.04-.07-.04-.14-.07-.18L1.15.29A.58.58 0 0 0 .65 0H.47C.18.07 0 .32 0 .61v11.32c0 .32.29.61.61.61.36 0 .65-.29.65-.61V2.99l3.21 5.62.94 4.94c.04.14.11.25.22.36l3.64 3.03 1.33 5.19s0 .07.04.11l2.85 6.02H1.26v-10.7c0-.36-.29-.61-.65-.61-.32 0-.61.25-.61.61v11.32c0 .32.29.61.61.61h13.87c.22 0 .4-.11.5-.29.14-.18.14-.4.04-.61l-3.24-6.81v-.01ZM30.27 20.22l-1.01-3.42c-.22-.76-.94-1.3-1.73-1.3h-7.06c-.79 0-1.51.54-1.73 1.3l-1.01 3.32c-1.15.36-1.98 1.44-1.98 2.74v3.5c0 .36.29.65.61.65h1.44v1.33c0 .65.5 1.15 1.15 1.15h1.44c.65 0 1.15-.5 1.15-1.15v-1.33h4.65v1.33c0 .65.5 1.15 1.15 1.15h1.44c.65 0 1.15-.5 1.15-1.15v-1.33h1.44c.32 0 .61-.29.61-.65v-3.5c0-1.19-.72-2.2-1.73-2.63l.02-.01ZM28.9 20h-2.85l2.2-2.2.65 2.2Zm-1.37-3.28L24.29 20h-2.27l3.28-3.28h2.23Zm-7.6.4c.07-.22.29-.4.54-.4h3.03L20.26 20h-1.15l.83-2.88h-.01Zm.36 11.14h-1.23V27h1.23v1.26Zm8.4 0h-1.26V27h1.26v1.26Zm2.05-2.52H17.01v-2.88c0-.9.72-1.62 1.59-1.62h10.56c.86 0 1.59.72 1.59 1.62v2.88h-.01ZM23.86 13.153c.36 0 .65-.25.65-.61v-1.66c0-.32-.29-.61-.65-.61-.32 0-.61.29-.61.61v1.66c0 .36.29.61.61.61ZM27.783 13.95c.14 0 .29-.04.43-.18l1.15-1.15c.25-.25.25-.65 0-.9-.25-.22-.65-.22-.86 0l-1.19 1.19c-.22.22-.22.61 0 .86.14.14.29.18.47.18ZM19.533 13.77c.11.14.29.18.43.18.18 0 .32-.04.47-.18.22-.25.22-.65 0-.86l-1.19-1.19c-.25-.22-.65-.22-.86 0-.25.25-.25.65 0 .9l1.15 1.15Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.29 23.86c0 .36-.25.61-.61.61s-.61-.25-.61-.61c0-.32.25-.61.61-.61s.61.29.61.61ZM28.682 23.86c0 .36-.29.61-.61.61-.36 0-.65-.25-.65-.61 0-.32.29-.61.65-.61.32 0 .61.29.61.61Z"
        fill={props.color}
      />
    </svg>
  );
};
export default SvgLandSlideDetection;
