import AlertBar from 'components/AlertBar';
import Input from 'components/Input';
import TextArea from 'components/TextArea';
import { IconType, StatusCode } from '../constants';
import { IAlertMessageType, IBreadcrum } from 'interface';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { descriptionRegex, IPv4_regex, MDNS_regex } from 'utils/utils';
import { useHeader } from 'contexts/HeaderContext';
import { useHistory, useLocation, useParams } from 'react-router';
import Button from 'components/Button';
import ButtonWithLoading from 'components/ButtonWithLoading';
import { useMutation, useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import { VscMultipleWindows } from 'react-icons/vsc';
import { GET_ALL_SERVER, GET_SERVER } from 'api_configs/queries';
import { CREATE_SERVER, UPDATE_SERVER } from 'api_configs/mutations';

const FormWrapper = styled.div`
  margin-left: -16px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  min-height: calc(100vh - 120px);
  padding: 20px;
  background-color: ${({ theme }) => theme.backgroundColor};
`;

const LeftSideWrapper = styled.div`
  padding: 8px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 48%;
  @media (max-width: 768px) {
    width: 100%;
  }
  > div > select {
    color: #000000
  }
`;

const NotesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: -5px;
  padding: 0;
  @media (max-width: 768px) {
    width: 100%;
  }
  :first-child > div {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  > div > textarea {
    padding-top: 6px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 94%;
  gap: 15px;
`;

const NewServerWrapper = styled.div`
  margin-top: -8px;
  width: 1030px;
  background-color: ${({theme}) => theme.backgroundColor};
  border-radius: 5px;
  @media (max-width: 768px) {
    width: 505px;
  }
`;

const InnerWrapper = styled.div`
  width: fit-content;
`;

const BasicCameraInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 1060px;
`;

const SpinnerBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 89vh;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.backgroundColor};
`;

const Label = styled.div`
  font-size: 16px;
`;

interface IParams {
  serverId: string;
}

const AddAIServer = () => {
  const [serverInfo, setServerInfo] = useState({id: 0, serverName: '', description: '', mDNSAddress: '', ipAddress: '', isMaster: false});
  const {t} = useTranslation(['common']);
  const { pathname } = useLocation();
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: ''});  
  const { serverId } = useParams<IParams>();
  const {updateHeaderTitle} = useHeader();
  const [isEditServer] = useState<boolean>(pathname.includes('editServer'));
  const [createServer, {loading: createLoading}] = useMutation(CREATE_SERVER, {refetchQueries: [GET_ALL_SERVER]});
  const [updateServer, {loading: updateLoading}] = useMutation(UPDATE_SERVER, {refetchQueries: [GET_ALL_SERVER]});
  const {data: getServer, loading, refetch: getServerRefetch} = useQuery(GET_SERVER, { variables: {serverId: parseInt(serverId)}});
  const {push} = useHistory();

  const fetchServer = useCallback(async()=>{
    getServerRefetch();
    const serverResponse = await getServer;
    if(serverResponse && serverResponse.getServer.statusCode === StatusCode.SUCCESS){
      const result = {
        id: serverResponse.getServer.result.id, 
        serverName: serverResponse.getServer.result.name, 
        description:serverResponse.getServer.result.description, 
        mDNSAddress: serverResponse.getServer.result.mdnsName, 
        ipAddress: serverResponse.getServer.result.serverIp,
        isMaster: serverResponse.getServer.result.isMaster
      };
      setServerInfo(result);
    }
  },[getServer, getServerRefetch]);

  useEffect(() => {
    if(isEditServer) {
      fetchServer();
    }
  },[fetchServer, isEditServer]);

  useEffect(()=>{
    const cameraConfigurationsBreadCrumb: IBreadcrum[] = [
      {label: t('AI Servers'), to: '/settings/servers'},
    ];
    updateHeaderTitle(!isEditServer ? t('Add AI Server') : t('Edit AI Server'), <VscMultipleWindows color='white' size={24} />, cameraConfigurationsBreadCrumb);
  },[t, updateHeaderTitle, serverInfo, isEditServer]);

  const handleDescriptionPaste = (e: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const pastedText = e.clipboardData.getData('text/plain');
    if (pastedText.length >= 256) {
      setMessage({text: t('Description text limit exceeded'), id: '', type:'danger'});
    }
    const trimmedText = pastedText.slice(0, 256);
    setServerInfo((prev) => ({...prev, description: trimmedText.replace(new RegExp(descriptionRegex, 'g'), '')}));
  };

  const handleServerPaste = (e: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const pastedText = e.clipboardData.getData('text/plain');
    if (pastedText.length >= 256) {
      setMessage({text: t('Server name text limit exceeded'), id: '', type:'danger'});
    }
    const trimmedText = pastedText.slice(0, 256);
    setServerInfo((prev) => ({...prev, serverName: trimmedText.replace(new RegExp(descriptionRegex, 'g'), '')}));
  };

  const handleAddServer = async() => {
    if(!serverInfo.serverName) {
      setMessage({text: t('Please provide server name'), id: '', type:'danger'});
      return;
    }
    if(!serverInfo.mDNSAddress) {
      setMessage({text: t('Please provide mDNS address'), id: '', type:'danger'});
      return;
    }
    if(!serverInfo.ipAddress) {
      setMessage({text: t('Please provide IP address'), id: '', type:'danger'});
      return;
    }
    if(!MDNS_regex.test(serverInfo.mDNSAddress)){
      setMessage({text: t('Please provide correct mDNS address'), id: '', type:'danger'});
      return;
    }
    if(!IPv4_regex.test(serverInfo.ipAddress)){
      setMessage({text: t('Please provide correct IP address'), id: '', type:'danger'});
      return;
    }
    if(isEditServer) {
      try {
        const updateServerPayload = {name: serverInfo?.serverName, description: serverInfo?.description, mdnsName: serverInfo?.mDNSAddress, serverIp: serverInfo?.ipAddress, id: parseInt(serverId), isMaster: serverInfo.isMaster};
        const data = await updateServer({variables: {payload: updateServerPayload}});
        if(data?.data?.updateServer?.statusCode === StatusCode.SUCCESS) {
          setMessage({text: t('Server updated successfully'), id: '', type:'success'});
          setTimeout(() => {
            push('/settings/servers');
          },1000);
        } else if(data?.data?.updateServer?.statusCode === StatusCode.ERROR){
          setMessage({text: t('AI Server with this IP address is already exists.'), id: '', type:'danger'});
        } else {
          setMessage({text: t('apiError'), id: '', type:'danger'});
        }
      }catch(e) {
        console.log(e);
      }
    } else {
      try {
        const createServerPayload = {name: serverInfo?.serverName, description: serverInfo?.description, mdnsName: serverInfo?.mDNSAddress, serverIp: serverInfo?.ipAddress, isMaster: false};
        const data = await createServer({variables: {payload: createServerPayload}});
        if(data?.data?.createServer?.statusCode === StatusCode.SUCCESS) {
          setMessage({text: t('Server created successfully'), id: '', type:'success'});
          setTimeout(() => {
            push('/settings/servers');
          },1000);
        } else if(data?.data?.createServer?.statusCode === StatusCode.ERROR){
          setMessage({text: t('AI Server with this IP address is already exists.'), id: '', type:'danger'});
        } else  {
          setMessage({text: t('apiError'), id: '', type:'danger'});
        }
      }catch(e) {
        console.log(e);
      }
    }
   
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = e.target.value;
    if (inputValue.length > 255) {
      setMessage({text: t('Description text limit exceeded'), id: '', type:'danger'});
    } else {
      setServerInfo((prev) => ({
        ...prev,
        description: inputValue.replace(new RegExp(descriptionRegex, 'g'), '')
      }));
    }
  };

  const handleServerNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (inputValue.length > 255) {
      setMessage({text: t('Server name text limit exceeded'), id: '', type:'danger'});
    } else {
      setServerInfo((prev) => ({
        ...prev,
        serverName: inputValue.replace(new RegExp(descriptionRegex, 'g'), '')
      }));
    }
  };

  if (loading) {
    return (
      <SpinnerBox>
        <CircularProgress />
        <Label>{t('Loading...')}</Label>
      </SpinnerBox>
    );
  }

  return (
    <FormWrapper>
      {
        message && <AlertBar message={message.text} type={message.type as IconType} setMessage={setMessage} />
      }
      <NewServerWrapper>
        <InnerWrapper>
          <BasicCameraInfo>
            <LeftSideWrapper>
              <Input label={t('Server name')} isRequired id='machineServerName' placeholder={t('Please enter server name')} onPaste={(e) => handleServerPaste(e)} type='text' maxLength={255} value={serverInfo?.serverName} onChange={(e) => handleServerNameChange(e)} />
              <Input label={t('Enter mDNS')} isRequired id='machineMDns' placeholder={t('Please mDNS address')} type='text' value={serverInfo?.mDNSAddress} disabled={serverInfo.isMaster} onChange={(e) => setServerInfo(prev => ({...prev, mDNSAddress: e.target.value}))} />
            </LeftSideWrapper>
            <LeftSideWrapper>
              <NotesWrapper>
                <TextArea label={t('description')} rows={10} cols={30} onPaste={(e) => handleDescriptionPaste(e)} maxLength={255} value={serverInfo.description} onChange={(e) => handleDescriptionChange(e)} height='40px' />
              </NotesWrapper>
              <Input label={t('IP Address')} isRequired id='machineIpAddress' placeholder={t('Please enter IP Address')} type='text' value={serverInfo?.ipAddress} disabled={serverInfo.isMaster} onChange={(e) => setServerInfo(prev => ({...prev, ipAddress: e.target.value}))} />
            </LeftSideWrapper>
            <ButtonsWrapper>
              <ButtonWithLoading loading={isEditServer ? updateLoading : createLoading} variant='primary' onClick={() => handleAddServer()}>{t('Save')}</ButtonWithLoading>
              <Button variant='primary' onClick={() => push('/settings/servers')}>{t('Cancel')}</Button>
            </ButtonsWrapper>
          </BasicCameraInfo>
        </InnerWrapper>
      </NewServerWrapper>
    </FormWrapper>
  );
};

export default AddAIServer;