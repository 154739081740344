import React, { useEffect, useState, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Icon from './Icon';

interface ButtonProps {
  active: boolean;
  enabled: boolean
  width?: string
}

const ButtonWrapper = styled.div<{disabled: boolean}>`
  display: flex;
  border-radius: 10px;
  border: 1px solid #cccc;
  width: fit-content;
  margin: 0 auto;
  background-color: ${({disabled}) => disabled ? '#F4F4F4': '#FFF'};
  overflow: hidden;
  position: relative;
  height: 100%;
  align-items: center;
`;

const IndividualButton = styled.button<ButtonProps>`
  flex: 1;
  display: flex;
  flex-direction: row;
  color: ${({ active }) => (active ? 'white' : '#000000')};
  background: none;
  border: none;
  outline: none;
  gap: 5px;
  width: ${({width})=> width};
  cursor: pointer;
  border-radius: 8px;
  padding: 5px 10px;
  font-size: 12px;
  z-index: ${({ active }) => (active ? '1' : '0')};
  transition: 0.3s;
  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
  }
`;

const ButtonLabel = styled.label<{enabled: boolean}>`
  font-size: 12px;
  font-weight: 400;
  cursor: ${({enabled}) => enabled ? 'pointer' : 'not-allowed'};
  pointer-events: ${({enabled}) => enabled ? '' : 'none'};
  width: 100%;
  text-align: center;
`;

const ButtonIndicator = styled.div<{selected: number, enabled: boolean, isInitialized: boolean}>`
  left: ${({selected}) => (selected === 1 ? '50%' : '0')};
  top: 0;
  position: absolute;
  width: 50%;
  height: 100%;
  background-color: ${({enabled}) => enabled ? '#435FCA':'#D0D7F2'};
  border-radius: 8px;
  transition: ${({isInitialized}) => isInitialized ? '0.3s': ''};
`;

interface IToggleProps {
  tabButtons: { label: string; selected: boolean; icon?: string | ReactNode }[];
  onTabClick: (index: number) => void;
  enabled: boolean,
  selected?: number,
  width?: string,
  iconSize?: number
}

const Toggle: React.FC<IToggleProps> = ({ width='54px', iconSize=14, enabled=false, tabButtons, selected=0, onTabClick }) => {
  const {t} = useTranslation(['common']);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsInitialized(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <ButtonWrapper disabled={(!enabled)}>
      {tabButtons.map((button, index) => (
        <IndividualButton
          key={index}
          enabled={enabled}
          active={button.selected}
          onClick={() => onTabClick(index)}
          disabled={!enabled}
          width={width}
        >
          {button.icon &&  
            <>
              {
                typeof button.icon === 'string' ? 
                  <Icon icon={button.icon ? button.icon : ''} size={iconSize} color={button.selected ? 'inverse' : 'mono'} />:
                  <>{button.icon}</>
              }    
            </>
          }
          <ButtonLabel enabled={enabled} onClick={() => onTabClick(index)}>{t(`${button.label}`)}</ButtonLabel>
        </IndividualButton>
      ))}
      <ButtonIndicator selected={selected} enabled={enabled} isInitialized={isInitialized} />
    </ButtonWrapper>
  );
};

export default Toggle;
