import React, { useEffect, useState, useCallback, useMemo } from 'react';
import Icon from 'components/Icon';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import { useHistory } from 'react-router';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { IAlertMessageType } from 'interface';
import AlertBar from 'components/AlertBar';
import { Container } from 'CommonStyles';
import ButtonWithIcon from 'components/ButtonWithIcon';
import { useHeader } from 'contexts/HeaderContext';
import Config from '../configs/config.json';
import { IconType, StatusCode} from '../constants';
import { useMutation, useQuery } from '@apollo/client';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import { VscMultipleWindows } from 'react-icons/vsc';
import { Stack } from '@mui/material';
import { GET_ALL_SERVER } from 'api_configs/queries';
import { DELETE_SERVER } from 'api_configs/mutations';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: row-reverse;
  width: 100%;
  > button {
    width: max-content;
  }
`;

const SpinnerBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 89vh;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.backgroundColor};
`;

const Label = styled.div`
  font-size: 16px;
`;

const TableContainer = styled.div`
  width: 100%;
  .MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle{
    font-weight: 600;
  }
  .MuiDataGrid-root .MuiDataGrid-cell{
    padding: 10px;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeaders{
    background-color: #F5F8FF;
  }
  .MuiDataGrid-root .MuiDataGrid-cellContent{
    text-align: left;
  }
  > div:nth-child(1) > div:nth-child(2){
    display: none;
  }
  > div > div:first-child{
  > div:first-child{
    font-size: 14px;
    color: #5a6269;
    > div > div > div > div:nth-child(2) > svg {
      display: none;
    }
  }
   > div:nth-child(2) {
    text-align: center;
    font-size: 14px;
    text-decoration: none;
    color:#7b8288;
    > div > div > div > div:nth-child(3){
      display: flex;
      flex-direction: column;
      row-gap: 5px;
      justify-content: center;
      align-items: initial;
    }
  }
}
`;

const ActionIcon = styled.div<{isMaster?: boolean}>`
  cursor: pointer;
  pointer-events: ${({isMaster}) => isMaster ? 'none' : 'auto'};
  opacity: ${({isMaster}) => isMaster ? '0.2' : '1'};
  width: 34px;
  height: 34px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`; 

const ActionIconsWrapper = styled.div`
  display: flex;
  column-gap: 10px;
  overflow: auto;
`;

const MasterServer = styled.div`
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 142px;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 70vh;
  gap: 10px;
`;

const NoServerWrapper = styled.div`
  width: 100%;
  border: 1px solid ${({theme}) => theme.divider};
  border-radius: 10px;
  height: 300px ;
  display: flex ;
  justify-content: center ;
  align-items: center ;
`;

const NoServerTitle = styled.div`
  font-size: 22px;
`;

interface IServerList {
  id: number,
  name: string,
  description: string,
  mdnsName: string,
  serverIp: string,
  isMaster: boolean
}

const AIServersList = () =>{
  const {t} = useTranslation(['common']);
  const [serverList, setServerList] = useState<IServerList[]>([]);
  const history = useHistory();
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'});
  const {updateHeaderTitle} = useHeader();
  const {data: getAllServers, loading, refetch: getServerRefetch} = useQuery(GET_ALL_SERVER);
  const [deleteServer] = useMutation(DELETE_SERVER,{refetchQueries: [GET_ALL_SERVER]});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<string | number>('');

  const fetchAllServers = useCallback(async()=>{
    getServerRefetch();
    const allServerResponse = await getAllServers;    
    if(allServerResponse && allServerResponse.getAllServers.statusCode === StatusCode.SUCCESS){
      setServerList(allServerResponse.getAllServers.result);
    } else {
      setServerList([]);
    }
  },[getAllServers, getServerRefetch]);

  useEffect(() => {
    fetchAllServers();
  }, [fetchAllServers]);

  useEffect(()=>{
    updateHeaderTitle(t('AI Servers'), <VscMultipleWindows color='white' size={24} />);
  },[updateHeaderTitle, t]);

  const handleEditClick = useCallback((id: string) => {
    history.push(`/settings/editServer/${id}`);
  },[history]);

  const columns: GridColDef[]  = useMemo(()=> [
    { field: 'id', headerName: t('ID'), flex: 1, headerAlign: 'left', align: 'left'},
    { field: 'name', headerName: t('Server name'), flex: 1.5, headerAlign: 'left', align: 'left', sortable: false},
    { field: 'description', headerName: t('description'), flex: 2, headerAlign: 'left', align: 'left', sortable: false,},
    { field: 'serverType', headerName: t('Master Server'), flex: 2, headerAlign: 'left', align: 'left', sortable: false,
      renderCell: (params) => {
        return (       
          <MasterServer>{params.row.serverType ? t('Yes') : t('No')}</MasterServer>);
      }
    },
    { field: 'mdnsName', headerName: t('mDNS'), flex: 2, headerAlign: 'left', align: 'left', sortable: false,},
    { field: 'serverIp', headerName: t('IP Address'), flex: 2, headerAlign: 'left', align: 'left', sortable: false,},
    {
      field: 'actions',
      headerName: '',
      flex: 1,
      align: 'left',
      sortable: false,
      renderCell: (params) => {
        return (
          <ActionIconsWrapper>
            <ActionIcon title={t('Edit')} onClick={() => handleEditClick(params?.row?.id)} >
              <Icon icon='Edit' color='primary' />
            </ActionIcon>
            <ActionIcon title={t('Delete')} isMaster={params.row.serverType} onClick={() => {setIsModalOpen(true); setSelectedId(params.row?.id);}}>
              <Icon icon='Delete' color='danger' />
            </ActionIcon>
          </ActionIconsWrapper>
        );
      }
    }
  ],[t, handleEditClick]);

  const getConfiguredColumns = useCallback(()=>{
    const configuredColumns = columns.filter((item)=>{
      return Config.ServerColumns.indexOf(item.field) !== -1;
    });
    return configuredColumns;
  },[columns]);

  const generateRowData = useCallback(() =>{
    return serverList?.length > 0 ? serverList?.map((item: IServerList)=>(
      { 
        id: item.id, 
        name: item.name,
        description: item?.description,
        mdnsName: item?.mdnsName,
        serverIp: item?.serverIp,
        serverType: item?.isMaster
      }
    )) : [];
  },[serverList]);

  const onDeleteServer = useCallback(async (id?: number) =>{
    try {
      const result = await deleteServer({variables: { payload: id}});
      if(result.data?.deleteServer?.statusCode === StatusCode.SUCCESS){
        setMessage({text: t('Server deleted succesfully'), id:'',type:'success'});
        setIsModalOpen(false);
        setSelectedId('');
        fetchAllServers();
      }else {
        setMessage({text: t('apiError'), id:'',type:'danger'});
      }
    } catch(e){
      setMessage({text: t('apiError'), id:'',type:'danger'});
    }
  },[deleteServer, fetchAllServers, t]);

  if (loading) {
    return (
      <SpinnerBox>
        <CircularProgress />
        <Label>{t('Loading...')}</Label>
      </SpinnerBox>
    );
  }

  return(
    <Container>
      {message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
      {isModalOpen &&
      <ConfirmationModal modalOpen={isModalOpen} name={''} setModalOpen={setIsModalOpen} onSubmit={()=>onDeleteServer( selectedId as number)} hasConfirmationField={false}
        titleText={t('Delete Server?')} confirmDescription={t('Are you sure you want to delete this server?')} noteText={t('Once deleted this action cannot be undone.')} />
      }
      <ButtonContainer>
        <ButtonWithIcon variant='primary' icon='Add' onClick={()=> history.push('/settings/addServer')}>{t('New')}</ButtonWithIcon>
      </ButtonContainer>
      <TableContainer>
        {(serverList?.length !== 0 && !loading) &&
        <DataGrid
          rows={generateRowData()}
          columns={getConfiguredColumns()}
          disableRowSelectionOnClick 
          disableColumnMenu
          getRowHeight={() => 'auto'}
          getRowId={(row) => row?.id}
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                {loading ? t('Loading...') : t('No Data')}
              </Stack>
            )
          }}
        />}
        {loading && <LoadingContainer>
          <CircularProgress />
        </LoadingContainer> }
        {(serverList?.length === 0 && !loading) &&
            <NoServerWrapper>
              <NoServerTitle>{t('No Servers to Show')}</NoServerTitle>
            </NoServerWrapper>}
      </TableContainer>
    </Container>
  );
};

export default AIServersList;