import React, { useCallback, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import Input from 'components/Input';
import { IMPORT_SYSTEM_CONFIGURATION } from 'api_configs/mutations';
import { useMutation } from '@apollo/client';
import { StatusCode } from '../../constants';
import { IAlertMessageType } from 'interface';
import AlertBar from 'components/AlertBar';
import { IconType } from '../../constants';
import Icon from 'components/Icon';
import Label from 'components/Label';
import CircularProgress from '@mui/material/CircularProgress';
import TokenService from 'api_configs/tokenService';

const MainWrapper = styled.div`
  width: 450px;
  margin-top: 25px;
`;

const ImportInput = styled.div`
  display: flex;
  align-items: center;
  gap: 0 15px;
  > div > input {
    border: 1px solid lightgrey;
    cursor: pointer;
  }
  > button {
    margin-top: 17px;
  }
`;

const ImportButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 15px;
  margin-top: 15px;
`;

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const UploadingSuccessLabel = styled(Label)`
  font-size: 20px;
`;

const DownloadingText = styled.div`
  color: #8b9196;
  font-size: 14px;
  line-height: 1.79;
  text-align: center;
  position: absolute;
  margin-top: 115px;
`;

const UploadingSuccessText = styled.div`
  font-size: 14px;
  line-height: 1.79;
  text-align: center;
`;

const SuccessButtonWrapper = styled.div`
  display: flex;
  gap: 0 10px;
  width: max-content;
  margin-top: 16px;
  > button:nth-child(2) {
    background-color: #e4edf4;
  }
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const UploadingErrorLabel = styled(Label)`
  font-size: 20px;
`;

const UploadingErrorText = styled(Label)`
  font-size: 14px;
  text-align: center;
`;

const SpinnerBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 70px;
  height: 100px;
  width: 450px;
  align-items: center;
  justify-content: center;
`;

const ImportSystemConfig = () =>{
  const {t} = useTranslation(['common']);
  const [selectedFile, setSelectedFile] = useState<FileList | undefined>();
  const fileInput = useRef<HTMLInputElement | null>(null);
  const [importFileMutation] = useMutation(IMPORT_SYSTEM_CONFIGURATION);
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'});
  const [imageBase64String, setImageBase64String] = useState('');
  const [loader, setLoader] = useState(false);
  const [successMessage, setSuccessMessage] = useState<boolean>(false);
  const [errorMessage, setErrorMessage]= useState<boolean>(false);
  const [mainContainer, setMainContainer] = useState(true);

  const onBrowse = useCallback(() => {
    if (fileInput.current !== null)
      fileInput.current.click();
  }, []);

  const onChangeFile = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null && event.target.files.length !== 0) {
      setSelectedFile(event.target.files);
    } else {
      setSelectedFile(undefined);
    }
  },[]);

  useEffect(() =>{
    const file = selectedFile && selectedFile[0];
    if(file){
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = (reader.result as string).split(',')[1];
        setImageBase64String(base64String);
      };
      reader.readAsDataURL(file);
    }
  },[selectedFile]);

  const onImportFile = useCallback(async() => {
    setLoader(true);
    try {
      const payload = {
        username: TokenService.getUser()?.username,
        zipContent: imageBase64String,
        zipFilename: selectedFile && selectedFile[0]?.name,
      };
      const data = await importFileMutation({variables: { payload: payload }});
      if (data?.data?.importSystemConfiguration?.statusCode === StatusCode?.SUCCESS) {
        setMessage({text: t('System configuration exported successfully.'), id:'', type:'success'});
        setLoader(false);
        setSelectedFile(undefined);
        setMainContainer(false);
        setSuccessMessage(true);
      } else {
        setMessage({text: t('Failed to communicate with the system'), id:'', type:'danger'});
        setLoader(false);
        setMainContainer(false);
        setErrorMessage(true);
      }
    } catch (error) {
      setMessage({text: t('Failed to communicate with the system'), id:'', type:'danger'});
      setLoader(false);
      setMainContainer(false);
      setErrorMessage(true);
      console.error((error as Error).message);
    }
  },[selectedFile, t, imageBase64String, importFileMutation]);

  if (loader) {
    return (
      <SpinnerBox>
        <CircularProgress />
        <DownloadingText>{t('Please do not navigate away from this page while uploading. We will let you know once this has been completed.')}</DownloadingText>
      </SpinnerBox>
    );
  }

  return(
    <MainWrapper>
      {message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
      {successMessage &&
        <SuccessContainer>
          <Icon color='success' icon='Success' size={40} weight='regular' />
          <UploadingSuccessLabel labelText={t('Import Completed')} />
          <UploadingSuccessText>{t('A file has been imported successfully. You may close this page now.')} </UploadingSuccessText>
          <SuccessButtonWrapper>
            <Button variant='primary' onClick={() => {setMainContainer(true); setSuccessMessage(false); setSelectedFile(undefined);}}>{t('Import Again')}</Button>
          </SuccessButtonWrapper>
        </SuccessContainer>}
      {errorMessage && 
        <ErrorContainer>
          <Icon icon='Warning' color='danger' size={40} weight='regular' />
          <UploadingErrorLabel labelText={t('Error Occured')} />
          <UploadingErrorText labelText={t('An error occurred during import')} />
          <SuccessButtonWrapper>
            <Button variant='primary' onClick={() => {setErrorMessage(false); onImportFile();}}>{t('Try Again')}</Button>
            <Button variant='secondary' onClick={() => {setErrorMessage(false); setSelectedFile(undefined); setMainContainer(true);}}>{t('Cancel')}</Button>
          </SuccessButtonWrapper>
        </ErrorContainer>}
      {mainContainer && <> <ImportInput>
        <input type='file' ref={fileInput} onChange={onChangeFile} accept='.zip' style={{ display: 'none' }} />
        <Input type='text' label={t('Please select a zip file')} placeholder={t('No file selected')} value={selectedFile ? selectedFile[0].name : ''} onClick={onBrowse}/>
        <Button variant='primary' onClick={onBrowse} >{t('Browse')}</Button>
      </ImportInput>
      <ImportButton>
        <Button variant='primary' onClick={onImportFile} disabled={selectedFile ? false : true} >{t('Import')}</Button>
        <Button variant='secondary' disabled={selectedFile ? false : true} onClick={() => setSelectedFile(undefined)}>{t('Clear')}</Button>
      </ImportButton></> }
    </MainWrapper>
  );
};

export default ImportSystemConfig;