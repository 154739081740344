import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

interface ISwitch {
  checked: boolean,
  disabled?: boolean
}

const StyledSwitch = styled.label`
  position: relative;
  display: flex;
  width: 40px;
  height: 20px;
`;

const StyledCheckbox = styled.input<{disabled:boolean}>`
  opacity: 0;
  width: 0;
  height: 0;
  margin-left: 50px;
  &:checked + .slider {
    background: ${({disabled}) => (disabled ? '#7287D7': '#435FCA')};
  }
  &:checked + .slider:before {
    transform: translateX(26px);
  }
`;

const StyledSlider = styled.span<{ checked: boolean; disabled: boolean; isInitialized: boolean }>`
  position: absolute;
  cursor: ${({disabled}) => disabled ? 'not-allowed': 'pointer'};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #D0D7F2;
  border-radius: 24px;
  transition: ${({ isInitialized }) => (isInitialized ? 'background-color 0.2s' : 'none')};
  &:before {
    position: absolute;
    content: '';
    height: 14px;
    width: 14px;
    left: ${({checked}) => checked ? '-3px': '3px'};
    bottom: 3px;
    border-radius: 50%;
    transition: ${({ isInitialized }) => (isInitialized ? 'transform 0.2s ease 0s' : 'none')};
    background-color: rgb(255, 255, 255);
  }
`;

type InputProps = ISwitch & React.InputHTMLAttributes<HTMLInputElement>

const Switch = ({checked, onChange, disabled=false, ...props}: InputProps) => {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsInitialized(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <StyledSwitch>
      <StyledCheckbox
        type='checkbox'
        checked={checked}
        onChange={onChange}
        {...props}
        disabled={disabled}
      />
      <StyledSlider className='slider' checked={checked} disabled={disabled} isInitialized={isInitialized} />
    </StyledSwitch>
  );
};

export default Switch;