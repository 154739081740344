import { useMutation, useQuery } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import { DOWNLOAD_DETECTIONS, GET_ALL_DETECTIONS, GET_ALL_ALGORITHMS, GET_STREAM } from 'api_configs/queries';
import Icon from 'components/Icon';
import { SERVER_URL, StatusCode, IconType, DETECTION_IMG_BASE_URL, PERMISSION_CODE, DetectionMessageType } from '../constants';
import { useHeader } from 'contexts/HeaderContext';
import { IAlertMessageType, IGetAllAlgorithmRes, IStream } from 'interface';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router';
import styled from 'styled-components';
import SearchInputFilter, { IAppliedFilter, ISearchQueryKeys } from 'components/SearchInputFilter';
import { DataGrid, GridColDef, GridRowSelectionModel, GridSortModel } from '@mui/x-data-grid';
import Thumbnail from 'components/Thumbnail';
import AlertBar from 'components/AlertBar';
import Modal from 'components/Modal';
import Switch from 'components/Switch';
import { getOnlyDate, getTimeSince } from 'utils';
import AwaitingStreamEng from '../svgs/Awaiting_Stream-ENG.svg';
import Config from '../configs/config.json';
import Pagination from 'components/Pagination';
import Button from 'components/Button';
import ButtonWithLoading from 'components/ButtonWithLoading';
import TokenService from 'api_configs/tokenService';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import { DELETE_DETECTIONS } from 'api_configs/mutations';

const MainContainer = styled.div<{isDetectionTab:boolean}>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: ${(prop) => prop.isDetectionTab  ? '1480px' : '100%'};
  padding: ${(prop) => prop.isDetectionTab ? '0px' : '20px'};
`;

const DetectionPageContainer = styled.div<{isDetectionTab: boolean}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  @media only screen and (min-width: 1920px){
    width: ${(prop) => prop.isDetectionTab  ? '1480px' : '1700px'};
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 70vh;
  gap: 10px;
`;

const SpinnerBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 89vh;
  align-items: center;
  justify-content: center;
`;

const Label = styled.span``;

const DetectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
`;

const DetectionTableContainer = styled.div`
  width: 100%;
  .MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }
  > div:nth-child(1) > div:nth-child(2){
    display: none;
  }
  > div:first-child {
    > div:nth-child(1) {
      > div:nth-child(2){
        min-height: 300px;
      }
    }
  }
  > div > div:first-child{
    > div:first-child{
      background-color: #F5F8FF;
      font-size: 14px;
      color: #5a6269;
      > div > div > div > div:nth-child(2) > svg {
        display: none;
      }
    }
    > div:nth-child(2) {
      text-align: center;
      font-size: 14px;
      text-decoration: none;
      color:#7b8288;
    }
  }
`;

const NoDetectionWrapper = styled.div`
  width: 100%;
  border: 1px solid ${({theme}) => theme.divider};
  border-radius: 10px;
  height: 300px ;
  display: flex ;
  justify-content: center ;
  align-items: center ;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 10px 40px;
  align-items: center;
`;

const SwitchLabel = styled.div`
  min-width: 100px;
  margin-bottom: auto;
  font-size: 20px;
`;

const EnableCameraWrapper = styled.div`
  display: flex;
  column-gap: 20px;
`;

const DownloadDetectionSettingLabel = styled.div`
  font-size: 22px;
`;

const PaginationBox = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  padding-bottom: 10px;
`;

const DetectionInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

const DetectionInfoLabel = styled.div`
  font-weight: 400;
  font-size: 14px;
`;

const DetectionInfoText = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: ${({theme}) => theme.text.primaryText};
`;

const MetaWrapper = styled.div`
  display: flex;
  gap: 40px;
`;

const ActionIcons = styled.div`
  display: flex;
  column-gap: 10px;
`;

const DownloadIconWrapper = styled.div`
  cursor: pointer;
  width: 34px;
  height: 34px;
  border-radius: 5px;
  border: 1px solid ${({theme}) => theme.divider};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CircularProgressWrapper = styled.div`
  width: 34px;
  height: 34px;
  > span{
    width: 24px !important;
    height: 24px !important;
    margin-top: 6px !important;
  }
`;

const CameraName = styled.div`
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  > button {
     height: 40px;
  }
`;

const NoPermissionMessage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoDetectionTitle = styled.div`
  font-size: 22px;
`;

interface IParams {
  instanceId: string;
}

interface IFilterValues {
  streamId?: string;
  analysisType?: string;
}

interface IDetectionDataType {
  Message: string;
}

interface IDetectionData {
  id?: number;
  status?: string;
  displayName?: string;
  detectionDatetime?: string;
  imagePath?: string;
  detectionData?: IDetectionDataType;
  algoCode?: string;
  streamId?: string;
}

interface INewParams {
  streamId?: string | null;
  analysisType?: string | null;
}

const FILTER_DATA: IFilterValues = { analysisType: '', streamId: ''};

interface IProps {
  isDetectionTab: boolean;
}

const Detections : React.FC<IProps> = ({isDetectionTab}) => {
  const {instanceId}: IParams = useParams();
  const [loader, setLoader] = useState(false);
  const [streamDetails, setStreamDetails] = useState<IStream>();
  const {t} = useTranslation(['common']);
  const {data:getStreamData, loading: isStreamLoading, refetch: getStreamRefetch} = useQuery(GET_STREAM, { variables: {streamId: instanceId} });
  const {updateHeaderTitle} = useHeader();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount ] = useState(1);
  const {replace} = useHistory();
  const [isNewCharts, setIsNewCharts] = useState(false);
  const params = useLocation().search;
  const [historyParams] = useState(params);
  const [filterValues, setFilterValues] = useState<IFilterValues>({streamId: '', analysisType: ''});
  const [loaderHistory, setLoaderHistory] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<GridRowSelectionModel>([]);
  const [isModalOpen,setIsModalOpen] = useState(false);
  const [downloadDetectionsReqData, setDownloadDetectionsReqData] = useState({image: true, video: false});
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'}); 
  const {refetch: downloadDetectionsRefetch} = useQuery(DOWNLOAD_DETECTIONS);
  const [sorting, setSorting] = useState<GridSortModel>([]);
  const {data, loading, refetch } = useQuery(GET_ALL_DETECTIONS);
  const [detectionData, setDetectionData] = useState([]);
  const [deleteDetectionMutation] = useMutation(DELETE_DETECTIONS);
  const history = useHistory();
  const [delectionLoading, setDetectionLoading] = useState<boolean>(false);
  const [filteredAlgos, setFilteredAlgos] = useState<string[]>([]);
  const [filterName, setFilterName] = useState<string>('');
  const [dateRange, setDateRange] = useState<string[]>(['','']);
  const [algorithmList, setAlgorithmList] = useState<IGetAllAlgorithmRes[]>([]);
  const { data: allAlgorithms } = useQuery(GET_ALL_ALGORITHMS);
  const [downloadDetectionLoader, setDownloadDetectionLoader] = useState(false);
  const [selectedDownloadId, setSelectedDownloadId] = useState(0);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedDetectionId, setSelectedDetectionId] = useState<number>(0);
  const downloadDetectionsAccess = TokenService.hasAccess(PERMISSION_CODE.download_alert); // We will update permissions once detections permissions are created as of now Using alerts permissions.
  const deleteDetectionsAccess = TokenService.hasAccess(PERMISSION_CODE.delete_alert);
  const viewDetectionsListAccess = TokenService.hasAccess(PERMISSION_CODE.alert_list);

  useEffect(()=>{
    setLoader(loading);
  },[loading]);

  useEffect(() => {
    if(isNewCharts) {
      setTimeout(() => {
        setIsNewCharts(false);
      }, 2000);
    }
  }, [isNewCharts]);

  useEffect(()=>{
    const allAlgorithmsData: IGetAllAlgorithmRes[] = allAlgorithms?.getAllAlgorithm?.result !== null ? allAlgorithms?.getAllAlgorithm?.result: [];
    if(allAlgorithmsData?.length > 0){
      const configuredAlgos = allAlgorithmsData?.filter((algorithm)=>{
        return Config.algorithmConfigurations.configuredAlgos.indexOf(algorithm.code) !== -1;
      });
      setAlgorithmList(configuredAlgos);
    }
  },[allAlgorithms]);

  const onDeleteDetection = useCallback(async () => {
    try {
      const result = await deleteDetectionMutation({variables: { detectionIdList: selectedDetectionId ? [selectedDetectionId] : selectedId }});
      if(result.data.deleteDetections.statusCode === StatusCode.SUCCESS){
        setMessage({text: t('Detections deleted successfully'), id:'',type:'success'});
        refetch();
        setSelectedDetectionId(0);
        setModalOpen(false);
      }else {
        setMessage({text: t('apiError'), id:'',type:'danger'});
      }
    } catch(e){
      setMessage({text: t('apiError'), id:'',type:'danger'});
    }
  },[refetch, selectedDetectionId, selectedId, t, deleteDetectionMutation]);

  const handleChange = (value: number) => {
    setCurrentPage(value);
  };

  useEffect(()=>{
    if(!isDetectionTab){
      updateHeaderTitle(t('Detections'), 'AlertOctagon');
    }
  },[updateHeaderTitle, t, isDetectionTab]);
  
  useEffect(()=>{
    setDetectionData(data?.getDetections?.result?.data ?? []);
    if(data?.getDetections?.count){
      setPageCount(data?.getDetections?.count);
    }
  },[data?.getDetections]);

  useEffect(()=>{
    getStreamRefetch();
    const result: IStream = getStreamData?.getStream?.result !== null &&  getStreamData?.getStream?.result !== undefined ? getStreamData?.getStream?.result : null;
    setStreamDetails(result);
  },[getStreamData, getStreamRefetch]);

  useEffect(() => {
    const fetchDetections = async (skipLoadingState = false) => {
      if (!skipLoadingState) {
        setDetectionLoading(true);
      }

      if (refetch) {
        await refetch({
          payload: {
            pageSize,
            pageNumber: currentPage,
            sortOrder: sorting[0]?.sort,
            streamId: streamDetails?.instanceId,
            algoCodeList: filteredAlgos,
            startDate: dateRange[0],
            endDate: dateRange[1],
            searchField: filterName,
          },
        });
        if (!skipLoadingState) {
          setDetectionLoading(false);
        }
      }
    };
    fetchDetections();
    const fetchInterval = setInterval(() => {
      fetchDetections(true);
    }, 5000);
  
    return () => {
      clearInterval(fetchInterval);
    };
  }, [currentPage, pageSize, refetch, sorting, streamDetails, filteredAlgos, dateRange, filterName]);

  const handleSaveDetections = useCallback(async (id?: number) => {
    setDownloadDetectionLoader(true);
    setDownloadLoading(true);
    try {
      const res = await downloadDetectionsRefetch({payload: {detectionIdList: id ? [id] : selectedId, includeImages: downloadDetectionsReqData.image , includeVideos: downloadDetectionsReqData.video}});
      if (res.data?.downloadDetections.statusCode === StatusCode.SUCCESS) {
        setTimeout(() =>{
          setSelectedId([]);
          setIsModalOpen(false);
          downloadLink(SERVER_URL + res.data?.downloadDetections.result.file);
          setMessage({ text: t('Detection data downloaded successfully'), id: '', type: 'success' });
          setDownloadDetectionLoader(false);
          setDownloadLoading(false);
        },1000);
      } else {
        setMessage({ text: t('apiError'), id: '', type: 'danger' });
        setTimeout(() =>{
          setDownloadDetectionLoader(false);
          setDownloadLoading(false);
        },1000);
      }
    } catch (err) {
      console.error(err);
      setMessage({ text: t('apiError'), id: '', type: 'danger' });
      setTimeout(() =>{
        setDownloadDetectionLoader(false);
        setDownloadLoading(false);
      },1000);
    }
  },[downloadDetectionsRefetch, downloadDetectionsReqData, selectedId, t]);

  const handleImageSwitchChange = () => {
    const updateData = {...downloadDetectionsReqData, image: !downloadDetectionsReqData?.image};
    setDownloadDetectionsReqData(updateData);
    localStorage.setItem('Download_Detection_Setting', JSON.stringify(updateData));
  };

  const handleVideoSwitchChange = () => {
    const updateData = {...downloadDetectionsReqData, video: !downloadDetectionsReqData?.video};
    setDownloadDetectionsReqData(updateData);
    localStorage.setItem('Download_Detection_Setting', JSON.stringify(updateData));
  };

  const fetchHistoryParams = useCallback(() => {
    setLoaderHistory(true);
    const params = new URLSearchParams(historyParams);
    const newParams: INewParams = {};
    newParams.analysisType = params.get('analysisType');
    newParams.streamId = params.get('streamId');

    const searchFilter = { ...FILTER_DATA };
    searchFilter.streamId = newParams.streamId ?? '';
    searchFilter.analysisType = newParams.analysisType ?? '';

    const searchValues = window.localStorage.getItem('Camera_Mgmt_DetectionsTabFilter');
    if(searchValues) {
      const parseValues = JSON.parse(searchValues);
      searchFilter.streamId = parseValues.streamId ?? '';
      searchFilter.analysisType = parseValues.analysisType ?? '';
      setFilterValues(parseValues);
    } 

    setFilterValues(searchFilter);
    setTimeout(()=>{
      setLoaderHistory(false);
    }, 0);
  }, [historyParams]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedId([]);
  };

  useEffect(() => {
    fetchHistoryParams();
  }, [fetchHistoryParams]);

  const searchQueryKeys: ISearchQueryKeys[] = [
    {keyName: 'name', displayName: t('CameraName')},
  ];

  const setParams = useCallback(() => {
    const params = '?' +
      `${filterValues.analysisType !== '' ? '&analysisType=' + filterValues.analysisType : ''}` +
      `${filterValues.streamId !== '' ? '&streamId=' + filterValues.streamId : ''}`;

    if (params === '?') {
      return '';
    }
    return params;
  }, [filterValues]);

  useEffect(() => {
    replace(window.location.pathname + setParams());
  }, [replace, setParams, filterValues]);

  const columns: GridColDef[] = useMemo(()=>
    [
      {
        field: 'thumbnail',
        headerName: '',
        width: 70,
        sortable: false,
        renderCell: (params) => (
          <Thumbnail src={ params?.row?.image ? DETECTION_IMG_BASE_URL + params?.row?.image : AwaitingStreamEng} size={40} />
        )
      },
      { 
        field: 'cameraName',
        headerName: t('Camera Name '),
        flex: 1,
        headerAlign: 'left',
        align: 'left',
        sortable: false,
        renderCell: (params) => {
          return (
            <CameraName onClick={() => history.push(`/cameras/${params?.row?.streamId}`)}>{params?.row?.displayName}</CameraName>
          );
        }
      },
      { field: 'message', headerName: t('Message'), flex: 1, headerAlign: 'left', align: 'left', sortable: false },
      { 
        field: 'meta',
        headerName: t('Meta'),
        flex: 2, 
        headerAlign: 'left',
        align: 'left', 
        sortable: false,
        renderCell: (params) => {
          const meassage: Record<string, string | number> = params?.row?.meta;
          const formattedItems = Object.entries(meassage ?? {}).map(([key, value]) => {
            const formattedKey = key.split('_').map((word, index) => (index === 0 ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : word.toLowerCase())).join(' ');
            return { key: formattedKey, value };
          });

          return (
            <MetaWrapper>
              {formattedItems?.map((item) => (
                <DetectionInfoWrapper>
                  <DetectionInfoLabel>{item.key}</DetectionInfoLabel>
                  <DetectionInfoText>{item.value} {params.row.unit}</DetectionInfoText>
                </DetectionInfoWrapper>
              ))}
            </MetaWrapper>
          );
        }
      },
      { field: 'timestamp', 
        headerName: t('Reported Time'), 
        flex: 1, 
        headerAlign: 'left', 
        align: 'left',
        renderCell: (params) => {
          return (
            <DetectionInfoWrapper>
              <DetectionInfoLabel>{getOnlyDate(params?.row?.timestamp)}</DetectionInfoLabel>
              <DetectionInfoText>{getTimeSince(params?.row?.timestamp)}</DetectionInfoText>
            </DetectionInfoWrapper>
          );
        }
      },
      {
        field: 'actions',
        headerName: '',
        flex: 1.3,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        renderCell: (params) => {
          return (
            <ActionIcons>
              { downloadDetectionLoader && selectedDownloadId === params?.row?.id ?
                <CircularProgressWrapper><CircularProgress /> </CircularProgressWrapper>
                : 
                <DownloadIconWrapper onClick={() => {handleSaveDetections(params?.row?.id); setSelectedDownloadId(params?.row?.id);}}>
                  <Icon icon='Download' color='primary' />
                </DownloadIconWrapper>
              }
              { <DownloadIconWrapper onClick={() => {setSelectedDetectionId(params?.row?.id); setModalOpen(!modalOpen);}}>
                <Icon icon='Delete' color='danger' />
              </DownloadIconWrapper>}
            </ActionIcons>
          );
        }
      }
    ]
  ,[t, handleSaveDetections, history, modalOpen, downloadDetectionLoader, selectedDownloadId]); 

  const getConfiguredColumns = useCallback(()=>{
    const configuredColumns = columns.filter((item)=>{
      if(isDetectionTab){
        return Config.DetectionTabColumns.indexOf(item.field) !== -1;
      }else{
        return Config.DetectionsColumns.indexOf(item.field) !== -1;
      }
    });
    return configuredColumns;
  },[columns, isDetectionTab]);

  const generateRowData = useCallback(() =>{
    return detectionData?.length > 0 ? detectionData?.map((item: IDetectionData)=>(
      { 
        id: item.id, 
        message: DetectionMessageType[item.algoCode as string]?.message, 
        unit: DetectionMessageType[item.algoCode as string]?.unit,
        meta: item.detectionData ?? '' ,
        timestamp: item?.detectionDatetime ?? '',
        status: item?.status,
        image: item?.imagePath,
        streamId: item?.streamId,
        displayName: item?.displayName
      }
    )) : [];
  },[detectionData]);

  const handleRowSelectionModelChange = (params: GridRowSelectionModel) => {
    setSelectedId(params ? params : []);
  };

  const getAlgoCode = useCallback((algoName: string)=>{
    const algos = algorithmList && algorithmList.find(item => item.name === algoName);
    return algos?.code;
  },[algorithmList]);

  const searchFilterCallback = useCallback((appliedFilters: IAppliedFilter[])=>{
    if(appliedFilters.length > 0){
      const analysisConfigsIndex = appliedFilters.findIndex(item =>item.keyName === 'analysis');
      if(analysisConfigsIndex !== -1){
        const filterdAlgoNames = (appliedFilters[analysisConfigsIndex].query as string[]).map(item => getAlgoCode(item));
        setFilteredAlgos(filterdAlgoNames as string[]);
      }else{
        setFilteredAlgos([]);
      }
      const dateRangeFilter = appliedFilters.findIndex(item =>item.keyName === 'dateRange');
      if(dateRangeFilter !== -1){
        const filteredDateRange = appliedFilters[dateRangeFilter].query ?? [];
        setDateRange(filteredDateRange as string[]);
      }else{
        setDateRange(['','']);
      }

      const cameraFilter = appliedFilters.findIndex(item =>item.keyName === 'name');
      if(cameraFilter !== -1){
        const filterName = appliedFilters[cameraFilter].query ?? '';
        setFilterName(filterName as string);
      }else{
        setFilterName('');
      }
    }else{
      setFilteredAlgos([]);
      setDateRange(['','']);
      setFilterName('');
    }
  },[getAlgoCode]);

  const getLoading = () => {
    return (
      <LoadingContainer>
        <CircularProgress />
        <Label>{t('Loading')}</Label>
      </LoadingContainer>
    );
  };

  const handleSortModelChange = (val: GridSortModel) =>{
    setSorting(val);
  };

  const onCancelYes = useCallback(()=>{
    onDeleteDetection();
  },[onDeleteDetection]);

  const onCancelNo = () => {
    setSelectedDetectionId(0);
  };

  if ((loader || loaderHistory) && !isDetectionTab) {
    return (
      <SpinnerBox>
        <CircularProgress />
        <Label>{t('Loading...')}</Label>
      </SpinnerBox>
    );
  }

  return (
    <MainContainer isDetectionTab={isDetectionTab}> 
      {
        modalOpen && 
        <ConfirmationModal modalOpen={modalOpen} setModalOpen={setModalOpen} onSubmit={onCancelYes} onCancelNo={onCancelNo} hasConfirmationField={false}
          titleText={t('Delete Detections?')} confirmDescription={t('Are you sure you want to delete this detection?')} noteText={t('Once deleted this action cannot be undone.')} />}
      {message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
      { isModalOpen &&
       <Modal isModalOpen={isModalOpen} width='32vw' closeModal={handleCloseModal}>
         <ModalContent>
           <DownloadDetectionSettingLabel>{t('Download Detection Setting')}:</DownloadDetectionSettingLabel>
           <EnableCameraWrapper>
             <SwitchLabel>{t('Include Images')}:</SwitchLabel>
             <Switch checked={downloadDetectionsReqData.image} onChange={handleImageSwitchChange}/>
           </EnableCameraWrapper>
           <EnableCameraWrapper>
             <SwitchLabel>{t('Include Videos')}:</SwitchLabel>
             <Switch checked={downloadDetectionsReqData.video} onChange={handleVideoSwitchChange}/>
           </EnableCameraWrapper>
         </ModalContent>
       </Modal>
      }
      { isStreamLoading ? getLoading() : 
        <DetectionPageContainer isDetectionTab={isDetectionTab}>
          <DetectionContainer>
            {selectedId.length > 0 && isModalOpen === false ? 
              <ButtonsContainer>
                {downloadDetectionsAccess && <ButtonWithLoading loading={downloadLoading} variant='primary' onClick={() => handleSaveDetections()}>{t('Download')}</ButtonWithLoading>}
                {deleteDetectionsAccess && <Button variant='danger' onClick={() => setModalOpen(!modalOpen)}>{t('Delete')}</Button>}
              </ButtonsContainer>:
              <SearchInputFilter showAlgorithm showDateRange searchFilterCallback={searchFilterCallback} algorithmList={algorithmList} searchQueryKeys={searchQueryKeys} placeholder={t('search by Camera Name...')} />}
            {(detectionData?.length !== 0 && !delectionLoading) && viewDetectionsListAccess && <DetectionTableContainer>
              <DataGrid
                rows={generateRowData()}
                columns={getConfiguredColumns()}
                checkboxSelection={true}
                disableRowSelectionOnClick
                disableColumnMenu
                onRowSelectionModelChange={handleRowSelectionModelChange}
                rowSelectionModel={selectedId}
                sortingMode='server'
                onSortModelChange={handleSortModelChange}
                getRowHeight={(params) => {
                  if (params?.model.timestamp) {
                    const messageKeys = Object.keys(params.model.timestamp);
                    if (messageKeys.length === 2) {
                      return 100;
                    } else if (messageKeys.length === 3) {
                      return 100;
                    } 
                    else {
                      return 100;
                    }
                  } else {
                    return 50;
                  }
                }}
              /> 
              <PaginationBox>
                <Pagination
                  onChange={handleChange}
                  pageSize={pageSize} 
                  count={Math.ceil(pageCount/pageSize)} 
                  setPageSize={(e) => setPageSize(e)}
                  currentPage={currentPage}
                />
              </PaginationBox>
            </DetectionTableContainer>}
            {delectionLoading && viewDetectionsListAccess && <LoadingContainer>
              <CircularProgress />
            </LoadingContainer> }
            {(detectionData?.length === 0 && !delectionLoading) && viewDetectionsListAccess &&
            <NoDetectionWrapper>
              <NoDetectionTitle>{t('No Detections to Show')}</NoDetectionTitle>
            </NoDetectionWrapper>}
            { !viewDetectionsListAccess &&
            <NoDetectionWrapper>
              <NoPermissionMessage>{t('Unfortunately, you do not have permission to view Detections list at this time. Please contact your administrator for assistance.')}</NoPermissionMessage>
            </NoDetectionWrapper>
            }
          </DetectionContainer>
        </DetectionPageContainer>
      }
    </MainContainer>
  );
};
export default Detections;

const downloadLink = (link: string) => {
  if(!link){return;}
  const anchor = document.createElement('a');
  anchor.href = link;
  document.body.appendChild(anchor);
  anchor.click();
  anchor.remove(); 
};