import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgLine = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M7.194 15.637c0 1.033-.83 1.864-1.847 1.864A1.855 1.855 0 0 1 3.5 15.637c0-1.033.83-1.864 1.847-1.864s1.847.832 1.847 1.864ZM21.277 7.364c0 1.032-.83 1.863-1.847 1.863a1.855 1.855 0 0 1-1.847-1.863c0-1.033.83-1.864 1.847-1.864s1.847.831 1.847 1.864ZM7.107 14.454l10.563-5.91"
        stroke={props.color}
        vectorEffect={"non-scaling-stroke"}
      />
    </svg>
  );
};
export default SvgLine;
