import React, { useState, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Icon from './Icon';
import { BsTelephone } from 'react-icons/bs';
import { WHATS_APP_MAX_NUMBERS } from '../constants';

const InputFieldWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 40px;
  padding: 6px;
  border: 1px solid hsla(120,1.3%,85.3%,1.000);
  color: hsla(207,5%,56.7%,1.000);
  border-radius: 3px;
  &:focus {
    outline: none;
    border-color: 1px solid hsla(120,1.3%,85.3%,1.000);
  }
  > ul > input {
    flex: 1;
    border: none;
    height: 25px;
    font-size: 14px;
    padding: 0 0 0 8px;
    border-radius: 3px;
    max-width: 150px;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  [type=number] {
    -moz-appearance: textfield;
  }
    &:focus {
      outline: transparent;
    }
    ::placeholder {
      color: hsla(207,5%,56.7%,1.000);
      opacity: 0.65;
      font-size: 12px;
      font-style: italic;
    }
  }
`;

const NumbersList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  padding: 0;
  max-width: 600px;
  align-items: baseline;
`;

const NumberLabel = styled.li`
  width: auto;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(96, 96, 96);
  gap: 6px;
  padding: 0 6px;
  font-size: 12px;
  list-style: none;
  border-radius: 6px;
  background: rgb(235, 232, 232);
`;

const NumberTitle = styled.span`
  color: #808080;
  max-width: 96px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const NumberListClose = styled.span`
  cursor: pointer;
`;

const Container = styled.div`
  min-width: 356px;
  position: relative;
  height: 100%;
  outline: none;
  width: 100%;
`;

const IconWrapper = styled.div`
  margin-top: 5px;
`;

interface IWhatsAppNumberField{
  selectedNumbers: (data: string[]) => void;
  WhatsAppNumberList: string[];
  onSelect: (data: string[])=> void;
  isEmptyNumberList?: boolean;
  readOnlyMode?: boolean;
  onlyNumbers?: boolean;
}

const WhatsAppNumberField: React.FC<IWhatsAppNumberField> = ({ selectedNumbers, WhatsAppNumberList,onlyNumbers=true, onSelect, isEmptyNumberList, readOnlyMode}) => {
  const [whatsAppNumbers, setWhatsAppNumbers] = React.useState<string[]>(WhatsAppNumberList);
  const [expanded, setExpanded] = useState<boolean>(false);
  const {t} = useTranslation(['common']);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const removeNumbers = (indexToRemove: number) => {
    setWhatsAppNumbers([...whatsAppNumbers.filter((_: string, index: number) => index !== indexToRemove)]);
    selectedNumbers([...whatsAppNumbers.filter((_: string, index: number) => index !== indexToRemove)]);
    onSelect([...whatsAppNumbers.filter((_: string, index: number) => index !== indexToRemove)]);
  };

  const addNumbers = (event: React.SyntheticEvent<EventTarget>) => {
    const value = (event.target as HTMLInputElement).value;
    const numberValue = value.trim();
    if (numberValue !== '' && onlyNumbers && numberValue.length === 10) {
      setWhatsAppNumbers([...whatsAppNumbers, value]);
      selectedNumbers([...whatsAppNumbers, value]);
      onSelect([...whatsAppNumbers, value]);
      (event.target as HTMLInputElement).value = '';
    }else if(numberValue !== '' && !onlyNumbers){
      setWhatsAppNumbers([...whatsAppNumbers, value]);
      selectedNumbers([...whatsAppNumbers, value]);
      onSelect([...whatsAppNumbers, value]);
      (event.target as HTMLInputElement).value = '';
    }
  };

  useEffect(() => {
    if(isEmptyNumberList){
      setWhatsAppNumbers([]);
    }
  },[isEmptyNumberList]);

  const toggle = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const onBlur = useCallback((e: React.SyntheticEvent) => {
    const currentTarget = e.currentTarget;
    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        setExpanded(false);
      }
    }, 0);
  }, []);

  const onlyNumber = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = event.keyCode ? event.keyCode : event.which;
    if(onlyNumbers){
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 190) {
        event.preventDefault();
      }
    }
  },[onlyNumbers]);

  const handleWrapperClick = useCallback(() => {
    if(inputRef.current){
      inputRef.current.focus();
    }
  },[]);

  return (
    <Container tabIndex={0} onBlur={onBlur} onClick={handleWrapperClick}>
      <InputFieldWrapper>
        <NumbersList>
          {whatsAppNumbers?.map((whatsAppNumber: string, index: number) => (
            <NumberLabel key={index}>
              { !readOnlyMode && onlyNumbers && <IconWrapper>
                <BsTelephone size={12} />
              </IconWrapper>}
              <NumberTitle title={whatsAppNumber}>{whatsAppNumber}</NumberTitle>
              { !readOnlyMode && <NumberListClose onClick={() => removeNumbers(index)}><Icon icon='Cross' size={16} color='dimmed' /></NumberListClose>}
            </NumberLabel>
          ))}
          <input
            ref={inputRef}
            style={{display:  (whatsAppNumbers.length === WHATS_APP_MAX_NUMBERS || readOnlyMode) ? 'none' : 'block'}}
            type='text'
            autoFocus
            onKeyUp={event => event.key === 'Enter' || (onlyNumbers && (event.target as HTMLInputElement).value.length === 10) ? addNumbers(event) : null}
            onClick={toggle}
            maxLength={64}
            placeholder={!readOnlyMode ? whatsAppNumbers.length === WHATS_APP_MAX_NUMBERS ? '' : t(`Enter ${onlyNumbers ? 'number': 'email'}`) : ''}
            disabled={readOnlyMode || whatsAppNumbers.length === WHATS_APP_MAX_NUMBERS}
            onKeyPress={(event: React.FormEvent<HTMLInputElement>) => onlyNumber(event as unknown as React.KeyboardEvent<HTMLInputElement>)}
          />
        </NumbersList>
      </InputFieldWrapper>
    </Container>
  );
};

export default WhatsAppNumberField;