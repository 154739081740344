import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgSwap = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M11.667 8.25 8.333 5m0 0L5 8.25M8.333 5v13m5-3.25L16.667 18m0 0L20 14.75M16.667 18V5"
        stroke={props.color}
        strokeWidth={props.weight}
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={"non-scaling-stroke"}
      />
    </svg>
  );
};
export default SvgSwap;
