import Input from 'components/Input';
import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { VIDEO_SOURCE_TYPE, StreamType, AlertTypes, IconType, StatusCode, TRANSPORT_ENCAPSULATION_VALUES, PERMISSION_CODE } from '../constants';
import SelectField from 'components/SelectField';
import Button from 'components/Button';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import AlertBar from 'components/AlertBar';
import Switch from 'components/Switch';
import TextArea from 'components/TextArea';
import { CREATE_NEW_CAMERA, CREATE_NEW_FOLDER, DELETE_FOLDER, DELETE_STREAM, PATCH_HLS_CAMERA, PATCH_RTMP_CAMERA, PATCH_RTP_CAMERA, PATCH_RTSP_CAMERA, PATCH_USB_CAMERA, UPDATE_FOLDER } from '../api_configs/mutations';
import { useMutation, useQuery } from '@apollo/client';
import { GET_RTSP_STREAM, GET_USB_STREAM, GET_RTMP_STREAM, GET_HLS_STREAM, GET_RTP_STREAM, GET_ALL_GROUPS, GET_ALL_SERVER, GET_FOLDER } from 'api_configs/queries';
import RTSPCamera from './camera-pages/RTSPCamera';
import USBCamera from './camera-pages/USBCamera';
import RTPCamera from './camera-pages/RTPCamera';
import RTMPCamera from './camera-pages/RTMPCamera';
import HLSCamera from './camera-pages/HLSCamera';
import CancelConfirmationModal from './configurations/CancelConfirmationModal';
import { CircularProgress } from '@mui/material';
import TextOnlyConfirmationModal from 'components/modals/TextOnlyConfirmationModal';
import ButtonWithLoading from 'components/ButtonWithLoading';
import { useHeader } from 'contexts/HeaderContext';
import { IBreadcrum } from 'interface';
import Label from 'components/Label';
import MultipleSelect from 'components/MultiSelectField';
import TokenService from 'api_configs/tokenService';
import { usernameRegex } from 'utils/utils';
import Config from '../configs/config.json';
import awsConfig from '../lib/amplify';
import { useIdentityID } from 'hooks/useIdentityID';

const FormWrapper = styled.div`
  margin-left: -16px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  min-height: calc(100vh - 120px);
  padding: 20px 20px 20px 0;
  background-color: ${({ theme }) => theme.backgroundColor};
`;

const SwitchLabel = styled.span`
  min-width: 65px;
`;

const BasicCameraInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 1060px;
`;

const BasicConfigurationWrapper = styled.div`
  display: flex;
  width: 1080px;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 768px) {
    width: 530px;
  }
`;

const NewCameraWrapper = styled.div`
  margin-top: -8px;
  width: 1080px;
  background-color: ${({theme}) => theme.backgroundColor};
  border-radius: 5px;
  @media (max-width: 768px) {
    width: 505px;
  }
`;

const LeftSideWrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
  > div > select {
    color: #000000
  }
`;

const RightSideWrapper = styled.div`
  padding: 0 0 0 20px;
  display: flex;
  flex-direction: column;
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
  > div > select {
    color: #000000
  }
`;

const NotesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 20px 20px 15px 20px;
  @media (max-width: 768px) {
    width: 100%;
  }
  > div > textarea {
    padding-top: 6px;
  }
`;

const Heading = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-left: 18px;
`;

const EnableCameraWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-right: 60px;
`;

const VerticalLine = styled.div`
  width: 1002px;
  height: 10px;
  border-bottom: 1px solid lightgrey;
  margin-top: 2px;
  margin-bottom: 15px;
  margin-left: 18px;
  @media (max-width: 768px) {
    width: 450px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 15px;
`;

const InnerWrapper = styled.div`
  width: fit-content;
`;

const AdvancedTabWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DeleteWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  margin-left: 18px;
`;

const GroupsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 0 20px 20px 20px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SpinnerBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  margin-left: -5px;
  height: 89vh;
  align-items: center;
  justify-content: center;
  margin-right: 136px;
  > span {
    margin-right: 15px;
  }
`;

interface CameraInfo {
  id?: number,
  enabled: boolean,
  name: string,
  streamType: string,
  notes?: string,
  instanceId: string;
  groupList: number[];
  serverId?: number | null;
}

interface IAlertMessageType {
  text: string,
  id: string,
  type: string | IconType
}

interface IUSBData {
  usbDeviceName: string;
  usbVideoSourceType: string;
  selectedDevice?: string
}

interface IRTMPData {
  rtmpServerType: string,
  rtmpSourceUrl: string,
  rtmpStreamKeyType: string,
  rtmpStreamKey: string,
  rtmpUrlType: string
}

interface IHLSData {
  httpSourceUrl: string
}

interface IItem {
  name: string,
  mdnsName: string,
  id: number
}

interface IRTPData {
  transportEncapsulation: string;
  videoMulticastAddress: string;
  videoPortRtp: string;
  videoCodec: string;
  videoInterlaceMode?: string;
  videoPortRtcp: string;
  videoPayloadType: number
}

interface IGroupData {
  name: string;
  id: number
}

const AddCamera = () => {
  const history = useHistory();
  const [cameraInfo, setCameraInfo] = useState<CameraInfo>({enabled: true, name: '', notes: '', streamType: '', instanceId:'', groupList: [], serverId: null});
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const {t} = useTranslation(['common']);
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: ''});  
  const [editStreamType, setEditStreamType] = useState(GET_RTSP_STREAM);
  const [advanceConfigModal, setAdvanceConfigModal] = useState(false);
  const [selectedStream, setSelectedStream] = useState('');
  const [isFormModified, setIsFormModified] = useState(false);
  const [rtspData, setRtspData] = useState({rtspUrl: 'rtsp://', username: '', password: '', rtspSourceBufferTime: '1', rtspSourceShortHeader: false, videoFps: 'auto'});
  const rtspPatchStreamPayloadData = {enabled: cameraInfo.enabled, groupList: cameraInfo.groupList, streamId: selectedStream, serverId: cameraInfo.serverId, name: cameraInfo.name, notes: cameraInfo.notes, streamType: cameraInfo.streamType, videoConfiguration: {rtspUrl: rtspData.rtspUrl, username: rtspData.username, password: rtspData.password}};
  const [usbData, setUsbData] = useState<IUSBData>({usbDeviceName: 'No Device Connected', usbVideoSourceType: 'UVC_I420'});
  const [rtmpData, setRtmpData] = useState<IRTMPData>({rtmpServerType: 'internal', rtmpUrlType: 'mDNS', rtmpSourceUrl: 'rtmp://', rtmpStreamKeyType: 'default_rtmp_stream_key', rtmpStreamKey: ''});
  const rtmpPatchStreamPayloadData = {enabled: cameraInfo.enabled, groupList: cameraInfo.groupList, streamId: selectedStream, name: cameraInfo.name, notes: cameraInfo.notes, streamType: cameraInfo.streamType, videoConfiguration: {rtmpServerType: rtmpData.rtmpServerType, rtmpUrlType: rtmpData.rtmpUrlType, rtmpSourceUrl: rtmpData.rtmpSourceUrl, rtmpStreamKeyType: rtmpData.rtmpStreamKeyType, rtmpStreamKey: rtmpData.rtmpStreamKeyType === 'custom_rtmp_stream_key' ? rtmpData.rtmpStreamKey: cameraInfo.name }};
  const [hlsData, setHlsData] = useState<IHLSData>({httpSourceUrl: ''});
  const [rtpData, setRtpData] = useState<IRTPData>({transportEncapsulation: 'H264', videoMulticastAddress: '', videoPortRtp: '', videoCodec: 'H264', videoInterlaceMode: '', videoPortRtcp: '6311', videoPayloadType: 96});
  const { pathname } = useLocation();
  const [disableAddCamera, setDisableAddCamera] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [newStreamInstanceId, setNewStreamInstanceId] = useState();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const {data: getAllGroupsResponse, loading: getAllGroupsLoading} = useQuery(GET_ALL_GROUPS);
  const [errorFromAPI, setErrorFromAPI] = useState(false);
  const [allServersData, setAllServersData] = useState([]);
  const [cancelModal, setCancelModal] = useState(false);
  const [groupData, setGroupData] = useState<{name: string, value: string}[]>([]);
  const [streamDeleteConfirmation, setStreamDeleteConfirmation] = useState(false);
  const {data: getAllServers, loading: getAllServerLoading} = useQuery(GET_ALL_SERVER);
  const [isEditCamera] = useState<boolean>(pathname.includes('CAM'));
  const {data, loading, refetch} = useQuery(editStreamType, { variables: {streamId: selectedStream} });
  const [deleteStreamMutation] = useMutation(DELETE_STREAM);
  const [addNewStreamMutation] = useMutation(CREATE_NEW_CAMERA);
  const [patchRTSPStreamMutation] = useMutation(PATCH_RTSP_CAMERA);
  const [patchUSBStreamMutation] = useMutation(PATCH_USB_CAMERA);
  const [patchRTMPStreamMutation] = useMutation(PATCH_RTMP_CAMERA);
  const [patchHLSStreamMutation] = useMutation(PATCH_HLS_CAMERA);
  const [patchRTPStreamMutation] = useMutation(PATCH_RTP_CAMERA);
  const [deleteFolder] = useMutation(DELETE_FOLDER);
  const [createFolder] = useMutation(CREATE_NEW_FOLDER);
  const [editFold] = useMutation(UPDATE_FOLDER);
  const {updateHeaderTitle} = useHeader();
  const [folderUploadModal, setUploadFolderModal] = useState(false);
  const editCameraAccess = TokenService.hasAccess(PERMISSION_CODE.edit_camera);
  const {userId} = useIdentityID();

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (isEditCamera) {
      if (query.get('camera-name') && query.get('camera-type')) {
        setSelectedStream(query.get('camera-name') as string);
        if(query.get('camera-type') === StreamType.RTSP) {
          setEditStreamType(GET_RTSP_STREAM);
        }
        if(query.get('camera-type') === StreamType.USB){
          setEditStreamType(GET_USB_STREAM);
        }
        if(query.get('camera-type') === StreamType.RTMP){
          setEditStreamType(GET_RTMP_STREAM);
        }
        if(query.get('camera-type') === StreamType.HLS){
          setEditStreamType(GET_HLS_STREAM);
        }
        if(query.get('camera-type') === StreamType.RTP){
          setEditStreamType(GET_RTP_STREAM);
        }
        if(query.get('camera-type') === StreamType.Folder){
          setEditStreamType(GET_FOLDER);
        }
        refetch();  
      }
    }
  }, [isEditCamera, selectedStream, refetch]);

  useEffect(() => {
    if(isEditCamera) {
      if(!loading) {
        if(data?.getStream.result){
          setErrorFromAPI(false);
          setCameraInfo(data?.getStream.result);
          setRtspData(data?.getStream.result);  
          setUsbData(data?.getStream.result);
          setRtmpData(data?.getStream.result);
          setHlsData(data?.getStream.result);
          setRtpData(data?.getStream.result);
          if(data?.getStream?.result?.groupList?.length === 0 || data?.getStream.result?.groupList === null) {
            setSelectedGroups(['-1']);
          }else
            setSelectedGroups(data?.getStream.result?.groupList?.map((groupId: number) => String(groupId)));   
        }else{
          setErrorFromAPI(true);
        }
      }
    }
  }, [data, loading, isEditCamera]);

  useEffect(() => {
    if(!getAllServerLoading) {
      if(getAllServers?.getAllServers?.result) {
        setAllServersData(getAllServers?.getAllServers?.result);
      }
    }
  }, [getAllServerLoading, getAllServers]);

  useEffect(()=>{
    if(!isEditCamera) {
      const cameraConfigurationsBreadCrumb: IBreadcrum[] = [
        {label: t('Cameras'), to: '/cameras'},
        {label: cameraInfo?.name as string, to: `/cameras/${cameraInfo?.instanceId}`}
      ];
      updateHeaderTitle(t('Configurations'), 'Setting', cameraConfigurationsBreadCrumb);
    }
  },[t, updateHeaderTitle, cameraInfo, isEditCamera]);

  useEffect(()=>{ 
    if(!getAllGroupsLoading) {
      const groups = getAllGroupsResponse?.getAllGroup?.result ? getAllGroupsResponse?.getAllGroup?.result : [];
      const availableGroups = [{name: 'No Group', value: '-1'}];
      if(groups?.length === 0 || !isEditCamera) {
        setSelectedGroups(['-1']);
        setCameraInfo(prev => ({...prev, groupList: [-1]}));
        groups?.map((group: IGroupData)=> {availableGroups.push({name: group.name, value: String(group.id)}); return null;});
        setGroupData(availableGroups);
      }
      else{
        groups?.map((group: IGroupData)=> {availableGroups.push({name: group.name, value: String(group.id)}); return null;});
        setGroupData(availableGroups);
      }
    }
  },[getAllGroupsResponse, getAllGroupsLoading, isEditCamera]);

  useEffect(() => {
    setTimeout(() => {
      if(disableAddCamera) {
        setDisableAddCamera(false);
      }
    }, 2000);
  }, [disableAddCamera]);

  const handleSelectGroupsChange = useCallback((selectedValues: string[]) => {
    if(selectedValues.length === 0) {
      setSelectedGroups(['-1']);
      setCameraInfo(prev => ({...prev, groupList: [-1]}));
    }else {
      setSelectedGroups(selectedValues);
      const numberedValues = selectedValues.map((value) => Number(value));
      setCameraInfo(prev => ({...prev, groupList: numberedValues}));
    }
  },[]);

  useEffect(() => {
    if(selectedGroups?.length > 1 && selectedGroups?.includes('-1'))
      setSelectedGroups(selectedGroups.filter((group) => group !== '-1'));
  }, [selectedGroups]);

  const handleEnableCameraChange = () => {
    setCameraInfo({...cameraInfo,enabled: !cameraInfo.enabled});
    setIsFormModified(true);
  };

  const handleCameraNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCameraInfo((prev) => ({
      ...prev,
      name: e.target.value,
    }));
    setIsFormModified(true);
    if(rtmpData.rtmpStreamKeyType === 'default_rtmp_stream_key') {
      setRtmpData(prev => ({...prev, rtmpStreamKey: e.target.value?.replace(usernameRegex, '')}));
    }
  };

  const handleCameraTypeChange = (value: string) => {
    setCameraInfo({...cameraInfo, streamType: value});
    setIsFormModified(true);
  };

  const handlePatchRTSPStream = async () => {
    setButtonLoading(true);
    try{
      const data = await patchRTSPStreamMutation({variables: {payload: rtspPatchStreamPayloadData}});
      if(data && data?.data?.patchStream?.statusCode === StatusCode.SUCCESS_200){
        setButtonLoading(false);
        setMessage({text: t('Camera updated successfully'), id:'',type:'success'});
        setTimeout(() => {
          history.push('/');
        }, 1000);
      }else{
        setButtonLoading(false);
        setMessage({text: t('apiError'), id:'',type:'danger'});
      }
    }catch(e){
      setMessage({text: t('apiError'), id:'',type:'danger'});
      console.error(e);
    }
  };

  const handlePatchUSBStream = async () => {
    setButtonLoading(true);
    try{
      const usbPatchStreamPayloadData = {enabled: cameraInfo.enabled, streamId: selectedStream, streamType: cameraInfo.streamType, name: cameraInfo.name, groupList: cameraInfo.groupList, notes: cameraInfo.notes, videoConfiguration: {videoDevice: {usbDeviceName: usbData.usbDeviceName, usbVideoSourceType: usbData.usbVideoSourceType, usbDeviceDescription: 'Description'}}};
      const data = await patchUSBStreamMutation({variables: {payload: usbPatchStreamPayloadData}});
      if(data && data?.data?.patchStream?.statusCode === StatusCode.SUCCESS_200){
        setButtonLoading(false);
        setMessage({text: t('Camera updated successfully'), id:'',type:'success'});
        setTimeout(() => {
          history.push('/');
        }, 1000);
      }else{
        setButtonLoading(false);
        setMessage({text: t('apiError'), id:'',type:'danger'});
      }
    }catch(e){
      setMessage({text: t('apiError'), id:'',type:'danger'});
      console.error(e);
    }
  };

  const handleAddRTSPStream = async () => {
    try{
      const rtspPutStreamPayloadData = {enabled: cameraInfo.enabled, name: cameraInfo.name, serverId: cameraInfo.serverId, notes: cameraInfo.notes, streamType: cameraInfo.streamType, streamId: cameraInfo.instanceId, groupList: cameraInfo.groupList, videoConfiguration: rtspData};
      const data = await addNewStreamMutation({variables: {input: rtspPutStreamPayloadData}});
      if(data && data?.data?.putStream?.statusCode === StatusCode.SUCCESS){
        setMessage({text: t('Camera created successfully'), id:'',type:'success'});
        setNewStreamInstanceId(data?.data?.putStream?.result);
        setAdvanceConfigModal(true);
      }else{
        setMessage({text: t('apiError'), id:'',type:'danger'});
      }
    }catch(e){
      setMessage({text: t('apiError'), id:'',type:'danger'});
      console.error(e);
    }
  };

  const handleAddUSBStream = async () => {
    try{
      const usbPutStreamPayloadData = {enabled: cameraInfo.enabled, name: cameraInfo.name, serverId: cameraInfo.serverId, notes: cameraInfo.notes, streamType: cameraInfo.streamType, streamId: cameraInfo.instanceId, groupList: cameraInfo.groupList, videoConfiguration: {videoDevice: {usbVideoSourceType: usbData.usbVideoSourceType, usbDeviceName: usbData.usbDeviceName, usbDeviceDescription: 'Description'}}};
      const data = await addNewStreamMutation({variables: {input: usbPutStreamPayloadData}});
      if(data && data?.data?.putStream?.statusCode === StatusCode.SUCCESS){
        setMessage({text: t('Camera created successfully'), id:'',type:'success'});
        setNewStreamInstanceId(data?.data?.putStream?.result);
        setAdvanceConfigModal(true);
      }else{
        setMessage({text: t('apiError'), id:'',type:'danger'});
      }
    }catch(e){
      setMessage({text: t('apiError'), id:'',type:'danger'});
      console.error(e);
    }
  };

  const handleAddRTMPStream = async () => {
    try{
      const rtmpPutStreamPayloadData = {enabled: cameraInfo.enabled, name: cameraInfo.name, notes: cameraInfo.notes, streamType: cameraInfo.streamType, streamId: cameraInfo.instanceId, groupList: cameraInfo.groupList, videoConfiguration: {rtmpServerType: rtmpData.rtmpServerType, rtmpUrlType: rtmpData.rtmpUrlType, rtmpSourceUrl: rtmpData.rtmpSourceUrl, rtmpStreamKeyType: rtmpData.rtmpStreamKeyType, rtmpStreamKey: rtmpData.rtmpStreamKeyType === 'custom_rtmp_stream_key' ? rtmpData.rtmpStreamKey: cameraInfo.name} };
      const data = await addNewStreamMutation({variables: {input: rtmpPutStreamPayloadData}});
      if(data && data?.data?.putStream?.statusCode === StatusCode.SUCCESS){
        setMessage({text: t('Camera created successfully'), id:'',type:'success'});
        setNewStreamInstanceId(data?.data?.putStream?.result);
        setAdvanceConfigModal(true);
      }else{
        setMessage({text: t('apiError'), id:'',type:'danger'});
      }
    }catch(e){
      setMessage({text: t('apiError'), id:'',type:'danger'});
      console.error(e);
    }
  };

  const handleAddHLSStream = async () => {
    setButtonLoading(true);
    try{
      const hlsPutStreamPayloadData = {enabled: cameraInfo.enabled, name: cameraInfo.name, notes: cameraInfo.notes, streamType: cameraInfo.streamType, streamId: cameraInfo.instanceId, groupList: cameraInfo.groupList, videoConfiguration: {httpSourceUrl: hlsData.httpSourceUrl}};
      const data = await addNewStreamMutation({variables: {input: hlsPutStreamPayloadData}});
      if(data && data?.data?.putStream?.statusCode === StatusCode.SUCCESS){
        setButtonLoading(false);
        setMessage({text: t('Camera created successfully'), id:'',type:'success'});
        setNewStreamInstanceId(data?.data?.putStream?.result);
        setAdvanceConfigModal(true);
      }else{
        setButtonLoading(false);
        setMessage({text: t('apiError'), id:'',type:'danger'});
      }
    }catch(e){
      setButtonLoading(false);
      setMessage({text: t('apiError'), id:'',type:'danger'});
      console.error(e);
    }
  };

  const handleAddRTPStream = async () => { 
    try{
      setButtonLoading(true);
      const rtpStreamPutPayload = {name: cameraInfo.name, notes: cameraInfo.notes, groupList: cameraInfo.groupList, streamType: StreamType.RTP, enabled: cameraInfo.enabled, videoConfiguration: {videoMulticastAddress: rtpData.videoMulticastAddress, videoPortRtcp: (Number(rtpData.videoPortRtp) + 1).toString(), videoPortRtp: rtpData.videoPortRtp === '' ? '6310': rtpData.videoPortRtp, transportEncapsulation: rtpData.transportEncapsulation, videoCodec: rtpData.videoCodec, videoPayloadType: rtpData.videoPayloadType, videoInterlaceMode: rtpData.videoInterlaceMode }};
      if(rtpData.transportEncapsulation === TRANSPORT_ENCAPSULATION_VALUES.H264 || rtpData.transportEncapsulation === TRANSPORT_ENCAPSULATION_VALUES.MPEG2PS){
        const keyToDelete = 'videoInterlaceMode';
        delete rtpStreamPutPayload.videoConfiguration[keyToDelete];
      } 
      const data = await addNewStreamMutation({variables: {input: rtpStreamPutPayload}});
      if(data && data?.data?.putStream?.statusCode === StatusCode.SUCCESS){
        setMessage({text: t('Camera created successfully'), id:'',type:'success'});
        setButtonLoading(false);
        setNewStreamInstanceId(data?.data?.putStream?.result);
        setAdvanceConfigModal(true);
      }else{
        setMessage({text: t('apiError'), id:'',type:'danger'});
        setButtonLoading(false);
      }
    }catch(e){
      setMessage({text: t('apiError'), id:'',type:'danger'});
      setButtonLoading(false);
      console.error(e);
    }
  };

  const handleAddFolder = async () => { 
    try{
      // const folderPayloadData = {enabled: cameraInfo.enabled, name: cameraInfo.name, notes: cameraInfo.notes, streamType: cameraInfo.streamType, streamId: cameraInfo.instanceId, groupList: cameraInfo.groupList};
      const folderPayloadData = {enabled: cameraInfo.enabled, name: cameraInfo.name, deleted: false};
      const data = await createFolder({variables: {payload: folderPayloadData, cognitoID: userId, cognitoIdentityPoolID: awsConfig.Auth.identityPoolId}});
      if(data && data?.data?.createFolder?.statusCode === StatusCode.SUCCESS){
        setMessage({text: t('Folder created successfully'), id:'',type:'success'});
        setNewStreamInstanceId(data?.data?.createFolder?.result);
        setUploadFolderModal(true);
      }else{
        setMessage({text: t('apiError'), id:'',type:'danger'});
      }
    }catch(e){
      setMessage({text: t('apiError'), id:'',type:'danger'});
      console.error(e);
    }
  };

  const handlePatchHLSStream = async () => {
    setButtonLoading(true);
    try{
      const hlsPatchStreamPayloadData = {enabled: cameraInfo.enabled, groupList: cameraInfo.groupList, streamId: selectedStream, name: cameraInfo.name, notes: cameraInfo.notes, streamType: cameraInfo.streamType, videoConfiguration: {httpSourceUrl: hlsData.httpSourceUrl}};
      const data = await patchHLSStreamMutation({variables: {payload: hlsPatchStreamPayloadData}});
      if(data && data?.data?.patchStream?.statusCode === StatusCode.SUCCESS_200){
        setButtonLoading(false);
        setMessage({text: t('Camera updated successfully'), id:'',type:'success'});
        setTimeout(() => {
          history.push('/');
        }, 1000);
      }else{
        setButtonLoading(false);
        setMessage({text: t('apiError'), id:'',type:'danger'});
      }
    }catch(e){
      setMessage({text: t('apiError'), id:'',type:'danger'});
      console.error(e);
    }
  };

  const handlePatchRTMPStream = async () => {
    setButtonLoading(true);
    try{
      const data = await patchRTMPStreamMutation({variables: {payload: rtmpPatchStreamPayloadData}});
      if(data && data?.data?.patchStream?.statusCode === StatusCode.SUCCESS_200){
        setButtonLoading(false);
        setMessage({text: t('Camera updated successfully'), id:'',type:'success'});
        setTimeout(() => {
          history.push('/');
        }, 1000);
      }else{
        setButtonLoading(false);
        setMessage({text: t('apiError'), id:'',type:'danger'});
      }
    }catch(e){
      setMessage({text: t('apiError'), id:'',type:'danger'});
      console.error(e);
    }
  };

  const handlePatchRTPStream = async () => {
    const rtpPatchStreamPayloadData = {enabled: cameraInfo.enabled, groupList: cameraInfo.groupList, streamId: selectedStream, streamType: cameraInfo.streamType, name: cameraInfo.name, notes: cameraInfo.notes, videoConfiguration: {transportEncapsulation: rtpData.transportEncapsulation, videoMulticastAddress: rtpData.videoMulticastAddress, videoPortRtp: rtpData.videoPortRtp, videoCodec: rtpData.videoCodec, videoInterlaceMode: rtpData.videoInterlaceMode, videoPortRtcp: rtpData.videoPortRtcp, videoPayloadType: Number(rtpData.videoPayloadType)}};
    try{
      setButtonLoading(true);
      const data = await patchRTPStreamMutation({variables: {payload: rtpPatchStreamPayloadData}});
      if(data && data?.data?.patchStream?.status_code === StatusCode.SUCCESS_200){
        setButtonLoading(false);
        setMessage({text: t('Camera updated successfully'), id:'',type:'success'});
        setTimeout(() => {
          history.push('/');
        }, 1000);
      }else{
        setButtonLoading(false);
        setMessage({text: t('apiError'), id:'',type:'danger'});
      }
    }catch(e){
      setMessage({text: t('apiError'), id:'',type:'danger'});
      console.error(e);
    }
  };

  const handlePatchFolder = async () => {
    setButtonLoading(true);
    try{
      // const folderPatchPayloadData = {enabled: cameraInfo.enabled, streamId: selectedStream, streamType: cameraInfo.streamType, name: cameraInfo.name, groupList: cameraInfo.groupList, notes: cameraInfo.notes};
      const folderPatchPayloadData = {enabled: cameraInfo.enabled, device_id: selectedStream};
      const data = await editFold({variables: {payload: folderPatchPayloadData, cognitoID: userId, cognitoIdentityPoolID: awsConfig.Auth.identityPoolId}});
      if(data && data?.data?.patchStream?.statusCode === StatusCode.SUCCESS_200){
        setButtonLoading(false);
        setMessage({text: t('Folder updated successfully'), id:'',type:'success'});
        setTimeout(() => {
          history.push('/');
        }, 1000);
      }else{
        setButtonLoading(false);
        setMessage({text: t('apiError'), id:'',type:'danger'});
      }
    }catch(e){
      setMessage({text: t('apiError'), id:'',type:'danger'});
      console.error(e);
    }
  };

  const handleAddCamera = () => {
    setDisableAddCamera(true);
    if((cameraInfo.name).trim() === '') {
      setMessage({text: t('Camera name is required.'), id: 'name', type: AlertTypes.danger});
      return false;
    }
    if(cameraInfo.streamType === StreamType.RTSP) {
      if((rtspData.rtspUrl).trim() === 'rtsp://' || (rtspData.rtspUrl).trim() === '')  {
        setMessage({text: t('RTSP URL is Required.'), id: 'rtsp_url', type: AlertTypes.danger});
        return false;
      } 
      if(!((rtspData.rtspUrl).startsWith('rtsp://') || (rtspData.rtspUrl).startsWith('rtspt://')))  {
        setMessage({text: t('Invalid RTSP URL'), id: 'rtsp_url', type: AlertTypes.danger});
        return false;
      } 
      isEditCamera ? handlePatchRTSPStream() : handleAddRTSPStream();
    }
    else if(cameraInfo.streamType === StreamType.USB){
      if(usbData.usbDeviceName === 'No Device Connected') {
        setMessage({text: t('Please connect USB Camera.'), id: '', type: AlertTypes.danger});
        return false;
      }
      isEditCamera ? handlePatchUSBStream() : handleAddUSBStream();
    } else if(cameraInfo.streamType === StreamType.RTMP) {
      isEditCamera ? handlePatchRTMPStream() : handleAddRTMPStream();
    } else if(cameraInfo.streamType === StreamType.HLS) {
      if((hlsData.httpSourceUrl).trim() === '') {
        setMessage({text: t('HTTP Stream URL is required'), id: 'httpStreamUrl', type: AlertTypes.danger});
        return false;
      }
      isEditCamera ? handlePatchHLSStream() : handleAddHLSStream();
    }
    else if(cameraInfo.streamType === StreamType.RTP) {
      if((rtpData.videoMulticastAddress).trim() === '') {
        setMessage({text: t('Multicast address is required.'), id: 'MulticastAddress', type: AlertTypes.danger});
        return false;
      }
      isEditCamera ? handlePatchRTPStream() : handleAddRTPStream();
    }
    else if (cameraInfo.streamType === StreamType.Folder) {
      isEditCamera ? handlePatchFolder() : handleAddFolder();
    }
  };

  const handleDeleteStream = async () => {
    try{
      setDeleteLoading(true);
      let data;
      let successMessage;
      if(cameraInfo.streamType === StreamType.Folder) {
        data = await deleteFolder({variables:{streamId:selectedStream}});
        successMessage = t('Folder deleted successfully');
      }else{
        data = await deleteStreamMutation({variables:{streamId: selectedStream}});
        successMessage = t('Camera deleted successfully');
      }
      if(data && data?.data?.deleteStream?.statusCode === StatusCode.SUCCESS){
        setDeleteLoading(false);
        setMessage({text: successMessage, id:'',type:'success'});
        setTimeout(() => {
          history.push('/');
        }, 2000);
      }else{
        setDeleteLoading(false);
        setMessage({text: t('apiError'), id:'',type:'danger'});
      }
    }catch(e){
      setDeleteLoading(false);
      setMessage({text: t('apiError'), id:'',type:'danger'});
    }
  };

  const handleCancelButton = () => {
    if(isFormModified) {
      setCancelModal(true);
    } 
    else{
      history.push('/');
    }
  };

  const handleNodeChange = (value: string) => {
    setCameraInfo(prev => ({...prev, serverId: Number(value)}));
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = e.target.value;
    if (inputValue.length > 255) {
      setMessage({text: t('Description text limit exceeded'), id: '', type:'danger'});
    } else {
      setCameraInfo((prev) => ({
        ...prev,
        notes: inputValue
      }));
      setIsFormModified(true);
    }
  };

  const handleDescriptionPaste = (e: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const pastedText = e.clipboardData.getData('text/plain');
    if (pastedText.length >= 256) {
      setMessage({text: t('Description text limit exceeded'), id: '', type:'danger'});
    }
    const trimmedText = pastedText.slice(0, 256);
    setCameraInfo((prev) => ({...prev, notes: trimmedText}));
  };

  return (
    <FormWrapper>
      {
        message && <AlertBar message={message.text} type={message.type as IconType} setMessage={setMessage} />
      }
      <NewCameraWrapper>
        { !getAllServerLoading && (!isEditCamera ||(!loading && !errorFromAPI)) &&
          <InnerWrapper>
            <BasicConfigurationWrapper>
              <Heading>{t('Basic Configurations')}</Heading>
              <EnableCameraWrapper>
                <SwitchLabel>{cameraInfo.enabled ? t('Enabled'): t('Disabled')}</SwitchLabel>
                <Switch disabled={isEditCamera && !editCameraAccess} checked={cameraInfo.enabled} onChange={handleEnableCameraChange}/>
              </EnableCameraWrapper>
            </BasicConfigurationWrapper>
            <VerticalLine />
            <BasicCameraInfo>
              <LeftSideWrapper>
                <Input type='text' id='name' message={message} disabled={isEditCamera && !editCameraAccess} placeholder={t('Enter the Name')} label={t('Camera Name')} isRequired={true} maxLength={255} value={cameraInfo.name} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCameraNameChange(e)} />
                <SelectField
                  disabled={isEditCamera}
                  isRequired
                  options={VIDEO_SOURCE_TYPE}
                  defaultValue={cameraInfo.streamType}
                  label={t('Camera Type')}
                  onChange={handleCameraTypeChange}
                  emptyListText={t('No cameras found')}
                  placeholder={t('Please select camera type')}
                />
                {Config.serverType !== 'single' && <SelectField label={t('Select Server')} defaultValue={cameraInfo.serverId ? cameraInfo.serverId.toString() : '' } disabled={allServersData?.length <= 1}  options={allServersData?.map((item: IItem) => ({name: item.name, value: item.id}))} onChange={(value) => handleNodeChange(value)} />}
              </LeftSideWrapper>
              <RightSideWrapper>
                <NotesWrapper>
                  <TextArea label={t('description')} rows={10} cols={30} disabled={isEditCamera && !editCameraAccess} onPaste={(e) => handleDescriptionPaste(e)}  maxLength={65535} value={cameraInfo.notes} onChange={(e) => handleDescriptionChange(e)} height='40px' />
                </NotesWrapper>
                <GroupsWrapper>
                  <MultipleSelect label={t('Groups')} emptyListPlaceholder={t('Groups not found')} options={groupData.filter(group => group.value !== '-1')} disabled={isEditCamera && !editCameraAccess} selectedValues={selectedGroups} onChange={handleSelectGroupsChange} width='450px'/>
                </GroupsWrapper>
              </RightSideWrapper>
            </BasicCameraInfo>
            {
              cameraInfo.streamType === StreamType.RTSP && <RTSPCamera rtspData={rtspData} message={message} setRtspData={setRtspData} setIsFormModified={setIsFormModified} isEditAccess={isEditCamera && !editCameraAccess} />
            }
            {
              cameraInfo.streamType === StreamType.USB && <USBCamera usbData={usbData} setUsbData={setUsbData} isEditCamera={isEditCamera} isEditAccess={isEditCamera && !editCameraAccess}/>
            }
            {
              cameraInfo.streamType === StreamType.RTP && <RTPCamera rtpData={rtpData} setRtpData={setRtpData} isEditAccess={isEditCamera && !editCameraAccess}/>
            }
            {
              cameraInfo.streamType === StreamType.RTMP && <RTMPCamera rtmpData={rtmpData} setRtmpData={setRtmpData} serverId={cameraInfo.serverId} cameraName={cameraInfo.name} isEditCamera={isEditCamera} isEditAccess={isEditCamera && !editCameraAccess}/>
            }
            {
              cameraInfo.streamType === StreamType.HLS && <HLSCamera hlsData={hlsData} setHlsData={setHlsData} message={message} isEditAccess={isEditCamera && !editCameraAccess}/>
            }
            <ButtonsWrapper>
              <ButtonWithLoading loading={buttonLoading} variant='primary' disabled={cameraInfo.streamType === '' || !editCameraAccess || disableAddCamera} onClick={handleAddCamera}>{t('saveText')}</ButtonWithLoading>
              <Button variant='secondary' onClick={handleCancelButton}>{t('cancleText')}</Button>
              <CancelConfirmationModal modalOpen={cancelModal} setModalOpen={setCancelModal} onCancelNo={() => setCancelModal(false)} onCancelYes={() => {setCancelModal(false); history.push('/');}} />
            </ButtonsWrapper>
            <TextOnlyConfirmationModal modalOpen={folderUploadModal} setModalOpen={setUploadFolderModal} titleText={t('Do you want to upload now?')} confirmDescription={t('You can always upload later, by going to upload page')} onYes={() => history.push(`/cameras/${newStreamInstanceId}/folder`)} onNo={() => history.push('/')}/>
            <TextOnlyConfirmationModal modalOpen={advanceConfigModal} setModalOpen={setAdvanceConfigModal} titleText={t('Do you want to configure now?')} confirmDescription={t('You can always configure later, by going to configurations page')} onYes={() => history.push(`/cameras/${newStreamInstanceId}/configurations?selectedTab=analysis&camera-name=${newStreamInstanceId}&camera-type=${cameraInfo.streamType}`)} onNo={() => history.push('/')}/>
            {
              (isEditCamera && editCameraAccess) && <AdvancedTabWrapper>
                <Heading>{t('Advanced')}</Heading>
                <VerticalLine />
                <TextOnlyConfirmationModal isLoading={deleteLoading} titleText={t('Are you sure you want to delete this camera')} modalOpen={streamDeleteConfirmation} setModalOpen={setStreamDeleteConfirmation} onYes={handleDeleteStream} confirmDescription={t('Once deleted this action cannot be undone.')} />
                <DeleteWrapper><Button variant='danger' onClick={() => setStreamDeleteConfirmation(true)}>{t('Delete')}</Button></DeleteWrapper>
              </AdvancedTabWrapper>
            }
          </InnerWrapper> 
        }
        {loading && 
        <SpinnerBox>
          <CircularProgress />
          <Label labelText={t('Loading...')}/>
        </SpinnerBox>}
        {(!loading && errorFromAPI) && <div>{t('apiError')}</div>}
      </NewCameraWrapper>
    </FormWrapper>
  );
};

export default AddCamera;