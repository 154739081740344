import React, { useEffect, useState, useCallback, useMemo } from 'react';
import Icon from 'components/Icon';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/client';
import { GET_ALL_GROUPS, GET_ALL_STREAMS, GET_EVENT_PIPELINES, GET_SCHEDULES_LIST } from 'api_configs/queries';
import CircularProgress from '@mui/material/CircularProgress';
import { useHistory } from 'react-router';
import { DataGrid, GridColDef, GridRenderCellParams, GridRowSelectionModel } from '@mui/x-data-grid';
import Config from '../../configs/config.json';
import Pagination from 'components/Pagination';
import Modal from 'components/Modal';
import { DELETE_EVENT_PIPELINE } from 'api_configs/mutations';
import { EP_FILTERS, IconType, PERMISSION_CODE, StatusCode } from '../../constants';
import { IAlertMessageType, IPipelineActions, IPipelineFilter, IPipelineInfo, ISchedule, IStream } from 'interface';
import AlertBar from 'components/AlertBar';
import EventPipelineGraphicalView from './EventPipelineGraphicalView';
import { GET_ALL_ALGORITHMS } from 'api_configs/queries';
import { Container } from 'CommonStyles';
import ButtonWithIcon from 'components/ButtonWithIcon';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import { useHeader } from 'contexts/HeaderContext';
import Button from 'components/Button';
import { ActionIcons } from 'utils/utils';
import { LuCopyPlus } from 'react-icons/lu';
import TokenService from 'api_configs/tokenService';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: row-reverse;
  width: 100%;
  > button {
    width: max-content;
  }
`;

const SpinnerBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 89vh;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.backgroundColor};
`;

const Label = styled.div`
  font-size: 16px;
`;

const TableContainer = styled.div`
  width: 100%;
  .MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle{
    font-weight: 600;
  }
  .MuiDataGrid-root .MuiDataGrid-cell{
    padding: 10px;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeaders{
    background-color: #F5F8FF;
  }
  .MuiDataGrid-root .MuiDataGrid-cellContent{
    text-align: left;
  }
  > div:nth-child(1) > div:nth-child(2){
    display: none;
  }
  > div > div:first-child{
  > div:first-child{
    font-size: 14px;
    color: #5a6269;
    > div > div > div > div:nth-child(2) > svg {
      display: none;
    }
  }
   > div:nth-child(2) {
    text-align: center;
    font-size: 14px;
    text-decoration: none;
    color:#7b8288;
    > div > div > div > div:nth-child(3){
      display: flex;
      flex-direction: column;
      row-gap: 5px;
      justify-content: center;
      align-items: initial;
    }
  }
}
`;

const PaginationBox = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;

 > div > div {
  background-color: none;
 }
`;

const ActionIcon = styled.div`
  cursor: pointer;
  width: 34px;
  height: 34px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`; 

const ActionIconsWrapper = styled.div`
  display: flex;
  column-gap: 10px;
  overflow: auto;
`;

const EmptyLabelWrapper = styled.div`
  border: 1px solid #E0E0E0;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  width: 100%;
`;

const ActionDetail = styled.div`
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ActionHeaderData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
`;

const Title = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #ADADAD;
`;

const TitleName = styled.div<{flexDirection?: string}>`
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: ${({flexDirection}) => flexDirection ? flexDirection : 'row'};
  gap: 10px;
`;

const Span = styled.span`
  border: 1px solid ${({theme}) => theme.divider};
  padding: 2px 4px;
  border-radius: 4px;
`;

const NoPermissionMessage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EmptyLabel = styled.div`
  font-size: 22px;
`;

const PipelineNameContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const Status = styled.div<{enabled?: boolean}>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  flex: none;
  background-color: ${({theme, enabled}) => enabled ? theme.icons.success : theme.icons.dimmed};
`;

const PipelineName = styled.div`
  text-align: left;
`;

interface IActions {
  code: string;
  displayName?: string;
 }
interface IEvents {
  code: string;
  displayName?: string;
  actions: IActions[]
}

interface ISelectedPipeline {
  id: number;
  name: string;
}

interface IAlgorithmConfigDetails {
  id?: number,
  name?: string,
  code?: string,
  description?: string,
  events: IEvents[],
  actions: IActions[]
}

interface IEventInfo {
  code?: string,
  displayName?: string,
  description?: string
}

interface IGroups {
  id: number,
  name: string,
  description?: string,
  streams: IStream[]
}

const EventPipeline = () =>{
  const {t} = useTranslation(['common']);
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount ] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [eventPipelineData, setEventPipelineData] = useState<IPipelineInfo[]>([]);
  const {data, loading, refetch} = useQuery(GET_EVENT_PIPELINES);
  const [deletePipelineMutation] = useMutation(DELETE_EVENT_PIPELINE);
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isGraphicalView, setIsGraphicalView] = useState(false);
  const [graphicalViewId, setGraphicalViewId] = useState(0);
  const [selectedPipeline, setSelectedPipeline] = useState<ISelectedPipeline>();
  const [algorithmConfigDetails, setAlgorithmConfigDetails] = useState<IAlgorithmConfigDetails[]>([]);
  const { data: allAlgorithms } = useQuery(GET_ALL_ALGORITHMS);
  const {updateHeaderTitle} = useHeader();
  const { data: scheduleList, refetch: refetchSchedules } = useQuery(GET_SCHEDULES_LIST);
  const [scheduleListData, setScheduleListData] = useState<ISchedule[]>([]);
  const [allStreams, setAllStreams] = useState<IStream[]>([]);
  const [allGroups, setAllGroups] = useState<IGroups[]>([]);
  const {data: getAllStreamsResponse} = useQuery(GET_ALL_STREAMS);
  const {data: getAllGroupsResponse} = useQuery(GET_ALL_GROUPS);
  const [selectedIds, setSelectedIds] = useState<GridRowSelectionModel>([]);
  const addEventPipelineAccess = TokenService.hasAccess(PERMISSION_CODE.add_event_pipeline);
  const editEventPipelineAccess = TokenService.hasAccess(PERMISSION_CODE.edit_event_pipeline);
  const deleteEventPipelineAccess = TokenService.hasAccess(PERMISSION_CODE.delete_event_pipeline);
  const viewEventPipelineAccess = TokenService.hasAccess(PERMISSION_CODE.event_pipeline_list);

  useEffect(()=>{
    refetchSchedules();
    const schedules: ISchedule[] = scheduleList?.getSchedules?.result !== null ? scheduleList?.getSchedules?.result : [];
    if(schedules?.length > 0){
      setScheduleListData(schedules);
    }
    if(getAllStreamsResponse && getAllStreamsResponse?.getAllStreams?.statusCode === StatusCode.SUCCESS && getAllStreamsResponse?.getAllStreams?.result !== null){
      const allStreams: IStream[] = getAllStreamsResponse?.getAllStreams?.result;
      setAllStreams(allStreams);
    }
    setAllGroups(getAllGroupsResponse?.getAllGroup?.result === null ? [] : getAllGroupsResponse?.getAllGroup?.result);
  },[scheduleList, refetchSchedules, getAllStreamsResponse, getAllGroupsResponse]);

  useEffect(()=>{
    updateHeaderTitle(t('Event Pipelines'), 'EventPipeline');
  },[updateHeaderTitle, t]);

  useEffect(()=>{
    setLoader(loading);
  },[loading]);

  useEffect(()=>{
    if(allAlgorithms?.getAllAlgorithm?.statusCode === StatusCode.SUCCESS && allAlgorithms?.getAllAlgorithm?.result.length > 0){
      const result = allAlgorithms?.getAllAlgorithm?.result;
      setAlgorithmConfigDetails(result);
    }
  },[allAlgorithms]);

  useEffect(()=>{
    refetch();
    if(data?.getEventPipelines?.result){
      setEventPipelineData(data?.getEventPipelines?.result);
    }
    if(data?.getEventPipelines?.count){
      setPageCount(data?.getEventPipelines?.count);
    }
  },[data, refetch]);

  const handleEditClick = useCallback((id: string) => {
    history.push(`/settings/edit-event-pipeline/${id}`);
  },[history]);

  const handleClonePipelineClick = useCallback((id: string) => {
    history.push(`/settings/add-event-pipeline?cloneId=${id}`);
  },[history]);

  const getEventName = useCallback((code: string) => {
    const event = algorithmConfigDetails.map((item: IAlgorithmConfigDetails) => item?.events.find((event: IEvents) => event.code === code)).find((event?: IEventInfo) => event !== undefined);
    return event ? event.displayName : null;
  },[algorithmConfigDetails]);

  const handleRowSelectionModelChange = (params: GridRowSelectionModel) => {
    setSelectedIds(params ? params : []);
  };

  const getDisplayNamesOfFilters = useCallback((key: string, value: (string | number)[])=>{
    if(key === EP_FILTERS.CAMERAS){
      return allStreams?.filter(item => value.includes(item.instanceId)).map(item => item.name);
    }else if(key === EP_FILTERS.GROUPS){
      return allGroups?.filter(item => value.includes(item.id)).map(item => item.name);
    }
  },[allStreams, allGroups]);

  const getFiltersColumn = useCallback((filters: IPipelineFilter)=>{
    return (
      <ActionsContainer>
        {
          Object.entries(filters ?? {}).map(([key, value]) => {
            const arr = value.includes('ALL') ? [] : getDisplayNamesOfFilters(key, value) as string[];
            return (
              <ActionHeaderData>
                {arr?.length > 0 ? <ActionHeaderData>
                  <Title>{t(key)}</Title>
                  <TitleName>
                    {arr.map(name => (
                      <Span>{name}</Span>
                    ))}
                  </TitleName>
                </ActionHeaderData> : '-'}
              </ActionHeaderData>
            );})
        }
      </ActionsContainer>
    );
  },[getDisplayNamesOfFilters, t]);

  const getActionDetails = useCallback((actionsData: IPipelineActions[])=>{
    return (
      <TitleName>
        {
          actionsData.map(action => (
            <ActionDetail title={action.type}>
              {ActionIcons[action.type]}
            </ActionDetail>
          ))
        }
      </TitleName>
    );
  },[]);

  const getScheduleColumn = useCallback((scheduleId: number)=>{
    const scheduleDetails = scheduleListData.find(item => item.id === scheduleId);
    return (
      <>
        {scheduleDetails ?
          <TitleName>{scheduleDetails?.name}</TitleName>:
          <div>-</div>}
      </>
    );
  },[scheduleListData]);

  const getAlgorithmColumn = useCallback((algoName: string, eventsList: string[])=>{
    return(
      <ActionsContainer>
        <ActionHeaderData>
          <Title>{t('Analysis')}</Title>
          <TitleName>{t(algoName)}</TitleName>
        </ActionHeaderData>
        <ActionHeaderData>
          <Title>{t('Events')}</Title>
          <TitleName flexDirection='column'>{eventsList.map(event => (
            <div>{t(getEventName(event) as string)}</div>
          ))}</TitleName>
        </ActionHeaderData>
      </ActionsContainer>
    );
  },[t, getEventName]);

  const getNameColumn = useCallback((name: string, enabled: boolean) => {
    return (
      <PipelineNameContainer>
        <Status title={enabled ? t('Enabled'): t('Disabled')} enabled={enabled} />
        <PipelineName>{name}</PipelineName>
      </PipelineNameContainer>
    );
  },[t]);

  const columns: GridColDef[]  = useMemo(()=> [
    { field: 'name', headerName: t('Name'), flex: 1, headerAlign: 'left', align: 'left',
      renderCell: (params) => getNameColumn(params.row.name, params.row.enabled)
    },
    { field: 'algorithmName', headerName: t('Analysis Name'), flex: 1.5, headerAlign: 'left', align: 'left', sortable: false,
      renderCell: (params) => getAlgorithmColumn(params.row.algorithmName, params.row.events)},
    { field: 'filters', headerName: t('Filters'), flex: 2, headerAlign: 'left', align: 'left', sortable: false,
      renderCell: (params:GridRenderCellParams) => getFiltersColumn(params.row.filters)
    },
    { field: 'schedule', headerName: t('Schedule'), flex: 1, headerAlign: 'left', align: 'left', sortable: false,
      renderCell: (params) => getScheduleColumn(params.row.schedule)
    },
    {
      field: 'configuredActions',
      headerName: t('Configured Actions'),
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      renderCell: (params) => getActionDetails(params.row.configuredActions),
    },  
    {
      field: 'actions',
      headerName: '',
      flex: 1,
      align: 'left',
      sortable: false,
      renderCell: (params) => {
        return (
          <ActionIconsWrapper>
            { editEventPipelineAccess && <ActionIcon title={t('Edit')} onClick={() => handleEditClick(params?.row?.id)} >
              <Icon icon='Edit' color='primary' />
            </ActionIcon>}
            { deleteEventPipelineAccess && <ActionIcon title={t('Delete')} onClick={() => {setIsModalOpen(true); setSelectedPipeline(params.row);}}>
              <Icon icon='Delete' color='danger' />
            </ActionIcon>}
            { viewEventPipelineAccess && <ActionIcon title={t('View')} onClick={() => { setIsModalOpen(true); setIsGraphicalView(true); setGraphicalViewId(params?.row?.id);}}>
              <Icon icon='Expand' color='primary' />
            </ActionIcon>}
            { addEventPipelineAccess && <ActionIcon title={t('Clone')} onClick={() => handleClonePipelineClick(params?.row?.id)}>
              <LuCopyPlus size={24} />
            </ActionIcon>}
          </ActionIconsWrapper>
        );
      }
    }
  ],[t, getAlgorithmColumn, getFiltersColumn, getScheduleColumn, getActionDetails, editEventPipelineAccess, deleteEventPipelineAccess, viewEventPipelineAccess, addEventPipelineAccess, handleEditClick, handleClonePipelineClick, getNameColumn]);

  const getConfiguredColumns = useCallback(()=>{
    const configuredColumns = columns.filter((item)=>{
      return Config.EventPipelineColumns.indexOf(item.field) !== -1;
    });
    return configuredColumns;
  },[columns]);

  const getAlgorithmName = useCallback((code: string) => {
    const item = algorithmConfigDetails.find((item: IAlgorithmConfigDetails) => item.code === code);
    return item ? item.name : null;
  },[algorithmConfigDetails]);

  const generateRowData = useCallback(() => {
    return eventPipelineData?.map((item) => ({
      id: item.id,
      name: item?.name,
      algorithmName: getAlgorithmName(item?.algoCode),
      filters: item.filters,
      events: item.event,
      configuredActions: item.actions,
      schedule: item.scheduleId,
      enabled: item.enabled
    }));
  },[eventPipelineData, getAlgorithmName]);

  const onDeletePipeline = useCallback(async (idsList?: number[]) =>{
    try {
      const result = await deletePipelineMutation({variables: { epIds: idsList }});
      if(result.data?.deleteEventPipeline?.statusCode === StatusCode.SUCCESS){
        setMessage({text: t('Event pipeline deleted successfully'), id:'',type:'success'});
        setIsModalOpen(false);
        const res = await refetch();
        if(res && res.data?.getEventPipelines?.result){
          setEventPipelineData(res.data?.getEventPipelines?.result);
        }else{
          setEventPipelineData([]);
        }
        setSelectedIds([]);
      }else {
        setMessage({text: t('apiError'), id:'',type:'danger'});
      }
    } catch(e){
      setMessage({text: t('apiError'), id:'',type:'danger'});
    }
  },[refetch, deletePipelineMutation, t]);

  const handleChange = (value: number) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    if(refetch) {
      refetch({ pageSize, pageNumber: currentPage });
    }
  }, [currentPage, pageSize, refetch]);

  if (loader) {
    return (
      <SpinnerBox>
        <CircularProgress />
        <Label>{t('Loading...')}</Label>
      </SpinnerBox>
    );
  }

  return(
    <Container>
      {message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
      {isModalOpen && !isGraphicalView &&
      <ConfirmationModal modalOpen={isModalOpen} name={selectedIds?.length > 0 ? '': selectedPipeline?.name} setModalOpen={setIsModalOpen} onSubmit={()=>onDeletePipeline(selectedIds?.length > 0 ? (selectedIds as number[]) : [selectedPipeline?.id as number])} hasConfirmationField={false}
        titleText={t(selectedIds?.length > 0 ? 'Delete Event Pipelines?' : 'Delete Event Pipeline?')} confirmDescription={t(selectedIds?.length > 0 ? 'Are you sure you want to delete selected event pipelines?' : 'Are you sure you want to delete this event pipeline?')} noteText={t('Once deleted this action cannot be undone.')} />
      }
      {isModalOpen && isGraphicalView &&
       <Modal isModalOpen={isModalOpen} width='fit-content' closeModal={() => {setIsModalOpen(false); setIsGraphicalView(false);}}>
         <EventPipelineGraphicalView id={graphicalViewId} />
       </Modal>
      }
      { addEventPipelineAccess && <ButtonContainer>
        <ButtonWithIcon variant='primary' icon='Add' onClick={()=> history.push('/settings/add-event-pipeline')}>{t('New')}</ButtonWithIcon>
        {selectedIds?.length > 0 && <Button variant='danger' onClick={() => setIsModalOpen(!isModalOpen)}>{t('Delete')}</Button>}
      </ButtonContainer>}
      { eventPipelineData.length > 0 && viewEventPipelineAccess &&
        <TableContainer>
          <DataGrid
            rows={generateRowData() ? generateRowData() : []}
            columns={getConfiguredColumns()}
            disableRowSelectionOnClick 
            onRowSelectionModelChange={handleRowSelectionModelChange}
            checkboxSelection={true}
            disableColumnMenu
            getRowHeight={() => 'auto'}
            getRowId={(row) => row?.id}
            sx={{
              '.MuiDataGrid-iconButtonContainer': {
                visibility: 'visible',
              },
              '.MuiDataGrid-sortIcon': {
                opacity: 'inherit !important',
              },
            }}
          /> 
          <PaginationBox>
            <Pagination 
              onChange={handleChange}
              pageSize={pageSize} 
              count={Math.ceil(pageCount/pageSize)} 
              setPageSize={(e) => setPageSize(e)}
              currentPage={currentPage}
            />
          </PaginationBox>
        </TableContainer>}  
      {eventPipelineData.length === 0 && viewEventPipelineAccess && <EmptyLabelWrapper>
        <EmptyLabel>{t('No Event Pipeline Found')}</EmptyLabel>
      </EmptyLabelWrapper>}
      { !viewEventPipelineAccess && <EmptyLabelWrapper>
        <NoPermissionMessage>{t('Unfortunately, you do not have permission to view Event Pipeline list at this time. Please contact your administrator for assistance.')}</NoPermissionMessage>
      </EmptyLabelWrapper>}
    </Container>
  );
};

export default EventPipeline;
