import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import PipelineArea from './EventPipelineTemplates/PipelineArea';
import usePipelineInfo from 'hooks/usePipelineInfo';
import Switch from 'components/Switch';

const SpinnerBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 400px;
  align-items: center;
  justify-content: center;
`;

const PipelineHeader = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;

const SwitchWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const Label = styled.div`
  font-size: 16px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  margin-bottom: 10px;
  gap: 15px;
  min-width: 600px;
  background-color: ${({ theme }) => theme.backgroundColor};
  > div > div {
    border: none;
    padding: initial;
  }
`;

const Pipelinetitle = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

interface IProps{
  id: number;
}

const EventPipelineGraphicalView: React.FC<IProps> = ({id}) => {
  const {t} = useTranslation(['common']);
  const {pipelineInfo, setPipelineInfo, actionNodes, filterNodes, scheduleNodes, epLoading, loading } = usePipelineInfo(id);

  const getLoading = () => {
    return (
      <SpinnerBox>
        <CircularProgress />
        <Label>{t('Loading...')}</Label>
      </SpinnerBox>
    );
  };

  return(
    <Container>
      {epLoading || loading ? getLoading():
        <>
          <PipelineHeader>
            <Pipelinetitle>{pipelineInfo.name}</Pipelinetitle>
            <SwitchWrapper>
              <Label>{pipelineInfo?.enabled ? t('Enabled'): t('Disabled')}</Label>
              <Switch checked={pipelineInfo.enabled} disabled={true} />
            </SwitchWrapper>
          </PipelineHeader>
          <PipelineArea 
            action_nodes={actionNodes} 
            filter_nodes={filterNodes} 
            schedule_nodes={scheduleNodes}
            pipelineInfo={pipelineInfo} 
            setPipelineInfo={setPipelineInfo}
            isReadOnly
            generateDelay={500} />
        </>
      }
    </Container>
  );
};

export default EventPipelineGraphicalView;