import SelectField from 'components/SelectField';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { GET_ALL_DEVICES } from 'api_configs/queries';
import { IOption } from 'interface';
import { useQuery } from '@apollo/client';
import { USB_VIDEO_SOURCE_TYPE } from '../../constants';
import { CircularProgress } from '@mui/material';

const VideoConfigurationsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const VerticalLine = styled.div`
  width: 980px;
  height: 10px;
  border-bottom: 1px solid lightgrey;
  margin-top: 2px;
  margin-bottom: 15px;
  margin-left: 18px;
  @media (max-width: 768px) {
    width: 450px;
  }
`;

const Heading = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-left: 18px;
`;

const SelectedCameraVideoConfigurations = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`;

const SpinnerBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  >span {
    margin-top: 10px;
  }
`;

const LeftSideWrapper = styled.div`
  padding: 20px;
  display: flex;
  gap: 20px;
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

interface IUSBData {
  usbDeviceName : string;
  usbVideoSourceType: string;
  selectedDevice?: string
}

interface IUSBCamera {
  usbData: IUSBData,
  isEditCamera?: boolean,
  setUsbData: React.Dispatch<React.SetStateAction<IUSBData>>,
  isEditAccess?: boolean
}

const USBCamera = ({usbData, setUsbData, isEditCamera, isEditAccess=false}: IUSBCamera) => {
  const {t} = useTranslation(['common']);
  const {data: getAllDevicesResponse, loading, refetch:getAllDevicesRefetch } = useQuery(GET_ALL_DEVICES);
  const [apiResponseOptions, setApiResponseOptions] = useState([{name: 'No Device Connected', value: ''}] as IOption[]);

  useEffect(() => {
    getAllDevicesRefetch();
  }, [getAllDevicesRefetch]);

  const isInputDisabled = () => {
    return getAllDevicesResponse?.getAllDevices?.result?.allDevices?.length === 0;
  };

  useEffect(() => {
    if(getAllDevicesResponse?.getAllDevices?.result?.allDevices && getAllDevicesResponse?.getAllDevices?.result?.allDevices?.length !== 0){
      setApiResponseOptions(getAllDevicesResponse?.getAllDevices?.result?.allDevices);
      if(!isEditCamera) {
        setUsbData((prevUsbData) => ({
          ...prevUsbData,
          usbDeviceName: getAllDevicesResponse?.getAllDevices?.result?.allDevices[0].name,
        }));
      }     
    }
  }, [getAllDevicesResponse?.getAllDevices?.result?.allDevices, setUsbData, isEditCamera]);
    
  return (
    <VideoConfigurationsWrapper>
      <HeadingWrapper>
        <Heading>{t('Video Configurations')}</Heading>
      </HeadingWrapper>
      <VerticalLine />
      <SelectedCameraVideoConfigurations>
        <LeftSideWrapper>
          <SelectField label={t('Video Device')} disabled={isInputDisabled() || isEditAccess} defaultValue={usbData.usbDeviceName} options={ apiResponseOptions } onChange={(value) =>  setUsbData({...usbData, usbDeviceName: value})}  />
          {
            loading && <SpinnerBox>
              <CircularProgress size={20} />
            </SpinnerBox> 
          }           
        </LeftSideWrapper>
        <LeftSideWrapper>
          <SelectField label={t('Video Source Type')} disabled={isInputDisabled() || isEditAccess} defaultValue={usbData.usbVideoSourceType} options={USB_VIDEO_SOURCE_TYPE} onChange={(value) => setUsbData({...usbData, usbVideoSourceType: value})} />
        </LeftSideWrapper>
      </SelectedCameraVideoConfigurations>
    </VideoConfigurationsWrapper> 
  );
};

export default USBCamera;