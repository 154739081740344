import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgWarning = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M11.997 6.6a.772.772 0 0 1 .771.771v5.4a.771.771 0 0 1-1.543 0v-5.4a.771.771 0 0 1 .772-.771Zm-1.158 9.643a1.157 1.157 0 1 0 2.314 0 1.157 1.157 0 0 0-2.314 0ZM22.8 12a1.528 1.528 0 0 1-.45 1.088l-9.264 9.264a1.543 1.543 0 0 1-2.176 0l-9.26-9.264a1.543 1.543 0 0 1 0-2.176l9.265-9.263a1.543 1.543 0 0 1 2.176 0l9.264 9.263A1.53 1.53 0 0 1 22.8 12Zm-1.542 0-9.26-9.258L2.737 12l9.26 9.258L21.257 12Z"
        fill={props.color}
      />
    </svg>
  );
};
export default SvgWarning;
