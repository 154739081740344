import Icon from 'components/Icon';
import Input from 'components/Input';
import { DEFAULT_AREA_POINTS, DEFAULT_TRAFFIC_PULSE_LINE_POINTS, IconType, LineUIType, PERMISSION_CODE, ZONE_LINEUI_CODES } from '../../../constants';
import { IAlertMessageType, INewAnalysisConfigsRes, IOption, IRedLightViolationConfigs } from '../../../interface';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IReducerActions } from 'components/LineUI/LineReducer';
import AlertBar from 'components/AlertBar';
import { AddNewLineWrapper, DeleteIconDiv, HorizontalDivider, IconDiv, LabelWrapper, LineDetailContainer, LineNameWrapper, LinesContainer, LinesListContainer } from './algoConfigStyles';
import { checkUniqueNames, getLineUIColor, isNullOrEmpty } from 'utils/utils';
import TokenService from 'api_configs/tokenService';

const ConfigurationForm = styled.div`
  display: flex;
  gap: 20px;
`;

const NoLineData = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 100%;
  border-radius: 5px;
  border: 1px solid #D0D7F2;
  min-height: 310px;
  min-width: 600px;
`;

const AddNewLineButton = styled.div<{disabled:boolean}>`
  background: ${({disabled}) => disabled ? '#8E9FDF' : 'linear-gradient(to top, #1538BD, #7287D7)' } ;
  color: #FFFFFF;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: ${({disabled}) => disabled ? 'not-allowed' : 'pointer' } ;
`;

const NoLineText = styled.div`
  max-width: 60%;
  font-size: 14px;
  color: ${({theme})=>theme.text.secondaryText};
  text-align: center;
`;

const Label = styled.div`
  font-size: 16px;
`;

const LineName = styled.div`
  width: 70px;
  white-space: nowrap;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface IRedLightViolationProps{
  selectedAlgorithmConfigsLength: number;
  selectedIndex: number;
  onAddNewLine: (a: boolean, data: IRedLightViolationConfigs, currentLength: number)=>void;
  handleRemoveLine?: (index: number, length: number, isSet: boolean)=>void;
  dispatch: React.Dispatch<IReducerActions>;
  onLineClickCallback?: (id: number, isSet: boolean) => void;
  setSelectedAlgoConfigs: React.Dispatch<React.SetStateAction<IRedLightViolationConfigs[]>>;
  selectedAlgoConfigs: IRedLightViolationConfigs[];
  scheduleOptions:IOption[];
  selectedAlgorithmConfigDetails: INewAnalysisConfigsRes;
  setSelectedAlgorithmConfigDetails: React.Dispatch<React.SetStateAction<INewAnalysisConfigsRes>>;
  setValidationMessage: React.Dispatch<React.SetStateAction<string>>;
}

const RedLightViolation: React.FC<IRedLightViolationProps> = ({selectedAlgorithmConfigsLength, setValidationMessage, selectedAlgoConfigs, dispatch, setSelectedAlgoConfigs, selectedIndex, handleRemoveLine=()=>{}, onLineClickCallback=()=>{}, onAddNewLine}) => {
  const {t} = useTranslation(['common']);
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'});

  const editAnalysisAccess = TokenService.hasAccess(PERMISSION_CODE.edit_analysis_configuration);

  const AddNewLine = useCallback(() => {
    if(selectedAlgorithmConfigsLength + 1 > 10){
      setMessage({text: t('configurationLimitWarning').replace('{X}',`${10}`), id:'',type:'danger'});
    }else{
      const newLineData: IRedLightViolationConfigs = {
        name: 'New Line' + (selectedAlgorithmConfigsLength + 1),
        index: selectedAlgorithmConfigsLength + 1,
        lineType: LineUIType.LINE_AREA_SET,
        styling: getLineUIColor(ZONE_LINEUI_CODES[selectedAlgorithmConfigsLength + 1]) as string,
        cross_line: DEFAULT_TRAFFIC_PULSE_LINE_POINTS.map(item => ({x: item.x , y: item.y + (selectedAlgorithmConfigsLength * 50)})),
        area: DEFAULT_AREA_POINTS.map(item => ({x: item.x + (selectedAlgorithmConfigsLength * 20), y: item.y})),
        red_color_range: [],
        direction: '',
      };
      onAddNewLine(true, {...newLineData}, selectedAlgorithmConfigsLength);
      setSelectedAlgoConfigs(prev => [...prev, newLineData]);
    }
  },[onAddNewLine, selectedAlgorithmConfigsLength, setSelectedAlgoConfigs, t]);

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAlgoConfigs((prev: IRedLightViolationConfigs[]) => {
      const selectedAlgoConfigs = [...prev];
      const selectedLineConfig = JSON.parse(JSON.stringify(selectedAlgoConfigs[selectedIndex - 1])); 
      selectedLineConfig.name = e.target.value;
      selectedAlgoConfigs[selectedIndex - 1] = selectedLineConfig;
      return selectedAlgoConfigs;
    });
    dispatch({
      type: 'RENAME_SET',
      index: selectedIndex - 1,
      data: {
        name: e.target.value
      }
    });
    dispatch({
      type: 'RENAME_SET',
      index: selectedIndex,
      data: {
        areaName: e.target.value
      }
    });
  },[setSelectedAlgoConfigs, selectedIndex, dispatch]);

  useEffect(() => {
    if (!checkUniqueNames(selectedAlgoConfigs)) {
      setValidationMessage('Duplicate Line Name values are not allowed');
    } else {
      let anyConditionTrue = false;

      for (const config of selectedAlgoConfigs) {
        if (isNullOrEmpty(config.name)) {
          setValidationMessage('Line Name should not be empty or null');
          anyConditionTrue = true;
          break;
        }
      }

      if (!anyConditionTrue) {
        setValidationMessage('');
      }
    }
  }, [selectedAlgoConfigs, setValidationMessage]);

  return (
    <ConfigurationForm>
      { message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
      {selectedIndex === 0 ? 
        <NoLineData>
          <NoLineText>{t('No Lines have been configured yet, Please add New Line')}</NoLineText>
          <AddNewLineButton onClick={editAnalysisAccess ? AddNewLine : undefined} disabled={!editAnalysisAccess}>{t('Add Line')}</AddNewLineButton>
        </NoLineData>:
        <>
          <LinesContainer>
            <AddNewLineWrapper>
              <LabelWrapper>
                <Icon icon='Line' size={20} />
                <Label>{t('Lines')}</Label>
              </LabelWrapper>
              <IconDiv title={t('Add New Lines')} onClick={AddNewLine}>
                {editAnalysisAccess && <Icon icon='Plus' size={16} />}
              </IconDiv>
            </AddNewLineWrapper>
            {selectedIndex !== 0 &&
            <LinesListContainer>
              {(selectedAlgoConfigs as IRedLightViolationConfigs[])?.map((line, index) => (
                <>
                  <LineNameWrapper isSelected={selectedIndex === index + 1} onClick={() => onLineClickCallback(index, true)}>
                    <div>{index + 1}.</div>
                    <LineName>{line?.name}</LineName>
                  </LineNameWrapper>
                  {index !== selectedAlgoConfigs.length - 1 && <HorizontalDivider />}
                </>
              ))}
            </LinesListContainer>}
          </LinesContainer>
          <LineDetailContainer>
            {editAnalysisAccess &&
            <DeleteIconDiv onClick={() => handleRemoveLine(selectedIndex - 1, selectedAlgorithmConfigsLength, true)}>
              <Icon icon='Delete' size={16} />
            </DeleteIconDiv>}
            <Input id='name' type='text' name='name' isRequired label={t('Line Name')} disabled={!editAnalysisAccess} value={selectedAlgoConfigs[selectedIndex - 1]?.name} onChange={handleInputChange} maxLength={32} />
          </LineDetailContainer>
        </>
      }
    </ConfigurationForm>
  );
};

export default RedLightViolation;