import React, {ChangeEvent, useState} from 'react';
import styled from 'styled-components';
import { HOURS, MINUTES, PERMISSION_CODE } from '../../constants';
import SelectField from '../../components/SelectField';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { useTranslation } from 'react-i18next';
import { IEmailDetails } from 'interface';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import ButtonWithLoading from 'components/ButtonWithLoading';
import TokenService from 'api_configs/tokenService';

const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 450px;
`;

const SelectFieldWrapper = styled.div``;

const InputFieldWrapper = styled.div`
  > div > input {
    margin-bottom: 0;
    width: 320px;
  }
  width: 320px;
`;

const IntroductionText = styled.div``;

const BasicFieldsWrapper = styled.div`
  display: flex;
  column-gap: 20px;
  margin-top: 10px;
`;

const ActionButtons = styled.div`
  display: flex;
  width: 1000px;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 15px;
  > button {
    width: max-content;
  }
`;

const Container = styled.div``;

const ConfigurationWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 450px;
  margin-top: 24px;
  margin-bottom: 14px;
`;

const ConfigurationLabel = styled.div`
  min-width: 225px;
  font-size: 20px;
`;

const ConfigurationLine = styled.div`
  height: 1px;
  background-color: #d3d3d3;
  width: inherit;
`;

interface IProps {
  emailDetails: IEmailDetails;
  onChange: (e?: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onHandleChangeHours: (e?: string) => void;
  onHandleChangeMinutes: (e?: string) => void;
  saveDetailsLoader: boolean,
  handlesSaveDetails: () => void;
  handleDelete: () => void;
  editMode?: boolean;
  handleUpdateDetails: () => void;
  desiredState: boolean;
  isActionButtonsDisable: boolean;
  onResetClick: () => void;
  onValidateConfigurationClick: () => void;
  onSendTestEmailClick: () => void;
  emailValidationLoader: boolean;
}

const AdvancedEmailSetting: React.FC<IProps> = ({emailDetails, emailValidationLoader, desiredState, onChange, onHandleChangeHours, onHandleChangeMinutes, handlesSaveDetails, saveDetailsLoader, handleDelete, editMode, handleUpdateDetails, isActionButtonsDisable, onResetClick, onValidateConfigurationClick, onSendTestEmailClick}) =>{
  const {t} = useTranslation(['common']);
  const [deleteClient, setDeleteClient] = useState<boolean>(false);
  const editEmailClientAccess = TokenService.hasAccess(PERMISSION_CODE.edit_email_client);
  const deleteEmailClientAccess = TokenService.hasAccess(PERMISSION_CODE.delete_email_client);

  return(
    <Container>
      <ConfigurationWrapper>
        <ConfigurationLabel>{t('Advanced Configuration')}</ConfigurationLabel>
        <ConfigurationLine />
      </ConfigurationWrapper>
      <FieldsWrapper>
        <IntroductionText>{t('AdvancedEmailSettingIntroText')}</IntroductionText>
        <BasicFieldsWrapper>
          <InputFieldWrapper>
            <Input id='days' name='days' onChange={onChange} value={emailDetails?.days} isRequired type='number' maxLength={2} label={t('Days')} />
          </InputFieldWrapper>
          <SelectFieldWrapper>
            <SelectField label={t('Hours')} width='320px' onChange={onHandleChangeHours} defaultValue={emailDetails.hours?.toString()} options={HOURS} />
          </SelectFieldWrapper>
          <SelectFieldWrapper>
            <SelectField label={t('Minutes')} width='320px' onChange={onHandleChangeMinutes} defaultValue={emailDetails.minutes?.toString()} options={MINUTES} />
          </SelectFieldWrapper>
        </BasicFieldsWrapper>
      </FieldsWrapper>
      <ActionButtons>
        <ButtonWithLoading variant='primary' loading={emailValidationLoader} onClick={onValidateConfigurationClick} >{t('Validate Configurations')}</ButtonWithLoading>
        <Button variant='primary' onClick={onSendTestEmailClick} >{t('Send Test Email')}</Button>
        <ButtonWithLoading variant='primary' loading={saveDetailsLoader} disabled={isActionButtonsDisable || !editEmailClientAccess} onClick={() => editMode ? handleUpdateDetails() : handlesSaveDetails()}>{t('Save')}</ButtonWithLoading>
        <Button variant='secondary' disabled={isActionButtonsDisable} onClick={onResetClick}>{t('Reset')}</Button>
        { editMode && <Button variant='danger' disabled={desiredState || !deleteEmailClientAccess} onClick={() => setDeleteClient(!deleteClient)}>{desiredState ? t('Deleting...') : t('Delete')}</Button>}
        {deleteClient && 
        <ConfirmationModal modalOpen={deleteClient} setModalOpen={setDeleteClient} onSubmit={()=> handleDelete()} hasConfirmationField={false}
          titleText={t('Delete Email Client')} confirmDescription={t('Are you sure you want to delete this Email Client?')} noteText={t('Once deleted this action cannot be undone.')}
        />}
      </ActionButtons>
    </Container>
  );
};

export default AdvancedEmailSetting;
