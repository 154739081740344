import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import Icon from 'components/Icon';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { GET_SCHEDULES_LIST } from 'api_configs/queries';
import { useQuery, useMutation } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import Pagination from 'components/Pagination';
import Config from '../../configs/config.json';
import Button from 'components/Button';
import Modal from 'components/Modal';
import { DELETE_SCHEDULE } from 'api_configs/mutations';
import { IAlertMessageType } from 'interface';
import AlertBar from 'components/AlertBar';
import { StatusCode, IconType, PERMISSION_CODE } from '../../constants';
import { Container } from 'CommonStyles';
import { useHeader } from 'contexts/HeaderContext';
import TokenService from 'api_configs/tokenService';
import ConfirmationModal from 'components/modals/ConfirmationModal';

const TableContainer = styled.div`
  width: 100%;
  .MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeaders{
    background-color: #F5F8FF;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle{
    font-weight: 600;
  }
  > div:nth-child(1) > div:nth-child(2){
    display: none;
  }
  > div > div:first-child{
  > div:first-child{
    font-size: 14px;
    color: #5a6269;
    > div > div > div > div:nth-child(2) > svg {
      display: none;
    }
  }
   > div:nth-child(2) {
    text-align: center;
    font-size: 14px;
    text-decoration: none;
    color:#7b8288;
  }
}
`;

const SpinnerBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 89vh;
  align-items: center;
  justify-content: center;
`;

const Label = styled.div`
  font-size: 16px;
`;

const DeleteScheduleLabel = styled.div`
  font-size: 18px;
`;

const ActionIcon = styled.div`
  cursor: pointer;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PaginationBox = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 10px 20px;
`;

interface IDays{
  days: string[];
}

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: end;
  width: 100%; 
  > button {
    width: max-content;
  }
`;

const DeleteIntroText = styled.div`
  font-size: 14px;
  margin-top: 10px;
`;

const ActionIconsWrapper = styled.div`
  display: flex;
  column-gap: 10px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  column-gap: 15px;
  justify-content: flex-end;
`; 

const CurrentAssignmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`;

const Line = styled.div`
  width: 85%;
  position: absolute;
  height: 1px;
  top: 60px;
  background-color: #b5aeae;
`;

const NavLink = styled(Link)`
  color: #5aaeea;
  text-decoration: none;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const EmptyLabelWrapper = styled.div`
  height: 700px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  justify-content: center;
  align-items: center;
  width: 100%; 
`;

const NoPermissionMessage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EmptyLabel = styled.div`
  font-size: 22px;
`;

const ScheduleNameWrapper = styled.div`
  padding-left: 16px;
`;

const ScheduleName = styled.div``;

interface IRules{
  startTime: String;
  endTime: string;
  days: IDays[];
}

interface IAssignedCamera {
  instance_id: string,
  name: string
}

interface ISchedule {
  id?: number;
  name?: string;
  rules?: IRules[];
  assignedCameras?: IAssignedCamera[];
}

const Schedules = () =>{
  const {t} = useTranslation(['common']);
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount ] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const {data, loading, refetch } = useQuery(GET_SCHEDULES_LIST);
  const [loader, setLoader] = useState(false);
  const [scheduleData, setScheduleData] = useState<ISchedule[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [deleteScheduleMutation] = useMutation(DELETE_SCHEDULE);
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'});
  const [currentAssignments, setCurrentAssignments] = useState<string[]>([]);
  const [selectedIds, setSelectedIds] = useState<GridRowSelectionModel>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const {updateHeaderTitle} = useHeader();
  const addScheduleAccess = TokenService.hasAccess(PERMISSION_CODE.add_schedule);
  const editScheduleAccess = TokenService.hasAccess(PERMISSION_CODE.edit_schedule);
  const deleteScheduleAccess = TokenService.hasAccess(PERMISSION_CODE.delete_schedule);
  const scheduleListAccess = TokenService.hasAccess(PERMISSION_CODE.schedule_list);

  useEffect(()=>{
    updateHeaderTitle(t('Schedules'), 'Schedule');
  },[updateHeaderTitle, t]);

  useEffect(()=>{
    setLoader(loading);
  },[loading]);

  useEffect(()=>{
    if(data?.getSchedules){
      setScheduleData(data?.getSchedules?.result ? data?.getSchedules?.result : []);
    }
    if(data?.getSchedules?.count){
      setPageCount(data?.getSchedules?.count);
    }
  },[data?.getSchedules]);

  const handleEditClick = useCallback((id: string) => {
    history.push(`/settings/edit-schedule/${id}`);
  },[history]);

  const getAssignedCameras = useCallback((id: number) => {
    const schedule = scheduleData.filter(item => item.id === id);
    const assignedCameras = schedule[0].assignedCameras?.map((item: IAssignedCamera) => item.instance_id);
    if(assignedCameras){
      setCurrentAssignments(assignedCameras);
    }
  },[scheduleData]);
  
  const columns: GridColDef[]  = useMemo(()=> [
    { 
      field: 'name', 
      headerName: t('Name'),
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => {
        return (
          <ScheduleNameWrapper>
            <ScheduleName>{params.row.name}</ScheduleName>
          </ScheduleNameWrapper>
        );
      }
    },
    { field: 'totalRules', headerName: t('Total Rules'), flex: 1, headerAlign: 'center', align: 'center'},
    { field: 'activeRules', headerName: t('Assigned Cameras'), flex: 1, headerAlign: 'center', align: 'center', sortable: false},
    {
      field: 'actions',
      headerName: '',
      flex: 1,
      align: 'right',
      sortable: false,
      renderCell: (params) => {
        return (
          <ActionIconsWrapper>
            { editScheduleAccess && <ActionIcon onClick={() => handleEditClick(params?.row?.id)}>
              <Icon icon='Edit' color='primary' />
            </ActionIcon>}
            { deleteScheduleAccess && <ActionIcon onClick={() => {setIsModalOpen(true); setSelectedId(params?.row?.id); getAssignedCameras(params?.row?.id);}}>
              <Icon icon='Delete' color='danger' />
            </ActionIcon>}
          </ActionIconsWrapper>
        );
      }
    }
  ],[t, handleEditClick, getAssignedCameras, editScheduleAccess, deleteScheduleAccess]);

  const getConfiguredColumns = useCallback(()=>{
    const configuredColumns = columns.filter((item)=>{
      return Config.ScheduleColumns.indexOf(item.field) !== -1;
    });
    return configuredColumns;
  },[columns]);

  const generateRowData = useCallback(() =>{
    return scheduleData?.map((item: ISchedule)=>(
      { 
        id: item.id, 
        name: item?.name,
        totalRules: item?.rules?.length,
        activeRules: item?.assignedCameras?.length
      }
    ));
  },[scheduleData]);

  const handleChange = (value: number) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    if(refetch) {
      refetch({ pageSize, pageNumber: currentPage });
    }
  }, [currentPage, pageSize, refetch]);

  const handleDeleteSchedule = async (id?: number) =>{
    try {
      const result = await deleteScheduleMutation({variables: { scheduleIds: selectedIds.length > 0 ? selectedIds : [id] }});
      if(result.data.deleteSchedules.statusCode === StatusCode.SUCCESS){
        setMessage({text: t('Schedules deleted successfully'), id:'',type:'success'});
        setIsModalOpen(false);
        setSelectedIds([]);
        refetch();
      }else {
        setMessage({text: t('apiError'), id:'',type:'danger'});
      }
    } catch(e){
      setMessage({text: t('apiError'), id:'',type:'danger'});
    }
  };

  const handleRowSelectionModelChange = (params: GridRowSelectionModel) => {
    const deletableIds: number[] = [];
    if(params.length){
      scheduleData.forEach((schedule) =>{
        if(params.includes(schedule?.id as number) && schedule.assignedCameras?.length === 0){
          deletableIds.push(schedule.id!);
        }
      });
    }
    setSelectedIds(deletableIds);
  };
  
  if (loader) {
    return (
      <SpinnerBox>
        <CircularProgress />
        <Label>{t('Loading...')}</Label>
      </SpinnerBox>
    );
  }

  return(
    <Container>
      {message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
      {isDeleteModalOpen &&
      <ConfirmationModal modalOpen={isDeleteModalOpen} setModalOpen={setIsDeleteModalOpen} onSubmit={()=> handleDeleteSchedule()} hasConfirmationField={false}
        titleText={t('Delete Schedules?')} confirmDescription={t('Are you sure you want to delete selected schedules?')} noteText={t('Once deleted this action cannot be undone.')} />
      }
      {isModalOpen &&
       <Modal isModalOpen={isModalOpen} width='40vw' closeModal={() => setIsModalOpen(false)}>
         <ModalContent>
           <DeleteScheduleLabel>{t('Delete Schedule')}</DeleteScheduleLabel>
           <Line />
           <DeleteIntroText> {t('DeleteScheduleIntroText').replace('{X}', `${currentAssignments?.length}`)}</DeleteIntroText>
           <Label>{t('Current Assignments')}: {currentAssignments?.length} </Label>
           <CurrentAssignmentWrapper>
             {currentAssignments?.map((cameraName: string) =>( <NavLink to={`/cameras/${cameraName}`}>{cameraName}</NavLink>))}
           </CurrentAssignmentWrapper>
           <ButtonsWrapper>
             <Button variant='secondary' onClick={() => setIsModalOpen(false)}>{t('Cancel')}</Button>
             <Button variant='danger' disabled={currentAssignments.length > 0 ? true : false} onClick={() => handleDeleteSchedule(selectedId)}>{t('Delete')}</Button>
           </ButtonsWrapper>
         </ModalContent>
       </Modal>
      }
      <ButtonsContainer>
        {scheduleData.length > 0 && <Button variant='primary' onClick={() => history.push('/settings/add-schedule')} disabled={!addScheduleAccess} >{t('Add Schedule')}</Button>}
        {selectedIds.length > 0 && <Button variant='danger' onClick={() => setIsDeleteModalOpen(!isDeleteModalOpen)} >{t('Delete')}</Button>}
      </ButtonsContainer>
      { scheduleListAccess && scheduleData.length > 0 && <TableContainer>
        <DataGrid
          rows={generateRowData() ? generateRowData() : []}
          columns={getConfiguredColumns()}
          disableRowSelectionOnClick 
          checkboxSelection
          disableColumnMenu
          getRowId={(row) => row?.id}
          onRowSelectionModelChange={handleRowSelectionModelChange}
        /> 
        <PaginationBox>
          <Pagination 
            onChange={handleChange}
            pageSize={pageSize} 
            count={Math.ceil(pageCount/pageSize)} 
            setPageSize={(e) => setPageSize(e)}
            currentPage={currentPage}
          />
        </PaginationBox>
      </TableContainer> }
      {scheduleListAccess && scheduleData.length <= 0  && <EmptyLabelWrapper>
        <EmptyLabel>{t('No Schedules Found')}</EmptyLabel>
        {
          editScheduleAccess && <Button variant='primary' onClick={() => history.push('/settings/add-schedule')} disabled={!addScheduleAccess} >{t('Add Schedule')}</Button>
        }
      </EmptyLabelWrapper> }
      { !scheduleListAccess && <EmptyLabelWrapper>
        <NoPermissionMessage>{t('Unfortunately, you do not have permission to view Schedule list at this time. Please contact your administrator for assistance.')}</NoPermissionMessage>
      </EmptyLabelWrapper>}
    </Container>
  );
};

export default Schedules;
