import React, { useState, ChangeEvent, useEffect } from 'react';
import { Container } from 'CommonStyles';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import SelectField from 'components/SelectField';
import Input from 'components/Input';
import { AWS_REGIONS, EXPORT_OPTIONS, IconType, PERMISSION_CODE, StatusCode } from '../../constants';
import Button from 'components/Button';
import Switch from 'components/Switch';
import { useQuery, useMutation } from '@apollo/client';
import { GET_EXPORT_SETTING } from 'api_configs/queries';
import { SAVE_EXPORT_SETTING } from 'api_configs/mutations';
import { IAlertMessageType } from 'interface';
import AlertBar from 'components/AlertBar';
import CircularProgress from '@mui/material/CircularProgress';
import { useHeader } from 'contexts/HeaderContext';
import Title from 'components/Title';
import TokenService from 'api_configs/tokenService';
import { isEqual } from 'lodash';

const MainWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

const AWSConfigurationFields = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  padding: 15px 30px 30px 22px;
  height: 460px;
  border-radius: 4px;
  border: 0.2px solid lightgrey;
`;

const ConfigurationWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 450px;
  margin-top: 5px;
  margin-bottom: 14px;
  height: 30px;
`;

const SelectFieldWrapper = styled.div``;

const EnableCameraWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-top: -8px;
`;

const SwitchLabel = styled.span``;

const ButtonWrapper = styled.div`
  width: 450px;
  text-align: center;
  margin-top: 50px;
  > button {
    height: 36px;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 450px;
`;

const SpinnerBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 89vh;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.backgroundColor};
`;

const Label = styled.div`
  font-size: 16px;
`;

export interface IS3ConfigurationDetails {
  isEnabled: boolean;
  aws_region: string;
  aws_access_key?: string;
  aws_secret_key?: string;
  bucket_name?: string;
}

const Export: React.FC<{}> = () => {
  const {t} = useTranslation(['common']);
  const [s3ConfigurationDetails, setS3ConfigurationDetails] = useState<IS3ConfigurationDetails>({isEnabled: true, aws_region: 'ap-south-1', aws_access_key: '', aws_secret_key: '', bucket_name: ''});
  const [s3PrevConfigurationDetails, setPrevS3ConfigurationDetails] = useState<IS3ConfigurationDetails>({isEnabled: false, aws_region: 'ap-south-1', aws_access_key: '', aws_secret_key: '', bucket_name: ''});
  const {data: s3ConfigDetails, loading: s3ConfigLoading } = useQuery(GET_EXPORT_SETTING, { variables: {recordType: EXPORT_OPTIONS.EXPORT_TO_S3} });
  const [saveExportConfigMutation] = useMutation(SAVE_EXPORT_SETTING);
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'});
  const [s3ConfigLoader, setS3ConfigLoader] = useState(false);
  const {updateHeaderTitle} = useHeader();
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const editExportSettingAccess = TokenService.hasAccess(PERMISSION_CODE.edit_export_setting);

  useEffect(()=>{
    updateHeaderTitle(t('Export'), 'Export');
  },[updateHeaderTitle, t]);

  useEffect(()=>{
    setS3ConfigLoader(s3ConfigLoading);
  },[s3ConfigLoading]);

  useEffect(()=>{
    if(s3ConfigDetails?.getExportSetting?.statusCode === StatusCode.SUCCESS){
      const result = JSON.parse(s3ConfigDetails.getExportSetting.result.data);
      if(result){
        const response = {
          isEnabled: result?.enable,
          aws_region: result?.aws_region,
          aws_access_key: result?.aws_access_key,
          aws_secret_key: result?.aws_access_key,
          bucket_name: result?.bucket_name
        };
        setS3ConfigurationDetails(response);
        setPrevS3ConfigurationDetails(response);
      } 
    }
  },[s3ConfigDetails]);

  const onHandleChangeS3Configuration = (event?: ChangeEvent<HTMLInputElement | HTMLSelectElement>)  =>{
    const { name, value } = event?.target || {};
    if(name === 'accessKey'){
      setS3ConfigurationDetails((prev: IS3ConfigurationDetails) => ({...prev, aws_access_key: value}));
    }
    if(name === 'secretKey'){
      setS3ConfigurationDetails((prev: IS3ConfigurationDetails) => ({...prev, aws_secret_key: value}));
    }  
    if(name === 'bucketName'){
      setS3ConfigurationDetails((prev: IS3ConfigurationDetails) => ({...prev, bucket_name: value}));
    }  
  };
  
  const onSaveS3Configuration = async () =>{
    if(s3ConfigurationDetails.aws_access_key?.replace(/\s/g, '').length === 0){
      setMessage({text: t('Please enter the access key'), id:'',type:'danger'});
      return;
    } else if(s3ConfigurationDetails.aws_secret_key?.replace(/\s/g, '').length === 0){
      setMessage({text: t('Please enter the secret key'), id:'',type:'danger'});
      return;
    } if(s3ConfigurationDetails.bucket_name?.replace(/\s/g, '').length === 0){
      setMessage({text: t('Please enter the bucket name'), id:'',type:'danger'});
      return;
    } 
    const payload = {
      recordType: EXPORT_OPTIONS.EXPORT_TO_S3,
      data : JSON.stringify({ aws_region: s3ConfigurationDetails.aws_region, aws_access_key: s3ConfigurationDetails.aws_access_key, aws_secret_key: s3ConfigurationDetails.aws_secret_key, enable: s3ConfigurationDetails.isEnabled, bucket_name: s3ConfigurationDetails.bucket_name })
    };

    try {
      const result = await saveExportConfigMutation({variables: {payload}});
      if(result.data.putExportSetting.statusCode === StatusCode.SUCCESS){
        setMessage({text: t('S3 configuration saved successfully'), id:'',type:'success'});
      }else {
        setMessage({text: t('apiError'), id:'',type:'danger'});
      }
    } catch(e){
      setMessage({text: t('apiError'), id:'',type:'danger'});
    }
  };

  useEffect(() =>{
    setSaveButtonDisabled(isEqual(s3PrevConfigurationDetails, s3ConfigurationDetails));
  },[s3ConfigurationDetails, s3PrevConfigurationDetails]);

  if(s3ConfigLoader) {
    return (
      <SpinnerBox>
        <CircularProgress />
        <Label>{t('Loading...')}</Label>
      </SpinnerBox>
    );
  }

  return(
    <Container>
      {message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
      <MainWrapper>
        <AWSConfigurationFields>
          <HeaderWrapper>
            <ConfigurationWrapper>
              <Title text={t('AWS S3 Configuration')} size='20px'/>
            </ConfigurationWrapper>
            <EnableCameraWrapper>
              <SwitchLabel>{s3ConfigurationDetails.isEnabled ? t('Enabled'): t('Disabled')}</SwitchLabel>
              <Switch checked={s3ConfigurationDetails.isEnabled} onChange={() => setS3ConfigurationDetails(prev => ({...prev, isEnabled: !s3ConfigurationDetails.isEnabled }))}/>
            </EnableCameraWrapper>
          </HeaderWrapper>
          <SelectFieldWrapper>
            <SelectField width='450px' isRequired defaultValue={s3ConfigurationDetails.aws_region} onChange={(value) => setS3ConfigurationDetails(prev => ({...prev, aws_region: value}))} label={t('Region')} options={AWS_REGIONS}/>
          </SelectFieldWrapper>
          <Input id='accessKey' name='accessKey' onChange={onHandleChangeS3Configuration} value={s3ConfigurationDetails.aws_access_key} isRequired type='text' label={t('Access Key')} maxLength={64} />
          <Input id='secretKey' name='secretKey' onChange={onHandleChangeS3Configuration} value={s3ConfigurationDetails.aws_secret_key} isRequired type='password' label={t('Secret Key')} maxLength={64} />
          <Input id='bucketName' name='bucketName' onChange={onHandleChangeS3Configuration} value={s3ConfigurationDetails.bucket_name} isRequired type='text' label={t('Bucket Name')} maxLength={64} />
          <ButtonWrapper>
            <Button variant='primary' onClick={onSaveS3Configuration} disabled={!editExportSettingAccess || saveButtonDisabled}>{t('Save')}</Button>
          </ButtonWrapper>
        </AWSConfigurationFields>
      </MainWrapper>
    </Container>
  );
};

export default Export;