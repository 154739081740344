import { useMutation } from '@apollo/client';
import TokenService from './tokenService';
import { LOGIN, LOGOUT, REFESHTOKEN } from './mutations';
import { StatusCode } from '../constants';

function AuthService() {
  const [login] = useMutation(LOGIN);
  const [logout] = useMutation(LOGOUT);
  const [refreshToken] = useMutation(REFESHTOKEN);

  const loginUser = async (form:{}) => {
    const res = await login({ variables: { payload: form } });
    if (res.data && res.data.login.result) {
      TokenService.setUser(res.data.login.result);
    }
    return res.data;
  };

  const logoutUser = async () => {
    const _user = TokenService.getLocalAccessToken();
    const res = await logout({ variables: { accessToken: _user } });
    if (res.data.logout.statusCode === StatusCode.SUCCESS) {
      TokenService.removeUser();
      window.location.href = '/login';
    }else{
      TokenService.removeUser();
      window.location.href = '/login';
    }
    return res.data.status;
  };

  const getRefreshToken = async () => {
    const _user = TokenService.getLocalRefreshToken();
    const res = await refreshToken({ variables: { refreshToken: _user } });
    if (res?.data?.refreshToken?.statusCode === StatusCode.SUCCESS) {
      TokenService.updateLocalAccessToken(res.data.refreshToken.result);
      window.location.reload();
      localStorage.setItem('token_expired', JSON.stringify(false));
    } else if(res?.data?.refreshToken?.statusCode ===  StatusCode.REFRESH_TOKEN_EXPIRED){
      TokenService.removeUser();
      window.location.href = '/login';
      localStorage.setItem('token_expired', JSON.stringify(false));
    }
  };

  return {
    loginUser,
    logoutUser,
    getRefreshToken
  };
}

export default AuthService;