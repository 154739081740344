import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Label from './Label';
import Icon from './Icon';
import { specialSymbol } from 'utils/utils';
import { STRONG_PASSWORD_MIN_MAX_VALUES } from '../constants';

interface Ownprops {
  type: string;
  label: string;
  isRequired?: boolean;
  isCustomInput?: boolean;
  message?: {text: string, id: string}
  value?: string | number;
  width?: string;
  hasCopyButton?: boolean;
  hasTooltip?: boolean;
  strongPassword?: boolean;
  isIncreamentDecreament?: boolean;
  setIsUserPasswordStrong?: React.Dispatch<React.SetStateAction<boolean>>
}

type InputProps = Ownprops & React.InputHTMLAttributes<HTMLInputElement>

const InputContainer = styled.div<{width?: string}>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${({width}) => width ? width : '100%'};
`;

const InputField = styled.input<{ isError: boolean | undefined; type: string; hasCopyButton: boolean; isIncreamentDecreament: boolean }>`
  padding-top: 2px;
  padding-left: 8px;
  padding-right: ${({ hasCopyButton, type }) => (hasCopyButton || type === 'password') ? '38px' : '8px'};
  height: 40px;
  width: 100%;
  border-radius: 6px;
  margin-bottom: 16px;
  border: 1px solid lightgrey;
  text-overflow: ellipsis;
  font-size: 16px;
  border-color: ${({ isError }) => isError ? 'red' : ''};
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.input.focus};
  }
  &:disabled {
    cursor: no-drop !important;
  }
  ::placeholder {
    font-style: italic;
    font-size: 12px;
  }

  ${({ isIncreamentDecreament }) => !isIncreamentDecreament && `
    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type='number'] {
      appearance: textfield;
      -moz-appearance: none;
    }
  `}
`;

const ToggleIcon = styled.span<{width: string}>`
  position: absolute;
  top: 40px;
  right: 36px;
  transform: translateY(-50%);
  cursor: pointer;
`;

const VerificationIcons = styled.div`
  display: flex;
  align-items: center;
  margin-top: -2px;
`;

const VerificationIcon = styled(Icon)`
  margin-right: 5px;
`;

const MessageWrapper = styled.span`
  margin-left: 3px;
  margin-right: 3px;
  font-size: 14px;
`;

const CopyButtonWrapper = styled.span<{disabled: boolean}>`
  cursor: ${({disabled}) => disabled ? 'not-allowed': 'pointer'};
  pointer-events: ${({disabled}) => disabled ? 'none': 'auto'};
  position: absolute;
  top: 20px;
  left: 412px;
  padding: 6px;
  height: 38px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  transform: translateY(-50%);
  background-color: #F6F6F6;
  border-left: 1px solid #CED4DA;
`;

const IconWrapper = styled.span`
  position: absolute;
`;

const UnitButton = styled.span`
  position: absolute;
  top: 53px;
  left: 372px;
  cursor: auto;
  width: 80px;
  transform: translateY(-50%);
  background-color: rgb(228, 228, 228);
  color: #93A5AC;
  padding: 10px 0 10px 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const Input: React.FC<InputProps> = ({ type, label, isRequired=false, strongPassword=false, isIncreamentDecreament=false, setIsUserPasswordStrong, hasCopyButton=false, message, id, onChange, value, isCustomInput, width='450px', name, maxLength, placeholder, disabled=false, ...Props }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [iconName, setIconName] = useState('Copy');
  const [inputFocused, setInputFocused] = useState(false);
  const [passwordVerification, setPasswordVerification] = useState({
    length: false,
    containsNumber: false,
    containsSpecialSymbol: false,
  });
  const {t} = useTranslation(['common']);

  useEffect(() => {
    setTimeout(() => {
      setIconName('Copy');
    }, 2000);
  }, [iconName]);

  useEffect(() => {
    if(setIsUserPasswordStrong) {
      if(passwordVerification.length && passwordVerification.containsNumber && passwordVerification.containsSpecialSymbol) {
        setIsUserPasswordStrong(true);
      }else {
        setIsUserPasswordStrong(false);
      }
    }
  }, [setIsUserPasswordStrong, passwordVerification]);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleInputFocus = () => {
    setInputFocused(false);
  };
  
  const handleInputBlur = () => {
    setInputFocused(true);
  };

  const handlePasswordChange = (newValue: string) => {
    if (strongPassword) {
      const lengthValid = newValue.length >= STRONG_PASSWORD_MIN_MAX_VALUES.MIN_VALUE && newValue.length <= STRONG_PASSWORD_MIN_MAX_VALUES.MAX_VALUE;
      const containsNumber = /\d/.test(newValue);
      const containsSpecialSymbol = specialSymbol.test(newValue);

      setPasswordVerification({
        length: lengthValid,
        containsNumber,
        containsSpecialSymbol,
      });
    }
  };

  const handleCopy = (value: string) => {
    const textArea = document.createElement('textarea');
    textArea.value = value;
    textArea.style.position = 'fixed';
    textArea.style.top = '0';
    textArea.style.left = '0';
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    setIconName('Success');
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if(onChange) {
      onChange(e);
      if(strongPassword) {
        handlePasswordChange(e.target.value);
      }
    }
  };

  return (
    <InputContainer width={width}>
      {label && <Label labelText={label} isMandatory={isRequired} />}
      <InputField type={showPassword ? 'text' : type} name={name} id={id} maxLength={maxLength} disabled={disabled} placeholder={placeholder} isError={message?.id === id} onChange={(e) => handleOnChange(e)} value={value} required onBlur={handleInputBlur} onFocus={handleInputFocus} {...Props} hasCopyButton={hasCopyButton} isIncreamentDecreament={isIncreamentDecreament}/>
      {type === 'password' && (
        <ToggleIcon onClick={togglePasswordVisibility} width={width}>
          {showPassword ? <IconWrapper><Icon icon='ShowEye' /></IconWrapper> : <IconWrapper><Icon icon='HideEye' /></IconWrapper>}
        </ToggleIcon>
      )}
      {isCustomInput && <UnitButton>{t('Seconds')}</UnitButton>}
      {hasCopyButton && (
        <CopyButtonWrapper disabled={disabled} onClick={() => handleCopy(value as string)}>
          <Icon icon={iconName} />
        </CopyButtonWrapper>
      )}
      {(strongPassword && (value as string).length > 1 && inputFocused && (!passwordVerification.length || !passwordVerification.containsNumber || !passwordVerification.containsSpecialSymbol)) && (
        <VerificationIcons>
          <VerificationIcon icon={passwordVerification.length ? 'Success' : 'Close'} color={passwordVerification.length ? 'success': 'danger'} /> <MessageWrapper>{t('Between 5 & 16')}</MessageWrapper>
          <VerificationIcon icon={passwordVerification.containsNumber ? 'Success' : 'Close'} color={passwordVerification.containsNumber ? 'success': 'danger'} /> <MessageWrapper>{t('Contains number')}</MessageWrapper>
          <VerificationIcon icon={passwordVerification.containsSpecialSymbol ? 'Success' : 'Close'} color={passwordVerification.containsSpecialSymbol ? 'success': 'danger'} /> <MessageWrapper>{t('Contains special character')}</MessageWrapper>
        </VerificationIcons>
      )}
    </InputContainer>
  );
};

export default Input;