import { useLazyQuery, useQuery, useSubscription } from '@apollo/client';
import { GET_DETECTIONS_COUNT, GET_FILTERED_CHARTS, GET_STREAM } from 'api_configs/queries';
import { LEAFLET_TILE_URL, MAP_CENTER, SNAPSHOT_URL, LEAFLET_ONLINE_URL, PERMISSION_CODE, SUPERSET_URL, TRAFFIC_PULSE_CLASSES, SUPERSET_REFRESH_INTERVAL, ALGORITHM_CODES } from '../constants';
import { LatLngExpression } from 'leaflet';
import { useHeader } from 'contexts/HeaderContext';
import { ColumnConfig, IBreadcrum, ICameraInfo, IGetAllAlgorithmRes, IStream } from 'interface';
import React, { useEffect, useState, useCallback, useRef, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { MapContainer, Marker, TileLayer, Tooltip } from 'react-leaflet';
import { useHistory, useParams } from 'react-router';
import styled, { css, keyframes } from 'styled-components';
import MapMarker from '../svgs/camera.svg';
import LineViewer from 'components/LineViewer';
import { useStreamStatus } from 'hooks/useStreamStatus';
import { getStatusColor } from 'utils/utils';
import AwaitingStreamEng from '../svgs/Awaiting_Stream-ENG.svg';
import { useAnnotation } from 'contexts/AnnotaionContext';
import l from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import Tabs, { ITabs } from 'components/Tabs';
import Alerts from 'components/Alerts';
import Detections from 'components/Detections';
import Icon from 'components/Icon';
import ZoneMap from 'components/mapview/ZoneMap';
import Config from '../configs/config.json';
import Modal from 'components/Modal';
import Table from 'components/CustomTable';
import { ScrollBarCss } from 'CommonStyles';
import TokenService from 'api_configs/tokenService';
import Calendar from 'components/Calender';
import Skeleton from 'components/skeletons/Skeleton';
import { DETECTION_SUB } from 'api_configs/subscriptions';
import Loader from 'components/loaders/Loader';
import Odometer from 'components/Odometer';
import { convertToJST } from 'utils';
import { AiOutlineBarChart } from 'react-icons/ai';
import { MdOutlineHideImage } from 'react-icons/md';
import { CircularProgress } from '@mui/material';
import { FaFolder } from 'react-icons/fa';
import Jobs from './camera-detail-tabs/Jobs';
import OverViewTab from './camera-detail-tabs/OverViewTab';
import SourceFiles from './camera-detail-tabs/SourceFiles';

const CameraDetailsPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
`;

const CameraDetailsPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

const AllVisualizationContainer = styled.div`
  width: 100%;
  border-radius: 10px;
  margin-left: -10px;
  font-size: 18px;
  display: flex;
  transition: all 0.6s ease-in-out;
`;

const VisualizationWrapper = styled.div`
  margin: 0 0 5px 10px;
  display: flex;
  z-index: 9;
`;

const VisualizationToggleWrapper = styled.span<{isAlertIconPresent: boolean}>`
  position: absolute;
  top: 14px;
  z-index: 9;
  right: ${({isAlertIconPresent}) => !isAlertIconPresent ? '116px' : '172px'};
  cursor: pointer;
`;

const AllChartOptionsContainer = styled.div`
  position: relative;
`;

const AllChartOptionsWrapper = styled.div<{visualizationIconToggle: boolean}>`
  display: ${({visualizationIconToggle}) => visualizationIconToggle ? 'flex' : 'none'};
  flex-direction: column;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  position: absolute;
  background-color: ${({theme}) => theme.backgroundColor};
  border-radius: 5px;
  height: fit-content;
  border-radius: 10px;
  right: 0;
  top: 0;
  margin-top: 11px;
  z-index: 9;
`;

const Arrow = styled.div`
  width: 15px;
  height: 15px;
  background-color: ${({theme}) => theme.backgroundColor};
  position: absolute;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  right: 8px;
  top: -8px;
  transform: rotate(45deg);
  border-top: 1px solid #DCE2E4;
  border-left: 1px solid #DCE2E4;
`;

const ChartOptionsWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 49px;
  cursor: pointer;
  background-color: #FFFFFF;
  border: 1px solid #DCE2E4;
  :hover{
    background-color: #EEFCFF;
  }
  padding: 10px;
  height: fit-content;
  z-index: 9;
`;

const PaginationWrapper = styled.div`
 display: flex;
 gap: 8px;
 height: 49px;
 align-items: center;
 padding-right: 22px
  > nav {
    margin: 0 auto;
  }
`;

const RefreshLabel = styled.div`
  font-size: 16px;
`;

const SelectOptionField = styled.select`
  border-radius: 8px;
  height: 35px;
  margin: auto;
  border: solid 1px #BFCDD2;
  padding: 0 6px 0 0px;
`;

const ChartOption = styled.div`
  min-width: 140px;
`;

const VisualizationIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TabsContainer = styled.div`
  position: relative;
  max-width: 100%;
  margin-top: 20px ;
  > div > div:nth-child(2){
    display: flex;
  }
`;

const CameraTopDiv = styled.div`
  display: flex;
  gap: 20px;
  width: 100% ;
  margin-top: 36px;
`;

const ZoneMapWrapper = styled.div`
  position: relative;
  width: calc(50%);
  min-height: 300px;
  >div {
    z-index: 1;
  }
`;

const CameraMapView = styled.div<{ isMapViewConfigured: boolean }>`
  flex: 1;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  height: 330px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  >div{
    z-index: 1;
  }
  >div > div :nth-child(4) > div {
    background-color: transparent;
    border: none;
  }
  @media only screen and (min-width: 1366px){
    height: 332px;
  }
  @media only screen and (min-width: 1920px){
    height: 490px;
  }

  ${({ isMapViewConfigured }) => !isMapViewConfigured && css`
    &:before{
      content: '';
      width: 100%;
      height: 100%;
      opacity: 0.5;
      background-color: black;
      position: absolute;
      z-index: 2;
      cursor: no-drop;
    }
    &:hover::after {
      content: 'Camera Location is not enabled';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      z-index: 2;
      cursor: no-drop;
    }
  `}
`;

const pulse = keyframes`
  100%{
    transform: scale(1.7);
    opacity: 0;
  }
`;

const BlinkDiv = styled.div<{ isBlinking: boolean }>`
  ${({ isBlinking }) =>
    isBlinking &&
    css`
      &:before,&:after{
        content: "";
        position: absolute;
        left: 30px;
        width: 46px;
        height: 46px;
        border-radius: 50%;
        background-color: #fe1900;
        z-index: 6;
        opacity: 0.6;
      }
      &:before{
        animation: ${pulse} 2s ease-out infinite;
      }
      &:after{
        animation: ${pulse} 2s 1s ease-out infinite;
      }
  `}
`;

const Label = styled.span``;

const LineViewerWrapper = styled.div`
  flex: 1;
  @media only screen and (min-width: 1366px) {
    width: 598px;
  }
  @media only screen and (min-width: 1920px){
    width: 730px;
  }
`;

const RightSection = styled.div`
  flex: 1;
  position: relative;
`;

const MapCountWrapper = styled.div`
  border: 1px solid #bbb;
  border-radius: 6px;
`;

const VideoSnapshotToggle = styled.div`
  position: absolute;
  top: -34px;
  right: 6px;
  border: 1px solid ${({theme}) => theme.divider};
  border-bottom: none;
  border-radius: 6px 6px 0 0;
  display: flex;
  >:nth-child(1){
    border-right: 1px solid ${({theme}) => theme.divider};
  }
`;

const Span = styled.span<{isSelected?: boolean}>`
  padding: 6px 10px;
  flex: 1;
  width: 130px;
  text-align: center;
  cursor: pointer;
  background-color: ${({isSelected}) => isSelected ? '#D0D7F2' : ''};
`;

const CustomButton = styled.div`
  padding: 8px 12px;
  background-color: #435FCA;
  color: #FFF;
  cursor: pointer;
  border-radius: 6px;
  margin-top: 10px;
`;

const DataTableWrapper = styled.div`
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 6px;
  height: 490px;
  @media only screen and (min-width: 1366px){
    height: 334px;
  }
  @media only screen and (min-width: 1920px){
    height: 490px;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const FiltersSection = styled.div`
  margin: 6px 0 0 6px;
`;

const DataContainer = styled.div`
  overflow: auto;
  height: 100%;
  ${ScrollBarCss};
`;

const CameraDetailsHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ButtonsWrapper = styled.div<{position?: string}>`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  ${({position}) => position && css`
    position: absolute;
  `}
`;

const ConfigurationButton = styled.div`
  display: flex;
  align-items: center;
  border-radius: 6px;
  gap: 10px;
  padding: 7px 10px;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  background-color: #435FCA;
  >span{
    color: #FFF;
    margin-bottom: 2px;
  }
  >div{
    >svg>g>path{
      stroke: ${({ theme }) => theme.text.lightText};
      fill: ${({ theme }) => theme.text.lightText};
    }
  }
`;

const CustomMarkerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100px;
  background-color: transparent;
  margin-left: -14px;
`;

const ActionIcon = styled.div<{ isZoneMap: boolean }>`
  position: absolute;
  cursor: pointer;
  width: 36px;
  height: 36px;
  padding: 6px;
  background-color: ${({ theme }) => theme.backgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 2px solid ${({ theme }) => theme.divider};
  top: 10px;
  right: ${({ isZoneMap }) => isZoneMap ? '10px' : '48px'} ;
  z-index: 1000;
  :hover{
    background-color: #F5F6FA;
  }
`;

const ModalMapViewContainer = styled.div`
  border-radius: 6px;
  display: flex ;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  margin-top: 10px;
  height: calc(100vh - 130px);
  width: 1180px;
  gap: 10px;
  >div > div :nth-child(4) > div {
    background-color: transparent;
    border: none;
  } 
  @media only screen and (min-width: 1366px){
    width: 1182px;
  }
  @media only screen and (min-width: 1440px){
    width: 1250px;
  }
  @media only screen and (min-width: 1600px){
    width: 1390px;
  }
  @media only screen and (min-width: 1680px){
    width: 1470px;
  }
  @media only screen and (min-width: 1920px){
    width: 1685px;
  }
  @media only screen and (min-width: 2560px){
    width: 2260px;
  }
  @media only screen and (min-width: 3840px){
    width: 3416px;
  }
`;

const VisualizationContainer = styled.div<{isVisible: boolean}>`
  width: ${({ isVisible }) => (isVisible ? '100%' : '0')};
  height: 300px;
  max-height: ${({ isVisible }) => (isVisible ? '300px' : '0')};
  border: 1px solid ${({theme})=>theme.divider};
  border-radius: 10px;
  font-size: 18px;
  display: flex;
  overflow: hidden;
  transition: max-height 0.6s ease-in-out;
`;

const CameraIcon = styled.img`
  width: 48px;
  height: 48px;
`;

const CustomToolTip = styled(Tooltip)`
  position: absolute;
  top: -15px;
  height: auto;
  width: auto;
  display: flex;
  flex-direction: column;
  z-index: 999;
  gap: 5px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
`;

const ToolTipImg = styled.img`
  width: 250px;
  height: 100%;
  aspect-ratio: 16/9;
  object-fit: contain;
`;

const LocationDiv = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  margin-top: -4px;
  margin-left: -5px;
  width: 110px;
  padding: 5px;
  line-height: 1;
`;

const SingleChartWrapper = styled.div`
  position: relative;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  > iframe {
    border: none;
  }
`;

const NoChartsMessage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  margin-left: 10px;
  border-radius: 10px;
  margin-top: 10px;
`;

const ChartTitle = styled.div`
  font-size: 20px;
  cursor: pointer;
  border-bottom: 2px solid #F6F5F9;
  padding: 10px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
`;

const ChartsWrapper = styled.div`
  display: flex;
  gap: 18px;
  overflow: auto;
  padding: 10px;
`;

const SpinnerBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const EmbeddedChart = styled.iframe<{ isDailyAnalysis?: boolean }>`
  border: 0.8px solid lightgrey;
  @media only screen and (min-width: 1270px){
    width: ${({ isDailyAnalysis }) => isDailyAnalysis ? '700px' : '350px'};
  }
  @media only screen and (min-width: 1350px){
    width: ${({ isDailyAnalysis }) => isDailyAnalysis ? '800px' : '350px'};
  }
  @media only screen and (min-width: 1395px){
    width: ${({ isDailyAnalysis }) => isDailyAnalysis ? '800px' : '375px'};
  }
  @media only screen and (min-width: 1590px){
    width: ${({ isDailyAnalysis }) => isDailyAnalysis ? '880px' : '490px'};
  }
  @media only screen and (min-width: 1670px){
    width: ${({ isDailyAnalysis }) => isDailyAnalysis ? '940px' : '510px'};
  }
  @media only screen and (min-width: 1900px){
    width: ${({ isDailyAnalysis }) => isDailyAnalysis ? '1050px' : '568px'};
  }
  @media only screen and (min-width: 2100px){
    width: ${({ isDailyAnalysis }) => isDailyAnalysis ? '1300px' : '600px'};
  }
`;

const CustomCell = styled.div<{fontSize?: number}>`
  font-weight: 600;
  color: #6b6c6f;
  flex: 1;
  font-size: ${({fontSize}) => fontSize ? `${fontSize}px`: '16px'};
  text-align: center;
  &,
  & * {
    line-height: normal !important;
  }
`;

const InfoRowContainer = styled.div`
  display: flex;
  padding: 16px 0;
  border-radius: 8px;
  width: 100%;
  background-color: #cde1f5;
  >div{
    color: #6b6c6f;
  }
`;

interface IParams {
  instanceId: string;
}

interface IChartsInfo {
  datasource: string,
  description: string | null,
  permalink: string,
  linkStatus: string,
  sliceId: number,
  sliceName: string,
  url: string,
  vizType: string
}

interface IDashboardInfo {
  id: number,
  title: string,
  url: string,
  chartsInfo: IChartsInfo[]
}

interface IDashboard {
  data: IDashboardInfo[]
  id?: number
  chartsInfo?: IChartsInfo[]
}

const NewCameraDetailsPage = () => {
  const { instanceId }: IParams = useParams();
  const [streamDetails, setStreamDetails] = useState<IStream>();
  const { t } = useTranslation(['common']);
  const { data: getStreamData, loading: isStreamLoading } = useQuery(GET_STREAM, { variables: { streamId: instanceId } });
  const { updateHeaderTitle } = useHeader();
  const { streamStatus } = useStreamStatus(instanceId);
  const viewChartsAccess = TokenService.hasAccess(PERMISSION_CODE.view_charts);
  const { push } = useHistory();
  const history = useHistory();
  const { isOfflineMap } = useAnnotation();
  const [isMapModalOpen, setIsMapModalOpen] = useState<boolean>(false);
  const [dashboardCharts, setDashboardCharts] = useState<IDashboard | null>();
  const [getDetections, { loading: detectionLoading }] = useLazyQuery(GET_DETECTIONS_COUNT);
  const { data } = useSubscription(DETECTION_SUB, { variables: { payload: { streamId: instanceId }}, skip: (getStreamData?.getStream?.result?.algorithmList as IGetAllAlgorithmRes[])?.findIndex(algo => algo.algorithm_code === ALGORITHM_CODES.traffic_pulse_detection) === -1});
  const [tableData, setTableData] = useState<{ type: string, up_count: number, down_count: number }[]>([]);
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const [visualizationIconToggle, setVisualizationIconToggle] = useState(false);
  const [dateFilter, setDateFilter] = useState<{startDate: string, endDate: string}>({startDate: '', endDate: ''});
  const [supersetDataRefetchInterval, setSupersetDataRefetchInterval] = useState(localStorage.getItem('cameradetailsRefresh') as string || '15s');
  const visualizationIconRef = useRef<HTMLInputElement>(null);
  const [selectedToggle, setSelectedToggle] = useState<string>('map');
  const manageChartsAccess = TokenService.hasAccess(PERMISSION_CODE.manage_charts);
  const storeChartsAccess = TokenService.hasAccess(PERMISSION_CODE.store_charts);
  const [isVisible, setIsVisible] = useState(JSON.parse(localStorage.getItem('isShowCharts') as string).cameradetailspage === true || false);
  const { refetch: refetchFilteredSupersetCharts, loading: filteredChartsLoading } = useQuery(GET_FILTERED_CHARTS, {
    variables: {
      pageName: 'dashboardpage',
      username: JSON.parse(localStorage.getItem('user_login_info') as string).username,
    },
    skip: true,
  });

  const tableColumnConfigs: ColumnConfig[] = [
    {
      columnId: 'type',
      header: t('Type'),
      alignHeader: 'center',
      alignText: 'center',
      flex: 1,
      render: (value) => <CustomCell>{TRAFFIC_PULSE_CLASSES[value as keyof typeof TRAFFIC_PULSE_CLASSES] ?? value}</CustomCell>
    },
    {
      columnId: 'up_count',
      header: t('Up Count'),
      alignHeader: 'center',
      alignText: 'center',
      flex: 1,
      render: (value) => <CustomCell>{<Odometer value={value} />}</CustomCell>
    },
    {
      columnId: 'down_count',
      header: t('Down Count'),
      alignHeader: 'center',
      alignText: 'center',
      flex: 1,
      render: (value) => <CustomCell>{<Odometer value={value} />}</CustomCell>
    },
    {
      columnId: 'total_count',
      header: t('Total Count'),
      alignHeader: 'center',
      alignText: 'center',
      flex: 1,
      render: (value, row) => <CustomCell>{row.up_count + row.down_count}</CustomCell>
    },
  ];

  const getDetectionCount = useCallback(async () => {
    let startDate = dateFilter.startDate;
    let endDate = dateFilter.endDate;

    if(!(startDate && endDate) && !isFilterApplied){
      const currentDate = new Date();
      const istOffset = 9 * 60 * 60 * 1000;
      const istDate = new Date(currentDate.getTime() + istOffset);
      endDate = istDate.toISOString().slice(0, 19).replace('T', ' ');
      const startOfDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
      const istStartOfDay = new Date(startOfDay.getTime() + istOffset);
      startDate = istStartOfDay.toISOString().slice(0, 19).replace('T', ' ').slice(0, 11) + '00:00:00';
    }

    const response = await getDetections({ variables: { payload: { startDate: startDate, endDate: endDate, streamId: instanceId, algoCode: ALGORITHM_CODES.traffic_pulse_detection  }}});
    if (response && response.data?.getDetectionCount && response.data?.getDetectionCount?.result !== null) {
      const classifications = response.data?.getDetectionCount?.result?.data?.classification;
      const arrayData = Object.keys(classifications ?? []).map(type => ({
        type: type,
        up_count: classifications[type].upCount,
        down_count: classifications[type].downCount
      }));
      setTableData([...arrayData]);
    }

  }, [getDetections, instanceId, dateFilter, isFilterApplied]);

  const filteredDashboardCall = useCallback(async (payloadToSend: any) => {
    const { data: getDateFilteredCharts } = await refetchFilteredSupersetCharts({
      payload: payloadToSend
    });
    if (getDateFilteredCharts?.getFilteredCharts?.result?.length > 0) {
      setDashboardCharts({data: getDateFilteredCharts?.getFilteredCharts?.result});
    }
  }, [refetchFilteredSupersetCharts]);

  useEffect(() => {
    getDetectionCount();
  }, [getDetectionCount]);

  const useOutSideClick = (ref: RefObject<HTMLDivElement>) => {
    useEffect(() => {
      function handleOutsideClick(event:MouseEvent) {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          setVisualizationIconToggle(false);
        }
      }
      document.addEventListener('click', handleOutsideClick);
      return () => {
        document.removeEventListener('click', handleOutsideClick);
      };
    }, [ref]);
  };

  useOutSideClick(visualizationIconRef);

  useEffect(() => {
    if(isVisible) {
      if(JSON.parse(localStorage.getItem('cameradetails') as string)) {
        const checkedPayload = {
          pageName: 'cameradetailspage',
          username: JSON.parse(localStorage.getItem('user_login_info') as string).username,
          streamIds: instanceId
        };
        filteredDashboardCall(checkedPayload);
        localStorage.removeItem('cameradetails');
      }
    }
  }, [isVisible, filteredDashboardCall, instanceId]);

  useEffect(() => {
    localStorage.setItem('cameradetailsRefresh', supersetDataRefetchInterval);
  }, [supersetDataRefetchInterval]);

  useEffect(() => {
    const prevData = JSON.parse(localStorage.getItem('isShowCharts') as string);
    localStorage.setItem('isShowCharts', (JSON.stringify({...prevData, cameradetailspage: isVisible})));
  }, [isVisible]);

  useEffect(() => {
    if(isVisible) {
      const interval = setInterval(() => {
        const checkedPayload = {
          pageName: 'cameradetailspage',
          username: JSON.parse(localStorage.getItem('user_login_info') as string).username,
          streamIds: instanceId
        };
        filteredDashboardCall(checkedPayload);
      }, (Number(Number(supersetDataRefetchInterval.substring(0, supersetDataRefetchInterval.indexOf('s')))) * 1000));
  
      return () => {
        clearInterval(interval);
      };
    }
  }, [isVisible, filteredDashboardCall, instanceId, supersetDataRefetchInterval]);

  useEffect(() => {
    if (!detectionLoading && data?.getDetectionsSub?.result && !isFilterApplied && Config.isSubscriptionEnabled && data.getDetectionsSub.result.algoCode === ALGORITHM_CODES.traffic_pulse_detection) {
      const objectName = data.getDetectionsSub.result.objectName;
      const detectionData = JSON.parse(data.getDetectionsSub.result.detectionData);
      const isUp = detectionData.direction_kind === 'forward';

      setTableData(prevTableData => {
        const existingEntry = prevTableData.find(entry => entry.type === objectName);

        if (existingEntry) {
          return prevTableData.map(entry =>
            entry.type === objectName
              ? {
                ...entry,
                up_count: isUp ? entry.up_count + 1 : entry.up_count,
                down_count: !isUp ? entry.down_count + 1 : entry.down_count,
              }
              : entry
          );
        } else {
          return [
            ...prevTableData,
            {
              type: objectName,
              up_count: isUp ? 1 : 0,
              down_count: isUp ? 0 : 1,
            },
          ];
        }
      });
    }
  }, [data, detectionLoading, isFilterApplied]);

  useEffect(() => {
    const cameraPageBreadCrumb: IBreadcrum[] = [
      { label: t('Cameras'), to: '/cameras' }
    ];
    const camInfo: ICameraInfo = {
      instanceId: streamDetails?.instanceId || '',
      streamType: streamDetails?.streamType || '',
    };
    updateHeaderTitle(streamDetails?.name, streamDetails?.streamType === 'Folder' ? <FaFolder /> :'Camera', cameraPageBreadCrumb, getStatusColor(streamStatus) as string, camInfo);
  }, [updateHeaderTitle, streamDetails, streamStatus, t]);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('cameraspage') as string)) {
      const checkedPayload = {
        pageName: 'cameradetailspage',
        username: JSON.parse(localStorage.getItem('user_login_info') as string).username,
        streamIds: instanceId
      };
      filteredDashboardCall(checkedPayload);
      localStorage.removeItem('cameraspage');
    }
  }, [filteredDashboardCall, instanceId]);

  useEffect(() => {
    if(isVisible) {
      const checkedPayload = {
        pageName: 'cameradetailspage',
        username: JSON.parse(localStorage.getItem('user_login_info') as string).username,
        streamIds: instanceId
      };
      filteredDashboardCall(checkedPayload);
    }
  }, [filteredDashboardCall, instanceId, isVisible]);

  useEffect(() => {
    const result: IStream = getStreamData?.getStream?.result !== null && getStreamData?.getStream?.result !== undefined ? getStreamData?.getStream?.result : null;
    if (result !== null) {
      setStreamDetails(result);
    }
  }, [getStreamData]);

  const handleThumbnailError = useCallback((e: React.SyntheticEvent<HTMLElement>) => {
    (e.target as HTMLSourceElement).src = AwaitingStreamEng;
  }, []);

  const CustomIconContent = () => (
    <CustomMarkerWrapper>
      <BlinkDiv isBlinking={streamDetails?.alertStatus?.hasActiveAlert ?? false} />
      <CameraIcon src={MapMarker} alt="Icon" style={{ backgroundColor: 'transparent' }} />
      <LocationDiv>{streamDetails?.location?.locationName}</LocationDiv>
    </CustomMarkerWrapper>
  );

  const customIcon = l.divIcon({
    html: renderToStaticMarkup(<CustomIconContent />),
    iconSize: [50, 50],
  });

  const getChartLoadingSkeleton = (margin: boolean) => {
    const loadingComponents = Array.from({ length: 3 }, (_, index) => (
      <Skeleton key={index} type='chartCard' />
    ));
    return (
      <Skeleton type={`chartsLoadingWrapper ${margin ? 'margin' : ''}`}>
        {loadingComponents}
      </Skeleton>
    );
  };

  const toggleVisualizationOptions = () => {
    setVisualizationIconToggle(!visualizationIconToggle);
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
    setVisualizationIconToggle(false);
  };

  const handleRefreshInterval = (value: string) => {
    setSupersetDataRefetchInterval(value);
  };

  const getLoading = () => {
    return (
      <Skeleton type='cameraDetailsPage'>
        {getChartLoadingSkeleton(false)}
        <Skeleton type='cameraDetailsContent'>
          <Skeleton type='cameraLineViewer' />
          <Skeleton type='tableLoader' />
        </Skeleton>
      </Skeleton>
    );
  };

  const DetectionTab = Config.menulist.filter((item) => item.label === 'Detections');

  const SystemConfigTabs: ITabs[] = [];


  if(streamDetails?.streamType === 'Folder') {
    SystemConfigTabs.push({
      id: 'general',
      name: t('Overview'),
      content: <OverViewTab streamDetails={streamDetails} status={streamStatus} />,
    });
    SystemConfigTabs.push({
      id: 'files',
      name: t('Files'),
      content: <SourceFiles />,
    });
    SystemConfigTabs.push({
      id: 'jobs',
      name: t('Jobs'),
      content: <Jobs />,
    });
  
  }

  SystemConfigTabs.push({
    id: 'alertsTab',
    name: t('Alerts'),
    content: <Alerts isCameraPageAlerts={false} isSearchQuery={false} showSingleAlertDetailsPanel streamId={streamDetails?.instanceId} streamName={streamDetails?.name} />,
  });

  if (DetectionTab[0].isVisible) {
    SystemConfigTabs.push({
      id: 'detectionTab',
      name: t('Detections'),
      content: <Detections isDetectionTab={true} />,
    });
  }

  const handleDateRangeCallback = useCallback((startDate: string | null, endDate: string | null) => {
    if(startDate && endDate){
      setIsFilterApplied(true);
      const startDateJP = convertToJST(startDate);
      const endDateJP = convertToJST(endDate);
      setDateFilter({startDate: startDateJP, endDate: endDateJP});
    }else{
      setIsFilterApplied(false);
      setDateFilter({startDate: '', endDate: ''});
    }
  }, []);

  const infoRow = () => {
    const totalUpCount = tableData.reduce((sum, row) => sum + row.up_count, 0);
    const totalDownCount = tableData.reduce((sum, row) => sum + row.down_count, 0);
    const totalCount = totalUpCount + totalDownCount;
  
    return (
      <InfoRowContainer>
        <CustomCell fontSize={18}>{t('Total')}</CustomCell>
        <CustomCell fontSize={18}><Odometer value={totalUpCount} /></CustomCell>
        <CustomCell fontSize={18}><Odometer value={totalDownCount} /></CustomCell>
        <CustomCell fontSize={18}><Odometer value={totalCount} /></CustomCell>
      </InfoRowContainer>
    );
  };

  return (
    <CameraDetailsPageContainer>
      <VisualizationWrapper ref={visualizationIconRef}>
        <VisualizationToggleWrapper isAlertIconPresent={Config.fetchLatestAlertAPIEnable} onClick={toggleVisualizationOptions}>     
          <AiOutlineBarChart size={24}/>
          <AllChartOptionsContainer>
            <AllChartOptionsWrapper visualizationIconToggle={visualizationIconToggle}>
              <Arrow /> 
              <ChartOptionsWrapper onClick={toggleVisibility}>
                <ChartOption>{isVisible ? t('Hide Charts'):  t('Show Charts')}</ChartOption>
                <VisualizationIconWrapper>
                  {
                    isVisible ? <MdOutlineHideImage size={24} /> : <AiOutlineBarChart size={24}/>
                  }
                </VisualizationIconWrapper>
              </ChartOptionsWrapper>
              { storeChartsAccess && <ChartOptionsWrapper onClick={() => history.push(`/cameradetailspage/${instanceId}/selectcharts`)}>            
                <ChartOption>{t('Import Charts')}</ChartOption>
                <Icon icon='Download' />
              </ChartOptionsWrapper>}
              { manageChartsAccess && <ChartOptionsWrapper onClick={() => window.open(dashboardCharts?.data[0]?.url, '_blank')}>
                <ChartOption>{t('Manage Charts')}</ChartOption>
                <Icon icon='Settings' />
              </ChartOptionsWrapper>}
              <ChartOptionsWrapper>
                <PaginationWrapper>
                  <RefreshLabel>{t('Refesh Interval')}</RefreshLabel>
                  <SelectOptionField
                    onChange={(e) => handleRefreshInterval(e.target.value)}
                    defaultValue={supersetDataRefetchInterval}
                    value={supersetDataRefetchInterval}
                  >
                    {
                      SUPERSET_REFRESH_INTERVAL.map((size: string, index: number) => <option key={index} value={size}>{size}</option>)
                    }
                  </SelectOptionField>
                </PaginationWrapper>  
              </ChartOptionsWrapper>
            </AllChartOptionsWrapper>
          </AllChartOptionsContainer>
        </VisualizationToggleWrapper>
      </VisualizationWrapper>
      <VisualizationContainer isVisible={isVisible}>
        {
          (viewChartsAccess && dashboardCharts) ? (dashboardCharts?.data[0]?.chartsInfo?.length > 0 && !filteredChartsLoading) ? <ChartsWrapper>
            {dashboardCharts.data.map((dashboardData) => (
              dashboardData.chartsInfo.map((chart) => (
                <SingleChartWrapper key={chart.sliceId}>
                  <ChartTitle>{chart.sliceName}</ChartTitle>
                  <EmbeddedChart isDailyAnalysis={chart.sliceId === 5} title={chart.sliceName} src={SUPERSET_URL + chart?.permalink?.split(':9097')[1]} height={250} />
                </SingleChartWrapper>
              ))
            ))}
          </ChartsWrapper>:      
            filteredChartsLoading && viewChartsAccess && <SpinnerBox>
              <CircularProgress />
              <Label>{t('Loading...')}</Label>
            </SpinnerBox> : <NoChartsMessage>{t('Oops! It looks like you have not selected any graphs yet. Please choose the graphs you would like to have on camera details page.')}</NoChartsMessage> 
        }
        {
          viewChartsAccess && dashboardCharts === null && <NoChartsMessage>{t('Oops! It looks like you have not selected any graphs yet. Please choose the graphs you would like to have on camera details page.')}</NoChartsMessage>
        }
        {
          !viewChartsAccess && <NoChartsMessage>{t('Unfortunately, you do not have permission to view these charts at this time. Please contact your administrator for assistance.')}</NoChartsMessage>
        }
      </VisualizationContainer>
      {Config.cameraDetailsPageConfigs.layout === 'traffic_pulse' &&
        <CameraDetailsHeader>
          <FiltersSection>
            <Calendar onDateRangeCallback={handleDateRangeCallback} defaultFromDatePlaceholder={t('Today (Midnight)')} defaultToDatePlaceholder={t('Today (Now)')} />
          </FiltersSection>
          <ButtonsWrapper>
            {streamDetails?.streamType === 'FOLDER' && 
              <ConfigurationButton onClick={() => push(`/cameras/${instanceId}/folder`)}>
                <Label>{t('Upload')}</Label>
                <Icon icon='Upload' color='inverse' />
              </ConfigurationButton>}
            <ConfigurationButton onClick={() => push(`/cameras/${instanceId}/configurations?selectedTab=basicConfig&camera-name=${instanceId}&camera-type=${streamDetails?.streamType}`)}>
              <Label>{t('Configurations')}</Label>
              <Icon icon='Setting' />
            </ConfigurationButton>
            <ConfigurationButton onClick={() => push(`/cameras/archives/videos?streamId=${instanceId}`)}>
              <Label>{t('Videos')}</Label>
              <Icon icon='Video' color='inverse' />
            </ConfigurationButton>
          </ButtonsWrapper>
        </CameraDetailsHeader>
      }
      {isStreamLoading ? getLoading() :
        <CameraDetailsPageWrapper>
          {Config.cameraDetailsPageConfigs.layout !== 'default' && <AllVisualizationContainer>
            {
              viewChartsAccess && dashboardCharts ? dashboardCharts?.data[0]?.chartsInfo?.length > 0 ? <ChartsWrapper>
                {dashboardCharts?.data?.map((dashboardData) => (
                  dashboardData?.chartsInfo?.map((chart) => (
                    <SingleChartWrapper key={chart.sliceId}>
                      <ChartTitle>
                        <Title>{chart.sliceName}</Title>
                      </ChartTitle>
                      <EmbeddedChart isDailyAnalysis={chart.sliceId === 5} title={chart.sliceName} src={SUPERSET_URL + chart?.permalink?.split(':9097')[1]} height={250} />
                    </SingleChartWrapper>
                  ))
                ))}
              </ChartsWrapper> : (!filteredChartsLoading && <NoChartsMessage>{t('Oops! It looks like you have not selected any graphs yet. Please choose the graphs you would like to have on camera details page.')}</NoChartsMessage>) :
                filteredChartsLoading && viewChartsAccess &&
                <>
                  {getChartLoadingSkeleton(true)}
                </>
            }
            {
              !viewChartsAccess && <NoChartsMessage>{t('Unfortunately, you do not have permission to view these charts at this time. Please contact your administrator for assistance.')}</NoChartsMessage>
            }
          </AllVisualizationContainer>}
          <CameraTopDiv>
            <LineViewerWrapper>
              <LineViewer showAlgorithmIcons liveVideo={true} streamName={streamDetails?.name} streamID={streamDetails?.instanceId || instanceId as string} minHeight='inherit' state={[]} width='100%' height='100%' hasFullScreenToggle />
            </LineViewerWrapper>
            <RightSection>
              {<VideoSnapshotToggle>
                <Span isSelected={selectedToggle === 'map'} onClick={() => setSelectedToggle('map')}>{t('Map')}</Span>
                <Span isSelected={selectedToggle === 'pulse'} onClick={() => setSelectedToggle('pulse')}>{t('Traffic Pulse')}</Span>
              </VideoSnapshotToggle>}
              <MapCountWrapper>
                {
                  selectedToggle === 'pulse'  ? 
                    <>
                      {
                        streamDetails?.algorithmList && streamDetails?.algorithmList.findIndex(algo => algo.algorithm_code === ALGORITHM_CODES.traffic_pulse_detection) !== -1 ?
                          <DataTableWrapper>
                            <FiltersSection>
                              <Calendar onDateRangeCallback={handleDateRangeCallback} defaultFromDatePlaceholder='Today (Midnight)' defaultToDatePlaceholder='Today (Now)' />
                            </FiltersSection>
                            <DataContainer>
                              {detectionLoading ?
                                <LoaderContainer>
                                  <Loader type='threedots' />
                                </LoaderContainer>
                                :
                                <Table noDataText={t('Vehicle details not found')} columns={tableColumnConfigs} data={tableData} infoRow={infoRow}/>
                              }
                            </DataContainer>
                          </DataTableWrapper> :
                          <CameraMapView isMapViewConfigured>
                            <div>{t('Traffic Pulse Analysis is not configured on this stream')}</div>
                            <CustomButton onClick={() => push(`/cameras/${instanceId}/configurations?selectedTab=analysis&camera-name=${instanceId}&camera-type=${streamDetails?.streamType}&selectedAlgorithm=TRAFFIC_PULSE_DETECTION`)}>{t('Configure Now')}</CustomButton>
                          </CameraMapView>
                      }
                    </> 
                    : 
                    <>
                      {Config.isZoneMap ?
                        <ZoneMapWrapper>
                          <ActionIcon onClick={() => setIsMapModalOpen(true)} isZoneMap={Config.isZoneMap}>
                            <Icon icon='Expand' color='mono' size={24} />
                          </ActionIcon>
                          <ZoneMap defaultZoom={0.9} height='100%' width='100%' instanceId={instanceId} />
                        </ZoneMapWrapper> :
                        <CameraMapView isMapViewConfigured={streamDetails?.location !== null && !!streamDetails?.location.enabledLocation}>
                          {!isStreamLoading && streamDetails !== undefined && <MapContainer center={streamDetails?.location !== null ? [Number(streamDetails?.location?.latitude), Number(streamDetails?.location?.longitude)] : MAP_CENTER as LatLngExpression} zoom={14} style={{ height: '100%', width: '100%' }}>
                            <TileLayer url={isOfflineMap ? LEAFLET_TILE_URL : LEAFLET_ONLINE_URL} maxZoom={isOfflineMap ? 15 : 20} minZoom={isOfflineMap ? 12 : 3} />
                            <Marker
                              position={streamDetails?.location !== null ? [Number(streamDetails?.location?.latitude), Number(streamDetails?.location?.longitude)] as LatLngExpression : MAP_CENTER as LatLngExpression}
                              icon={customIcon}
                            >
                              <CustomToolTip direction='top'>
                                <Label>{streamDetails?.instanceId}</Label>
                                <ToolTipImg onError={handleThumbnailError} src={SNAPSHOT_URL.replace('{X}', (streamDetails?.instanceId) as string).replace('{Y}', new Date().getTime().toString())} />
                              </CustomToolTip>
                            </Marker>
                          </MapContainer>}
                        </CameraMapView>}
                    </>
                }
              </MapCountWrapper>
            </RightSection>
          </CameraTopDiv>
          {!(Config.cameraDetailsPageConfigs.layout === 'traffic_pulse') && <TabsContainer>
            <ButtonsWrapper position={'absolute'}>
              <ConfigurationButton onClick={() => push(`/cameras/archives/videos?streamId=${instanceId}`)}>
                <Label>{t('Videos')}</Label>
                <Icon icon='Video' color='inverse' />
              </ConfigurationButton>
              <ConfigurationButton onClick={() => push(`/cameras/${instanceId}/configurations?selectedTab=basicConfig&camera-name=${instanceId}&camera-type=${streamDetails?.streamType}`)}>
                <Label>{t('Configurations')}</Label>
                <Icon icon='Setting' />
              </ConfigurationButton>
            </ButtonsWrapper>
            <Tabs tabs={SystemConfigTabs} activeMode='box' />
          </TabsContainer>}
          {isMapModalOpen &&
            <Modal isModalOpen={isMapModalOpen} closeModal={() => setIsMapModalOpen(false)} width='100vw'>
              <ModalMapViewContainer>
                <ZoneMap defaultZoom={0.9} height='500px' width='1170px' instanceId={instanceId} />
              </ModalMapViewContainer>
            </Modal>
          }
        </CameraDetailsPageWrapper>
      }
    </CameraDetailsPageContainer>
  );
};

export default NewCameraDetailsPage;