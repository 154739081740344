import React, { ReactNode } from 'react';
import styled, { keyframes } from 'styled-components';

const placeholderShimmer = keyframes`
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
`;

const Container = styled.div`
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 100%;
  display: inline-block;
  position: relative;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${placeholderShimmer};
  animation-timing-function: linear;

  &.cardContainer{
    width: 100%;
    border-radius: 8px;
    background: none;
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media only screen and (min-width: 1366px){
      height: 180px;
    }
    @media only screen and (min-width: 1920px){
      height: 250px;
    }
  }

  &.rectBox {
    width: 324px;
    height: 100px;
    border-radius: 20px;
  }

  &.card{
    width: 100%;
    height: calc(100% - 48px);
    border-radius: 8px;
  }
  &.text{
    width: 156px;
    height: 26px;
    border-radius: 10px;
  }
  &.icondiv{
    width: 28px;
    height: 28px;
    border-radius: 50%;
  }
  &.mapCamerasPage{
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    height: calc(100vh - 160px);
    width: 350px;
    @media only screen and (min-width: 1366px){
      width: 468px;
    }

    @media only screen and (min-width: 1440px){
      width: 500px;
    }

    @media only screen and (min-width: 1600px){
      width: 550px;
    }

    @media only screen and (min-width: 1680px){
      width: 600px;
    }

    @media only screen and (min-width: 1920px){
      width: 785px;
    }

    @media only screen and (min-width: 2560px){
      width: 1200px;
    }

    @media only screen and (min-width: 3840px){
      width: 1800px;
    }
  }
  &.chartsLoadingWrapper {
    display: flex;
    gap: 18px;
    background: none;
    margin-top: 10px;
  }
  &.margin{
    margin-left: 10px;
  }
  &.chartCard {
    width: 560px;
    height: 310px;
    border-radius: 8px;
    @media only screen and (min-width: 1350px){
      width: 380px;
    }
    @media only screen and (min-width: 1920px){
      width: 560px;
    }
  }
  &.cameraDetailsPage{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    background: none;
  }
  &.cameraDetailsContent{
    display: flex;
    gap: 20px;
    width: 100%;
    background: none;
  }
  &.cameraLineViewer{
    border-radius: 8px;
    flex: 1;
    @media only screen and (min-width: 1366px) {
      width: 598px;
    }
    @media only screen and (min-width: 1920px){
      width: 730px;
      aspect-ratio: 16 / 9;
    }
  }
  &.tableLoader {
    border-radius: 8px;
    flex: 1;
    height: 100%;
    aspect-ratio: 16 / 9;
  }
`;

interface ISkeleton {
  type:string,
  children?: ReactNode | ReactNode[]
}

const Skeleton: React.FC<ISkeleton> = ({type, children}) => {
  const classes = `skeleton ${type}`;
  return (
    <Container className={classes}>{children}</Container>
  );
};

export default Skeleton;