import { CircularProgress } from '@mui/material';
import { ALERT_IMG_BASE_URL, AlertMessageType, VIDEO_ARCHIVES_ALERT_TABLE_COLUMN_IDS } from '../constants';
import React, { forwardRef, useCallback, useImperativeHandle, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Thumbnail from './Thumbnail';
import AwaitingStreamEng from '../svgs/Awaiting_Stream-ENG.svg';
import { useAlertList } from 'contexts/AlertListSnapshotContext';

const AlertsContainer = styled.div`
  border: 1px solid ${({theme}) => theme.divider};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;

const NotFoundDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({theme}) => theme.divider};
  border-radius: 10px;
  font-size: 20px;
  font-weight: 600;
  aspect-ratio: 16 / 7;
`;

const AlertsHeader = styled.div`
  width: 100%;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  top: 0;
`;

const AlertsHeaderData = styled.div`
  padding: 10px;
  background-color: #D0D7F2;
  display: flex;
  align-items: center;
`;

const HeaderCell = styled.div<{flex?: number, textAlign?: string}>`
  flex: ${({flex}) => flex ? flex : 1};
  text-align: ${({textAlign}) => textAlign ? textAlign : 'left'};
`;

const TableRow = styled.div`
  display: flex;
  min-height: 70px;
  align-items: center; 
  border-bottom: 1px solid ${({theme}) => theme.divider};
`;

const TableCell = styled.div<{flex?: number, textAlign?: string}>`
  flex: ${({flex}) => flex ? flex : 1};
  text-align: ${({textAlign}) => textAlign ? textAlign : 'left'};
`;

const MetaWrapper = styled.div<{isCameraAlerts: boolean}>`
  display: flex;
  gap: ${({isCameraAlerts}) => isCameraAlerts ? '10px' : '40px'};
`;

const ThumbnailWrapper = styled.div``;

const AlertInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

const AlertInfoLabel = styled.div<{isCameraAlert:boolean}>`
  font-weight: 400;
  font-size: ${({isCameraAlert}) => isCameraAlert ? '12px' : '14px'};
`;

const AlertInfoText = styled.div<{isCameraAlert:boolean}>`
  font-weight: 500;
  font-size: ${({isCameraAlert}) => isCameraAlert ? '14px' : '16px'};
  color: ${({theme}) => theme.text.primaryText};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: ${({isCameraAlert}) => isCameraAlert ? '142px': '192px' };
`;

const LoadingWrapper = styled.div`
  text-align: center;
`;

interface IAlertData {
  id?: number;
  displayName?: string;
  alertData?: any;
  message?: string;
  lastUpdate?: string;
  imagePath?: string;
  instanceId?: string;
  status?: number;
  streamId?: string;
  eventType?: string;
}

const AlertList = forwardRef((_,ref) => {
  const {t} = useTranslation(['common']);
  const {hasMore, applyCallback, alertData, fetchMoreData} = useAlertList();

  useImperativeHandle(
    ref,
    () => ({
      applyCallback
    }),
  );

  const alertColumnConfig = useMemo(() => [
    {
      id: VIDEO_ARCHIVES_ALERT_TABLE_COLUMN_IDS.THUMBNAIL,
      displayName: '',
      flex: 0.2,
      textAlign: 'center',
    },
    {
      id: VIDEO_ARCHIVES_ALERT_TABLE_COLUMN_IDS.MESSAGE,
      displayName: t('Message'),
      flex: 1,
      textAlign: 'center',
    },
    {
      id: VIDEO_ARCHIVES_ALERT_TABLE_COLUMN_IDS.METADATA,
      displayName: t('Meta Data'),
      flex: 1,
      textAlign: 'center',
    },
    {
      id: VIDEO_ARCHIVES_ALERT_TABLE_COLUMN_IDS.TIMESTAMP,
      displayName: t('Reported At'),
      flex: 1,
      textAlign: 'center',
    },
  ],[t]);

  const renderCellContent = useCallback((id: string, row: IAlertData) => {
    let message;
    let formattedItems;
    switch (id) {
    case VIDEO_ARCHIVES_ALERT_TABLE_COLUMN_IDS.THUMBNAIL:
      return (
        <ThumbnailWrapper>
          <Thumbnail src={ row?.imagePath ? ALERT_IMG_BASE_URL + row?.imagePath : AwaitingStreamEng} size={40} />
        </ThumbnailWrapper>
      );
    
    case VIDEO_ARCHIVES_ALERT_TABLE_COLUMN_IDS.MESSAGE:
      return AlertMessageType[row.eventType as string]?.message;
    
    case VIDEO_ARCHIVES_ALERT_TABLE_COLUMN_IDS.METADATA: 
      message = row.alertData;
      formattedItems = Object.entries(message ?? {}).map(([key, value]) => {
        const formattedKey = key.split('_').map((word, index) => (index === 0 ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : word.toLowerCase())).join(' ');
        return { key: formattedKey, value };
      });

      return (
        <MetaWrapper isCameraAlerts={true}>
          {formattedItems?.map((item) => (
            <AlertInfoWrapper>
              <AlertInfoLabel isCameraAlert={true}>{item.key}</AlertInfoLabel>
              <AlertInfoText isCameraAlert={true}>{item.value} {AlertMessageType[row.eventType as string]?.unit}</AlertInfoText>
            </AlertInfoWrapper>
          ))}
        </MetaWrapper>
      );
    
    default:
      return row[id as keyof IAlertData];
    }
  },[]);

  return (
    <>
      {
        alertData.length > 0 ? 
          <AlertsContainer>
            <AlertsHeader>
              <AlertsHeaderData>
                {alertColumnConfig.length > 0 &&
                  <>
                    {
                      alertColumnConfig.map((column) => {
                        return (
                          <HeaderCell flex={column.flex} textAlign={column.textAlign}>
                            {column.displayName}
                          </HeaderCell>);
                      })

                    }
                  </>
                }
              </AlertsHeaderData>
            </AlertsHeader>
            <InfiniteScroll
              dataLength={alertData?.length || 0}
              next={fetchMoreData}
              hasMore={hasMore}
              height={370} // TO_DO having problems when given 100% height. 
              loader={<LoadingWrapper><CircularProgress size={30} /></LoadingWrapper>}
            >
              {
                alertData?.map((alert, index) => {
                  return (
                    <TableRow key={index}>
                      {alertColumnConfig.map((column, index) => (
                        <TableCell flex={column.flex} textAlign={column.textAlign} key={index}>
                          {renderCellContent(column.id, alert)}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })
              }

            </InfiniteScroll>
          </AlertsContainer>:
          <NotFoundDiv>{t('Alerts not found')}</NotFoundDiv>
      }
    </>
  );
});

export default AlertList;