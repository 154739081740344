import { format } from 'date-fns';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { DATE_FORMAT_WITH_DASH } from '.././constants';

const Container = styled.div`
  width: 300px;
  z-index: 6;
  border-radius: 4px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
  overflow: hidden;
  background: #FFFF;
  border: 1px solid lightgrey;
  opacity: 100%;
  display: flex;
  justify-content: space-between;
`;

const HoursBox = styled.div`
  padding: 0.5em;
  margin-right: 10px;
`;

const MinutesBox = styled.div`
  padding: 0.5em 0.5em 0.5em 0.5em;
`;

const ButtonBox = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

const HoursMinutesText = styled.div`
  margin-bottom: 4px;
  text-transform: capitalize;
`;

const HoursSection = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.5em;
`;

const HourMinutesNumber = styled.div<{active: boolean, disabled?: boolean}>`
  width: 40px;
  height: 30px;
  border: 1px solid #ccc;
  padding: 0.4em;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  ${({ active }) => active && css`
    border: solid 1px hsl(205,100%,72%);
    background-color: hsl(205,100%,72%);
    color: hsla(0,0%,100%,1.000);
  `}

  ${({ disabled }) => disabled && css`	
    background-color: #CCC;
    cursor: default;
  `}
`;

const MinutesSection = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.5em;
`;

interface IProps{
  time: Date;
  onSave: (hourMinute: Date) => void;
  showTime?: (time: string) => void;
  hourIncrementBy:number
}

const TimePicker: FC<IProps> = ({ time, onSave, hourIncrementBy }) => {
  const [ hourMinute, setHourMinute ] = useState<Date>(new Date(time));
  const {t} = useTranslation(['common']);

  const onChangeHour = useCallback((hourData: number) => {
    const updatedHoursMinute = new Date(hourMinute.setHours(hourData));
    setHourMinute(updatedHoursMinute);
    onSave(updatedHoursMinute);
  }, [onSave, hourMinute]);

  const onChangeMinute = useCallback((minuteData: number) => {
    const updatedHoursMinute = new Date(hourMinute.setMinutes(minuteData));
    setHourMinute(updatedHoursMinute);
    onSave(updatedHoursMinute);
  }, [onSave, hourMinute]);

  const generateHoursMinutes = useCallback(() => {
    const currentDate : Date = new Date();
    let currentTime;
    const timeDiv = [];
    const oldData = format(new Date(hourMinute), DATE_FORMAT_WITH_DASH);
    const currentData = format(new Date(), DATE_FORMAT_WITH_DASH);

    if(currentData === oldData) {
      currentTime = currentDate.getHours() + hourIncrementBy;
    } else {
      currentTime  = (new Date(time)) < (new Date()) ? 24 : 24;  
    }

    for (let i = 0; i < 24; i++){
      if(i<currentTime){
        timeDiv.push(<HourMinutesNumber key={i} active={hourMinute.getHours() === i} onClick={() => onChangeHour(i)}>{('0' + i).slice(-2)}</HourMinutesNumber>);	
      } else{	
        timeDiv.push(<HourMinutesNumber key={i} active={hourMinute.getHours() === i} onClick={() => onChangeHour(i)}>{('0' + i).slice(-2)}</HourMinutesNumber>);	
      }
    }
    return timeDiv;
  }, [ hourMinute, onChangeHour, time, hourIncrementBy ]);

  const generateMinutes = useCallback(() => {
    const timeDiv = [];
    for (let i = 0; i < 60; i+=5){
      timeDiv.push(<HourMinutesNumber key={'mnt' + i} active={hourMinute.getMinutes() === i} onClick={() => onChangeMinute(i)}>{('0' + i).slice(-2)}</HourMinutesNumber>);	
    }
    return timeDiv;
  }, [hourMinute, onChangeMinute]);

  return (
    <Container>
      <HoursBox>
        <HoursMinutesText>{t('Hour')}</HoursMinutesText>
        {hourMinute &&
          <HoursSection>
            {generateHoursMinutes()}
          </HoursSection>}
      </HoursBox>
      <MinutesBox>
        <HoursMinutesText>{t('Minute')}</HoursMinutesText>
        {hourMinute &&
          <MinutesSection>
            {generateMinutes()}
          </MinutesSection>}
      </MinutesBox>
      <ButtonBox />
    </Container>
  );
};

export default TimePicker;