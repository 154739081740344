import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgCamera = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M4.424 6H21.29a.6.6 0 0 1 .375 1.069L18.599 9.52a.6.6 0 0 1-.375.132h-13.8a.6.6 0 0 1-.6-.6V6.6a.6.6 0 0 1 .6-.6Z"
        stroke={props.color}
        strokeLinecap="square"
        vectorEffect={"non-scaling-stroke"}
      />
      <path
        d="M3.824 8.739v2.652a1 1 0 0 0 1 1h14.437a1 1 0 0 0 1-1V8.282M22.585 9.24v2.653"
        stroke={props.color}
        strokeLinecap="round"
        vectorEffect={"non-scaling-stroke"}
      />
      <path
        d="M12.044 12.392a2.74 2.74 0 0 1-5.479 0"
        stroke={props.color}
        vectorEffect={"non-scaling-stroke"}
      />
      <path
        d="M9.309 15.588v1.283a1 1 0 0 1-1 1H2.917M2.5 15.632v4.479"
        stroke={props.color}
        strokeLinecap="round"
        vectorEffect={"non-scaling-stroke"}
      />
    </svg>
  );
};
export default SvgCamera;
