import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgHome = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="m2.906 12.363 9.6-9.45 9.6 9.45"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={"non-scaling-stroke"}
      />
      <path
        d="M5.044 10.263v7.35c0 .557.224 1.09.625 1.485.4.393.942.615 1.508.615h10.667a2.15 2.15 0 0 0 1.508-.615c.4-.394.625-.928.625-1.485v-7.35"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={"non-scaling-stroke"}
      />
    </svg>
  );
};
export default SvgHome;
