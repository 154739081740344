import Modal from 'components/Modal';
import { ALERT_IMG_BASE_URL, ALERT_STATUS_INDICATOR, AlertMessageType } from '../../constants';
import { IAlertData } from 'interface';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import AwaitingStreamEng from '../../svgs/Awaiting_Stream-ENG.svg';
import { getOnlyDate } from 'utils';
import { useTranslation } from 'react-i18next';

const AlertDetailsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const AlertImage = styled.img`
  object-fit: contain;
`;

const AlertDetails = styled.div`
  display: flex;
  gap: 30px;
`;

const Text = styled.span`
  color: ${({theme}) => theme.text.secondaryText};
  font-size: 14px;
`;

const Title = styled.span<{color?: string}>`
  color: ${({theme, color}) => color ? color : theme.text.primaryText};
`;

const AlertData = styled.div`
  display: flex;
  flex-direction: column;
`;

interface IAlertThumbnailModalProps{
  modalOpen: boolean;
  setModalOpen: (modalOpen: boolean) => void;
  alertData: IAlertData;
}

const AlertThumbnailModal: React.FC<IAlertThumbnailModalProps> = ({modalOpen, setModalOpen, alertData}) => {
  const {t} = useTranslation(['common']);

  const handleThumbnailError = useCallback((e:React.SyntheticEvent<HTMLElement>)=>{
    (e.target as HTMLSourceElement).src = AwaitingStreamEng;
  },[]);

  const getAlertMetaData = useCallback((alertData: Record<string, string | number>, unit: string) => {
    const formattedItems = Object.entries(alertData ?? {}).map(([key, value]) => {
      const formattedKey = key.split('_').map((word, index) => (index === 0 ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : word.toLowerCase())).join(' ');
      return { key: formattedKey, value };
    });
    return (
      <>
        {formattedItems?.map((item) => (
          <AlertData>
            <Text>{item.key}</Text>
            <Title>{item.value} {unit}</Title>
          </AlertData>
        ))}
      </>
    );
  },[]);

  return (
    <Modal isModalOpen={modalOpen} closeModal={setModalOpen} width='60vw'>
      <AlertDetailsContainer>
        <AlertImage src={ALERT_IMG_BASE_URL + alertData?.imagePath} onError={handleThumbnailError}/>
        <AlertDetails>
          <AlertData>
            <Text>{t('Camera')}</Text>
            <Title>{alertData?.displayName ? alertData?.displayName : '-'}</Title>
          </AlertData>
          <AlertData>
            <Text>{t('Message')}</Text>
            <Title>{AlertMessageType[alertData.eventType as string]?.message}</Title>
          </AlertData>
          <AlertData>
            <Text>{t('Date')}</Text>
            <Title>{alertData?.timestamp ? getOnlyDate(alertData?.timestamp) : '-'}</Title>
          </AlertData>
          {getAlertMetaData(alertData.alertData, AlertMessageType[alertData.eventType as string]?.unit)}
          <AlertData>
            <Text>{t('Status')}</Text>
            <Title color={ALERT_STATUS_INDICATOR[alertData.status as keyof typeof ALERT_STATUS_INDICATOR].color}>{alertData.status ? ALERT_STATUS_INDICATOR[alertData.status as keyof typeof ALERT_STATUS_INDICATOR].name : '-'}</Title>
          </AlertData>
        </AlertDetails>
      </AlertDetailsContainer>
    </Modal>
  );
};

export default AlertThumbnailModal;