import React, { useState, ChangeEvent, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import GeneralEmailSetting from './GeneralEmailSetting';
import AdvancedEmailSetting from './AdvancedEmailSetting';
import { DELETE_EMAIL_CLIENT, SAVE_EMAIL_CLIENT, SAVE_TEST_EMAIL, UPDATE_EMAIL_CLIENT, VALIDATE_EMAIL_CLIENT } from 'api_configs/mutations';
import { GET_EMAIL_CLIENT } from 'api_configs/queries';
import { useMutation, useQuery } from '@apollo/client';
import AlertBar from 'components/AlertBar';
import CircularProgress from '@mui/material/CircularProgress';
import { DEFAULT_EMAIL_CLIENT_DATA, IconType, StatusCode } from '../../constants';
import { IEmailDetails } from 'interface';
import { isEqual } from 'lodash';
import Modal from 'components/Modal';
import Input from 'components/Input';
import TextArea from 'components/TextArea';
import Button from 'components/Button';
import ButtonWithLoading from 'components/ButtonWithLoading';

const TabContainer = styled.div``;

const SpinnerBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 89vh;
  align-items: center;
  justify-content: center;
`;

const Label = styled.div``;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 40px;
  align-items: center;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  margin-left: 16px;
`;

const ConfigurationLine = styled.div`
  height: 1px;
  background-color: #d3d3d3;
  width: inherit;
  margin-top: 6px;
  margin-bottom: 10px;
`;

const ActionButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
  gap: 15px;
  > button {
    width: max-content;
  }
`;

interface IAlertMessageType {
  text: string,
  id: string,
  type: string | IconType,
}

interface ITestEmailDetails {
  defaultFrom?: string,
  defaultTo?: string,
  subject?: string,
  emailBody?: string
}

const EmailSetting = () =>{
  const {t} = useTranslation(['common']);
  const [saveEmailClientMutation] = useMutation(SAVE_EMAIL_CLIENT);
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'});
  const [loader, setLoader] = useState(false); 
  const [emailDetails, setEmailDetails] = useState<IEmailDetails>(DEFAULT_EMAIL_CLIENT_DATA);
  const [deleteEmailMutation] = useMutation(DELETE_EMAIL_CLIENT);
  const {data: GetEmailDetailsData,loading: GetEmailDetailsLoader, refetch: getEmailClientRefetch} = useQuery(GET_EMAIL_CLIENT, { variables: {clientId: emailDetails.instanceId} });
  const [editMode, setEditMode] = useState<boolean>(false);
  const [updateEmailMutation] = useMutation(UPDATE_EMAIL_CLIENT);
  const [desiredState, setDesiredState] = useState(false);
  const [isActionButtonsDisable, setActionButtonsDisable] = useState(false);
  const [saveDetailsLoader, setSaveDetailsLoader] = useState(false);
  const [initialEmailDetails, setInitialEmailDetails] = useState<IEmailDetails>(DEFAULT_EMAIL_CLIENT_DATA);
  const [validateEmailClientMutation] = useMutation(VALIDATE_EMAIL_CLIENT);
  const [saveTestEmailMutation] = useMutation(SAVE_TEST_EMAIL);
  const [isModalOpen,setIsModalOpen] = useState(false);
  const [testEmailDetails, setTestEmailDetails ] = useState<ITestEmailDetails>({defaultFrom: '', defaultTo: '', subject: '', emailBody: ''});
  const [emailValidationLoader, setEmailValidationLoader] = useState(false);
  const [testEmailLoader, setTestEmailLoader] = useState(false);

  useEffect(() =>{
    if(GetEmailDetailsData?.getEmailClient){
      let intervalId: NodeJS.Timeout | null = null;
      const { statusCode, result } = GetEmailDetailsData.getEmailClient;
      if (statusCode === StatusCode.SUCCESS) {
        const response = {
          instanceId: result?.instanceId, 
          nickname: result?.nickname,
          region: result?.awsRegion,
          accessKey: result?.awsAccessKey,
          secretKey: result?.awsSecretKey,
          defaultFrom: result?.defaultFrom,
          defaultTo: result?.defaultTo,
          days: result?.days,
          hours: result?.hours,
          minutes: result?.minutes
        };
        setEmailDetails(response);
        setInitialEmailDetails(response);
        setTestEmailDetails({defaultFrom: response.defaultFrom, defaultTo: response.defaultTo});
        if(result?.instanceId){
          setEditMode(true);
        }
        setActionButtonsDisable(true);
        setDesiredState(false);
      }
      if(statusCode === StatusCode.NO_RESULT){
        setEmailDetails(DEFAULT_EMAIL_CLIENT_DATA);
        setDesiredState(false);
        setEditMode(false);
        setActionButtonsDisable(false);
      }
      if (statusCode === StatusCode.DESIRED && intervalId === null) {
        intervalId = setInterval(() => {
          getEmailClientRefetch();
        },5000);
      }
      return () => {
        if (intervalId !== null) {
          clearInterval(intervalId);
          intervalId = null;
        }
      };
    }
  },[GetEmailDetailsData?.getEmailClient, t, getEmailClientRefetch]);

  useEffect(()=>{
    setActionButtonsDisable(isEqual(emailDetails, initialEmailDetails));
  },[emailDetails, initialEmailDetails]);

  useEffect(()=> {
    if(GetEmailDetailsLoader){
      setLoader(true);
    } else {
      setLoader(false);
    }
  },[GetEmailDetailsLoader]);

  const handleChange = (event?: ChangeEvent<HTMLInputElement | HTMLSelectElement>)  => {
    const { name, value } = event?.target || {};
    if(name === 'instanceId'){
      setEmailDetails(prev => ({...prev, instanceId: value}));
    }
    if(name === 'nickname'){
      setEmailDetails(prev => ({...prev, nickname: value}));
    }
    if(name === 'accessKey'){
      setEmailDetails(prev => ({...prev, accessKey: value}));
    }
    if(name === 'secretKey'){
      setEmailDetails(prev => ({...prev, secretKey: value}));
    }
    if(name === 'defaultFrom'){
      setEmailDetails(prev => ({...prev, defaultFrom: value}));
    }
    if(name === 'defaultTo'){
      setEmailDetails(prev => ({...prev, defaultTo: value}));
    }
    if(name === 'days'){
      setEmailDetails(prev => ({...prev, days: value!.replace(/[^\d]/, '').slice(0, 2)}));
    }
  };

  const onHandleChangeHours = (value?: string)  => {
    setEmailDetails(prev => ({...prev, hours: parseInt(value!)}));
  };

  const onHandleChangeMinutes = (value?: string)  => {
    setEmailDetails(prev => ({...prev, minutes: parseInt(value!)}));
  };

  const onHandleChangeRegion = (value?: string)  => {
    setEmailDetails(prev => ({...prev, region: value}));
  };

  const handlesSaveDetails = useCallback(async () =>{
    if(emailDetails.instanceId?.replace(/\s/g, '').length === 0){
      setMessage({text: t('Please enter the instance ID'), id:'',type:'danger'});
      return;
    }
    if(emailDetails.region?.replace(/\s/g, '').length === 0){
      setMessage({text: t('Please select the region'), id:'',type:'danger'});
      return;
    } 
    if(emailDetails.accessKey?.replace(/\s/g, '').length === 0){
      setMessage({text: t('Please enter the access key'), id:'',type:'danger'});
      return;
    } 
    if(emailDetails.secretKey?.replace(/\s/g, '').length === 0){
      setMessage({text: t('Please enter the secret key'), id:'',type:'danger'});
      return;
    }
    if(emailDetails.days === ''){
      setMessage({text: t('Please select days'), id:'',type:'danger'});
      return;
    }
    setSaveDetailsLoader(true);
    try {
      const paylaod = {
        instanceId: emailDetails.instanceId,
        nickname: emailDetails.nickname,
        region: emailDetails.region,
        accessKey: emailDetails.accessKey,
        secretKey: emailDetails.secretKey,
        defaultFrom: emailDetails.defaultFrom,
        defaultTo: emailDetails.defaultTo,
        days: parseInt(emailDetails.days!),
        hours: emailDetails.hours,
        minutes: emailDetails.minutes
      };
      const result = await saveEmailClientMutation({variables: {input: paylaod}});
      if(result.data.addEmailClient.statusCode === StatusCode.SUCCESS){
        setMessage({text: t('Email saved successfully'), id:'',type:'success'});
        setTimeout(() =>{
          getEmailClientRefetch();
        },1000);
      }else {
        setMessage({text: t('Fail to save email'), id:'',type:'danger'});
      }
    } catch(e) {
      setMessage({text: t('Fail to save email'), id:'',type:'danger'});
    } finally {
      setSaveDetailsLoader(false);
    }
  },[emailDetails, getEmailClientRefetch, t, saveEmailClientMutation]);

  const handleDeleteEmail = useCallback(async () => {
    try{
      const result = await deleteEmailMutation({variables:{emailClientId: emailDetails?.instanceId}});
      if(result.data.deleteEmailClient.statusCode === StatusCode.SUCCESS){
        setMessage({text: t('Email Client deleted successfully'), id:'',type:'success'});
        setDesiredState(true);
        getEmailClientRefetch();
      }else{
        setMessage({text: t('apiError'), id:'',type:'danger'});
      }
    }catch(e){
      setMessage({text: t('apiError'), id:'',type:'danger'});
    }
  },[t, deleteEmailMutation, getEmailClientRefetch, emailDetails?.instanceId]);

  const handleUpdateDetails = useCallback(async () => {
    if(emailDetails.instanceId?.replace(/\s/g, '').length === 0){
      setMessage({text: t('Please enter the instance ID'), id:'',type:'danger'});
      return;
    }
    if(emailDetails.region?.replace(/\s/g, '').length === 0){
      setMessage({text: t('Please select the region'), id:'',type:'danger'});
      return;
    } 
    if(emailDetails.accessKey?.replace(/\s/g, '').length === 0){
      setMessage({text: t('Please enter the access key'), id:'',type:'danger'});
      return;
    } 
    if(emailDetails.secretKey?.replace(/\s/g, '').length === 0){
      setMessage({text: t('Please enter the secret key'), id:'',type:'danger'});
      return;
    }
    if(emailDetails.days === ''){
      setMessage({text: t('Please select days'), id:'',type:'danger'});
      return;
    }
    setSaveDetailsLoader(true);
    try{
      const paylaod = {
        instanceId: emailDetails.instanceId,
        nickname: emailDetails.nickname,
        region: emailDetails.region,
        accessKey: emailDetails.accessKey,
        secretKey: emailDetails.secretKey,
        defaultFrom: emailDetails.defaultFrom,
        defaultTo: emailDetails.defaultTo,
        days: parseInt(emailDetails.days!),
        hours: emailDetails.hours,
        minutes: emailDetails.minutes
      };
      const result = await updateEmailMutation({variables: {payload: paylaod}});
      if(result.data.editEmailClient.statusCode === StatusCode.SUCCESS){
        setMessage({text: t('Email Client updated successfully'), id:'',type:'success'});
        getEmailClientRefetch();
      }else{
        setMessage({text: t('apiError'), id:'',type:'danger'});
      }
    } catch(e){
      setMessage({text: t('apiError'), id:'',type:'danger'});
    } finally {
      setSaveDetailsLoader(false);
    }
  },[t, emailDetails, getEmailClientRefetch, updateEmailMutation]);

  const onResetClick = useCallback(() =>{
    setEmailDetails(initialEmailDetails);
  },[initialEmailDetails]);

  const validateEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  const onValidateConfigurationClick = useCallback(async() => {
    const payload = {
      regionName: emailDetails.region,
      awsAccessKeyId: emailDetails.accessKey,
      awsSecretAccessKey: emailDetails.secretKey,
      defaultFrom: emailDetails.defaultFrom,
      defaultTo: emailDetails.defaultTo
    };

    if(payload.regionName?.replace(/\s/g, '').length === 0){
      setMessage({text: t('Please select the region'), id:'',type:'danger'});
      return;
    } 
    if(payload.awsAccessKeyId?.replace(/\s/g, '').length === 0){
      setMessage({text: t('Please enter the access key'), id:'',type:'danger'});
      return;
    } 
    if(payload.awsSecretAccessKey?.replace(/\s/g, '').length === 0){
      setMessage({text: t('Please enter the secret key'), id:'',type:'danger'});
      return;
    }
    if (!validateEmail(payload.defaultFrom ? payload.defaultFrom : '')) {
      setMessage({ text: t('Please enter a valid From email address'), id: '', type: 'danger' });
      return;
    }
    if (!validateEmail(payload.defaultTo ? payload.defaultTo : '')) {
      setMessage({ text: t('Please enter a valid To email address'), id: '', type: 'danger' });
      return;
    }
    setEmailValidationLoader(true);
    try {
      const result = await validateEmailClientMutation({variables: {payload}});
      if(result.data.validateEmailClient.statusCode === StatusCode.SUCCESS){
        setMessage({text: t('Configuration validation successfully'), id:'',type:'success'});
      } else{
        setMessage({text: t('Configuration validation failed'), id:'',type:'danger'});
      }
    } catch(e){
      setMessage({text: t('Configuration validation failed'), id:'',type:'danger'});
    }
    setEmailValidationLoader(false);
  },[t, emailDetails, validateEmailClientMutation]);

  const onChangeTestEmail = (event?: ChangeEvent<HTMLInputElement | HTMLSelectElement>)  => {
    const { name, value } = event?.target || {};
    if(name === 'defaultFrom'){
      setTestEmailDetails((prev: any) => ({...prev, defaultFrom: value}));
    }
    if(name === 'defaultTo'){
      setTestEmailDetails((prev: any) => ({...prev, defaultTo: value}));
    }
    if(name === 'subject'){
      setTestEmailDetails((prev: any) => ({...prev, subject: value}));
    }
  };

  const onHandleSendTestEmail = async () =>{
    const payload = {
      emailClientId: emailDetails.instanceId,
      defaultFrom: testEmailDetails.defaultFrom,
      defaultTo: testEmailDetails.defaultTo,
      subject: testEmailDetails.subject,
      emailBody: testEmailDetails.emailBody
    };

    if (!validateEmail(payload.defaultFrom ? payload.defaultFrom : '')) {
      setMessage({ text: t('Please enter a valid From email address'), id: '', type: 'danger' });
      return;
    }
    if (!validateEmail(payload.defaultTo ? payload.defaultTo : '')) {
      setMessage({ text: t('Please enter a valid To email address'), id: '', type: 'danger' });
      return;
    }
    if(payload.subject?.replace(/\s/g, '').length === 0){
      setMessage({text: t('Please enter the subject'), id:'',type:'danger'});
      return;
    } 
    if(payload.emailBody?.replace(/\s/g, '').length === 0){
      setMessage({text: t('Please enter the email body'), id:'',type:'danger'});
      return;
    } 
    setTestEmailLoader(true);
    try {
      const result = await saveTestEmailMutation({variables: {payload}});
      if(result.data.sendTestEmail.statusCode === StatusCode.SUCCESS){
        setMessage({text: t('Email sent successfully'), id:'',type:'success'});
        setTestEmailDetails({});
        setIsModalOpen(false);
      } else if(result.data.sendTestEmail.statusCode === StatusCode.API_CALL_ERROR) {
        setMessage({text: t('Failed to send test email'), id:'',type:'danger'});
      } 
      else{
        setMessage({text: t('apiError'), id:'',type:'danger'});
      }
    } catch(e){
      setMessage({text: t('apiError'), id:'',type:'danger'});
    }
    setTestEmailLoader(false);
  };

  const onSendTestEmailClick = () =>{
    setIsModalOpen(!isModalOpen);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  if (loader){
    return (
      <SpinnerBox>
        <CircularProgress />
        <Label>{t('Loading...')}</Label>
      </SpinnerBox>
    );
  }

  return (
    <>
      {message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
      { isModalOpen &&
       <Modal isModalOpen={isModalOpen} width='520px' closeModal={handleCloseModal}>
         <>
           <ModalTitle>{t('Send Test Email')}</ModalTitle>
           <ConfigurationLine />
           <ModalContent>
             <Input id='defaultFrom' value={testEmailDetails.defaultFrom} onChange={onChangeTestEmail} name='defaultFrom' type='text' label={t('From')} isRequired maxLength={64} />
             <Input id='defaultTo' value={testEmailDetails.defaultTo} onChange={onChangeTestEmail} name='defaultTo' type='text' label={t('To')} isRequired maxLength={64} />
             <Input id='subject' value={testEmailDetails.subject} onChange={onChangeTestEmail} name='subject' type='text' label={t('Subject')} isRequired maxLength={64} />
             <TextArea name='emailDesc' value={testEmailDetails.emailBody} onChange={(e) => setTestEmailDetails({...testEmailDetails, emailBody: e.target.value})} label={t('Email Body')} required rows={10} cols={30} isRequired height='148px' />
           </ModalContent>
           <ActionButtons>
             <ButtonWithLoading loading={testEmailLoader} variant='primary' onClick={onHandleSendTestEmail}>{t('Send')}</ButtonWithLoading>
             <Button variant='danger' onClick={handleCloseModal}>{t('Cancel')}</Button>
           </ActionButtons>
         </>
       </Modal>
      }
      <TabContainer>
        <GeneralEmailSetting
          handlesSaveDetails={handlesSaveDetails} 
          onChange={handleChange}
          onHandleChangeRegion={onHandleChangeRegion} 
          emailDetails={emailDetails}
          editMode={editMode}
          handleUpdateDetails={handleUpdateDetails}
        />
        <AdvancedEmailSetting 
          handleDelete={handleDeleteEmail}
          editMode={editMode}
          handlesSaveDetails={handlesSaveDetails}
          saveDetailsLoader={saveDetailsLoader}
          onChange={handleChange}
          onHandleChangeMinutes={onHandleChangeMinutes} 
          onHandleChangeHours={onHandleChangeHours}
          emailDetails={emailDetails}
          handleUpdateDetails={handleUpdateDetails}
          desiredState={desiredState}
          isActionButtonsDisable={isActionButtonsDisable}
          onResetClick={onResetClick}
          onValidateConfigurationClick={onValidateConfigurationClick}
          emailValidationLoader={emailValidationLoader}
          onSendTestEmailClick={onSendTestEmailClick}
        />
      </TabContainer>
    </>
  );
};

export default EmailSetting;