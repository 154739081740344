import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgList = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M3.394 6.1c0-.292.119-.572.33-.778.21-.206.497-.322.795-.322h1.125c.299 0 .585.116.796.322.21.206.33.486.33.778 0 .292-.12.572-.33.778a1.138 1.138 0 0 1-.796.322H4.52c-.298 0-.584-.116-.795-.322a1.088 1.088 0 0 1-.33-.778Zm5.625 0c0-.292.119-.572.33-.778.21-.206.497-.322.795-.322H20.27c.299 0 .585.116.796.322.21.206.33.486.33.778 0 .292-.12.572-.33.778a1.138 1.138 0 0 1-.796.322H10.144c-.298 0-.584-.116-.795-.322a1.088 1.088 0 0 1-.33-.778Zm-5.625 5.5c0-.292.119-.572.33-.778.21-.206.497-.322.795-.322h1.125c.299 0 .585.116.796.322.21.207.33.486.33.778 0 .292-.12.572-.33.778a1.138 1.138 0 0 1-.796.322H4.52c-.298 0-.584-.116-.795-.322a1.088 1.088 0 0 1-.33-.778Zm5.625 0c0-.292.119-.572.33-.778.21-.206.497-.322.795-.322H20.27c.299 0 .585.116.796.322.21.207.33.486.33.778 0 .292-.12.572-.33.778a1.138 1.138 0 0 1-.796.322H10.144c-.298 0-.584-.116-.795-.322a1.088 1.088 0 0 1-.33-.778Zm-5.625 5.5c0-.292.119-.572.33-.778.21-.206.497-.322.795-.322h1.125c.299 0 .585.116.796.322.21.207.33.486.33.778 0 .292-.12.572-.33.778a1.138 1.138 0 0 1-.796.322H4.52c-.298 0-.584-.116-.795-.322a1.088 1.088 0 0 1-.33-.778Zm5.625 0c0-.292.119-.572.33-.778.21-.206.497-.322.795-.322H20.27c.299 0 .585.116.796.322.21.207.33.486.33.778 0 .292-.12.572-.33.778a1.138 1.138 0 0 1-.796.322H10.144c-.298 0-.584-.116-.795-.322a1.088 1.088 0 0 1-.33-.778Z"
        fill={props.color}
      />
    </svg>
  );
};
export default SvgList;
