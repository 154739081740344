import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgTrafficPulse = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.826 24.35v-4.8a.47.47 0 1 0-.94 0v6.77l-1.75 3.66c-.44.93.24 2.01 1.27 2.01h21.32a.47.47 0 1 0 0-.94h-3.66l-.75-4.47c-.11-.68-.7-1.17-1.39-1.17h-1.88c-.69 0-1.27.49-1.39 1.17l-.75 4.47h-8.88l8.09-17.88h2.34l-.22 2.22c-.08.83.57 1.55 1.4 1.55h.75c.83 0 1.48-.72 1.4-1.55l-.22-2.22h2.33l8.09 17.89h-3.06a.47.47 0 1 0 0 .94h5.67a1.41 1.41 0 0 0 1.27-2.01l-1.75-3.66V12.87a3.293 3.293 0 0 0 1.88-2.97V3.28c0-1.81-1.47-3.28-3.28-3.28H3.286C1.476 0 .006 1.47.006 3.28v6.61c0 1.31.77 2.44 1.88 2.97v7.43a.47.47 0 1 0 .94 0v-7.15c.31.04 3.87.03 5.42.03l-5.42 11.18Zm26.36 0-5.45-11.18c1.49 0 5.14.01 5.45-.03v11.21ZM.946 9.89V3.28c0-1.29 1.05-2.34 2.34-2.34h25.44c1.29 0 2.34 1.05 2.34 2.34v6.61c0 1.29-1.05 2.34-2.34 2.34H3.286c-1.29 0-2.34-1.05-2.34-2.34Zm13.66 16.85c.04-.23.23-.39.46-.39h1.88c.23 0 .42.16.46.39l.72 4.32h-4.25l.72-4.32h.01Zm-11.61 4.32h-1.58c-.35 0-.57-.36-.42-.67l8.19-17.22h1.9l-8.09 17.89Zm13.85-15.58a.47.47 0 0 1-.47.52h-.75c-.28 0-.49-.24-.47-.52l.23-2.31h1.22l.23 2.31h.01Zm13.75 15.58h-1.58l-8.09-17.89h1.9l8.2 17.22c.15.31-.08.67-.42.67h-.01Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.13 9.893v-6.6c0-.78-.63-1.41-1.41-1.41h-4.64a.47.47 0 1 0 0 .94h4.64c.26 0 .47.21.47.47v6.6c0 .26-.21.47-.47.47H3.3a.47.47 0 0 1-.47-.47v-6.6c0-.26.21-.47.47-.47h18.59a.47.47 0 1 0 0-.94H3.3c-.78 0-1.41.63-1.41 1.41v6.6c0 .78.63 1.41 1.41 1.41h25.42c.78 0 1.41-.63 1.41-1.41Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.454 5.799c-.15 0-.54-.04-.54.2 0 .12.01.28.22.28.65 0 .62.06.76-.29.05-.14.1-.27.16-.4l.31-.8v.01l.39 1.78.09.44c.03.16.06.3.1.46l.24 1.13c.08.39.39.25.45.14l.81-2.22c.02-.07.06-.14.07-.21h.03c.01.05.48.93.53 1 .12.14.33.09.41-.06l.6-1.29c.04-.09.08-.17.12-.26l.12-.26s.03.04.05.1l.03.11.21.66c.05.16.3 1.03.37 1.11.04.05.12.09.2.09.19-.01.22-.16.26-.32.04-.16.08-.3.13-.45l.14-.46c.12 0 .33.02.47 0 .31 0 .32-.02.43-.29.05-.14.1-.27.16-.4l.31-.8v.01l.39 1.78.09.44c.04.16.06.3.1.46l.24 1.13c.08.39.39.25.45.14l.81-2.22c.02-.07.06-.14.07-.21h.03c.01.05.48.93.53 1 .12.14.33.09.4-.06l.6-1.29c.04-.09.08-.17.12-.26l.12-.26s.03.04.05.1l.03.11.21.66c.05.16.3 1.03.37 1.11.04.05.12.09.2.09.19-.01.22-.16.26-.32.04-.16.08-.3.13-.45l.14-.46c.17 0 .5.03.59-.05a.22.22 0 0 0 .08-.15v-.09c-.02-.1-.1-.19-.31-.19h-.48c-.18 0-.27.06-.31.2l-.06.2c-.02.06-.05.15-.05.21-.05-.04-.15-.42-.17-.49-.05-.18-.11-.33-.16-.51s-.11-.33-.16-.51c-.05-.17-.09-.37-.3-.37s-.23.18-.3.3l-.64 1.36c-.04.07-.18.36-.19.42h-.03c-.02-.08-.41-.77-.48-.92-.17-.35-.41-.23-.49-.06l-.11.3c-.12.37-.31.82-.43 1.2-.03.08-.2.55-.21.61-.04-.03-.03-.06-.06-.19-.01-.07-.03-.14-.05-.22l-.27-1.26c-.16-.68-.29-1.42-.45-2.1-.02-.07-.03-.13-.07-.18a.274.274 0 0 0-.18-.08c-.17 0-.22.11-.26.24-.14.4-.4 1.04-.57 1.47l-.14.37H15.594c-.18 0-.27.06-.31.2l-.06.2c-.02.06-.05.15-.05.21-.05-.04-.15-.42-.17-.49-.05-.18-.11-.33-.16-.51s-.11-.33-.16-.51c-.05-.17-.09-.37-.3-.37s-.23.18-.3.3l-.64 1.36c-.04.07-.18.36-.19.42h-.03c-.02-.08-.41-.77-.48-.92-.17-.35-.41-.23-.49-.06l-.11.3c-.12.37-.31.82-.43 1.2-.03.08-.2.55-.21.61-.04-.03-.03-.06-.06-.19-.01-.07-.03-.14-.05-.22l-.27-1.26c-.16-.68-.29-1.42-.45-2.1-.02-.07-.03-.13-.07-.18a.274.274 0 0 0-.18-.08c-.17 0-.22.11-.26.24-.14.4-.4 1.04-.57 1.47l-.14.37Z"
        fill={props.color}
      />
      <path d="M2.83 17.89h-.94v4.56h.94v-4.56Z" fill={props.color} />
    </svg>
  );
};
export default SvgTrafficPulse;
