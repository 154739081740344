import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgError = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="M12 21a9 9 0 0 1-9-9 9 9 0 0 1 9-9 9 9 0 0 1 9 9 9 9 0 0 1-9 9Zm0-1.8a7.2 7.2 0 1 0 0-14.4 7.2 7.2 0 0 0 0 14.4Zm0-11.7a.9.9 0 0 1 .9.9v4.5a.9.9 0 1 1-1.8 0V8.4a.9.9 0 0 1 .9-.9Zm0 9a.9.9 0 1 1 0-1.8.9.9 0 0 1 0 1.8Z"
          fill="#34495E"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgError;
