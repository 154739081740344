import { IAlertMessageType, IVideoPlayerProps } from 'interface';
import { IconType, SERVER_URL } from '../constants';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FaPause, FaPlay } from 'react-icons/fa';
import { MdFullscreenExit, MdOutlineFullscreen } from 'react-icons/md';
import styled from 'styled-components';
import { formatTime } from 'utils';
import { IoMdDownload } from 'react-icons/io';
import Modal from './Modal';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ScrollBarCss } from 'CommonStyles';
import AlertBar from './AlertBar';
import { TbRewindBackward10, TbRewindForward10 } from 'react-icons/tb';

const Container = styled.div<{width?: string}>`
  position: relative;
  width: ${({width}) => width ?? '100%'};
`;

const VideoContainer = styled.div`
  position: relative;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3));
  border-radius: 10px;
  overflow: hidden;
  aspect-ratio: 16 / 9;
`;

const Video = styled.video``;

const VideoControlsWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 99;
  opacity: 1;
  bottom: 0px;
  transition: all 0.08s ease;
  &::before{
    content: "";
    bottom: 0;
    width: 100%;
    z-index: -1;
    position: absolute;
    height: calc(100% + 35px);
    pointer-events: none;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
  }
`;

const VideoTimeline = styled.div`
  height: 7px;
  width: 100%;
  cursor: pointer;
  background-color: #aaaaaa;
`;

const VideoControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between; 
  height: 40px;
  padding: 0 10px;
  >div{
    flex: 1;
    display: flex;
    gap: 8px;
    align-items: center;
    color: #FFF;
  }
`;

const ProgressArea = styled.div`
  height: 7px;
  position: relative;
  background: rgba(255, 255, 255, 0.6);
  >span{
    position: absolute;
    left: 50%;
    top: -25px;
    font-size: 13px;
    color: #fff;
    pointer-events: none;
    transform: translateX(-50%);
    display: none; //TO_DO
  }
`;

const ProgressBar = styled.div<{width?: number}>`
  width: ${({width}) => width ? `${width}%` : '0%'};
  height: 100%;
  position: relative;
  background: #2289ff;
  z-index: 9;
  transition: all 0.3s ease;
`;

const FetchVideosProgressBar = styled.div<{width?: number}>`
  width: ${({width}) => width ? `${width}%` : '0%'};
  height: 2px;
  position: absolute;
  bottom: 0;
  background: #60ff30;
  z-index: 8;
  transition: all 0.3s ease;
`;

const VideoLeftControls = styled.div`
  justify-content: flex-start;
`;

const VideoCenterControls = styled.div`
  justify-content: center;
  margin-left: -10px;
  gap: 14px !important;
  >svg{
    cursor: pointer;
  }
`;

const VideoRightControls = styled.div`
  justify-content: flex-end;
  >svg{
    cursor: pointer;
  }
`;

const Span = styled.span``;

const DownloadContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Header = styled.span`
  font-weight: 600;
  font-size: 18px;
`;

const FilesContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  max-height: 70vh;
  overflow-y: auto;
  ${ScrollBarCss};
`;

const FileContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  width: 100px;
  height: 70px;
  cursor: pointer;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  >svg{
    cursor: pointer;
  }
`;

const ThumbnailImg = styled.img`
  aspect-ratio: 16 / 9;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const IconDiv = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover{
    background-color: #aaaaaa5e;
  }
`;

const DownloadWrapper = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VideosInfo = styled.div`
  position: absolute;
  bottom: -20px;
  right: 0;
  display: flex;
  gap: 10px;
`;

const VideoInfoDiv = styled.div``;

interface DownloadingState {
  [key: string]: boolean;
}

const VideoPlayer: React.FC<IVideoPlayerProps> = ({ width='100%', urls, totalDuration, durationArray}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const videoTimelineRef = useRef<HTMLDivElement>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [currentVideoIndex, setCurrentVideoIndex] = useState<number>(0);
  const [mediaList, setMediaList] = useState<string[]>([]);
  const [videoProgress, setVideoProgress] = useState<number>(0);
  const timeoutRef = useRef<number | undefined>(); 
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [downloadModal, setDownloadModal] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<DownloadingState>({});
  const {t} = useTranslation(['common']);
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'});  

  const timeUpdate = useCallback(() => {
    const video = videoRef.current;
    if(video){
      const progress = currentVideoIndex === 0 ? video.currentTime : video.currentTime + durationArray[currentVideoIndex - 1];
      setVideoProgress(progress);
    }
  },[currentVideoIndex, durationArray]);

  useEffect(() => {
    timeUpdate();
    const interval = setInterval(() => {
      timeUpdate();
    },1000);
    return () => clearInterval(interval);
  },[timeUpdate]);

  const fetchVideos = useCallback(async() => {
    async function fetchMedia() {
      for (let i = 0; i<urls.length; i++) {
        try {
          const response = await fetch(SERVER_URL + urls[i]);
          if (!response.ok || response.headers.get('Content-Type') !== 'video/mp4') {
            throw new Error('Network response was not ok');
          }
          const file = await response.arrayBuffer();
          const blobURL = URL.createObjectURL(new Blob([file]));
          setMediaList(prev => {
            const tempList = [...prev];
            tempList[i] = blobURL;
            return [...tempList];
          });
        } catch (error) {
          console.error('Error fetching media:', error);
        }
      }
    }
    await fetchMedia();
  },[urls]);

  useEffect(() => {
    fetchVideos();
  },[fetchVideos]);

  const handleVideoEnded = useCallback(() => {
    if(currentVideoIndex < urls.length-1){
      setCurrentVideoIndex(prev => prev + 1);
    }else{
      if(videoRef.current){
        videoRef.current.pause();
      }
      setCurrentVideoIndex(0);
      setVideoProgress(0);
    }
  },[currentVideoIndex, urls]);

  const handleVideoClick = useCallback(() => {
    if(videoRef.current){
      if(isPlaying){
        videoRef.current.pause();
      }else{
        videoRef.current.play();
      }
      setIsPlaying(prev => !prev);
    }
  },[isPlaying]);

  const handleTimelineClick = useCallback((e: React.MouseEvent<HTMLDivElement | SVGElement>, forward: boolean=false, backward: boolean=false) => {
    const videoTimeline = videoTimelineRef.current;
    if(videoTimeline && e.nativeEvent instanceof MouseEvent){
      const timelineWidth = videoTimeline.clientWidth;
      let updatedProgress: number;
      if(forward){
        updatedProgress = videoProgress + 10;
      }else if(backward){
        updatedProgress = videoProgress - 10;
      }else{
        updatedProgress = (e.nativeEvent.offsetX / timelineWidth) * totalDuration;
      }
      if(updatedProgress > durationArray[mediaList.length - 1]){
        setMessage({text: t('That part of the video is not fetched yet. Please wait.'), id:'',type:'danger'});
        return;
      }
      setVideoProgress(updatedProgress);
      clearTimeout(timeoutRef.current); // de-bouncing implemented when user drags the video-timeline. Although it needs some modifications/changes
      timeoutRef.current = window.setTimeout(() => {
        let seekedVideoIndex = 0;
        let seekedVideoCurrentTime = updatedProgress;
        if (updatedProgress > durationArray[0]) {
          for (let i = 1; i < durationArray.length; i++) {
            if (updatedProgress <= durationArray[i]) {
              seekedVideoIndex = i;
              seekedVideoCurrentTime = updatedProgress - durationArray[i - 1];
              break;
            }
          }
        }
        setCurrentVideoIndex(seekedVideoIndex);
        if (videoRef.current) {
          videoRef.current.src = mediaList[seekedVideoIndex];
          videoRef.current.currentTime = seekedVideoCurrentTime;
        }
      }, 300);
    }
  },[totalDuration, mediaList, durationArray, t, videoProgress]);

  const handleFullScreenToggle = useCallback(() => {
    const videoContainerElement = videoContainerRef.current;
    if (videoContainerElement) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        videoContainerElement.requestFullscreen().catch((err) => {
          console.error('Error attempting to enable fullscreen:', err);
        });
      }
    }
  },[]);

  const handleFullScreenChange = () => {
    setIsFullScreen(!!document.fullscreenElement);
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullScreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement && isFullScreen) {
      videoElement.style.height = '100vh';
      videoElement.style.width = '100vw';
    }
    return () => {
      if (videoElement) {
        videoElement.style.height = '';
        videoElement.style.width = '';
      }
    };
  }, [isFullScreen]);

  const handleDownloadClick = useCallback(() => {
    setDownloadModal(prev => !prev);
  },[]);

  const handleDownload = async (url: string) => {
    const videoUrl = SERVER_URL + url;
    try {
      setDownloading(prevState => ({
        ...prevState,
        [url]: true
      }));
      const response = await fetch(videoUrl);
      const blob = await response.blob();
      const urlObject = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.style.display = 'none';
      anchor.href = urlObject;
      anchor.download = url.split('/').slice(-1)[0]; //File Name
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      window.URL.revokeObjectURL(urlObject);
      setDownloading(prevState => ({
        ...prevState,
        [url]: false
      }));
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  return (
    <Container ref={videoContainerRef}>
      { message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
      <VideoContainer>
        <Video
          autoPlay 
          src={mediaList[currentVideoIndex]} 
          onEnded={handleVideoEnded} 
          style={{borderRadius: '10px'}}
          ref={videoRef} 
          width={width} 
          onClick={handleVideoClick} 
          controls={false}
        /> 
        <VideoControlsWrapper>
          <VideoControls>
            <VideoLeftControls>
              <Span>{formatTime(videoProgress)}</Span>
              <span>/</span>
              <Span>{formatTime(totalDuration)}</Span>
            </VideoLeftControls>
            <VideoCenterControls>
              <TbRewindBackward10 size={22} onClick={(e) => handleTimelineClick(e, false, true)} />
              {videoRef.current && !videoRef.current.paused ? <FaPause onClick={handleVideoClick} /> : <FaPlay onClick={handleVideoClick} />}
              <TbRewindForward10 size={22} onClick={(e) => handleTimelineClick(e, true, false)}/>
            </VideoCenterControls>
            <VideoRightControls>
              <IoMdDownload size={24} color='#FFF' onClick={handleDownloadClick} />
              {!isFullScreen ? <MdOutlineFullscreen size={24} color='#FFF' onClick={handleFullScreenToggle} /> : <MdFullscreenExit size={24} color='#FFF' onClick={handleFullScreenToggle} />}
              {downloadModal && 
              <Modal isModalOpen={downloadModal} closeModal={setDownloadModal} width='20vw'>
                <DownloadContainer>
                  <Header>{t('Video Files')}</Header>
                  <FilesContent>
                    {urls.map(url => (
                      <FileContainer>
                        <ThumbnailImg title={url.split('/').slice(-1)[0]} src={SERVER_URL + url.split('.')[0]+'_thumbnail.png'} />
                        <IconDiv title={url.split('/').slice(-1)[0]}>
                          <DownloadWrapper>
                            {downloading[url] ? (
                              <CircularProgress size={24} />
                            ) : (
                              <IoMdDownload color='#5675ff' size={24} onClick={() => handleDownload(url)} />
                            )}
                          </DownloadWrapper>
                        </IconDiv>
                      </FileContainer>
                    ))}
                  </FilesContent>
                </DownloadContainer>
              </Modal>}
            </VideoRightControls>
          </VideoControls>
          <VideoTimeline ref={videoTimelineRef} onClick={handleTimelineClick}>
            <ProgressArea>
              <span>00:00</span> {/* TO_DO Tooltip for video timeline */}
              <ProgressBar width={(videoProgress / totalDuration) * 100}/>
              {mediaList.length > 0 && <FetchVideosProgressBar width={(durationArray[mediaList.length - 1] / totalDuration) * 100} />}
            </ProgressArea>
          </VideoTimeline>
        </VideoControlsWrapper>
      </VideoContainer>
      <VideosInfo>
        {/* Adding below div for Test purpose only. will remove once testing is done. */}
        <VideoInfoDiv>{t('Current Video Index')}:{currentVideoIndex + 1}</VideoInfoDiv>
        <VideoInfoDiv>{t('Fetched Videos')}: {mediaList.length}</VideoInfoDiv>
        <VideoInfoDiv>{('Total Videos')}: {durationArray.length}</VideoInfoDiv>
      </VideosInfo>
    </Container>
  );
};

export default VideoPlayer;