import React from 'react';
import styled from 'styled-components';

const LabelTitle = styled.label<{size: string}>`
  font-size: ${({size}) => size};
  font-weight: 800;
`;

interface ITitle {
  text: string
  size?: string
}

const Title = ({text, size='25px'}: ITitle) => {
  return (
    <LabelTitle size={size}>{text}</LabelTitle>
  );
};

export default Title;