import AlertBar from 'components/AlertBar';
import Icon from 'components/Icon';
import Input from 'components/Input';
import { IReducerActions } from 'components/LineUI/LineReducer';
import { DEFAULT_FLOOR_PLAN_AREA_POINTS, IconType, LineUIType, ZONE_LINEUI_CODES } from '../../constants';
import { IAlertMessageType, IFloorPlanConfigs} from 'interface';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineMinus } from 'react-icons/ai';
import styled from 'styled-components';
import Config from '../../configs/config.json';
import { getLineUIColor } from 'utils/utils';
import { AddNewLineWrapper, DeleteIconDiv, HorizontalDivider, IconDiv, LabelWrapper, LineDetailContainer, LineNameWrapper, LinesContainer, LinesListContainer } from '../../pages/configurations/algorithm-config/algoConfigStyles';
import TextArea from 'components/TextArea';

const NoAreaData = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 100%;
  border-radius: 5px;
  border: 1px solid #D0D7F2;
  min-height: 310px;
  min-width: 600px;
`;

const NoAreaText = styled.div`
  max-width: 60%;
  font-size: 14px;
  color: ${({theme})=>theme.text.secondaryText};
  text-align: center;
`;

const AddNewAreaButton = styled.div`
  background: linear-gradient(to top, #1538BD, #7287D7);
  color: #FFFFFF;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
`;

const AreaPointsHandler = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
`;

const PointsToggleDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  justify-content: space-between;
  height: 40px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid lightgrey;
`;

const PointsCountChanger = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border: 1px solid lightgrey;
  border-radius: 6px;
  cursor: pointer;
`;

const PointsCount = styled.div``;

const LineName = styled.div`
  width: 70px;
  white-space: nowrap;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Label = styled.div`
  font-size: 14px;
`;

const ConfigurationForm = styled.div`
  display: flex;
  gap: 20px;
`;

const AdvancedConfigurationSection = styled.div`
  margin-top: 20px;
`;

interface IFloorPlanConfigsProps {
  selectedLayerConfigLength: number;
  selectedIndex: number;
  selectedTab: number;
  onAddNewLine: (cameraName: string, data: IFloorPlanConfigs, currentLength: number)=>void;
  handleRemoveLine?: (index: number, length: number)=>void;
  dispatch: React.Dispatch<IReducerActions>;
  onLineClickCallback?: (id: number) => void;
  setSelectedLayerConfig: React.Dispatch<React.SetStateAction<IFloorPlanConfigs[]>>;
  selectedLayerConfig: IFloorPlanConfigs[];
}

const ZoneConfiguration: React.FC<IFloorPlanConfigsProps> = ({selectedLayerConfigLength, selectedLayerConfig, setSelectedLayerConfig, selectedIndex, dispatch, onAddNewLine, onLineClickCallback=()=>{}, handleRemoveLine=()=>{}, selectedTab}) => {
  const {t} = useTranslation(['common']);
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'});

  const AddNewLine = useCallback(() => {
    if(selectedLayerConfigLength + 1 > Config.algorithmConfigurations.areasLimit){
      setMessage({text: t('configurationLimitWarning').replace('{X}',`${Config.algorithmConfigurations.areasLimit}`), id:'',type:'danger'});
    }else{
      const newLineData: IFloorPlanConfigs = {
        name:  selectedTab === 1 ? ('New Area') : ('New Zone') + (selectedLayerConfigLength + 1),
        pointsCount: 4,
        index: selectedLayerConfigLength + 1,
        lineType: LineUIType.AREA,
        styling: getLineUIColor(ZONE_LINEUI_CODES[selectedTab === 1 ? 0 : selectedLayerConfigLength + 1]) as string,
        points: DEFAULT_FLOOR_PLAN_AREA_POINTS.map((item: { x: number; y: any; }) => ({x: item.x + (selectedLayerConfigLength * 20), y: item.y}))
      };
      onAddNewLine('', newLineData, selectedLayerConfigLength);
      setSelectedLayerConfig(prev => [...prev, newLineData]);
    }
  },[onAddNewLine, selectedLayerConfigLength, selectedTab, setSelectedLayerConfig, t]);
   
  const onRemovePoint = useCallback((currentPointCount: number)=>{
    if(currentPointCount > 3){
      setSelectedLayerConfig((prev: IFloorPlanConfigs[]) => {
        const selectedLayerConfig = [...prev];
        const selectedAreaConfig = JSON.parse(JSON.stringify(selectedLayerConfig[selectedIndex - 1])); 
        selectedAreaConfig.pointsCount = selectedAreaConfig.pointsCount - 1;
        selectedLayerConfig[selectedIndex - 1] = selectedAreaConfig;
        return selectedLayerConfig;
      });
      dispatch({
        type: 'REMOVE_POINT',
        index: selectedIndex-1,
      });
    }else{
      setMessage({text: t('Minimum 3 points required!'), id:'',type:'danger'});
    }
  },[dispatch, t, setSelectedLayerConfig, selectedIndex]);

  const onAddPoint = useCallback(()=>{
    let isLimitExceeded = false;
    setSelectedLayerConfig((prev: IFloorPlanConfigs[]) => {
      const selectedLayerConfig = [...prev];
      const selectedAreaConfig = JSON.parse(JSON.stringify(selectedLayerConfig[selectedIndex -1])); 
      if(selectedAreaConfig.pointsCount + 1 > Config.algorithmConfigurations.areaPointsLimit){
        setMessage({text: t('pointsLimitWarning').replace('{X}',`${Config.algorithmConfigurations.areaPointsLimit}`), id:'',type:'danger'});
        isLimitExceeded = true;
      }else{
        selectedAreaConfig.pointsCount = selectedAreaConfig.pointsCount + 1;
      }
      selectedLayerConfig[selectedIndex -1] = selectedAreaConfig;
      if(!isLimitExceeded){
        dispatch({
          type: 'ADD_POINT',
          index: selectedIndex-1,
        });
      }
      return selectedLayerConfig;
    });
  },[dispatch, setSelectedLayerConfig, t, selectedIndex]);

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedLayerConfig((prev: IFloorPlanConfigs[]) => {
      const selectedLayerConfig = [...prev];
      const selectedAreaConfig = JSON.parse(JSON.stringify(selectedLayerConfig[selectedIndex - 1])); 
      selectedAreaConfig[e.target.name] = e.target.name !== 'name' ? parseInt(e.target.value) : e.target.value;
      selectedLayerConfig[selectedIndex - 1] = selectedAreaConfig;
      return selectedLayerConfig;
    });
    if(e.target.name === 'name'){
      dispatch({
        type: 'RENAME_SET',
        index: selectedIndex - 1,
        data: {
          areaName: e.target.value
        }
      });
    }
  },[setSelectedLayerConfig, dispatch, selectedIndex]);

  const handleDescriptionChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>)=>{
    setSelectedLayerConfig((prev: IFloorPlanConfigs[]) => {
      const selectedLayerConfig = [...prev];
      const selectedLineConfig = JSON.parse(JSON.stringify(selectedLayerConfig[selectedIndex - 1])); 
      selectedLineConfig[e.target.name] = e.target.value;
      selectedLayerConfig[selectedIndex - 1] = selectedLineConfig;
      return selectedLayerConfig;
    });
  },[setSelectedLayerConfig, selectedIndex]);

  return (
    <ConfigurationForm>
      { message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
      {selectedIndex === 0 ? 
        <NoAreaData>
          <NoAreaText>{selectedTab === 2 ? t('No Zone have been configured yet, Please add New Zone') :t('No area have been configured yet, please add new area')}</NoAreaText>
          <AddNewAreaButton onClick={ AddNewLine }  >{selectedTab === 2 ? t('Add Zone'):  selectedTab === 3 ? t('Add Camera') :t('Add Area')}</AddNewAreaButton>
        </NoAreaData>:
        <>
          <LinesContainer>
            { selectedTab === 2 &&<AddNewLineWrapper>
              <LabelWrapper>
                <Icon icon='Line' size={20} />
                <Label>{selectedTab === 2 ? t('Zone') :t('Areas')}</Label>
              </LabelWrapper>
              { selectedTab === 2 && <IconDiv title={t('Add New Areas')} onClick={AddNewLine}>
                <Icon icon='Plus' size={16} />
              </IconDiv>}
            </AddNewLineWrapper>}
            {selectedIndex !== 0 && selectedTab === 2 &&
            <LinesListContainer>
              {(selectedLayerConfig as IFloorPlanConfigs[])?.map((line, index) => (
                <>
                  <LineNameWrapper isSelected={selectedIndex === index + 1} onClick={() => onLineClickCallback(index)}>
                    <div>{index + 1}.</div>
                    <LineName>{line?.name}</LineName>
                  </LineNameWrapper>
                  {index !== selectedLayerConfig?.length - 1 && <HorizontalDivider />}
                </>
              ))}
            </LinesListContainer>}
          </LinesContainer>
          <LineDetailContainer>
            { selectedTab !== 3 && <DeleteIconDiv onClick={() => handleRemoveLine(selectedIndex - 1, selectedLayerConfigLength)}>
              <Icon icon='Delete' size={16} />
            </DeleteIconDiv>}
            { selectedTab !== 3 && <Input id='name' type='text' name='name' label={selectedTab === 2 ? t('Zone Name') :t('Area Name')} maxLength={16} value={selectedLayerConfig[selectedIndex - 1]?.name} onChange={handleInputChange} />}
            <AreaPointsHandler>
              <Label>{selectedTab === 3 ? t('Cameras') : t('Points')}</Label>
              <PointsToggleDiv>
                <PointsCountChanger onClick={() => onRemovePoint(selectedLayerConfig[selectedIndex - 1]?.pointsCount as number)}><AiOutlineMinus /></PointsCountChanger>
                <PointsCount>{selectedLayerConfig[selectedIndex - 1]?.pointsCount}</PointsCount>
                <PointsCountChanger onClick={onAddPoint}><Icon icon='Add' size={20} /></PointsCountChanger>
              </PointsToggleDiv>
            </AreaPointsHandler>
            { selectedTab !== 3 && <TextArea label={t('Description')} id='description' name='description' rows={10} cols={30} disabled={false}  maxLength={65535} value={selectedLayerConfig[selectedIndex - 1]?.description ? selectedLayerConfig[selectedIndex - 1]?.description : '' } onChange={(e) => handleDescriptionChange(e)} height='54px' />}
            <AdvancedConfigurationSection>
            </AdvancedConfigurationSection>
          </LineDetailContainer>
        </>
      }
    </ConfigurationForm>
  );
};

export default ZoneConfiguration;