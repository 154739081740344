import AlertBar from 'components/AlertBar';
import Button from 'components/Button';
import ButtonWithIcon from 'components/ButtonWithIcon';
import Input from 'components/Input';
import SelectField from 'components/SelectField';
import TextArea from 'components/TextArea';
import { IconType, ImageBasePath, OBJECT_MAX_IMAGES, PRODUCT_IMAGES_URL, PROPERTY_TYPE, SERVER_URL, StatusCode } from '../constants';
import { IAlertMessageType, IBreadcrum, IOption } from 'interface';
import React, {useState, useEffect} from 'react';
import { GET_OBJECT } from 'api_configs/queries';
import styled from 'styled-components';
import { IoCloudUploadOutline } from 'react-icons/io5';
import { GET_OBJECT_TYPE } from 'api_configs/queries';
import { useMutation, useQuery } from '@apollo/client';
import { useLocation, useParams } from 'react-router';
import { useHistory } from 'react-router';
import { CREATE_NEW_OBJECT, EDIT_OBJECT, IMAGE_UPLOAD } from 'api_configs/mutations';
import { useTranslation } from 'react-i18next';
import { useHeader } from 'contexts/HeaderContext';
import Icon from 'components/Icon';
import Label from 'components/Label';
import { CircularProgress } from '@mui/material';
import ButtonWithLoading from 'components/ButtonWithLoading';

const LabelWrapper = styled.div`
  margin-bottom: 14px;
`;

const AllFieldsWrapper = styled.div`
  max-width: 1000px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
`;

const SingleImageAttributeWrapper = styled.div<{isBorder: boolean}>`
  width: 450px;
  height: 148px;
  border: ${({isBorder}) => isBorder ? '1px solid #ccc': 'none'};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  border-radius: 5px;
`;

const AddObjectWrapper = styled.div`
  max-width: 965px;
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
`;

const InputWrapper = styled.div`
  margin-top: 16px;
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const LeftSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  margin: 20px;
`;

const SelectFieldWrapper = styled.div`
  margin-top: 15px;
`;

const ImageSectionWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  height: 195px;
`;

const TextAreaWrapper = styled.div`
  margin-top: 15px;
`;

const ImagePreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;  
  justify-content: end;
`;

const ImagePreview = styled.img`
  width: 160px;
  height: 160px;
  margin-bottom: 5px;
`;


const NoImageText = styled.div`
  margin-top: 5px;
  margin-bottom: 15px;
`;

const RequiredStatus = styled.span`
  margin-left: 5px;
  color: red;
`;

const ImagesSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  max-width: 980px;
  position: relative;
`;

const UploadImageBox = styled.div`
  display: flex;
  width: 450px;
  height: 260px;
  flex-wrap: wrap;
  border-radius: 5px;
  border: 1px solid rgba(135, 174, 183, 0.188);
  justify-content: space-between;
  margin-top: 12px;
  
  @media (max-width: 768px) {
    width: 530px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 450px;
`;

const ImageText = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5px;
  font-style: italic;
`;

const LabelText = styled.span``;

const Separator = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
`;

const ThumbnaiImagesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 6px 5px;
  border-radius: 5px;
  margin-top: 10px;
  border: 1px solid rgba(135, 174, 183, 0.188);
  gap: 18px;
  width: 450px;
  height: 314px;
`;

const HiddenInput = styled.input`
  display: none;
`;

const ButtonsAndThumbnailWrapper = styled.div``;

const UploaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -50px;
  margin-left: 20px;
  position: absolute;
  top: 58px;
  right: 60px;
`;

const ObjectImagesWrapper = styled.div`
  display: flex;
  width: fit-content;
  height: fit-content;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  padding: 2px;
  margin-top: 4px;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 15px;

  >:nth-child(2) {
    margin-top: -10px;
  }
`;

const CloseWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 99999;
  position: absolute;
  top: -8px;
  right: -12px;
  border-radius: 50%;
  background-color: ${({theme})=>theme.backgroundColor};
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.4);
`;

const OnHoverButtons = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 15px;
  margin-top: 28px;
  z-index: 6;

  > button {
    min-height: 20px;
    min-width: 55px;
  }
`;

const SingleThumbnaiWrapper = styled.div<{selected: boolean}>`
  padding: 2px;
  border: ${({selected}) => selected ? '2px solid #0d35d5': 'none'};
`;

const ProductImageName = styled.label`
  font-size: 12px;
  width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const NoImagesText = styled.div`
  display: flex;
  align-items: center; 
  margin-left: 170px;
`;

const ImagePreviewIconsWrapper = styled.div`
  cursor: pointer;
`;

const Thumbnail = styled.img<{blurred: boolean}>`
  width: 80px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  opacity: ${({blurred}) => blurred ? '0.2': 1};
`;

const ImagePreviewIcons = styled.div`
  display: flex;
  width: 160px;
  gap: 8px;
  justify-content: end;
`;

const BrowserButton = styled.div`
  color: #435FCA;
  font-size: 16px;
  cursor: pointer;
`;

const UploadMoreImagesButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;

  > button {
    width: 100px;
  }
  > button > div {
    margin-right: -8px;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 1000px;
  min-height: 300px;
  gap: 10px;
`;

const Loading = styled.span``;

interface IParams {
  objectId: string,
  editId: string
}

interface IEditObject {
  id?: string,
  objectProperties: string[] | any
}

interface IPayloadImage {
  filename: string,
  base64: string,
  featureExtraction?: string
}

interface IFinalImages {
  isNew: boolean,
  fileData: string | File,
  isUploaded: boolean,
  base64?: string
}

interface ICreateObjectPayload {
  objectTypeId: number;
  objectProperties: { [key: string]: string | number };
  uniqueAttr?: string | number;
}

interface IEditObjectPayload {
  id: number,
  objectTypeId: number,
  objectProperties: any,
  deletedImages: string[],
  uniqueAttr?: string
}

interface IProperty {
  displayName: string,
  featureExtraction?: string,
  isRequired: boolean,
  key: string,
  maxImages?: number,
  propertyType: string,
  sequence: string,
  sequenceId: number,
  isUnique?: boolean,
  isTextOnly?: boolean,
  maxTextLength?: number,
  selectOptions?: string[],
  rows?: number,
  cols?: number
}

const AddObject = () => {
  const objectId: IParams = useParams();
  const objectTypeId = objectId.objectId;
  const editId = objectId.editId;
  const {t} = useTranslation(['common']);
  const [leftSection, setLeftSection] = useState<IProperty[]>([]);
  const [rightSection, setRightSection] = useState<IProperty[]>([]);
  const { pathname } = useLocation();
  const {updateHeaderTitle} = useHeader();
  const [isEditObject] = useState(pathname.includes('/edit/'));
  const [allTextInputValues, setAllTextInputValues] = useState<any>({});
  const [toEditObjectInfo, setToEditObjectInfo] = useState<IEditObject>();
  const [singleObject, setSingleObject] = useState<any>({});
  const [deletedImages, setDeletedImages] = useState<string[]>([]);
  const [editObjectMutation] = useMutation(EDIT_OBJECT);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: ''});
  const [createNewObjectMutation] = useMutation(CREATE_NEW_OBJECT);
  const {data: getEditObject, loading: getEditObjectLoading, refetch: getEditObjectRefetch} = useQuery(GET_OBJECT, { variables: {objectId: Number(editId)} });
  const [allSingleImageAttributes, setAllSingleImageAttributes] = useState<any>({});
  const [allMultiImageAttributes, setAllMultiImageAttributes] = useState<any>({});
  const [imageUpload] = useMutation(IMAGE_UPLOAD);
  const { data, loading, refetch: getObjectTypeRefetch } = useQuery(GET_OBJECT_TYPE, {variables: {id: Number(objectTypeId)}});
  const history = useHistory();

  useEffect(()=>{
    const cameraPageBreadCrumb: IBreadcrum[] = [
      {label: t('Objects'), to: `/objects?selectedTab=${objectTypeId}`}
    ];
    updateHeaderTitle(isEditObject ? `Edit ${singleObject?.name ? singleObject?.name : ''}` :`Add ${singleObject?.name}`, 'Object', cameraPageBreadCrumb);
  },[updateHeaderTitle, singleObject, isEditObject, objectTypeId, editId, t]);

  useEffect(() => {
    if(!loading) {
      if(data) {
        setSingleObject(data?.getObjectType?.result);
      }
    }
  }, [loading, data]);

  useEffect(() => {
    getObjectTypeRefetch();
  }, [getObjectTypeRefetch]);

  useEffect(() => {
    if(isEditObject) {
      getEditObjectRefetch();
    }
  }, [isEditObject, getEditObjectRefetch]);

  useEffect(() => {
    if(isEditObject) {
      if(!getEditObjectLoading) {
        if(getEditObject?.getObject.result) {
          setToEditObjectInfo(getEditObject?.getObject.result);
        }
      }
    }
  }, [isEditObject, getEditObjectLoading, getEditObject]);

  useEffect(() => {
    const leftElements: IProperty[] = [];
    const rightElements: IProperty[] = [];
  
    singleObject?.properties?.forEach((element: IProperty) => {
      const sequenceId = element.sequence;
      const section = sequenceId.charAt(0);
  
      if (section === 'L') {
        leftElements.push({ ...element, sequenceId: parseInt(sequenceId.substring(1))});
      } else if (section === 'R') {
        rightElements.push({ ...element, sequenceId: parseInt(sequenceId.substring(1))});
      }
    });
  
    leftElements.sort((a, b) => a.sequenceId - b.sequenceId);
    rightElements.sort((a, b) => a.sequenceId - b.sequenceId);

    setLeftSection(leftElements);
    setRightSection(rightElements);
  }, [singleObject]);

  useEffect(() => {
    const initialValues: any = {};
    const initialImageValues: any = {};
    const initialMultiImageValues: any = {};
    singleObject?.properties?.forEach((property: any) => {
      switch (property.propertyType) {
      case PROPERTY_TYPE.Text:
      case PROPERTY_TYPE.TextArea:
      case PROPERTY_TYPE.Image:
      case PROPERTY_TYPE.SelectField:
        initialValues[property.key] = isEditObject ? toEditObjectInfo?.objectProperties[property.key] : '';
        if (property.propertyType === PROPERTY_TYPE.Image) {
          initialImageValues[property.key] = isEditObject ? toEditObjectInfo?.objectProperties[property.key] : '';
        }
        break;
      case PROPERTY_TYPE.MultiImage: {
        initialValues[property.key] = {images: [], defaultImage: ''};
        if(isEditObject) {
          if(toEditObjectInfo?.objectProperties[property.key]?.images === undefined) {
            initialMultiImageValues[property.key] = {images: [], defaultImage: '', selectedImage: -1, deletedImages: [], newImages: [], hoveredIndex: -1};
          }else {
            const allImages = toEditObjectInfo?.objectProperties[property.key]?.images?.map((image: string) => {return { isNew: false, fileData: image, isUploaded: true };});
            const defaultImageIndex = toEditObjectInfo?.objectProperties[property.key]?.images?.indexOf(toEditObjectInfo?.objectProperties[property.key]?.defaultImage);
            initialValues[property.key] = {...toEditObjectInfo?.objectProperties?.[property.key]};
            initialMultiImageValues[property.key] = {...toEditObjectInfo?.objectProperties?.[property.key], images: allImages, selectedImage: defaultImageIndex, deletedImages: [], newImages: [], hoveredIndex: -1};
          }}else {
          initialMultiImageValues[property.key] = {images: [], defaultImage: '', selectedImage: -1, deletedImages: [], newImages: [], hoveredIndex: -1};
        }
        break;
      }
      default:
        break;
      }});
    if(Object.keys(initialImageValues)?.length !== 0) {
      setAllSingleImageAttributes(initialImageValues);
    }
    if(Object.keys(initialMultiImageValues)?.length !== 0) {
      setAllMultiImageAttributes(initialMultiImageValues);
    }
    setAllTextInputValues(initialValues);
  }, [singleObject, toEditObjectInfo, isEditObject]);

  const handleInputFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = event.target.id;
    let value: string = event.target.value;
    value = value.slice(0, 254);

    setAllTextInputValues((prevState: any) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>, id: string, displayName: string) => {
    const pastedText = event.clipboardData.getData('text/plain');
    if (pastedText.length >= 256) {
      setMessage({text: t(`${displayName} text limit exceeded`), id: id, type:'danger'});
    }
    const trimmedText = pastedText.slice(0, 256);
    setAllTextInputValues((prevState: any) => ({
      ...prevState,
      [id]: trimmedText,
    }));
  };

  const handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const id = event.target.id;
    const value: string = event.target.value;
    setAllTextInputValues((prevState: any) => ({
      ...prevState,
      [id]: value
    }));
  };

  const triggerFileInput = (key: string) => {
    const input = document.getElementById(key) as HTMLInputElement | null;
    if (input) {
      input.click();
    }
  };

  const handleSelectFieldChange = (value: string, id: string) => {
    setAllTextInputValues((prevState: any) => ({
      ...prevState,
      [id]: value
    }));  
  };

  const handleImageUpload = async (fileList: FileList | null, property: any) => {
    if(allMultiImageAttributes[property.key]?.images?.length === 0) {
      setAllMultiImageAttributes((prevState: any) => ({
        ...prevState,
        [property.key]: {
          ...prevState[property.key],
          selectedImage: 0
        }
      }));
    }
    if(!isEditObject) {
      setAllMultiImageAttributes((prevState: any) => ({
        ...prevState,
        [property.key]: {
          ...prevState[property.key],
          selectedImage: 0
        }
      }));
    }
    if (fileList) {
      const fileArray = Array.from(fileList);
      if(!isEditObject) {
        if(allMultiImageAttributes[property.key]?.images?.length === 0) {
          setAllMultiImageAttributes((prevState: any) => ({
            ...prevState,
            [property.key]: {
              ...prevState[property.key],
              defaultImage: `images/${fileArray[0]?.name}`
            }
          }));
          setAllTextInputValues((prevState: any) => ({
            ...prevState,
            [property.key]: {
              ...prevState[property.key],
              defaultImage: `images/${fileArray[0]?.name}`
            }
          }));
        }
      }else {
        if(allMultiImageAttributes[property.key]?.images?.length === 0) {
          setAllMultiImageAttributes((prevState: any) => ({
            ...prevState,
            [property.key]: {
              ...prevState[property.key],
              defaultImage: `images/${fileArray[0]?.name}`
            }
          }));
          setAllTextInputValues((prevState: any) => ({
            ...prevState,
            [property.key]: {
              ...prevState[property.key],
              defaultImage: `images/${fileArray[0]?.name}`
            }
          }));
        }
      }

      const readFileAsBase64 = (file: File) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (event) => {
            resolve(event?.target?.result);
          };
          reader.onerror = (error) => {
            reject(error);
          };
          reader.readAsDataURL(file);
        });
      };
      const updatedImages = await Promise.all(fileArray.map(async (file) => {
        const base64Image = await readFileAsBase64(file);
        return { isNew: true, fileData: file, isUploaded: false, base64: base64Image };
      }));
      if(allMultiImageAttributes[property.key]?.images?.length + updatedImages.length <= Number(property.maxImages)) {
        const existingImages = allMultiImageAttributes[property.key]?.images?.map((existingImage: any) => existingImage);
        const fileNames = fileArray.map((file) => `images/${file.name}`);
        const newImages = updatedImages.map((updatedImage) => updatedImage);
        const allImages = existingImages.concat(newImages);
        
        setAllMultiImageAttributes((prevState: any) => ({
          ...prevState,
          [property.key]: {
            ...prevState[property.key],
            images: allImages
          }
        }));
        setAllTextInputValues((prevState: any) => ({
          ...prevState,
          [property.key]: {
            ...prevState[property.key],
            images: prevState[property.key].images
              ? [...prevState[property.key].images, ...fileNames]
              : fileNames
          }
        }));
      } else {
        setMessage({text: `Maximum of ${property.maxImages} are allowed for ${property.displayName}`, id:property.key, type:'danger'});
      }
    }
  };

  const handleMultiImageFileChange = (event: React.ChangeEvent<HTMLInputElement>, property: any) => {
    const fileList = event.target.files;
    handleImageUpload(fileList, property);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, property: any) => {
    const fileChangeInputid = event.target.id;
    const filename = event.target.files?.item(0)?.name;
    setAllTextInputValues({...allTextInputValues, [fileChangeInputid] : filename});
    const reader = new FileReader();
    reader.onload = function (event) {
      const base64Image = event?.target?.result;
      setAllSingleImageAttributes({...allSingleImageAttributes, [fileChangeInputid] : {filename: filename, base64: base64Image as string, featureExtraction: property.featureExtraction}});
    };
    reader.readAsDataURL(event.target.files?.item(0)!);
  };

  const handleAddObject = async () => {
    const requiredFields = singleObject?.properties?.filter((object: IProperty) => object.isRequired === true);
    const uniqueAttributeField = singleObject?.properties?.filter((object: IProperty) => object.isUnique === true);
    const ActualValues = Object.entries(allTextInputValues);
    const matchedObjects = requiredFields.filter((individualObject: any) => {
      return ActualValues.some((object: any) => individualObject.key === object[0]);
    });
    const emptyFields = matchedObjects.filter((matchedObject: any) => allTextInputValues[matchedObject.key] === '' || JSON.stringify(allTextInputValues[matchedObject.key]) === '{}' || allTextInputValues[matchedObject.key]?.images?.length === 0);
    if(emptyFields.length > 0) {
      if(emptyFields[0].propertyType === PROPERTY_TYPE.Text || emptyFields[0].propertyType === PROPERTY_TYPE.TextArea || emptyFields[0].propertyType === PROPERTY_TYPE.SelectField) {
        setMessage({text: `${emptyFields[0].displayName} is required`, id:emptyFields[0].key, type:'danger'});
        return;
      }
      if(emptyFields[0].propertyType === PROPERTY_TYPE.Image) {
        setMessage({text: `Please upload image for ${emptyFields[0].displayName}`, id:'',type:'danger'});
        return;
      }
      if(emptyFields[0].propertyType === PROPERTY_TYPE.MultiImage) {
        setMessage({text: `Please upload images for ${emptyFields[0].displayName}`, id:'',type:'danger'});
        return;
      }
    }
    const imagesAttributes = Object.entries(allSingleImageAttributes).filter((entry) => entry[1] !== '');
    const imageNames = imagesAttributes.map((everyImage: any) => `images/${(everyImage[1] as IPayloadImage).filename}`);
    if(imageNames.length === 0) {
      imageNames.push('images/defaultImage');
    }
    const multiImagesAttributes = Object.entries(allMultiImageAttributes).map((everyObject) => everyObject[1]);
    const actualMultiImageData: any[] = [];
    // @ts-ignore (Temporarily I have put this, and I am working on edit of multiimage)
    multiImagesAttributes.map((imagesSection) => imagesSection.images.map((image) => actualMultiImageData.push(image)));
    const createObjectPayload: ICreateObjectPayload = {objectTypeId: Number(objectTypeId), objectProperties: allTextInputValues};
    if(uniqueAttributeField.length > 0) {
      createObjectPayload.uniqueAttr = allTextInputValues[uniqueAttributeField[0].key];
    }
    try{
      setButtonDisabled(true);
      const data = await createNewObjectMutation({variables: {payload: createObjectPayload}});
      if(data && data?.data?.createObject?.statusCode === StatusCode.SUCCESS){
        setMessage({text: t('Object added successfully'), id:'',type:'success'});
        setButtonDisabled(false);
        const productImages = await Promise.all(
          imagesAttributes
            .map(async newImage => {
              return {
                path: `${data?.data?.createObject?.result}/images`,
                filename: (newImage[1] as IPayloadImage).filename,
                data: ((newImage[1] as IPayloadImage).base64 as string).split(',')[1],
                featureExtraction: ((newImage[1] as IPayloadImage).featureExtraction as string)
              };
            })
        );
        const allMultiImageAttributeValues = await Promise.all(actualMultiImageData.map(async (multiImageAttribute) => { return {
          path: `${data?.data?.createObject?.result}/images`,
          filename: multiImageAttribute.fileData.name,
          data: (multiImageAttribute.base64 as string).split(',')[1],
          featureExtraction: 'None'
        };}));
        const allImages = productImages.concat(allMultiImageAttributeValues);
        allImages.map(async (image) => {
          if(image.featureExtraction && image.featureExtraction !== 'None')
            await imageUpload({variables: {payload: {path: image.path, filename: image.filename, data: image.data, featureExtractor: image.featureExtraction}}});
          else 
            await imageUpload({variables: {payload: {path: image.path, filename: image.filename, data: image.data}}});
          return 0;
        });
        setTimeout(() => {
          history.push(`/objects?selectedTab=${objectTypeId}`);
        }, 2000);
      } else if(data && data?.data?.createObject?.statusCode === StatusCode.DUPLICATE_ENTRY) {
        setMessage({text: `${singleObject?.name} with ${uniqueAttributeField[0].displayName} ${allTextInputValues[uniqueAttributeField[0].key]} already exists`, id:'',type:'danger'});
      }
    } catch{
      console.log('error');
    }finally {
      setButtonDisabled(false);
    }
  };

  const handleRemoveImage = (index: number, property: any) => {
    if(allMultiImageAttributes[property.key].selectedImage === index) {
      if(index > 0){
        if(!isEditObject) {
          setAllMultiImageAttributes((prevState: any) => ({
            ...prevState,
            [property.key]: {
              ...prevState[property.key],
              defaultImage: `images/${allMultiImageAttributes[property.key].images[index].fileData.name}`
            }
          }));
          setAllTextInputValues((prevState:any) => {
            return {
              ...prevState,
              [property.key]: {
                ...prevState[property.key],
                defaultImage: `images/${allMultiImageAttributes[property.key].images[index].fileData.name}`
              }
            };
          });
          setAllMultiImageAttributes((prevState: any) => ({
            ...prevState,
            [property.key]: {
              ...prevState[property.key],
              selectedImage: index-1
            }
          }));
        }else{
          setAllMultiImageAttributes((prevState: any) => ({
            ...prevState,
            [property.key]: {
              ...prevState[property.key],
              defaultImage: `${allMultiImageAttributes[property.key].images[index].fileData}`
            }
          }));
          setAllTextInputValues((prevState:any) => {
            return {
              ...prevState,
              [property.key]: {
                ...prevState[property.key],
                defaultImage: `${allTextInputValues[property.key].images[index-1]}`
              }
            };
          });
          setAllMultiImageAttributes((prevState: any) => ({
            ...prevState,
            [property.key]: {
              ...prevState[property.key],
              selectedImage: index-1
            }
          }));
        }
        if(!isEditObject)
          setDeletedImages(prevArray => [...prevArray, `images/${allMultiImageAttributes[property.key].images[index-1].fileData.name}`]);
        else 
          setDeletedImages(prevArray => [...prevArray, `${allTextInputValues[property.key].images[index-1]}`]);
      }else{
        setAllMultiImageAttributes((prevState: any) => ({
          ...prevState,
          [property.key]: {
            ...prevState[property.key],
            defaultImage: allMultiImageAttributes[property.key].images[index]
          }
        }));
        setAllMultiImageAttributes((prevState: any) => ({
          ...prevState,
          [property.key]: {
            ...prevState[property.key],
            selectedImage: index
          }
        }));
        if(!isEditObject) {
          setAllTextInputValues((prevState:any) => {
            return {
              ...prevState,
              [property.key]: {
                ...prevState[property.key],
                defaultImage: `images/${allMultiImageAttributes[property.key].images[index].fileData.name}`
              }
            };
          });
        }else{
          setAllTextInputValues((prevState:any) => {
            return {
              ...prevState,
              [property.key]: {
                ...prevState[property.key],
                defaultImage: `${allTextInputValues[property.key].images[index+1]}`
              }
            };
          });
        }
      }
      if(!isEditObject)
        setDeletedImages(prevArray => [...prevArray, `images/${allMultiImageAttributes[property.key]?.images[index-1]?.fileData?.name}`]);
      else 
        setDeletedImages(prevArray => [...prevArray, `${allTextInputValues[property.key]?.images[index]}`]);
    }else {
      setDeletedImages(prevArray => [...prevArray, `${allTextInputValues[property.key]?.images[index]}`]);
    }
    setAllMultiImageAttributes((prevState:any) => {
      const updatedImages = [...prevState[property.key].images];  
      updatedImages.splice(index, 1);
      return {
        ...prevState,
        [property.key]: {
          ...prevState[property.key],
          images: updatedImages
        }
      };
    });
    setAllTextInputValues((prevState:any) => {
      const updatedImages = [...prevState[property.key].images];
      updatedImages.splice(index, 1);
      return {
        ...prevState,
        [property.key]: {
          ...prevState[property.key],
          images: updatedImages
        }
      };
    });
  };

  const handleSelectedImageChange = (value:number, property: any) => {
    setAllMultiImageAttributes((prevState: any) => ({
      ...prevState,
      [property.key]: {
        ...prevState[property.key],
        selectedImage: Number(value)
      }
    }));
    setAllMultiImageAttributes((prevState: any) => ({
      ...prevState,
      [property.key]: {
        ...prevState[property.key],
        defaultImage: `images/${allMultiImageAttributes[property.key]?.images[Number(value)]?.fileData.name}`
      }
    }));
    if(!isEditObject) {
      setAllTextInputValues((prevState: any) => ({
        ...prevState,
        [property.key]: {
          ...prevState[property.key],
          defaultImage: `images/${allMultiImageAttributes[property.key]?.images[Number(value)]?.fileData.name}`
        }
      }));
    }else {
      setAllTextInputValues((prevState: any) => ({
        ...prevState,
        [property.key]: {
          ...prevState[property.key],
          defaultImage: `${allTextInputValues[property.key]?.images[Number(value)]}`
        }
      }));
    }
  };

  const handleEditObject = async () => {
    const requiredFields = singleObject?.properties?.filter((object: IProperty) => object.isRequired === true);
    const uniqueAttributeField = singleObject?.properties?.filter((object: IProperty) => object.isUnique === true);
    const ActualValues = Object.entries(allTextInputValues);
    const matchedObjects = requiredFields.filter((individualObject: any) => {
      return ActualValues.some((object: any) => individualObject.key === object[0]);
    });
    const emptyFields = matchedObjects.filter((matchedObject: any) => allTextInputValues[matchedObject.key] === '' || JSON.stringify(allTextInputValues[matchedObject.key]) === '{}' || allTextInputValues[matchedObject.key]?.images?.length === 0);
    if(emptyFields.length > 0) {
      if(emptyFields[0].propertyType === PROPERTY_TYPE.Text || emptyFields[0].propertyType === PROPERTY_TYPE.TextArea || emptyFields[0].propertyType === PROPERTY_TYPE.SelectField) {
        setMessage({text: `${emptyFields[0].displayName} is required`, id:emptyFields[0].key, type:'danger'});
        return;
      }
      if(emptyFields[0].propertyType === PROPERTY_TYPE.Image) {
        setMessage({text: `Please upload image for ${emptyFields[0].displayName}`, id:'',type:'danger'});
        return;
      }
      if(emptyFields[0].propertyType === PROPERTY_TYPE.MultiImage) {
        setMessage({text: t(`Please upload images for ${emptyFields[0].displayName}`), id:'',type:'danger'});
        return;
      }
    }
    setButtonDisabled(true);
    const editedimagesAttributes = Object.entries(allSingleImageAttributes).filter((entry) => typeof entry[1] !== 'string');
    const multiImagesAttributes = Object.entries(allMultiImageAttributes).map((everyObject) => everyObject[1]);
    const actualMultiImageData: any[] = [];
    if(multiImagesAttributes?.length > 0)
      // @ts-ignore (Temporarily I have put this, and I am working on edit of multiimage)
      multiImagesAttributes?.map((imagesSection) => imagesSection?.images?.map((image) => image?.isNew && actualMultiImageData.push(image)));
    const editObjectPayload: IEditObjectPayload = {id: Number(editId), objectTypeId: Number(objectTypeId), objectProperties: allTextInputValues, deletedImages: deletedImages};
    try{
      if(uniqueAttributeField.length > 0) {
        editObjectPayload.uniqueAttr = allTextInputValues[uniqueAttributeField[0].key];
      }
      const data = await editObjectMutation({variables: {payload: editObjectPayload}});
      if(data && data?.data?.editObject?.statusCode === StatusCode.SUCCESS){
        setMessage({text: 'Object details updated successfully', id:'', type:'success'});
        setButtonDisabled(false);
        const productImages = await Promise.all(
          editedimagesAttributes
            .map(async newImage => {
              return {
                path: `${editId}/images`,
                filename: (newImage[1] as IPayloadImage).filename,
                data: ((newImage[1] as IPayloadImage).base64 as string).split(',')[1]
              };
            })
        );
        const MultiImageUploadData = await Promise.all(
          actualMultiImageData
            .map(async newImage => {
              return {
                path: `${editId}/images`,
                filename: newImage.fileData.name,
                data: (newImage.base64 as string).split(',')[1]
              };
            })
        );
        productImages.map(async (image) => {
          await imageUpload({variables: {payload: {path: image.path, filename: image.filename, data: image.data, featureExtractor: 'PERSON'}}});
          return 0;
        });
        MultiImageUploadData.map(async (image) => {
          await imageUpload({variables: {payload: {path: image.path, filename: image.filename, data: image.data, featureExtractor: 'PERSON'}}});
          return 0;
        });
        setTimeout(() => {
          history.push(`/objects?selectedTab=${objectTypeId}`);
        }, 2000);
      } else if(data && data?.data?.editObject?.statusCode === StatusCode.DUPLICATE_ENTRY) {
        setMessage({text: `${singleObject?.name} with ${uniqueAttributeField[0].displayName} ${allTextInputValues[uniqueAttributeField[0].key]} already exists`, id:'',type:'danger'});
      }
    }catch(e) {
      console.log(e, 'error');
    }finally {
      setButtonDisabled(false);
    }
  };

  if(loading) {
    return <LoadingContainer>
      <CircularProgress />
      <Loading>{t('Loading')}</Loading>
    </LoadingContainer>;
  }

  return (
    <Wrapper>
      <AllFieldsWrapper>
        {
          message && <AlertBar message={message.text} type={message.type as IconType} setMessage={setMessage} />
        }
        <LeftSection>
          <LeftSectionWrapper>
            {
              leftSection?.map((property: IProperty) => {
                if (property.propertyType === PROPERTY_TYPE.Text) {
                  return (
                    <InputWrapper>
                      <Input
                        key={property.key}
                        type='text'
                        id={property.key}
                        label={property.displayName}
                        value={allTextInputValues[property.key]}
                        isRequired={property.isRequired}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputFieldChange(e)}
                        onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => handlePaste(e, property?.key, property.displayName)}
                      />
                    </InputWrapper>
                  );
                } else if(property.propertyType === PROPERTY_TYPE.TextArea) {
                  return <TextAreaWrapper>
                    <TextArea rows={property.rows as number} isRequired={property.isRequired} cols={property.cols as number} id={property.key} label={property.displayName} value={allTextInputValues[property.key]} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleTextAreaChange(e)} onPaste={(e: React.ClipboardEvent<HTMLTextAreaElement>) => handlePaste(e, property?.key, property.displayName)}/>
                  </TextAreaWrapper>;
                } else if(property.propertyType === PROPERTY_TYPE.Image) {
                  return <ImageSectionWrapper>
                    <LabelWrapper>{property.displayName}{property.isRequired && <RequiredStatus>*</RequiredStatus>}</LabelWrapper>  
                    <SingleImageAttributeWrapper isBorder={allSingleImageAttributes[property.key] === ''}>
                      {allSingleImageAttributes[property.key] === '' ? <IoCloudUploadOutline size={34}/> :<ImagePreviewWrapper><ImagePreviewIcons><ImagePreviewIconsWrapper onClick={() => triggerFileInput(property.key)}><Icon icon='Edit' size={30}/></ImagePreviewIconsWrapper><ImagePreviewIconsWrapper onClick={() => { setAllTextInputValues((prevState: any) => ({...prevState, [property.key]: ''}));setAllSingleImageAttributes((prevState: any) => ({...prevState, [property.key]: ''}));}}><Icon icon='Delete' color='danger' size={30}/></ImagePreviewIconsWrapper></ImagePreviewIcons><ImagePreview src={typeof allSingleImageAttributes[property.key] === 'string' ? PRODUCT_IMAGES_URL.replace('{SERVER_URL}', SERVER_URL).replace('{ID}', (toEditObjectInfo?.id as string)).replace('{imageUrl}', `images/${toEditObjectInfo?.objectProperties[property.key]}`) as string : allSingleImageAttributes[property.key]?.base64} alt='Hello'/> </ImagePreviewWrapper>}
                      {allSingleImageAttributes[property.key] === '' && <NoImageText>{t('Drag and drop to upload')}</NoImageText>}
                      {allSingleImageAttributes[property.key] === '' && <ButtonWithIcon variant='primary' icon='Add' position='right' onClick={() => triggerFileInput(property.key)}>{t('Upload')}</ButtonWithIcon>}
                      <input type='file' id={property.key} onChange={(e) => handleFileChange(e, property)} style={{display: 'none'}} />
                    </SingleImageAttributeWrapper>
                  </ImageSectionWrapper>;
                } else if(property.propertyType === PROPERTY_TYPE.SelectField) {
                  const selectOptions: IOption[] = (property.selectOptions as string[]).map((option: string) => {return {name: option, value: option};});
                  return <SelectFieldWrapper>
                    <SelectField label={property.displayName} isRequired={property.isRequired} id={property.key} options={selectOptions} defaultValue={allTextInputValues[property.key]} placeholder='Please select' onChange={(value) => handleSelectFieldChange(value, property.key)} />
                  </SelectFieldWrapper>;
                } else if(property.propertyType === 'multi-image'){
                  return <SectionWrapper>
                    <Label labelText={property.displayName} isMandatory={property.isRequired} />
                    <ImagesSectionWrapper>
                      {
                        allMultiImageAttributes[property.key]?.images?.length === 0 ? <UploadImageBox
                          onDragOver={(e) => e.preventDefault()}
                          onClick={() => triggerFileInput(property.key)}
                        >
                          <ContentWrapper>
                            <IconWrapper>
                              <Icon icon='Image' size={30} />
                            </IconWrapper>
                            <ImageText>
                              <LabelText>{t('Drag and Drop image or Click to Upload File')}*</LabelText>
                            </ImageText>
                            <Separator>
                              <LabelText>{t('OR')}</LabelText>
                            </Separator>
                            <Separator>
                              <ButtonWithIcon color='inverse' disabled={allMultiImageAttributes[property.key]?.images?.length >= (property.maxImages as number)} variant='primary' icon='Add' position='right' onClick={(e) => {e.stopPropagation(); triggerFileInput(property.key);}}>{t('Upload Images')}</ButtonWithIcon>
                            </Separator>
                          </ContentWrapper>
                        </UploadImageBox> : <ThumbnaiImagesWrapper>
                          {
                            allMultiImageAttributes[property.key]?.images?.length === 0 && <NoImagesText>No Images Selected</NoImagesText>
                          }
                          { allMultiImageAttributes[property.key]?.images?.length <= OBJECT_MAX_IMAGES && allMultiImageAttributes[property.key]?.images?.map((image: IFinalImages, index: number) => {
                            const getThumbnailImage = (data: IFinalImages) => {
                              if(isEditObject) {
                                if(data.isNew === true) {
                                  return URL.createObjectURL(data.fileData as Blob);
                                }else {
                                  return PRODUCT_IMAGES_URL.replace('{SERVER_URL}', SERVER_URL).replace('{ID}', (toEditObjectInfo?.id)?.toString() || '10').replace('{imageUrl}', (data.fileData) as string);
                                }
                              }else {
                                return URL.createObjectURL(data.fileData as Blob);
                              }
                            };
                            const getProductImageName = (data: IFinalImages) => {
                              if(data.isNew === true) {
                                return (data.fileData as File).name;
                              } else 
                                return data?.fileData?.toString().replace(ImageBasePath.replace('{SERVER_URL}', SERVER_URL).replace('{selectedProduct}', String(editId)), '');
                            };
                            return <ObjectImagesWrapper>
                              <CloseWrapper onClick={() => handleRemoveImage(index, property)}>
                                <Icon icon='Close' size={18} />
                              </CloseWrapper>
                              <ButtonsAndThumbnailWrapper key={index} onMouseEnter={() => { setAllMultiImageAttributes((prevState: any) => ({
                                ...prevState,
                                [property.key]: {
                                  ...prevState[property.key],
                                  hoveredIndex: index
                                }
                              }));}} onMouseLeave={() => { setAllMultiImageAttributes((prevState: any) => ({
                                ...prevState,
                                [property.key]: {
                                  ...prevState[property.key],
                                  hoveredIndex: -1
                                }
                              }));}}>
                                {
                                  allMultiImageAttributes[property.key].hoveredIndex === index && <OnHoverButtons >
                                    <Button variant='primary' size='small' onClick={() => handleSelectedImageChange(index, property)}>Default</Button>
                                  </OnHoverButtons>
                                }
                                <SingleThumbnaiWrapper selected={index === allMultiImageAttributes[property.key].selectedImage}>
                                  <Thumbnail src={getThumbnailImage(image) as string} blurred={allMultiImageAttributes[property.key].hoveredIndex === index}  />
                                </SingleThumbnaiWrapper>
                              </ButtonsAndThumbnailWrapper>
                              <ProductImageName title={getProductImageName(image)}>{getProductImageName(image)}</ProductImageName>           
                            </ObjectImagesWrapper>;
                          })}
                          {allMultiImageAttributes[property.key]?.images?.length <= 8 && 
                          <UploadMoreImagesButton>
                            <ButtonWithIcon color='inverse' disabled={allMultiImageAttributes[property.key]?.images?.length >= (property.maxImages as number)} variant='primary' icon='Add' position='right' onClick={(e) => {e.stopPropagation(); triggerFileInput(property.key);}}>{t('Upload Images')}</ButtonWithIcon> 
                          </UploadMoreImagesButton> 
                          }
                        </ThumbnaiImagesWrapper>
                      }
                      <HiddenInput type="file" id={property.key} multiple accept=".jpg,.png,.jpeg" onChange={(e:React.ChangeEvent<HTMLInputElement>) => handleMultiImageFileChange(e, property)} />
                      <UploaderWrapper>
                      </UploaderWrapper> 
                    </ImagesSectionWrapper>
                  </SectionWrapper>;
                } else {
                  return <div>Invalid Type</div>;
                }
              })
            }
          </LeftSectionWrapper>
        </LeftSection>
        <LeftSection>
          <LeftSectionWrapper>
            {
              rightSection?.map((property: IProperty) => {
                if (property.propertyType === PROPERTY_TYPE.Text) {
                  return (
                    <InputWrapper>
                      <Input
                        key={property.key}
                        type='text'
                        id={property.key}
                        label={property.displayName}
                        value={allTextInputValues[property.key]}
                        isRequired={property.isRequired}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputFieldChange(e)}
                      />
                    </InputWrapper>
                  );
                } else if(property.propertyType === PROPERTY_TYPE.TextArea) {
                  return <TextAreaWrapper>
                    <TextArea rows={property.rows as number} isRequired={property.isRequired} cols={property.cols as number} id={property.key} label={property.displayName} value={allTextInputValues[property.key]} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleTextAreaChange(e)}/>
                  </TextAreaWrapper>;
                } else if(property.propertyType === PROPERTY_TYPE.Image) {
                  return <ImageSectionWrapper>
                    <LabelWrapper>{property.displayName}{property.isRequired && <RequiredStatus>*</RequiredStatus>}</LabelWrapper>  
                    <SingleImageAttributeWrapper isBorder={allSingleImageAttributes[property.key] === ''}>
                      {allSingleImageAttributes[property.key] === '' ? <IoCloudUploadOutline size={34}/> :<ImagePreviewWrapper><ImagePreviewIcons><ImagePreviewIconsWrapper onClick={() => triggerFileInput(property.key)}><Icon icon='Edit' size={30}/></ImagePreviewIconsWrapper><ImagePreviewIconsWrapper onClick={() => { setAllTextInputValues((prevState: any) => ({...prevState, [property.key]: ''}));setAllSingleImageAttributes((prevState: any) => ({...prevState, [property.key]: ''}));}}><Icon icon='Delete' color='danger' size={30}/></ImagePreviewIconsWrapper></ImagePreviewIcons><ImagePreview src={typeof allSingleImageAttributes[property.key] === 'string' ? PRODUCT_IMAGES_URL.replace('{SERVER_URL}', SERVER_URL).replace('{ID}', (toEditObjectInfo?.id as string)).replace('{imageUrl}', `images/${toEditObjectInfo?.objectProperties[property.key]}`) as string : allSingleImageAttributes[property.key]?.base64} alt='Hello'/> </ImagePreviewWrapper>}
                      {allSingleImageAttributes[property.key] === '' && <NoImageText>{t('Drag and drop to upload')}</NoImageText>}
                      {allSingleImageAttributes[property.key] === '' && <BrowserButton onClick={() => triggerFileInput(property.key)}>{t('Browse')}</BrowserButton>}
                      <input type='file' id={property.key} onChange={(e) => handleFileChange(e, property)} style={{display: 'none'}} />
                    </SingleImageAttributeWrapper>
                  </ImageSectionWrapper>;
                } else if(property.propertyType === PROPERTY_TYPE.SelectField) {
                  const selectOptions: IOption[] = (property.selectOptions as string[]).map((option: string) => {return {name: option, value: option};});
                  return <SelectFieldWrapper>
                    <SelectField label={property.displayName} isRequired={property.isRequired} id={property.key} options={selectOptions} defaultValue={allTextInputValues[property.key]} placeholder='Please select' onChange={(value) => handleSelectFieldChange(value, property.key)} />
                  </SelectFieldWrapper>;
                } else if(property.propertyType === 'multi-image'){
                  return <SectionWrapper>
                    <Label labelText={property.displayName} isMandatory={property.isRequired} />
                    <ImagesSectionWrapper>
                      {
                        allMultiImageAttributes[property.key]?.images?.length === 0 ? <UploadImageBox
                          onDragOver={(e) => e.preventDefault()}
                          onClick={() => triggerFileInput(property.key)}
                        >
                          <ContentWrapper>
                            <IconWrapper>
                              <Icon icon='Image' size={30}/>
                            </IconWrapper>
                            <ImageText>
                              <LabelText>{t('Drag and Drop image or Click to Upload File')}*</LabelText>
                            </ImageText>
                            <Separator>
                              <LabelText>{t('OR')}</LabelText>
                            </Separator>
                            <Separator>
                              <ButtonWithIcon color='inverse' disabled={allMultiImageAttributes[property.key]?.images?.length >= (property.maxImages as number)} variant='primary' icon='Add' position='right' onClick={(e) => {e.stopPropagation(); triggerFileInput(property.key);}}>{t('Upload Images')}</ButtonWithIcon>
                            </Separator>
                          </ContentWrapper>
                        </UploadImageBox> : <ThumbnaiImagesWrapper>
                          {
                            allMultiImageAttributes[property.key]?.images?.length === 0 && <NoImagesText>No Images Selected</NoImagesText>
                          }
                          { allMultiImageAttributes[property.key]?.images?.length <= OBJECT_MAX_IMAGES && allMultiImageAttributes[property.key]?.images?.map((image: IFinalImages, index: number) => {
                            const getThumbnailImage = (data: IFinalImages) => {
                              if(isEditObject) {
                                if(data.isNew === true) {
                                  return URL.createObjectURL(data.fileData as Blob);
                                }else {
                                  return PRODUCT_IMAGES_URL.replace('{SERVER_URL}', SERVER_URL).replace('{ID}', (toEditObjectInfo?.id)?.toString() || '10').replace('{imageUrl}', (data.fileData) as string);
                                }
                              }else {
                                return URL.createObjectURL(data.fileData as Blob);
                              }
                            };
                            const getProductImageName = (data: IFinalImages) => {
                              if(data.isNew === true) {
                                return (data.fileData as File).name;
                              } else 
                                return data?.fileData?.toString().replace(ImageBasePath.replace('{SERVER_URL}', SERVER_URL).replace('{selectedProduct}', String(editId)), '');
                            };
                            return <ObjectImagesWrapper>
                              <CloseWrapper onClick={() => handleRemoveImage(index, property)}>
                                <Icon icon='Close' size={18} />
                              </CloseWrapper>
                              <ButtonsAndThumbnailWrapper key={index} onMouseEnter={() => { setAllMultiImageAttributes((prevState: any) => ({
                                ...prevState,
                                [property.key]: {
                                  ...prevState[property.key],
                                  hoveredIndex: index
                                }
                              }));}} onMouseLeave={() => { setAllMultiImageAttributes((prevState: any) => ({
                                ...prevState,
                                [property.key]: {
                                  ...prevState[property.key],
                                  hoveredIndex: -1
                                }
                              }));}}>
                                {
                                  allMultiImageAttributes[property.key].hoveredIndex === index && <OnHoverButtons >
                                    <Button variant='primary' size='small' onClick={() => handleSelectedImageChange(index, property)}>Default</Button>
                                  </OnHoverButtons>
                                }
                                <SingleThumbnaiWrapper selected={index === allMultiImageAttributes[property.key].selectedImage}>
                                  <Thumbnail src={getThumbnailImage(image) as string} blurred={allMultiImageAttributes[property.key].hoveredIndex === index}  />
                                </SingleThumbnaiWrapper>
                              </ButtonsAndThumbnailWrapper>
                              <ProductImageName title={getProductImageName(image)}>{getProductImageName(image)}</ProductImageName>           
                            </ObjectImagesWrapper>;
                          })}
                          {allMultiImageAttributes[property.key]?.images?.length <= 7 && <UploadMoreImagesButton>
                            <ButtonWithIcon color='inverse' disabled={allMultiImageAttributes[property.key]?.images?.length >= (property.maxImages as number)} variant='primary' icon='Add' position='right' onClick={(e) => {e.stopPropagation(); triggerFileInput(property.key);}}>{t('Upload Images')}</ButtonWithIcon> 
                          </UploadMoreImagesButton> }
                        </ThumbnaiImagesWrapper>
                      }
                      <HiddenInput type="file" id={property.key} multiple accept=".jpg,.png,.jpeg" onChange={(e:React.ChangeEvent<HTMLInputElement>) => handleMultiImageFileChange(e, property)} />
                      <UploaderWrapper>
                      </UploaderWrapper> 
                    </ImagesSectionWrapper>
                  </SectionWrapper>;
                } else {
                  return <div>Invalid Type</div>;
                }
              })
            }
          </LeftSectionWrapper>
        </LeftSection>
      </AllFieldsWrapper>
      <AddObjectWrapper>
        <ButtonWithLoading loading={buttonDisabled} variant='primary' disabled={buttonDisabled} onClick={isEditObject ? handleEditObject : handleAddObject}>{t('saveText')}</ButtonWithLoading>
        <Button variant='secondary' onClick={() => history.push(`/objects?selectedTab=${objectTypeId}`)}>{t('cancleText')}</Button>
      </AddObjectWrapper>
    </Wrapper>
  );
};

export default AddObject;