import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgExport = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M12.995 3v3.818c0 .253.106.496.293.674.188.18.442.28.707.28h4"
        stroke={props.color}
        strokeWidth={props.weight}
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={"non-scaling-stroke"}
      />
      <path
        d="M10.5 20.18H6a2.05 2.05 0 0 1-1.414-.56A1.866 1.866 0 0 1 4 18.27V4.91c0-.506.21-.992.586-1.35A2.05 2.05 0 0 1 6 3h7l5 4.772v4.772m-5 5.726h7m0 0-3-2.863m3 2.863-3 2.864"
        stroke={props.color}
        strokeWidth={props.weight}
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={"non-scaling-stroke"}
      />
    </svg>
  );
};
export default SvgExport;
