import Input from 'components/Input';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const VideoConfigurationsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const VerticalLine = styled.div`
  width: 980px;
  height: 10px;
  border-bottom: 1px solid lightgrey;
  margin-top: 2px;
  margin-bottom: 15px;
  margin-left: 18px;
  @media (max-width: 768px) {
    width: 450px;
  }
`;

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Heading = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-left: 18px;
`;

const SelectedCameraVideoConfigurations = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`;

const LeftSideWrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const RightSideWrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

interface IRTSPData {
  rtspUrl: string,
  username: string,
  password: string,
  rtspSourceBufferTime: string,
  rtspSourceShortHeader: boolean,
  videoFps: string
}

interface IMessage {
  text: string,
  id: string
}

interface IRTSPCamera {
  rtspData: IRTSPData,
  message: IMessage,
  setRtspData: React.Dispatch<React.SetStateAction<IRTSPData>>,
  setIsFormModified: React.Dispatch<React.SetStateAction<boolean>>,
  isEditAccess?: boolean
}

const RTSPCamera = ({rtspData, message, setRtspData, setIsFormModified, isEditAccess=false}: IRTSPCamera) => {
  const {t} = useTranslation(['common']);

  return (
    <VideoConfigurationsWrapper>
      <HeadingWrapper>
        <Heading>{t('Video Configurations')}</Heading>
      </HeadingWrapper>
      <VerticalLine />   
      <SelectedCameraVideoConfigurations>
        <LeftSideWrapper>
          <Input type='text' id='rtspUrl' value={rtspData.rtspUrl} maxLength={255} message={message} disabled={isEditAccess} label={t('RTSP URL')} placeholder='e.g. rtsp://192.168.10.1/ or rtspt://192.168.10.1/' isRequired onChange={(e) => {setRtspData({...rtspData, rtspUrl: e.target.value}); setIsFormModified(true);}} />
        </LeftSideWrapper>
        <RightSideWrapper>
          <Input type='text' id='username' value={rtspData.username} disabled={isEditAccess} label={t('Username')} maxLength={255} onChange={(e) => {setRtspData({...rtspData, username: e.target.value}); setIsFormModified(true);}} />
          <Input type='password' id='password' value={rtspData.password} disabled={isEditAccess} label={t('Password')} maxLength={255} onChange={(e) => {setRtspData({...rtspData, password: e.target.value}); setIsFormModified(true);}} />
        </RightSideWrapper>
      </SelectedCameraVideoConfigurations>
    </VideoConfigurationsWrapper>
  );
};

export default RTSPCamera;