import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgUser = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M19.991 21.999h-2v-2a3 3 0 0 0-3-3h-6a3 3 0 0 0-3 3v2h-2v-2a5 5 0 0 1 5-5h6a5 5 0 0 1 5 5v2Zm-8-9a6 6 0 1 1 0-12 6 6 0 0 1 0 12Zm0-2a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
        fill={props.color}
      />
    </svg>
  );
};
export default SvgUser;
