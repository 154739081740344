import { ISVGProps } from 'interface';
import React from 'react';

const RouteDeviationSVG: React.FC<ISVGProps> = ({color, size}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M15.1381 11.2675L15.1681 7.1475L16.4981 7.0875L14.0981 3.9375L11.7281 7.1975L13.1481 7.1675L13.1581 9.6875L6.57812 11.4175L6.59812 20.4075L13.1581 22.4375L13.0881 27.7475L15.1581 27.7675V20.8875L8.57812 18.8275L8.63813 13.0175L15.1381 11.2675Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M0.59 0C0.92 0 1.18 0.27 1.18 0.59V31.11C1.18 31.44 0.91 31.7 0.59 31.7C0.26 31.7 0 31.43 0 31.11V0.59C0 0.26 0.27 0 0.59 0Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M22.4884 0C22.8184 0 23.0784 0.27 23.0784 0.59V31.11C23.0784 31.44 22.8084 31.7 22.4884 31.7C22.1584 31.7 21.8984 31.43 21.8984 31.11V0.59C21.8984 0.26 22.1684 0 22.4884 0Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M11.5431 0C11.8731 0 12.1331 0.03 12.1331 0.08V4.01C12.1331 4.01 11.8631 4.09 11.5431 4.09C11.2131 4.09 10.9531 4.06 10.9531 4.01V0.08C10.9531 0.08 11.2231 0 11.5431 0Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M11.5431 11.1719C11.8731 11.1719 12.1331 11.2019 12.1331 11.2519V15.1819C12.1331 15.1819 11.8631 15.2619 11.5431 15.2619C11.2131 15.2619 10.9531 15.2319 10.9531 15.1819V11.2519C10.9531 11.2519 11.2231 11.1719 11.5431 11.1719Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M11.5431 16.75C11.8731 16.75 12.1331 16.78 12.1331 16.83V20.76C12.1331 20.76 11.8631 20.84 11.5431 20.84C11.2131 20.84 10.9531 20.81 10.9531 20.76V16.83C10.9531 16.83 11.2231 16.75 11.5431 16.75Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M11.5431 27.9219C11.8731 27.9219 12.1331 27.9519 12.1331 28.0019V31.9319C12.1331 31.9319 11.8631 32.0119 11.5431 32.0119C11.2131 32.0119 10.9531 31.9819 10.9531 31.9319V28.0019C10.9531 28.0019 11.2231 27.9219 11.5431 27.9219Z" fill={color}/>
      <path d="M14.1372 15.8403L15.0572 14.9203C15.1172 14.8603 15.1972 14.8203 15.2772 14.8303C15.3472 14.8303 15.4172 14.8603 15.4872 14.9303C15.5472 14.9903 15.5872 15.0603 15.5972 15.1403C15.5972 15.2203 15.5672 15.2903 15.5072 15.3603L14.5872 16.2803L15.5172 17.2103C15.5872 17.2803 15.6172 17.3503 15.6072 17.4303C15.6072 17.5103 15.5672 17.5803 15.5072 17.6403C15.4472 17.7003 15.3772 17.7303 15.2972 17.7303C15.2172 17.7303 15.1472 17.7103 15.0772 17.6403L14.1572 16.7203L13.2272 17.6503C13.1672 17.7103 13.0972 17.7403 13.0172 17.7403C12.9272 17.7403 12.8572 17.7003 12.7972 17.6403C12.7372 17.5803 12.7072 17.5103 12.6972 17.4303C12.6972 17.3503 12.7272 17.2703 12.7972 17.2003L13.7172 16.2803L12.7772 15.3403C12.7172 15.2803 12.6872 15.2103 12.6972 15.1303C12.6972 15.0603 12.7272 14.9803 12.7872 14.9103C12.8572 14.8503 12.9272 14.8203 13.0072 14.8203C13.0872 14.8203 13.1572 14.8403 13.2172 14.9003L14.1572 15.8403H14.1372Z" fill={color}/>
    </svg>

  );
};

export default RouteDeviationSVG;