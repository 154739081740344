import { IUser } from 'interface';

const getLocalRefreshToken = () => {
  const _user = localStorage.getItem('user_login_info');
  if(_user) {
    return JSON.parse(_user)?.refreshToken;
  }
};

const getLocalAccessToken = () => {
  const _user = localStorage.getItem('user_login_info');
  if(_user){
    return JSON.parse(_user)?.accessToken;
  }
};

const updateLocalAccessToken = (token: string) => {
  const _user = localStorage.getItem('user_login_info');
  if(_user){
    const user: IUser = JSON.parse(_user);
    user.accessToken = token;
    localStorage.setItem('user_login_info', JSON.stringify(user));
  }
};

const getUser = () => {
  const _user = localStorage?.getItem('user_login_info');
  if(_user){
    return JSON.parse(_user);
  }
};

const getUserID = () => {
  const _user = localStorage.getItem('user_login_info');
  if (_user) {
    return JSON.parse(_user)?.user_id;
  }
};

const getUserRole = () => {
  const _user = localStorage?.getItem('user_login_info');
  if(_user){
    return JSON.parse(_user)?.role;
  }
};

const getUserTypeAdmin = () => {
  const _user = localStorage?.getItem('user_login_info');
  if (_user) {
    return JSON.parse(_user)?.role === 'admin';
  } else {
    return false;
  }
};

const getUserTypeGuest = () => {
  const _user = localStorage?.getItem('user_login_info');
  if (_user) {
    return JSON.parse(_user)?.user?.role === 'guest';
  } else {
    return false;
  }
};

const setUser = (user: IUser) => {
  localStorage.setItem('user_login_info', JSON.stringify(user));
};

const removeUser = () => {
  localStorage.removeItem('user_login_info');
};

const hasAccess = (code: string) => {
  const _user = localStorage?.getItem('user_login_info') as string;
  const permissions = JSON.parse(_user)?.permissions;

  if (permissions) {
    if (permissions.includes('ALL')) {
      return true; 
    } else if (permissions.includes(code)) {
      return true;
    }
  }

  return false; 
};

const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  getUser,
  setUser,
  removeUser,
  getUserID,
  getUserTypeAdmin,
  getUserTypeGuest,
  getUserRole,
  hasAccess
};

export default TokenService;
