import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  min-height: calc(100vh - 120px);
  padding: 20px;
  gap: 10px;
  background-color: ${({ theme }) => theme.backgroundColor};
  width: 100%;
`;

export const ScrollBarCss = css`
   /* Define the scrollbar width */
   &::-webkit-scrollbar {
    width: 10px; /* Width of the scrollbar */
  }

  /* Track (the background of the scrollbar) */
  &::-webkit-scrollbar-track {
    border-radius: 8px; /* Radius on top and bottom */
    background-color: #F1F2F8; /* Track background color */
  }

  /* Handle (the draggable part of the scrollbar) */
  &::-webkit-scrollbar-thumb {
    border-radius: 8px; /* Radius on top and bottom */
    background-color: #C0C5DD; /* Scrollbar handle color */
    width: 6px;
  }

  /* Hide the top and bottom arrows */
  &::-webkit-scrollbar-button {
    display: none;
  }

  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: #C0C5DD #F1F2F8;
`;