import { IReducerActions } from 'components/LineUI/LineReducer';
import { ALGORITHM_CODES, DEFAULT_ROUTE_DEVIATION_LINE1, DEFAULT_ROUTE_DEVIATION_LINE2, IconType, LineUIType, PERMISSION_CODE } from '../../../constants';
import { IAlertMessageType, IRouteDeviationDetectionConfigs, IRouteDeviationLaneLines, IPoints, IOption, INewAnalysisConfigsRes } from 'interface';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Icon from 'components/Icon';
import Input from 'components/Input';
import { allowOnlyNumbers, checkUniqueNames, getLineUIColor, isNullOrEmpty } from 'utils/utils';
import Config from '../../../configs/config.json';
import AlertBar from 'components/AlertBar';
import InputWithUnit from 'components/InputWithUnit';
import { AiOutlineMinus } from 'react-icons/ai';
import { cloneDeep } from 'lodash';
import { AddNewLineWrapper, DeleteIconDiv, HorizontalDivider, IconDiv, LabelWrapper, LineDetailContainer, LineNameWrapper, LinesContainer, LinesListContainer } from './algoConfigStyles';
import TokenService from 'api_configs/tokenService';

const ConfigurationForm = styled.div`
  display: flex;
  gap: 20px;
`;

const NoLineData = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 100%;
  border-radius: 5px;
  border: 1px solid #D0D7F2;
  min-height: 310px;
  min-width: 600px;
`;

const AddNewLineButton = styled.div<{disabled:boolean}>`
  background: ${({disabled}) => disabled ? '#8E9FDF' : 'linear-gradient(to top, #1538BD, #7287D7)' } ;
  color: #FFFFFF;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: ${({disabled}) => disabled ? 'not-allowed' : 'pointer' } ;
`;

const NoLineText = styled.div`
  max-width: 60%;
  font-size: 14px;
  color: ${({theme})=>theme.text.secondaryText};
  text-align: center;
`;


const FieldsContainer = styled.div`
  display: flex;
  flex-direction:column;
  gap: 20px;
  margin-top: 10px;
`;

const Label = styled.div`
  font-size: 16px;
`;


const LineName = styled.div`
  width: 70px;
  white-space: nowrap;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AreaPointsHandler = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const PointsToggleDiv = styled.div<{disabled:boolean}>`
  display: flex;
  align-items: center;
  margin-top: 5px;
  justify-content: space-between;
  background-color: ${({disabled}) => disabled ? '#FAFAFA' : ''};
  height: 40px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid lightgrey;
`;

const PointsCountChanger = styled.div<{disabled:boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border: 1px solid lightgrey;
  border-radius: 6px;
  cursor: pointer;
    ${({ disabled }) =>
    disabled &&
    `
    background-color: #e9e8e8;
    cursor: not-allowed;
  `}
`;

const PointsCount = styled.div``;

interface IRouteDeviationDetectionProps {
  selectedAlgorithmConfigsLength: number;
  selectedIndex: number;
  onAddNewLine: (a: boolean, data: IRouteDeviationDetectionConfigs, currentLength: number)=>void;
  addLine: (name: string, lineArray: IPoints[][], isLine: boolean, styling?:string, prevLength?: number, lineIndex?:number )=>void;
  handleRemoveLine?: (index: number, length: number, isSet?: boolean, prevLength?: number, lineIndex?:number)=>void;
  dispatch: React.Dispatch<IReducerActions>;
  onLineClickCallback?: (id: number, isSet?: boolean, prevLength?: number, length?:number) => void;
  setSelectedAlgoConfigs: React.Dispatch<React.SetStateAction<IRouteDeviationDetectionConfigs[]>>;
  selectedAlgoConfigs: IRouteDeviationDetectionConfigs[];
  scheduleOptions:IOption[];
  selectedAlgorithmConfigDetails: INewAnalysisConfigsRes;
  setSelectedAlgorithmConfigDetails: React.Dispatch<React.SetStateAction<INewAnalysisConfigsRes>>;
  setValidationMessage: React.Dispatch<React.SetStateAction<string>>;
}

const RouteDeviationDetection: React.FC<IRouteDeviationDetectionProps> = ({selectedIndex,selectedAlgoConfigs, selectedAlgorithmConfigsLength ,onAddNewLine, setSelectedAlgoConfigs, setValidationMessage, handleRemoveLine=()=>{}, dispatch, onLineClickCallback=()=>{}, addLine=()=>{}}) => {
  const {t} = useTranslation(['common']);
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'});

  const editAnalysisAccess = TokenService.hasAccess(PERMISSION_CODE.edit_analysis_configuration);

  const AddNewLine = useCallback(() => {
    if(selectedAlgorithmConfigsLength + 1 > Config.algorithmConfigurations.lineSetsLimit){
      setMessage({text: t('configurationLimitWarning').replace('{X}',`${Config.algorithmConfigurations.lineSetsLimit}`), id:'',type:'danger'});
    }else{
      const newLineData: IRouteDeviationDetectionConfigs = {
        name: 'Lane' + (selectedAlgorithmConfigsLength + 1),
        index: selectedAlgorithmConfigsLength + 1,
        lineType: LineUIType.LINE_SET_ARRAY,
        styling: getLineUIColor(ALGORITHM_CODES.route_deviation_detection) as string,
        line_set_array: [
          {
            points: DEFAULT_ROUTE_DEVIATION_LINE1.map((item) => ({ x: item.x + (selectedAlgorithmConfigsLength * 80), y: item.y })),
            line_name: `${selectedAlgorithmConfigsLength + 1}A`,
          },
          {
            points: DEFAULT_ROUTE_DEVIATION_LINE2.map((item) => ({ x: item.x + (selectedAlgorithmConfigsLength * 80), y: item.y })),
            line_name: `${selectedAlgorithmConfigsLength + 1}B`,
          },
        ],
        threshold: '',
        duration: '',
      };
      onAddNewLine(true, {...newLineData}, selectedAlgorithmConfigsLength);
      setSelectedAlgoConfigs(prev => [...prev, newLineData]);
    }
  },[selectedAlgorithmConfigsLength, setSelectedAlgoConfigs, onAddNewLine, t]);

  const getPrevLineCount = useCallback((index: number) => {
    let length = 0;
    for(let i=0; i<index; i++){
      length = length + (selectedAlgoConfigs[i]?.line_set_array as IRouteDeviationLaneLines[])?.length as number;
    }
    return length;
  },[selectedAlgoConfigs]);

  const addLineToSetArray = useCallback(() => {
    if(((selectedAlgoConfigs[selectedIndex-1]?.line_set_array as IRouteDeviationLaneLines[])?.length as number) < 9){
      setSelectedAlgoConfigs(prev => {
        const temp = cloneDeep(prev);
        let lineCount = 0;
        if(temp[selectedIndex - 1]?.line_set_array?.length){
          lineCount = (temp[selectedIndex - 1]?.line_set_array?.length) as number;
        }
        const newLine = {
          line_name: `${(selectedIndex-1) + 1 + String.fromCharCode(65 + lineCount)}`,
          points: [
            {
              x: 100 + (lineCount * 110),
              y: 150
            },
            {
              x: 100 + (lineCount * 110),
              y: 500
            }
          ]
        };
        temp[selectedIndex-1]?.line_set_array?.push(newLine);
        addLine(newLine.line_name, [newLine.points], true, '',getPrevLineCount(selectedIndex-1), ((selectedAlgoConfigs[selectedIndex-1]?.line_set_array as IRouteDeviationLaneLines[])?.length as number));

        return temp;
      });
    }
  }, [ setSelectedAlgoConfigs, selectedIndex, addLine, selectedAlgoConfigs, getPrevLineCount]);

  const onDividerRemove = useCallback(() => {
    setSelectedAlgoConfigs(prev => {
      const temp = cloneDeep(prev);
      temp[selectedIndex-1]?.line_set_array?.pop();
      return temp;
    });
    const index = (selectedAlgoConfigs[selectedIndex - 1]?.line_set_array as IRouteDeviationLaneLines[])?.length as number + getPrevLineCount(selectedIndex-1);
    dispatch({
      type: 'REMOVE_SET',
      index: index - 1,
    });
  }, [setSelectedAlgoConfigs, selectedIndex, dispatch, getPrevLineCount, selectedAlgoConfigs]);

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAlgoConfigs((prev: IRouteDeviationDetectionConfigs[]) => {
      const selectedAlgoConfigs = [...prev];
      const selectedLineConfig = JSON.parse(JSON.stringify(selectedAlgoConfigs[selectedIndex - 1])); 
      selectedLineConfig[e.target.name] = e.target.name !== 'name' ? parseInt(e.target.value) : e.target.value;
      selectedAlgoConfigs[selectedIndex - 1] = selectedLineConfig;
      return selectedAlgoConfigs;
    });
  },[setSelectedAlgoConfigs, selectedIndex]);

  useEffect(() => {
    if (!checkUniqueNames(selectedAlgoConfigs)) {
      setValidationMessage('Duplicate Lane Name values are not allowed');
    } else {
      let anyConditionTrue = false;

      for (const config of selectedAlgoConfigs) {
        if (isNullOrEmpty(config.name)) {
          setValidationMessage('Lane Name should not be empty or null');
          anyConditionTrue = true;
          break;
        }
        else if (isNullOrEmpty(config.threshold)) {
          setValidationMessage('Detection When should not be empty or null');
          anyConditionTrue = true;
          break;
        }
        else if (isNullOrEmpty(config.duration)) {
          setValidationMessage('Happens Within should not be empty or null');
          anyConditionTrue = true;
          break;
        }
        else if (Number(config.threshold) > (config.line_set_array?.length ?? 0)) {
          setValidationMessage('Detection When should not exceed the number of Dividers');
          anyConditionTrue = true;
          break;
        }
      }

      if (!anyConditionTrue) {
        setValidationMessage('');
      }
    }
  }, [selectedAlgoConfigs, selectedIndex, setValidationMessage]);

  return (
    <ConfigurationForm>
      { message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
      {selectedIndex === 0 ? 
        <NoLineData>
          <NoLineText>{t('No Lines Sets been configured yet, Please add Line Set')}</NoLineText>
          <AddNewLineButton onClick={editAnalysisAccess ? AddNewLine : undefined} disabled={!editAnalysisAccess}>{t('Add Line Set')}</AddNewLineButton>
        </NoLineData>:
        <>
          <LinesContainer>
            <AddNewLineWrapper>
              <LabelWrapper>
                <Icon icon='Line' size={20} />
                <Label>{t('Lanes')}</Label>
              </LabelWrapper>
              <IconDiv title={t('Add New Lane')} onClick={AddNewLine}>
                { editAnalysisAccess &&  <Icon icon='Plus' size={16} />}
              </IconDiv>
            </AddNewLineWrapper>
            {selectedIndex !== 0 &&
            <LinesListContainer>
              {(selectedAlgoConfigs as IRouteDeviationDetectionConfigs[])?.map((line, index) => (
                <>
                  <LineNameWrapper isSelected={selectedIndex === index + 1} onClick={() => onLineClickCallback(index, true, getPrevLineCount(index), (selectedAlgoConfigs[index]?.line_set_array as IRouteDeviationLaneLines[])?.length as number)}>
                    <div>{index + 1}.</div>
                    <LineName>{line?.name}</LineName>
                  </LineNameWrapper>
                  {index !== selectedAlgoConfigs.length - 1 && <HorizontalDivider />}
                </>
              ))}
            </LinesListContainer>}
          </LinesContainer>
          <LineDetailContainer>
            { editAnalysisAccess && 
            <DeleteIconDiv onClick={() => handleRemoveLine(selectedIndex - 1, selectedAlgorithmConfigsLength, true, getPrevLineCount(selectedIndex - 1), (selectedAlgoConfigs[selectedIndex - 1]?.line_set_array as IRouteDeviationLaneLines[])?.length as number)}>
              <Icon icon='Delete' size={16} />
            </DeleteIconDiv>}
            <Input id='name' isRequired type='text' name='name' label={t('Lane Name')} value={selectedAlgoConfigs[selectedIndex - 1]?.name} onChange={handleInputChange} maxLength={16} disabled={!editAnalysisAccess}/>
            <AreaPointsHandler>
              <Label>{t('Dividers')}</Label>
              <PointsToggleDiv disabled={!editAnalysisAccess}>
                <PointsCountChanger onClick={onDividerRemove} disabled={((selectedAlgoConfigs[selectedIndex - 1]?.line_set_array as IRouteDeviationLaneLines[])?.length as number) === 0 || !editAnalysisAccess}>
                  <AiOutlineMinus />
                </PointsCountChanger>
                <PointsCount>{selectedAlgoConfigs[selectedIndex - 1]?.line_set_array ? selectedAlgoConfigs[selectedIndex - 1]?.line_set_array?.length : 0}
                </PointsCount>
                <PointsCountChanger onClick={addLineToSetArray} disabled={((selectedAlgoConfigs[selectedIndex - 1]?.line_set_array as IRouteDeviationLaneLines[])?.length as number) === 9 || !editAnalysisAccess}>
                  <Icon icon='Add' size={20} />
                </PointsCountChanger>
              </PointsToggleDiv>
            </AreaPointsHandler>
            <FieldsContainer>
              <InputWithUnit required unit={t('divider crossings')} id='threshold' type='text' name='threshold' disabled={!editAnalysisAccess} onKeyPress={allowOnlyNumbers} label={t('Detection When..')} value={selectedAlgoConfigs[selectedIndex - 1]?.threshold ? selectedAlgoConfigs[selectedIndex - 1]?.threshold : ''} onChange={handleInputChange} maxLength={16} />
              <InputWithUnit required unit={t('seconds')} id='duration' type='text' name='duration' disabled={!editAnalysisAccess} onKeyPress={allowOnlyNumbers} label={t('...Happens Within')} value={selectedAlgoConfigs[selectedIndex - 1]?.duration ? selectedAlgoConfigs[selectedIndex - 1]?.duration : ''} onChange={handleInputChange} maxLength={16} />
            </FieldsContainer>
          </LineDetailContainer></>
      }
    </ConfigurationForm>
  );
};

export default RouteDeviationDetection;