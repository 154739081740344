import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgInAppSound = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <rect
        x={2.4}
        y={2.4}
        width={19.2}
        height={14.756}
        rx={1.6}
        stroke={props.color}
        strokeWidth={props.weight}
        vectorEffect={"non-scaling-stroke"}
      />
      <path
        d="M6.445 10.385V8.51a1.01 1.01 0 0 1 1.01-1.01H8.92a.505.505 0 0 0 .278-.084l3.03-1.998a.505.505 0 0 1 .783.422v7.215a.505.505 0 0 1-.783.422l-3.03-1.998a.505.505 0 0 0-.278-.083H7.455a1.01 1.01 0 0 1-1.01-1.01Z"
        stroke={props.color}
        strokeWidth={props.weight}
        vectorEffect={"non-scaling-stroke"}
      />
      <path
        d="M14.78 7.172s.757.758.757 2.02c0 1.263-.758 2.02-.758 2.02m1.515-5.555s1.263 1.263 1.263 3.536c0 2.272-1.263 3.535-1.263 3.535"
        stroke={props.color}
        strokeWidth={props.weight}
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={"non-scaling-stroke"}
      />
      <path
        d="M12 17.557v3.047M8.666 20.889h6.667"
        stroke={props.color}
        strokeWidth={props.weight}
        strokeLinecap="round"
        vectorEffect={"non-scaling-stroke"}
      />
    </svg>
  );
};
export default SvgInAppSound;
