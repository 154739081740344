import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { EXPORT_SYSTEM_CONFIGURATION } from 'api_configs/queries';
import { useQuery } from '@apollo/client';
import { IAlertMessageType } from 'interface';
import AlertBar from 'components/AlertBar';
import { SERVER_URL, IconType, StatusCode } from '../../constants';
import Button from 'components/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Icon from 'components/Icon';
import Label from 'components/Label';

const MainWrapper = styled.div`
  width: 450px;
  margin-top: 25px;
`;

const ButtonWrapper = styled.div`
  text-align: center;
  justify-content: center;
  display: flex;
  > button {
    height: 36px;
  }
`;

const DownloadingText = styled.div`
  color: #8b9196;
  font-size: 14px;
  line-height: 1.79;
  text-align: center;
  position: absolute;
  margin-top: 115px;
`;

const SpinnerBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 70px;
  height: 100px;
  width: 450px;
  align-items: center;
  justify-content: center;
`;

const SuccessButtonWrapper = styled.div`
  display: flex;
  gap: 0 10px;
  width: max-content;
  margin-top: 16px;
  > button:nth-child(2) {
    background-color: #e4edf4;
  }
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const UploadingErrorLabel = styled(Label)`
  font-size: 20px;
`;

const UploadingSuccessText = styled.div`
  font-size: 14px;
  line-height: 1.79;
  text-align: center;
`;

const UploadingErrorText = styled(Label)`
  font-size: 14px;
  text-align: center;
`;

const IntroductionText = styled.div`
  margin-bottom: 40px;
`;

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const UploadingSuccessLabel = styled(Label)`
  font-size: 20px;
`;

const ExportSystemConfig = () =>{
  const {t} = useTranslation(['common']);
  const [loading, setLoading] = useState(false);
  const {refetch} = useQuery(EXPORT_SYSTEM_CONFIGURATION);
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'});
  const [errorMessage, setErrorMessage]= useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<boolean>(false);
  const [mainContainer, setMainContainer] = useState(true);

  const onExportData = async() => {
    setLoading(true);
    try {
      const result = await refetch();
      if(result.data.exportSystemConfiguration.statusCode === StatusCode.SUCCESS){
        downloadLink(SERVER_URL + result.data.exportSystemConfiguration.result.file);
        setMessage({text: t('Data exported successfully'), id:'',type:'success'});
        setLoading(false);
        setSuccessMessage(true);
        setMainContainer(false);
      } else{
        setMessage({text: t('apiError'), id:'',type:'danger'});
        setLoading(false);
        setErrorMessage(true);
        setMainContainer(false);
      }
    } catch(e){
      setMessage({text: t('apiError'), id:'',type:'danger'});
      setLoading(false);
      setErrorMessage(true);
      setMainContainer(false);
    }
  };

  if (loading) {
    return (
      <SpinnerBox>
        <CircularProgress />
        <DownloadingText>{t('Please do not navigate away from this page while downloading. We will let you know once this has been completed.')}</DownloadingText>
      </SpinnerBox>
    );
  }

  return(
    <MainWrapper>
      {message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
      {successMessage &&
        <SuccessContainer>
          <Icon color='success' icon='Success' size={40} weight='regular' />
          <UploadingSuccessLabel labelText={t('Export Completed')} />
          <UploadingSuccessText>{t('A file has been exported successfully. You may close this page now.')} </UploadingSuccessText>
          <SuccessButtonWrapper>
            <Button variant='primary' onClick={onExportData} >{t('Export Again')}</Button>
            <Button variant='secondary' onClick={() => {setSuccessMessage(false); setMainContainer(true);}}>{t('Cancel')}</Button>
          </SuccessButtonWrapper>
        </SuccessContainer>}
      {errorMessage && 
        <ErrorContainer>
          <Icon icon='Warning' color='danger' size={40} weight='regular' />
          <UploadingErrorLabel labelText={t('Error Occured')} />
          <UploadingErrorText labelText={t('An error occurred during export')} />
          <SuccessButtonWrapper>
            <Button variant='primary' onClick={onExportData} >{t('Try Again')}</Button>
            <Button variant='secondary' onClick={() => {setErrorMessage(false); setMainContainer(true);}}>{t('Cancel')}</Button>
          </SuccessButtonWrapper>
        </ErrorContainer>}
      { mainContainer &&
       <>
         <IntroductionText>
           {t('Generate a compressed file containing various system wide configuration data.')}
         </IntroductionText>
         <ButtonWrapper>
           <Button variant='primary' onClick={onExportData} >{t('Export')}</Button>
         </ButtonWrapper>
       </>}
    </MainWrapper>
  );
};

export default ExportSystemConfig;

const downloadLink = (link: string) => {
  if(!link){return;}
  const anchor = document.createElement('a');
  anchor.href = link;
  document.body.appendChild(anchor);
  anchor.click();
  anchor.remove(); 
};