import axios from 'axios';
import { EDGE_API_URL } from '../constants';
import { useCallback, useEffect, useState } from 'react';
import { IStream } from 'interface';

interface IStatusCount {
  active: number;
  inActive: number;
  activeStreams: string[];
  inActiveStreams: string[];
}

export const useAllStreamStatus = ({allStreams}: {allStreams: IStream[]}) => {
  const [allStreamStatusCount, setAllStreamStatusCount] = useState<IStatusCount>({active: 0, inActive: 0, inActiveStreams: [], activeStreams: []});

  const updateStatusInfo = useCallback( async () => {
    try {
      const res = await axios.get(EDGE_API_URL + '/stacks/status/all');
      if(res && res.data.data){
        let active = 0, inActive = 0;
        const activeStackNames: string[] = [];
        const inActiveStackNames: string[] = [];
        const stacks = res.data.data.stacks;
        Object.keys(stacks).filter(stack_name => allStreams.some(item => item.instanceId === stack_name)).map((stack_name) => {
          const statckStatus = stacks[stack_name].running_status.status;
          if(statckStatus === 'running'){
            active++;
            activeStackNames.push(stack_name);
          } else if(statckStatus === 'disabled' || statckStatus === 'paused' || statckStatus === 'recovering' || statckStatus === 'desired' || statckStatus === 'failed'){
            inActive++;
            inActiveStackNames.push(stack_name);
          }
          return null;
        });
        setAllStreamStatusCount({active: active, inActive: inActive, activeStreams: activeStackNames, inActiveStreams: inActiveStackNames});
      }
    } catch(error) {
      console.error(error);
    }
  },[allStreams]);
  
  useEffect(()=>{
    updateStatusInfo();
    const intervalId = setInterval(async () => {
      await updateStatusInfo();
    }, 5000);
    return () => clearInterval(intervalId);
  }, [updateStatusInfo]);

  return {
    activeStreamsCount : allStreamStatusCount?.active,
    inActiveStreamsCount : allStreamStatusCount?.inActive,
    activeStreams: allStreamStatusCount?.activeStreams,
    inActiveStreams: allStreamStatusCount?.inActiveStreams
  };
};