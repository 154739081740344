import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgStoppedVehicle = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.19 31.18c7.28 0 13.19.12 13.19.27v.28c0 .15-5.9.27-13.19.27C5.91 32 0 31.88 0 31.73v-.28c0-.15 5.9-.27 13.19-.27ZM18.937 3.38 15.687.14c-.07-.07-.14-.14-.2-.14h-4.6c-.14 0-.2.07-.27.14l-3.25 3.25c-.07.07-.14.14-.14.2v4.67c0 .07.07.14.14.2l3.25 3.25c.07.07.14.14.27.14h4.6c.07 0 .14-.07.2-.14l3.25-3.25c.07-.07.14-.14.14-.2V3.58c0-.07-.07-.14-.14-.2Zm-.54 4.67-3.04 3.11h-4.33l-3.04-3.11V3.79l3.04-3.11h4.33l3.04 3.11v4.26ZM3.72 18.117h18.94c.2 0 .41-.2.41-.41 0-.21-.2-.41-.41-.41H3.72c-.27 0-.47.2-.47.41 0 .21.2.41.47.41Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m25.833 19.27-1.08-1.69-.41-1.62h.88c.2 0 .41-.2.41-.41 0-.27-.2-.47-.41-.47h-1.08l-1.08-4.33c-.27-1.08-1.35-1.89-2.5-1.89h-.95l-.81.81h1.76c.74 0 1.49.61 1.69 1.28l1.69 6.9.07.07 1.08 1.76c.14.2.27.61.27.81v5.61h-6.22l-1.01-2.5c0-.2-.2-.27-.34-.27h-9.2c-.2 0-.34.07-.41.27l-1.01 2.5h-6.16v-5.61c0-.2.07-.61.2-.81l1.08-1.76c.07 0 .07-.07.07-.07l1.76-6.9c.14-.68.88-1.28 1.62-1.28h1.89l-.74-.81h-1.15c-1.08 0-2.16.81-2.43 1.89l-1.08 4.33h-1.08c-.27 0-.41.2-.41.47 0 .2.14.41.41.41h.88l-.41 1.62-1.08 1.69c-.2.27-.41.81-.41 1.22v6.02c0 .2.2.41.47.41h.14v3.04c0 .2.14.41.41.41h4.33c.27 0 .41-.2.41-.41v-1.83c0-.2-.14-.41-.41-.41-.2 0-.41.2-.41.41v1.42h-3.51v-2.64h23.26v2.64h-3.58v-1.42c0-.2-.2-.41-.41-.41-.21 0-.41.2-.41.41v1.83c0 .2.2.41.41.41h4.4c.2 0 .41-.2.41-.41v-3.04h.14c.2 0 .41-.2.41-.41v-6.02c0-.41-.14-.95-.34-1.22h-.02Zm-7.57 6.83H8.053l.81-1.96h8.66l.74 1.96Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.23 24.007h1.56c1.22 0 2.23-1.01 2.23-2.23s-1.01-2.23-2.23-2.23H2.57c-.27 0-.47.14-.47.41 0 .2.2.41.47.41h1.22c.81 0 1.42.61 1.42 1.42 0 .74-.61 1.42-1.42 1.42H2.23c-.2 0-.41.14-.41.41 0 .2.2.41.41.41v-.02ZM20.29 21.781c0 1.22 1.01 2.23 2.23 2.23h1.56c.27 0 .41-.2.41-.41 0-.27-.14-.41-.41-.41h-1.56c-.74 0-1.42-.68-1.42-1.42 0-.81.68-1.42 1.42-1.42h1.28c.2 0 .41-.2.41-.41 0-.27-.2-.41-.41-.41h-1.28c-1.22 0-2.23 1.01-2.23 2.23v.02ZM15.01 3.447c-.14 0-.2.07-.27.07v-.07c0-.27-.27-.54-.61-.54-.07 0-.2 0-.27.07v-.07a.53.53 0 0 0-.54-.54c-.34 0-.61.2-.61.54v.07c-.07-.07-.14-.07-.27-.07-.27 0-.54.27-.54.54v2.98s0 .07-.07.07h-.07l-.54-.81c-.14-.27-.54-.34-.74-.14-.14.07-.2.2-.27.34 0 .2 0 .34.14.47l1.42 2.1c.41.61 1.08.95 1.76.95 1.08 0 2.03-.95 2.03-2.1v-3.25c0-.34-.27-.61-.54-.61h-.01Zm.27 3.85c0 1.01-.81 1.76-1.76 1.76-.61 0-1.15-.27-1.49-.74l-1.49-2.16c-.07-.07-.07-.14-.07-.2s.07-.14.14-.2h.14c.14 0 .2 0 .27.07l.47.68.07.14c.07.07.2.14.27.14.2 0 .34-.2.34-.34v-2.98c0-.14.14-.27.27-.27.2 0 .27.14.27.27v2.1c0 .14.07.2.2.2.07 0 .14-.07.14-.2v-2.64c0-.14.14-.27.27-.27s.27.14.27.27v2.64c0 .14.07.2.14.2s.14-.07.14-.2v-2.1c0-.14.14-.27.27-.27.2 0 .27.14.27.27v2.43c0 .07.07.14.2.14.07 0 .14-.07.14-.14v-1.83c0-.2.07-.34.27-.34.14 0 .27.14.27.34v3.25l-.01-.02Z"
        fill={props.color}
      />
    </svg>
  );
};
export default SvgStoppedVehicle;
