import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgImage = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M19.5 3h-15A1.5 1.5 0 0 0 3 4.5v15A1.5 1.5 0 0 0 4.5 21h15a1.5 1.5 0 0 0 1.5-1.5v-15A1.5 1.5 0 0 0 19.5 3Zm-15 1.5h15v7.254L17.185 9.44a1.5 1.5 0 0 0-2.12 0L5.003 19.5H4.5v-15Zm15 15H7.125l9-9 3.375 3.375V19.5ZM9 11.25a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5Zm0-3a.75.75 0 1 1 0 1.5.75.75 0 0 1 0-1.5Z"
        fill={props.color}
      />
    </svg>
  );
};
export default SvgImage;
