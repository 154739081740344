import Button from 'components/Button';
import ButtonWithIcon from 'components/ButtonWithIcon';
import Input from 'components/Input';
import SelectField from 'components/SelectField';
import TextArea from 'components/TextArea';
import { PROPERTY_TYPE } from '../constants';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon';
import Label from 'components/Label';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import { CustomDroppable } from 'components/CustomDroppable';
import { IOption } from 'interface';

const LabelWrapper = styled.div`
  margin-bottom: 14px;
`;

const AllFieldsWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

const SingleImageAttributeWrapper = styled.div`
  width: 450px;
  height: 138px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  border-radius: 5px;
`;

const AddObjectWrapper = styled.div`
  max-width: 965px;
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 14px;
`;

const InputWrapper = styled.div`
  width: fit-content;
  height: 80px;
  margin-top: 16px;
`;

const Wrapper = styled.div`
  margin: 20px;
  min-height: 400px;
`;

const SelectFieldWrapper = styled.div`
  margin-top: 15px;
  > div {
    margin-bottom: 10px;
  }
`;

const ImageSectionWrapper = styled.div`
  margin-top: 15px;
  border-radius: 5px;
  height: 180px;
`;

const TextAreaWrapper = styled.div`
  margin-top: 15px;
`;

const DropText = styled.p`
  font-size: 16px;
  margin-bottom: 10px;
`;

const RequiredStatus = styled.span`
  margin-left: 5px;
  color: red;
`;

const ImagesSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  max-width: 980px;
  position: relative;
`;

const UploadImageBox = styled.div`
  display: flex;
  width: 450px;
  height: 236px;
  flex-wrap: wrap;
  border-radius: 5px;
  border: 1px solid rgb(7, 171, 207);
  justify-content: space-between;
  margin-top: 15px;
  @media (max-width: 768px) {
    width: 530px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 450px;
`;

const ImageText = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5px;
  font-style: italic;
`;

const LabelText = styled.span``;

const Separator = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
`;

const NoElementComponent = styled.div`
  width: 300px;
  height: 100px;
`;

const LeftSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const HiddenInput = styled.input`
  display: none;
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const UploaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -50px;
  margin-left: 20px;
  position: absolute;
  top: 58px;
  right: 60px;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 10px;
  >:nth-child(2) {
    margin-top: -14px;
  }
`;

const ObjectTypeNameWrapper = styled.div`
  font-size: 18px;
  margin-bottom: 15px;
`;

const MessageWrapper = styled.span`
  font-size: 18px;
  font-weight: bold;
`;

const EmptySection = styled.div`
  width: 450px;
  height: 180px;
`;

interface IAddObject {
  properties: IProperty[],
  setProperties: React.Dispatch<React.SetStateAction<IProperty[]>>,
  setEnablePreview: React.Dispatch<React.SetStateAction<boolean>>,
  objectName: string
}

interface IProperty {
  displayName: string,
  featureExtraction?: string,
  isRequired: boolean,
  key: string,
  maxImages?: number,
  propertyType: string,
  sequence: string,
  sequenceId?: number,
  isTextOnly?: boolean,
  maxTextLength?: number,
  selectOptions?: string[],
  rows?: number,
  cols?: number
}

const AddObject = ({properties, setProperties, setEnablePreview, objectName}: IAddObject) => {
  const {t} = useTranslation(['common']);
  const [leftSection, setLeftSection] = useState<IProperty[]>([]);
  const [rightSection, setRightSection] = useState<IProperty[]>([]);

  useEffect(() => {
    const leftElements: IProperty[] = [];
    const rightElements: IProperty[] = [];
  
    properties.forEach((element: any) => {
      const sequenceId = element.sequence;
      const section = sequenceId.charAt(0);
  
      if (section === 'L') {
        leftElements.push({ ...element, sequenceId: parseInt(sequenceId.substring(1))});
      } else if (section === 'R') {
        rightElements.push({ ...element, sequenceId: parseInt(sequenceId.substring(1))});
      }
    });
  
    leftElements.sort((a, b) => (a.sequenceId as number) - (b.sequenceId as number));
    rightElements.sort((a, b) => (a.sequenceId as number) - (b.sequenceId as number));

    setLeftSection(leftElements);
    setRightSection(rightElements);
  }, [properties]);

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
  
    const sourceId = result.source.droppableId;
    const destinationId = result.destination.droppableId;
  
    const updatedLeftSection = [...leftSection];
    const updatedRightSection = [...rightSection];
  
    const moveBetweenSections = (sourceSection: IProperty[], destinationSection: IProperty[], destinationPrefix: string) => {
      const movedProperty = sourceSection[result.source.index];
      sourceSection.splice(result.source.index, 1);
  
      if (destinationSection === updatedLeftSection || destinationSection === updatedRightSection) {
        if(result.destination) {
          const destinationIndex = result.destination.index;
          movedProperty.sequence = `${destinationPrefix}${destinationIndex}`;
        }
      }
      if(result.destination)
        destinationSection.splice(result.destination.index, 0, movedProperty);
    };
  
    if (sourceId === 'left-section' && destinationId === 'left-section') {
      const movedProperty = updatedLeftSection[result.source.index];
      updatedLeftSection.splice(result.source.index, 1);
      updatedLeftSection.splice(result.destination.index, 0, movedProperty);
      updatedLeftSection.forEach((property, index) => {
        property.sequence = `L${index}`;
      });
    } else if (sourceId === 'right-section' && destinationId === 'right-section') {
      const movedProperty = updatedRightSection[result.source.index];
      updatedRightSection.splice(result.source.index, 1);
      updatedRightSection.splice(result.destination.index, 0, movedProperty);
      updatedRightSection.forEach((property, index) => {
        property.sequence = `R${index}`;
      });
    } else if (sourceId === 'left-section' && destinationId === 'right-section') {
      moveBetweenSections(updatedLeftSection, updatedRightSection, 'R');
      updatedRightSection.forEach((property, index) => {
        property.sequence = `R${index}`;
      });
    } else if (sourceId === 'right-section' && destinationId === 'left-section') {
      moveBetweenSections(updatedRightSection, updatedLeftSection, 'L');
      updatedLeftSection.forEach((property, index) => {
        property.sequence = `L${index}`;
      });
    }
    setLeftSection([...updatedLeftSection]);
    setRightSection([...updatedRightSection]);
  };
  
  
  const handleSaveChanges = () => {
    setProperties([...leftSection, ...rightSection]);
    setEnablePreview(false);
  };

  return (
    <Wrapper>
      <ObjectTypeNameWrapper>{t('Design Layout for')} <MessageWrapper>{objectName} - </MessageWrapper></ObjectTypeNameWrapper>
      <AllFieldsWrapper>
        <DragDropContext onDragEnd={handleDragEnd}>
          <LeftSection>
            <CustomDroppable droppableId="left-section">
              {(provided) => (
                <LeftSectionWrapper ref={provided.innerRef} {...provided.droppableProps}>
                  {
                    leftSection?.length > 0 ? leftSection?.map((property: IProperty, index: number) => {
                      if (property?.propertyType === PROPERTY_TYPE.Text) {
                        return (
                          <Draggable key={property.key} draggableId={property.key} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <InputWrapper>
                                  <Input
                                    key={property.key}
                                    type='text'
                                    id={property.key}
                                    label={property.displayName}
                                    isRequired={property.isRequired}
                                  />
                                </InputWrapper>
                              </div>)}
                          </Draggable>   
                        );
                      } else if(property?.propertyType === PROPERTY_TYPE.TextArea) {
                        return (
                          <Draggable key={property.key} draggableId={property.key} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <TextAreaWrapper>
                                  <TextArea rows={(property.rows as number)} isRequired={property.isRequired} cols={(property.cols as number)} id={property.key} label={property.displayName} />
                                </TextAreaWrapper>
                              </div>)}
                          </Draggable>   
                        );
                      } else if(property?.propertyType === PROPERTY_TYPE.Image) {
                        return (
                          <Draggable key={property.key} draggableId={property.key} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <ImageSectionWrapper>
                                  <LabelWrapper>{property.displayName}{property.isRequired && <RequiredStatus>*</RequiredStatus>}</LabelWrapper>  
                                  <SingleImageAttributeWrapper>
                                    <DropText>{t('Drag and drop here')}</DropText>
                                    <ButtonWithIcon disabled variant='primary' icon='Add' position='right'>{t('Upload')}</ButtonWithIcon>
                                    <input type='file' id={property.key} style={{display: 'none'}} />
                                  </SingleImageAttributeWrapper>
                                </ImageSectionWrapper>
                              </div>)}
                          </Draggable>  
                        );
                      } else if(property?.propertyType === PROPERTY_TYPE.SelectField) {
                        const selectOptions: IOption[] = (property.selectOptions as string[]).map((option: string) => {return {name: option, value: option};});
                        return (
                          <Draggable key={property.key} draggableId={property.key} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <SelectFieldWrapper>
                                  <SelectField label={property.displayName} isRequired={property.isRequired} id={property.key} options={selectOptions} placeholder='Please select' />
                                </SelectFieldWrapper>
                              </div>)}
                          </Draggable> 
                        );
                      } else if(property?.propertyType === 'multi-image'){
                        return (
                          <Draggable key={property.key} draggableId={property.key} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <SectionWrapper>
                                  <Label labelText={property.displayName} isMandatory={property.isRequired} />
                                  <ImagesSectionWrapper>
                                    <UploadImageBox
                                      onDragOver={(e) => e.preventDefault()}
                                    >
                                      <ContentWrapper>
                                        <IconWrapper>
                                          <Icon icon='Image' size={30}/>
                                        </IconWrapper>
                                        <ImageText>
                                          <LabelText>{t('Drag and Drop image or Click to Upload File')}*</LabelText>
                                        </ImageText>
                                        <Separator>
                                          <LabelText>{t('OR')}</LabelText>
                                        </Separator>
                                        <Separator>
                                          <ButtonWithIcon color='inverse' disabled={true} variant='primary' icon='Add' position='right'>{t('Upload Images')}</ButtonWithIcon>
                                        </Separator>
                                      </ContentWrapper>
                                    </UploadImageBox>
                                    <HiddenInput type='file' id={property.key} multiple accept=".jpg,.png,.jpeg" />
                                    <UploaderWrapper></UploaderWrapper>
                                  </ImagesSectionWrapper>
                                </SectionWrapper>
                              </div>)}
                          </Draggable> 
                        );
                      } else {
                        return <div></div>;
                      }
                    }) : <EmptySection></EmptySection>
                  }
                </LeftSectionWrapper>
              )}
            </CustomDroppable>
          </LeftSection>
          <LeftSection>
            <CustomDroppable droppableId="right-section">
              {(provided) => (
                <LeftSectionWrapper ref={provided.innerRef} {...provided.droppableProps}>
                  {
                    rightSection?.length > 0 ? rightSection?.map((property: any, index: number) => {
                      if (property?.propertyType === PROPERTY_TYPE.Text) {
                        return (
                          <Draggable key={property.key} draggableId={property.key} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <InputWrapper>
                                  <Input
                                    key={property.key}
                                    type='text'
                                    id={property.key}
                                    label={property.displayName}
                                    isRequired={property.isRequired}
                                  />
                                </InputWrapper>
                              </div>)}
                          </Draggable>   
                        );
                      } else if(property?.propertyType === PROPERTY_TYPE.TextArea) {
                        return (
                          <Draggable key={property.key} draggableId={property.key} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <TextAreaWrapper>
                                  <TextArea rows={property.rows} isRequired={property.isRequired} cols={property.cols} id={property.key} label={property.displayName} />
                                </TextAreaWrapper>
                              </div>)}
                          </Draggable>   
                        );
                      } else if(property?.propertyType === PROPERTY_TYPE.Image) {
                        return (
                          <Draggable key={property.key} draggableId={property.key} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <ImageSectionWrapper>
                                  <LabelWrapper>{property.displayName}{property.isRequired && <RequiredStatus>*</RequiredStatus>}</LabelWrapper>  
                                  <SingleImageAttributeWrapper>
                                    <DropText>{t('Drag and drop here')}</DropText>
                                    <ButtonWithIcon disabled variant='primary' icon='Add' position='right'>{t('Upload')}</ButtonWithIcon>
                                    <input type='file' id={property.key} style={{display: 'none'}} />
                                  </SingleImageAttributeWrapper>
                                </ImageSectionWrapper>
                              </div>)}
                          </Draggable>  
                        );
                      } else if(property?.propertyType === PROPERTY_TYPE.SelectField) {
                        const selectOptions = property.selectOptions.map((option: string | number) => {return {name: option, value: option};});
                        return (
                          <Draggable key={property.key} draggableId={property.key} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <SelectFieldWrapper>
                                  <SelectField label={property.displayName} isRequired={property.isRequired} id={property.key} options={selectOptions} placeholder='Please select' />
                                </SelectFieldWrapper>
                              </div>)}
                          </Draggable> 
                        );
                      } else if(property?.propertyType === 'multi-image'){
                        return (
                          <Draggable key={property.key} draggableId={property.key} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <SectionWrapper>
                                  <Label labelText={property.displayName} isMandatory={property.isRequired} />
                                  <ImagesSectionWrapper>
                                    <UploadImageBox
                                      onDragOver={(e) => e.preventDefault()}
                                    >
                                      <ContentWrapper>
                                        <IconWrapper>
                                          <Icon icon='Image' size={30}/>
                                        </IconWrapper>
                                        <ImageText>
                                          <LabelText>{t('Drag and Drop image or Click to Upload File')}*</LabelText>
                                        </ImageText>
                                        <Separator>
                                          <LabelText>{t('OR')}</LabelText>
                                        </Separator>
                                        <Separator>
                                          <ButtonWithIcon color='inverse' disabled={true} variant='primary' icon='Add' position='right'>{t('Upload Images')}</ButtonWithIcon>
                                        </Separator>
                                      </ContentWrapper>
                                    </UploadImageBox>
                                    <HiddenInput type='file' id={property.key} multiple accept=".jpg,.png,.jpeg" />
                                    <UploaderWrapper>
                                    </UploaderWrapper> 
                                  </ImagesSectionWrapper>
                                </SectionWrapper>
                              </div>)}
                          </Draggable> 
                        );
                      } else if(property?.propertyType === 'empty') {
                        return <NoElementComponent></NoElementComponent>;
                      } else {
                        return <div></div>;
                      }
                    }) : <EmptySection></EmptySection>
                  }
                </LeftSectionWrapper>
              )}
            </CustomDroppable>
          </LeftSection>
        </DragDropContext>
      </AllFieldsWrapper>
      <AddObjectWrapper>
        <Button variant='primary' onClick={handleSaveChanges}>{t('OK')}</Button>
      </AddObjectWrapper>
    </Wrapper>
  );
};

export default AddObject;