import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgAlertTriangle = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M10.29 3.86 1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0ZM12 9v4M12 17h.01"
        stroke={props.color}
        strokeWidth={props.weight}
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={"non-scaling-stroke"}
      />
    </svg>
  );
};
export default SvgAlertTriangle;
