import { gql } from '@apollo/client';

export const DETECTION_SUB = gql`
  subscription getDetectionsSub($payload: DetectionSubscriptionInput!) {
    getDetectionsSub(payload: $payload) {
      result {
        detectionData
        objectName
        streamId
        algoCode
      }
      statusCode
      statusMessage
    }
  }
`;