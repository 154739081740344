import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Redirect,
  Route,
  BrowserRouter as Router, Switch,
  useHistory,
} from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import config from './configs/config.json';
import AddCamera from './pages/AddCamera';
import Menus from './components/Menus';
import ClientConfiguration from 'pages/settings/ClientConfiguration';
import ObjectList from 'pages/ObjectList';
import Schedules from 'pages/settings/Schedules';
import AddObject from 'pages/AddObject';
import AddSchedule from 'pages/settings/AddSchedule';
import CameraConfigurations from 'pages/CameraConfigurations';
import EditSchedule from 'pages/settings/EditSchedule';
import EventPipeline from 'pages/settings/EventPipeline';
import EditEventPipeline from 'pages/settings/EditEventPipeline';
import ExportAlerts from 'pages/ExportAlerts';
import Export from 'pages/settings/Export';
import Login from 'pages/Login';
import SecureRoute from 'SecureRoute';
import { HeaderProvider } from 'contexts/HeaderContext';
import { ScrollBarCss } from 'CommonStyles';
import Dashboard from 'pages/Dashboard';
import ImportChartPage from 'components/modals/ImportChartPage';
import CameraDetails from 'pages/CameraDetails';
import AlertsPage from 'pages/AllAlertsPage';
import { AnnotationsProvider } from 'contexts/AnnotaionContext';
import AlertConfiguration from 'pages/settings/AlertSettings';
import Integration from 'pages/settings/Integration';
import MyProfile from 'pages/MyProfile';
import SystemConfiguration from 'pages/settings/SystemConfiguration';
import { AlertsContextProvider } from 'contexts/AlertsContext';
import AddObjectType from 'pages/AddObjectType';
import ImportObjects from 'pages/ImportObjects';
import Forgotpassword from 'pages/Forgotpassword';
import AddNewEventpipeline from 'pages/settings/AddNewEventpipeline';
import CameraManagement from 'pages/CameraManagement';
import Detections from 'components/Detections';
import FloorMap from 'pages/settings/FloorMap';
import VideoArchives from 'pages/VideoArchives';
import { DetectionProvider } from 'contexts/DetectionContext';
import AddAIServer from 'pages/AddAIServer';
import AIServersList from 'pages/AIServersList';
import awsConfig from './lib/amplify';
import Amplify, { Auth } from 'aws-amplify';
import Register from 'pages/Register';
import TopBar from 'components/TopBar';
import CustomLoader from 'components/CustomLoader';
import Folder from 'pages/camera-pages/Folder';

const Container = styled.div`
  display: flex;
  height: 100%;
`;

const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  width: calc(100% - 110px); 
`;

const Wrapper = styled.div`
  overflow-y: auto;
  width: fill-available;
  height: calc(100vh - 56px);
  ${ScrollBarCss}
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 700px;
  gap: 10px;
`;

interface IProps {
  darkMode:boolean;
  setDarkMode:(darkMode:boolean)=> void;
}

Amplify.configure(awsConfig);

const CloudApp: React.FC<IProps> = ({darkMode,setDarkMode}) => {
  window.document.title = config.projectName;
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true);
  const [query,setQuery] = useState('');
  const { pathname } = useLocation();
  const [isforgotPassword, setIsforgotPassword] = useState(pathname === '/forgot-password');
  const [isRegister, setIsRegister] = useState(pathname === '/register');
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setIsforgotPassword(pathname === '/forgot-password');
    setIsRegister(pathname === '/register');
  }, [pathname, isforgotPassword, isRegister]);

  useEffect(() => {
    if(!localStorage.getItem('dashboardFilters')) {
      localStorage.setItem('dashboardFilters', '{"dateFilter":{"startDate":"","endDate":""},"selectedGroup":[],"selectedCamera":[]}');
    }
    if(!localStorage.getItem('isShowCharts')) {
      localStorage.setItem('isShowCharts', '{"allAlertsPage": true, "cameradetailspage": false, "cameraspage": false}');
    }
  }, []);

  // const fetchUsername = useCallback(async () => {
  //   setLoading(true);
  //   try {
  //     const userInfo = await Auth.currentUserInfo();
  //     console.log(userInfo, 'fghjkl;');
  //     if(userInfo !== null) {
  //       setIsAuthenticated(true);
  //     } else {
  //       setIsAuthenticated(false);
  //       if(isRegister) {
  //         history.push('/register');
  //       } else if(isforgotPassword) {
  //         history.push('/forgot-password');
  //       } else {
  //         history.push('/login');
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Failed to fetch user info:', error);
  //     history.push('/login');
  //     setIsAuthenticated(false);
  //   } finally {
  //     setLoading(false);
  //   }
  // }, [history, isRegister, isforgotPassword]);

  const fetchUsername = useCallback(async () => {
    const retries = 3;
    let delay = 500;
    
    const retryFetch = async (attempt: number) => {
      try {
        const userInfo = await Auth.currentUserInfo();
        if (userInfo) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
          if (isRegister) {
            history.push('/register');
          } else if (isforgotPassword) {
            history.push('/forgot-password');
          } else {
            history.push('/login');
          }
        }
      } catch (error: any) {
        if (error.name === 'TooManyRequestsException' && attempt < retries) {
          setTimeout(() => retryFetch(attempt + 1), delay);
          delay *= 2;
        } else {
          console.error('Failed to fetch user info after retries:', error);
          setIsAuthenticated(false);
          history.push('/login');
        }
      } finally {
        setLoading(false);
      }
    };
  
    setLoading(true);
    await retryFetch(0);
  }, [history, isRegister, isforgotPassword]);
  
  useEffect(() => {
    fetchUsername();
  }, [fetchUsername, isAuthenticated]);

  const getApp = () => {
    return (
      <Container>
        <HeaderProvider>
          <AnnotationsProvider>
            <AlertsContextProvider>
              <Menus menuOptions={config.menulist} setDarkMode={setDarkMode} darkMode={darkMode} />
              <MainContainer>
                <TopBar query={query} setQuery={setQuery} />
                <Wrapper>
                  <SecureRoute exact path={['/', '/login']}>
                    <Redirect to='/cameras' />
                  </SecureRoute>
                  <SecureRoute path='/cameras' exact
                    render={() => (
                      <DetectionProvider>
                        <CameraManagement />
                      </DetectionProvider>
                    )} />
                  <SecureRoute path='/alerts' exact component={AlertsPage} />
                  <SecureRoute path='/detections' exact component={Detections} />
                  <SecureRoute path='/add-camera' exact component={AddCamera} />
                  <SecureRoute path='/cameras/:instanceId' exact component={CameraDetails} />
                  <SecureRoute path='/edit-camera' exact component={AddCamera} />
                  <SecureRoute path='/cameras/:instanceId/configurations' exact component={CameraConfigurations} />
                  <SecureRoute path='/cameras/:instanceId/folder' exact component={Folder} />
                  <SecureRoute path='/settings/client-configuration' exact component={ClientConfiguration} />
                  <SecureRoute path='/settings/schedules' exact component={Schedules} />
                  <SecureRoute path='/settings/floor-map' exact component={FloorMap} />
                  <SecureRoute path='/settings/edit-schedule/:id' exact component={EditSchedule} />
                  <SecureRoute path='/settings/event-pipeline' exact component={EventPipeline} />
                  <SecureRoute path='/settings/add-event-pipeline' exact component={AddNewEventpipeline} />
                  <SecureRoute path='/settings/edit-event-pipeline/:id' exact component={EditEventPipeline} />
                  <SecureRoute path='/export-alerts' exact component={ExportAlerts} />
                  <SecureRoute path='/settings/add-schedule' exact component={AddSchedule} />
                  <SecureRoute path='/objects' exact component={ObjectList} />
                  <SecureRoute path='/objects/:objectId/add' exact component={AddObject} />
                  <SecureRoute path='/objects/importobjects/:id' exact component={ImportObjects} />
                  <SecureRoute path='/objects/:objectId/edit/:editId' exact component={AddObject} />
                  <SecureRoute path='/settings/export' exact component={Export} />
                  <SecureRoute path='/dashboard' exact component={Dashboard} />
                  <SecureRoute path='/cameraspage/selectcharts' exact component={ImportChartPage} />
                  <SecureRoute path='/dashboardpage/selectcharts' exact component={ImportChartPage} />
                  <SecureRoute path='/cameradetailspage/:instanceId/selectcharts' exact component={ImportChartPage} />
                  <SecureRoute path='/alertspage/selectcharts' exact component={ImportChartPage} />
                  <SecureRoute path='/settings/alert' exact component={AlertConfiguration} />
                  <SecureRoute path='/settings/addServer' exact component={AddAIServer} />
                  <SecureRoute path='/settings/editServer/:serverId' exact component={AddAIServer} />
                  <SecureRoute path='/settings/servers' exact component={AIServersList} />
                  <SecureRoute path='/settings/integration' exact component={Integration} />
                  <SecureRoute path='/my-profile' exact component={MyProfile} />
                  <SecureRoute path='/cameras/archives/videos' exact component={VideoArchives} />
                  <SecureRoute path="/objects/addobjecttype" exact component={AddObjectType} />
                  <SecureRoute path="/objects/editobjecttype/:editId" exact component={AddObjectType} />
                  <SecureRoute path='/settings/system-configuration' exact component={SystemConfiguration} />
                </Wrapper>
              </MainContainer>
            </AlertsContextProvider>
          </AnnotationsProvider>
        </HeaderProvider>
      </Container>
    );
  };

  const getLoginRoute = () => {
    if (isforgotPassword) {
      return <Route path='/forgot-password' component={Forgotpassword} />;
    } else if(isRegister) {
      return <Route path='/register' render={() => <Register />}/>;
    } else {
      return <Route render={() => <Login setIsAuthenticated={setIsAuthenticated} />}/>;
    }
  };

  if(loading) {
    return <LoadingContainer>
      <CustomLoader />
    </LoadingContainer>;
  }

  return (
    <>
      <Router>
        <Switch>
          {(isAuthenticated) ? getApp() : getLoginRoute()}
        </Switch>
      </Router>
    </>
  );
};

export default CloudApp;