import AlertBar from 'components/AlertBar';
import Icon from 'components/Icon';
import Input from 'components/Input';
import { IReducerActions } from 'components/LineUI/LineReducer';
import { ALGORITHM_CODES, DEFAULT_AREA_POINTS, IconType, LineUIType, OBJECTS_LIST, PERMISSION_CODE, getDayNumberValue, getDayTextValue } from '../../../constants';
import { IAlertMessageType, INewAnalysisConfigsRes, INoEntryVehicleDetectionConfigs, IOption } from 'interface';
import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Config from '../../../configs/config.json';
import { AddNewLineWrapper, DeleteIconDiv, HorizontalDivider, IconDiv, LabelWrapper, LineDetailContainer, LineNameWrapper, LinesContainer, LinesListContainer } from './algoConfigStyles';
import { AiOutlineMinus } from 'react-icons/ai';
import MultipleSelect from 'components/MultiSelectField';
import TimeRange from 'components/TimeRange';
import { isEqual } from 'lodash';
import { checkUniqueNames, getLineUIColor, isNullOrEmpty } from 'utils/utils';
import TokenService from 'api_configs/tokenService';

const NoAreaData = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 100%;
  min-height: 100%;
  border-radius: 5px;
  border: 1px solid #D0D7F2;
  min-height: 310px;
  min-width: 600px;
`;

const NoAreaText = styled.div`
  max-width: 60%;
  font-size: 14px;
  color: ${({theme})=>theme.text.secondaryText};
  text-align: center;
`;

const AddNewAreaButton = styled.div<{disabled:boolean}>`
  background: ${({disabled}) => disabled ? '#8E9FDF' : 'linear-gradient(to top, #1538BD, #7287D7)' } ;
  color: #FFFFFF;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: ${({disabled}) => disabled ? 'not-allowed' : 'pointer' } ;
`;

const LineName = styled.div`
  width: 70px;
  white-space: nowrap;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Label = styled.div`
  font-size: 14px;
`;

const TimeLabel = styled.div`
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: -10px;
`;

const ConfigurationForm = styled.div`
  display: flex;
  gap: 20px;
`;

const AreaPointsHandler = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
`;

const PointsToggleDiv = styled.div<{disabled:boolean}>`
  display: flex;
  align-items: center;
  margin-top: 5px;
  justify-content: space-between;
  background-color: ${({disabled}) => disabled ? '#FAFAFA' : ''};
  height: 40px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid lightgrey;
`;

const PointsCountChanger = styled.div<{disabled:boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border: 1px solid lightgrey;
  border-radius: 6px;
  cursor: pointer;
    ${({ disabled }) =>
    disabled &&
    `
    background-color: #e9e8e8;
    cursor: not-allowed;
  `}
`;

const ScheduleDaysContainer = styled.div`
  margin-top: 30px;
`;

const PointsCount = styled.div``;

interface INoEntryVehicleDetectionProps {
  selectedAlgorithmConfigsLength: number;
  selectedIndex: number;
  onAddNewLine: (a: boolean, data: INoEntryVehicleDetectionConfigs, currentLength: number)=>void;
  handleRemoveLine?: (index: number, length: number)=>void;
  dispatch: React.Dispatch<IReducerActions>;
  onLineClickCallback?: (id: number) => void;
  setSelectedAlgoConfigs: React.Dispatch<React.SetStateAction<INoEntryVehicleDetectionConfigs[]>>;
  selectedAlgoConfigs: INoEntryVehicleDetectionConfigs[];
  scheduleOptions:IOption[];
  selectedAlgorithmConfigDetails: INewAnalysisConfigsRes;
  setSelectedAlgorithmConfigDetails: React.Dispatch<React.SetStateAction<INewAnalysisConfigsRes>>;
  setValidationMessage:  React.Dispatch<React.SetStateAction<string>>
}

interface IRules {
  startTime?: string | undefined;
  endTime?: string | undefined;
  days?: number[];
}

interface IRuleList {
  startTime?: String;
  endTime?: string;
  days?: string[];
}

interface ITimeRange {
  rules?: IRules[] | [];
}

const NoEntryVehicleDetection: React.FC<INoEntryVehicleDetectionProps> = ({selectedAlgorithmConfigsLength, selectedAlgoConfigs, setSelectedAlgoConfigs, setValidationMessage, selectedIndex, dispatch, onAddNewLine, onLineClickCallback=()=>{}, handleRemoveLine=()=>{}}) => {
  const {t} = useTranslation(['common']);
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'});
  const [selectedObjects, setSelectedObjects] = useState<string[]>([]);
  const [updatedSchedule, setUpdatedSchedule] = useState<ITimeRange>({rules: []});

  const editAnalysisAccess = TokenService.hasAccess(PERMISSION_CODE.edit_analysis_configuration);

  useEffect(() => {
    setSelectedObjects(selectedAlgoConfigs[0]?.objects as string[]);
    const ruleList = selectedAlgoConfigs[0]?.time_range && selectedAlgoConfigs[0]?.time_range.map((item: IRuleList) => {  
      const daysList: number[] = [];
      item?.days?.map((item)=> daysList.push(getDayNumberValue[item]));
      return {
        startTime: item?.startTime?.toLocaleLowerCase(),
        endTime: item?.endTime,
        days: daysList
      };
    });
    if(ruleList?.length !== 0){
      setUpdatedSchedule({ rules: ruleList});
    }
  },[selectedAlgoConfigs]);

  const AddNewLine = useCallback(() => {
    if(selectedAlgorithmConfigsLength + 1 > Config.algorithmConfigurations.areasLimit){
      setMessage({text: t('configurationLimitWarning').replace('{X}',`${Config.algorithmConfigurations.areasLimit}`), id:'',type:'danger'});
    }else{
      const newLineData: INoEntryVehicleDetectionConfigs = {
        name: 'New Area' + (selectedAlgorithmConfigsLength + 1),
        pointsCount: 4,
        index: selectedAlgorithmConfigsLength + 1,
        lineType: LineUIType.AREA,
        styling: getLineUIColor(ALGORITHM_CODES.noentry_vehicle_detection) as string,
        objects: [],
        time_range: [],
        points: DEFAULT_AREA_POINTS.map(item => ({x: item.x + (selectedAlgorithmConfigsLength * 20), y: item.y}))
      };
      onAddNewLine(false, newLineData, selectedAlgorithmConfigsLength);
      setSelectedAlgoConfigs(prev => [...prev, newLineData]);
    }
  },[onAddNewLine, selectedAlgorithmConfigsLength, setSelectedAlgoConfigs, t]);

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAlgoConfigs((prev: INoEntryVehicleDetectionConfigs[]) => {
      const selectedAlgoConfigs = [...prev];
      const selectedAreaConfig = JSON.parse(JSON.stringify(selectedAlgoConfigs[selectedIndex - 1])); 
      selectedAreaConfig[e.target.name] = e.target.name !== 'name' ? parseInt(e.target.value) : e.target.value;
      selectedAlgoConfigs[selectedIndex - 1] = selectedAreaConfig;
      return selectedAlgoConfigs;
    });
    if(e.target.name === 'name'){
      dispatch({
        type: 'RENAME_SET',
        index: selectedIndex - 1,
        data: {
          areaName: e.target.value
        }
      });
    }
  },[setSelectedAlgoConfigs, selectedIndex, dispatch]);

  const onRemovePoint = useCallback((currentPointCount: number)=>{
    if(currentPointCount > 3){
      setSelectedAlgoConfigs((prev: INoEntryVehicleDetectionConfigs[]) => {
        const selectedAlgoConfigs = [...prev];
        const selectedAreaConfig = JSON.parse(JSON.stringify(selectedAlgoConfigs[selectedIndex - 1])); 
        selectedAreaConfig.pointsCount = selectedAreaConfig.pointsCount - 1;
        selectedAlgoConfigs[selectedIndex - 1] = selectedAreaConfig;
        return selectedAlgoConfigs;
      });
      dispatch({
        type: 'REMOVE_POINT',
        index: selectedIndex-1,
      });
    }else{
      setMessage({text: t('Minimum 3 points required!'), id:'',type:'danger'});
    }
  },[selectedIndex, dispatch, t, setSelectedAlgoConfigs]);

  const onAddPoint = useCallback(()=>{
    let isLimitExceeded = false;
    setSelectedAlgoConfigs((prev: INoEntryVehicleDetectionConfigs[]) => {
      const selectedAlgoConfigs = [...prev];
      const selectedAreaConfig = JSON.parse(JSON.stringify(selectedAlgoConfigs[selectedIndex - 1])); 
      if(selectedAreaConfig.pointsCount + 1 > Config.algorithmConfigurations.areaPointsLimit){
        setMessage({text: t('pointsLimitWarning').replace('{X}',`${Config.algorithmConfigurations.areaPointsLimit}`), id:'',type:'danger'});
        isLimitExceeded = true;
      }else{
        selectedAreaConfig.pointsCount = selectedAreaConfig.pointsCount + 1;
      }
      selectedAlgoConfigs[selectedIndex - 1] = selectedAreaConfig;
      if(!isLimitExceeded){
        dispatch({
          type: 'ADD_POINT',
          index: selectedIndex-1,
        });
      }
      return selectedAlgoConfigs;
    });
  },[selectedIndex, dispatch, setSelectedAlgoConfigs, t]);

  const handleSelectChange = useCallback((selectedValues: string[]) => {
    setSelectedObjects(selectedValues);
    setSelectedAlgoConfigs((prev: INoEntryVehicleDetectionConfigs[]) => {
      const selectedAlgoConfigs = [...prev];
      const selectedAreaConfig = JSON.parse(JSON.stringify(selectedAlgoConfigs[selectedIndex - 1])); 
      selectedAreaConfig.objects = selectedValues;
      selectedAlgoConfigs[selectedIndex - 1] = selectedAreaConfig;
      return selectedAlgoConfigs;
    });
  },[selectedIndex, setSelectedAlgoConfigs]);

  const getUpdatedTimeRangeList= useCallback((list: object) => {
    if(!isEqual(list, updatedSchedule.rules)){
      setUpdatedSchedule({rules: list as []});
      const ruleList = list && (list as  []).map((item: IRules) => {  
        const daysList: string[] = [];
        item?.days?.map((item: number)=> daysList.push(getDayTextValue[item]));
        return {
          startTime: item?.startTime,
          endTime: item?.endTime,
          days: daysList
        };
      });
      setSelectedAlgoConfigs((prev: INoEntryVehicleDetectionConfigs[]) => {
        const selectedAlgoConfigs = [...prev];
        const selectedAreaConfig = JSON.parse(JSON.stringify(selectedAlgoConfigs[selectedIndex - 1])); 
        selectedAreaConfig.time_range = ruleList;
        selectedAlgoConfigs[selectedIndex - 1] = selectedAreaConfig;
        return selectedAlgoConfigs;
      });
    }
  },[ selectedIndex, setSelectedAlgoConfigs, updatedSchedule.rules]);

  useEffect(() => {
    if (!checkUniqueNames(selectedAlgoConfigs)) {
      setValidationMessage('Duplicate Area Name values are not allowed');
    } else {
      let anyConditionTrue = false;

      for (const config of selectedAlgoConfigs) {
        if (isNullOrEmpty(config.name)) {
          setValidationMessage('Area Name should not be empty or null');
          anyConditionTrue = true;
          break;
        }
      }

      if (!anyConditionTrue) {
        setValidationMessage('');
      }
    }
  }, [selectedAlgoConfigs, setValidationMessage]);

  return (
    <ConfigurationForm>
      { message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
      {selectedIndex === 0  ? 
        <NoAreaData>
          <NoAreaText>{t('No area have been configured yet, please add new area')}</NoAreaText>
          <AddNewAreaButton onClick={editAnalysisAccess ? AddNewLine : undefined} disabled={!editAnalysisAccess}>{t('Add Area')}</AddNewAreaButton>
        </NoAreaData>:
        <><LinesContainer>
          <AddNewLineWrapper>
            <LabelWrapper>
              <Icon icon='Line' size={20} />
              <Label>{t('Areas')}</Label>
            </LabelWrapper>
            <IconDiv title={t('Add New Areas')} onClick={AddNewLine}>
              { editAnalysisAccess && <Icon icon='Plus' size={16} />}
            </IconDiv>
          </AddNewLineWrapper>
          {selectedIndex !== 0 &&
            <LinesListContainer>
              {(selectedAlgoConfigs as INoEntryVehicleDetectionConfigs[])?.map((line, index) => (
                <>
                  <LineNameWrapper isSelected={selectedIndex === index + 1} onClick={() => onLineClickCallback(index)}>
                    <div>{index + 1}.</div>
                    <LineName>{line?.name}</LineName>
                  </LineNameWrapper>
                  {index !== selectedAlgoConfigs.length - 1 && <HorizontalDivider />}
                </>
              ))}
            </LinesListContainer>}
        </LinesContainer>
        <LineDetailContainer>
          { editAnalysisAccess &&
          <DeleteIconDiv onClick={() => handleRemoveLine(selectedIndex - 1, selectedAlgorithmConfigsLength)}>
            <Icon icon='Delete' size={16} />
          </DeleteIconDiv>}
          <Input id='name' type='text' isRequired name='name' label={t('Area Name')} disabled={!editAnalysisAccess} maxLength={16} value={selectedAlgoConfigs[selectedIndex - 1].name} onChange={handleInputChange} />
          <AreaPointsHandler>
            <Label>{t('Points')}</Label>
            <PointsToggleDiv disabled={!editAnalysisAccess}>
              <PointsCountChanger onClick={() => onRemovePoint(selectedAlgoConfigs[selectedIndex - 1]?.pointsCount as number)}  disabled={!editAnalysisAccess}><AiOutlineMinus /></PointsCountChanger>
              <PointsCount>{selectedAlgoConfigs[selectedIndex - 1].pointsCount}</PointsCount>
              <PointsCountChanger onClick={onAddPoint} disabled={!editAnalysisAccess}><Icon icon='Add' size={20} /></PointsCountChanger>
            </PointsToggleDiv>
          </AreaPointsHandler>
          <MultipleSelect
            width='450px'
            placeholder={t('Objects')}
            label={t('Objects')}
            options={OBJECTS_LIST}
            selectedValues={selectedObjects}
            onChange={handleSelectChange}
            disabled={!editAnalysisAccess}
          />
          <TimeLabel>{t('Time Range')}</TimeLabel>
          <ScheduleDaysContainer>
            <TimeRange actionIcons hideActionButtons isUpdateList getTimeRangeList={getUpdatedTimeRangeList} TimeRangeDetails={updatedSchedule?.rules} />
          </ScheduleDaysContainer>
        </LineDetailContainer>
        </>}
    </ConfigurationForm>
  );
};

export default NoEntryVehicleDetection;