import styled from 'styled-components';

export const LinesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 130px;
`;

export const AddNewLineWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 6px;
`;

export const LineDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  gap: 10px;
  position: relative;
`;

export const LinesListContainer = styled.div`
  border: 1px solid ${({theme}) => theme.divider};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Label = styled.div`
  font-size: 14px;
`;

export const IconDiv = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  z-index: 9;
  border-radius: 5px;
  background-color: #DFDFDF;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LineName = styled.div`
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const HorizontalDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({theme})=>theme.divider};
`;

export const LineNameWrapper = styled.div<{isSelected?: boolean}>`
  cursor: pointer;
  color: ${({ isSelected}) => isSelected ? '#FFFFFF' : 'black'};
  background-color: ${({isSelected}) => isSelected ? '#7287D7' : '#FFFFFF' };
  padding: 6px 10px;
  display: flex;
  gap: 10px;
  font-size: 14px;
`;

export const NoDataContainer = styled.div`
  border: 1px solid ${({theme}) => theme.divider};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  text-align: center;
  height: 275px;
`;

export const DeleteIconDiv = styled.div`
  position: absolute;
  top: 5px;
  right: 0;
  width: 24px;
  height: 24px;
  cursor: pointer;
  z-index: 9;
  border-radius: 5px;
  background-color: #DFDFDF;
  display: flex;
  align-items: center;
  justify-content: center;
`;