import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  format,
  addMonths,
  subMonths,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  addDays,
  isSameDay,
  isSameMonth,
  getYear,
  setYear,
  addYears,
  subYears
} from 'date-fns';
import { useClickOutside } from 'hooks/useClickOutside';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const ParentWrapper = styled.div`
  width: 440px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: fit-content;
`;

const DateWrapper = styled.div<{ isActive: boolean }>`
  flex: 1;
  padding: 8px 10px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  z-index: 1;
`;

const ResetButton = styled.button`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) !important;
  padding: 2px 20px;
  opacity: 1;
  background-color: #4d42e7;
  border: none;
  cursor: pointer;
  clip-path: polygon(0 0, 100% 0, 80% 100%, 20% 100%);
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  color: white;
  font-weight: bold;
  font-size: 12px;
  z-index: 4;
  &:hover{
    background-color: #4d42e7;
  }
  &:disabled {
    background-color: #A4B0C7;
    cursor: no-drop;
  }
  transition: 0.3s ease;
`;

const Divider = styled.div`
  width: 1px;
  height: 50%;
  padding: 8px 0;
  border: 1px solid #aaa;
`;

const BorderIndicator = styled.div<{ selected: string; enabled: boolean }>`
  ${({ enabled, selected }) =>
    enabled &&
    css`
      left: ${selected === 'from' ? '0' : '50%'};
      border: 2px solid #007bff;
      background-color: #d6e8f7;
      border-radius: 8px;
      top: 0;
      position: absolute;
      width: 50%;
      height: 100%;
      transition: 0.3s;
    `}
  z-index: 0;
`;

const SelectHeader = styled.div<{ align: string }>`
  text-align: ${({ align }) => align};
  font-size: 14px;
  color: ${({ theme }) => theme.text.secondaryText};
`;

const DateText = styled.span<{ align: string }>`
  display: block;
  font-size: 16px;
  font-weight: 600;
  text-align: ${({ align }) => align};
  color: #6b6c6f;
`;

const DatePickerWrapper = styled.div<{ activeField: string }>`
  position: absolute;
  top: 56px;
  left: ${({ activeField }) => (activeField === 'from' ? 0 : '')};
  right: ${({ activeField }) => (activeField === 'to' ? 0 : '')};
  z-index: 1000;
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 10px;
  :nth-child(3) > div {
    text-align: center;
  }
`;

const DatePickerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  align-items: center;
`;

const MonthYear = styled.div`
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 4px;
`;

const MonthAction = styled.button`
  border: none;
  outline: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
  &:hover {
    background-color: #d6e8f7;
  }
  transition: all 0.3s ease;
`;

const DayNames = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px;
  font-weight: bold;
  > div {
    width: 46px;
  }
`;

const DaysGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
  padding: 10px;
  border-bottom: 1px solid #CCC;
`;

const YearsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  padding: 10px;
`;

const Day = styled.div<{ isCurrentMonth: boolean; isSelected: boolean; disabled: boolean }>`
  padding: 8px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  ${(props) =>
    !props.isCurrentMonth &&
    css`
      color: #ccc;
    `}
  ${(props) =>
    props.isSelected &&
    css`
      background: #9dccf3;
      color: #ffffff;
    `}
  ${(props) =>
    props.disabled &&
    css`
      color: #ccc;
      pointer-events: none;
    `}
  &:hover {
    background-color: #d6e8f7;
  }
  transition: all 0.3s ease;
`;


const Year = styled.div<{ isSelected: boolean }>`
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  ${(props) =>
    props.isSelected &&
    css`
      background: #007bff;
      color: #fff;
    `}
`;

const TimePicker = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 10px;
`;

const TimeInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TimeSeparator = styled.div`
  margin-bottom: 24px;
`;

const TimeInput = styled.input`
  width: 60px;
  padding: 5px;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  &:focus {
    border: 1px solid #007bff;
  }
`;

const Label = styled.label`
  font-size: 12px;
  margin-top: 5px;
  color: #666;
`;

const AmPmToggle = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const AmPmButton = styled.button<{ isActive: boolean }>`
  padding: 4px 10px;
  border: 1px solid #ccc;
  font-size: 12px;
  border-radius: 5px;
  background-color: ${(props) => (props.isActive ? '#007bff' : '#fff')};
  color: ${(props) => (props.isActive ? '#fff' : '#000')};
  cursor: pointer;
  &:first-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  &:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  &:hover {
    background-color: #d6e8f7;
  }
`;

interface ICalendarProps {
  onDateRangeCallback?: (fromDate: string | null, toDate: string | null) => void;
  defaultFromDatePlaceholder?: string;
  defaultToDatePlaceholder?: string;
  clearDateRange?: boolean;
  setDateFilter?: any
}

const Calendar: React.FC<ICalendarProps> = ({onDateRangeCallback=()=>{}, defaultFromDatePlaceholder='Select date', defaultToDatePlaceholder='Select date', clearDateRange=false, setDateFilter=null}) => {
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [fromTime, setFromTime] = useState({ hours: '12', minutes: '00', seconds: '00', ampm: 'AM' });
  const [toTime, setToTime] = useState({ hours: '12', minutes: '00', seconds: '00', ampm: 'AM' });
  const [activeField, setActiveField] = useState<'from' | 'to' | null>(null);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentYear, setCurrentYear] = useState(getYear(new Date()));
  const [view, setView] = useState<'month' | 'year'>('month');
  const selectDateRef = useRef<HTMLDivElement | null>(null);
  const {t} = useTranslation(['common']);
  useClickOutside(selectDateRef, () => setActiveField(null));
  const [isFilterApplied, setIsFilterApllied] = useState<boolean>(false);

  const convertTo24HourTimeInSeconds = (time: { hours: string, minutes: string, seconds: string, ampm: string }) => {
    let hours = parseInt(time.hours, 10);
    const minutes = parseInt(time.minutes, 10);
    const seconds = parseInt(time.seconds, 10);
    if (time.ampm === 'PM' && hours < 12) {
      hours += 12;
    } else if (time.ampm === 'AM' && hours === 12) {
      hours = 0;
    }
    return hours * 3600 + minutes * 60 + seconds;
  };

  const isFromTimeLessThanToTime = useCallback((
    fromTime: { hours: string, minutes: string, seconds: string, ampm: string },
    toTime: { hours: string, minutes: string, seconds: string, ampm: string }
  ): boolean => {
    const fromTotalSeconds = convertTo24HourTimeInSeconds(fromTime);
    const toTotalSeconds = convertTo24HourTimeInSeconds(toTime);
    return fromTotalSeconds <= toTotalSeconds;
  }, []);

  const formatDateTime = useCallback((date: Date | null, time: { hours: string; minutes: string; seconds: string; ampm: string }, type?: string) => {
    if (!date) return type ? type === 'from' ? t(defaultFromDatePlaceholder) : t(defaultToDatePlaceholder) : t('Select Date');
    const formattedDate = format(date, 'dd/MM/yyyy');
    const formattedTime = `${time.hours}:${time.minutes}:${time.seconds} ${time.ampm}`;
    return `${formattedDate} ${formattedTime}`;
  },[defaultFromDatePlaceholder, defaultToDatePlaceholder, t]);

  const handleDateApply = useCallback(() => {
    const startDate = formatDateTime(fromDate, fromTime);
    const endDate = formatDateTime(toDate, toTime);
    if(isSameDay(fromDate as Date, toDate as Date)) {
      if(!isFromTimeLessThanToTime(fromTime, toTime)) {
        return;
      }
    }
    onDateRangeCallback(startDate, endDate);
    setActiveField(null);
    setIsFilterApllied(true);
  },[fromDate, toDate, fromTime, toTime, onDateRangeCallback, isFromTimeLessThanToTime, formatDateTime]);

  useEffect(() => {
    if(clearDateRange) {
      setFromDate(null);
      setToDate(null);
      setFromTime({ hours: '12', minutes: '00', seconds: '00', ampm: 'AM' });
      setToTime({ hours: '12', minutes: '00', seconds: '00', ampm: 'AM' });
      onDateRangeCallback(null, null);
      setIsFilterApllied(prev => !prev);
    }
  }, [onDateRangeCallback, clearDateRange]);

  const handleDateClick = (field: 'from' | 'to', date: Date) => {
    if (field === 'from') {
      setFromDate(date);
    } else {
      setToDate(date);
    }
    setActiveField(null);
  };

  const handleYearClick = (year: number) => {
    setCurrentYear(year);
    setCurrentMonth(setYear(currentMonth, year));
    setView('month');
  };

  const renderHeader = () => {
    return (
      <DatePickerHeader>
        <MonthAction onClick={() => view === 'month' ? setCurrentMonth(subMonths(currentMonth, 1)) : setCurrentYear(Number(subYears(currentYear, 12)))}>
          <FaAngleLeft />
        </MonthAction>
        <MonthYear onClick={() => setView(view === 'month' ? 'year' : 'month')}>
          {view === 'month' ? format(currentMonth, 'MMMM yyyy') : `${currentYear - 6} - ${currentYear + 5}`}
        </MonthYear>
        <MonthAction onClick={() => view === 'month' ? setCurrentMonth(addMonths(currentMonth, 1)) : setCurrentYear(Number(addYears(currentYear, 12)))}>
          <FaAngleRight />
        </MonthAction>
      </DatePickerHeader>
    );
  };

  const renderDays = () => {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);
    const today = new Date();
    const rows = [];
    let days = [];
    let day = startDate;
  
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        const formattedDate = format(day, 'd');
        const cloneDay = day;
        const isDisabled =
          (activeField === 'from' && toDate && day > toDate) ||
          (activeField === 'to' && fromDate && day < fromDate) || 
          day > today;
  
        days.push(
          <Day
            key={day.toString()}
            isCurrentMonth={isSameMonth(day, monthStart)}
            isSelected={
              (activeField === 'from' && fromDate && isSameDay(day, fromDate)) ||
              (activeField === 'to' && toDate && isSameDay(day, toDate)) as boolean
            }
            onClick={() => handleDateClick(activeField!, cloneDay)}
            disabled={isDisabled as boolean}
          >
            {formattedDate}
          </Day>
        );
        day = addDays(day, 1);
      }
      rows.push(<React.Fragment key={day.toString()}>{days}</React.Fragment>);
      days = [];
    }
    return <DaysGrid>{rows}</DaysGrid>;
  };

  const renderYears = () => {
    const startYear = currentYear - 6;
    const years = [];

    for (let i = startYear; i < startYear + 12; i++) {
      years.push(
        <Year
          key={i}
          isSelected={i === currentYear}
          onClick={() => handleYearClick(i)}
        >
          {i}
        </Year>
      );
    }
    return <YearsGrid>{years}</YearsGrid>;
  };

  const validateTimeInput = (value: string, max: number) => {
    const num = parseInt(value, 10);
    if (isNaN(num) || num < 0 || num > max) return '';
    return value;
  };

  const handleTimeChange = (field: 'from' | 'to', timePart: string, value: string) => {
    const timeSetter = field === 'from' ? setFromTime : setToTime;
    const currentTime = field === 'from' ? fromTime : toTime;
  
    let validatedValue = value;
    if (timePart === 'hours') {
      validatedValue = validateTimeInput(value, 12);
    } else if (timePart === 'minutes' || timePart === 'seconds') {
      validatedValue = validateTimeInput(value, 59);
    }
  
    timeSetter({ ...currentTime, [timePart]: validatedValue });
  };
  

  const renderTimePicker = (field: 'from' | 'to') => {
    const time = field === 'from' ? fromTime : toTime;

    return (
      <TimePicker>
        <TimeInputWrapper>
          <TimeInput
            type="text"
            placeholder="HH"
            value={time.hours}
            onChange={(e) => handleTimeChange(field, 'hours', e.target.value)}
          />
          <Label>{t('Hr')}</Label>
        </TimeInputWrapper>
        <TimeSeparator>:</TimeSeparator>
        <TimeInputWrapper>
          <TimeInput
            type="text"
            placeholder="MM"
            value={time.minutes}
            onChange={(e) => handleTimeChange(field, 'minutes', e.target.value)}
          />
          <Label>{t('Min')}</Label>
        </TimeInputWrapper>
        <TimeSeparator>:</TimeSeparator>
        <TimeInputWrapper>
          <TimeInput
            type="text"
            placeholder="SS"
            value={time.seconds}
            onChange={(e) => handleTimeChange(field, 'seconds', e.target.value)}
          />
          <Label>{t('Sec')}</Label>
        </TimeInputWrapper>
        <AmPmToggle>
          <AmPmButton
            isActive={time.ampm === 'AM'}
            onClick={() => handleTimeChange(field, 'ampm', 'AM')}
          >
            {t('AM')}
          </AmPmButton>
          <AmPmButton
            isActive={time.ampm === 'PM'}
            onClick={() => handleTimeChange(field, 'ampm', 'PM')}
          >
            {t('PM')}
          </AmPmButton>
        </AmPmToggle>
      </TimePicker>
    );
  };

  const resetDates = (e: React.MouseEvent) => {
    e.stopPropagation();
    setFromDate(null);
    setToDate(null);
    setFromTime({ hours: '12', minutes: '00', seconds: '00', ampm: 'AM' });
    setToTime({ hours: '12', minutes: '00', seconds: '00', ampm: 'AM' });
    onDateRangeCallback(null, null);
    setIsFilterApllied(prev => !prev);
    if(setDateFilter) {
      setDateFilter({startDate: '', endDate: ''});
    }
  };

  return (
    <ParentWrapper ref={selectDateRef}>
      <ResetButton disabled={!(fromDate && toDate)} className="reset-button" onClick={(e) => !isFilterApplied ? handleDateApply() : resetDates(e)}>
        {isFilterApplied ? t('Clear') : t('Apply')}
      </ResetButton>
      <DateWrapper
        isActive={activeField === 'from'}
        onClick={() => setActiveField('from')}
      >
        <SelectHeader align="left">{t('From')}</SelectHeader>
        <DateText align="left">{formatDateTime(fromDate, fromTime, 'from')}</DateText>
        {activeField === 'from' && (
          <DatePickerWrapper activeField={activeField !== null ? activeField : ''}>
            {renderHeader()}
            {view === 'month' ? (
              <>
                <DayNames>
                  <div>{t('Sun')}</div>
                  <div>{t('Mon')}</div>
                  <div>{t('Tue')}</div>
                  <div>{t('Wed')}</div>
                  <div>{t('Thu')}</div>
                  <div>{t('Fri')}</div>
                  <div>{t('Sat')}</div>
                </DayNames>
                {renderDays()}
                {renderTimePicker('from')}
              </>
            ) : (
              renderYears()
            )}
          </DatePickerWrapper>
        )}
      </DateWrapper>
      {activeField == null && !(fromDate && toDate && fromTime.hours && fromTime.minutes && fromTime.seconds && toTime.hours && toTime.minutes && toTime.seconds) && <Divider />}
      <DateWrapper
        isActive={activeField === 'to'}
        onClick={() => setActiveField('to')}
      >
        <SelectHeader align="right">{t('To')}</SelectHeader>
        <DateText align="right">{formatDateTime(toDate, toTime, 'to')}</DateText>
        {activeField === 'to' && (
          <DatePickerWrapper activeField={activeField !== null ? activeField : ''}>
            {renderHeader()}
            {view === 'month' ? (
              <>
                <DayNames>
                  <div>{t('Sun')}</div>
                  <div>{t('Mon')}</div>
                  <div>{t('Tue')}</div>
                  <div>{t('Wed')}</div>
                  <div>{t('Thu')}</div>
                  <div>{t('Fri')}</div>
                  <div>{t('Sat')}</div>
                </DayNames>
                {renderDays()}
                {renderTimePicker('to')}
              </>
            ) : (
              renderYears()
            )}
          </DatePickerWrapper>
        )}
      </DateWrapper>
      <BorderIndicator selected={activeField !== null ? activeField : ''} enabled={activeField !== null} />
    </ParentWrapper>
  );
};

export default Calendar;
