import Modal from 'components/Modal';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon';
import Input from 'components/Input';
import ButtonWithLoading from 'components/ButtonWithLoading';

const ConfirmationModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  gap: 15px;
  >:nth-child(3), :nth-child(4){
    input{
      margin-bottom: 0;
    }
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ConfirmationModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ConfirmDesc = styled.span`
  text-align: center;
  color: ${({theme})=>theme.text.secondaryText};
`;

const NoteText = styled.span`
  font-size: 12px;
  font-weight: 400px;
  color: ${({theme})=>theme.icons.danger};
  margin-left: auto;
  margin-right: auto;
`; 

const HeaderText = styled.span`
  max-width: 350px;
  word-wrap: break-word;
  font-size: 16px;
  font-weight: 600;
  color: ${({theme})=>theme.icons.danger};
  text-align: center;
`;

const IconDiv = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({theme})=>theme.statusBackground.danger};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoteTitle = styled.span`
  font-weight: 600;
`;

const ActionName = styled.div`
  text-align: center;
  max-width: 350px;
  overflow-wrap: break-word;
`;

interface IConfirmationModalProps{
  modalOpen: boolean,
  setModalOpen: (modalOpen: boolean) => void,
  onSubmit: ()=>void;
  hasConfirmationField?: boolean;
  textToCheck?: string;
  titleText?: string;
  confirmDescription?: string;
  noteText?: string;
  placeholder?: string;
  loading?: boolean;
  name?: string;
  onCancelNo?: () => void;
  actionName?: string;
  variant?: any;
  autoCloseAfterAction?: boolean
}

const ConfirmationModal: React.FC<IConfirmationModalProps> = ({modalOpen, name, setModalOpen, loading=false, onSubmit, hasConfirmationField=true, textToCheck='', titleText='', confirmDescription='', noteText='', placeholder='', onCancelNo, actionName='Delete', variant='danger', autoCloseAfterAction=true}) => {
  const {t} = useTranslation(['common']);
  const [confirmText,setConfirmText] = useState<string>('');

  const handleSubmit = useCallback(()=>{
    if(hasConfirmationField){
      if(textToCheck === confirmText){
        onSubmit();
        setConfirmText('');
        if(autoCloseAfterAction) {
          setTimeout(() => {
            setModalOpen(false);
          }, 1000);
        }
      }
    }else{
      onSubmit();
      setConfirmText('');
      if(autoCloseAfterAction) {
        setTimeout(() => {
          setModalOpen(false);
        }, 1000);
      }
    }
  }, [onSubmit, autoCloseAfterAction, setModalOpen, confirmText, hasConfirmationField, textToCheck]);

  const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setConfirmText(e.target.value);
  };

  return (
    <Modal isModalOpen={modalOpen} closeModal={setModalOpen}>
      <ConfirmationModalContainer>
        <ConfirmationModalHeader>
          <IconDiv>
            <Icon icon='Delete' color='danger' />
          </IconDiv>
          <HeaderText>{titleText}</HeaderText>
        </ConfirmationModalHeader>
        <ConfirmDesc>{confirmDescription}</ConfirmDesc>
        {name && <ActionName>{name}</ActionName>}
        {hasConfirmationField && <Input id='name' type='text' name='name' placeholder={placeholder} label='' value={confirmText} onChange={handleChange} width='90%' />}
        {noteText && <NoteText><NoteTitle>{t('Note')}</NoteTitle>{noteText}</NoteText>}
        <ButtonsWrapper>
          <ButtonWithLoading loading={loading} variant={variant} type='submit' disabled={textToCheck !== confirmText} onClick={handleSubmit}>{t(`${actionName}`)}</ButtonWithLoading>
          <Button
            variant='secondary' 
            onClick={()=>{ setModalOpen(false);
              if(onCancelNo){
                onCancelNo();
              }
            }}
          >
            {t('Cancel') }
          </Button>
        </ButtonsWrapper>
      </ConfirmationModalContainer>
    </Modal>
  );
};

export default ConfirmationModal;