import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgDisabled = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M12 3c4.95 0 9 4.05 9 9s-4.05 9-9 9-9-4.05-9-9 4.05-9 9-9Zm0 1.8c-1.71 0-3.24.54-4.41 1.53l10.08 10.08c.9-1.26 1.53-2.79 1.53-4.41 0-3.96-3.24-7.2-7.2-7.2Zm4.41 12.87L6.33 7.59C5.34 8.76 4.8 10.29 4.8 12c0 3.96 3.24 7.2 7.2 7.2 1.71 0 3.24-.54 4.41-1.53Z"
        fill={props.color}
      />
    </svg>
  );
};
export default SvgDisabled;
