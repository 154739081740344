import React from 'react';
import styled from 'styled-components';

const OdometerWrapper = styled.div<{fontSize: number}>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({fontSize}) => `${fontSize}px`};
  font-weight: bold;
  text-align: center;
`;

const DigitWrapper = styled.div`
  overflow: hidden;
  height: 1em;
  position: relative;
  line-height: 1em;
`;

const Digit = styled.div`
  transition: transform 0.8s ease;
`;

const Odometer = ({ value, fontSize=16 }: { value: number, fontSize?: number }) => {
  const digits = value?.toString()?.split('');

  return (
    <OdometerWrapper fontSize={fontSize}>
      {digits?.map((digit, index) => (
        <DigitWrapper key={index}>
          <Digit style={{ transform: `translateY(-${Number(digit)}em)` }}>
            {Array.from({ length: 10 }, (_, num) => (
              <div key={num} style={{ height: '1em' }}>{num}</div>
            ))}
          </Digit>
        </DigitWrapper>
      ))}
    </OdometerWrapper>
  );
};

export default Odometer;
