import React, { useEffect } from 'react';
import { useHeader } from 'contexts/HeaderContext';
import { useTranslation } from 'react-i18next';
import { Container } from 'CommonStyles';
import styled from 'styled-components';
import Tabs, { ITabs } from 'components/Tabs';
import ExportSystemConfig from './ExportSystemConfig';
import ImportSystemConfig from './ImportSystemConfig';
import { PERMISSION_CODE } from '../../constants';
import TokenService from 'api_configs/tokenService';

const TabsContainer = styled.div`
  position: relative;
  > div > div:nth-child(2){
    display: flex;
  }
`; 

const NoPermissionWrapper = styled.div`
  width: 100%;
  border: 1px solid ${({theme}) => theme.divider};
  border-radius: 10px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoPermissionMessage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SystemConfiguration = () => {
  const {updateHeaderTitle} = useHeader();
  const {t} = useTranslation(['common']);

  const importSystemConfigAccess = TokenService.hasAccess(PERMISSION_CODE.import_system_config);
  const exportSystemConfigAccess = TokenService.hasAccess(PERMISSION_CODE.export_system_config);
  
  useEffect(()=>{
    updateHeaderTitle(t('System Configuration'), 'Export');
  },[updateHeaderTitle, t]);

  const SystemConfigTabs: ITabs[] = [];

  if (importSystemConfigAccess) {
    SystemConfigTabs.push({
      id: 'import',
      name: t('Import'),
      content: <ImportSystemConfig />,
    });
  }
  
  if (exportSystemConfigAccess) {
    SystemConfigTabs.push({
      id: 'export',
      name: t('Export'),
      content: <ExportSystemConfig />,
    });
  }

  return(
    <Container>
      { (exportSystemConfigAccess && importSystemConfigAccess) ? <TabsContainer>
        <Tabs tabs={SystemConfigTabs} activeMode='box' />
      </TabsContainer>
        :
        <NoPermissionWrapper>
          <NoPermissionMessage>{t('Unfortunately, you do not have permission to import and export system configuration at this time. Please contact your administrator for assistance.')}</NoPermissionMessage>
        </NoPermissionWrapper> 
      }
    </Container>
  );
};

export default SystemConfiguration;
