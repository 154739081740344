import Icon from 'components/Icon';
import React, { ReactNode, useState, useRef, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { TfiTimer } from 'react-icons/tfi';
import { MdOutlineNotificationsActive } from 'react-icons/md';
import { RiFilter2Line } from 'react-icons/ri';
import { useClickOutside } from 'hooks/useClickOutside';
import { NODE_ID } from '../../../constants';
import { useTranslation } from 'react-i18next';

const NodeContainer = styled.div`
  width: fit-content;
  height: fit-content;
  position: relative;
  border: 1px solid ${({theme}) => theme.divider};
  border-radius: 6px;
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
`;

const topCss = css`
  top: -2px;
  right: 50%;
  left: 50%;
  transform: translateX(-50%);
`;

const bottomCss = css`
  bottom: -2px;
  right: 50%;
  left: 50%;
  transform: translateX(-50%);
`;

const leftCss = css`
  left: -2px;
  top: 30px;
`;

const rightCss = css`
  right: -2px;
  top: 30px;
`;

const CoonectionPoint = styled.div<{position: string}>`
  width: 2px;
  height: 2px;
  background-color: ${({ theme }) => theme.divider};
  border-radius: 50%;
  position: absolute;
  ${({position}) => {
    if(position==='top'){
      return topCss;
    }else if(position === 'bottom'){
      return bottomCss;
    }else if(position === 'left'){
      return leftCss;
    }else{
      return rightCss;
    }
  } }
`;

const NodeWrapper = styled.div`
  display: flex;
  height: fit-content;
  position: relative;
  background-color: #FFF;
`;

const IconDiv = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 5px;
  background-color: #2a44d6;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  font-size: 12px;
  color: #FFF;
  position: absolute;
  right: 50%;
  left: 50%;
  transform: translateX(-50%);
  bottom: -30px;
  cursor: pointer;
  border: none;
`;

const MenuWrapper = styled.div`
  position: absolute;
  top: 32px;
  right: -100px;
`;

const Menu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const MenuButton = styled.div<{active: boolean}>`
  position: absolute;
  z-index: 1;
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: var(--color);
  box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
  cursor: pointer;
  transition: transform 0.5s ease;
  ${({ active }) =>
    active &&
    `
    transform: rotate(45deg);
  `}
`;

const MenuItems = styled.ul`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
`;

const MenuItem = styled.li<{active: boolean}>`
  position: absolute;
  display: flex;
  align-items: center;
  width: 145px;
  height: 40px;
  z-index: 10;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  opacity: 0;
  ${({ active }) =>
    active && css`
    :nth-child(1){
      opacity: 1;
      transform: translate(40px, -55px)
    }
    :nth-child(2){
      opacity: 1;
      transform: translate(75px, 0px)
    }
    :nth-child(3){
      opacity: 1;
      transform: translate(40px, 55px)
    }
  `}
`;

const MenuItemIcon = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${({theme}) => theme.divider};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Span = styled.span`
  width: fit-content;
  background-color: ${({theme}) => theme.divider};
  padding: 5px 10px;
  position: absolute;
  font-size: 12px;
  left: 30px;
  border-radius: 0 6px 6px 0;
`;

const DeleteIcon = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -12px;
  right: 8px;
  border: none;
  background-color: #FFF;
  cursor: pointer;
`;

interface IConnectionPointProps{
  show: boolean
  id: string
}

interface INodeProps{
  children?: ReactNode;
  hasAddNewNodeOption?: boolean;
  addNewNodeOptions?: string[];
  connectionOptions?: {
    leftPoint?:IConnectionPointProps
    rightPoint?:IConnectionPointProps
    topPoint?:IConnectionPointProps
    bottomPoint?:IConnectionPointProps
  }
  onNewNodeCallBack?: (newNodeType: string)=>void;
  hasCopyNodeOption?: {show?: boolean, id?: string};
  copyNodeCallback?: () => void;
  hasRemoveNodeOption?: boolean;
  removeNodeCallback?: ()=>void;
  isReadOnly?: boolean; 
}

const initialConnectionOptions = {
  leftPoint:{show: false, id: ''},
  rightPoint:{show: false, id: ''},
  topPoint:{show: false, id: ''},
  bottomPoint:{show: false, id: ''},
};

const PipelineNode: React.FC<INodeProps> = ({children,hasRemoveNodeOption=true,isReadOnly=false, removeNodeCallback=()=>{}, connectionOptions={...initialConnectionOptions}, hasAddNewNodeOption, copyNodeCallback=()=>{}, hasCopyNodeOption={show: false, id: ''}, addNewNodeOptions=[], onNewNodeCallBack=()=>{}}) => {
  const [isActive, setIsActive] = useState(false);
  const addNewNodeRef = useRef<HTMLDivElement>(null);
  const {t} = useTranslation(['common']);
  useClickOutside(addNewNodeRef, ()=>setIsActive(false));

  const handleWrapperClick = () => {
    setIsActive(!isActive);
  };

  const handleNewNodeCallback = useCallback((newNodeType: string)=>{
    onNewNodeCallBack(newNodeType);
    setIsActive(prev => !prev);
  },[onNewNodeCallBack]);

  const getIcon = useCallback((icon: string)=>{
    switch(icon){
    case 'filter':{
      return <RiFilter2Line size={20}/>;
    }
    case 'schedule':{
      return <TfiTimer size={20} />;
    } 
    case 'action':{
      return <MdOutlineNotificationsActive size={20}/>;
    }
    }
  },[]);

  return (
    <NodeWrapper>
      <NodeContainer>
        {connectionOptions?.leftPoint?.show && <CoonectionPoint id={connectionOptions?.leftPoint?.id} position='left' />}
        {connectionOptions?.rightPoint?.show && <CoonectionPoint id={connectionOptions?.rightPoint?.id} position='right' />}
        {connectionOptions?.topPoint?.show && <CoonectionPoint id={connectionOptions?.topPoint?.id} position='top' />}
        {connectionOptions?.bottomPoint?.show && <CoonectionPoint id={connectionOptions?.bottomPoint?.id} position='bottom' />}
        {children}
        {hasCopyNodeOption.show && !isReadOnly && <IconDiv onClick={() => copyNodeCallback()}><Icon icon='Plus' size={20} color='inverse' /></IconDiv>}
        {hasRemoveNodeOption && !isReadOnly && <DeleteIcon onClick={() => removeNodeCallback()}><Icon icon='Delete' size={20} color='subtle' /></DeleteIcon>}
      </NodeContainer>
      {connectionOptions?.rightPoint?.show && hasAddNewNodeOption ? 
        <MenuWrapper>
          <Menu id={NODE_ID.ADD_NEW_NODE} ref={addNewNodeRef}>
            <MenuButton className={isActive ? 'active' : ''} active={isActive} onClick={handleWrapperClick}>
              <Icon icon='Plus' />
            </MenuButton>
            <MenuItems>
              {addNewNodeOptions.map(option => (
                <MenuItem title={t(`Add ${option}`)} onClick={()=>handleNewNodeCallback(option)} active={isActive}>
                  <MenuItemIcon>
                    {getIcon(option)}
                  </MenuItemIcon>
                  <Span>{t(`Add ${option}`)}</Span>
                </MenuItem>
              ))}
            </MenuItems>
          </Menu>
        </MenuWrapper>: null}
    </NodeWrapper>
  );
};

export default PipelineNode;