import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgHelp = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M7 8.245a.443.443 0 0 0 .27.425.434.434 0 0 0 .175.033h1.521c.254 0 .457-.21.49-.464.166-1.218.996-2.106 2.475-2.106 1.265 0 2.423.637 2.423 2.17 0 1.178-.69 1.72-1.78 2.545-1.24.908-2.223 1.968-2.153 3.69l.005.402a.466.466 0 0 0 .461.457h1.496a.46.46 0 0 0 .46-.464v-.195c0-1.333.504-1.721 1.863-2.76C15.83 11.12 17 10.166 17 8.162 17 5.355 14.647 4 12.071 4c-2.336 0-4.895 1.096-5.07 4.245Zm2.871 10.7c0 .99.784 1.722 1.863 1.722 1.123 0 1.895-.732 1.895-1.722 0-1.024-.774-1.745-1.897-1.745-1.077 0-1.86.72-1.86 1.745Z"
        fill={props.color}
      />
    </svg>
  );
};
export default SvgHelp;
