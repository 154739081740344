import { useQuery } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import { GET_STREAM } from 'api_configs/queries';
import Tabs, { ITabs } from 'components/Tabs';
import { IBreadcrum, IGetStream } from 'interface';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import styled from 'styled-components';
import AdvancedConfig from './camera-pages/AdvancedConfig';
import GeoLocation from './camera-detail-tabs/GeoLocation';
import { useHeader } from 'contexts/HeaderContext';
import AnalysisTab from './configurations/AnalysisTab';
import AddCamera from './AddCamera';
import Config from '../configs/config.json';

const CameraDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  min-height: calc(100vh - 120px);
  padding: 10px 20px;
  background-color: ${({ theme }) => theme.backgroundColor};  
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70vh;
  gap: 10px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TabsContainer = styled.div`
  margin-top: 20px;
  position: relative;
`; 

interface IParams {
  instanceId: string;
}

const Loading = styled.span``;

const CameraConfigurations = () => {
  const {instanceId}: IParams = useParams();
  const [streamDetails,setStreamDetails] = useState<IGetStream>();
  const {t} = useTranslation(['common']);
  const {data:getStreamData,loading:isStreamLoading} = useQuery(GET_STREAM, { variables: {streamId: instanceId} });
  const {updateHeaderTitle} = useHeader();
  
  useEffect(()=>{
    const result = getStreamData?.getStream?.result !== null &&  getStreamData?.getStream?.result !== undefined ? getStreamData?.getStream?.result : null;
    setStreamDetails(result);
  },[getStreamData]);

  useEffect(()=>{
    const cameraConfigurationsBreadCrumb: IBreadcrum[] = [
      {label: t('Cameras'), to: '/cameras'},
      {label: streamDetails?.name as string, to: `/cameras/${instanceId}` as string}
    ];
    if(instanceId){
      updateHeaderTitle(t('Configurations'), 'Setting', cameraConfigurationsBreadCrumb);
    }
  },[t, updateHeaderTitle, streamDetails, instanceId]);

  const CameraDetailTabs: ITabs[] = [
    {
      id: 'basicConfigs',
      name: t('Basic Configs'),
      content: <AddCamera />,
    },
    {
      id: 'analysis',
      name: t('Analysis'),
      content: <AnalysisTab streamDetails = {streamDetails as IGetStream} />,
      disabled: instanceId === 'configurations'
    },
    {
      id: 'geolocation',
      name: t( Config.isZoneMap ? 'Zone Map' : 'GeoLocation'),
      content: <GeoLocation instanceId={instanceId} />,
      disabled: instanceId === 'configurations'
    },
    {
      id: 'advanced',
      name: t('Advanced'),
      content: <AdvancedConfig streamType={streamDetails?.streamType} instanceId={streamDetails?.instanceId} />,
      disabled: instanceId === 'configurations'
    }
  ];

  const getLoading = () => {
    return (
      <LoadingContainer>
        <CircularProgress />
        <Loading>{t('Loading')}</Loading>
      </LoadingContainer>
    );
  };

  return (
    <CameraDetailsContainer>
      {
        isStreamLoading ? getLoading():
          <Wrapper>
            <TabsContainer>
              <Tabs tabs={CameraDetailTabs} activeMode='box' />
            </TabsContainer>
          </Wrapper>
      }
    </CameraDetailsContainer>
  );
};

export default CameraConfigurations;