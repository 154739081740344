import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgEmailAlert = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M18 4.077c-2.546 0-2.91 2.154-2.91 3.949S14 10.897 14 10.897h8s-1.09-1.076-1.09-2.871-.364-3.95-2.91-3.95Zm0 0V3m1.454 7.897s0 1.436-1.454 1.436-1.454-1.436-1.454-1.436"
        stroke={props.color}
        strokeWidth={props.weight}
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={"non-scaling-stroke"}
      />
      <path
        d="M13.142 7.127H2.998a.999.999 0 0 0-.998 1v11.206a1 1 0 0 0 1 1h16.983a1 1 0 0 0 1-1v-6.841"
        stroke={props.color}
        strokeWidth={props.weight}
        strokeLinecap="round"
        vectorEffect={"non-scaling-stroke"}
      />
      <path
        d="m2.411 7.95 7.844 6.418a2 2 0 0 0 2.494.03l2.455-1.908M2.717 19.453l5.722-6.437M20.157 19.51l-5.533-6.02"
        stroke={props.color}
        strokeWidth={props.weight}
        strokeLinecap="round"
        vectorEffect={"non-scaling-stroke"}
      />
    </svg>
  );
};
export default SvgEmailAlert;
