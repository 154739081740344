import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Icon from './Icon';
import {  ALERTS_REFRESH_INTERVAL, AlertTypes,IconType } from '../constants';
interface IAlertBar {
  type: IconType
  message: string,
  setMessage: React.Dispatch<React.SetStateAction<{ text: string; id: string; type: string |IconType }>>
  disableAutoClose?: boolean
}

const slideDown = keyframes`
  from{
    top: -80px;
  }
  to{
    top: 10px;
  }
`;

const slideUp = keyframes`
  from{
    top: 10px;
  }
  to{
    top: -80px;
  }
`;

const Container = styled.div<{background:string}>`
  min-height: 50px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 14px;
  background-color: ${({theme, background}) => theme.statusBackground[background]};
  width: 700px;
  color: ${({theme, background}) => theme.icons[background]};
  border: 1px solid ${({theme, background}) => theme.icons[background]};
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999999;
  backdrop-filter: blur(7px);
  animation: ${slideDown} 0.5s ease forwards, ${slideUp} 0.5s 4s ease forwards;
`;

const MessageWrapper = styled.div`
  display: flex;
  gap: 30px;
`;

const CloseWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

const AlertBar = ({message, setMessage, type, disableAutoClose=false}: IAlertBar) => {
  const [dismiss, setDismiss] = useState(false);
  useEffect(()=>{
    setDismiss(false);
  },[message]);

  useEffect(() => {
    setDismiss(false);
    if(!disableAutoClose) {
      const timeout = setTimeout(() => {
        setDismiss(true);
        setMessage({ text: '', id: '', type: AlertTypes.neutral });
      }, ALERTS_REFRESH_INTERVAL);
      return () => clearTimeout(timeout);
    }
  }, [message, setMessage, disableAutoClose]);

  const handleClose = () => {
    setDismiss(true); 
    setMessage({text: '', id: '', type: AlertTypes.neutral});
  };

  const getColor = (colorType: IconType) => {
    if(colorType === AlertTypes.success)
      return AlertTypes.success;
    else if(colorType === AlertTypes.danger)
      return AlertTypes.danger;
    else if(colorType === AlertTypes.warning)
      return AlertTypes.warning;
    else 
      return AlertTypes.neutral;
  };

  const getIcon = (type: string) => {
    if(type === AlertTypes.success)
      return 'Success';
    else if(type === AlertTypes.danger)
      return 'AlertCircle';
    else if(type === AlertTypes.warning)
      return 'Info';
    else 
      return 'AlertCircle';
  };

  return ( (message && !dismiss) ?
    <Container background={getColor(type)}>
      <MessageWrapper>
        <Icon icon={getIcon(type)} color={getColor(type) as IconType}/>
        <div>{message}</div>
      </MessageWrapper>
      <CloseWrapper onClick={handleClose}>
        <Icon icon='Close' color={getColor(type) as IconType}/>
      </CloseWrapper>
    </Container> : null
  );
};

export default AlertBar;