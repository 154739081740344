import React, { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, Link as RouterLink, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Icon from './Icon';
import MenuLogo from '../svgs/logo_menu.png';
import Config from '../configs/config.json';
import { useClickOutside } from 'hooks/useClickOutside';

const Container = styled.div`
  width: 110px;
  background-color: ${({theme})=>theme.sidemenu.background};
  position: relative;
`;

const LogoContainer = styled.div`
  width: 110px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  cursor: pointer;
`;

const LogoImg = styled.img`
  object-fit: contain;
  cursor: pointer;
  aspect-ratio: 5/2;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const NavLinks = styled.ul`
  margin-left: -40px;
  height: calc(100vh - 56px);
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  li{
    position: static;
  }
`;

const SubMenuWrapper = styled.div`
  position: absolute;
  z-index: 10;
  display: none;
  border-radius: 0 8px 8px 0;
`;

const SubMenuContainer = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: #FFF;
  border-radius: 0 8px 8px 0;
  padding: 6px;
  box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.3),
              5px 0 5px -5px rgba(0, 0, 0, 0.3),
              0 5px 5px -5px rgba(0, 0, 0, 0.3);
`;

const NavLink = styled(RouterLink)<{isActive?: boolean}>`
  position: static;
  border-left: ${({ isActive, theme }) => (!isActive ? '3px solid transparent' : `3px solid ${theme.backgroundColor}`)};
  background-color: ${({ isActive }) => (!isActive ? '' : '#244C9B')};
  list-style: none;
  text-decoration: none;
  cursor: pointer;
  list-style-position: inside;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover{
    background-color: #244C9B;
  }
  &:hover ${SubMenuWrapper}{
    top: 0;
    opacity: 1 !important;
    display: block !important;
    pointer-events: auto;
    z-index: 100;
    background-color: #FFF;
  }
`;

const LinkWrapper = styled.div<{isActive?: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  text-decoration: none;
  transform: ${({isActive}) => !isActive ? '' : 'scale(1.09)'};
  transition: all 0.2s ease;
`;

const LinkIcon = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  >div{
    >svg{
      >path{
        stroke: ${({theme}) => theme.text.lightText};
      }
    }
    >svg>g>path{
      stroke: ${({theme}) => theme.text.lightText};
      fill: ${({theme}) => theme.text.lightText};
    }
  }
`;

const LinkLabel = styled.span<{isActive:boolean}>`
  font-size: 12px;
  font-weight: ${({isActive})=>isActive ? 600: 400};
  color: ${({theme}) => theme.text.lightText};
  text-align: center;
`;

const SubMenuLink = styled.div<{isActive?: boolean}>`
  text-decoration: none;
  width: 150px;
  color: #3d3d3d;
  font-size: 14px;
  padding: 8px 10px;
  border-radius: 8px;
  background-color: ${({ isActive }) => (!isActive ? '' : '#76a4ff8b')};
  &:hover{
    background-color: #76a4ff52;
  }
`;

interface MenuOptions {
  isVisible: boolean;
  label: string;
  icon: string;
  to: string;
  subMenu?: SubMenu[];
}

interface SubMenu{
  label: string;
  icon?: string;
  to: string;
  isVisible: boolean;
}

interface Iprops{
  darkMode: boolean;
  setDarkMode:(darkMode:boolean) => void;
  menuOptions: MenuOptions[];
  showThemeToggle?: boolean;
}

const Menus: React.FC<Iprops> = ({menuOptions}) => {
  const {t} = useTranslation(['common']);
  const [configuredMenus, setConfiguredMenus] = useState<MenuOptions[]>([]);
  const { pathname } = useLocation();
  const history = useHistory();
  const menuItemRef = useRef<HTMLDivElement>(null);
  const [openedSubMenu, setOpenedSubMenu] = useState(-1);

  const handleOutsideClick = useCallback(() => {
    const menuItem = document.querySelector(`a.withsubmenu${openedSubMenu}`) as HTMLElement;
    if(menuItem){
      const submenuWrapper = menuItem.querySelector('.wrapper') as HTMLElement;
      if(submenuWrapper){
        submenuWrapper.style.opacity = '0';
        submenuWrapper.style.display = 'none';
        setOpenedSubMenu(-1);
      }
    }
  },[openedSubMenu]);

  useClickOutside(menuItemRef, () => {openedSubMenu !== -1 && handleOutsideClick();});

  useEffect(()=>{
    setConfiguredMenus(menuOptions.filter(item => {
      const configuredSubmenu = item.subMenu?.filter(submenu => submenu.isVisible);
      if(item.subMenu){
        item.subMenu = configuredSubmenu;
      }
      return item.isVisible;
    }));
  },[menuOptions]);

  const handleMouseHover = useCallback((e: MouseEvent<HTMLAnchorElement>,index: number) => {
    const menuItem = document.querySelector(`a.withsubmenu${index}`) as HTMLElement;
    const submenuWrapper = e.currentTarget.querySelector('.wrapper') as HTMLElement;
    const menuItemRect = e.currentTarget.getBoundingClientRect();
    const menuItemPosition = {
      top: menuItemRect.top,
      left: menuItemRect.left,
    };
    let submenuPos = {
      top: menuItemPosition.top,
      left: menuItemPosition.left + Math.round(e.currentTarget.offsetWidth)
    };
    //check if the submenu has enough space in the bottom
    if(submenuWrapper && menuItem){
      if(window.innerHeight - menuItemRect.top < submenuWrapper.clientHeight){
        submenuPos = {
          top: menuItemPosition.top - submenuWrapper.clientHeight + menuItem.clientHeight,
          left: menuItemPosition.left + Math.round(menuItem.offsetWidth)
        };
      }
    }
    if(submenuWrapper){
      submenuWrapper.style.top = submenuPos.top + 'px';
      submenuWrapper.style.left = submenuPos.left + 'px';
    }
  },[]);

  const handleMenuItemClick = useCallback((index: number) => {
    const menuItem = document.querySelector(`a.withsubmenu${index}`) as HTMLElement;
    if(menuItem){
      const submenuWrapper = menuItem.querySelector('.wrapper') as HTMLElement;
      if(submenuWrapper){
        submenuWrapper.style.opacity = '1';
        submenuWrapper.style.display = 'block';
      }
    }
    setOpenedSubMenu(index);
  },[]);

  const handleSubMenuLinkclick = useCallback((event: React.MouseEvent,path: string) => {
    event.stopPropagation();
    event.preventDefault();
    history.push(path);
    const menuItem = document.querySelector(`a.withsubmenu${openedSubMenu}`) as HTMLElement;
    if(menuItem){
      const submenuWrapper = menuItem.querySelector('.wrapper') as HTMLElement;
      if(submenuWrapper){
        submenuWrapper.style.opacity = '0';
        submenuWrapper.style.display = 'none';
        setOpenedSubMenu(-1);
      }
    }
  },[openedSubMenu, history]);

  return (
    <Container>
      <LogoContainer onClick={() => history.push('/cameras?currentView=grid')}>
        <LogoImg src={MenuLogo} />
      </LogoContainer>
      <NavLinks>
        {configuredMenus.map(({ icon, label, to, subMenu, isVisible }, index) => (
          <>
            {isVisible ? 
              <NavLink onClick={() => handleMenuItemClick(index)} to={to} className={subMenu ? `withsubmenu${index}`: ''} onMouseEnter={(e) => handleMouseHover(e, index)} isActive={pathname.split('/')[1].includes(to.split('/')[1])}>
                <LinkWrapper ref={menuItemRef} isActive={pathname.split('/')[1] === to.split('/')[1]}>
                  <LinkIcon>
                    <Icon icon={icon} size={24} />
                  </LinkIcon>
                  <LinkLabel isActive={pathname.split('/')[1] === to.split('/')[1]}>{t(`${label}`)}</LinkLabel>
                </LinkWrapper>
                {subMenu && <SubMenuWrapper className='wrapper'>
                  <SubMenuContainer>
                    {subMenu.map((item) => (
                      <>
                        {item.isVisible && (
                          (item.label !== 'Floor Plan' || Config.isZoneMap ) && (
                            <SubMenuLink isActive={pathname === item.to} onClick={(e) => handleSubMenuLinkclick(e,item.to)}>{t(item.label)}</SubMenuLink>
                          )
                        )}
                      </>
                    ))}
                  </SubMenuContainer>
                </SubMenuWrapper>}
              </NavLink>: null}
          </>
        ))}
      </NavLinks>
    </Container>
  );
};

export default Menus;