import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import Button from 'components/Button';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import Input from 'components/Input';
import ScheduleDays from 'components/ScheduleDays';
import { CREATE_SCHEDULE } from 'api_configs/mutations';
import { useMutation } from '@apollo/client';
import AlertBar from 'components/AlertBar';
import { IconType, StatusCode, getDayTextValue } from '../../constants';
import { IAlertMessageType, IBreadcrum } from 'interface';
import { Container } from 'CommonStyles';
import CancelConfirmationModal from 'pages/configurations/CancelConfirmationModal';
import { useHeader } from 'contexts/HeaderContext';

const ButtonsContainer = styled.div`
  display: flex;
  column-gap: 30px;
  justify-content: center;
  > button {
    width: 120px;
  }
  width: 560px;
`;

const FieldsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

interface IRules {
  startTime?: string;
  endTime?: string;
  days?: number[];
}

const ScheduleDaysContainer = styled.div`
  margin: 10px 0px 20px 0;
`;

const SetScheduleWrapper = styled.div`
  div::after{
    font-weight: bold;
    content: " *";
    color: rgb(238, 75, 43);
  }
`;

const SetScheduleLabel = styled.div``;

interface ISchedule {
  rules?: IRules[] | [];
}

const AddSchedule = () =>{
  const {t} = useTranslation(['common']);
  const history = useHistory();
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'});
  const [updatedSchedule, setUpdatedSchedule] = useState<ISchedule>({rules: []});
  const [saveScheduleMutation] = useMutation(CREATE_SCHEDULE);
  const [scheduleName, setScheduleName] = useState('');
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const {updateHeaderTitle} = useHeader();

  useEffect(()=>{
    const eventPipelineBreadCrumb: IBreadcrum[] = [
      {label: t('schedules'), to: '/settings/schedules'}
    ];
    updateHeaderTitle(t('Add Schedule'), 'Schedule', eventPipelineBreadCrumb);
  },[t, updateHeaderTitle]);

  const getUpdatedScheduleList= useCallback((list: object) => {
    setUpdatedSchedule({rules: list as []});
  },[]);

  const handleSaveSchedule = async () =>{
    if(scheduleName?.replace(/\s/g, '').length === 0){
      setMessage({text: t('Please enter the schedule name'), id:'',type:'danger'});
      return;
    } else if(updatedSchedule.rules?.length === 0){
      setMessage({text: t('Please add a rule'), id:'',type:'danger'});
      return;
    }

    const ruleList = updatedSchedule?.rules && updatedSchedule?.rules.map((item: IRules) => {  
      const daysList: string[] = [];
      item?.days?.map((item: number)=> daysList.push(getDayTextValue[item]));
      return {
        startTime: item?.startTime,
        endTime: item?.endTime,
        days: daysList
      };
    });

    try {
      const result = await saveScheduleMutation({variables: {payload: { name: scheduleName, rules: ruleList}}});
      if(result.data.createSchedule.statusCode === StatusCode.SUCCESS){
        setMessage({text: t('Schedule added successfully'), id:'',type:'success'});
        setTimeout(()=>{
          history.push('/settings/schedules');
        },1000);
      } else if(result.data.createSchedule.statusCode === StatusCode.FAILED){
        setMessage({text: t('Schedule already exists'), id:'',type:'danger'});
      } else {
        setMessage({text: t('apiError'), id:'',type:'danger'});
      }
    } catch(e){
      setMessage({text: t('apiError'), id:'',type:'danger'});
    }
  };

  const onCancelYes = useCallback(()=>{
    setModalOpen(false);
    history.push('/settings/schedules');
  },[history]);

  const onCancelNo = useCallback(()=>{
    setModalOpen(false);
  },[]);
  
  return(
    <Container>
      {message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
      {modalOpen && <CancelConfirmationModal modalOpen={modalOpen} setModalOpen={setModalOpen} onCancelYes={onCancelYes} onCancelNo={onCancelNo} />}
      <FieldsWrapper>
        <Input id='instanceId' name='instanceId' value={scheduleName} onChange={(e) => setScheduleName(e.target.value)} isRequired type='text' label={t('Schedule Name')} maxLength={64} />
      </FieldsWrapper>
      <SetScheduleWrapper>
        <SetScheduleLabel>{t('Rules')}</SetScheduleLabel>
      </SetScheduleWrapper>
      <ScheduleDaysContainer>
        <ScheduleDays actionIcons hideActionButtons isUpdateList getScheduleList={getUpdatedScheduleList} />
      </ScheduleDaysContainer>
      <ButtonsContainer>
        <Button variant='primary' onClick={() => handleSaveSchedule()}>{t('Save')}</Button>
        <Button variant='secondary' onClick={() =>{ scheduleName.length || updatedSchedule.rules?.length ? setModalOpen(!modalOpen) : history.push('/settings/schedules');}}>{t('Cancel')}</Button>
      </ButtonsContainer>
    </Container>
  );
};

export default AddSchedule;