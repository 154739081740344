import 'styled-components';

export const LightTheme = {
  mainBackgroundColor: '#EEF0F8',
  backgroundColor: '#FFFFFF',
  divider: '#D3D6E8',
  sidemenu:{
    background: '#0B2588',
    selectedItembackground: '#A1AFE5',
    itemIconBackground: '#CBC4FF',
    selectedItemBorder: '#5065B8',
  },
  topBar: {
    backGround: '#F5F6FA',
  },
  input: {
    focus: '#2E4EC4'
  },
  icons:{
    inverse: '#FFFFFF',
    mono: '#34495E',
    dimmed: 'hsla(0, 0%, 65.1%, 1.000)',
    subtle: '#737D81',
    primary: '#435FCA',
    danger: '#D63031',
    success: '#51CF6D',
    warning: '#FFa943',
    neutral: '#96ACBC'
  },
  text:{
    primaryText: '#25292A',
    secondaryText: '#737D81',
    lightText: '#FFFFFF',
    darkText: '#25292A',
    placeholder: '#BFBFBF'
  },
  statusBackground:{
    inverse: 'hsla(0, 0%, 100%, 1.000)',
    mono: '#34495E4D',
    dimmed: 'hsla(0, 0%, 65.1%, 1.000)',
    subtle: '#737D81',
    primary: '#0984E34D',
    danger: '#D630314D',
    success: '#51CF6D4D',
    warning: '#FFa9434D',
    neutral: '#96ACBC'
  },
  custom: {
    lines:{
      'primary': { //sky blue
        label: {
          fill: '#fff'//'hsla(205deg, 80%, 25%, 100%)'
        },
        contrastLine: {
          stroke: 'hsla(205deg, 80%, 45%, 100%);'
        },
        highlightLine: {
          stroke: 'hsla(205deg, 45%, 90%, 90%);'
        },
        highlightLineBorder: {
          stroke: 'hsla(204.8,53.4%,46.3%,100%);'
        },
        grabHandle: {
          fill: 'hsla(205deg, 45%, 90%, 100%)',
          stroke: 'hsla(205deg, 45%, 100%, 100%)'
        },
        point:{
          fill: 'hsla(205deg, 45%, 90%, 100%)',
        },
        grabHandleContrast:{
          stroke: 'hsla(205deg, 80%, 45%, 100%)'
        },
        grabHandleText:{
          fill: 'hsla(205deg, 80%, 25%, 100%);'
        },
        handleBase: {
          fill: 'hsla(235deg, 100%, 80%, 100%);'
        },
        handleRingLayer: {
          stroke: 'hsla(205deg, 100%, 89%, 100%);'
        },
        handleReactiveFill:{
          fill: 'hsla(192deg, 100%, 45%, 100%);'
        },
        handleReactiveRing:{
          stroke: 'hsla(205deg, 100%, 36%, 27%);'
        },
        handleContrastLayer:{
          stroke: 'hsla(205deg, 100%, 36%, 15%);'
        },
        stopStart:{
          stopColor:' hsla(205deg, 100%, 15%, 35%);'
        },
        stopEnd:{
          stopColor: 'hsla(205deg, 100%, 15%, 0%)'
  
        }
  
      },
      'secondary': { //light green
        label: {
          fill: '#fff'//'hsla(205deg, 80%, 25%, 100%)'
        },
        contrastLine: {
          stroke: 'hsla(120deg, 80%, 45%, 100%);'
        },
        highlightLine: {
          stroke: 'hsla(120deg, 45%, 90%, 90%);'
        },
        highlightLineBorder: {
          stroke: 'hsla(120deg, 81%, 70%, 90%);'
        },
        grabHandle: {
          fill: 'hsla(120deg, 45%, 90%, 100%)',
          stroke: 'hsla(120deg, 45%, 100%, 100%)'
        },
        point:{
          fill: 'hsla(120deg, 45%, 90%, 100%)',
        },
        grabHandleContrast:{
          stroke: 'hsla(120deg, 80%, 45%, 100%)'
        },
        grabHandleText:{
          fill: 'hsla(120deg, 80%, 25%, 100%);'
        },
        handleBase: {
          fill: 'hsla(150deg, 100%, 80%, 100%);'
        },
        handleRingLayer: {
          stroke: 'hsla(120deg, 100%, 89%, 100%);'
        },
        handleReactiveFill:{
          fill: 'hsla(108deg, 100%, 45%, 100%);'
        },
        handleReactiveRing:{
          stroke: 'hsla(120deg, 100%, 36%, 27%);'
        },
        handleContrastLayer:{
          stroke: 'hsla(120deg, 100%, 36%, 15%);'
        },
        stopStart:{
          stopColor:' hsla(120deg, 100%, 15%, 35%);'
        },
        stopEnd:{
          stopColor: 'hsla(120deg, 100%, 15%, 0%)'
  
        }
      },
      'danger': { //red
        label: {
          fill: '#fff'//'hsla(205deg, 80%, 25%, 100%)'
        },
        contrastLine: {
          stroke: 'hsla(0, 80%, 45%, 100%);'
        },
        highlightLine: {
          stroke: 'hsla(0, 45%, 90%, 90%);'
        },
        highlightLineBorder: {
          stroke: 'hsla(0deg, 86%, 70%, 100%);'
        },
        grabHandle: {
          fill: 'hsla(0, 45%, 90%, 100%)',
          stroke: 'hsla(0, 45%, 100%, 100%)'
        },
        point:{
          fill: 'hsla(0, 45%, 90%, 100%)',
        },
        grabHandleText:{
          fill: 'hsla(0, 80%, 25%, 100%);'
        },
        grabHandleContrast:{
          stroke: 'hsla(0, 80%, 45%, 100%)'
        },
        handleBase: {
          fill: 'hsla(30deg, 100%, 80%, 100%);'
        },
        handleRingLayer: {
          stroke: 'hsla(0, 100%, 89%, 100%);'
        },
        handleReactiveFill:{
          fill: 'hsla(348deg, 100%, 45%, 100%);'
        },
        handleReactiveRing:{
          stroke: 'hsla(0deg, 100%, 36%, 27%);'
        },
        handleContrastLayer:{
          stroke: 'hsla(0deg, 100%, 36%, 15%);'
        },
        stopStart:{
          stopColor:' hsla(0, 100%, 15%, 35%);'
        },
        stopEnd:{
          stopColor: 'hsla(0, 100%, 15%, 0%);'
        }
      },
      'neutral': { //orange
        label: {
          fill: '#fff'
        },
        contrastLine: {
          stroke: 'hsla(30deg, 100%, 45%, 100%)'
        },
        highlightLine: {
          stroke: 'hsla(30deg, 80%, 70%, 100%)' 
        },
        highlightLineBorder: {
          stroke: 'hsla(30deg, 80%, 50%, 100%)' 
        },
        grabHandle: {
          fill: 'hsla(30deg, 80%, 70%, 100%)' 
        },
        point: {
          fill: 'hsla(30deg, 80%, 70%, 100%)' 
        },
        grabHandleText: {
          fill: 'hsla(30deg, 100%, 45%, 100%)' 
        },
        grabHandleContrast: {
          stroke: 'hsla(30deg, 100%, 45%, 100%)' 
        },
        handleBase: {
          fill: 'hsla(30deg, 100%, 80%, 100%)' 
        },
        handleRingLayer: {
          stroke: 'hsla(30deg, 80%, 85%, 100%)' 
        },
        handleReactiveFill: {
          fill: 'hsla(30deg, 100%, 60%, 100%)' 
        },
        handleReactiveRing: {
          stroke: 'hsla(30deg, 80%, 65%, 100%)' 
        },
        handleContrastLayer: {
          stroke: 'hsla(30deg, 80%, 65%, 100%)' 
        },
        stopStart: {
          stopColor: 'hsla(30deg, 100%, 15%, 35%)' 
        },
        stopEnd: {
          stopColor: 'hsla(30deg, 100%, 15%, 0%)'
        }
      },
      'info': { //purple
        label: {
          fill: '#fff'
        },
        contrastLine: {
          stroke: 'hsla(270deg, 100%, 45%, 100%)' 
        },
        highlightLine: {
          stroke: 'hsla(270deg, 80%, 70%, 100%)' 
        },
        highlightLineBorder: {
          stroke: 'hsla(270deg, 80%, 50%, 100%)' 
        },
        grabHandle: {
          fill: 'hsla(270deg, 80%, 70%, 100%)' 
        },
        point: {
          fill: 'hsla(270deg, 80%, 70%, 100%)' 
        },
        grabHandleText: {
          fill: 'hsla(270deg, 100%, 45%, 100%)' 
        },
        grabHandleContrast: {
          stroke: 'hsla(270deg, 100%, 45%, 100%)' 
        },
        handleBase: {
          fill: 'hsla(270deg, 100%, 80%, 100%)' 
        },
        handleRingLayer: {
          stroke: 'hsla(270deg, 80%, 85%, 100%)' 
        },
        handleReactiveFill: {
          fill: 'hsla(270deg, 100%, 60%, 100%)' 
        },
        handleReactiveRing: {
          stroke: 'hsla(270deg, 80%, 65%, 100%)' 
        },
        handleContrastLayer: {
          stroke: 'hsla(270deg, 80%, 65%, 100%)' 
        },
        stopStart: {
          stopColor: 'hsla(270deg, 100%, 15%, 35%)' 
        },
        stopEnd: {
          stopColor: 'hsla(270deg, 100%, 15%, 0%)' 
        }
      },
      'warning': { //yellow
        label: {
          fill: '#fff'
        },
        contrastLine: {
          stroke: 'hsla(60deg, 100%, 45%, 100%)' 
        },
        highlightLine: {
          stroke: 'hsla(60deg, 80%, 70%, 100%)' 
        },
        highlightLineBorder: {
          stroke: 'hsla(60deg, 80%, 50%, 100%)' 
        },
        grabHandle: {
          fill: 'hsla(60deg, 80%, 70%, 100%)' 
        },
        point: {
          fill: 'hsla(60deg, 80%, 70%, 100%)' 
        },
        grabHandleText: {
          fill: 'hsla(60deg, 100%, 45%, 100%)' 
        },
        grabHandleContrast: {
          stroke: 'hsla(60deg, 100%, 45%, 100%)' 
        },
        handleBase: {
          fill: 'hsla(60deg, 100%, 80%, 100%)' 
        },
        handleRingLayer: {
          stroke: 'hsla(60deg, 80%, 85%, 100%)' 
        },
        handleReactiveFill: {
          fill: 'hsla(60deg, 100%, 60%, 100%)' 
        },
        handleReactiveRing: {
          stroke: 'hsla(60deg, 80%, 65%, 100%)' 
        },
        handleContrastLayer: {
          stroke: 'hsla(60deg, 80%, 65%, 100%)' 
        },
        stopStart: {
          stopColor: 'hsla(60deg, 100%, 15%, 35%)' 
        },
        stopEnd: {
          stopColor: 'hsla(60deg, 100%, 15%, 0%)' 
        }
      },
      'calm': { // Olive
        label: {
          fill: '#fff' 
        },
        contrastLine: {
          stroke: 'hsla(80, 50%, 45%, 1)' 
        },
        highlightLine: {
          stroke: 'hsla(80, 50%, 70%, 1)' 
        },
        highlightLineBorder: {
          stroke: 'hsla(80, 50%, 50%, 1)' 
        },
        grabHandle: {
          fill: 'hsla(80, 50%, 70%, 1)' 
        },
        point: {
          fill: 'hsla(80, 50%, 70%, 1)' 
        },
        grabHandleText: {
          fill: 'hsla(80, 50%, 45%, 1)' 
        },
        grabHandleContrast: {
          stroke: 'hsla(80, 50%, 45%, 1)' 
        },
        handleBase: {
          fill: 'hsla(80, 50%, 80%, 1)' 
        },
        handleRingLayer: {
          stroke: 'hsla(80, 50%, 85%, 1)' 
        },
        handleReactiveFill: {
          fill: 'hsla(80, 50%, 60%, 1)' 
        },
        handleReactiveRing: {
          stroke: 'hsla(80, 50%, 65%, 1)' 
        },
        handleContrastLayer: {
          stroke: 'hsla(80, 50%, 65%, 1)' 
        },
        stopStart: {
          stopColor: 'hsla(80, 50%, 15%, 0.35)' 
        },
        stopEnd: {
          stopColor: 'hsla(80, 50%, 15%, 0)' 
        }
      },
      'vibrant': { //dark pink
        label: {
          fill: '#fff'
        },
        contrastLine: {
          stroke: 'hsla(328, 100%, 45%, 100%)' 
        },
        highlightLine: {
          stroke: 'hsla(328, 100%, 70%, 100%)'  
        },
        highlightLineBorder: {
          stroke: 'hsla(328, 100%, 50%, 100%)'
        },
        grabHandle: {
          fill: 'hsla(328, 100%, 70%, 100%)'
        },
        point: {
          fill: 'hsla(328, 100%, 70%, 100%)'
        },
        grabHandleText: {
          fill: 'hsla(328, 100%, 45%, 100%)' 
        },
        grabHandleContrast: {
          stroke: 'hsla(328, 100%, 45%, 100%)' 
        },
        handleBase: {
          fill: 'hsla(328, 100%, 80%, 100%)'
        },
        handleRingLayer: {
          stroke: 'hsla(328, 100%, 85%, 100%)'
        },
        handleReactiveFill: {
          fill: 'hsla(328, 100%, 60%, 100%)' 
        },
        handleReactiveRing: {
          stroke: 'hsla(328, 100%, 65%, 100%)' 
        },
        handleContrastLayer: {
          stroke: 'hsla(328, 100%, 65%, 100%)' 
        },
        stopStart: {
          stopColor: 'hsla(328, 100%, 15%, 35%)' 
        },
        stopEnd: {
          stopColor: 'hsla(328, 100%, 15%, 0%)' 
        }
      },
      'subtle': { //brown
        label: {
          fill: '#fff'
        },
        contrastLine: {
          stroke: 'hsla(30, 100%, 30%, 1)'
        },
        highlightLine: {
          stroke: 'hsla(30, 100%, 45%, 1)'
        },
        highlightLineBorder: {
          stroke: 'hsla(20, 100%, 45%, 1)'
        },
        grabHandle: {
          fill: 'hsla(30, 100%, 45%, 1)'
        },
        point: {
          fill: 'hsla(30, 100%, 45%, 1)'
        },
        grabHandleText: {
          fill: 'hsla(30, 100%, 30%, 1)'
        },
        grabHandleContrast: {
          stroke: 'hsla(30, 100%, 30%, 1)'
        },
        handleBase: {
          fill: 'hsla(20, 100%, 70%, 1)'
        },
        handleRingLayer: {
          stroke: 'hsla(20, 100%, 75%, 1)'
        },
        handleReactiveFill: {
          fill: 'hsla(30, 100%, 45%, 1)'
        },
        handleReactiveRing: {
          stroke: 'hsla(30, 100%, 45%, 1)'
        },
        handleContrastLayer: {
          stroke: 'hsla(30, 100%, 45%, 1)'
        },
        stopStart: {
          stopColor: 'hsla(30, 100%, 15%, 0.35)'
        },
        stopEnd: {
          stopColor: 'hsla(30, 100%, 15%, 0)'
        }
      },
      'dark': { //Navy Blue
        label: {
          fill: '#fff'
        },
        contrastLine: {
          stroke: 'hsla(220, 75%, 30%, 1)' 
        },
        highlightLine: {
          stroke: 'hsla(220, 75%, 50%, 1)'
        },
        highlightLineBorder: {
          stroke: 'hsla(220, 75%, 40%, 1)' 
        },
        grabHandle: {
          fill: 'hsla(220, 75%, 50%, 1)'
        },
        point: {
          fill: 'hsla(220, 75%, 50%, 1)'
        },
        grabHandleText: {
          fill: 'hsla(220, 75%, 30%, 1)' 
        },
        grabHandleContrast: {
          stroke: 'hsla(220, 75%, 30%, 1)' 
        },
        handleBase: {
          fill: 'hsla(220, 75%, 70%, 1)' 
        },
        handleRingLayer: {
          stroke: 'hsla(220, 75%, 75%, 1)' 
        },
        handleReactiveFill: {
          fill: 'hsla(220, 75%, 45%, 1)' 
        },
        handleReactiveRing: {
          stroke: 'hsla(220, 75%, 50%, 1)' 
        },
        handleContrastLayer: {
          stroke: 'hsla(220, 75%, 50%, 1)' 
        },
        stopStart: {
          stopColor: 'hsla(220, 75%, 15%, 0.35)' 
        },
        stopEnd: {
          stopColor: 'hsla(220, 75%, 15%, 0)'
        }
      }
    }
  }
};

// All the darkh theme colors will be replaced once the complete dark theme is finalized
export const DarkTheme = {
  mainBackgroundColor: '#EEF0F8',
  backgroundColor: '#FFFFFF',
  divider: '#D3D6E8',
  sidemenu:{
    background: '#FFFFFF',
    selectedItembackground: '#EEFCFF',
    itemIconBackground: '#C7EBF3',
  },
  topBar: {
    backGround: '#F5F6FA',
  },
  input: {
    focus: '#2E4EC4'
  },
  icons:{
    inverse: 'hsla(0, 0%, 100%, 1.000)',
    mono: '#34495E',
    dimmed: 'hsla(0, 0%, 65.1%, 1.000)',
    subtle: '#737D81',
    primary: '#435FCA',
    danger: '#D63031',
    success: '#00B894',
    warning: '#FFa943',
    neutral: '#96ACBC'
  },
  text:{
    primaryText: '#25292A',
    secondaryText: '#737D81'
  },
  statusBackground:{
    inverse: 'hsla(0, 0%, 100%, 1.000)',
    mono: '#34495E4D',
    dimmed: 'hsla(0, 0%, 65.1%, 1.000)',
    subtle: '#737D81',
    primary: '#0984E34D',
    danger: '#D630314D',
    success: '#51CF6D4D',
    warning: '#FFa9434D',
    neutral: '#96ACBC'
  },
  custom: {
    lines:{
      primary: {
        label: {
          fill: '#fff'//'hsla(205deg, 80%, 25%, 100%)'
        },
        contrastLine: {
          stroke: 'hsla(205deg, 80%, 45%, 100%);'
        },
        highlightLine: {
          stroke: 'hsla(205deg, 45%, 90%, 90%);'
        },
        highlightLineBorder: {
          stroke: 'hsla(204.8,53.4%,46.3%,100%);'
        },
        grabHandle: {
          fill: 'hsla(205deg, 45%, 90%, 100%)',
          stroke: 'hsla(205deg, 45%, 100%, 100%)'
        },
        point:{
          fill: 'hsla(205deg, 45%, 90%, 100%)',
        },
        grabHandleContrast:{
          stroke: 'hsla(205deg, 80%, 45%, 100%)'
        },
        grabHandleText:{
          fill: 'hsla(205deg, 80%, 25%, 100%);'
        },
        handleBase: {
          fill: 'hsla(235deg, 100%, 80%, 100%);'
        },
        handleRingLayer: {
          stroke: 'hsla(205deg, 100%, 89%, 100%);'
        },
        handleReactiveFill:{
          fill: 'hsla(192deg, 100%, 45%, 100%);'
        },
        handleReactiveRing:{
          stroke: 'hsla(205deg, 100%, 36%, 27%);'
        },
        handleContrastLayer:{
          stroke: 'hsla(205deg, 100%, 36%, 15%);'
        },
        stopStart:{
          stopColor:' hsla(205deg, 100%, 15%, 35%);'
        },
        stopEnd:{
          stopColor: 'hsla(205deg, 100%, 15%, 0%)'
  
        }
  
      },
      secondary: {
        label: {
          fill: '#fff'//'hsla(205deg, 80%, 25%, 100%)'
        },
        contrastLine: {
          stroke: 'hsla(120deg, 80%, 45%, 100%);'
        },
        highlightLine: {
          stroke: 'hsla(120deg, 45%, 90%, 90%);'
        },
        highlightLineBorder: {
          stroke: 'hsla(120deg, 81%, 70%, 90%);'
        },
        grabHandle: {
          fill: 'hsla(120deg, 45%, 90%, 100%)',
          stroke: 'hsla(120deg, 45%, 100%, 100%)'
        },
        point:{
          fill: 'hsla(120deg, 45%, 90%, 100%)',
        },
        grabHandleContrast:{
          stroke: 'hsla(120deg, 80%, 45%, 100%)'
        },
        grabHandleText:{
          fill: 'hsla(120deg, 80%, 25%, 100%);'
        },
        handleBase: {
          fill: 'hsla(150deg, 100%, 80%, 100%);'
        },
        handleRingLayer: {
          stroke: 'hsla(120deg, 100%, 89%, 100%);'
        },
        handleReactiveFill:{
          fill: 'hsla(108deg, 100%, 45%, 100%);'
        },
        handleReactiveRing:{
          stroke: 'hsla(120deg, 100%, 36%, 27%);'
        },
        handleContrastLayer:{
          stroke: 'hsla(120deg, 100%, 36%, 15%);'
        },
        stopStart:{
          stopColor:' hsla(120deg, 100%, 15%, 35%);'
        },
        stopEnd:{
          stopColor: 'hsla(120deg, 100%, 15%, 0%)'
  
        }
      },
      'danger': {
        label: {
          fill: '#fff'//'hsla(205deg, 80%, 25%, 100%)'
        },
        contrastLine: {
          stroke: 'hsla(0, 80%, 45%, 100%);'
        },
        highlightLine: {
          stroke: 'hsla(0, 45%, 90%, 90%);'
        },
        highlightLineBorder: {
          stroke: 'hsla(0deg, 86%, 70%, 100%);'
        },
        grabHandle: {
          fill: 'hsla(0, 45%, 90%, 100%)',
          stroke: 'hsla(0, 45%, 100%, 100%)'
        },
        point:{
          fill: 'hsla(0, 45%, 90%, 100%)',
        },
        grabHandleText:{
          fill: 'hsla(0, 80%, 25%, 100%);'
        },
        grabHandleContrast:{
          stroke: 'hsla(0, 80%, 45%, 100%)'
        },
        handleBase: {
          fill: 'hsla(30deg, 100%, 80%, 100%);'
        },
        handleRingLayer: {
          stroke: 'hsla(0, 100%, 89%, 100%);'
        },
        handleReactiveFill:{
          fill: 'hsla(348deg, 100%, 45%, 100%);'
        },
        handleReactiveRing:{
          stroke: 'hsla(0deg, 100%, 36%, 27%);'
        },
        handleContrastLayer:{
          stroke: 'hsla(0deg, 100%, 36%, 15%);'
        },
        stopStart:{
          stopColor:' hsla(0, 100%, 15%, 35%);'
        },
        stopEnd:{
          stopColor: 'hsla(0, 100%, 15%, 0%);'
        }
      }
    }
  }
};