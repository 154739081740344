import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Input from './Input';
import Switch from './Switch';
import Toggle from './Toggle';
import { useTranslation } from 'react-i18next';

interface IRTMPData {
  rtmpServerType: string,
  rtmpSourceUrl: string,
  rtmpStreamKeyType: string,
  rtmpStreamKey: string,
  rtmpUrlType: string
}

interface IMdnsIpInput {
  name: string
  mdnsValue: string;
  ipValue: string;
  defaultValue: boolean;
  isToggleDisabled?: boolean
  onEnabled?: ()=>void,
  customInputValue?: IRTMPData,
  isMdnsIpToggleDisabled?: boolean,
  onInputChange?: React.Dispatch<React.SetStateAction<IRTMPData>>
  disabled?: boolean
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const LabelWrapper = styled.div`
  display: flex;
`;

const Row = styled.div<{isValueAvailable?: boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  > div > input {
    font-size: ${({isValueAvailable}) => isValueAvailable ? '12px': '14px'};
    font-style: ${({isValueAvailable}) => isValueAvailable ? 'italic': 'normal'};
    pointer-events: ${({isValueAvailable}) => isValueAvailable ? 'none': ''};
    cursor: ${({isValueAvailable}) => isValueAvailable ? 'not-allowed': 'pointer'};
  }
`;

const Label = styled.label`
  margin-right: 8px;
`;

const ToggleWrapper =styled.div`
  display: flex;
  gap: 10px;
`;

const MdnsIpInput: React.FC<IMdnsIpInput> = ({ defaultValue=false, customInputValue=undefined, onInputChange, isMdnsIpToggleDisabled=false, mdnsValue, ipValue, name, onEnabled, isToggleDisabled=false, disabled= false}) => {
  const [enabled, setEnabled] = useState(false);
  const [inputValue, setInputValue] = useState<string>();
  const {t} = useTranslation(['common']);
  const [selectedTab, setSelectedTab] = useState(0);

  const tabButtons = [
    { label: 'mDNS', selected: selectedTab === 0 },
    { label: 'IP', selected: selectedTab === 1 },
  ];

  const handleTabClick = (index: number) => {
    setSelectedTab(index);
    if(index === 0) {
      setInputValue(mdnsValue);
    }
    if(index === 1) {
      setInputValue(ipValue);
    }
  };

  useEffect(() => {
    setEnabled(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if(enabled) {
      setInputValue(mdnsValue);
      setSelectedTab(0);
    }
  }, [enabled, mdnsValue]);

  const handleToggleChange = () => {
    setEnabled(!enabled);
    if(onEnabled !== undefined) {
      onEnabled();
    }
    if(enabled === true) {
      setInputValue('');
      return;
    }
    if (enabled === false) {
      setInputValue(mdnsValue);
    }
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if(customInputValue) {
      onInputChange && onInputChange({...customInputValue, rtmpSourceUrl: e.target.value});
    }
  };

  return (
    <Container>
      <Row>
        <LabelWrapper>
          <Label>{name}</Label>
          <Switch disabled={isToggleDisabled || disabled} checked={enabled} onChange={handleToggleChange} />
        </LabelWrapper>
        <ToggleWrapper>
          <Toggle enabled={!isMdnsIpToggleDisabled || disabled} tabButtons={tabButtons} selected={selectedTab} onTabClick={handleTabClick} />
        </ToggleWrapper>
      </Row>
      <Row isValueAvailable={mdnsValue === t('Please save endpoints to generate URL')}>
        <Input
          id='copyTextInput'
          label=''
          type="text"
          value={customInputValue?.rtmpServerType === 'internal' || customInputValue === undefined  ? inputValue: customInputValue?.rtmpSourceUrl}
          disabled={!enabled || disabled}
          hasCopyButton
          hasTooltip
          onChange={(e) => handleInputChange(e)}
          width='450px'
        />
      </Row>
    </Container>
  );
};

export default MdnsIpInput;
