import React from 'react';
import Threedots from './Threedots';
import { CircularProgress } from '@mui/material';

interface ILoaderProps {
  type: 'threedots' | 'circular'
}

const Loader: React.FC<ILoaderProps> = ({type}) => {

  const getLoader = (type: string) => {
    switch(type) {
    case 'threedots':
      return <Threedots />;
    default:
      return <CircularProgress />;
    }
  };

  return (
    <>{getLoader(type)}</>
  );
};

export default Loader;