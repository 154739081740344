import React, { useState, ChangeEvent, useCallback, useEffect } from 'react';
import Input from 'components/Input';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ButtonWithLoading from 'components/ButtonWithLoading';
import Button from 'components/Button';
import { UPDATE_SMTP_CLIENT, DELETE_SMTP_CLIENT } from 'api_configs/mutations';
import { useMutation, useQuery } from '@apollo/client';
import { IconType, StatusCode } from '../../constants';
import { IAlertMessageType } from 'interface';
import AlertBar from 'components/AlertBar';
import { emailRegex } from 'utils/utils';
import { GET_SMTP_CLIENT } from 'api_configs/queries';
import { CircularProgress } from '@mui/material';
import Label from 'components/Label';
import { isEqual } from 'lodash';
import ConfirmationModal from 'components/modals/ConfirmationModal';

const BasicFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  width: 450px;
`;

const SpinnerBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 600px;
  gap: 10px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  column-gap: 30px;
  margin-top: 20px;
  justify-content: center;
  height: 70px;
`;

interface IEmailDetails {
  serverAddress?: string;
  serverPort?: string;
  senderEmail?: string;
  senderPassword?: string;
}

const SMTPSetting = () => {
  const [emailDetails, setEmailDetails] = useState<IEmailDetails>({ serverAddress: '', serverPort: '', senderEmail: '', senderPassword: ''});
  const [initialEmailDetails, setInitialEmailDetails] = useState<IEmailDetails>({ serverAddress: '', serverPort: '', senderEmail: '', senderPassword: ''});
  const { t } = useTranslation(['common']);
  const [updateSMTPClientMutation] = useMutation(UPDATE_SMTP_CLIENT);
  const [deleteSMTPClientMutation] = useMutation(DELETE_SMTP_CLIENT);
  const [onSaveLoader, setOnSaveLoader] = useState(false);
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'});
  const [deleteLoader, setDeleteLoader] = useState(false);
  const {data: SMTPDetails, loading, refetch} = useQuery(GET_SMTP_CLIENT);
  const [isSMTPClientAvailable, setSMTPClientAvailable] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isActionButtonsDisable, setActionButtonsDisable] = useState(false);
  const [deleteClient, setDeleteClient] = useState<boolean>(false);

  useEffect(() =>{
    setLoader(loading);
  },[loading]);

  useEffect(() =>{
    if(SMTPDetails?.getSmtpClient?.statusCode === StatusCode.SUCCESS && SMTPDetails.getSmtpClient.result !== null){
      const result = SMTPDetails.getSmtpClient.result;
      const response = {
        serverAddress: result.smtpServerAddress,
        serverPort: result.smtpServerPort.toString(),
        senderEmail: result.smtpSenderEmail,
        senderPassword: result.smtpSenderPassword
      };
      setEmailDetails(response);
      setInitialEmailDetails(response);
      setSMTPClientAvailable(true);
    } else {
      setSMTPClientAvailable(false);
      setEmailDetails({serverAddress: '', serverPort: '', senderEmail: '', senderPassword: ''});
      setInitialEmailDetails({serverAddress: '', serverPort: '', senderEmail: '', senderPassword: ''});
    }
  },[SMTPDetails]);

  const handleChange = (event?: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event?.target || {};
    if (name === 'serverAddress') {
      setEmailDetails((prev) => ({ ...prev, serverAddress: value }));
    }
    if (name === 'serverPort') {
      setEmailDetails((prev) => ({ ...prev, serverPort: value }));
    }
    if (name === 'senderEmail') {
      setEmailDetails((prev) => ({ ...prev, senderEmail: value }));
    }
    if (name === 'senderPassword') {
      setEmailDetails((prev) => ({ ...prev, senderPassword: value }));
    }
  };

  const onHandleSubmit = useCallback(async() => {
    const {serverAddress, serverPort, senderEmail, senderPassword } = emailDetails;
    if(serverAddress?.replace(/\s/g, '').length === 0){
      setMessage({text: t('Please enter the server address'), id:'',type:'danger'});
      return;
    } 
    if(serverPort?.replace(/\s/g, '').length === 0){
      setMessage({text: t('Please enter the server port'), id:'',type:'danger'});
      return;
    } 
    if(!senderEmail?.match(emailRegex)){
      setMessage({text: t('Please enter the valid email'), id:'',type:'danger'});
      return;
    } 
    if(senderPassword?.replace(/\s/g, '').length === 0){
      setMessage({text: t('Please enter the server password'), id:'',type:'danger'});
      return;
    } 
    setOnSaveLoader(true);
    try {
      const data = await updateSMTPClientMutation({variables: {payload: {smtpServerAddress: serverAddress, smtpServerPort: parseInt(serverPort!), smtpSenderEmail: senderEmail, smtpSenderPassword: senderPassword}}});
      if (data?.data?.updateSmtpClient?.statusCode === StatusCode?.SUCCESS) {
        setMessage({text: t('SMTP client updated successfully.'), id:'', type:'success'});
        refetch();
      } else {
        setMessage({text: t('apiError'), id:'',type:'danger'});
      }
    } catch (error) {
      setMessage({text: t('apiError'), id:'',type:'danger'});
      console.error((error as Error).message);
    }
    setOnSaveLoader(false);
  },[t, updateSMTPClientMutation, emailDetails, refetch]);

  const onDeleteClient = useCallback(async () => {
    setDeleteLoader(true);
    try {
      const result = await deleteSMTPClientMutation();
      if(result.data.deleteSmtpClient.statusCode === StatusCode.SUCCESS){
        setMessage({text: t('SMTP client deleted successfully'), id:'',type:'success'});
        refetch();
      }else {
        setMessage({text: t('apiError'), id:'',type:'danger'});
      }
    } catch(e){
      setMessage({text: t('apiError'), id:'',type:'danger'});
    }
    setDeleteLoader(false);
  },[t, deleteSMTPClientMutation, refetch]);

  useEffect(()=>{
    setActionButtonsDisable(isEqual(emailDetails, initialEmailDetails));
  },[emailDetails, initialEmailDetails]);

  if (loader) {
    return (
      <SpinnerBox>
        <CircularProgress />
        <Label labelText={t('Loading...')}></Label>
      </SpinnerBox>
    );
  }

  return (
    <>
      {message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
      <BasicFieldsWrapper>
        <Input id='serverAddress' isRequired name='serverAddress' onChange={handleChange} value={emailDetails.serverAddress} type='text' label={t('Server Address')} maxLength={64} />
        <Input id='serverPort' isRequired name='serverPort' onChange={handleChange} type='text' value={emailDetails.serverPort} label={t('Server Port')} maxLength={64} />
        <Input id='senderEmail' isRequired name='senderEmail' onChange={handleChange} type='text' value={emailDetails.senderEmail} label={t('Sender Email')} maxLength={64} />
        <Input id='senderPassword' isRequired name='senderPassword' onChange={handleChange} type='text' value={emailDetails.senderPassword} label={t('Sender Password')} maxLength={64} />
        <ButtonsContainer>
          <ButtonWithLoading loading={onSaveLoader} disabled={isActionButtonsDisable} variant='primary' onClick={onHandleSubmit} type='submit'>{t('Save')}</ButtonWithLoading>
          <Button variant='secondary' >{t('Cancel')}</Button>
          {isSMTPClientAvailable && <ButtonWithLoading loading={deleteLoader} variant='danger' onClick={() => {setDeleteClient(!deleteClient);}} type='submit'>{t('Delete')}</ButtonWithLoading>}
          {deleteClient && 
          <ConfirmationModal modalOpen={deleteClient} setModalOpen={setDeleteClient} onSubmit={()=> onDeleteClient()} hasConfirmationField={false}
            titleText={t('Delete SMTP Client')} confirmDescription={t('Are you sure you want to delete this SMTP Client?')} noteText={t('Once deleted this action cannot be undone.')}
          />}
        </ButtonsContainer>
      </BasicFieldsWrapper>
    </>
  );
};

export default SMTPSetting;
