import React, { useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled, { keyframes } from 'styled-components';
import Icon from './Icon';

const growIn = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const growOut = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
`;

const BackgroundContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(7px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: hsla(202, 33%, 95%, 0.51);
  z-index: 99999; // This is required as Leaflet Map has z-index > 9999;
`;

const ContentWrapper = styled.div<{ width?: string; minWidth?: string; isModalOpen: boolean }>`
  max-width: 90%;
  min-width: ${({ minWidth }) => minWidth};
  width: ${({ width }) => (width ? width : '450px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  animation: ${({ isModalOpen }) => (isModalOpen ? growIn : growOut)} 0.2s ease-out forwards;
`;

const Content = styled.div<{padding:string}>`
  background-color: ${({theme})=>theme.backgroundColor};
  padding: ${({padding})=>padding};
  border-radius: ${({padding})=>(padding==='0' ? '0' : '5px')};
  position: relative;
  max-height: 90%;
  width: 100%;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
`;

const CloseButton = styled.div`
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 99999;
  position: absolute;
  top: -12px;
  right: -12px;
  border-radius: 50%;
  background-color: ${({theme})=>theme.backgroundColor};
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.4);
`;

interface IModalProps {
  isModalOpen: boolean;
  closeModal: (a:boolean)=>void;
  children: JSX.Element;
  width?: string;
  padding?: string;
  minWidth?: string;
}

const Modal: React.FC<IModalProps> = ({isModalOpen, closeModal, children, width, padding='20px', minWidth='0'}) => {

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      closeModal(false);
    }
  }, [closeModal]);

  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isModalOpen, handleKeyDown]);
  
  return isModalOpen ? 
    ReactDOM.createPortal(
      <BackgroundContainer>
        <ContentWrapper width={width} minWidth={minWidth} isModalOpen={isModalOpen}>
          <CloseButton onClick={() => closeModal(!isModalOpen)}>
            <Icon icon='Close' size={20} />
          </CloseButton>
          <Content padding={padding}>{children}</Content>
        </ContentWrapper>
      </BackgroundContainer>,
      document.body
    )
    : null;
};

export default Modal;