import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgUrgentAlert = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M15.915 1.494a.6.6 0 1 0-.627 1.022c2.71 1.66 4.944 4.097 5.111 8.508a.6.6 0 0 0 1.2-.045c-.19-4.92-2.734-7.678-5.684-9.485Zm-1.413 3.18a.6.6 0 0 1 .832-.161c1.929 1.302 3.258 3.166 3.466 5.309.02.192.029.387.029.584a.6.6 0 0 1-1.2 0c0-.158-.009-.314-.024-.468-.163-1.701-1.23-3.273-2.943-4.431a.6.6 0 0 1-.16-.833Zm-8.408.852a6.19 6.19 0 0 0-3.137 8.173l1.468 3.299.108 2.784a.9.9 0 0 0 1.265.787l11.672-5.196a.9.9 0 0 0 .262-1.468l-1.997-1.942-1.469-3.3a6.19 6.19 0 0 0-8.172-3.137Zm-2.04 7.684a4.99 4.99 0 0 1 9.116-4.058l1.562 3.51 1.846 1.794-10.863 4.836-.1-2.572-1.562-3.51h.001Zm8.758 7.241a1.788 1.788 0 0 1-2.218-.648l3.222-1.433a1.788 1.788 0 0 1-1.004 2.081Z"
        fill={props.color}
      />
    </svg>
  );
};
export default SvgUrgentAlert;
