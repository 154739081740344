import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgAlertStatusSnooze = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M14.448 3.643h3.271L14.45 8.57h3.27"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={"non-scaling-stroke"}
      />
      <path
        d="M20.188 18.5H3s2.344-2.25 2.344-6 .781-8.25 6.25-8.25V2m3.125 16.5s0 3-3.125 3-3.125-3-3.125-3"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={"non-scaling-stroke"}
      />
      <path
        d="M17.719 11.855c0 3.75 2.453 6.57 2.453 6.57"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={"non-scaling-stroke"}
      />
    </svg>
  );
};
export default SvgAlertStatusSnooze;
