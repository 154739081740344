import React, { createContext, useContext, ReactNode } from 'react';
import { useSubscription } from '@apollo/client';
import { DETECTION_SUB } from '../api_configs/subscriptions';
import Config from '../configs/config.json';

interface DetectionContextProps {
  data: any | undefined;
  loading: boolean;
  error: Error | undefined;
}

const DetectionContext = createContext<DetectionContextProps | undefined>(undefined);

interface DetectionProviderProps {
  children: ReactNode;
  isSkip?: boolean; 
}

export const DetectionProvider: React.FC<DetectionProviderProps> = ({ children, isSkip=true }) => {
  const { data, loading, error } = useSubscription(DETECTION_SUB, {variables: {payload: {}}, skip: !Config.isSubscriptionEnabled || isSkip});
  return (
    <DetectionContext.Provider value={{ data, loading, error }}>
      {children}
    </DetectionContext.Provider>
  );
};

export const useDetection = (): DetectionContextProps => {
  const context = useContext(DetectionContext);
  if (!context) {
    throw new Error('useDetection must be used within a DetectionProvider');
  }
  return context;
};
