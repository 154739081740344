import { LatLngBoundsExpression } from 'leaflet';
import React, { useEffect } from 'react';
import { useMap } from 'react-leaflet';

interface IFitMapBoundsMemo {
  locations: string;
  boundsPresent: boolean;
}

const FitMapBoundsMemo: React.FC<IFitMapBoundsMemo>= ({locations, boundsPresent}) => {
  const map = useMap();
  useEffect(()=>{
    if(JSON.parse(locations).length > 1){
      map.fitBounds(JSON.parse(locations) as LatLngBoundsExpression, {padding: [40, 40]});
    }else if(boundsPresent){
      map.fitBounds(JSON.parse(locations) as LatLngBoundsExpression, {padding: [20, 20]});
      map.setZoom(13);
    }else{
      map.setZoom(8);
    }
  },[locations, map, boundsPresent]);
  return null;
};

export default React.memo(FitMapBoundsMemo);