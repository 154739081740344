import AlertBar from 'components/AlertBar';
import { HLS_IP_URL, HLS_MDNS_URL, IconType, PERMISSION_CODE, RTSP_IP_URL, RTSP_MDNS_URL, StatusCode, StreamType, VIDEO_FPS_OPTIONS, WEB_RTC_IP_URL, WEB_RTC_MDNS_URL } from '../../constants';
import { IAlertMessageType } from 'interface';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Switch from 'components/Switch';
import Label from 'components/Label';
import Title from 'components/Title';
import { useHistory } from 'react-router';
import SelectField from 'components/SelectField';
import MP4Recording from './CameraComponents/MP4Recording';
import PeriodicSnapshot from './CameraComponents/PeriodicSnapshot';
import Button from 'components/Button';
import { GET_ADV_CONFIG, GET_DEVICE_INFO, GET_HLS_STREAM } from 'api_configs/queries';
import { useMutation, useQuery } from '@apollo/client';
import { PATCH_HLS_CAMERA } from 'api_configs/mutations';
import MdnsIpInput from 'components/MdnsIpInput';
import { CircularProgress } from '@mui/material';
import TokenService from 'api_configs/tokenService';
const viewAdvancedConfigAccess = TokenService.hasAccess(PERMISSION_CODE.view_advanced_config);
const editAdvancedConfigAccess = TokenService.hasAccess(PERMISSION_CODE.edit_advanced_config);

const AdvancedConfigWrapper = styled.div<{isEditPermission: boolean}>`
  pointer-events: ${({isEditPermission}) => isEditPermission ? '': 'none'};
`;

const VideoConfigurationWrapper = styled.div`
  display: flex;
  width: 1060px;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 768px) {
    width: 530px;
  }
`;

const Heading = styled.div`
  font-size: 20px;
  font-weight: 700;
`;

const VerticalLine = styled.div`
  width: 1300px;
  height: 10px;
  border-bottom: 1px solid lightgrey;
  margin-top: 2px;
  margin-bottom: 32px;
  @media (max-width: 768px) {
    width: 450px;
  }
`;

const LeftSideWrapper = styled.div``;

const SwitchWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 15px;
`;

const NoPermissionMessage = styled.div`
  width: 1040px;
  height: 800px;
  display: flex;
  padding-top: 160px;
  justify-content: center;
`;

const MessageWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: 1060px;
  justify-content: center;
  gap: 20px;
  margin-top: 15px;
`;

const HWAccelerationWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const MP4RecordingWrapping = styled.div``;

const PeriodicSnapshotWrapping = styled.div``;

const Configurations = styled.div`
  display: flex;
  gap: 150px;
`;

const SpinnerBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 89vh;
  align-items: center;
  justify-content: center;
`;

const EndPointsWrapper = styled.div`
  width: 1060px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const VideoSourceProperties = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

interface IAdvancedConfig {
  streamType: string | undefined,
  instanceId: string | undefined
}

const AdvancedHLS = ({streamType, instanceId}: IAdvancedConfig) => {
  const [basicInfo, setBasicInfo] = useState({name: '', notes: '', groupList: [], streamType: '', httpSourceUrl: ''});
  const [advancedHLS, setAdvancedHLS] = useState({rtspPort: '', hlsEnabled: false, rtspEnabled: false, services: {recorder: {enableHwAcceleration: true, videoFps: 'auto', videoPayloadType: 96, enableVideoMulticast: true, videoPortRtcp: '1063', pixelAspectRatio: 'auto', enableVideoRtcpMuxed: false, enableAudio: false, rtpAudioSourceType: 'acc', audioPayloadType: 103, enableAudioMulticast: false, audioMulticastAddress: '', audioPortRtp: '', enableAudioRtcpMuxed: false, audioPortRtcp: '', rtspSourceBufferTime: '5', videoFlipMethod: 'auto', webrtcPort: '', maximumPacketDelay: 'auto', maximumPacketDelayType: 'auto', rtspSourceShortHeader: false, videoWidth: '1920', h264Profile: 'auto', videoHeight: '1080', enableSaveMp4: true, videoSplitMode: 'time', enableMp4TimeOverlay: false, recordingQuality: 'low', videoFileMaxSize: '52428100', videoDuration: '600', periodicSnapshot: true, enableSnapshotTimeOverlay: false, saveInterval: '600', specifySnapshotResolution: false, periodicSnapshotResolution: 'auto', saveOriginalSnapshot: false}}});
  const [currentResponse, setCurrentResponse] = useState({rtspPort: '', hlsEnabled: false, rtspEnabled: false, services: {recorder: {enableHwAcceleration: true, videoFps: 'auto', videoPayloadType: 96, enableVideoMulticast: true, videoPortRtcp: '1063', pixelAspectRatio: 'auto', enableVideoRtcpMuxed: false, enableAudio: false, rtpAudioSourceType: 'acc', audioPayloadType: 103, enableAudioMulticast: false, audioMulticastAddress: '', audioPortRtp: '', enableAudioRtcpMuxed: false, audioPortRtcp: '', rtspSourceBufferTime: '5', videoFlipMethod: 'auto', webrtcPort: '', maximumPacketDelay: 'auto', maximumPacketDelayType: 'auto', rtspSourceShortHeader: false, videoWidth: '1920', h264Profile: 'auto', videoHeight: '1080', enableSaveMp4: true, videoSplitMode: 'time', enableMp4TimeOverlay: false, recordingQuality: 'low', videoFileMaxSize: '52428100', videoDuration: '600', periodicSnapshot: true, enableSnapshotTimeOverlay: false, saveInterval: '600', specifySnapshotResolution: false, periodicSnapshotResolution: 'auto', saveOriginalSnapshot: false}}});
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: ''});
  const [pageLoading, setPageLoading] = useState(false);
  const [currentDeviceInfo, setCurrentDeviceInfo] = useState({deviceId: '', networkId: ''});
  const [patchHLSStreamMutation] = useMutation(PATCH_HLS_CAMERA);
  const {data: deviceInfo, loading: getDeviceInfoLoading} = useQuery(GET_DEVICE_INFO);
  const {data: advancedConfigData, loading: getAdvancedConfigLoading, refetch } = useQuery(GET_ADV_CONFIG, { variables: {streamId: instanceId}});
  const history = useHistory();
  const {data: getRTMP, loading: getStreamLoading} = useQuery(GET_HLS_STREAM, { variables: {streamId: instanceId} });
  const {t} = useTranslation(['common']);

  useEffect(() => {
    refetch();
    if(!getAdvancedConfigLoading && !getStreamLoading && !getDeviceInfoLoading) {
      if(advancedConfigData?.getAdvConfig?.result){
        setAdvancedHLS(advancedConfigData?.getAdvConfig?.result);
        setCurrentResponse(advancedConfigData?.getAdvConfig?.result);
        setBasicInfo(getRTMP?.getStream?.result);
        setCurrentDeviceInfo(prevState => ({
          ...prevState,
          deviceId: deviceInfo?.getDeviceInfo?.result.deviceId,
          networkId: deviceInfo?.getDeviceInfo?.result?.networks[0]?.ipAddress?.split('/')[0]
        }));
      }
    } 
  }, [getAdvancedConfigLoading, deviceInfo, getStreamLoading, advancedConfigData, getRTMP, refetch, getDeviceInfoLoading]);

  const handleSwitchChange = (state: boolean, id: string) => {
    setAdvancedHLS(prevState => ({
      ...prevState,
      services: {
        ...prevState?.services,
        recorder: {
          ...prevState?.services?.recorder,
          [id]: !state
        }
      }
    }));
  };

  const handleSelectFieldChange = (value: string, id: string) => {
    setAdvancedHLS(prevState => ({
      ...prevState,
      services: {
        ...prevState?.services,
        recorder: {
          ...prevState?.services?.recorder,
          [id]: value
        }
      }
    }));  
  };

  const handlePatchHLSStream = async () => {
    const advancedHLSPatchPayload = {streamId: instanceId, groupList: basicInfo.groupList, streamType: StreamType.HLS, name: basicInfo.name, notes: basicInfo.notes, rtspEnabled: advancedHLS.rtspEnabled, hlsEnabled: advancedHLS.hlsEnabled, videoConfiguration: {httpSourceUrl: basicInfo.httpSourceUrl, enableHwAcceleration: advancedHLS.services.recorder.enableHwAcceleration, videoFps: advancedHLS.services.recorder.videoFps, enableSaveMp4: advancedHLS.services.recorder.enableSaveMp4, videoSplitMode: advancedHLS.services.recorder.videoSplitMode, videoDuration: advancedHLS.services.recorder.videoDuration, periodicSnapshot: advancedHLS.services.recorder.periodicSnapshot, enableSnapshotTimeOverlay: advancedHLS.services.recorder.enableSnapshotTimeOverlay, saveInterval: advancedHLS.services.recorder.saveInterval, specifySnapshotResolution: advancedHLS.services.recorder.specifySnapshotResolution, periodicSnapshotResolution: advancedHLS.services.recorder.periodicSnapshotResolution, saveOriginalSnapshot: advancedHLS.services.recorder.saveOriginalSnapshot}};
    try{
      const data = await patchHLSStreamMutation({variables: {payload: advancedHLSPatchPayload}});
      if(data && data?.data?.patchStream?.statusCode === StatusCode.SUCCESS_200){
        setMessage({text: t('Data updated successfully'), id:'', type:'success'});
        refetch();
        setPageLoading(false);
      }else{
        setMessage({text: t('apiError'), id:'', type:'danger'});
      }
    }catch(e){
      setMessage({text: t('apiError'), id:'', type:'danger'});
      console.error(e);
    }
  };

  if(!viewAdvancedConfigAccess) {
    return <NoPermissionMessage>
      <MessageWrapper>{t('Unfortunately, you do not have permission to view advanced configurations at this time. Please contact your administrator for assistance.')}</MessageWrapper>
    </NoPermissionMessage>;
  }

  return (
    (getAdvancedConfigLoading || pageLoading) ? 
      <SpinnerBox>
        <CircularProgress />
        <Label labelText={t('Loading...')}/>
      </SpinnerBox> : 
      <AdvancedConfigWrapper isEditPermission={editAdvancedConfigAccess}>
        {
          message && <AlertBar message={message.text} type={message.type as IconType} setMessage={setMessage} />
        }
        <EndPointsWrapper>
          <Heading>{t('Endpoints')}</Heading>
          <VerticalLine />
          <MdnsIpInput defaultValue={advancedHLS?.hlsEnabled} name={t('HLS Enabled')} mdnsValue={advancedHLS?.hlsEnabled === false ? '' : HLS_MDNS_URL.replace('{deviceId}', currentDeviceInfo.deviceId).replace('{instanceId}', instanceId as string)} ipValue={advancedHLS?.hlsEnabled === false ? '' : HLS_IP_URL.replace('{networkId}', currentDeviceInfo.networkId).replace('{instanceId}', instanceId as string)} onEnabled={() => {setAdvancedHLS(prevState => ({...prevState, hlsEnabled: !advancedHLS?.hlsEnabled}));}} />
          <MdnsIpInput defaultValue={true} name={t('WebRTC Enabled')} mdnsValue={WEB_RTC_MDNS_URL.replace('{deviceId}', currentDeviceInfo.deviceId).replace('{webRTCPort}', advancedHLS?.services?.recorder?.webrtcPort)} ipValue={WEB_RTC_IP_URL.replace('{networkId}', currentDeviceInfo.networkId).replace('{webRTCPort}', advancedHLS?.services?.recorder?.webrtcPort)} />
          <MdnsIpInput defaultValue={advancedHLS?.rtspEnabled} name={t('RTSP Enabled')} mdnsValue={advancedConfigData?.getAdvConfig?.result?.rtspPort === '' ? t('Please save endpoints to generate URL') : advancedHLS?.rtspEnabled === false ? '' : RTSP_MDNS_URL.replace('{deviceId}', currentDeviceInfo.deviceId).replace('{rtspPort}', advancedHLS?.rtspPort).replace('{instanceId}', instanceId as string)} ipValue={advancedConfigData?.getAdvConfig?.result?.rtspPort === '' ? t('Please save endpoints to generate URL') : advancedHLS?.rtspEnabled === false ? '' : RTSP_IP_URL.replace('{networkId}', currentDeviceInfo.networkId).replace('{rtspPort}', advancedHLS.rtspPort).replace('{instanceId}', instanceId as string)} onEnabled={() => {setAdvancedHLS(prevState => ({...prevState, rtspEnabled: !advancedHLS?.rtspEnabled}));}} />
        </EndPointsWrapper>
        <VideoConfigurationWrapper>
          <Heading>{t('Video Configurations')}</Heading>
          <VerticalLine />
          <Configurations>
            <LeftSideWrapper>
              <HWAccelerationWrapper>
                <Title size='18px' text={t('HW Acceleration')} />
                <SwitchWrapper>
                  <Switch checked={advancedHLS?.services?.recorder?.enableHwAcceleration} onChange={() => handleSwitchChange(advancedHLS?.services?.recorder?.enableHwAcceleration, 'enableHwAcceleration')} />
                  <Label labelText={advancedHLS?.services?.recorder?.enableHwAcceleration ? t('Enabled'): t('Disabled')} />
                </SwitchWrapper>  
              </HWAccelerationWrapper>
              <VideoSourceProperties>
                <SelectField id='videoFps' label={t('FrameRate of Analysis')} options={VIDEO_FPS_OPTIONS} defaultValue={advancedHLS?.services?.recorder?.videoFps} onChange={(value) => handleSelectFieldChange(value, 'videoFps')} />
              </VideoSourceProperties>
            </LeftSideWrapper>
            <LeftSideWrapper>
              <MP4RecordingWrapping>
                <MP4Recording streamType={streamType} recordingFields={advancedHLS} setRecordingFields={setAdvancedHLS} />
              </MP4RecordingWrapping>
              <PeriodicSnapshotWrapping>
                <PeriodicSnapshot streamType={streamType} snapshotFields={advancedHLS} setSnapshotFields={setAdvancedHLS} />
              </PeriodicSnapshotWrapping>
            </LeftSideWrapper>
          </Configurations>
          <ButtonsWrapper>
            <Button variant='primary' disabled={(JSON.stringify(advancedHLS) === JSON.stringify(currentResponse))} onClick={handlePatchHLSStream}>{t('Save')}</Button>
            <Button variant='secondary' onClick={() => history.push('/')}>{t('Cancel')}</Button>
          </ButtonsWrapper>
        </VideoConfigurationWrapper>
      </AdvancedConfigWrapper>
  );
};

export default AdvancedHLS;