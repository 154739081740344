import Switch  from '../../../components/Switch';
import AlertBar from 'components/AlertBar';
import Icon from 'components/Icon';
import Input from 'components/Input';
import { IReducerActions } from 'components/LineUI/LineReducer';
import { DEFAULT_AREA_POINTS, IconType, LineUIStyling, LineUIType } from '../../../constants';
import { IAlertMessageType, IAnomalyDetectionConfigs, INewAnalysisConfigsRes, IOption } from 'interface';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineMinus } from 'react-icons/ai';
import styled from 'styled-components';
import Config from '../../../configs/config.json';
import SelectField from 'components/SelectField';

const FieldsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  >div{
    >label{
      margin-bottom: 0;
    }
    >select{
      width: 14vw;
      height: 35px;
      padding: 6px;
      margin-bottom: 6px;
    }
    #arrow{
      top: 42px;
    }
  }
  >div{
    @media only screen and (min-width: 1920px) {
      width: 280px;
    }
    input{
      height: 35px;
      padding: 6px;
    }
  }
`;

const NoAreaData = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 100%;
  min-height: 100%;
  border-radius: 5px;
  border: 1px solid #33B7D4;
  min-height: 305px;
  min-width: 430px;
`;

const NoAreaText = styled.div`
  max-width: 60%;
  font-size: 14px;
  color: ${({theme})=>theme.text.secondaryText};
  text-align: center;
`;

const AddNewAreaButton = styled.div`
  background-color: #07ABCF;
  color: #FFFFFF;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
`;

const AreaConfigDiv = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: min-content;
  padding: 20px 20px;
  #topFields{
    margin-top: 20px;
  }
  border-radius: 5px;
  border: 1px solid #33B7D4;
  min-height: 100%;
`;

const LineTools = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const LineTool = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 8px 4px;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  background-color: #07ABCF;
  >div>svg>path{
    fill: white;
  }
  >:nth-child(2){
    margin-bottom: 2px;
  }
`;

const AreaPointsHandler = styled.div`
  display: flex;
  flex-direction: column;
  width: 8vw;
`;

const PointsToggleDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  justify-content: space-between;
  height: 35px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid lightgrey;
`;

const PointsCountChanger = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  border: 1px solid lightgrey;
  border-radius: 6px;
  cursor: pointer;
`;

const PointsCount = styled.div``;

const EventsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;

const IconDiv = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({theme})=>theme.backgroundColor};
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.4);
  top: -5px;
  right: -5px;
`;

const Configs = styled.div<{isSelected:boolean}>`
  position: relative;
  border-radius: 5px;
  padding: 5px;
  border: 1px solid ${({isSelected})=>isSelected ? '#33B7D4' : 'gray'};
  width: 70px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
`;

const LineName = styled.div`
  width: 70px;
  white-space: nowrap;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ConfiguredDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const EventsRow = styled.div`
  display: flex;
  align-items: center;
  gap: 70px;
`;

const SingleEvent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  flex: 1;
`;

const Label = styled.div`
  font-size: 16px;
`;

const ConfigurationForm = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
`;

const ConfiguredLines = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const EventsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #F5F5F5;
  border-radius: 5px;
  gap: 10px;
  padding: 10px;
`;

const AdvancedConfigurationSection = styled.div`
  margin-top: 20px;
`;

const AdvancedHeading = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

const AdvanceConfigurations = styled.div`
  display: flex;
  gap: 30px;
  margin-top: 20px;
  >div{
    >label{
      margin-bottom: 0;
    }
    >select{
      width: 18vw;
      height: 35px;
      padding: 6px;
      margin-bottom: 6px;
      padding-right: 30px;
    }
    #arrow{
      top: 42px;
    }
  }
`;

interface IRichAnomalyConfigs {
  selectedAlgorithmConfigsLength: number;
  selectedIndex: number;
  onAddNewLine: (a: boolean, data: IAnomalyDetectionConfigs, currentLength: number)=>void;
  handleRemoveLine?: (index: number, length: number)=>void;
  dispatch: React.Dispatch<IReducerActions>;
  onLineClickCallback?: (id: number) => void;
  setSelectedAlgoConfigs: React.Dispatch<React.SetStateAction<IAnomalyDetectionConfigs[]>>;
  selectedAlgoConfigs: IAnomalyDetectionConfigs[];
  scheduleOptions:IOption[];
  selectedAlgorithmConfigDetails: INewAnalysisConfigsRes;
  setSelectedAlgorithmConfigDetails: React.Dispatch<React.SetStateAction<INewAnalysisConfigsRes>>;
}

const ProductAnomalyRICHS: React.FC<IRichAnomalyConfigs> = ({selectedAlgorithmConfigsLength, selectedAlgoConfigs, setSelectedAlgoConfigs, selectedIndex, dispatch, onAddNewLine, onLineClickCallback=()=>{}, handleRemoveLine=()=>{}, scheduleOptions, setSelectedAlgorithmConfigDetails, selectedAlgorithmConfigDetails}) => {
  const {t} = useTranslation(['common']);
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: 'neutral'});

  const AddNewLine = useCallback(() => {
    if(selectedAlgorithmConfigsLength + 1 > Config.algorithmConfigurations.areasLimit){
      setMessage({text: t('configurationLimitWarning').replace('{X}',`${Config.algorithmConfigurations.areasLimit}`), id:'',type:'danger'});
    }else{
      const newLineData: IAnomalyDetectionConfigs = {
        name: 'New Area' + (selectedAlgorithmConfigsLength + 1),
        pointsCount: 4,
        logoImage: true,
        batchNo: true,
        manufacturingDate: true,
        expiryDate: true,
        index: selectedAlgorithmConfigsLength + 1,
        lineType: LineUIType.AREA,
        styling: LineUIStyling.SECONDARY,
        points: DEFAULT_AREA_POINTS.map(item => ({x: item.x + (selectedAlgorithmConfigsLength * 20), y: item.y}))
      };
      onAddNewLine(false, newLineData, selectedAlgorithmConfigsLength);
      setSelectedAlgoConfigs(prev => [...prev, newLineData]);
    }
  },[onAddNewLine, selectedAlgorithmConfigsLength, setSelectedAlgoConfigs, t]);

  const onRemovePoint = useCallback((currentPointCount: number)=>{
    if(currentPointCount > 3){
      setSelectedAlgoConfigs((prev: IAnomalyDetectionConfigs[]) => {
        const selectedAlgoConfigs = [...prev];
        const selectedAreaConfig = JSON.parse(JSON.stringify(selectedAlgoConfigs[selectedIndex - 1])); 
        selectedAreaConfig.pointsCount = selectedAreaConfig.pointsCount - 1;
        selectedAlgoConfigs[selectedIndex - 1] = selectedAreaConfig;
        return selectedAlgoConfigs;
      });
      dispatch({
        type: 'REMOVE_POINT',
        index: selectedIndex-1,
      });
    }else{
      setMessage({text: t('Minimum 3 points required!'), id:'',type:'danger'});
    }
  },[selectedIndex, dispatch, t, setSelectedAlgoConfigs]);

  const onAddPoint = useCallback(()=>{
    let isLimitExceeded = false;
    setSelectedAlgoConfigs((prev: IAnomalyDetectionConfigs[]) => {
      const selectedAlgoConfigs = [...prev];
      const selectedAreaConfig = JSON.parse(JSON.stringify(selectedAlgoConfigs[selectedIndex - 1])); 
      if(selectedAreaConfig.pointsCount + 1 > Config.algorithmConfigurations.areaPointsLimit){
        setMessage({text: t('pointsLimitWarning').replace('{X}',`${Config.algorithmConfigurations.areaPointsLimit}`), id:'',type:'danger'});
        isLimitExceeded = true;
      }else{
        selectedAreaConfig.pointsCount = selectedAreaConfig.pointsCount + 1;
      }
      selectedAlgoConfigs[selectedIndex - 1] = selectedAreaConfig;
      if(!isLimitExceeded){
        dispatch({
          type: 'ADD_POINT',
          index: selectedIndex-1,
        });
      }
      return selectedAlgoConfigs;
    });
  },[selectedIndex, dispatch, setSelectedAlgoConfigs, t]);

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAlgoConfigs((prev: IAnomalyDetectionConfigs[]) => {
      const selectedAlgoConfigs = [...prev];
      const selectedAreaConfig = JSON.parse(JSON.stringify(selectedAlgoConfigs[selectedIndex - 1])); 
      selectedAreaConfig.name = e.target.value;
      selectedAlgoConfigs[selectedIndex - 1] = selectedAreaConfig;
      return selectedAlgoConfigs;
    });
    dispatch({
      type: 'RENAME_SET',
      index: selectedIndex - 1,
      data: {
        areaName: e.target.value
      }
    });
  },[setSelectedAlgoConfigs, selectedIndex, dispatch]);

  const handleSwitchchange = useCallback((e: React.ChangeEvent<HTMLInputElement>)=>{
    setSelectedAlgoConfigs((prev: IAnomalyDetectionConfigs[]) => {
      const selectedAlgoConfigs = [...prev];
      const selectedAreaConfig = JSON.parse(JSON.stringify(selectedAlgoConfigs[selectedIndex - 1])); 
      selectedAreaConfig[e.target.name] = e.target.checked;
      selectedAlgoConfigs[selectedIndex - 1] = selectedAreaConfig;
      return selectedAlgoConfigs;
    });
  },[setSelectedAlgoConfigs, selectedIndex]);

  return (
    <ConfigurationForm>
      { message && <AlertBar message={message.text} setMessage={setMessage} type={message.type as IconType} />}
      {selectedIndex === 0  ? 
        <NoAreaData>
          <NoAreaText>{t('No area have been configured yet, please add new area')}</NoAreaText>
          <AddNewAreaButton onClick={AddNewLine}>{t('Add Area')}</AddNewAreaButton>
        </NoAreaData>:
        <AreaConfigDiv>
          <LineTools>
            <LineTool title={t('New Area')} onClick={AddNewLine}><Icon icon='AddCircle' size={16} /><div>{t('Area')}</div></LineTool>
          </LineTools>
          <FieldsContainer id='topFields'>
            <Input id='name' type='text' name='name'  label={t('Area Name' )} width='22vw' maxLength={16} value={selectedAlgoConfigs[selectedIndex - 1].name} onChange={handleInputChange} />
            <AreaPointsHandler>
              <Label>{t('Points')}</Label>
              <PointsToggleDiv>
                <PointsCountChanger onClick={()=>onRemovePoint(selectedAlgoConfigs[selectedIndex - 1]?.pointsCount as number)}><AiOutlineMinus /></PointsCountChanger>
                <PointsCount>{selectedAlgoConfigs[selectedIndex - 1].pointsCount}</PointsCount>
                <PointsCountChanger onClick={onAddPoint}><Icon icon='Add' size={20} /></PointsCountChanger>
              </PointsToggleDiv>
            </AreaPointsHandler>
          </FieldsContainer>
          <EventsContainer>
            <Label>{t('Detections')}</Label>
            <EventsWrapper>
              <EventsRow>
                <SingleEvent>
                  <Label>{t('Logo Image')}</Label>
                  <Switch checked={!!selectedAlgoConfigs[selectedIndex - 1].logoImage} name='logoImage' onChange={handleSwitchchange}/>
                </SingleEvent>
                <SingleEvent>
                  <Label>{t('MFG Date')}</Label>
                  <Switch checked={!!selectedAlgoConfigs[selectedIndex - 1].manufacturingDate} name='manufacturingDate' onChange={handleSwitchchange}/>
                </SingleEvent>
              </EventsRow>
              <EventsRow>
                <SingleEvent>
                  <Label>{t('Batch Number')}</Label>
                  <Switch checked={!!selectedAlgoConfigs[selectedIndex - 1].batchNo} name='batchNo' onChange={handleSwitchchange} />
                </SingleEvent>
                <SingleEvent>
                  <Label>{t('Expiry Date')}</Label>
                  <Switch checked={!!selectedAlgoConfigs[selectedIndex - 1].expiryDate} name='expiryDate' onChange={handleSwitchchange} />
                </SingleEvent>
              </EventsRow>
            </EventsWrapper>
          </EventsContainer>
          <ConfiguredLines>
            <Label>{t('Areas')}:</Label>
            <ConfiguredDetails>
              {(selectedAlgoConfigs as IAnomalyDetectionConfigs[])?.map((line,index)=>(
                <Configs isSelected={selectedIndex === index+1}>
                  <IconDiv onClick={()=>handleRemoveLine(index, selectedAlgoConfigs?.length)}>
                    <Icon icon='Close' size={10} />
                  </IconDiv>
                  <LineName onClick={()=>onLineClickCallback(index)}>{line?.name}</LineName>
                </Configs>
              ))}
            </ConfiguredDetails> 
          </ConfiguredLines>
          <AdvancedConfigurationSection>
            <AdvancedHeading>{t('Advanced')}</AdvancedHeading>
            <AdvanceConfigurations>
              <SelectField
                width='450px'
                label={t('Schedule')}
                options={scheduleOptions}
                placeholder={t('Please select the schedule')}
                defaultValue={(selectedAlgorithmConfigDetails?.scheduleId === -1 || selectedAlgorithmConfigDetails?.scheduleId === null) ? '' : selectedAlgorithmConfigDetails?.scheduleId}
                onChange={(value) => setSelectedAlgorithmConfigDetails(prev => ({ ...prev, scheduleId: parseInt(value) }))}
                emptyListText={t('schedules not found')} />
            </AdvanceConfigurations>
          </AdvancedConfigurationSection>
        </AreaConfigDiv>
      }
    </ConfigurationForm>
  );
};

export default ProductAnomalyRICHS;