import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import LoginLogo from '../svgs/logo_login.png';
import Input from 'components/Input';
import ButtonWithLoading from 'components/ButtonWithLoading';
import { GENERATE_OTP, VERIFY_OTP } from 'api_configs/mutations';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router';
import { StatusCode } from '../constants';
import { IAlertMessageType } from 'interface';
import AlertBar from 'components/AlertBar';
import { IconType } from '../constants';
import { useTranslation } from 'react-i18next';
import { allowOnlyNumbers } from 'utils/utils';
import Button from 'components/Button';

const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
`;

const TopSection = styled.div`
  width: 100%;
  height: 30%;
  background-color: #F6F6F6;
`;

const DownSection = styled.div`
  width: 100%;
  height: 70%;
  background-color: #6992c8;;
`;

const ForgotPasswordSection = styled.div`
  position: absolute;
  width: 500px;
  height: 620px;
  background-color: #ffffff;
  border-radius: 10px;
  margin-top: -14px;
  @media only screen and (min-width: 1360px){
    top: 12px;
    left: 414px;
  }
  @media only screen and (min-width: 1910px){
    top: 100px;
    left: 675px;
  }
`;

const AvtarImg = styled.img`
  margin-left: 140px;
  margin-top: 30px;
  width: 200px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 50px;
  cursor: pointer;
`;

const ForgotPasswordHeading = styled.div`
  margin-top: 30px;
  margin-bottom: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  font-size: 22px;
  font-family: Arial, Helvetica, sans-serif;
`;

const ForgotPasswordDescription = styled.div`
  margin: 14px 20px 30px 20px;
  color: grey;
  display: flex;
  justify-content: center;
  text-align: center;
  line-height: 1.3rem;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
`;

const UsernameWrapper = styled.div`
  margin: 20px 20px 0 20px;
`;

const GenerateOTPButton = styled.div`
  margin: 15px 30px 0 48px;
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const MaskedEmailInfoSection = styled.div`
  margin-top: 30px;
  margin-left: 20px;
  font-size: 15px;
  color: grey;
  font-family: Arial, Helvetica, sans-serif;
`;

const NewPasswordSection = styled.div`
  margin-top: 25px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  
  >button {
    max-width: 405px;
  }
`;

const UpdatePasswordButtonWrapper = styled.div`
  margin-top: 15px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  margin-top: 60px;
`;

const ResendOTPSection = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 15px;
  margin-left: 20px;
  > button {
    margin-top: 3px;
    height: 25px;
    font-size: 14px;
  }
`;

const ResendOTPText = styled.div`
  margin-top: 5px;
  font-size: 15px;
  color: grey;
`;

const ResendOTPTimer = styled.div`
  margin-top: 5px;
`;

const NoPasswordMatchIndicator = styled.div`
  margin-top: -14px;
  font-size: 16px;
  color: red;
`;

const Forgotpassword = () => {
  const [username, setUsername] = useState('');
  const [newPasswordInfo, setNewPasswordInfo] = useState({otp: '', newPassword: '', confirmPassword: ''});
  const [maskedEmail, setMaskedEmail] = useState('');
  const [generateOTPLoading, setGenerateOTPLoading] = useState(false);
  const [updatePasswordLoading, setUpdatePasswordLoading] = useState(false);
  const [resendOTPTimer, setResendOTPTimer] = useState('59');
  const {t} = useTranslation(['common']);
  const [isStrongPassword, setIsStrongPassword] = useState(false);
  const history = useHistory();
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: ''});  
  const [resetPasswordSection, setResetPasswordSection] = useState(false);
  const [generateOtp] = useMutation(GENERATE_OTP);
  const [verifyOtp] = useMutation(VERIFY_OTP);

  useEffect(() => {
    if(resetPasswordSection && resendOTPTimer !== '00') {
      if(Number(resendOTPTimer) <= 10) {
        setTimeout(() => {setResendOTPTimer(`0${(Number(resendOTPTimer) - 1)}`);}, 1000);
      }else{
        setTimeout(() => {setResendOTPTimer((Number(resendOTPTimer) - 1).toString());}, 1000);
      }
    }
  }, [resendOTPTimer, resetPasswordSection]);

  const handleSubmitUsername = async () => {
    setGenerateOTPLoading(true);
    try{
      const data = await generateOtp({variables: {username: username}});
      if(data && data?.data?.generateOtp?.statusCode === StatusCode.SUCCESS){
        setMaskedEmail(data?.data?.generateOtp?.result?.toLowerCase());
        setMessage({text: t(`OTP Sent to ${data?.data?.generateOtp?.result?.toLowerCase()}`), id:'',type:'success'});
        setGenerateOTPLoading(false);
        setResetPasswordSection(true);
      }else if(data && data?.data?.generateOtp?.statusCode === StatusCode.USER_NOT_FOUND) {
        setGenerateOTPLoading(false);
        setMessage({text: t('Username does not exists.'), id:'',type:'danger'});
      }else if(data && data?.data?.generateOtp?.statusCode === StatusCode.NO_EMAIL_CLIENT){
        setGenerateOTPLoading(false);
        setMessage({text: t('Cannot send OTP, Please contact Administrator.'), id:'',type:'danger'});
      }
    }catch(e){
      setGenerateOTPLoading(false);
      setMessage({text: t('apiError'), id:'',type:'danger'});
      console.error(e);
    }
  };

  const handleUpdatePassword = async () => {
    if(!isStrongPassword) {
      setMessage({text: t('Please choose a stronger password. Try a mix of letters, numbers, and symbols.'), id:'newPassword',type:'danger'});
      return;
    }
    setUpdatePasswordLoading(true);
    try{
      const data = await verifyOtp({variables: {username: username, otp: Number(newPasswordInfo.otp), password: newPasswordInfo.newPassword}});
      if(data && data?.data?.verifyOtp?.statusCode === StatusCode.SUCCESS){
        setMessage({text: t('Password updated successfully'), id:'',type:'success'});
        setUpdatePasswordLoading(false);
        setTimeout(() => {
          history.push('/login');
          window.location.reload();
        }, 2000);
      }else if(data?.data?.verifyOtp?.statusCode === StatusCode.INVALID_OTP) {
        setUpdatePasswordLoading(false);
        setMessage({text: t('Invalid OTP. Please check and try again.'), id:'',type:'danger'});
      }else{
        setGenerateOTPLoading(false);
        setMessage({text: t('Something went wrong!'), id:'',type:'danger'});
      }
    }catch(e){
      setUpdatePasswordLoading(false);
      setMessage({text: t('apiError'), id:'',type:'danger'});
      console.error(e);
    }
  };

  const handleResendOTP = () => {
    handleSubmitUsername();
    setResendOTPTimer('59');
  };

  const handleLogoClick = () => {
    history.push('/login');
    window.location.reload();
  };

  return (
    <Container>
      {
        message && <AlertBar message={message.text} type={message.type as IconType} setMessage={setMessage} />
      }
      <TopSection></TopSection>
      <DownSection></DownSection>
      {!resetPasswordSection && <ForgotPasswordSection>
        <ContentWrapper>
          <AvtarImg src={LoginLogo} onClick={() => handleLogoClick()} />
          <ForgotPasswordHeading>{t('Forgot Password')}</ForgotPasswordHeading>
          <ForgotPasswordDescription>{t('Please enter username below. We will send OTP to your registered Email')}</ForgotPasswordDescription>
          <UsernameWrapper>
            <Input type='text' label='' placeholder={t('Please enter username')} value={username} onChange={(e) => setUsername(e.target.value)} id='username' width='458px'/>
          </UsernameWrapper>
          <GenerateOTPButton>
            <ButtonWithLoading variant='primary' disabled={username === ''} loading={generateOTPLoading} onClick={() => handleSubmitUsername()}>{t('Send OTP')}</ButtonWithLoading>
            <Button variant='primary' onClick={() => {history.push('/login'); window.location.reload();}}>{t('Back to Login')}</Button>
          </GenerateOTPButton>
        </ContentWrapper>
      </ForgotPasswordSection>}
      {
        resetPasswordSection && <ForgotPasswordSection>
          <AvtarImg src={LoginLogo} onClick={() => handleLogoClick()} />
          <MaskedEmailInfoSection>{t(`OTP sent to ${maskedEmail}`)}</MaskedEmailInfoSection>
          <ResendOTPSection>
            <ResendOTPText>{t('Did not receive OTP?')}</ResendOTPText>
            <Button variant='primary' disabled={resendOTPTimer !== '00'} onClick={() => handleResendOTP()}>{t('Resend OTP')}</Button>
            {resendOTPTimer !== '00' && <ResendOTPTimer>00 : {resendOTPTimer}</ResendOTPTimer>}
          </ResendOTPSection>
          <NewPasswordSection>
            <Input type='text' onKeyPress={allowOnlyNumbers} label={t('Enter OTP')} id='otp' value={newPasswordInfo.otp} maxLength={6} onChange={((e) => setNewPasswordInfo(prev => ({...prev, otp: e.target.value})))} width='458px' />
            <Input type='password' strongPassword setIsUserPasswordStrong={setIsStrongPassword} label={t('Enter new password')} id='newPassword' value={newPasswordInfo.newPassword} onChange={((e) => setNewPasswordInfo(prev => ({...prev, newPassword: e.target.value})))} width='458px' />
            <Input type='password' label={t('Confirm new password')} id='confirmNewPassword' value={newPasswordInfo.confirmPassword} onChange={((e) => setNewPasswordInfo(prev => ({...prev, confirmPassword: e.target.value})))} width='458px' />
            {(newPasswordInfo.confirmPassword.length >= 1 && (newPasswordInfo.newPassword !== newPasswordInfo.confirmPassword)) && <NoPasswordMatchIndicator>{t('Passwords do not match')}</NoPasswordMatchIndicator> }
          </NewPasswordSection>
          <UpdatePasswordButtonWrapper>
            <ButtonWithLoading variant='primary' disabled={(newPasswordInfo.otp === '' || newPasswordInfo.newPassword === '' || newPasswordInfo.confirmPassword === '') || (newPasswordInfo.newPassword !== newPasswordInfo.confirmPassword)} loading={updatePasswordLoading} onClick={() => handleUpdatePassword()}>{t('Update Password')}</ButtonWithLoading>
          </UpdatePasswordButtonWrapper>
        </ForgotPasswordSection>
      }
    </Container>
  );
};

export default Forgotpassword;