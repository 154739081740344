import React, { useState, useEffect, DragEvent, useRef, RefObject, useCallback, useMemo } from 'react';
import styled, { css, keyframes } from 'styled-components';
import ButtonWithIcon from 'components/ButtonWithIcon';
import { DOWNLOAD_GRAFANA_CHARTS_PDF, GET_ALL_GROUPS, GET_ALL_STREAMS, GET_DASHBOARD_CHARTS, GET_FILTERED_CHARTS, GET_STORED_GRAFANA_CHARTS } from 'api_configs/queries';
import { useMutation, useQuery } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import Label from 'components/Label';
import { useTranslation } from 'react-i18next';
import { useHeader } from 'contexts/HeaderContext';
import { useHistory } from 'react-router';
import TokenService from 'api_configs/tokenService';
import Modal from 'components/Modal';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { ALGORITHM_CODES, DATE_PICKER_DATE_TIME_FORMAT, GRAFANA_CHART_RANGE_OPTIONS, GRAFANA_REFRESH_INTERVAL, GRAFANA_URL, PERMISSION_CODE, SERVER_URL, SUPERSET_PORT, SUPERSET_URL } from '../constants';
import { DateTimePicker, LocalizationProvider, renderTimeViewClock } from '@mui/x-date-pickers';
import Button from 'components/Button';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { GRAFANA_DASHBOARD_PAGE_MUTATION, SUPERSET_DASHBOARD_PAGE_MUTATION } from 'api_configs/mutations';
import { IconType, SUPERSET_REFRESH_INTERVAL, StatusCode } from '../constants';
import { IAlertMessageType, IOption, IStream } from 'interface';
import AlertBar from 'components/AlertBar';
import { AiOutlineBarChart } from 'react-icons/ai';
import config from 'configs/config.json';
import SelectField from 'components/SelectField';
import { useClickOutside } from 'hooks/useClickOutside';
import ButtonWithLoading from 'components/ButtonWithLoading';
import ThreeDotLoader from 'components/ThreeDotLoader';
import Calendar from 'components/Calender';
import { LuRefreshCcw } from 'react-icons/lu';
import { convertToISTWithDash, convertToJST } from 'utils';
import MultipleSelect from 'components/MultiSelectField';
import { IoMdClose } from 'react-icons/io';
import StatisticInfoPanel from 'components/StatisticInfoPanel';
import { DetectionProvider } from 'contexts/DetectionContext';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const ImportChartsWrapper = styled.div`
  margin: 27px 0 5px 10px;
  display: flex;
  gap: 12px;
  justify-content: end;
  @media only screen and (min-width: 1366px){
    justify-content: start;
    flex-direction: column;
    >button{
      width: 190px;
    }
  }
`;

const DashboardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 8px;
  margin-right: 30px;
`;

const CalenderWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

const ChartsWrapper = styled.div`
  margin-left: 25px;
  margin-top: 25px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
`;

const SingleChartWrapper = styled.div<{ isRearrange: boolean }>`
  position: relative;
  padding-top: 15px;
  height: fit-content;
  border-radius: 10px;
  border: ${({ isRearrange }) => (isRearrange ? '1px solid #435FCA' : 'none')};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  &:hover {
    border-right-width: ${({ isRearrange }) => (isRearrange ? '5px' : '0')};
    border-bottom-width: ${({ isRearrange }) => (isRearrange ? '5px' : '0')};
    transition: border-right-width 0.3s ease;
  }
  > iframe {
    border-radius: 10px;
  }
`;

const ChartTitle = styled.div`
  font-size: 20px;
  cursor: pointer;
  border-bottom: 2px solid #F6F5F9;
`;

const EmbeddedChart = styled.iframe`
  border: none;
`;

const SpinnerBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 89vh;
  align-items: center;
  justify-content: center;
`;

const ResizeIndicator = styled.div`
  position: absolute;
  right: -16px;
  bottom: -8px;
  width: 65px;
  height: 400px;
  background-color: transparent;
  cursor: e-resize;
  transition: width 1s ease; 
`;

const ResizeIndicatorY = styled.div<{ width: number }>`
  position: absolute;
  right: 0;
  bottom: 0;
  width: ${({width}) => width ? `${width}px`: '600px'};
  height: 60px;
  background-color: transparent;
  cursor: ew-resize;
  transition: width 1s ease; 
`;

const MessageWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 30%;
  text-align: center;
  @media only screen and (min-width: 1366px){
    top: 80%;
    left: 20%;
  }
  @media only screen and (min-width: 1900px){
    top: 50%;
  }
`;

const AppliedFilterWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-left: 40px;
  margin-top: -8px;
  margin-bottom: 10px;
`;

const DurationTextWrapper = styled.span`
  font-size: 14px;
`;

const AppliedFiltersDiv = styled.div`
  padding: 5px 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: ${({theme}) => theme.sidemenu.selectedItembackground};
  border-radius: 10px;
`;

const ModalViewWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const VisualizationWrapper = styled.div`
  z-index: 9;
`;

const VisualizationToggleWrapper = styled.span<{isDefaultPage: boolean}>`
  position: absolute;
  top: 14px;
  z-index: 9;
  right: ${({isDefaultPage}) => !isDefaultPage ? '116px' : '172px'};
  cursor: pointer;
`;

const AllChartOptionsWrapper = styled.div<{visualizationIconToggle: boolean, isAlertIconPresent: boolean}>`
  display: ${({visualizationIconToggle}) => visualizationIconToggle ? 'flex' : 'none'};
  flex-direction: column;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  position: absolute;
  background-color: ${({theme}) => theme.backgroundColor};
  border-radius: 5px;
  height: fit-content;
  width: 196px;
  right: ${({isAlertIconPresent}) => !isAlertIconPresent ? '122px' : '170px'};
  top: 40px;
  margin-top: 11px;
  z-index: 9;
`;

const Arrow = styled.div`
  width: 15px;
  height: 15px;
  background-color: ${({theme}) => theme.backgroundColor};
  position: absolute;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  right: 0;
  top: -8px;
  transform: rotate(45deg);
  border-top: 1px solid #DCE2E4;
  border-left: 1px solid #DCE2E4;
`;

const ChartOptionsWrapper = styled.div`
  display: flex;
  position: relative;
  width: 200px;
  height: 49px;
  cursor: pointer;
  background-color: #FFFFFF;
  border: 1px solid #DCE2E4;
  :hover{
    background-color: #EEFCFF;
  }
  padding: 2px 0 2px 2px;
  height: fit-content;
  z-index: 9;
`;

const ChartOption = styled.option`
  width: 108px;
  padding: 7px 0 7px 7px;
  margin: 5px 20px;
`;

const PaginationWrapper = styled.div`
 display: flex;
 gap: 8px;
 height: 49px;
 padding-right: 22px
  > nav {
    margin: 0 auto;
  }
`;

const RefreshLabel = styled.div`
  font-size: 16px;
  margin: auto 2px auto 20px;
  width: 108px;
`;

const SelectOptionField = styled.select`
  border-radius: 8px;
  height: 35px;
  margin: auto;
  border: solid 1px #BFCDD2;
  padding: 0 6px 0 0px;
`;

const GrafanaChartsWrapper = styled.div`
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  margin-left: 38px;
  margin-bottom: 18px;
`;

const GrafanaSingleChartWrapper = styled.div<{ isRearrange: boolean }>`
  position: relative;
  border: ${({ isRearrange }) => (isRearrange ? '1px solid #435FCA' : 'none')};
  border-radius: ${({ isRearrange }) => (isRearrange ? '10px' : 'none')};
  height: fit-content;

  &:hover {
    border-right-width: ${({ isRearrange }) => (isRearrange ? '5px' : '0')};
    border-bottom-width: ${({ isRearrange }) => (isRearrange ? '5px' : '0')};
    transition: border-right-width 0.3s ease;
  }

  >iframe {
    .grafana-app {
      background-color: red;
    }
  }
`;

const FiltersWrapper = styled.div`
  display: flex;
  margin-left: 40px;
  position: relative;
`;

const IFrameGrafana = styled.iframe`
  border: 0.8px solid lightgrey;
  border-radius: 10px;
`;

const CustomFilter = styled.div`
  margin-top: 36px;
  margin-left: 20px;
`;

const SeparatorTextWrapper = styled.span`
  margin-top: 42px;
  margin-left: 20px;
`;

const CustomFilterWrapper = styled.div`
  width: 400px;
  height: 248px;
  position: absolute;
  border: 1px solid ${({theme}) => theme.divider};
  top: -36px;
  left: 605px;
  border-radius: 8px;
  background-color: ${({theme}) => theme.backgroundColor};
  z-index: 5;
  padding: 10px;
`;

const DashboardHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  @media only screen and (min-width: 1366px){
    margin-left: 20px;
  }
  @media only screen and (min-width: 1900px){
    margin-left: 0;
  }

`;

const AllFilters = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  min-height: 176px;
`;

const FiltersSection = styled.div`
  display: flex;
  align-items: center;
  gap: 35px;

  >div:nth-child(2), >div:nth-child(3) {
    width: 250px;
    >:nth-child(2) {
      min-height: 55px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    }
  }
  >div:nth-child(2):nth-child(1), >div:nth-child(3):nth-child(1) {
    margin-bottom: 2px;
  }
`;

const CalendarLabel = styled.div``;

const ModalLabel = styled.span<{isBold?: boolean}>`
  font-weight: ${({isBold}) => isBold ? 600 : ''};
  font-size: ${({isBold}) => isBold ? '18px' : '16px'};
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

const FilterContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex: 1;
`;

const Title = styled.div`
  padding-left: 20px;
  padding-bottom: 12px;
  font-size: 16px;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
`;

const AppliedFiltersWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
`;

const FilterTag = styled.div`
  display: flex;
  align-items: center;
  background-color: rgb(161, 175, 229);
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 14px;
  
  svg {
    margin-left: 5px;
    cursor: pointer;
  }
`;

const ClearAllFiltersWrapper = styled.div`
  margin-left: 25px;
  margin-top: 4px;
  cursor: pointer;
  font-size: 14px;
  padding: 4px 8px;
  border-radius: 5px;
  background-color: #e4edf4;
  :hover {
    background-color: #A4B0C7;
    color: white;
  }
`;

const RefreshButtonWrapper = styled.div<{isTrafficPulse?: boolean}>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  margin-top: ${({isTrafficPulse}) => isTrafficPulse ? '0' : '10px'};
`;

const IconWrapper = styled.div<{isRotating: boolean}>`
  cursor: pointer;
  > svg {
    ${({ isRotating }) =>
    isRotating &&
    css`
      animation: ${rotate} 1s linear infinite;
    `}
  }
`;

const StatusWrapper = styled.div`
  font-size: 14px;
  margin-top: 2px;
  color: #737D81;
  margin-bottom: 5px;
`;

const DateRangeFilterContainer = styled(FilterContainerWrapper)`
  gap: 8px;
`;

const DateRangeContainer = styled.div`
  div .MuiStack-root{
    flex-direction: column;
    gap: 10px;
  }
`;

const DatePickersWrappers = styled.div`
  > div {
    display: flex;
    flex-direction: row;
    column-gap: 30px;
    top: 0;
    > div:nth-child(2){
       margin-top: 0;
    }
  }
`;

const CloseButtonWrapper = styled.div`
  display: flex;
`;

const ChartsSection = styled.div<{isTrafficPulse?: boolean}>`
  display: flex;
  flex-direction: column;
  margin-top: ${({isTrafficPulse}) => isTrafficPulse ? '-53px': '0'};
`;

interface IChartsInfo {
  datasource: string,
  description: string | null,
  permalink: string,
  sliceId: number,
  linkStatus: string,
  sliceName: string,
  url: string,
  width: number,
  height?: number,
  vizType: string,
  sequenceId: number,
  dashboardId: number
}

interface IGroups {
  id: number,
  name: string,
  description?: string,
  streams: IStream[]
}

interface IDashboardInfo {
  id: number,
  title: string,
  url: string,
  chartInfo: IChartsInfo[]
  permalink: string | null,
  status: string | null,
  thumbnailUrl: string | null,
  chartsInfo: IChartsInfo[]
}

interface IDashboard {
  data: IDashboardInfo[]
}

const Dashboard = () => {
  const [dashboardCharts, setDashboardCharts] = useState<IDashboard | null>();
  const [isNewCharts, setIsNewCharts] = useState(false);
  const {t} = useTranslation(['common']);
  const [fullScreenModalView, setFullScreenModalView] = useState(false);
  const [modalViewSrc, setModalViewSrc] = useState('');
  const [resizeStartX, setResizeStartX] = useState(0);
  const [resizeStartY, setResizeStartY] = useState(0);
  const [isResizing, setIsResizing] = useState(false);
  const [visualizationIconToggle, setVisualizationIconToggle] = useState(false);
  const [supersetDataRefetchInterval, setSupersetDataRefetchInterval] = useState(localStorage.getItem('DashboardChartsRefresh') as string || '15s');
  const visualizationIconRef = useRef<HTMLInputElement>(null);
  const [resizingSliceId, setResizingSliceId] = useState<number | null>();
  const [startDate, setStartDate] = useState<Dayjs| null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [isResetSequenceActive, setIsResetSequenceActive] = useState(false);
  const history = useHistory();
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: ''});  
  const pathUrl = 'dashboardpage';
  const {updateHeaderTitle} = useHeader();
  const [selectedCamera, setSelectedCamera] = useState<string[]>((JSON.parse(localStorage.getItem('dashboardFilters') as string))?.selectedCamera);
  const [storeChartsInfo] = useMutation(SUPERSET_DASHBOARD_PAGE_MUTATION);
  const [storeGrafanaChartsInfo] = useMutation(GRAFANA_DASHBOARD_PAGE_MUTATION);
  const [selectedGroup, setSelectedGroup] = useState((JSON.parse(localStorage.getItem('dashboardFilters') as string))?.selectedGroup?.map((groupId: string) => Number(groupId)));
  const [grafanaChartsTimeRange, setGrafanaChartsTimeRange] = useState('from=now-7d&to=now-1m');
  const [sortedCharts, setSortedCharts] = useState<IChartsInfo[]>([]);
  const {loading: dashboardChartsLoading} = useQuery(GET_DASHBOARD_CHARTS, {variables: {pageName: 'dashboardpage', username: JSON.parse(localStorage.getItem('user_login_info') as string).username}, skip: (config.Visualization_Tool as string) !== 'Superset'});
  const {data: grafanaCharts, loading: grafanaChartsLoading, refetch: refetchStoredGrafanaCharts} = useQuery(GET_STORED_GRAFANA_CHARTS, {variables: {pageName: 'dashboardpage', username: JSON.parse(localStorage.getItem('user_login_info') as string).username}, skip: (config.Visualization_Tool as string) !== 'Grafana'});
  const viewChartsAccess = TokenService.hasAccess(PERMISSION_CODE.view_charts);
  const [allGrafanaCharts, setAllGrafanaCharts] = useState([]);
  const [customFilterModal, setCustomFilterModal] = useState(false);
  const [isCustomDateFilter, setIsCustomDateFilter] = useState(false);
  const [chartsUpdateTime, setChartsUpdateTime] = useState('Just now');
  const [clearDateInterval, setClearDateInterval] = useState(false);
  const [isAllFiltersClear, setIsAllFiltersClear] = useState(false);
  const {data: getAllStreamsResponse, refetch: getAllStreamsRefetch} = useQuery(GET_ALL_STREAMS, {skip: config.cameraDetailsPageConfigs.layout !== 'default'});
  const [appliedFilterName, setAppliedFilterName] = useState('Last week');
  const customFilterRef = useRef<HTMLDivElement>(null);
  const [dateError, setDateError] = useState<boolean>(false);
  const [loader, setLoader] = useState(false);
  const [allStreams, setAllStreams] = useState<IOption[]>([]);
  const [allStreamsData, setAllStreamData] = useState<IStream[]>([]);
  const {refetch: downloadGrafanaPDFRefetch} = useQuery(DOWNLOAD_GRAFANA_CHARTS_PDF,{skip: true});
  const [resizeChartDashboardId, setResizeChartDashboardId] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [isRotating, setIsRotating] = useState(false);
  const [groups, setGroups] = useState<IGroups[]>();
  const {data: getAllGroupsResponse, loading: getAllGroupsLoading} = useQuery(GET_ALL_GROUPS,{skip: config.cameraDetailsPageConfigs.layout !== 'default'});
  const [dateFilter, setDateFilter] = useState((JSON.parse(localStorage.getItem('dashboardFilters') as string))?.dateFilter);
  const { refetch: refetchFilteredSupersetCharts } = useQuery(GET_FILTERED_CHARTS, {
    variables: {
      pageName: 'dashboardpage',
      username: JSON.parse(localStorage.getItem('user_login_info') as string).username,
    },
    skip: true,
  });

  const filteredDashboardCall = useCallback(async (payloadToSend: any) => {
    setLoader(true);
    const { data: getDateFilteredCharts } = await refetchFilteredSupersetCharts({
      payload: payloadToSend
    });
    if (getDateFilteredCharts?.getFilteredCharts?.result?.length > 0) {
      setDashboardCharts({data: getDateFilteredCharts?.getFilteredCharts?.result});
    }
    setLoader(false);
  }, [refetchFilteredSupersetCharts]);

  const checkedPayload = useMemo(() => ({
    startTime: (JSON.parse(localStorage.getItem('dashboardFilters') as string))?.dateFilter?.startDate,
    endTime: (JSON.parse(localStorage.getItem('dashboardFilters') as string))?.dateFilter?.endDate,
    groupIds: (JSON.parse(localStorage.getItem('dashboardFilters') as string))?.selectedGroup,
    streamIds: (JSON.parse(localStorage.getItem('dashboardFilters') as string))?.selectedCamera,
    pageName: 'dashboardpage',
    username: JSON.parse(localStorage.getItem('user_login_info') as string).username,
  }), []);

  useEffect(() => {
    if(JSON.parse(localStorage.getItem('dashboardpage') as string)) {
      if(config.Visualization_Tool === 'Superset') {
        if(!checkedPayload.startTime || !checkedPayload.endTime) {
          delete checkedPayload.startTime;
          delete checkedPayload.endTime;
        }
        if(checkedPayload.streamIds?.length === 0) {
          delete checkedPayload.streamIds;
        }
        if(checkedPayload.groupIds?.length === 0) {
          delete checkedPayload.groupIds;
        }
        filteredDashboardCall(checkedPayload);
        setChartsUpdateTime(t('Just now'));
      }else{
        refetchStoredGrafanaCharts();
      }
      localStorage.removeItem('dashboardpage');
    }
  }, [refetchFilteredSupersetCharts, checkedPayload, filteredDashboardCall, t, refetchStoredGrafanaCharts]);

  useEffect(() => {
    if(config.cameraDetailsPageConfigs.layout === 'default') {
      if(!getAllGroupsLoading) {
        setGroups(getAllGroupsResponse?.getAllGroup?.result === null ? [] : [...getAllGroupsResponse?.getAllGroup?.result as []]);
      }
    }
  }, [getAllGroupsLoading, getAllGroupsResponse, t]);

  const getAllStreams = useCallback(async () => {
    const allStreamDataResponse = await getAllStreamsResponse;
    if (allStreamDataResponse && allStreamDataResponse.getAllStreams.statusCode === StatusCode.SUCCESS && allStreamDataResponse.getAllStreams.result !== null) {
      setAllStreamData(allStreamDataResponse?.getAllStreams?.result);
    }else if(allStreamDataResponse && allStreamDataResponse.getAllStreams.statusCode === StatusCode.NO_RESULT){
      setAllStreams([]);
    }
    await getAllStreamsRefetch();
  }, [getAllStreamsRefetch, getAllStreamsResponse]);

  useEffect(() => {
    if(config.cameraDetailsPageConfigs.layout === 'default') {
      getAllStreams();
    }
  }, [getAllStreams]);

  useEffect(()=>{
    updateHeaderTitle(t('Dashboard'), 'Home');
  },[updateHeaderTitle, t]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (chartsUpdateTime === 'Just now') {
        setChartsUpdateTime(`1 ${t('seconds')}`);
      } else {
        const timeParts = chartsUpdateTime.split(' ');
        const currentNumber = Number(timeParts[0]);
        
        if (!isNaN(currentNumber)) {
          setChartsUpdateTime(`${currentNumber + 1} ${t('seconds')}`);
        } else {
          setChartsUpdateTime(`1 ${t('seconds')}`);
        }
      }
    }, 1000);
  
    return () => {
      clearInterval(interval);
    };
  }, [chartsUpdateTime, t]);

  useEffect(() => {
    if(isNewCharts) {
      setTimeout(() => {
        setIsNewCharts(false);
      }, 2000);
    }
  }, [isNewCharts]);

  useEffect(() => {
    if(config.Visualization_Tool === 'Superset') {
      if(!checkedPayload.startTime || !checkedPayload.endTime) {
        delete checkedPayload.startTime;
        delete checkedPayload.endTime;
      }
      if(checkedPayload.streamIds?.length === 0) {
        delete checkedPayload.streamIds;
      }
      if(checkedPayload.groupIds?.length === 0) {
        delete checkedPayload.groupIds;
      }
      filteredDashboardCall(checkedPayload);
    }
  }, [filteredDashboardCall, checkedPayload]);

  useEffect(() => {
    if(selectedGroup.length > 0) {
      const filtered = allStreamsData?.filter((camera: IStream) =>
        camera?.groupList?.some((groupId) => selectedGroup?.map((group: any) => Number(group))?.includes(groupId))
      );
      setAllStreams(filtered?.map((stream: IStream) => ({name: stream.name, value: stream.instanceId})));
    }else {
      setAllStreams(allStreamsData?.map((stream: IStream) => ({name: stream.name, value: stream.instanceId})));
    }
  }, [selectedGroup, allStreamsData]);

  useEffect(() => {
    if(config.Visualization_Tool === 'Grafana') {
      if(grafanaCharts) {
        if(!grafanaChartsLoading) {
          setAllGrafanaCharts(grafanaCharts?.getStoredGrafanaCharts?.result?.dashboards);
        }
      }
    }
  }, [grafanaCharts, grafanaChartsLoading]);

  useEffect(() => {
    const filters = {
      dateFilter,
      selectedGroup,
      selectedCamera,
    };
    localStorage.setItem('dashboardFilters', JSON.stringify(filters));
  }, [dateFilter, selectedGroup, selectedCamera]);


  useEffect(() => {
    const customDateRangeFilter = JSON.parse(localStorage.getItem('grafanaCustomDateRange') as string);
    if(customDateRangeFilter?.startDate && customDateRangeFilter?.endDate) {
      const startDate = new Date(customDateRangeFilter?.startDate);
      const endDate = new Date(customDateRangeFilter?.endDate);
      const fetchedStartDate = dayjs(startDate.toString());
      const fetchedEndDate = dayjs(endDate.toString());
      setStartDate(fetchedStartDate);
      setEndDate(fetchedEndDate);
      setIsCustomDateFilter(true);
      setGrafanaChartsTimeRange(`&from=${startDate?.getTime().toString()}&to=${endDate?.getTime().toString()}`);
    }else if(customDateRangeFilter?.range) {
      setGrafanaChartsTimeRange(customDateRangeFilter?.range);
      const nameOfAppliedFilter = GRAFANA_CHART_RANGE_OPTIONS.filter((option) => option.value === customDateRangeFilter?.range);
      setAppliedFilterName(nameOfAppliedFilter[0]?.name);
    }
  }, []);

  const handleModalChartView = (sliceUrl: string) => {
    setModalViewSrc(sliceUrl);
    setFullScreenModalView(true);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      refetchFilteredSupersetCharts();
      setChartsUpdateTime(t('Just now'));
    }, (Number(Number(supersetDataRefetchInterval.substring(0, supersetDataRefetchInterval.indexOf('s')))) * 1000));

    return () => {
      clearInterval(interval);
    };
  }, [refetchFilteredSupersetCharts, t, supersetDataRefetchInterval]);

  useEffect(() => {
    if(JSON.parse(localStorage.getItem('dashboardpage') as string)) {
      refetchFilteredSupersetCharts();
      setChartsUpdateTime(t('Just now'));
      localStorage.removeItem('dashboardpage');
    }
  }, [refetchFilteredSupersetCharts, t, isNewCharts]);

  useEffect(() => {
    localStorage.setItem('DashboardChartsRefresh', supersetDataRefetchInterval);
  }, [supersetDataRefetchInterval]);

  const handleRearrangeCharts = async () => {
    const userLoginInfo = JSON.parse(localStorage.getItem('user_login_info') as string);

    if(config.Visualization_Tool === 'Superset') {
      const updatedArray = (dashboardCharts?.data || []).map((dataItem: IDashboardInfo) => ({
        ...dataItem,
        chartsInfo: (dataItem.chartsInfo || []).map(({ linkStatus, ...rest }) => rest),
      }));
      
      const data = updatedArray.map((dataItem) => ({
        ...dataItem,
        id: dataItem.id?.toString(),
      }));
      
      const payload = {
        pageName: pathUrl || '',
        username: userLoginInfo.username || '',
        data: data || [],
      };
      setLoading(true);
      try{
        const data = await storeChartsInfo({variables: {payload: payload}});
        if(data && data?.data?.storeChartsInfo?.statusCode === StatusCode.SUCCESS){
          localStorage.setItem(`${pathUrl}`, 'true');
          history.push(`/${pathUrl.substring(0, pathUrl.indexOf('page'))}`);
          setMessage({text: t('Charts sequence updated successfully'), id:'',type:'success'});
          setIsResetSequenceActive(false);
          const checkedPayload = {
            startTime: dateFilter.startDate ? dateFilter.startDate : undefined,
            endTime: dateFilter.endDate ? dateFilter.endDate : undefined,
            groupIds: selectedGroup.length === 0 ? undefined: selectedGroup ,
            streamIds: selectedCamera.length === 0 ? undefined: selectedCamera,
            pageName: 'dashboardpage',
            username: JSON.parse(localStorage.getItem('user_login_info') as string).username
          };
      
          if(!dateFilter.startDate || !dateFilter.endDate) {
            delete checkedPayload.startTime;
            delete checkedPayload.endTime;
          }
          if(!checkedPayload.streamIds) {
            delete checkedPayload.streamIds;
          }
          if(!checkedPayload.groupIds) {
            delete checkedPayload.groupIds;
          }
          filteredDashboardCall(checkedPayload);
        }else{
          setMessage({text: t('apiError'), id:'',type:'danger'});
        }
      }catch(e){
        setMessage({text: t('apiError'), id:'',type:'danger'});
      }finally {
        setLoading(false);
      }
    }else {
      const updatedArray = (allGrafanaCharts).map((dataItem: IDashboardInfo) => ({
        ...dataItem,
        chartInfo: (dataItem.chartInfo || []).map(({...rest }) => rest)
      }));
      
      const payload = {
        pageName: pathUrl || '',
        username: userLoginInfo.username || '',
        data: updatedArray || [],
      };
      try{
        const data = await storeGrafanaChartsInfo({variables: {payload: payload}});
        if(data && data?.data?.storeGrafanaChartsInfo?.statusCode === StatusCode.SUCCESS){
          localStorage.setItem(`${pathUrl}`, 'true');
          history.push(`/${pathUrl.substring(0, pathUrl.indexOf('page'))}`);
          setMessage({text: t('Charts sequence updated successfully'), id:'',type:'success'});
          setIsResetSequenceActive(false);
          refetchFilteredSupersetCharts();
        }else{
          setMessage({text: t('apiError'), id:'',type:'danger'});
        }
      }catch(e){
        setMessage({text: t('apiError'), id:'',type:'danger'});
      }
    }
  };

  const useOutSideClick = (ref: RefObject<HTMLDivElement>) => {
    useEffect(() => {
      function handleOutsideClick(event:MouseEvent) {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          setVisualizationIconToggle(false);
        }
      }
      document.addEventListener('click', handleOutsideClick);
      return () => {
        document.removeEventListener('click', handleOutsideClick);
      };
    }, [ref]);
  };

  useOutSideClick(visualizationIconRef);

  const handleDragStart = (e: DragEvent<HTMLDivElement>, index: number) => {
    e.dataTransfer.setData('text/plain', String(index));
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>, dropIndex: number) => {
    e.preventDefault();
    const dragIndex = e.dataTransfer.getData('text/plain');
    if(config.Visualization_Tool === 'Superset') {
      const updatedDashboard = dashboardCharts?.data?.map(dashboard => ({
        ...dashboard,
        chartsInfo: dashboard?.chartsInfo?.map(chart => {
          if (chart.sequenceId === Number(dragIndex)) {
            return { ...chart, sequenceId: dropIndex };
          } else if (chart.sequenceId === dropIndex) {
            return { ...chart, sequenceId: Number(dragIndex) };
          }
          return chart;
        }),
      }));
      const updatedCharts = {
        ...dashboardCharts,
        data: updatedDashboard || [],
      };
      setDashboardCharts(updatedCharts);
    }
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDateRangeCallback = useCallback(async (startDate: string | null, endDate: string | null) => {
    if(startDate && endDate){
      setIsFilterApplied(true);
      const startDateJP = convertToJST(startDate);
      const endDateJP = convertToJST(endDate);
      if(!isAllFiltersClear) {
        setDateFilter({startDate: startDateJP, endDate: endDateJP});
      }else {
        setIsAllFiltersClear(false);
      }
      if(config.cameraDetailsPageConfigs.layout !== 'traffic_pulse') {
        const checkedPayload = {
          startTime: startDateJP,
          endTime: endDateJP,
          groupIds: selectedGroup,
          streamIds: selectedCamera?.length === 0 ? undefined : selectedCamera,
          pageName: 'dashboardpage',
          username: JSON.parse(localStorage.getItem('user_login_info') as string).username
        };
        const { data: getDateFilteredCharts } = await refetchFilteredSupersetCharts({
          payload: checkedPayload
        });
        if(checkedPayload.streamIds?.length === 0) {
          delete checkedPayload.streamIds;
        }
        if (getDateFilteredCharts?.getFilteredCharts?.result?.length > 0) {
          setDashboardCharts({data: getDateFilteredCharts?.getFilteredCharts?.result});
        }
      }
    }else if(!startDate && !startDate && config.cameraDetailsPageConfigs.layout !== 'traffic_pulse') {
      const { data: getDateFilteredCharts } = await refetchFilteredSupersetCharts({
        payload: {
          startTime: '',
          endTime: '',
          pageName: 'dashboardpage',
          username: JSON.parse(localStorage.getItem('user_login_info') as string).username
        }
      });
      if(config.cameraDetailsPageConfigs.layout !== 'traffic_pulse') {
        if (getDateFilteredCharts?.getFilteredCharts?.result?.length > 0) {
          setDashboardCharts({data: getDateFilteredCharts?.getFilteredCharts?.result});
        }
      }
    }else{
      setIsFilterApplied(false);
      setDateFilter({startDate: '', endDate: ''});
    }
  }, [refetchFilteredSupersetCharts, isAllFiltersClear, selectedGroup, selectedCamera]);

  const handleGrafanaTimeRangeChange = (value: string) => {
    setIsCustomDateFilter(false);
    setGrafanaChartsTimeRange(value);
    const nameOfAppliedFilter = GRAFANA_CHART_RANGE_OPTIONS.filter((option) => option.value === value);
    setAppliedFilterName(nameOfAppliedFilter[0].name);
    localStorage.setItem('grafanaCustomDateRange', JSON.stringify({range: value}));
  };

  const handleMouseDown = (e: React.MouseEvent, sliceId: number, dashboardId?: number) => {
    e.preventDefault();
    setIsResizing(true);
    setResizeStartX(e.clientX);
    setResizeStartY(e.clientY);
    setResizingSliceId(sliceId);
    if(config.Visualization_Tool === 'Grafana') {
      setResizeChartDashboardId(dashboardId);
    }
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (isResizing) {
      const diffX = e.clientX - resizeStartX;
      const diffY = e.clientY - resizeStartY;
      let updatedWidth = diffX;
      let updatedHeight = diffY;
      if(config.Visualization_Tool === 'Superset') {
        if (resizingSliceId !== null) {
          const updatedData = (dashboardCharts?.data || []).map((dataInfo) => {
            if (dataInfo.chartsInfo) {
              const updatedCharts = dataInfo.chartsInfo.map((chart) => {
                if (chart.sliceId === resizingSliceId) {
                  updatedWidth = Math.max(300, Math.min(chart.width + updatedWidth, 800)); // Limit width between 300 and 800
                  updatedHeight = Math.max(400, Math.min((chart.height as number) + updatedHeight, 1000));
                  return {
                    ...chart,
                    width: updatedWidth,
                    height: updatedHeight
                  };
                } else {
                  return chart;
                }
              });
              return {
                ...dataInfo,
                chartsInfo: updatedCharts,
              };
            }
            return dataInfo;
          });
          const updatedObject = {
            ...dashboardCharts,
            data: updatedData,
          };
          setDashboardCharts(updatedObject);
        }
        setResizeStartX(e.clientX);
        setResizeStartY(e.clientY);
      }
      else {
        if (resizingSliceId !== null) {
          const updatedData = (allGrafanaCharts || []).map((dataInfo: any) => {
            if (dataInfo.chartInfo) {
              const updatedCharts = dataInfo.chartInfo.map((chart: any) => {
                if (chart.id === resizingSliceId && dataInfo?.id === resizeChartDashboardId) {
                  updatedWidth = Math.max(500, Math.min(chart.width + updatedWidth, 1000)); // Limit width between 300 and 800
                  updatedHeight = Math.max(400, Math.min(chart.height + updatedHeight, 1000));
                  return {
                    ...chart,
                    width: updatedWidth,
                    height: updatedHeight
                  };
                } else {
                  return chart;
                }
              });
              return {
                ...dataInfo,
                chartInfo: updatedCharts,
              };
            }
            return dataInfo;
          });
          setAllGrafanaCharts(updatedData as []);
        }
        setResizeStartX(e.clientX);
        setResizeStartY(e.clientY);
      }
    }
  };

  const handleUpdateCharts = () => {
    const checkedPayload = {
      startTime: dateFilter.startDate ? dateFilter.startDate : undefined,
      endTime: dateFilter.endDate ? dateFilter.endDate : undefined,
      groupIds: selectedGroup.length === 0 ? undefined: selectedGroup ,
      streamIds: selectedCamera.length === 0 ? undefined: selectedCamera,
      pageName: 'dashboardpage',
      username: JSON.parse(localStorage.getItem('user_login_info') as string).username
    };

    if(!dateFilter.startDate || !dateFilter.endDate) {
      delete checkedPayload.startTime;
      delete checkedPayload.endTime;
    }
    if(!checkedPayload.streamIds) {
      delete checkedPayload.streamIds;
    }
    if(!checkedPayload.groupIds) {
      delete checkedPayload.groupIds;
    }
    filteredDashboardCall(checkedPayload);
    setChartsUpdateTime(t('Just now'));
    setIsRotating(true); 
    setTimeout(() => {
      setIsRotating(false);
    }, 1000);
  };

  const handleGrafanaChartsPDFDownload = async () => {
    try {
      const res = await downloadGrafanaPDFRefetch({payload: {pageName: 'dashboardpage', username: JSON.parse(localStorage.getItem('user_login_info') as string).username, timeRange: grafanaChartsTimeRange}});
      if (res.data?.storeGrafanaChartsPdf?.statusCode === StatusCode.SUCCESS) {
        setTimeout(() =>{
          downloadLink(SERVER_URL + res.data?.storeGrafanaChartsPdf?.result);
          setMessage({ text: t('Charts PDF downloaded successfully'), id: '', type: 'success' });
        },1000);
      } else {
        setMessage({ text: t('apiError'), id: '', type: 'danger' });
      }
    } catch (err) {
      console.error(err);
      setMessage({ text: t('apiError'), id: '', type: 'danger' });
    }
  };

  const handleApplyCustomFilter = () => {
    if(startDate && endDate && !dateError) {
      setIsCustomDateFilter(true);
      setGrafanaChartsTimeRange(`&from=${startDate?.toDate()?.getTime()}&to=${endDate?.toDate()?.getTime()}`); 
      localStorage.setItem('grafanaCustomDateRange', JSON.stringify({startDate: startDate, endDate: endDate}));
      setCustomFilterModal(false);
    }
  };
  
  const handleMouseUp = () => {
    setIsResizing(false);
    setResizingSliceId(null);
  };

  const GROUP_OPTIONS = useMemo(()=>{
    return groups?.map((item: IGroups) => ({
      name: item.name,
      value: item.id
    }));
  },[groups]);

  const handleGroupFilterChange = (selectedIds: string[]) => {
    setSelectedGroup(selectedIds);
    const ids = selectedIds.map(id => parseInt(id));
    const checkedPayload = {
      startTime: dateFilter.startDate ? dateFilter.startDate : undefined,
      endTime: dateFilter.endDate ? dateFilter.endDate : undefined,
      groupIds: ids,
      streamIds: selectedCamera.length === 0 ? undefined: selectedCamera,
      pageName: 'dashboardpage',
      username: JSON.parse(localStorage.getItem('user_login_info') as string).username
    };

    if(!dateFilter.startDate || !dateFilter.endDate) {
      delete checkedPayload.startTime;
      delete checkedPayload.endTime;
    }
    if(!checkedPayload.streamIds) {
      delete checkedPayload.streamIds;
    }
    filteredDashboardCall(checkedPayload);
  };

  const handleCameraFilterChange = async (value: string[]) => {
    setSelectedCamera(value);
    const ids = value.map(id => parseInt(id));
    const checkedPayload = {
      startTime: dateFilter.startDate ? dateFilter.startDate : undefined,
      endTime: dateFilter.endDate ? dateFilter.endDate : undefined,
      groupIds: selectedGroup,
      streamIds: ids.length !== 0 ? value : undefined,
      pageName: 'dashboardpage',
      username: JSON.parse(localStorage.getItem('user_login_info') as string).username
    };
    if(!dateFilter.startDate || !dateFilter.endDate) {
      delete checkedPayload.startTime;
      delete checkedPayload.endTime;
    }
    if(!value) {
      delete checkedPayload.streamIds;
    }
    filteredDashboardCall(checkedPayload);
  };

  const handleClearAllFilters = () => {
    setSelectedGroup([]);
    setSelectedCamera([]);
    setDateFilter({startDate: '', endDate: ''});
    setIsAllFiltersClear(true);
    const checkedPayload = {
      pageName: 'dashboardpage',
      username: JSON.parse(localStorage.getItem('user_login_info') as string).username
    };
    setClearDateInterval(true);
    filteredDashboardCall(checkedPayload);
  };

  const toggleVisualizationOptions = () => {
    setVisualizationIconToggle(!visualizationIconToggle);
  };

  const isDatePickerPresent = () => {
    const elements = document.querySelectorAll('.css-1anqmj6-MuiPopper-root-MuiPickersPopper-root');
    if (elements.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  useClickOutside(customFilterRef, () => {if(customFilterModal) {
    if(!isDatePickerPresent()){
      setCustomFilterModal(false);
    }
  }});

  const handleRefreshInterval = (value: string) => {
    setSupersetDataRefetchInterval(value);
  };

  useEffect(() => {
    if(config.Visualization_Tool === 'Superset') {
      if (dashboardCharts?.data) {
        const allCharts = dashboardCharts.data.flatMap((dataItem) => dataItem.chartsInfo);
        const sorted = allCharts.slice().sort((a, b) => a.sequenceId - b.sequenceId);
        setSortedCharts(sorted);
      }
    }
  }, [dashboardCharts, allGrafanaCharts]);

  return (
    <DashboardWrapper>
      {
        message && <AlertBar message={message.text} type={message.type as IconType} setMessage={setMessage} />
      }
      <VisualizationWrapper ref={visualizationIconRef}>
        <VisualizationToggleWrapper isDefaultPage={config.fetchLatestAlertAPIEnable} onClick={toggleVisualizationOptions}>     
          <AiOutlineBarChart size={24}/>
        </VisualizationToggleWrapper>
        <AllChartOptionsWrapper isAlertIconPresent={config.fetchLatestAlertAPIEnable} visualizationIconToggle={visualizationIconToggle}>
          <Arrow />
          <ChartOptionsWrapper onClick={() => {setIsResetSequenceActive(true); setVisualizationIconToggle(false);}}>
            <ChartOption>{t('Rearrange')}</ChartOption>
          </ChartOptionsWrapper>
          <ChartOptionsWrapper>
            <PaginationWrapper>
              <RefreshLabel>{t('Refesh Interval')}</RefreshLabel>
              <SelectOptionField
                onChange={(e) => handleRefreshInterval(e.target.value)}
                defaultValue={supersetDataRefetchInterval}
                value={supersetDataRefetchInterval}
              >
                {
                  config.Visualization_Tool === 'Superset' ? SUPERSET_REFRESH_INTERVAL.map((size: string, index: number) => <option key={index} value={size}>{size}</option>): GRAFANA_REFRESH_INTERVAL.map((size: string, index: number) => <option key={index} value={size}>{size}</option>)
                }
              </SelectOptionField>
            </PaginationWrapper>  
          </ChartOptionsWrapper>
        </AllChartOptionsWrapper>
      </VisualizationWrapper>
      <Modal isModalOpen={fullScreenModalView} closeModal={setFullScreenModalView} width='60vw'>
        <ModalViewWrapper>
          <EmbeddedChart src={SUPERSET_URL + modalViewSrc} width={750} height={300} />
        </ModalViewWrapper>
      </Modal>
      {
        <DashboardHeader>
          <ImportChartsWrapper>
            { !isResetSequenceActive && TokenService.hasAccess(PERMISSION_CODE.store_charts) && <ButtonWithIcon icon='Download' position='right' variant='primary' onClick={() => history.push('/dashboardpage/selectcharts')}>{t('Import Charts')}</ButtonWithIcon>}
            { !isResetSequenceActive && TokenService.hasAccess(PERMISSION_CODE.manage_charts) && <ButtonWithIcon icon='Settings' position='right' variant='primary' onClick={() => {if(config.Visualization_Tool === 'Superset') {window.open(SERVER_URL + ':' + SUPERSET_PORT, '_blank');} else {window.open(SERVER_URL + GRAFANA_URL);}}} >{t('Manage Charts')}</ButtonWithIcon>}
            { allGrafanaCharts?.length > 0 && <ButtonWithIcon icon='Download' variant='primary' onClick={handleGrafanaChartsPDFDownload}>{t('Download charts')}</ButtonWithIcon>}
            { isResetSequenceActive && <ButtonWithLoading loading={loading} disabled={loading} variant='primary' onClick={handleRearrangeCharts}>{t('Save')}</ButtonWithLoading>}
            { isResetSequenceActive && <Button variant='secondary' onClick={() => setIsResetSequenceActive(!isResetSequenceActive)}>{t('Cancel')}</Button>}
          </ImportChartsWrapper>
          <AllFilters>
            <FiltersSection>      
              <CalenderWrapper>
                <CalendarLabel>{t('Date Range')}</CalendarLabel>
                <Calendar onDateRangeCallback={handleDateRangeCallback} defaultFromDatePlaceholder={t('Today (Midnight)')} setDateFilter={setDateFilter} defaultToDatePlaceholder={t('Today (Now)')} clearDateRange={clearDateInterval} />
              </CalenderWrapper>
              {config.cameraDetailsPageConfigs.layout !== 'traffic_pulse' && <>
                <MultipleSelect
                  disabled={false}
                  width='100%'
                  showSelectedOptions={false}
                  selectedPlaceholder={'Selected'}
                  label={t('Filter by Group')}
                  placeholder={t('Filter by Group')}
                  options={(GROUP_OPTIONS as [])?.length !== 0 ? GROUP_OPTIONS as [] : []} 
                  selectedValues={selectedGroup} 
                  onChange={(selectedFilterIds) => handleGroupFilterChange(selectedFilterIds)}
                  emptyListPlaceholder={'No Groups present'}
                />
                <MultipleSelect
                  disabled={false}
                  width='100%'
                  showSelectedOptions={false}
                  selectedPlaceholder={t('Selected')}
                  label={t('Filter by Cameras')}
                  placeholder={t('Filter by Cameras')}
                  options={(allStreams as [])?.length !== 0 ? allStreams as [] : []} 
                  selectedValues={selectedCamera} 
                  onChange={(selectedFilterIds) => handleCameraFilterChange(selectedFilterIds)}
                  emptyListPlaceholder={'No Cameras present'}
                />
              </> 
              }
            </FiltersSection>
            <AppliedFiltersWrapper>
              {(dateFilter.startDate !== '' && dateFilter.endDate !== '') && (
                <FilterTag>
                  {t('Custom Date')}: {`${convertToISTWithDash(dateFilter.startDate)} - ${convertToISTWithDash(dateFilter.endDate)}`}
                  <CloseButtonWrapper onClick={() => {setDateFilter({startDate: '', endDate: ''}); setClearDateInterval(true);}}>
                    <IoMdClose size={22} color='#000000'/>
                  </CloseButtonWrapper>
                </FilterTag>
              )}
              {selectedGroup.length > 0 && (
                <FilterTag>
                  Groups: {selectedGroup.map((groupId: any) => GROUP_OPTIONS?.find(group => group.value === Number(groupId))?.name).join(', ')}
                  <CloseButtonWrapper onClick={() => handleGroupFilterChange([])}>
                    <IoMdClose size={22} color='#000000'/>
                  </CloseButtonWrapper>
                </FilterTag>
              )}
              {selectedCamera.length > 0 && (
                <FilterTag>
                  Cameras: {selectedCamera.map(cameraId => allStreams.find(stream => stream.value === cameraId)?.name).join(', ')}
                  <CloseButtonWrapper onClick={() => handleCameraFilterChange([])}>
                    <IoMdClose size={22} color='#000000'/>
                  </CloseButtonWrapper>
                </FilterTag>
              )}
              {((dateFilter.startDate !== '' && dateFilter.endDate !== '') || selectedGroup.length > 0 || selectedCamera.length > 0) && <ClearAllFiltersWrapper title={t('Clear All')} onClick={handleClearAllFilters}>{t('Clear All')}</ClearAllFiltersWrapper>}
            </AppliedFiltersWrapper>
          </AllFilters>
        </DashboardHeader>
      }
      {
        config.showDetectionCountOnDashboard && allStreamsData.some(camera =>camera?.algorithmList?.some(algorithm => algorithm.code === ALGORITHM_CODES.traffic_pulse_detection)) &&
        <DetectionProvider isSkip={!allStreamsData.some(camera =>camera?.algorithmList?.some(algorithm => algorithm.code === ALGORITHM_CODES.traffic_pulse_detection))}>
          <StatisticInfoPanel 
            startDate={dateFilter.startDate} 
            endDate={dateFilter.endDate} 
            selectedCamera={selectedCamera}
            selectedGroup={selectedGroup}
            isFilterApplied={isFilterApplied} />
        </DetectionProvider>
      }
      {
        dashboardCharts ? (
          <ChartsSection isTrafficPulse={config.cameraDetailsPageConfigs.layout === 'traffic_pulse'}>
            {sortedCharts?.length > 0 && <RefreshButtonWrapper isTrafficPulse={config.cameraDetailsPageConfigs.layout === 'traffic_pulse'}>
              <StatusWrapper>{t('Updated')}: {chartsUpdateTime} {chartsUpdateTime !== t('Just now') && t('ago')}</StatusWrapper>
              <IconWrapper isRotating={isRotating}>
                <LuRefreshCcw size={18} onClick={() => handleUpdateCharts()} /> 
              </IconWrapper>
            </RefreshButtonWrapper>}
            <ChartsWrapper>
              {sortedCharts?.map((chart, chartIndex) => (
                <SingleChartWrapper isRearrange={isResetSequenceActive}
                  key={`chart-${chartIndex}`}
                  onDragStart={(e) => handleDragStart(e, chart?.sequenceId)}
                  onDragOver={(e) => handleDragOver(e)}
                  onDrop={(e) => handleDrop(e, chart?.sequenceId)}
                  draggable={isResetSequenceActive}
                >
                  <ChartTitle onClick={() => handleModalChartView(chart?.permalink?.split(':9097')[1])}>
                    <Title>
                      {chart?.sliceName}
                    </Title>
                  </ChartTitle>
                  {isResetSequenceActive && (
                    <>
                      <ResizeIndicator
                        onClick={() => setIsResizing(!isResizing)}
                        onMouseDown={(e) => handleMouseDown(e, chart.sliceId)}
                        onMouseLeave={() => setIsResizing(false)}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                      />
                      <ResizeIndicatorY width={chart?.width}
                        onClick={() => setIsResizing(!isResizing)}
                        onMouseDown={(e) => handleMouseDown(e, chart.sliceId)}
                        onMouseLeave={() => setIsResizing(false)}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                      />
                    </>
                  )}
                  <EmbeddedChart
                    title={chart?.sliceName}
                    src={SUPERSET_URL + chart?.permalink?.split(':9097')[1]}
                    width={chart.width || 400}
                    height={chart.height || 400}
                  />
                </SingleChartWrapper>
              ))}        
            </ChartsWrapper>
          </ChartsSection>
        ) :      
          ((dashboardChartsLoading && viewChartsAccess) || loader) ? <SpinnerBox>
            <ThreeDotLoader />
          </SpinnerBox> : <MessageWrapper>{t('It looks like you have not selected any graphs yet. Please choose the graphs you would like to have on dashboard page.')}</MessageWrapper>
      }
      {
        grafanaChartsLoading && <SpinnerBox>
          <CircularProgress />
          <Label labelText={t('Fetching your graphs')}/>
        </SpinnerBox>
      }
      {
        allGrafanaCharts?.length > 0 && <FiltersWrapper>
          <SelectField label={t('Select duration')} options={GRAFANA_CHART_RANGE_OPTIONS.map((option) => {return {name: t(`${option.name}`), value: option.value};})} defaultValue={grafanaChartsTimeRange} onChange={handleGrafanaTimeRangeChange} />
          <SeparatorTextWrapper>{t('ORtext')}</SeparatorTextWrapper>
          <CustomFilter>
            <Button variant='primary' onClick={() => setCustomFilterModal(true)}>{t('Date Range')}</Button>
          </CustomFilter>
          {
            customFilterModal && <CustomFilterWrapper ref={customFilterRef}>
              <DateRangeFilterContainer>
                <ModalLabel isBold>{t('Date Range')}</ModalLabel>
                <DateRangeContainer>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePickersWrappers>
                      <DemoContainer components={['DateTimePicker']}>
                        <DateTimePicker
                          label={t('From Date')}
                          value={startDate}
                          defaultValue={null}
                          disableFuture
                          onError={(err) => setDateError(!!err)}
                          format={DATE_PICKER_DATE_TIME_FORMAT}
                          onChange={(newValue) => {setStartDate(newValue);}}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock
                          }}
                        />
                        <DateTimePicker
                          label={t('To Date')}
                          value={endDate}
                          disableFuture
                          onError={(err) => setDateError(!!err)}
                          format={DATE_PICKER_DATE_TIME_FORMAT}
                          onChange={(newValue) => setEndDate(newValue)}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock
                          }}
                        />
                      </DemoContainer>
                    </DatePickersWrappers>
                  </LocalizationProvider>
                </DateRangeContainer>
                <ButtonContainer>
                  <Button variant='primary' onClick={() => handleApplyCustomFilter()}>{t('Apply')}</Button>
                </ButtonContainer>
              </DateRangeFilterContainer>
            </CustomFilterWrapper>  
          }
        </FiltersWrapper>
      }
      {allGrafanaCharts?.length > 0 && <AppliedFilterWrapper>
        <AppliedFiltersDiv><DurationTextWrapper>{t('Duration')}:</DurationTextWrapper>
          {!isCustomDateFilter && <span>{t(`${appliedFilterName}`)}</span>}
          {isCustomDateFilter && <span>{String(startDate?.toDate()) + ' - ' + String(endDate?.toDate())}</span>}
        </AppliedFiltersDiv>
      </AppliedFilterWrapper>}
      <GrafanaChartsWrapper>
        {
          allGrafanaCharts?.length > 0 ? allGrafanaCharts?.map((grafanaChart: any) => 
            grafanaChart['chartInfo' as string].map((individualChart: any) => {
              const updatedChartUrl = (individualChart.url).replace('/grafana/grafana', '/grafana');
              return <GrafanaSingleChartWrapper
                draggable={isResetSequenceActive}
                isRearrange={isResetSequenceActive}>
                {isResetSequenceActive && (
                  <>
                    <ResizeIndicator
                      onClick={() => setIsResizing(!isResizing)}
                      onMouseDown={(e) => handleMouseDown(e, individualChart?.id, grafanaChart?.id)}
                      onMouseLeave={() => setIsResizing(false)}
                      onMouseMove={handleMouseMove}
                      onMouseUp={handleMouseUp}
                    />
                    <ResizeIndicatorY width={individualChart?.width}
                      onClick={() => setIsResizing(!isResizing)}
                      onMouseDown={(e) => handleMouseDown(e, individualChart.id, grafanaChart?.id)}
                      onMouseLeave={() => setIsResizing(false)}
                      onMouseMove={handleMouseMove}
                      onMouseUp={handleMouseUp}
                    />
                  </>
                )}
                {/* To be Updated in Next PR */}
                <IFrameGrafana src={SERVER_URL + ':25000/grafana' + updatedChartUrl + '&theme=light&refresh='+ supersetDataRefetchInterval + '&' + grafanaChartsTimeRange} title='Grafana Chart' width={individualChart?.width || 810} height={individualChart?.height || 400}></IFrameGrafana> 
              </GrafanaSingleChartWrapper>;
            })  
          ) : (config.Visualization_Tool === 'Grafana' && !grafanaChartsLoading) && <MessageWrapper>{t('It looks like you have not selected any graphs yet. Please choose the graphs you would like to have on dashboard page.')}</MessageWrapper>
        }
      </GrafanaChartsWrapper>
      {
        !viewChartsAccess && <MessageWrapper>{t('Unfortunately, you do not have permission to view these charts at this time. Please contact your administrator for assistance.')}</MessageWrapper>
      }
    </DashboardWrapper>
  );
};

export default Dashboard;

const downloadLink = (link: string) => {
  if(!link){return;}
  const anchor = document.createElement('a');
  anchor.href = link;
  document.body.appendChild(anchor);
  anchor.click();
  anchor.remove(); 
};