import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgDownload = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4m-4-6-5 5-5-5m5 3.8V2.5"
        stroke={props.color}
        strokeWidth={props.weight}
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={"non-scaling-stroke"}
      />
    </svg>
  );
};
export default SvgDownload;
