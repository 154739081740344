import React from 'react';
import styled from 'styled-components';
import { IStream } from 'interface';
import { FaFolder } from 'react-icons/fa';
// import Icon from 'components/Icon';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media only screen and (min-width: 1270px){
    gap: 40px;
  }
  @media only screen and (min-width: 1400px){
    gap: 5px;
  }
  width: 100%;
`;

const Section = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  background: #f9f9f9;
  width: 500px;
`;

const Title = styled.h3`
  margin-bottom: 10px;
`;

const DetailsList = styled.div`
  display: flex;
  gap: 0 10px;
`;

const StatusIndicator = styled.div<{ online: string }>`
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${props => props.online === 'ready' ? 'green' : 'lightblue'};
  margin-right: 10px;
`;

const Titlewrapper = styled.div`
  display: flex;
  gap: 0 10px;
  margin-bottom: 15px;
`;

const StatusWrapper = styled.div`
  display: flex;
  gap: 5px 10px;
  align-items: center;
`;

const Status = styled.div`
  display: flex;
  gap: 5px 10px;
  flex-direction: column;
`;

const TimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px 10px;
  margin-left: 50px;
`;

const SubTitle = styled.div`
  color: grey;
  font-size: 14px;
`;

const DetailsTitle = styled.div`
  font-size: 16px;
`;

// const ConfigurationButton = styled.div`
//   display: flex;
//   align-items: center;
//   border-radius: 6px;
//   gap: 10px;
//   padding: 7px 10px;
//   width: fit-content;
//   cursor: pointer;
//   background-color: #435FCA;
//   >span{
//     color: #FFF;
//     margin-bottom: 2px;
//   }
//   >div{
//     >svg>g>path{
//       stroke: ${({theme}) => theme.text.lightText};
//       fill: ${({theme}) => theme.text.lightText};
//     }
//   }
// `;

// const Label = styled.span``;


interface IOverViewDetail {
  streamDetails: IStream | undefined
  status: string
}

const OverViewTab = ({ streamDetails, status }: IOverViewDetail) => {

  console.log({ streamDetails, status });
  return (
    <Container>
      <Section>
        <Titlewrapper>
          <FaFolder size={20} />
          <Title>Folder Details</Title>
        </Titlewrapper>
        <DetailsList>
          <StatusWrapper>
            <StatusIndicator online={status} />
            <Status>
              <SubTitle>Status</SubTitle>
              <DetailsTitle>{status === 'running' ? 'Ready' : 'Disabled'}</DetailsTitle>
            </Status>
          </StatusWrapper>
          <TimeWrapper>
            <SubTitle>Last Upload</SubTitle>
            <DetailsTitle>26 min ago</DetailsTitle>
          </TimeWrapper>
        </DetailsList>
      </Section>
      {/* <ConfigurationButton>
        <Label>Upload</Label>
        <Icon icon='Upload' color='inverse' />
      </ConfigurationButton> */}
    </Container>
  );
};

export default OverViewTab;
