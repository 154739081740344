import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { GET_ADV_CONFIG, GET_DEVICE_INFO, GET_RTSP_STREAM } from 'api_configs/queries';
import { useMutation, useQuery } from '@apollo/client';
import Switch from 'components/Switch';
import Button from 'components/Button';
import { useHistory } from 'react-router';
import SelectField from 'components/SelectField';
import { HLS_MDNS_URL, HLS_IP_URL, IconType, MAX_PACKET_DELAY, SHORT_HEADER_OPTIONS, StatusCode, StreamType, VIDEO_FPS_OPTIONS, RTSP_IP_URL, RTSP_MDNS_URL, WEB_RTC_IP_URL, WEB_RTC_MDNS_URL, PERMISSION_CODE } from '../../constants';
import Input from 'components/Input';
import MP4Recording from './CameraComponents/MP4Recording';
import PeriodicSnapshot from './CameraComponents/PeriodicSnapshot';
import { ADVANCED_PATCH_RTSP_CAMERA } from 'api_configs/mutations';
import { IAlertMessageType } from 'interface';
import AlertBar from 'components/AlertBar';
import { useTranslation } from 'react-i18next';
import Label from 'components/Label';
import { CircularProgress } from '@mui/material';
import MdnsIpInput from 'components/MdnsIpInput';
import { allowOnlyNumbers } from 'utils/utils';
import ButtonWithLoading from 'components/ButtonWithLoading';
import TokenService from 'api_configs/tokenService';
const viewAdvancedConfigAccess = TokenService.hasAccess(PERMISSION_CODE.view_advanced_config);
const editAdvancedConfigAccess = TokenService.hasAccess(PERMISSION_CODE.edit_advanced_config);

const AdvancedConfigWrapper = styled.div<{isEditPermission: boolean}>`
  pointer-events: ${({isEditPermission}) => isEditPermission ? '': 'none'};
`;

const SpinnerBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 89vh;
  align-items: center;
  justify-content: center;
`;

const VideoConfigurationWrapper = styled.div`
  display: flex;
  width: 1060px;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 768px) {
    width: 530px;
  }
`;

const EndPointsWrapper = styled.div`
  width: 1040px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Heading = styled.div`
  font-size: 20px;
  font-weight: 700;
`;

const Configurations = styled.div`
  display: flex;
  gap: 110px;
`;

const LeftSideWrapper = styled.div``;

const RightSideWrapper = styled.div``;

const HWAccelerationWrapper = styled.div`
  display: flex;
  gap: 25px;
  margin-bottom: 20px;
`;

const MP4RecordingWrapping = styled.div``;

const VerticalLine = styled.div`
  width: 1040px;
  height: 10px;
  border-bottom: 1px solid lightgrey;
  margin-top: 2px;
  margin-bottom: 32px;
  @media (max-width: 768px) {
    width: 450px;
  }
`;

const PeriodicSnapshotWrapping = styled.div``;

const ButtonsWrapper = styled.div`
  display: flex;
  width: 1060px;
  justify-content: center;
  gap: 20px;
  margin-top: 15px;
`;

const NoPermissionMessage = styled.div`
  width: 1040px;
  height: 800px;
  display: flex;
  padding-top: 160px;
  justify-content: center;
`;

const MessageWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
`;

const SwitchWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

interface IAdvancedConfig {
  streamType: string | undefined,
  instanceId: string | undefined
}

const AdvancedRTSP = ({streamType, instanceId}: IAdvancedConfig) => {
  const [basicInfo, setBasicInfo] = useState({name: '', rtspUrl: '', notes: '', instanceId: '', username: '', password: '', groupList: []});
  const [advancedRtsp, setAdvancedRtsp] = useState({rtspPort: '', hlsEnabled: false, rtspEnabled: false, services: {recorder: {enableHwAcceleration: false, videoFps: 'auto', rtspSourceBufferTime: '5', videoFlipMethod: 'auto', maximumPacketDelay: 'auto', webrtcPort: '', maximumPacketDelayType: 'auto', rtspSourceShortHeader: false, videoWidth: '1920', h264Profile: 'auto', videoHeight: '1080', enableSaveMp4: true, videoSplitMode: 'time', enableMp4TimeOverlay: false, recordingQuality: 'low', videoFileMaxSize: '52428100', videoDuration: '600', periodicSnapshot: true, enableSnapshotTimeOverlay: false, saveInterval: '600', specifySnapshotResolution: false, periodicSnapshotResolution: 'auto', saveOriginalSnapshot: false, videoPayloadType: 96, enableVideoMulticast: true, videoPortRtcp: '1063', pixelAspectRatio: 'auto', enableVideoRtcpMuxed: false, enableAudio: false, rtpAudioSourceType: 'acc', audioPayloadType: 103, enableAudioMulticast: false, audioMulticastAddress: '', audioPortRtp: '', enableAudioRtcpMuxed: false, audioPortRtcp: ''}}});
  const [currentResponse, setCurrentResponse] = useState({rtspPort: '', hlsEnabled: false, rtspEnabled: false, services: {recorder: {enableHwAcceleration: false, videoFps: 'auto', rtspSourceBufferTime: '5', videoFlipMethod: 'auto', maximumPacketDelay: 'auto', webrtcPort: '', maximumPacketDelayType: 'auto', rtspSourceShortHeader: false, videoWidth: '1920', h264Profile: 'auto', videoHeight: '1080', enableSaveMp4: true, videoSplitMode: 'time', enableMp4TimeOverlay: false, recordingQuality: 'low', videoFileMaxSize: '52428100', videoDuration: '600', periodicSnapshot: true, enableSnapshotTimeOverlay: false, saveInterval: '600', specifySnapshotResolution: false, periodicSnapshotResolution: 'auto', saveOriginalSnapshot: false, videoPayloadType: 96, enableVideoMulticast: true, videoPortRtcp: '1063', pixelAspectRatio: 'auto', enableVideoRtcpMuxed: false, enableAudio: false, rtpAudioSourceType: 'acc', audioPayloadType: 103, enableAudioMulticast: false, audioMulticastAddress: '', audioPortRtp: '', enableAudioRtcpMuxed: false, audioPortRtcp: ''}}});
  const { data: advancedConfigData, loading: getAdvancedConfigLoading, refetch } = useQuery(GET_ADV_CONFIG, { variables: {streamId: instanceId} });
  const {data: getRTSP, loading: getStreamLoading} = useQuery(GET_RTSP_STREAM, { variables: {streamId: instanceId} });
  const {t} = useTranslation(['common']);
  const [currentDeviceInfo, setCurrentDeviceInfo] = useState({deviceId: '', networkId: ''});
  const [isLoading, setIsLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(false);
  const {data: deviceInfo, loading: getDeviceInfoLoading} = useQuery(GET_DEVICE_INFO, {variables: {serverId: null}});
  const [message, setMessage] = useState<IAlertMessageType>({text: '', id: '', type: ''});  
  const [patchRTSPStreamMutation] = useMutation(ADVANCED_PATCH_RTSP_CAMERA);
  const history = useHistory();

  useEffect(() => {
    refetch();
    if(!getAdvancedConfigLoading && !getStreamLoading && !getDeviceInfoLoading) {
      if(advancedConfigData?.getAdvConfig?.result && getRTSP?.getStream?.result && deviceInfo?.getDeviceInfo?.result){
        setAdvancedRtsp(advancedConfigData?.getAdvConfig?.result);
        setCurrentResponse(advancedConfigData?.getAdvConfig?.result);
        setBasicInfo(getRTSP?.getStream?.result);
        setCurrentDeviceInfo(prevState => ({
          ...prevState,
          deviceId: deviceInfo?.getDeviceInfo?.result.deviceId,
          networkId: deviceInfo?.getDeviceInfo?.result?.networks[0]?.ipAddress?.split('/')[0]
        }));
        setIsLoading(false);
      }
    } 
  },[getAdvancedConfigLoading, advancedConfigData, getRTSP, deviceInfo, getStreamLoading, refetch, getDeviceInfoLoading]);
  
  const handlePatchRTSPStream = async () => {
    const advancedRtspPatchPayload = {streamId: basicInfo.instanceId, groupList: basicInfo.groupList, notes: basicInfo.notes, streamType: StreamType.RTSP, name: basicInfo.name, rtspEnabled: advancedRtsp.rtspEnabled, hlsEnabled: advancedRtsp.hlsEnabled, videoConfiguration: {rtspUrl: basicInfo.rtspUrl, username: basicInfo.username, password: basicInfo.password, enableHwAcceleration: advancedRtsp.services.recorder.enableHwAcceleration, videoFps: advancedRtsp.services.recorder.videoFps, rtspSourceBufferTime: advancedRtsp.services.recorder.rtspSourceBufferTime, maximumPacketDelay: advancedRtsp.services.recorder.maximumPacketDelay, maximumPacketDelayType: advancedRtsp.services.recorder.maximumPacketDelayType, rtspSourceShortHeader: advancedRtsp.services.recorder.rtspSourceShortHeader, enableSaveMp4: advancedRtsp.services.recorder.enableSaveMp4, videoSplitMode: advancedRtsp.services.recorder.videoSplitMode, videoDuration: advancedRtsp.services.recorder.videoDuration, periodicSnapshot: advancedRtsp.services.recorder.periodicSnapshot, enableSnapshotTimeOverlay: advancedRtsp.services.recorder.enableSnapshotTimeOverlay, saveInterval: advancedRtsp.services.recorder.saveInterval, specifySnapshotResolution: advancedRtsp.services.recorder.specifySnapshotResolution, periodicSnapshotResolution: advancedRtsp.services.recorder.periodicSnapshotResolution, saveOriginalSnapshot: advancedRtsp.services.recorder.saveOriginalSnapshot}};
    if(advancedRtsp?.services?.recorder?.rtspSourceBufferTime === '') {
      setMessage({text: t('Buffer Time cannot be empty'), id:'rtspSourceBufferTime', type:'danger'});
      return;
    }
    if(advancedRtsp?.services?.recorder?.maximumPacketDelayType === 'custom' && advancedRtsp?.services?.recorder?.maximumPacketDelay === '') {
      setMessage({text: t('Maximum packet delay cannot be empty'), id:'maximumPacketDelayType', type:'danger'});
      return;
    }
    if(advancedRtsp?.services?.recorder?.videoDuration === '') {
      setMessage({text: t('Please enter mp4 split interval value in sec(s)'), id:'videoDuration', type:'danger'});
      return;
    }
    try{
      setPageLoading(true);
      const data = await patchRTSPStreamMutation({variables: {payload: advancedRtspPatchPayload}});
      if(data && data?.data?.advPatchStream?.statusCode === StatusCode.SUCCESS_200){
        setMessage({text: t('Data updated successfully'), id:'', type:'success'});
        refetch();
        setPageLoading(false);
      }else{
        setMessage({text: t('apiError'), id:'', type:'danger'});
        setPageLoading(false);
      }
    }catch(e){
      setMessage({text: t('apiError'), id:'', type:'danger'});
      console.error(e);
    }finally {
      setPageLoading(false);
    }
  };

  const handleInputFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = event.target.id;
    const value: string = event.target.value;
    setAdvancedRtsp(prevState => ({
      ...prevState,
      services: {
        ...prevState?.services,
        recorder: {
          ...prevState?.services?.recorder,
          [id]: value
        }
      }
    }));
  };

  const handleSelectFieldChange = (value: string, id: string) => {
    if(id === 'maximumPacketDelayType')
    {
      if(value === 'auto') {
        setAdvancedRtsp(prevState => ({
          ...prevState,
          services: {
            ...prevState?.services,
            recorder: {
              ...prevState?.services?.recorder,
              [id]: value,
              maximumPacketDelay: 'auto'
            }
          }
        }));
      }else{
        setAdvancedRtsp(prevState => ({
          ...prevState,
          services: {
            ...prevState?.services,
            recorder: {
              ...prevState?.services?.recorder,
              [id]: value,
              maximumPacketDelay: '2'
            }
          }
        }));
      }
      
    } else if(id === 'rtspSourceShortHeader'){
      let boolValue: boolean;
      if(value === '0') {
        boolValue = false;
      }else 
        boolValue = true;
      setAdvancedRtsp(prevState => ({
        ...prevState,
        services: {
          ...prevState?.services,
          recorder: {
            ...prevState?.services?.recorder,
            [id]: boolValue,
          }
        }
      }));
    } else {
      setAdvancedRtsp(prevState => ({
        ...prevState,
        services: {
          ...prevState?.services,
          recorder: {
            ...prevState?.services?.recorder,
            [id]: value
          }
        }
      }));  
    }
  };

  const handleSwitchChange = (state: boolean, id: string) => {
    setAdvancedRtsp(prevState => ({
      ...prevState,
      services: {
        ...prevState?.services,
        recorder: {
          ...prevState?.services?.recorder,
          [id]: !state
        }
      }
    }));
  };

  if(!viewAdvancedConfigAccess) {
    return <NoPermissionMessage>
      <MessageWrapper>{t('Unfortunately, you do not have permission to view advanced configurations at this time. Please contact your administrator for assistance.')}</MessageWrapper>
    </NoPermissionMessage>;
  }

  return (
    (isLoading) ? 
      <SpinnerBox>
        <CircularProgress />
        <Label labelText={t('Loading...')}/>
      </SpinnerBox> : 
      <AdvancedConfigWrapper isEditPermission={editAdvancedConfigAccess}>
        {
          message && <AlertBar message={message.text} type={message.type as IconType} setMessage={setMessage} />
        }
        <EndPointsWrapper>
          <Heading>{t('Endpoints')}</Heading>
          <VerticalLine />
          {/* working on this. will raise separate PR for this */}
          <MdnsIpInput defaultValue={advancedRtsp?.hlsEnabled} name={t('HLS Enabled')} mdnsValue={advancedRtsp?.hlsEnabled === false ? '' : HLS_MDNS_URL.replace('{deviceId}', currentDeviceInfo.deviceId).replace('{instanceId}', instanceId as string)} ipValue={advancedRtsp?.hlsEnabled === false ? '' : HLS_IP_URL.replace('{networkId}', currentDeviceInfo.networkId).replace('{instanceId}', instanceId as string)} onEnabled={() => {setAdvancedRtsp(prevState => ({...prevState, hlsEnabled: !advancedRtsp?.hlsEnabled}));}} />
          <MdnsIpInput defaultValue={true} name={t('WebRTC Enabled')} mdnsValue={WEB_RTC_MDNS_URL.replace('{deviceId}', currentDeviceInfo.deviceId).replace('{webRTCPort}', advancedRtsp?.services?.recorder?.webrtcPort)} ipValue={WEB_RTC_IP_URL.replace('{networkId}', currentDeviceInfo.networkId).replace('{webRTCPort}', advancedRtsp?.services?.recorder?.webrtcPort)} />
          <MdnsIpInput defaultValue={advancedRtsp?.rtspEnabled} name={t('RTSP Enabled')} mdnsValue={advancedConfigData?.getAdvConfig?.result?.rtspPort === '' ? t('Please save endpoints to generate URL') : advancedRtsp?.rtspEnabled === false ? '' : RTSP_MDNS_URL.replace('{deviceId}', currentDeviceInfo.deviceId).replace('{rtspPort}', advancedRtsp?.rtspPort).replace('{instanceId}', instanceId as string)} ipValue={advancedConfigData?.getAdvConfig?.result?.rtspPort === '' ? t('Please save endpoints to generate URL') : advancedRtsp?.rtspEnabled === false ? '' : RTSP_IP_URL.replace('{networkId}', currentDeviceInfo.networkId).replace('{rtspPort}', advancedRtsp.rtspPort).replace('{instanceId}', instanceId as string)} onEnabled={() => {setAdvancedRtsp(prevState => ({...prevState, rtspEnabled: !advancedRtsp?.rtspEnabled}));}} />
        </EndPointsWrapper>
        <VideoConfigurationWrapper>
          <Heading>{t('Video Configurations')}</Heading>
          <VerticalLine />
          <Configurations>
            <LeftSideWrapper>
              <HWAccelerationWrapper>
                <Label labelText={t('HW Acceleration')} />
                <SwitchWrapper>
                  <Switch checked={advancedRtsp?.services?.recorder?.enableHwAcceleration} onChange={() => handleSwitchChange(advancedRtsp?.services?.recorder?.enableHwAcceleration, 'enableHwAcceleration')} />
                </SwitchWrapper>  
              </HWAccelerationWrapper>
              <SelectField id='videoFps' label={t('Framerate of Analysis')} options={VIDEO_FPS_OPTIONS} defaultValue={advancedRtsp?.services?.recorder?.videoFps} onChange={(value) => handleSelectFieldChange(value, 'videoFps')} />
              <Input label={t('Buffer Time')} type='text' maxLength={4} isRequired={true} id='rtspSourceBufferTime' onKeyPress={allowOnlyNumbers} value={advancedRtsp?.services?.recorder?.rtspSourceBufferTime} onChange={(e:React.ChangeEvent<HTMLInputElement>) => handleInputFieldChange(e)} />
              <SelectField label={t('Maximum Packet Delay (Secs)')} isRequired={true} id='maximumPacketDelayType' defaultValue={advancedRtsp?.services?.recorder?.maximumPacketDelayType} options={MAX_PACKET_DELAY} onChange={(value) => handleSelectFieldChange(value, 'maximumPacketDelayType')} />
              {
                advancedRtsp?.services?.recorder?.maximumPacketDelayType === 'custom' && <Input label='' id='maximumPacketDelay' type='number' value={advancedRtsp.services.recorder.maximumPacketDelay} onChange={(e:React.ChangeEvent<HTMLInputElement>) => handleInputFieldChange(e)} />
              }
              <SelectField label={t('Short Header')} options={SHORT_HEADER_OPTIONS} defaultValue={advancedRtsp?.services?.recorder?.rtspSourceShortHeader === true ? '1': '0'} onChange={(value) => handleSelectFieldChange(value, 'rtspSourceShortHeader')} />
            </LeftSideWrapper>
            <RightSideWrapper>
              <MP4RecordingWrapping>
                <MP4Recording streamType={streamType} recordingFields={advancedRtsp} setRecordingFields={setAdvancedRtsp} />
              </MP4RecordingWrapping>
              <PeriodicSnapshotWrapping>
                <PeriodicSnapshot streamType={streamType} snapshotFields={advancedRtsp} setSnapshotFields={setAdvancedRtsp} />
              </PeriodicSnapshotWrapping>
            </RightSideWrapper>
          </Configurations> 
          <ButtonsWrapper>
            <ButtonWithLoading loading={pageLoading} variant='primary' disabled={(JSON.stringify(advancedRtsp) === JSON.stringify(currentResponse)) || !editAdvancedConfigAccess || pageLoading} onClick={handlePatchRTSPStream}>{t('Save')}</ButtonWithLoading>
            <Button variant='secondary' onClick={() => history.push('/')}>{t('Cancel')}</Button>
          </ButtonsWrapper>     
        </VideoConfigurationWrapper>
      </AdvancedConfigWrapper>
  );
};

export default AdvancedRTSP;