import * as React from "react";
import { ISvgIcons } from "../interface";
const SvgEdit = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M16.212 5.1 18.9 7.788M8.836 12.475l2.69 2.687M18.12 3l-9.285 9.476-.927 3.616 3.616-.928 3.797-3.796L21 5.88 18.12 3Z"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={"non-scaling-stroke"}
      />
      <path
        d="M14.455 3.818H3.4a.4.4 0 0 0-.4.4v15.156c0 .22.18.4.4.4h15.156a.4.4 0 0 0 .4-.4V9.955"
        stroke={props.color}
        strokeLinecap="round"
        vectorEffect={"non-scaling-stroke"}
      />
    </svg>
  );
};
export default SvgEdit;
