import React from 'react';
import styled from 'styled-components';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { FaCloudUploadAlt } from 'react-icons/fa';

interface CircularProgressBarProps {
  progress: number;
}

const Container = styled.div`
  width: 80px;
  height: 80px;
  position: relative;
`;

const IconContainer = styled.div`
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: #4a00e0;
`;

const CircularProgressBar: React.FC<CircularProgressBarProps> = ({ progress }) => {
  return (
    <Container>
      <CircularProgressbar
        value={progress}  
        styles={buildStyles({
          pathColor: '#4a00e0',
          trailColor: '#e0e0df',
        })}
      />
      {progress < 100 && (
        <IconContainer>
          <FaCloudUploadAlt size={40} />
        </IconContainer>
      )}
    </Container>
  );
};

export default CircularProgressBar;
